import React, { useState, useEffect } from 'react'
import cls from 'classnames'
import { kodas } from '../../kodas/Kodas'
import closeButton from '../../assets/close.svg'
import AttributePanel from '../AttributePanel/AttributePanel'
import KodaCard from '../KodaCard/KodaCard'
import LandOwnerCard from '../LandOwnerCard/LandOwnerCard'
import { categoriseTraits } from '../../helpers/categoriseTraits'
import './Main.css'

const Main = ({ selectedNft, listData, setSelectedNft }) => {
  const [activeNft, setActiveNft] = useState(listData[0])
  const [wasRendered, setWasRendered] = useState(false)
  const [kodaId, setKodaId] = useState(null)
  const [koda, setKoda] = useState(null)
  const [categorisedTraits, setCategorisedTraits] = useState(null)

  useEffect(() => {
    setActiveNft(selectedNft)
    if (selectedNft) {
      const hasKoda = selectedNft?.traits.some((trait) => trait.trait_type === 'Koda?' && trait.value === 'Yes')
      if (hasKoda) {
        const idTrait = selectedNft?.traits.find((trait) => (trait.trait_type === 'Koda ID' ? trait.value : null))
        setKodaId(idTrait.value)
      } else {
        setKodaId(null)
        setKoda(null)
      }

      setCategorisedTraits(categoriseTraits(selectedNft?.traits))
    }
    if (!wasRendered) {
      setWasRendered(true)
    }
  }, [listData, selectedNft])

  useEffect(() => {
    if (kodaId) {
      const foundKoda = kodas.find((koda) => {
        return koda.id === kodaId
      })
      setKoda(foundKoda)
    }
  }, [kodaId])

  if (!activeNft) return

  return (
    <div className={cls('main', { 'main--loaded': wasRendered })}>
      <div className="closeButton" onClick={() => setSelectedNft(0)}>
        <img src={closeButton} className="closeIcon" alt="close" />
      </div>
      <div className="mainContent">
        <div className="highlight">
          <div className="nftContainer">
            <img className="selectedNft" src={activeNft.imgUrl} alt="" />
          </div>
        </div>
        <div className="detailsWrapper">
          <div className="owner-and-land-info">
            <LandOwnerCard owner={activeNft?.owner} permalink={activeNft?.openseaUrl} />
            <AttributePanel traitType="Plot" traitValue={`#${activeNft.tokenId}`} isBig={true} />
          </div>
          <div className="landDetails">
            {categorisedTraits &&
              Object.keys(categorisedTraits).map((key, i) => {
                return (
                  <AttributePanel
                    key={i}
                    traitType={key}
                    traitValue={categorisedTraits[key].value}
                    traitTier={categorisedTraits[key].tier}
                  />
                )
              })}
          </div>
          {koda && <KodaCard koda={koda} />}
        </div>
      </div>
    </div>
  )
}
export default Main
