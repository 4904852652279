import React from 'react'
import cls from 'classnames'
import './Tag.css'

const Tag = ({ label, isLong, hasEllipsis, className }) => {
  return (
    <>
      {label && (
        <span className={cls('tag', { 'tag--long': isLong }, { 'text-ellipsis': hasEllipsis }, className)}>
          {label}
        </span>
      )}
      {!label && ''}
    </>
  )
}

export default Tag
