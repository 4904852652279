import React from 'react'
import cls from 'classnames'
import './AttributePanel.css'

const AttributePanel = ({ traitType, traitValue, traitTier, disclaimer, isBig }) => {
  return (
    <div className="attributePanel">
      {traitType && <p className="attributePanel__name text-ellipsis">{traitType}</p>}
      {traitValue && (
        <h5
          className={cls('attributePanel__value text-ellipsis', {
            'attributePanel__value--big': isBig,
          })}
        >
          {traitValue}
        </h5>
      )}
      {disclaimer && <h6 className="attributePanel__disclaimer text-ellipsis">{disclaimer}</h6>}
      {traitTier && <div className="attributePanel__level-indicator">Tier {traitTier}</div>}
    </div>
  )
}

export default AttributePanel
