import React from 'react'
import BagWtf from '../../assets/Logo_02.svg'
// import MetamaskConnect from '../MetamaskConnect/MetamaskConnect'

const Header = ({ search }) => {
  const handleHome = () => {
    window.location.reload()
  }

  return (
    <div className="header">
      <div className="logoContainer" onClick={handleHome}>
        <img src={BagWtf} className="bagwtfLogo" alt="BagWtf Logo" />
      </div>
      {/* <MetamaskConnect /> */}
      {search}
    </div>
  )
}

export default Header
