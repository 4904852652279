import React from 'react'
import Tag from '../Tag/Tag'

const TraitCard = ({ trait }) => {
  return (
    <div className="landCard__category">
      <h6 className="attributePanel__name">{trait.traitType}</h6>
      <div className="landCard__category-values">
        <Tag label={trait.values.value} hasEllipsis={true} />
        {trait.values.tier && <Tag label={trait.values.tier} hasEllipsis={true} />}
      </div>
    </div>
  )
}

export default TraitCard
