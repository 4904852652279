import React from 'react'
// import weth from "../assets/weth.png";
import OSlogo from '../../assets/OSlogo.png'
import './LandCard.css'
import { categoriseTraits } from '../../helpers/categoriseTraits'
import TraitCard from '../TraitsCard/TraitsCard'

const LandCard = ({ id, name, traits, image, permalink, onClickHandler }) => {
  const categorisedTraits = categoriseTraits(traits)

  let cardTraits = []
  Object.keys(categorisedTraits).forEach((key) => {
    cardTraits.push({ traitType: key, values: categorisedTraits[key] })
  })

  return (
    <div className="landCard">
      <div className="landCard__image" onClick={onClickHandler}>
        <img src={image} alt="" />
      </div>
      <div className="landCard__details">
        <div className="name">
          {name} <div className="id">#{id}</div>
        </div>

        {cardTraits.map((item, index) => {
          return <TraitCard trait={item} key={index} />
        })}

        <a href={permalink}>
          <img src={OSlogo} className="osLogo inline" alt="" /> <span className="osText">View on OpenSea</span>
        </a>
      </div>
    </div>
  )
}
export default LandCard
