import React from 'react'
import LandCard from '../LandCard/LandCard'
import './LandList.css'

const LandList = ({ listData, setSelectedNft }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <div className="list">
      {listData.map((nft) => (
        <div key={nft.tokenId} onClick={() => setSelectedNft(nft)}>
          <LandCard
            key={nft.tokenId}
            id={nft.tokenId}
            name={nft.name}
            traits={nft.traits}
            image={nft.imgUrl}
            permalink={nft.openseaUrl}
            onClickHandler={scrollToTop}
          />
        </div>
      ))}
    </div>
  )
}
export default LandList
