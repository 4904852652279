import React from 'react'
import DashboardPanel from '../DashboardPanel/DashboardPanel'
import LandOwnerCard from '../LandOwnerCard/LandOwnerCard'
import './Dashboard.css'

const Dashboard = ({ aggregatedData, accountData }) => {
  return (
    <div className="dashboard">
      {/* {console.log("aggregatedData", aggregatedData)} */}
      <div className="dashboardBlock">
        <LandOwnerCard owner={accountData[0]?.owner} className={'dashboardOwnerCard'} />
      </div>
      {Object.keys(aggregatedData).map((traitList, i) => {
        return (
          <div key={i} className="dashboardBlock">
            <div className="innerDashboardBlock">
              <DashboardPanel traitType={traitList} traitValues={aggregatedData[traitList]}></DashboardPanel>
            </div>
          </div>
        )
      })}

      {/* <br></br>
      <span>Artifacts -</span>
      {aggregatedData["Artifact"] &&
        aggregatedData["Artifact"].map((artifact) => {
          return <span key={artifact}>{artifact}, </span>;
        })} */}

      {/* <br></br> */}
      {/* <span>Sediments -</span>
      {aggregatedData["Sediment"] &&
        aggregatedData["Sediment"].map((sediment) => {
          return <span key={sediment}>{sediment}, </span>;
        })} */}

      {/* <div className="resources"></div> */}
    </div>
  )
}

export default Dashboard

// traitList.includes("Koda") ? (
// aggregatedData["Koda"].map((koda) => {
//   console.log("kodas", aggregatedData["Koda"]);
//   return (
//     <AttributePanel
//       traitType="Koda IDs"
//       traitValue={`#${koda} `}
//        isBig={true}
//     />
//   );
// })
//   <></>
// ) : (
