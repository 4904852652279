/**
 * Checks if the given string is an address
 *
 * @method isAddress
 * @param {String} address the given HEX adress
 * @return {Boolean}
 */
export const isAddress = function (address) {
  const addressLowercase = address.toLowerCase();
  if (!/^(0x)?[0-9a-f]{40}$/i.test(addressLowercase)) {
    // check if it has the basic requirements of an address
    return false;
  } else if (
    /^(0x)?[0-9a-f]{40}$/.test(addressLowercase) ||
    /^(0x)?[0-9A-F]{40}$/.test(addressLowercase)
  ) {
    // If it's all small caps or all all caps, return true
    return true;
  }
};
