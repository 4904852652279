import React from 'react'
import './LandOwnerCard.css'
import Tag from '../Tag/Tag'
import cls from 'classnames'
import { truncateAddress } from '../../helpers/utils'
import CopyToClipboard from 'react-copy-to-clipboard'

const LandOwnerCard = ({ owner, permalink, className }) => {
  return (
    <div className={cls('landOwnerCard', className)}>
      <p className="attributePanel__name">Owner</p>
      {owner && owner.address && (
        <>
          <div className="landOwnerCard__image">
            <img
              src={
                // owner?.profile_img_url ||
                'https://pbs.twimg.com/profile_images/1580260320848773144/sBA-yy3r_400x400.jpg'
              }
              alt=""
            />
          </div>
          <p className="landOwnerCard__user">{owner?.user?.username ? owner?.user?.username : '-'}</p>
          {owner.address && (
            <CopyToClipboard text={owner.address || ''}>
              <span className="clickable">
                <Tag label={truncateAddress(owner.address)} isLong={true} />
              </span>
            </CopyToClipboard>
          )}
          {permalink && (
            <div className="landOwnerCard__os-link">
              <a href={permalink} target="_blank" rel="noreferrer">
                View on OpenSea
              </a>
            </div>
          )}
          <a href="https://swap.kiwi" className="kiwiButton">
            Swap your land on swap.kiwi
          </a>
        </>
      )}
    </div>
  )
}

export default LandOwnerCard
