const clothing = {
  "Starry Beetle Cloak": 61,
  "Degenerates Cape": 41,
  "Oozeguardian Armor": 91,
  "Fanged Cape": 64,
  "Brawlers Outfit": 78,
  "Bladed Carapace Armor": 42,
  "Unknown Beast Armor": 43,
  "Hot Rod Bod": 36,
  Direplate: 34,
  "Grassland Armor": 99,
  "Grimace Armor": 33,
  "Butchers Belt": 79,
  "Wasterider Pauldrons": 95,
  Gutterplate: 30,
  "Cobalt Squad Belt": 91,
  "Wanderer Cape": 95,
  "Elite Crimson Armor": 73,
  "Minor Light Armor": 80,
  "Cape Of Eternal Burning": 40,
  "Celestial Crystal Armor": 65,
  "Sovereign Outfit": 61,
  "Icicle Shard Armor": 31,
  "Sol Mantle": 38,
  "Mace Plate": 41,
  "Toasted Mantle Armor": 37,
  "Petrified Bands": 83,
  "Eternal Drain Armor": 17,
  Infiltrator: 45,
  "Planet Suitor": 35,
  "Leader Plate": 38,
  "Fun Guy Tunic": 31,
  "Reinforced Mallow Armor": 43,
  "Explorers Outfit": 80,
  "Nomad Armor": 79,
  "Ogre Armor": 69,
  "Knight Mare Armor": 76,
  "Glutton Cape": 79,
  "Gemstone Paladin": 85,
  "Heavy Iron Armor": 92,
  "Pluma Pauldrons": 44,
  "Desola Armor": 37,
  "Deaths Call Armor": 29,
  "Skull Strapped Armor": 38,
  "Bubble Cape": 16,
  Angelic: 28,
  "Terra Light Armor": 37,
  "Sky Merchant": 37,
  "Tattered Cape": 28,
  "Cape Of Beast Instincts": 29,
  "Ice Crystal Armor": 17,
  "Arcana Outfit": 59,
  "Jaded Gnaw Armor": 67,
  "Barely There Armor": 85,
  "Hardened Calcite Armor": 58,
  "Boiled Armor": 40,
  "Armor Of Carnage": 60,
  "Daybreak Armor": 37,
  "Tough Armor": 54,
  "Chrono Plate Armor": 46,
  "Feather Mantle": 42,
  "Beetle Shell": 37,
  "Classic Mail Armor": 95,
  "Champion Sash": 33,
  "Stone Plate Armor": 76,
  "Leafy Top": 20,
  "Hardened Leather Armor": 71,
  "Ascended Guard Armor": 74,
  "Coastal Scavenger": 64,
  "Ancient Chief": 44,
  "Enoki Cape": 45,
  "Golden Caesars Dressing": 45,
  "All Seeing Pauldron": 42,
  "Warriors Journey Garb": 62,
  "Light Foliage Armor": 27,
  Duster: 78,
  "Spike Plate": 37,
  "Summoners Stole": 65,
  "Ocular Hydra Armor": 37,
  "Ape Cape": 67,
  "Cloak Of Knowing": 36,
  "Gilly Cloth": 33,
  "Neon Streaked Cloak": 34,
  "Ravenmore Armor": 41,
  "Outerplane Toga": 66,
  "Shrine Cloak": 36,
  "Executioners Uniform": 36,
  "Ancient Orb Armor": 37,
  "Organic Armor": 42,
  Adventured: 31,
  "Jeweled Adornment": 51,
  "Crystal Light Armor": 73,
  "Battered Armor": 74,
  "Basic Smock": 77,
  "Adventurer Mantle": 85,
  "Vortex Armor": 62,
  "Travel Outfit": 72,
  "Pyrite Armor": 45,
  "Wildlands Armor": 31,
  "Deep Sea Spiked Armor": 65,
  "Gloopmire Cape": 24,
  Orchestrated: 47,
  Skeletarmour: 23,
  "Furnace Mantle": 25,
  "Reinforced Mantle": 81,
  "Furnace Armor": 43,
  "Cauldron Complete": 33,
  "Skurts Mantle": 85,
  "Plateau Seer": 25,
  "Temple Rot Armor": 26,
  Geometric: 21,
  "Wasteland Explorer": 71,
  "Consummation Plate": 32,
  "Wasteland Spiked Armor": 31,
  "Heavy Mantle": 28,
  "Sludge Grime Cape": 28,
  "Entangled Darkness": 44,
  "Shrine Bandolier": 44,
  "Ocean Tunic": 44,
  "Badlands Elite Armor": 72,
  "Indigo Cape": 38,
  "Scouts Honor": 25,
  "Sky Seeker": 21,
  "Frost Fur Cape": 47,
  "Thorny Armor": 33,
  "Polished Marrow Armor": 34,
  "Plateau Explorer": 25,
  "Undergrowth Robe": 18,
  "Wind Shaman": 36,
  "Charred Mallow Cape": 28,
  Wingsect: 28,
  "Carapace Armor": 42,
  "Coral Core Armor": 21,
  "Wasteland Elite Armor": 30,
  "Bark Moss Armor": 36,
  "Monster Mesh": 27,
  "Shelly Pauldrons": 35,
  "Gunk Thread": 30,
  "Malachite Armor": 25,
  "Belt Of All Seeing": 41,
  "Flame Licked Armor": 18,
  "Dance Of Petals": 44,
  "Furnace Commander": 23,
  "Pileas Gown": 19,
  "Chaos Avenger": 1,
  "Chaos Soldier": 1,
  "Chaos Gunner": 1,
};

const head = {
  "Pink Fluff": 31,
  "Crystalline Helm": 56,
  Arachnid: 139,
  "X Ray": 59,
  Hoot: 150,
  "Horned Lion": 80,
  "Dawn Disc": 282,
  "Demon Spawn": 160,
  "Tested Elder": 113,
  "Chomp Chomp": 215,
  "Jade Carapace": 224,
  "Tri Vent": 293,
  "Great Kleos": 77,
  "Blue Crown": 166,
  "Drill Sergeant": 235,
  Swordfish: 38,
  "Green Gill": 83,
  "Coral Delight": 62,
  "Keep It Chill": 193,
  "Crystal Horned": 95,
  "Somethings Fishy": 72,
  "Blossom Bud": 253,
  "Cracked Horn": 170,
  "Infinite Vision": 164,
  Foreteller: 208,
  "Loggin Noggin": 87,
  "Cobalt Kudu": 65,
  "Viridescent Hero": 167,
  Springbuck: 132,
  "Stretched Thin": 57,
  "Copper Coil": 91,
  "All Ears": 76,
  "Blazin Hot": 88,
  "Spike Cauldron": 130,
  "Fish Bowl": 122,
  "Devils Advocate": 195,
  "Cave Crystal": 114,
  "Sturdy Helm": 75,
  "Twisty Horn": 253,
  Scraggly: 112,
  "Skull King": 112,
  "Deep Sea": 45,
  "Take Flight": 52,
  "Winged Cheese": 173,
  Mummified: 69,
  "Bleached Horns": 354,
  Screwhorn: 139,
  "Sticks No Stones": 92,
  "Muddy Explorer": 151,
  "Scorched Brain": 96,
  "Goliath Bull": 30,
  "Spiked Wyvern": 108,
  "Dual Slick": 60,
  "Drill Bit Droid": 228,
  "Nautilus Trench": 50,
  "Vertical Vision": 86,
  "Reef Dragon": 113,
  "Fairy Wisps": 93,
  "Stubborn Ox": 134,
  "Manta Ray": 129,
  "John Doe": 337,
  "Creepy Crawly": 141,
  "Sun Goop": 121,
  "Winged Fiend": 203,
  "Geo Stag": 66,
  "Open Wide": 19,
  Sluggy: 81,
  "Bulls Eye": 28,
  "Aqua Elf": 86,
  "Another Memory": 117,
  Sunkissed: 144,
  "Natures Platter": 47,
  Skully: 10,
  "Elder Bharal": 96,
  "Nuts For Nuts": 109,
  "Beacon of Light": 14,
  Droopy: 61,
  "Fuzzy Dweller": 18,
  Impalas: 32,
  "Axe Grinder": 22,
  "Octo Tentaclenotinfinalcollection": 40,
  Bugle_Horn: 22,
  "All Seeing Maw": 12,
  "Woodland King": 23,
  "Batty Spiked": 9,
  "Levitating Lava": 6,
  Blockhead: 1,
  "Moss Boss": 1,
  "Eye Cid": 1,
  "Glimmer Sprite": 1,
  "Slime Time": 1,
  "Grime Time": 1,
  "Bubble Boil": 1,
  "Emperor Ice": 1,
  "Rough Puff": 1,
  "Goo Goo": 1,
  Aweful: 1,
  Commander: 1,
  "Hot Head": 1,
  "Caustic Visage": 1,
  Mallowrium: 1,
  Incandessence: 1,
  "Spike Stag": 1,
  "Crimson Stag Beetle": 1,
  Mushyanna: 1,
  "Blossom Top": 1,
  "Petulant Belle": 1,
  "Temple Growth": 1,
  "Runic Commander": 1,
  "High Ruins Priest": 1,
  Glistener: 1,
  "Medulas Whisper": 1,
  "Prism Visage": 1,
  "Gemmo Dome": 1,
  "Glimmer Top": 1,
  "King Red": 1,
  "High Collared": 1,
  "Red Wizard": 1,
  Jellicles: 1,
  Archshroom: 1,
  "Fungi Forest": 1,
  Bloom: 1,
  "Plant Tank": 1,
  "Turitella Dome": 1,
  Bristled: 1,
  "Hillside Grazer": 1,
  "Shaggy Horns": 1,
  "Dark Orbit": 1,
  "Caustic Blush": 1,
  "Quiet Beast": 1,
  "Rhino Beetle": 1,
  "Lackian Helm": 1,
  "Spindle Dome": 1,
  "Grey Horn": 1,
  "Pomped Out": 1,
  "Grey Seeker": 1,
  "Clifftop Nest": 1,
  "Ravens Approach": 1,
  Fluid: 1,
  Cinderflow: 1,
  "Lazuli Nebula": 1,
  "The Rising Sun": 1,
  "Omni Lapis": 1,
  "Cracked Pilot": 1,
  Epiphyte: 1,
  "Flora Display": 1,
  "Whimsy Wisps": 1,
  "Shrine Seer": 1,
  "Arid Elder": 1,
  "High Wonder": 1,
  "Billy Brayer": 1,
  Regal: 1,
  "Leader of The Pack": 1,
  "All Knowing Naru": 1,
  "Ivory Tusk": 1,
  "Hollowed Horns": 1,
  "Shama Horns": 1,
  "Shrine Mentor": 1,
  "Hella Flagella": 1,
  "Shrine Guardian": 1,
  "Arid Trotter": 1,
  "Gentle Grazer": 1,
  "Badland Rover": 1,
  "Skeleton Chief": 1,
  "Pearl Spires of Dread": 1,
  "Absentia Mind": 1,
  "Plant Life": 1,
  "Bone Dry Heretic": 1,
  Elysion: 1,
  "Echo Locator": 1,
  "Night Lies": 1,
  "The Ram": 1,
  "Ruined Senses": 1,
  "Temple Sky": 1,
  "Bog Head": 1,
  Mallowfied: 1,
  Tazor: 1,
  "Mech Mouse": 1,
  "Cyber Mind": 1,
  "Geiser Dome": 1,
  "Elder Tree": 1,
  "Glo Jo": 1,
};

const eyes = {
  "Sewed Wide Shut": 46,
  Tricemore: 21,
  "Late Night": 38,
  "Pink Staredown": 34,
  "Dark Matters": 18,
  Veggie: 67,
  Furious: 77,
  Winged: 40,
  "Here And Everywhere": 60,
  Dryclops: 17,
  "Cave Fright": 42,
  "Grim Bellows": 34,
  Insomniac: 61,
  Villainous: 61,
  "Peace And Love": 48,
  Catty: 55,
  "Aquatic Ogle": 21,
  Twins: 54,
  Crusty: 67,
  "Dual Pits": 90,
  "Jelly Beans": 73,
  Tired: 77,
  Sad: 71,
  "Cave Diving": 64,
  "Mycology Eyes": 31,
  "Purple Flame": 57,
  Tearpaint: 71,
  "Eye Of Glob": 37,
  Steely: 74,
  "Third Eye": 53,
  Intense: 64,
  Speechless: 65,
  "Look Deep": 71,
  Hypcylco: 57,
  Casper: 72,
  Chameleo: 47,
  Judger: 17,
  "Peer Through Sky": 12,
  Droopy: 90,
  "Release Me": 64,
  Maze: 73,
  "Coin Slots": 86,
  Readers: 34,
  "Web Crawler": 61,
  "Ocular Drive": 50,
  "Simple Math": 54,
  "Peer Review": 71,
  "Crag Glow": 70,
  Emotional: 79,
  "Slimey Terror": 42,
  Bloodshot: 67,
  "Unistone Green": 29,
  "Worry Wart": 74,
  "Green Glance": 38,
  "Bulls Eye": 66,
  Sleepy: 78,
  "Triple Threat": 60,
  "Teething Eye": 66,
  "Stay Awake": 70,
  Stubborn: 56,
  "Oval Ancients": 27,
  Omniscient: 77,
  "Glacia Glare": 30,
  "Peek Curiosity": 62,
  "Crystal Grinder": 51,
  "Buttoned Up": 71,
  "Open Up": 40,
  "Two Tone": 68,
  "Gemini Gaze": 34,
  Tiny: 51,
  "Bugged Out": 56,
  Wormy: 72,
  "Intense Focus": 58,
  Amphiboid: 78,
  "Snake Eyes": 75,
  "Dimensional Sight": 45,
  Boney: 67,
  Afraid: 80,
  Angry: 52,
  Smile: 67,
  Mysterious: 66,
  Caged: 77,
  "Cyber Sight": 73,
  "Moon Pilot": 17,
  Trance: 57,
  Lizard: 58,
  Infrared: 20,
  "All Seeing Ooze": 29,
  Harpy: 57,
  "Swamp Secrets": 24,
  "Twenty Cubed": 71,
  "I See You": 65,
  "Spider Trance": 27,
  "Tri Green": 54,
  "On The Edge": 17,
  "Black Hole": 72,
  "Stare Down": 69,
  "Adorable Intake": 35,
  Reptilian: 47,
  Arachnid: 74,
  "Dual Time": 73,
  "Twin Pits": 73,
  "Genis Gaze": 57,
  Squiggles: 27,
  "Fluff Vision": 41,
  "Five Eye": 57,
  Analyze: 72,
  "Is Anyone Home": 53,
  "All Knowing Ahh": 21,
  "Avian Insight": 29,
  "Empty Stare": 40,
  "Fierce Adventurer": 32,
  Boomerang: 65,
  Petrified: 54,
  "Seeing Two": 63,
  "Psychic Stare": 42,
  "Glimmer Of Hope": 39,
  "Forlorn Fuzz": 36,
  Tripartite: 80,
  "Earnest Caps": 29,
  Eyevestigation: 29,
  Elemental: 59,
  "Ice Cage": 27,
  "Dry Stare": 22,
  "Veiled Look": 21,
  "Shocked Blue": 71,
  Mesmerize: 45,
  "Golden Grill": 29,
  Fortune: 66,
  "Deep Focus": 75,
  Birdlike: 31,
  Ancient: 53,
  "Jungle Eh": 31,
  "Over It": 69,
  "Uni Eyes": 71,
  "Ruby Red": 18,
  "See It All": 58,
  Mirestare: 33,
  Beady: 34,
  "Glazey Gaze": 31,
  "Lookout Below": 62,
  Beaky: 18,
  "Arid Stare": 16,
  "The Gates": 27,
  Toxic: 57,
  "Tri Stare": 54,
  "True Blue": 61,
  Staredown: 31,
  "Burn On Sight": 21,
  "Through Your Soul": 38,
  "Melted Gaze": 25,
  "Furnace Burn": 33,
  Eternal: 29,
  "Jungle Gem": 22,
  Carapace: 70,
  "Deep Stares": 49,
  Hydrothermal: 31,
  "Calcified Pair": 39,
  "Eyes On You": 60,
  "Peeker Orbs": 42,
  "All Seeing": 58,
  "Into Your Zone": 21,
  "Rock Bottom": 16,
  "Peep Holes": 35,
  "Hypnotic Groove": 33,
  "Bone Sneak": 28,
  "Are You There": 31,
  Frigid: 51,
  "Globular Gaze": 23,
  Squiggly: 58,
  Extraterrestrial: 52,
  "Jungle Ah": 26,
  "Melted Gold": 33,
  "Shattered Cyclop": 37,
  "Fuzzy Lookers": 23,
  "Special Guest": 30,
  Crystalline: 59,
  "Cave Glow": 43,
  "Smore Smudge": 17,
  Lookalike: 60,
  Exhausted: 34,
  "Steppe See": 18,
  "Cutie Pies": 64,
  Owly: 56,
  "Toil And Rubble": 19,
  "Clifftop Sigh": 37,
  "Stink Eye": 76,
  "Four Eyes": 22,
  Stoney: 45,
  "Darkened Foresight": 19,
  Skylight: 32,
  Gloomy: 24,
  "Terra Glare": 33,
  "Pinball Attention": 25,
  "Red Soaked Stare": 23,
  "Sky Seeker": 32,
  "Every Which Way": 27,
  "Awakened Entertainer": 24,
  "Sullen Glance": 12,
  "Interconnected Flow": 23,
  "Slick Seer": 19,
  "Solar Focus": 31,
  "Blob Eye": 16,
  Circular: 17,
  "Light Visor": 1,
  "Galactic Gaze": 1,
  "Coiled Up": 1,
  "Jack 'O Waste": 38,
};

const core = {
  Candy: 21,
  "Caged Geminite": 172,
  "Onyx Doom": 533,
  "X Ray": 178,
  "Koda Bear": 276,
  "Bubble And Toil": 253,
  Husk: 112,
  "Lava Lamp": 357,
  Amoebatopia: 259,
  "Carpet Gorilla": 47,
  "Tide Roller": 119,
  "Ivy Swirls": 54,
  "Woodland Guardian": 195,
  Hypnotic: 151,
  "Monster Smoke": 136,
  Skeletron: 115,
  "Berry Bomb": 83,
  Whirlpool: 95,
  "Tranquil Water": 104,
  "Desert Bot": 374,
  Islander: 87,
  "Banana Wrapped": 114,
  Woodland: 202,
  "All Sight": 242,
  "All Smiles": 98,
  Zebro: 263,
  "Pure Sinew": 125,
  "Secret Ruins": 87,
  Spotty: 244,
  Orangetan: 50,
  "Pink Disco": 101,
  "Floating Lava": 72,
  Stonebase: 148,
  "Aqua Gorilla": 115,
  "8 Knit": 252,
  "Stoney Pecs": 195,
  "Grey Matter": 201,
  Cushioned: 248,
  "Storm Tracks": 183,
  "Magma Runes": 167,
  "Flora Smock": 114,
  "Purple Disco Monster": 221,
  Petrified: 264,
  "Leaf Me Alone": 119,
  Patches: 89,
  "Barnacle Buddy": 42,
  Globetrotter: 65,
  "Stone Hole Jackson": 141,
  "Space Blob": 79,
  "Dino Egg": 135,
  Spacesuit: 168,
  "Swamp Monster": 36,
  Sunflare: 104,
  "All Bite": 282,
  "Pumpkin Teeth": 48,
  "Mummy Wrap": 182,
  "Eye Sore": 25,
  "Woodland Wanderer": 48,
  "Spirit Wisps": 86,
  Kaktus: 76,
  "Pink Bear": 50,
  Vivarium: 91,
  Wrinkles: 40,
  "Swamp Bucket": 40,
  Maw: 82,
  "Inside Out": 21,
  Fuschiacron: 24,
  "Green Thumb": 17,
  Basalt: 36,
  "Monster Ball": 8,
  "Bog Water": 1,
  "Third Eye": 1,
  Luminasaur: 1,
  Flan: 1,
  "Jelly Belly": 1,
  Bloatus: 1,
  Snowmelt: 1,
  Gooey: 1,
  Grooze: 1,
  Dissolve: 1,
  Doubloon: 1,
  Bellystone: 1,
  "Geyser Arms": 1,
  "Molten Melt": 1,
  Glowsaurus: 1,
  "Molten Core": 1,
  "Grime Shell": 1,
  Reef: 1,
  Glimwrap: 1,
  "Sorrow Spore": 1,
  "Ancient Shell": 1,
  Spelunker: 1,
  "Ancient Watcher": 1,
  Threadbear: 1,
  Biolumey: 1,
  "Prism Prison": 1,
  "Amethyst Skin": 1,
  "Cavernous Loot": 1,
  "Red Button": 1,
  "Crackled Lava": 1,
  "Red Soil Seep": 1,
  "Cumulus Blue": 1,
  Polypore: 1,
  Rhodactiskin: 1,
  Dermatosis: 1,
  "Anenome Organs": 1,
  "Gilly Guts": 1,
  "Patchy Fur": 1,
  "Free The Bristle": 1,
  "Aqua Fleece": 1,
  "Black Thorn": 1,
  Transcendence: 1,
  "Ancient Bark": 1,
  "Shadow Scales": 1,
  "Petrichor Explorer": 1,
  "Tavo River": 1,
  "Grey Pelt": 1,
  "Grey Belly": 1,
  "Grey Fuzzy": 1,
  Midnight: 2,
  "Tazer Droplets": 1,
  "Glowing Embers": 1,
  "Sky Myth": 1,
  "Deep Space": 1,
  "Night Sky": 1,
  "Pressure Crack": 1,
  "Prolific Growth": 1,
  "Jungle Brewing": 1,
  "Climbing Dusk": 1,
  Enshrined: 1,
  "Terra Welder": 1,
  "Mid Knight": 1,
  "Golden Hide": 1,
  "24k Thoracic": 1,
  Groundswell: 1,
  "Forest Secrets": 1,
  Crackle: 1,
  "Hollow Belly": 1,
  "Mountain Tested": 1,
  Gorillatron: 1,
  Glowbulb: 1,
  "Spirit Slab": 1,
  Carcussed: 1,
  "Root Believer": 1,
  "Stone Shard": 1,
  "Terra Former": 1,
  "Sharp Of Night": 1,
  "Ruined Skin": 1,
  Undergrowth: 1,
  "Terra Builder": 1,
  Ordyceptus: 1,
  "Deviled Belly": 1,
  "Grasp Of Shadows": 1,
  "Magma Plate": 1,
  "Dilapidated Presence": 1,
  "Aqua Toshi": 1,
  "Bog Wood": 1,
  Smore: 1,
  Kilowatt: 1,
  "Static Enigma": 1,
  "Dyna Volt": 1,
  "Sink Hole": 1,
  "Mossy Bod": 1,
  Radiadon: 1,
};

const weapon = {
  "The Watchers Eye": 14,
  "Shards of Iridia": 8,
  "Ravens Bite": 4,
  "Jaws of Evercrushing": 5,
  "Mush Clobberer": 10,
  Bamboospear: 34,
  "Skulds Renown": 16,
  "Abyssal Trident": 34,
  "Never Bokken": 23,
  "Feathered Glaive": 39,
  "Oracles Hammer": 30,
  "Arc Wielder": 27,
  "The Clobbler": 27,
  Silvertongue: 41,
  "Bramble Thumper": 19,
  "Tongue Tearer": 12,
  "Ox Tongue Blade": 27,
  "Undergrowth Stick": 27,
  "Staff of Wild Things": 36,
  "Dual Scythe": 9,
  "Hallows Crescent": 35,
  "Coral Clubber": 17,
  "Widow Taker": 39,
  "Occams Razor": 29,
  "Scavengers Blade": 49,
  "Mighty Quill": 12,
  "Gargantic Hammer": 19,
  Wavebreaker: 20,
  "Studded Club": 38,
  "The Bloodletter": 49,
  "Staff of Sentience": 14,
  "Electro Grip": 11,
  "Green Machete": 13,
  "Azure Fangs": 17,
  "Axe of Descent": 19,
  "Bad Mama Jama": 35,
  "Gallows Scape": 28,
  "Spade Blade": 17,
  "Crimson Edge": 17,
  "Truth Teller": 17,
  "Pickles Pike": 51,
  "Badlands Machete": 32,
  Shimmer_Blade: 17,
  "Cavern Haunter": 18,
  Doomseeker: 15,
  "Batter Up": 11,
  "Gusty Spear": 15,
  "Crackle Stick": 15,
  "Battle Axe of Loathing": 24,
  "Goop Fork": 19,
  "Iron Flail": 30,
  "Wise Wand": 11,
  "Shoo Flame": 13,
  "Executioners Delight": 37,
  "Sword of Alinta": 8,
  "Iron Wing": 39,
  "Deceiver Staff": 18,
  "Dual Eclipse": 23,
  "Spinal Whip": 7,
  "Guardians Hand": 21,
  "Cat A Comb": 34,
  "The Tenderizer": 10,
  "Brigids Fury": 12,
  "Sheer Face Claymore": 23,
  "Sticky Bow": 11,
  "Stygias Fang": 21,
  "Wood Ear Chopper": 6,
  "Column Cleaver": 16,
  "Goop Slicer": 18,
  "Absentia Club": 27,
  Terrachete: 14,
  "Staff of Confessing": 41,
  "The Claw": 10,
  "Fancy Pickaxe": 27,
  "Adventurer Bow": 9,
  Traditor: 13,
  "Spike In The Head": 13,
  Sabrechomp: 19,
  "Winged Axe": 10,
  "The Spatterer": 21,
  "Rib Spreader": 17,
  "Gaze of Mysterius": 10,
  "Bubble Blower": 9,
  "Sword of The Horde": 37,
  "Dread All": 44,
  Vivecsection: 14,
  "Serpents Hand": 27,
  "Sulfur Triton": 13,
  "Apothecarys Secret": 28,
  "Axe of Anguish": 43,
  "Gnaw Saw": 10,
  "Shaved Ice": 18,
  "Petri Spikes": 16,
  "Hunters Respite": 31,
  "Twistree Blade": 9,
  "Highwind Wand": 22,
  Unraveler: 34,
  "Lightning Rod": 22,
  "The Peeler": 31,
  "Apprentices Staff": 22,
  "Death Head": 32,
  "Bone Hook": 7,
  "Glimmer Staff": 18,
  Scisword: 28,
  "Golden Wand": 14,
  "Synthesis Blade": 28,
  "Sword of Constant Sorrow": 33,
  "Dark Axesaw": 13,
  "Twin Shadows": 17,
  "Orbius Axe": 12,
  "Demon Catcher": 13,
  "Skull Flail": 22,
  "Orbius Glave": 18,
  "Glow Up Staff": 16,
  "Undulate Staff": 8,
  "Carapace Claws": 12,
  "Everlights Wand": 10,
  Smoozitar: 15,
  Blasphemy: 4,
  "Slimey Sword": 12,
  "Underworld Spine": 9,
  "Magnolio Rod": 17,
  "Ethereal Spear": 24,
  "Trusty Hatchet": 40,
  "Lights Edge": 9,
  "Fickle Sickle": 28,
  "Brimstone Blade": 17,
  "Slicer Glaive": 19,
  "Explored Blade": 9,
  "Grazing Wand": 6,
  "Mortal Arrow": 46,
  "Crystal Stabber": 10,
  "Spiny Staff": 15,
  "Shadowed Axe": 5,
  "The Axicle": 15,
  Confraction: 15,
  "Night Star": 18,
  "Malfunction Rod": 17,
  "Blossoming Staff": 20,
  "Conch Beater": 15,
  "Urchin Flail": 18,
  "Gemmo Hammeraxe": 19,
  "Gunk Blade": 6,
  "Orbius Staff": 12,
  Betrayer: 7,
  "Fungus Whip": 12,
  Omincidel: 20,
  "Blade of Great Heights": 9,
  "Skull Staff": 16,
  Cactivorpal: 9,
  "Comet Piercer": 21,
  "Sulfur Striker": 3,
  "Coils of Vengeance": 1,
  Truestrike: 1,
  "Cosmic Hammer": 1,
  "Adventurer's Sword": 32,
};

const sortedWeapons = Object.fromEntries(
  Object.entries(weapon).sort(([, b], [, a]) => a - b)
);
const sortedEyes = Object.fromEntries(
  Object.entries(eyes).sort(([, b], [, a]) => a - b)
);
const sortedCore = Object.fromEntries(
  Object.entries(core).sort(([, b], [, a]) => a - b)
);
const sortedHead = Object.fromEntries(
  Object.entries(head).sort(([, b], [, a]) => a - b)
);
const sortedClothing = Object.fromEntries(
  Object.entries(clothing).sort(([, b], [, a]) => a - b)
);

const traitsObject = {
  Weapon: { ...sortedWeapons },
  Eyes: { ...sortedEyes },
  Core: { ...sortedCore },
  Head: { ...sortedHead },
  Clothing: { ...sortedClothing },
};

export default traitsObject;
