import { categoriseTraits } from "./categoriseTraits";

export const getAggregatedData = (nftList) => {
  let aggregatedData = [];
  const allNFTtraitList = nftList.map((nft) => {
    return categoriseTraits(nft.traits);
  });

  allNFTtraitList.map((traits) => {
    for (const traitType in traits) {
      if (aggregatedData.hasOwnProperty(traitType)) {
        aggregatedData[traitType].push(traits[traitType].value);
      } else {
        aggregatedData[traitType] = [];
        aggregatedData[traitType].push(traits[traitType].value);
      }
    }
  });

  return aggregatedData;
};
