import './App.css'
import SearchBar from './components/SearchBar/SearchBar'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import './components//Header/Header.css'
import { useFilteredNFTs } from './hooks/filteredList'
import React, { useState, useEffect } from 'react'
import LandList from './components/LandList/LandList'
import Dashboard from './components/Dashboard/Dashboard'
import Main from './components/Main/Main'
import { getAggregatedData } from './helpers/getAggregatedData'
// import { kodas } from './kodas/Kodas'
// import KodaCard from './components/KodaCard/KodaCard'
// import { useWeb3React } from '@web3-react/core'

function App() {
  const [selectedNft, setSelectedNft] = useState(0)
  // let { account } = useWeb3React()
  const [aggregatedData, setAggregatedData] = useState([])
  const [searchResponse, setSearchResponse] = useState([])
  const [searchResponseText, setSearchResponseText] = useState('')
  const { setSearchText, filteredList } = useFilteredNFTs(searchResponse) // not used now, but can be used for client side search after the api search results are back
  const dataToShow = searchResponse

  // for testing purposes set this account to see the os query working
  // account = '0x7761a4b62d9f2b3165644577637c85f4f6b7e701'

  useEffect(() => {
    if (dataToShow.length > 1) setAggregatedData(getAggregatedData(dataToShow))
  }, [dataToShow])

  useEffect(() => {
    setSelectedNft(0)
  }, [searchResponse])

  return (
    <div className="app">
      <Header
        search={
          <SearchBar
            onChange={setSearchText}
            setSearchResponse={setSearchResponse}
            setSearchResponseText={setSearchResponseText}
          />
        }
      />

      <p className="searchResultsText">{searchResponseText}</p>
      {searchResponse.length > 0 && (
        <>
          <Main listData={dataToShow} selectedNft={selectedNft} setSelectedNft={setSelectedNft} />

          {dataToShow.length > 1 && dataToShow.length < 100 && (
            <Dashboard aggregatedData={aggregatedData} accountData={dataToShow} />
          )}

          <LandList listData={dataToShow} setSelectedNft={setSelectedNft} />
        </>
      )}

      <Footer></Footer>

      {/* {kodas.map((koda) => (
        <div key={koda.id} className="inline-block m-2 ">
          <KodaCard koda={koda} />
        </div>
      ))} */}
    </div>
  )
}

export default App
