export const categoriseTraits = (traits) => {
  let categorisedTraitList = []
  console.log('traits', traits)
  traits.map((trait) => {
    if (
      // ignore Koda traits, as we display them in the Koda info
      trait?.trait_type.includes('Koda?') ||
      trait?.trait_type.includes('Koda ID') ||
      trait?.trait_type.includes('Clothing') ||
      trait?.trait_type.includes('Weapon') ||
      trait?.trait_type.includes('Eyes') ||
      trait?.trait_type.includes('Core') ||
      trait?.trait_type.includes('Head') ||
      // ignore Plot number, as we already display it using the tokenId
      trait?.trait_type.includes('Plot') ||
      trait?.trait_type.includes('Artifact')
    )
      return

    if (trait?.trait_type.includes('Tier')) {
      categorisedTraitList[trait?.trait_type.replace(' Tier', '')] = {
        ...categorisedTraitList[trait?.trait_type.replace(' Tier', '')],
        tier: trait.value,
      }
    } else {
      categorisedTraitList[trait?.trait_type] = {
        ...categorisedTraitList[trait?.trait_type],
        value: trait.value,
      }
    }
  })

  console.log('categorisedTraitList', categorisedTraitList)
  return categorisedTraitList
}
