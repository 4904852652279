import React from 'react'
import twitterLogo from '../../assets/Owner/twitter.png'

const Footer = () => {
  return (
    <div className="footer">
      © 2023 niftyhorde GmbH{' '}
      <a href="https://twitter.com/swapkiwi">
        <img src={twitterLogo} className="osLogo inline" alt="" />
      </a>
    </div>
  )
}

export default Footer
