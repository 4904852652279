import React from 'react'
import traitsObject from '../../kodas/kodasSorted'
import './KodaCard.css'
import AttributePanel from '../AttributePanel/AttributePanel'

const getTraitRarity = (traitType, traitValue) => {
  return traitsObject[traitType][traitValue]
}

const KodaCard = ({ koda }) => {
  return (
    <div className="kodaCard">
      <div className="kodaCard__avatar">
        <img src={koda.image} alt="koda" />
      </div>
      <AttributePanel traitType="Koda ID" traitValue={`#${koda.id}`} isBig={true} />
      {koda?.attributes.map((trait, i) => (
        <AttributePanel
          key={i}
          traitType={trait.trait_type}
          traitValue={trait.value}
          disclaimer={`${getTraitRarity(trait.trait_type, trait.value)} Kodas have this trait`}
        />
      ))}
    </div>
  )
}

export default KodaCard
