import React, { useState, useEffect } from 'react'
import searchIcon from '../../assets/Header/search.png'
import { getNFTsFromOS } from '../../helpers/getNFTsFromOS'

const SearchBar = ({ onChange, setSearchResponse, setSearchResponseText }) => {
  const [searchQuery, setsearchQuery] = useState('')
  const [enterPressed, setEnterPressed] = useState(false)

  useEffect(() => {
    if (searchQuery !== '' && enterPressed) {
      const loadNFTs = async (searchQuery) => {
        try {
          const nfts = await getNFTsFromOS(searchQuery)
          setSearchResponse(nfts || [])
          setSearchResponseText(nfts && nfts.length > 0 ? '' : 'No results for this search')
        } catch (err) {
          setSearchResponse([])
          setSearchResponseText('No results for this search')
          console.error(err)
        }
      }
      loadNFTs(searchQuery)

      setEnterPressed(false)
    } else if (searchQuery === '' && enterPressed) {
      setSearchResponse([])
    }
  }, [searchQuery, enterPressed])

  const onChangeText = (e) => {
    onChange(e.target.value)
    setsearchQuery(e.target.value)
  }

  const isEnterPressed = (e) => {
    const isEnterPressed = e.key === 'Enter' || e.keyCode === 13 || e.which === 13
    if (isEnterPressed) {
      setEnterPressed(true)
    }
  }

  return (
    <div className="searchBar">
      <div className="searchIcon">
        <img src={searchIcon} alt="" />
      </div>
      <input
        placeholder="Search by Land ID or Wallet Address"
        className="searchInput"
        onChange={onChangeText}
        onKeyUp={isEnterPressed}
      />
      <button className="loginButton" onClick={() => setEnterPressed(true)}>
        Search
      </button>
    </div>
  )
}

export default SearchBar
