import { useEffect, useState } from 'react'

export const useFilteredNFTs = (initialList) => {
  const [searchText, setSearchText] = useState('')
  const [filteredList, setFilteredList] = useState([])

  useEffect(() => {
    if (!searchText || !initialList) return setFilteredList(initialList)

    const textToSearch = searchText.toLowerCase()
    const list1 = initialList.filter((nft) => nft?.tokenId?.toString().toLowerCase().startsWith(textToSearch))
    const list2 = initialList.filter((nft) => nft?.owner?.toString().toLowerCase().startsWith(textToSearch))
    setFilteredList(Array.from(new Set(list1.concat(list2))))
  }, [searchText, initialList])

  return {
    setSearchText,
    filteredList,
  }
}
