const kodas = [
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68be2758ac10ddebf36c95318e3189afe0dbe7ed34373102fe78b46085a68146.png',
    id: '0',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Shards of Iridia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63960f98d58826ab11c47679d347d5c9734544b900a1613fe67fff276868e45c.png',
    id: '1',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Ravens Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c473772544784436b7d7550ee9d5a117ebaef73e100adf8641ceacfb678e982.png',
    id: '2',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Jaws of Evercrushing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d23c0d59357e4cac151469715c369c27785b0dc8b12b88eb2e7c455ccf09a39f.png',
    id: '3',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Mush Clobberer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86ea2edd1de0ea3f0f024b840c5f9f96d0e17b47e5e2aec739f89f48bc0bdd8e.png',
    id: '4',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad64a682950f6c3f0a0dd70d00cf2b815de0aec8eb62250119783cb229fb34c0.png',
    id: '5',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41190a40c117b9b50efda77d0cc0fea30f7e1919cb13a3e916a2d149ba39f274.png',
    id: '6',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ac961d50434dcb0a15fc60e2e31809e756f01452a32ba89849c38e913cbf038.png',
    id: '7',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a335834c7b5b7ee6bdd490878eec057f85c0baa9132f2484049df9a8bf9e366.png',
    id: '8',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/246116ea482a2923e0349f22060af31242fdd4fa5dfc41a82111a89208ee63f2.png',
    id: '9',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b2de68b1bb4bf2ba91d509efa00d0221e6dbafe16b38410c92d96d67431c434.png',
    id: '10',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc148b7fb3e4587300be3ec359ff199049b22f0e7d3dc8f58b36864e0c1cdd2f.png',
    id: '11',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b6da5a6b32d8705f42ac251c308ebd7ac42a9243de5424005e15a133a02a4dd.png',
    id: '12',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f349c1fdd25dbd67008d4265031d3ecfc9416f01d7fc7d673687ce48624de390.png',
    id: '13',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fda546a07700d0709a56089160834265e4ceb0d4db337c162add4ead86ed9f96.png',
    id: '14',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f57d4f6954a708c7eabc9303263622cf78155c813828a6eafde353d432e4c75e.png',
    id: '15',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82d3d6abdc2e5814c555b7bb52051b030635f828d229799daed1582882436e3b.png',
    id: '16',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a10bf658cce8c9dabe6c3cf1b0fa998ca78eaef01df2621fd105e828cc99fb30.png',
    id: '17',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5cf386bea404df2a2f555212249c2923b4f9bd687741d598063ebd3ebd4f2843.png',
    id: '18',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1634cedf40bc06a552e07d80d4d010243ee006987333d27b43ea83c891165fa.png',
    id: '19',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f786a7fd6a3173028e8d8189b3baa9f2fa1ecf83d18f709c43d2ce9edb9633a5.png',
    id: '20',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd223ab5689e4b1c0ffd4397c72bf509157c9c380000725e640626bb2a948a5b.png',
    id: '21',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2030155c98998e5a0273e12ddc57339ae1ca6b6b049dc53670548663cc276a62.png',
    id: '22',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35c120fb268a26193a50ee989e50dbd0124c5d7988cff0cccc4525f709e34f5b.png',
    id: '23',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b726ce2eb3d5053ddff7e2498cdf5e69d27bec9e60acb5c6ff119a2b98fba36.png',
    id: '24',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36adcc52263faf1a7f02407f0aaa95a77d05b0fdc72dd3ac0f15c7aa673dcab2.png',
    id: '25',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1df7022bbd25079821ad52b40a8e107a143bf77b93bf29378305c69abdcd10ca.png',
    id: '26',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/536a2ec11a0a5238dc4dd2f3a2fcc8e5eca79e55f8e031c1c43eca8b855da6b4.png',
    id: '27',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3609f1307d6751a13780a25f48c39616ccf9540a074b1eb271c8ffc73f65052f.png',
    id: '28',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32d51c2c741ed81e9d94430b0806c7353a291a70ed984920f06632866c4e4be0.png',
    id: '29',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2ccfb4bb0741c46712e1fde14705a63fb510699d2d7064a794ba6fcc8be4edd.png',
    id: '30',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd49f170d75740b9c71eeba1201adb0c445dc92006939ddb99c190a826bec265.png',
    id: '31',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c07cd04c5f427d4e4de28ae73dc5f61391b4b3aeb1e21ded0fcfe7a809ed663.png',
    id: '32',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/406e915504244e702879f66ed5da1bfc4d71bfb46248e71d7384338350484826.png',
    id: '33',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/802a1ce430844044af5a5e5fee410aaca614af1695f3611d1d2fe09db485d047.png',
    id: '34',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76acfd0a2f1be7c5c4f5799a5004e0f89d14c3d0d4fd56bc3348c76ac460930e.png',
    id: '35',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bec9bbeed7cfd7f492a1ef6d6fb5aa9e8cd3090fbd84fd68a4d7cd5f836ff93.png',
    id: '36',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eed79bd5a07aa10333451d14b336b64b4b8316749eea4b1d1978c0f9634980e8.png',
    id: '37',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/173737f066864b6ca2a80d0a6f0cc42dfc4e78434b06cdb1ce5dc3e5f45ad770.png',
    id: '38',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/274241c4c4fe78547a8ea5f7fb7af9933ffdf2fcc42858fdf4337170e09cd174.png',
    id: '39',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b13ad911d2daca4545d5c485ba5941240b5f01374f6f14019b5a32da1cca868b.png',
    id: '40',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0638b3ba1ea0c8ec0c7edeac28ba7d2d81048f9ff0496df1028dfb6285228c8d.png',
    id: '41',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb88c790c4ca18cf6698a71f38db18324016b4ac0df4801908a1d3c2e83dc470.png',
    id: '42',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b81e968692c0c9203e840c7bfe0fda029ae491b942aa79c152de9628f811717.png',
    id: '43',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bed35338713b5c386a60689911463e0ebbfbf16108d1c5c48644086292e77068.png',
    id: '44',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cacbe08683e4ba28b657696d4fad12af97ccbcc998ab28861f6b2dd08aa5bd24.png',
    id: '45',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f431e2fb9b1e733bafb33488a25839f3c1380310726cb58ec22759493663688a.png',
    id: '46',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1122a85dcae29810c3efe9585cc5957bd3faf14ef8e6319d0c0a680da5810c4d.png',
    id: '47',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a60c0ed66b2e3d85ae9216d76710b99f17011f444e741af1a9423f5257d8f0ed.png',
    id: '48',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96a4b07b3317064c2d2866a4dc994642a1ca5b9350fae403ce83d9c12fae0479.png',
    id: '49',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60bea13300be290a0a6ae6034fd389f108d46ddbfd1ba34f6b2b54a7e7e7ec37.png',
    id: '50',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1bd94a0256a666db7c406aa463313ddf6975a8caf57f2c9fee5b885572c1aa7.png',
    id: '51',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Scythe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f637d154bd9d4e39634f1a6ee78040f3a87a59367cd4f4321cdae22dd7f471aa.png',
    id: '52',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/097602747107d21ac30bc4084596a24d6c68331c3df7174cc766344d15121d7d.png',
    id: '53',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7d98ce9a50d7c59a9836f500297074869e5d929f210ecc63f9994bbfe1c8f1c.png',
    id: '54',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65fb3ea44140f3ba9144f7568cf345fc8123d6934b8b614fb4ce996bd4e98173.png',
    id: '55',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/924146a1b0f94c867ec1c152581a0ca48753909e817b32a0137a0fbfd7ed0894.png',
    id: '56',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cd72037c8150b271c4957295387bd0969aa53ea5180297b704002933571b990.png',
    id: '57',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/121ec93899f19a25ec986b7251a98088cb76c07b699c0558f753091f908e8721.png',
    id: '58',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf5de33c196182487bfad7fecd3dff92092d3017cc0a8fc4d82507d0c756cc09.png',
    id: '59',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dab3e55828f14318f17f120f96ab7811e6ed266aa39652fe074733c34c5ecff1.png',
    id: '60',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2315335837f2ae4a3d5eb5560ad1a1452eff418a97dea3e54dc0ad145b9d8886.png',
    id: '61',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e1f1a7946d5fb6dd9fd4b939e2e5bccca0f949999abb8a0451ae72163877393.png',
    id: '62',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42bbf3c4a5de3a4df94e6a92b4f21d14ad9f4cc282491fa8121b8ee507845fe9.png',
    id: '63',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9dca4207732249e5e85ee3c7ce37a23cdd7c892d37d9d15cc4ddef43547571b3.png',
    id: '64',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8387af58eeb5cc5a9f5966e8207fae689cb2f49bfa83c4b0a3bb92f6b39d24ea.png',
    id: '65',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/868470866c77006576daa270ff8b329dda55b938553ccb16c3e472de616278c2.png',
    id: '66',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1074812abb514c81f3d2b4209ba4f90932ea34f8d2f68d28b77aca5665657fb.png',
    id: '67',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b58791a341db9a3219ea02d426764a8922fff4ecd7a03eb33016d202d5fb579.png',
    id: '68',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc199442430f2a2227abdb77fd9a15ca8f6c7abd1954a75ecd7f89a053996ef4.png',
    id: '69',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c9cbdd9bf3b13fd9750742005895dbafaf95802d4d9294df2c4d3819c769192.png',
    id: '70',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49c8d082db59dbff8fa6097cf60ceb36f2f041f38ac3251352c00e522038753c.png',
    id: '71',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f79e7b0789e34ecc325b3b59eea8e539d3d2af8123859548a140cd81342687bd.png',
    id: '72',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a69648455da082644627966eefefc813e19e899fbdcc5311bd1a16e6afc7d6d9.png',
    id: '73',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28a425f22ea64f4721a95a14066cf1169d17d9e94927f29717a2f866eeb94064.png',
    id: '74',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44fdf37b231d840a1714d2b21bfd6de2bab22d88a29921e7958a977a6f37261c.png',
    id: '75',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d17d1f0b89370ea86771a8e31243865c5b2edf7f31da06c1f224e3d568c13643.png',
    id: '76',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e2600882b93e4e4e2fb0e15e28a7ec1fd731bfb68149610bb10d02ec5e40c60.png',
    id: '77',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc648e4757d6359be955461f849ca00adb3e47c5f0c13024656fda0f9d686763.png',
    id: '78',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9910672282237b43aac41dc69fd2255a63bcbe6170fe89283ea39802d9657602.png',
    id: '79',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbd82370a4febfc10ad9fd02f46440d9bcecc3eb7d5eff457591af105b500118.png',
    id: '80',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bfebc73f8deaf9b616c668634abf5723ab9f3694fa66bb5f57a2edb71ead769b.png',
    id: '81',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/964e16fb37cfb33f77469cff8c7d674d3e78b7939f4330ad2b4f999359aa38ab.png',
    id: '82',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecb9ebecabe50e2c1f59d408107fe85720b56b8f9488f8157299709585ecb719.png',
    id: '83',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07289f8afb602db95c949079c24d267350047edb6ad89cea88294b603d2d72a4.png',
    id: '84',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4c4cbff8cae781e7b2b609ab406c421c1da453a09b4225f314a864ad2b2737b.png',
    id: '85',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a3444e3d6fd282d96122687b378ffce89d1b509f9bc18ac4b11cf32390e56a6.png',
    id: '86',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f742a64cd625b93c6b5768fa65b8ee4e7597e225dbd6588f202246ea39fcf6e4.png',
    id: '87',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d46dd3aeb991d48d969ad778f1f17b9b66b956c4dbf44947ed054bbf04527a68.png',
    id: '88',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07cb9f3bd45f2e9dec74faf843f8e5a2d98f1a9475ec1857aff70702485a8118.png',
    id: '89',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2df961ccd95ce709f55809baa03d91da608ba291885242aa669e858d97294d19.png',
    id: '90',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e20f17d0e603db7dcb0332fe29941d8f382d70f701873fb71cb4517e787a13fb.png',
    id: '91',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/248010f5e6981d3b9db7d4ee95c30bc93312d0549deac34e29e5ec7a3ec49fd6.png',
    id: '92',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dcaa97208ab04b88b3f84a3f28af386c33301b87b12ba8bc78cb6574872bb925.png',
    id: '93',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c841903e54765f63faa6053570657e0efd8356038e6855e4379e56689c25e786.png',
    id: '94',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a12f091dfaa48e6e9fac045f709e90326ea21962fb47d44619fa4488b8bd86b0.png',
    id: '95',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eafe6188734dfafc897cc58e2aca8c9a9fda3e0bd1485f55f225a0c7406e1a7b.png',
    id: '96',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9790eb42502e518586a35fcfe29bb93602218999677cba2b33d87e6e1dedbddc.png',
    id: '97',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6e9460cb503e72a24d99147c1c6e96585f9e8c8a4d19ab97bf3ce622480c6da.png',
    id: '98',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dcd5849ce7b9a8371be8b21abf45bdd7dc4905769fde01d21758e64738199745.png',
    id: '99',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6172b12158e2a29f2367ab461aaa85fc5e8498ab7380e50c34ce064319948550.png',
    id: '100',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c3ceb814e9774a9a6991803a05f755b2728e2fb748a0b02b7e4795d75817b22.png',
    id: '101',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8595c6a93e5eead9df78813a0933e706625c4af8c8d2cf4eb1331266247ccb0.png',
    id: '102',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22663ad4723aa63dba3538bc41a0e9d4453f9525d208fb7825d1f0ca9de5c10a.png',
    id: '103',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1aab9e2c7b321dc85849745f884ee82ef083eea54c73153a69b09b55599b81b2.png',
    id: '104',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f91d8c909cddcea076100d0f6646c992a621d741f267361efeb1cae7dc403b7.png',
    id: '105',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b724b90609dc9d6162c0b44c9219eba88194324124bdfa3271ba427912885245.png',
    id: '106',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2fffef0ac4ba45248ecea99ec32624910cffbe82cb88c1e390adccd2e726218.png',
    id: '107',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4399f279239abd0cdf2d5aa1b4eb5c67b1ab8b455ab90479081e4292eb080be1.png',
    id: '108',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0bd5478dca841650c10bee392cddf6fc38cbc1d561b97d549dfbb8c3f3a3e881.png',
    id: '109',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0998b41eadf957e0adc8414339a8437828ee87afcb97b242656bfa6607b595a4.png',
    id: '110',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ffa10212f279e2cfcbf803da5fcb862933237cfef087abcfeae01346683ab71.png',
    id: '111',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f1cdf3d3a66c1d7796c50a3f853a37cf91abe1c32b6037a4e84e24f2fabc627.png',
    id: '112',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/744501adac9df663083159c8992f7f566bf884d7496dd6ff313091830c0851e5.png',
    id: '113',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b53a48b9c36557932f678ed25ddbeb69c19880019f5e6d1fcabffe3f29068f8.png',
    id: '114',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/051323a066dfc9a37f82c81a49b3d3742d5c1845807a9d49b81a544ce8b38211.png',
    id: '115',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bac75fc54378fedf6b03ff9083a202e07852ddd08e081104dc3709b72aa3ab6c.png',
    id: '116',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcf5d3bd241e4c55d6f84461bd090f4cf14e5cfe1438a724274fed2d2b2fdbab.png',
    id: '117',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3852db698cb69f13cfd3f9ff43b063e9d1c0c95fae300dc09d7c6fbc94902451.png',
    id: '118',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcac0779171190c62bcee6382d2d46654691dad6f8fd7d24b6eb9738870255bf.png',
    id: '119',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0067f36131d1726ebaf697cc50fa192ca54ca488ac254b9e4f49c682aae8919.png',
    id: '120',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b5d42478b69f0302061f8e6ae24441558c03fa3ce4eef32e2f1045585f9b852.png',
    id: '121',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/897bac1e6f69db66ca079b1c8fc8beda5479bceedcb26cd1ac237ffe41a2ad09.png',
    id: '122',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01c146b7a49eff70b8aef1c3eac2124ab09e3be9c3bf1938bc07fd27d3d3140e.png',
    id: '123',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6c04a600717c9af43ca2f035cc3c5252588e7052c4ec5c20249bd7d5650576e.png',
    id: '124',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a229a01044617e02418d375bf64147666930426aaa7837a2938d47c0c314eaf.png',
    id: '125',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8162b2c5f698bd2a19de63e9059493544fadbc49b705f6ca7ff254235234258e.png',
    id: '126',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7771a4cc625601adf4209ff8c45a3347cbfedf90b86bfef420a83b813e89f04d.png',
    id: '127',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bff2111e4fd5804a620f0799ebb66bd5f72f2265f094e9308101708a74cb28cb.png',
    id: '128',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37795eeb8917ae11f3c058aa836ed6af7f43a4a551e112d521a5f79946354ce0.png',
    id: '129',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66a22b8d589af151055cfd1171ddc17507c22184a80767ad26e99df0f01a6cb8.png',
    id: '130',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3a8221d9460f4916131a09ccc4ee7a4e2cda0471c23f20497cf23111b09f2e0.png',
    id: '131',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b3eb3d260de256faf7337d7f37be99577bc2b41a20b7272eb733e92802e8fe3f.png',
    id: '132',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/892277d46080a9065f912a855cc33e7bddbf294ffae3513c99680def96f30a47.png',
    id: '133',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed7436adc51cf9edd676c2b6764a8605b85e156e7b7bf16a32446435ed4e05e7.png',
    id: '134',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd76bdd3c0f744b877a7b14298eafccf6d5d9fc93209d21a6de10e6fdc15faf3.png',
    id: '135',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53a50e3f7f08c87d042d136de694d65bd71e2e7218d17bf280c4e6b3428fb76a.png',
    id: '136',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/997158c14797a6ece57e0949b8cc345eb65851eec297355a151378ba6bd080ce.png',
    id: '137',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05e5cbd7ca390c0afd6b12c99b47ff773bb6517641c82b9414c9ffc90e7a8b1b.png',
    id: '138',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5baf77616b4a7c4962c7a5d9b9a95fff58347a3bec38c2ab33c7182fe23f313a.png',
    id: '139',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca22333630c7d8cc07b058a4dfef0b805397efe3afd656de0e3853079d84aef3.png',
    id: '140',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37ddb5d94f6cdaad438d4bf60ffab025dd810cb2746447dd7350d2ec625a8d4d.png',
    id: '141',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddb167c0e1786ed5a96611c9c375c2f6085bee0ca8a6520558d08761e6ea8ca6.png',
    id: '142',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63d4fb1e273b80e152eb0d381ca4f1b911f0f896483fa27bb97f1cda432a2439.png',
    id: '143',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a48de26faaca2359feb263b273c78e9973ea7dec03d14095c485f3cbd207d969.png',
    id: '144',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Ravens Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dba412ca86d5cd08c06afb449b3a1facc8e2d038ff57124ccee5fd9f3edf964b.png',
    id: '145',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/961a954d77b155f68805629674ed56d88150ff83642cda9ef60de000ff6239dd.png',
    id: '146',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d45e4cfb7cecf5ce7fdc63a291f65a697c34a66b9c2a75ecbb887d98c2b1c7a.png',
    id: '147',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/241c1d0448363aa308518dba691ed17385b01770d70e684d3b4bb3fb6ad401bb.png',
    id: '148',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ac775f989442c9732fdf3983a3511a46c0294892049e6f0ed5488e4fea42791.png',
    id: '149',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7062c9bf3efcf1eb952e6f471d8fc02a8282ebd9e5e954aa3b629d8b7d96f1d5.png',
    id: '150',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25bf11e6b5d0701ced0444765d26637dadbc398b6acb644fe0b7a7f745c6bdcb.png',
    id: '151',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e85221970353ecdb5c55fe22654b00ffdb375b54ef077e39f355d7b4d1667c6.png',
    id: '152',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/785e1c936cdd17a05dd7d65cfbf01f845a998b5f1e7c5293b2bcac54ac7c5583.png',
    id: '153',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Batter Up',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5556dd222c61d4f7f0ce6bf1f3c6b9ecf595fbfc89ea0ddf7d9771e5f10b131.png',
    id: '154',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/114417d4e101dcfbb4bea7ede611ca6293758db81f6604e177c997bd15cb309a.png',
    id: '155',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d25ae531f8760dcc583b8bf3fc94cf08712e54acda2d6065549083367474e5b4.png',
    id: '156',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/638bc2c733c9e13d23b16e8750288c61ce5e5897cd15f1644796124a6140f53d.png',
    id: '157',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fba3e2f87c13698d06104153a9d403885453451366f17f29cc9b9e6d2b6c2755.png',
    id: '158',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0bda233e1be1ec2a098bf2cc4c429bb4d434d85571e76094953564cc2402ea76.png',
    id: '159',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6741d0394a1d47cc02b8e1d2357db45b6375ca3d6a3aec01e82cd56b00de8ebc.png',
    id: '160',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62830a61c7b5fa9b764224c42864d11804c937b6c1971d30b2d26c71481738ef.png',
    id: '161',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e8ffa0c51cab716b50f1a5f6f1ee2bf591d67fdaf76a920be8a8bd800d8a619.png',
    id: '162',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59c98ad3de47aeea11c6eaf72ff5df854b76d9fe41702d840d2c461594c003c9.png',
    id: '163',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97b9909a6c90b0a7558e7e88763e61123491bff994e93822a6ee51d4cd3f3ca8.png',
    id: '164',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b5b2b2cff61483be2bae211ebef9f1095a01c2c0e226b12ee0c19fdb2fa66b4.png',
    id: '165',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ad998b7c278412b9e98e95c987f0950e958cb87379e4271c93f4836b11619a5.png',
    id: '166',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65766be78653dcf6bb291954ce9342ed08f44778ca60e5b5b4fd1cc7c94bf534.png',
    id: '167',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0825aa528133e52ab54b9f4f7f0c1b493d718d1df6e237d6de94a7fd7356daa8.png',
    id: '168',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a583157f628b40e29a6690eed04186280d0795fc17f643a79f3d18f23612b40f.png',
    id: '169',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eeccf1de8a14f5f0c11d42c525cd9dc4642dc7c2d67f00ad70221d9b903b8115.png',
    id: '170',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ca8a81d2a7e046998f301438ba15a3a84c931e0e089f680e04d10a9423f4be7.png',
    id: '171',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2333313781bbfa7c787df93eeacd8cc9600bc1193832fd0b743f56648eedf564.png',
    id: '172',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff629fcb1af0a3232bd2790daf3e321817827b0009f8766b2c5735529b80e4e1.png',
    id: '173',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/070e7b13ce01b6a4754873384747a039c2a205d70f3de6c042f70cfa537b64dc.png',
    id: '174',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d56a88dfce6100b0d87f25b48a0b840173439c71f25d16e786ec64b925971410.png',
    id: '175',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b99e44423ffb2d1cb574db5e049d26702587cd78ee159db739c7f163b4cbb2de.png',
    id: '176',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ad0ff99a4a2691fabb69b442c76d4db641922ee8b7b3010b28d39bff582d8eb.png',
    id: '177',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8c0749d95338ad82a8f3b0f60a190540653ccaca21d2f0502337a62bc5a40d2.png',
    id: '178',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/067fcd9582eddeba2dfe8580533c6810e9008467ade012965fecd42dc16d6388.png',
    id: '179',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6b07583c51303e1de24deb3752fccaba53f6099f7df314260d9bebea549bff9.png',
    id: '180',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1274a4e3de06d498cec539e192f1671415017781c99c48b9b2fc4ac30489c22c.png',
    id: '181',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f92679682acad70c4487d2dcbe835c6af42de77edbe8ca4727db862305c8b47d.png',
    id: '182',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87703547a0af1feaba260801d667ce8144aa51132e2ff898832ed1e64390a2e0.png',
    id: '183',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/008a94c97fafb6b321a08d30426b878ac64ddc80724a745f340d0d6241f30c55.png',
    id: '184',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37dd19021130b91f76c7efd251d2688898e2cb588a18db247b01b50b98a16a32.png',
    id: '185',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ed434416e0c679a5adc31bd1814ae3b17043577c9a3074259edcfbdce2a5b94.png',
    id: '186',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0d4cc477d37a12c5acb42192538ad90095bb3bdfdc21da8a957544f8bb04e63.png',
    id: '187',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3511bdff0cf72c4df1832d2dea12af2f05570a5647a97c15b7ed4739e9e45ef4.png',
    id: '188',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13c0091091b7fc68b72477bcc9c0a71018808263c81f14dc4e05be04e5dccd5f.png',
    id: '189',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93557e68658a26a09e1e70ade771e16f7342ec0b34993286f83cfeee97e3c1cb.png',
    id: '190',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wise Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ac5fd4f5a2fb06451aa59f26b09151e02e431dbcd2e12de4809aa0d18c1ddfd.png',
    id: '191',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fae117f0177a0620a749eda9cea9359ffd02c885c314a642e42b5e2bd308417.png',
    id: '192',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01bac532ea06c39282cc13113dc5bb25d6878fb7b0deba93f6e3b942e69107da.png',
    id: '193',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3b6afa1bccaab7b8cc1dfbf7578d1a0bddbc91ab4743dcd3bf456b7ee512186.png',
    id: '194',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20f2f30856e83fd64ef50d4ad36444bebe34fe3bf53ca05d1264f544881313ae.png',
    id: '195',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95e1faf084c606a1f6ff0ab8c768c5cd5183f95910230bd059b23bdebc10f1c2.png',
    id: '196',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fc15bc9938f8487454cda1b4d90ab4b6ff847e894fe06fe27d145cfd2a617b9.png',
    id: '197',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d06359c4810ee28ef4cd9d1a1ebfdc363583d99c76edca173ff1381966926bce.png',
    id: '198',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Alinta',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34630bf16f48dbc266e01d7da804974bd2bd026c03e5a0fd6fac7de32e9e5fb3.png',
    id: '199',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f38f247692b932c8ab088ce8102c3bdfd35177c6db6a85b5b03375ac9dcdd5a5.png',
    id: '200',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f66082fe50ea131007cca1735539c8e7bc77644831a69a792f71238574e8985.png',
    id: '201',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba34b3190b7e3e4dae76fdcfc432acb786710d2bd76707910a2a4b5cd33b7ce9.png',
    id: '202',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d86e646a465cff28b08e20dfded31de5038791a774442191e5fb679bee967fa3.png',
    id: '203',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5f063647227e9fb28df780d1ab8fab4de174e21245c5b82cc1a27c2c720e6e5.png',
    id: '204',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06db3bc533bc04e499d5c17ad7a7f5d95fddb6d2026f83c23d456ef9d92ae3c5.png',
    id: '205',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85b425d6060df64d369cc89d724ac126855b9922d75e739c7fbdc73ab6ee12d1.png',
    id: '206',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95d3c9cb703dab00acda8d01fbb979789cbf7d4be8234d6ce9fdaf4de8f28869.png',
    id: '207',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f6db494d618eb7067d9f71019d12df3677bc251de6679b18ba97aa055618d89.png',
    id: '208',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb147a6abb3fa0b3e9d21d4843d90fea02b7ddf705a6e544609dbe60ddc44631.png',
    id: '209',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Spinal Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dce09728e3605d4c7bd679e2131c051a502012febf147f2c441d80d5078382da.png',
    id: '210',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84e781e7ad8faa1f7c56f1d896a7d45709f98a18b1297ae3dd5f4201f5d52841.png',
    id: '211',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c891d2e3a6a675fb4024c0bc276f4f65506a4cfa099408ca761fa825db47d249.png',
    id: '212',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/173db7e29d2e4d06f46f34169dee46fe65176e220748e4ab34023d20178940a7.png',
    id: '213',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebdc7de960ca288bee4488f76d0f1e063f9bcf3f22fccf318c7ea0b8feabe5da.png',
    id: '214',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78e39679ff4be2b5361fdb96e514a96782a6afd29eacf872719bb25fa9102b41.png',
    id: '215',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c8052f4337ea71eb4a1bc967efb87824c828a387359430db02eb7ce6ba275e3.png',
    id: '216',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d25c970adf2585cbff00b8b75d8c7a02c70d111b3ca1b3e637700602971f0950.png',
    id: '217',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'The Tenderizer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b2f501c7e20ef114b905804537fd047ca98fb8fb6372a26b913a4eadc896950.png',
    id: '218',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4d3bf22bc0542d198fca64410d89f2158221936c58d5cfd943364af7898d914.png',
    id: '219',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0db9a9a1c8b3a218ea169680abfafc1defe23415d36f21fcd5ff84639080014a.png',
    id: '220',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5145de1f5a1c142ee80d52f3477b0a1853fcd8c5e0c728b4605ff85f33e9c0a.png',
    id: '221',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91c1d9467fb504ab3ab9f4019d53c33e0b2f33c991294559742325a4c25ea767.png',
    id: '222',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be093b8eef41f1d0346f849fd42dc9a9f40eef0a817cfb6addb6e037dd3c7849.png',
    id: '223',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Alinta',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f0def74112730af56abf99a23d6a52fb47d46c58ad289d90c509a11ffe31025.png',
    id: '224',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c409a42f01c1837725ed52e91d111c2f42d5aed764381f83e54ec9f27a6b1e89.png',
    id: '225',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97ba3454224dca55ce3bb5c6d048b69ad76867876bfc5a71561ca81a64ffcee8.png',
    id: '226',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/969e18cc5bdb369ee4edf4c2eb9c8a8986a8a6cca13ad8597354dbe09d4167d9.png',
    id: '227',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86932fda524d675b014116f5c21d9f243d9fd9c81d6c45a6f9a8dc78fcb80ace.png',
    id: '228',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/947de728e3728850a7e9e8315e352e96f677cdeb3f543d5c57f7d20eda373cac.png',
    id: '229',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01f2e9e88c57a49069252d8a059590ffb2f0d13da3a90d41f1995c8072d1a2cd.png',
    id: '230',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f6ad3ade49d878ded0860ca4f89dc0611b61b51c87384c48c7f8ca70a61700b.png',
    id: '231',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2b0c857ed18b15764fed89ff536b2d35a1d48908b55f0f1cab7ab769c5e5696.png',
    id: '232',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5518f3aeb0b0f719728dd2b089f8ee3ef5e509343d11d05f49e29981627301ea.png',
    id: '233',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6a99d7312596274396c315b1147b8b56252c1fc4a8c637384653ba77e02b2c2.png',
    id: '234',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d01cbece5fd31eb295488e0652860f7aca284cc39d510033061c39ffc6a0b17e.png',
    id: '235',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b3d3697c7640228d91167da6697631c82908ae94a34755a4956b7e1a9742085.png',
    id: '236',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1be6aece33b21d557d3060f05e778876827497dfcd33f87b4a2dc37f57ff0136.png',
    id: '237',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2101acfd3052c774a0dfd649d81fb3d2bb59e63a5be998b130281ffe8337e706.png',
    id: '238',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69b9c233eea28b334e99ea511b80127346b402ce4aa1e26758556fb50c06eef4.png',
    id: '239',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skully',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90c0ce466fbcfa52e205dca6293d5cf9e47b092ea76d03dd9324cf8aca980c62.png',
    id: '240',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87dd8fa625eac0279ab66ab8ac657082fa767e1c3188f0fafd9de17703a48897.png',
    id: '241',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/646025247d04f7b49bbeff7fd328587f2b7c9c88be4131c24e10c69be684d895.png',
    id: '242',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de320d81666bfb6b2e8b7d3472dfd55566c5450d808d89d570d47a828a137821.png',
    id: '243',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sticky Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9da38e7e66ac0b1983e5aee83c72f83966db0569dc8eada6a685f69e851a8255.png',
    id: '244',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70186a24e7f76550f5d2bececb1c33e1c3d54bb2fb663a689d86b4e46c38977a.png',
    id: '245',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d2e4facfad6b2a755de588760f0ef6653d4a56a354337d43cbe14cd1c17a48c.png',
    id: '246',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5195e440a2679398ce1d23d316995b0d2a3b2847cc2ce043d69c1c20f87d69c3.png',
    id: '247',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Wood Ear Chopper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35eb938a91326f69f405ce9b8bf657be6abf533e30486f8b0e8d67918e95cac1.png',
    id: '248',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/079453a62c19f2d771c31eeab3356649790c2f6f32d86f54352a8012a62bd101.png',
    id: '249',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/438f3c16fb7c2ffde9ca086524a3564cb43c3179719dc75de5048572f6a967cf.png',
    id: '250',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8aa095a0490e04c518e5f246311a3d5bee986a7cfbf1dfa4eb65d916df53921f.png',
    id: '251',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdbcf7013e26b5bcbe714b4a45b8875a32530d57ab7a406f8600e4079625c14c.png',
    id: '252',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23243a8281a0e5c742372aac3512157a8002b3b39e28b70e6d60ccbdbe3ca397.png',
    id: '253',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b466c1d25df0e55333350de97e966dfe8a1d0b12d5161fec7b0d0189b0d7e9d8.png',
    id: '254',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f4f3747ee7f17fa3c9dbdfd969fc85a9b75d03535ccec6d5fd86f0354fc4807.png',
    id: '255',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5cd58a3a87034ae9d71db25be84fbca546c4b18b4ae30e80c9345fd40e17b2a.png',
    id: '256',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be83b4a12b19dc1a9418d81df974045d404d86700de995a3b98c6c2fe52b8a13.png',
    id: '257',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aad73945cf568728cc82826ac237729a1c2b1affb326ce83a9986336972ff39d.png',
    id: '258',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef87df3cc48ddeaf89bbf4868b7afa7d970b1bc01ca079544fca06ef5f1f2608.png',
    id: '259',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/636c08971741162ae2432808d59a80eb0f387f59e3eb42215cd8e84e3e6ad990.png',
    id: '260',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff3e412103821e3821c3035737f12e0fec2f8f5a3801b23ba3d123c9fed7bda7.png',
    id: '261',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3282656032d04da08a348c8bdb0105b7352587f52a371d13fe5dc5676067a108.png',
    id: '262',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7deda2a9e0716c46868e162f7e9911a17d64a6d4bc9cbf02a73216da71eaaefe.png',
    id: '263',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8dab611d6e10e87efda36382b954ab4ff3d9c428b7103a947908ab914d1b4cfd.png',
    id: '264',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da750fd73dd7be71f8bf68cc5061ef10a5286807072f4c310fb51deabfdbc6c4.png',
    id: '265',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eaafc51f9c171b4baba4b7ba5cb91b3138b936df2bbc80d7f838bd223f50df12.png',
    id: '266',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c9e33985925bc4dfc9130651cfd63e9ac7257308b0578890141e7eecfb419f2.png',
    id: '267',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78ca82181e1e92c19e366d4ba6f1d25c23ec9822845fb25e863c8ab4277bcdb8.png',
    id: '268',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dfd95a4e7a72e54db87c29389598478bc3752dceee09b4f0cf10ff5fc20192bb.png',
    id: '269',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32a827ab20adbd6e45cddc725e6d46ec81dc85f1b7753de348482fb32c2cfb11.png',
    id: '270',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c58946899bc853d34129d5b4f7ee804030c96d52b03af075347387129fe11d7e.png',
    id: '271',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ca220f9ab2053b1480dc95aff61730a0176584a96daa08e76b1b04925626046.png',
    id: '272',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c750dc63f2972f5954bc44821ae3cb05d81f4faed6d1ae280cb32f7df987270.png',
    id: '273',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Scythe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66566343cc8ffded244a3a60318af3793cab7ba9964d051a88afba5bdd2eec71.png',
    id: '274',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ae07d3034193e82aaaccd3ae7900943651b051024e473aa0cf778f9b782475b.png',
    id: '275',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/168faa762c25ab00034d6dd50cb663126b9a63fdead75cdb58c98aa07ae1b354.png',
    id: '276',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06821627c4a8ac2dbbd6da69ca2c1b4256205cfd2b232e1cf5cd21dc8f00110b.png',
    id: '277',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18d5180bdf588a7569c154bb29c976bb65cac59f710988079f45385ad0003d3a.png',
    id: '278',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68cf8a5bf25752e799cfd53455c37537c72e33de053fd86edb96f60070234fa9.png',
    id: '279',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'The Claw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f552519cb31c60789e01be3449a792ef7ddc2d0a841679699c9ed7be012afdc6.png',
    id: '280',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd4bca352c3163fbd0c452b67b5e4890924f6a2f79323883fd0a959498a02ef1.png',
    id: '281',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Adventurer Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5cc010bcff19c4b378c9bd66a70061221b8478ed86a6fab8171e916dc1c7bfc.png',
    id: '282',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af8819a7c1df1035b1dd54598ae4a9fde19b4ce7b3bde14d21613b33e675d05c.png',
    id: '283',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/127dce681ba8a6ccd2a1646045e956dc4dc399fd69a3f200fd0252eb942af477.png',
    id: '284',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35752c40f4f24198c702bc24f12d8a17278f8193cc389a74d6755acf227dfe5d.png',
    id: '285',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42dd2a118eee08081f84267049805956249990b3fa23ee706c5fb77c73e8b724.png',
    id: '286',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b9f1fbc356338da3e72533dd61af636344638fe8215f2760c431c30c5a78518.png',
    id: '287',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/307c33acd98b4386079bd0a0da0f0fa84fd34553258a7f462bd33b16720ce5fd.png',
    id: '288',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66b217ad47c71460bbd98a08afb1615bdeda5aaa00226052164e5e797578bbff.png',
    id: '289',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/631f3b8ec3a6a4d72c0c262999bb191ba61568f26d4bcec642d30f53da0ad706.png',
    id: '290',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cefb6a082b336b3c944d4e99de198e768e631f05ebaa9b4a41de976e8ce3e244.png',
    id: '291',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d302356f9fda334a7e353253bf950b40b99271fd5001d86fdcdcfd656ae2bbe0.png',
    id: '292',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29f8f58cd48a310221d7be1b16568932bb8f764b4f55331c6e242205373b895e.png',
    id: '293',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56e008720052f44c05b5f24608c712e93568c7473204daa276a5d3076d97b5c3.png',
    id: '294',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88d8cf49622c971e613d5278aba4e35989bdb4ecbabc340b307a42fa089e9315.png',
    id: '295',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7f1a42325b22871de0056bf87daf9b7a8d1a1ab2b0fa3951c3d56a0710d1966.png',
    id: '296',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdce31164286c0164b88d5d5ac0273878354f1969a011cfefb00f4cfed8b4f7c.png',
    id: '297',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce6362b4f7bd90c7ce59734a2d4499f86746cc0c544bd8c9f26fe87a40db8b6d.png',
    id: '298',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Winged Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bc6893ba0737e0041eaca0b32bbe46274f84af2f5c64fc0b83c1d11c372db0b.png',
    id: '299',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d89ec7d36c990e2417cdcb71c5b9e8cf4053894cec9615faef317d6fb892dfe.png',
    id: '300',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89d1d6a86a6c467cf930e094b8802c800a4d76e6a3ba5bcb8c2053b6a613c461.png',
    id: '301',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee221c9cca5068ebf59a273d7fb042fbeb43c8cba6bdd2eb3ff3081a83b622b5.png',
    id: '302',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c969381be34593fb05122b7a6358cda6c73b51f094a72525f7e54e7b5338deb2.png',
    id: '303',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8a72e83b8b8df86fea22ec46e284125d65975b6ddfa5812c6d6765bccaa2a5d.png',
    id: '304',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d9da4392a7963af96ad1e03c836289738ec1911b34d3c5704be5e779b255cda.png',
    id: '305',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ee795aa31694bfbaf9c871cfeb290018996601e04c6de2fffd7f79a023538a5.png',
    id: '306',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/128b4788ac393b0adba391b8ab70c6e4445baef1513d76ef179560bc77918248.png',
    id: '307',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0431e2382e221f0743ba7f74601f95f3759b39dac688b41010ed5500cb1c0bab.png',
    id: '308',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56c57d920cd6f9ba9b98f7dbc7688302647e143fc86cead33a18e79eafc3d6d9.png',
    id: '309',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a9336b45963f45ee50be20d6132aeeea1ff152de2a36fbc7d8fee93f5016b91.png',
    id: '310',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1608716d17dcdff8ffd49e1e1556f4a0ad5adcb0a840931a095dd1b8a8a35b7.png',
    id: '311',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3035d304d7c6dbb2a2fac19673e42327f34c13fdd5db0f05026d846fcd9c458.png',
    id: '312',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20909af9fbde2d2cf29307b724960ca217b7efd265810c82d5c83d20a97031c8.png',
    id: '313',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/269bf162cbf1ca2faa3c3fa2b077ae470dda3b2e3975b062df8dd1fbb5728572.png',
    id: '314',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad30bb6330c35eb2d6f5727690f9389bb93ff7c98216f52fc47d3d69fe888f81.png',
    id: '315',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ea3fbf6b98e0bc589d898e8339661faaa704e35bd45de8836d5383ad6107bc2.png',
    id: '316',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a18337b138a7df8ea215405c7c08acd8a9baa13060f49080e6a17cc3ab4b39e4.png',
    id: '317',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/013bfbd8aef9ce53bab950c061a1eac17ae0c70cdb0fa5b02b6495a6144ac201.png',
    id: '318',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3114bdb191dfd0a8efa6d26c8222c6c2d24b95f147826b383dc49c8ab33c331.png',
    id: '319',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a8c9df783f2dc753d3c7d1674a55a67d1e3eeb5115ae1d5184c56b761f85092.png',
    id: '320',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83de67130ed12620b9377146fdd67714c516ca2e46df8a7e1121c2a2ee6d4fb9.png',
    id: '321',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e021bdff16f5f281d8ad3ffb23ec2d56519f6a5f0fd6ac36537e3bd4c86fa826.png',
    id: '322',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c4586900378fbc4fabbb7d321390b80c15d401d9a874ee7d97c0d922d084479.png',
    id: '323',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5a36f912a694a4e14e0c28d4659b936ad93b7312a5a24cabf8d86e318584cbe.png',
    id: '324',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4effa2f238fac5a273669f83868e578fca5c84fed3642b1f835985dc8dc8c8f.png',
    id: '325',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e4f6663cd7e6fc403c9640045f747d639847ad135eb1afb65f2d69de130c0a9.png',
    id: '326',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b357fcd27e363853ee953c00a65c7a4b83d850b0436d020a294ba4e984d77dad.png',
    id: '327',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92cb495fd34f0eba5551bf95bd9dbb7938b4caccb58bbaa51397f20c4254b1b1.png',
    id: '328',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d990642f3843db6f6af09b89c6ff5a727583f146ff6a83fef87a6caf183f8ea3.png',
    id: '329',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1196109678bb19ea5a91428856b9b5748b7bfcf8065470b335c61a3a77c55284.png',
    id: '330',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1675a285f1886441cfbc8f09194c9385394c7429aca100b57afde2f4b50482e5.png',
    id: '331',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5836baebb3592814dad90c0044220bf3887fd88473990cdc8bc529c7b572a889.png',
    id: '332',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f371ebb9937c9e5fc86d042452bbb403524ab56d0a6b440e49fee6912ffe702.png',
    id: '333',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7753f2ccd24d77e3d6db1fba4028e6f0a725d19b3c63db78a692280035d16ad.png',
    id: '334',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffad5d5b7550e201d2fad06c1281d0716b1ad78dcfb4cd8bc0d0d8079be1cbc2.png',
    id: '335',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b267cbc67e5624f6763cfd69410fb37a9ea9254d5adb425a3edda6ce708dd59e.png',
    id: '336',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2083e5caec30bcc6e3c4e7df2add193d33fad9fbe816a266f10d73c18fc26352.png',
    id: '337',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e79904b4a2322b87b092e3ab4fa7e146c2eff2726173794294117706d59ccb92.png',
    id: '338',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7fbb45a6fe10a37aadc550013450b86b1538c5910fa59e13290a9884b4cf7aa.png',
    id: '339',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gaze of Mysterius',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eec513e6997f28da2ab9ed5212af813ae8afe746de9165e90476be8c58f38097.png',
    id: '340',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f906e5d63342dc58e3cec7485136b698fa9c4fdddc474c634e1a9c748fe56658.png',
    id: '341',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75104b4317bb2db3f3fa34f3ac75e7fe7a3777b339138d1ebbf54e3c0c92c75d.png',
    id: '342',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bubble Blower',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f81553df038101c027469dc1859c66f45e15779df3d7ae032d4ffbc4a836ff88.png',
    id: '343',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b700cb5b746bb70b197dbb1247b2d786903a0b35f6ea88d712913f3369edbe1b.png',
    id: '344',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6eaf4187050e125d4911c3bad3eae778125a5e22f59edabd03b763aee9188a49.png',
    id: '345',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62fd19737cbf448fce4a2387857695c9639200e21d1a97f5060a3234ffb5eb29.png',
    id: '346',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3712d8fcdb05d64cd1b26b8412ab65decd716352c5e963a6700193a3ba093ef2.png',
    id: '347',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Mush Clobberer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9532dda42fe07044bcdf5043b696e874ecad55f00453f144314405c19841d798.png',
    id: '348',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c7811807b547d2bd6da05fc9e435f90b1fd86539c47a47c689d174759e13927.png',
    id: '349',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0becddca085d255cc7606b82c42d242a5b33bf3d5442a2f15aff186e1e497bb4.png',
    id: '350',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fe4c51ca8f6999d9f552131162b6dcac708059ff15747501d132224b588f3d1.png',
    id: '351',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e48bdeae60dea01516301ba1be5cc01a11774d9b2b298bea8206c08a2376bb14.png',
    id: '352',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc1bf6f35c47046f9ce1e8400bae209e72472ad71405dd83726370add75d4d3a.png',
    id: '353',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26f56808fb4409882e42075860bebdb3669d912e1e50b48e9da65a9045efcfc7.png',
    id: '354',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24ed98098b1ea0cd4b6d2838d5ece497a16888d259c7b1e74438dee8f2d473fa.png',
    id: '355',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb00f39cdf3fe0f6bb9c1f5d0719da67d3df1d60fba04e0af2a7c9b4253f9e08.png',
    id: '356',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0dc27eee3ba4a3a8e7a8bf258bd1c9c9bb66ba21efcb8f051b50c22796993b1c.png',
    id: '357',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3e6e15cdcc232f278f94ad21ab69589485fbd892b68a7a3f267000c3a74d677.png',
    id: '358',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a128be773a45dd0efc1d6f1f25015680dce55324259494f3aef5930ae247c4d.png',
    id: '359',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bb77192ad517174454589283eeea2dcb09f913330388b66774cf433ae67b4e5.png',
    id: '360',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d6cedae3038d0d011f166cc2da75392ac91b44f540c7d3e293ffffb1d70ef33.png',
    id: '361',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8248083aa180c89f11c9e9312ede633191eda005c3fb4a03f27b20d0b69a0486.png',
    id: '362',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02c27afe3d98ae51a8278da74a640ebc6f06800736bc3f83d302a19e376a55ea.png',
    id: '363',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcfb1dad2bd8f45d1ee1a053fef4b21766c8915ab06c4457fa71a16d41ed4372.png',
    id: '364',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42fe97942a7efa16f1c75d4f04e652021951853e5c839c102e7248a754c8b175.png',
    id: '365',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a58b6212d700703b87936dd6652a3db138565f4f0d303c8366af2a769d5e8a42.png',
    id: '366',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecdd47706d40ea5ed03dc32e3fdf233ef813c72ba138ea65c7f9457f96510a14.png',
    id: '367',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21ee16603666602b57b5f69214609857b0303bf34d01d67495d8bfdff5597619.png',
    id: '368',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c69aeaed3edaa9de2d36ae066d3d81941f4b6ba977bccc8b50d95735b6293bb1.png',
    id: '369',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5f3f6826773b80a202df8119cb3348f802c8ede961c64086e053d85b6425381.png',
    id: '370',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48b0e4d2f0768d2a00167caae8d61f02c953efcf6c82f13299a86f56af928521.png',
    id: '371',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93753044c9e653cc27d7f2a408d61ad94f85a9965781c2eafc13dd4d7f2ad206.png',
    id: '372',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89905e95d14823923b514c6b62111ad7f0f0ccee6bc40af0d9f1a9a74d79a978.png',
    id: '373',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d8ba99edccc8a1daadabf0d996f1e7b573bdad99e84a179814d3515ce053c7e.png',
    id: '374',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1820980a61f2690ff053e4e4c56fbf312e297db5a37f2c9d16de8220a202968.png',
    id: '375',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b22abe4ce3e9b4b8a993229503db91b7200aecc33ec40a2ad934f8f9f3e6622.png',
    id: '376',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fe6f18ef303de319be17ec4ea948561e296bb3c377d6ebaa6b0d53e78451f5c.png',
    id: '377',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05dda8b7087b1f4690e45c3933f3989f1adda3d4153892c27a7725f5529b96eb.png',
    id: '378',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f72644dd4a93ebb3e94af03cbf684855d76e9c7a76a3b5d5ea5461b13229a1c.png',
    id: '379',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b815b9bb4fdaa603ebc2f25a9e85cf1b42920947deabe43203a4c20682193b1.png',
    id: '380',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d15d86bbc3d54e42d1d80942e666212faccbdb80d58821d9b13dfcd2e8ccebb2.png',
    id: '381',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef26ee08413da821bf230d481c5646f5e94ff33e0bbc36ede7ff3aaf162155b5.png',
    id: '382',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cb5e09b07edfc8acae8db1b3349898aa2ad608ccdb892d898a70f6a97e8f6fa.png',
    id: '383',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e43e3ad985dd348ddeae3cfbe07adc5858cc935e89f00de4dd21b59fd9af7801.png',
    id: '384',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f875e138e8a1ace9502bbf5160e259f56b9d9b9a73c059cd58347201d00f564b.png',
    id: '385',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5040d2bfc158edd437b7202606efcb1f78f48a90e8d92f22ec8dcd2070550d12.png',
    id: '386',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4733c06a2018f1a936c617352e9e9d26164edec6b6ef2dadf20acfebcf99de21.png',
    id: '387',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29d3c4ec036358dd4e6c8441f36ed04505e160cce24464bd03213f58ce039dcc.png',
    id: '388',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29031afacf1ecb5bbc5e44382fe88e1a17fdbe7348431c770d1dc467ca9faba6.png',
    id: '389',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38dad5ebc1b6248201cf164683856259d6dbc14eefb6ad4d0e316af450fe7fd3.png',
    id: '390',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5fdc309ed9cc75c931e658d57f1bf3dec608e2b4c9209259c67136e449f7ec1.png',
    id: '391',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5210f0aef57241b0c206bf0adcff47e37ffb0c50fa5bb833b37f38b60f5802d.png',
    id: '392',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f08c8bedb9de715f501ccb02afe8a0ba187fe646e462ce44a7c70b89e3de521.png',
    id: '393',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ac41b1da70c08dba8a0bc94e9ea511bf920966ac08847956c3c3b07ef70dfd2.png',
    id: '394',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecaa85682125e075f5f6ad8d0b0b2c681955bcc99ce2eb29d3f69d563ad36313.png',
    id: '395',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fa568b4373fcdf7ecd52c6e06361825d733ed581edafb0ed61c86098d2f68fe.png',
    id: '396',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e39c3bf9f9a4e629f22e4e9b633a83ae8a1662d75bd35f100a32dd2c1f4ead24.png',
    id: '397',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00b693d3e509e6ffb986a65106c23599bdf381a476c31a624744bb3c602e85d3.png',
    id: '398',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07982077f3c0cc256f0cab14b2a20ca6a6c8476b72118cc64c89318c8fb7c71f.png',
    id: '399',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15686c299e8e889ede20c0449c0e62bb1a166400d7f76b898c7bd3324b2f2e05.png',
    id: '400',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b28ccb02029fd02fcc96f32276f25bdcb106f039050afb069b2c10ca7ee9d88d.png',
    id: '401',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02903474eb2346f3c1ddbd6710df01807e557b129e52f1d22c934f95826e0f02.png',
    id: '402',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'Wood Ear Chopper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f47ec0be875442273fd9d7e608fb7e6b4b2cb484dd43e60eda9fbedc9c9398b1.png',
    id: '403',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6105f6d029778109bca9ec5d3bd9bf8c6c512679a5714685216e8441ae31c2d7.png',
    id: '404',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a00f6fb8ae8e78d01520caad4a3c752f76046bf7cce1e59718880b384d299410.png',
    id: '405',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5de4a6cf0be421b78286e72fb7d3f14bb40e4e52f45305fb80696add2302b5fb.png',
    id: '406',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01b3e1d3842c51df83061abaa1f4162b395b0c17b98d64fd06d70ea25d0de1f4.png',
    id: '407',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24df6faae7a2cc5ba4ec2ad967ef51841bf8a4d5e8407f1cfc155d5573d58f14.png',
    id: '408',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c213c2db349ee778973f8030abffe562736b53c2a35c77f6522df7e65ba5577.png',
    id: '409',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/490290ab30552eed5195defb72fe0449943dc47a48bb352af9ccde925f576e89.png',
    id: '410',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04bf3c5eaaa3c5ece786e754a974389546aa74a95d33733a31d22dbd6c9f4645.png',
    id: '411',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1af97fe9f69ef2c6a11f0c2e0150b716e52afa2952df949a56da3c5e95b12f0.png',
    id: '412',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81b0ba51b2c61982555398813e24893a8f97fe6cd14bf6a0f33fd79cd7544061.png',
    id: '413',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e041b3aec0c4fd641e8ae94d6438d81e1511c79d537d358e9d5c9d273134b32.png',
    id: '414',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3fba0f987085ceb19c6f3b50b8af66329bb5cdc835d9df307731715464573a35.png',
    id: '415',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a531e1faf23e72bf2b90ef90f448ba7d4c348185ce0e7d08f65c630d3f55ae7d.png',
    id: '416',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51e51c63484a7ac02c9a70bb08f65d13cbf3107a3a53c176df97b27b605f4d7d.png',
    id: '417',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95a805b96e74e44633f0162a0b821b2bc434ee6c2caf1cdf72b2d5c3b3b8a353.png',
    id: '418',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1c6f15cb8dcb9fa9dee1df056acfc7578b195fa80ccce27bb79e167ecd67329.png',
    id: '419',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/152bcc7e8ec53e29fde6ce4be91d4497eb143bc72fd07113a463d2df974817b3.png',
    id: '420',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0e2c8fbd77bf06e5bc8471ea4577b1e9ddfb9cbef43023e54828882cdef531f.png',
    id: '421',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a378145d0befdd04990e018d44e64e1ac81075fc9238909f1bfc6e97f736038d.png',
    id: '422',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d5697232c488ee829faf93d6aca8bd494b923aa5e177f39b92df03aab6c45e2.png',
    id: '423',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89947e4a160a64c7d807efd4cfea9613b79cf445be5d1071b310fb244ea2cccb.png',
    id: '424',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0a9c0d12397edd0a622732cb2150d381fc068440f1caed83214ea8c150a959b.png',
    id: '425',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27d414d14936f1b7f83c9bcf091bc3fb3aaed514948a25fd2b9ec28e5076adae.png',
    id: '426',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35236f1204736d8c782b1a142fe6a05fe63fd691da8ac4a53aec21b47ec41954.png',
    id: '427',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/efc19c7b41758aea36e16843f24dc3c833f084fcca2515d8abb3b25a403788a7.png',
    id: '428',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e15c47d07157d3031e31ebf2a1ab6a8b1e02e9eabcfd17ae3644b3330d3d0a4.png',
    id: '429',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa0dff18fc5141dca0c6b1145972ff687f20d1edccfc06e43303ae8bf6e8d42e.png',
    id: '430',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff01ccdd1ab2f0178ddb4f3c6ac987740ca39c2e5ab184461ba4d66b9a81d23d.png',
    id: '431',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1404c7e2cea8c4e2018ae080fa4c649ccaa2d66d9c1e97e766cc7c2225f6749a.png',
    id: '432',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23c8c7fed7b7f77e8c2f856ff5a48701e441ac426273675c9410d7c2623e164c.png',
    id: '433',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bd4a02c8c9a734fc5b5932f987d89c8531842a1b9b95f666c70e65f3c507911.png',
    id: '434',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bd9bdfc456bb429bd739c44052170751bad3b3b844dd98cba91ab35d783fff7.png',
    id: '435',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3df5d25a9a0b1ec4c9274fe5572e125794edb1148ad10efb46da08fbaf0af1c1.png',
    id: '436',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68b700b58e4fcc3c608b181cb07cb7bacd0440d0cd3b1adcee8ab55797bdcca4.png',
    id: '437',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01591db214683046b2e8c4ad8d2b67841ee502fc4bfb06b966fdf3e93c73a951.png',
    id: '438',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec35025a735e6ed3bc5c198904b25fae2c76f1ad7509cba28d24765739870671.png',
    id: '439',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fc8b7cedc28d61c87ad0bd5c1973e0e21b22d1a5f3a688e2a357a711389d70f.png',
    id: '440',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b781f5dd968ff0b3b87fba9637742488677676b9263037e6407c1b604b01f716.png',
    id: '441',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1fe6dba4f0f1abe6fbb3232e76fc408e50d004e371974de9690277e6ac36254.png',
    id: '442',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e9a1556c66c59ec0c5badeba592dbb2e9c7e62740a598a5f745f89eef5c44ee.png',
    id: '443',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32cdaf4f6ed5acdeb497a2e5f107c889cb93ca626b465101e8ba099d35825002.png',
    id: '444',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d9e14b89fa4abe30373ddc72df3a274498c15afe23fb28db82b1215a95fcedf.png',
    id: '445',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eaccc9a67290332d114e5a3d8ecaafc1e0987edd3ad095f99fb2cc3b3f2097f3.png',
    id: '446',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/067a6423b44794d3d6562a2b6e0bf2f34ab55a396939b42c041b9c6d7fb3fe61.png',
    id: '447',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dc91494aed4f3e298c3e42e4345f139923c856cddd3979b5161ed283f4e7c13.png',
    id: '448',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93d786d14085e5547cb78c126362f4b92f86a0ee02d052534d6e51415f2a0b5d.png',
    id: '449',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3365e158ee67fded62ced858696ab4183d0ebda5d4c36d1452d973aa7bb86e3f.png',
    id: '450',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15dae608c39cb529f7e9b5100ec941af61abb879d36979765212f6dd88df547e.png',
    id: '451',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/394a3b0a30b14eaa1169ff2fd7639a30fbca6af87444e845d0b77aafcf697e8c.png',
    id: '452',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2039f369af0b33bc0a9e9f289acd4dce5da76138d56e5e76373190903aaf90c2.png',
    id: '453',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Gnaw Saw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52c97cbdfb3bd088e4053e7feabcc900c407a508e683c880f3f6e34315cab2dd.png',
    id: '454',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/013d6ab3ee20e35dc8cf944390cf91b10267459cf3ef7049c51771dc8ebb0948.png',
    id: '455',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7dda6659595cdec73dde689e7c5364779f0a63b57607178e1f2530839056c3d.png',
    id: '456',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74b4acb1394401974c5c4a36d6f31c82236b2c000f2d4efe19f2888285e939f0.png',
    id: '457',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d18c80965d5be4d4b04d3e7ea73a2d8508fea587057fed43480c3067cb0885fd.png',
    id: '458',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1bccb902c8d24b00228e5c57ce1d8abdb7025b0d6757ac64c27e05030ee4d27.png',
    id: '459',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1e3f15b51306f1546372fb17281c0da81202b830faa294af15e8cb0753b46d7.png',
    id: '460',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/394ba4f39cb09fec57c078e27771f0dddc8ca8a2f084a703aaeae6dfe750142f.png',
    id: '461',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0de96b1bbe529bd5b24176f060cc596463dc5ccec185d46faca2b1549c4d1c68.png',
    id: '462',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ae6dc5dd2aa24c532ca07806209588a06a9f3a56a7114c7f292e7335bda8224.png',
    id: '463',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cb17480dd66417005b9a690bdbbe3930909a7621144edb5267f5b5607d678d3.png',
    id: '464',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1ba73a43115179f46bef13234970189a5328414ec4c03237eefd7fc5eca9509.png',
    id: '465',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1f8fe9edf454a54ef2c8115ce9682d43493bb58a0afe7fd9eb74cdc57bd0701.png',
    id: '466',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acbc808591a11f3b2aafeb87bb5bfa403008285da30ae7e339e50253ec448a5f.png',
    id: '467',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0754df704ab04056abd340ceca4fc6df2c17839e9c7612a8346b18d95140ebf9.png',
    id: '468',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/313b8b5b4fac8a8253d30399c4ab758a7c1169bc2d37bba62d4d4f1507a7d9e3.png',
    id: '469',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d037455a00653ab6b499ba347f1f598a736d5322934fc516f0123d62c65621d6.png',
    id: '470',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/210f5518846910b04a525faff7e61b2dcbf2d38f96dd9ddc4287b13448eeb463.png',
    id: '471',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/637c03e663bcc31b3ec96707c9ce86da0521eb236ac223ab05821510af1e6906.png',
    id: '472',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5988c3c1bdc4bba1b4267a9e9b2080a79bb35e99d2b0ca9f8983c5ec34a171cd.png',
    id: '473',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/030afcf066f558a8cc12096027281dcb5f52ec94dd6952602fc7a42f5e965859.png',
    id: '474',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4517a23c8ffc6131892db0707a7212d0d10e1b235666fed431505bb2a2c7a330.png',
    id: '475',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a435f6c1aea5c3d00af4bcdb71a0e49152991bc3ccfc8b72419baa8cc12fc8b.png',
    id: '476',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00324d2c5e059941b1d845482cacb651d2e89f191e2d0ba982a6a3dabcec1c09.png',
    id: '477',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07fc2af0aac3e9c36fd5d5133c199fbc005e87929c2c273d5db925145b2c9a14.png',
    id: '478',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1f6ee3f31d7aba8650b2f7b4fe3e0cb77df051046d95173fb9d083e89fe1150.png',
    id: '479',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1fb896ad7f7cd675bf678bbfc5612e69381d715d69347ee5e2b2c8ca208e572.png',
    id: '480',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c16d15e172be679dc68217f059455777f923abf9f7d2d9cf0c4ad78b9bda9e0.png',
    id: '481',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16d78af501334d7c04d4b1a1fe9a590ae692ec832210f6c50adbf51bc78b994a.png',
    id: '482',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c2057e395d1788d0eb785971af8d5eedeff3e1b074ca543d07971b2679eeebb.png',
    id: '483',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/850c707929fc3df8c391314bba89370893877bebf5daccf63827b528b8e5132c.png',
    id: '484',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f7a9a66fa1768e0cf9e25e63f51d94340035941bb3a1f0f04c088af5288874b.png',
    id: '485',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03d974e4177a2e2985a7bd5f4c8ddbc45ba28ba103c1d1aa1ee5cc902169e2b0.png',
    id: '486',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15a551554fe166335a51da4c24a9ee4c0ce97dc8bfb61dbab6f4cef4394d4ba4.png',
    id: '487',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d018505bb40d9ca3afec2757eda89a9529f5f29789d9dc685aebe42d38a1d795.png',
    id: '488',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95887d4d5dcb1901dd2ba543c66c95f13d17bc9d7082e85fce3471b518612ab0.png',
    id: '489',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4182d3796ac3ffb7559e03aef43d123a7c931fc3a36a522e8159615e10e4a106.png',
    id: '490',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/889b623163a7ade9b07f2e17ae76f6c8e38ab016c323d86e698bae06cdffd640.png',
    id: '491',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c68b693197adf2b0d5afc83f7077bbbe844e9b53165f96407a0e6caea8eb7b4e.png',
    id: '492',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b650ad74d538b1a2501e26202f4570a792759e64c0a249aa5d524b4cbb625b2.png',
    id: '493',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fdd7c998fbc0b391903e2e3e30ee69a7ceea50ab01b03a0425312df277d632fd.png',
    id: '494',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'The Claw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92a4818989bd9267d92ec194c70380eae89801cd63d952098de3e42d2a9fce8a.png',
    id: '495',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Wise Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d964eca44eaa1498b4a26ac56137c0f8cf2388d7c1a1e8883547f7239466bf9b.png',
    id: '496',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf8d9cb3011e6babfb4d928f1c9e3fc281e387f393f87b4f26d5806016937bc7.png',
    id: '497',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40b02a2b5ffe0daf1299570f95476bb1fde70a195cd10d31a80c556b43d97142.png',
    id: '498',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c735171e18c5cf67252b53572f7010e707f59dc50380da0e92665b45c76aabb9.png',
    id: '499',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fdeffb1f556dc8d85ce8be41a08e490ef4bbb45df26c156cefef382bcf114b2b.png',
    id: '500',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Twistree Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c32bd9cd4fb212ed712ded109aacf074dfa46fe2e002a74995c43522ce58d930.png',
    id: '501',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49da486b632666afeb9a41b0e88afc5b5fcdad904a6e1acf08c689ee5488de00.png',
    id: '502',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33a573e23eed73f8c4594e22e9ae9672626d8f74c5b7f8342b251ab1ee79314d.png',
    id: '503',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5dc8fe153aeb14f7f7a70cffb17474fe3503bceb6acab2b71b6b351bd32c33ce.png',
    id: '504',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a50538f08c440c57b701916a272eb6dc789a8c38a1be642fad8a3a21d225251.png',
    id: '505',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72293323dbde3445617e504b64112e77cd1fc12442b339c7ca045a8f23b6addf.png',
    id: '506',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba3956c1704cbe18ebd1a8fe64b07655d0f88055631e1823d44c1b46b0541ad9.png',
    id: '507',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6f1b2daee769e93da3c5eb43bf5b6c637b4613d4f98d85a30e4486ef9db7f91.png',
    id: '508',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7edc76917500eea2d6b18a9b5ddaef2a6e02a19ff11228ba383e494768c0afcb.png',
    id: '509',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee08000545b01342ae9279ed837cdb977e0b2f66ce86ad787cd29b21e2a480f5.png',
    id: '510',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Sticky Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4361d915efda89a11266b04eb789d98780262a1a74c8e66cee51457853c1d30b.png',
    id: '511',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33838fef977e6329255aa3a8c711e103663aed8de667fbf8545b3824c7635a9c.png',
    id: '512',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40aad8a3f9d4745d0455f5998f6cecaf6416439d5048c5f2b83a8fa64363eafa.png',
    id: '513',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89db2fecb0113d2757fe8c9aea5102e123079a81770a030cba2c680b69dccd35.png',
    id: '514',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/786a1cd73d7f49880925b15ba8a5d3b3de8c90026c3ad78f731e6f5914812d70.png',
    id: '515',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e320ebd3dc0af57232e647802d0f7ee35eb538439adaf7c3392395523c0db7ca.png',
    id: '516',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5433ace196d6fd82c43707e279b11b90741ac64a67c2d007c082af4e418de7e7.png',
    id: '517',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79666ee483cd1630b41f002052b2f813d3021bac9d27e33491334c5440dcbde6.png',
    id: '518',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/097636f4afe9514597771236e9138dca709510b868d73fd27c3609c8770345c8.png',
    id: '519',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33e10c73bc716312f8307f8df9c091f3f63c4b49a3c0f06d3d6ab45efce7718a.png',
    id: '520',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e565cc7fe45c3131e74e190c339d8d4b91dd74c4c90bd96d864e30e566eee98.png',
    id: '521',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89aa3c67bcc9226285990193378a222af2b7061d3ae268d2f4d87d6c09f53dfd.png',
    id: '522',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/231e10dbaf0e1baabd4ecd0dbd15fcb9967d5f3e8a3cbcebe886347d23a65414.png',
    id: '523',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e01e045cd04978a99363aee5ebddaa0bc58169d7c9676c2bf83a287b0fc6ec4.png',
    id: '524',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c7a839310100f727647ecedd2138328efd4664869617a19a1f81a63f6d82ac6.png',
    id: '525',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a85ab2257cad511f09d24e5c5655d1c5a3b2d3ae93768c618723f1d366fd34f2.png',
    id: '526',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b05879739c4ff36bfe6baadcd69d54f644f369b25ccac51c8cee647684e94e2f.png',
    id: '527',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4071b2d09d5d543895923ae7e70643e99cdba4d0a923021ccc4c2e7706a5246a.png',
    id: '528',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4615cfa8723ac0dcf2ef87da13ea9cdcb58f1af7df66bf3a64d4242f47c3c2bd.png',
    id: '529',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Ravens Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53e4d3806d0692cad45850f6cd6f2542f273e42393652c9100b2b0962249e351.png',
    id: '530',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7650994ef0b404949ddfefb3ca398c8affd888ceb09f55968225ed433a1ddf1a.png',
    id: '531',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df9f51f72bd1ef5b5322c8660162e695cfbd5956bce20f7330994abad47c8c6c.png',
    id: '532',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/838ef50ea2a0f837e297d5eac6532773ec8de5b3b2086d0902a889e0e2f01dee.png',
    id: '533',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17b599221cc9c948de2686751d59561b0525b2116e52f3c0ffb2b72883a01726.png',
    id: '534',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2bac690e914a4ea00493e94715abdb650b554feae8f5f4fa37129b3dcdc9e70.png',
    id: '535',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c3956eab17a8b6cd84b88258f5ecc975687fe69894dc726cdc92e4ca417bdd0.png',
    id: '536',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/214b88a2bef1a7df0143902dae9dba8615c723ae580ee3a5ab93aa2d819d1c69.png',
    id: '537',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee5decbeeedfb16505e4e243678344c44c4078b2a700138eab57d3a33ed239b4.png',
    id: '538',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca3912510e0671c1e75e7f6fae6d57e617d831130a9460953d5b98e1d639bdcd.png',
    id: '539',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/583dd8568835d340591d9b8c3eddf5cefff846ecbd755f16b8293f9a097b6c0b.png',
    id: '540',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58a0b90763f16782ed28ac070d83cb29ddcf9f1c366d773c768b14a5ab59ac2f.png',
    id: '541',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2008d69c596ede693be17a08c9d0036dc8f43fc75ec904d5f5b88c028ee2e09d.png',
    id: '542',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/238e18a236961622b8d9bdb011f03d2b92fc9cd7afdacc22a7db67ac10111a38.png',
    id: '543',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/489207c75e4de40bad28a3315f4014ee48b815574167ba8e790dc9138464b68d.png',
    id: '544',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4850e9b9568ed90598dd6ec6a4bc368ef19057ee9adf3ae9fe738f28950d0c4a.png',
    id: '545',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c500cb83b80907970dcb605eec307e3652f90c7c51cb1148528dea8db65f63d8.png',
    id: '546',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b61f323412cd903b55074860c9977c90b1b37dd1ab84847313f36812e5a321e.png',
    id: '547',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b37394f01a079ccbff2ea1b6f901471eb98d89c763d1684eb199e9a64bae416.png',
    id: '548',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5f90b6202cffc24ac5d85a264af6ec666fb5c813fe3011908a57c66978b743f.png',
    id: '549',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/156981e87233e582aba3280985409d10afb0ae49536f0506b55ca34e0e52bb53.png',
    id: '550',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e1f83a390445f5ea89ed4715304f89da797041c2a5668495ca1029d4d602475.png',
    id: '551',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/042c6fb0ccb1f98a6930ae15bca64f419286e737afae5f2a9f404233723346de.png',
    id: '552',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27656dda858eab37d2b956ff3c0bc3cafd5f9a2fd90438d1ae87251e3e3016a0.png',
    id: '553',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cdd36402a8ef8e5cd627d905c06ddc6f85bc898d11455fc46bc80fd9e72cb29c.png',
    id: '554',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc253f755fbdfa30953e62c20f98bd5dd63abae11b751d928bff796ff95e16c8.png',
    id: '555',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a337bee33db6829025118a8e781e7abe3f179aa1296e5340084d77a813bc7ad2.png',
    id: '556',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b44dd56f487d27991919b88af087e64d909c8bd19a6f1a782c63ed1e1ced7cd.png',
    id: '557',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09e0f2dca4ade4432fdf115759547d9b7c178c32ce3e39e5128ec0d43255efe3.png',
    id: '558',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/599f15d02d73d275551678ef6b6ffe433b9cdfb6d155819f79b9f40d27223763.png',
    id: '559',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd24a6e08325ecd58c0de5d4ce537d4ba059c3e12dfcd3abb08a15608979cdd5.png',
    id: '560',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Wise Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/937fe4b94fc2755c42b7f2c9d5285755ea9a9bb38ee6cf64a2f963079d689afa.png',
    id: '561',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40b2491ad97434b21e3493d1ef906603d230d643b610742006dadbdde9b882e2.png',
    id: '562',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a081fc22d5778ab4ec87c203b6ef2e6dbce0fef98e41917d9b6c9f9e6951cd4.png',
    id: '563',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/580513c727609e19477dc5e905af117139aeea80b8d2c7a6ddf23478bf702a28.png',
    id: '564',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7cee5fb528854f18ac9a29fb538e3223c14be3505e2dc732c09d99a9d349b54.png',
    id: '565',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec4fe439911fc871fdd69dc586377c42356110700c89d41edc181caa40bec557.png',
    id: '566',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dbdbc8ad4bca32efbe83246c0f0610e7a8c152406f3ff5e7e632115c3970fdc.png',
    id: '567',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8793d2c324fda159ba232b2eb57011c2563a00a3146281e09b6d7cbd080f670.png',
    id: '568',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd71b7b03ccfcc588c78db00d53e65924f7a4d9e09d04934101e3b2850e14c45.png',
    id: '569',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2147c2623162356f83abfdee5a3b5306f1a69f4ce5191099c19f489f7f59bcc0.png',
    id: '570',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e54af4b92687a68c140e5db0767a906d644d3f70064312e4f519a7482422fbc.png',
    id: '571',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8fd5b1a11f2c5cb78d84d47ecc335e1c9359bf955e612f7719ab675b4251fb1.png',
    id: '572',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d96ae439448c3517425d6f2147bdf8ca25e4d8e1374ed9831c45690a33599b25.png',
    id: '573',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee71a38a45cafddba4ce17be13fbf994dd2cd3ce70f5f658dd49693cd7de265d.png',
    id: '574',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9fc759fce6734280f1081fc839c617006f5f2ae00307f419aac6a6e75dcbb7b.png',
    id: '575',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec82a2a9833cb7fca96f14137c08570357c0f8f90b54d2170a1b1dba481ce653.png',
    id: '576',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b087237d75b83cf8cf198406964f414a626a3738131699ec746e3d9fb186de58.png',
    id: '577',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12ea6eb87f08bcaa5c677bfb4022e3f64dfb163f5c567008a8a6ca42c766b7e9.png',
    id: '578',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31d5b6bfc01c61a1ef534ba27a62bdff833f1152f5b64ed9bafcf3221f297bd6.png',
    id: '579',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c1d47af1d217e1171f5bc9d06131df5fac1431d07f25e643fed6dfcd2bedf27.png',
    id: '580',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26af963a61d659f95d5d3952cecaa51cf17fbd0f560c85d0002ecb85628f26d2.png',
    id: '581',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c158f0792bd99e91945eacc56d5d9b3530ae670aa1d2dff4be93a1a00d21e80.png',
    id: '582',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58084ea3de93bc0c742a2f35ff48c143a6987214c601d72ef3cecccac9571453.png',
    id: '583',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0934480ad1aaf19dc271ed8b8cc7eec5333d3c53fb9040dfea72f00763bbbd8.png',
    id: '584',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35af74f5c1fd6936f8d1b037d8c329732e320cb8abeb1a037ee2bd13a2073580.png',
    id: '585',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5bd25bbd2f0bf1fd63b16de78abd96b6ee292dce25c0793dde22ef7a55255a52.png',
    id: '586',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8342ef2bb7b46490fdb46f3b817a847f2dbc838f9bd90667f47d399def5b65f.png',
    id: '587',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24943853ab5cb872a715ebd74a7597c576607341479ab583ced60fae74ecd882.png',
    id: '588',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/065902f9135874a2d72ea2e194a81a6059e8056bd7ac59759ea71556ea1d1bd5.png',
    id: '589',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d89c41446c7dc281c20d3cfd24a389863f39961b03643f697c5a22f090a5c3d7.png',
    id: '590',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63ac6b4f06963fc01e8d3db5b34961329e8c6a04dff047eae954b3bacb672d67.png',
    id: '591',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8023494849d87ae48cfcb046bc3607da964bcf96d4417fcf41d2add6a1f9e75.png',
    id: '592',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cc447ec440611722afa2714558278522eda870281f310849a05b765769d3c9b.png',
    id: '593',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bone Hook',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8c85b337bfb31f6895793cf799a8cdf0575f9e069643673f3666b2958a74332.png',
    id: '594',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b885c8e276af6b85c8ad0d2b48de6eb9d263ef2f89059cef347802895bd5f7b.png',
    id: '595',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ef924b6a69585a367b6d6389bd6ff5f5746bda4760b366634c3a6ccf1f1d289.png',
    id: '596',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b55f40c81368770341a43be722c0af48e6cce22cdbdedcd562076477e93771ab.png',
    id: '597',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8836dc4498b901f8d1df68d8668bb69d1af1062ad6fc3ca2d582a4f04707a16.png',
    id: '598',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65ca5cd0f8b1ca65fa763d6b8857fccf3d2a2f2f83353f531bce9d7bdc394fad.png',
    id: '599',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38f684cca511268784741408f3256ca2f0906bc4dcaeaa64524f7ec016df0e0f.png',
    id: '600',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59ee6b6ae418cc39c37b1e8c4bf5d4fc3c6eceea755ba9fa148d432eea2b49eb.png',
    id: '601',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13713f11bbbc1b3fb1566fb7cfff2c63b953613abf8fb1a8a7ecb71fcc90e91a.png',
    id: '602',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e1ced91e54deac31bf61618853326a6813939d29411a4bfc766eeabe807f93b.png',
    id: '603',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02e4e228b38ab07f9b80dcac5d191d681a421236f8e2772511f1a05369ca0433.png',
    id: '604',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7216d8d3ae6fc64c16007d48524edf1bba139fa81993a6de2dfdd311a439794.png',
    id: '605',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a2de040f7d261328f65a7165cfd704f38df4af2531396992eff38f3280baadc.png',
    id: '606',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e0d117a16d4e8d929ad88a65f6e90a90778a491ae8556ede3356c937f9a5cc4.png',
    id: '607',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f896fe604ac9110b89e1aeca1c3e1a311b25f25acf2f77ff0a3049ff93c491b3.png',
    id: '608',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18881486632a954ec5df2cb5f25be172d6cbe6fcdca0c4c0212ec3add60581d0.png',
    id: '609',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90906861f4aa900c6fe5ad55a908fb81b765acc18c091f49841cd7d4f96cb32a.png',
    id: '610',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1a456499a323d1cf4754a6ab20302c743e8bea593b338e4ae412b6a2451bcfb.png',
    id: '611',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4be3599f6b6cd289e37e2cfb039a34965a8d15a75f1ac806808739c80a4faf6.png',
    id: '612',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc5ea571408a8cb0d95ef12e8d5bbb2ba86c7c72dbc9cfc18b318d2188228c7e.png',
    id: '613',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3c10421bdf6446128ac9fdaab0e7a5881775382e1fd6e4ff0bd15c20d94edba.png',
    id: '614',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e81794747acf13c2b8de29625b1caedd9f24607952eaff6e8e5d136c1d55837.png',
    id: '615',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a47386b145b69d2d27adba314f05f3e9ae197474cdd83a4ef9d39e08901d82bf.png',
    id: '616',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20618f2a2bb44e265d9cf964628b583373bab4f746682ca79850f4f95d47f00e.png',
    id: '617',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/768a691abe7c94e81dad2f0bd25b70a611c4256cd3b4b3c500cd07d6ed14f785.png',
    id: '618',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ed9c1f218f676b1415f2fae0a437853717afc8ac2cebed4141e3992fa9f7234.png',
    id: '619',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99d36ce398cab66de7920f00f86fa069c679e434ac32a0bfe9a9ca0bb50ee6c3.png',
    id: '620',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9249f9d1ec630c285cfafdca3f925180d86ee6e4ef2bcb98494d59ea8737581d.png',
    id: '621',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/676aa20e2441ef473f2c05caf5ed7663b4b05de70258c6a59d8ef1f6bf00abad.png',
    id: '622',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c2a3514aa9dec36ba91bae7387a227bbad9ed723a497071cc3b4b32b14783f5.png',
    id: '623',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afbeba99778a7cab7ee78f9ffafb8a07c6e14f38029067474bcc6827f4bfb0a5.png',
    id: '624',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddacf1b523a6466af7d995d05b4c49cb67a445671cfd404c7d6402ebba40fc9d.png',
    id: '625',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77af000e8829e9ad33f177217d5473c2560f8eb4d611e3070ecca00d482fb0d8.png',
    id: '626',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73b1515e2f352c597d5d5910ef84877406a8b0314b8e60e917c6a195e2b85f49.png',
    id: '627',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1b747ae6f82c05d1a646640f4daabc56f4bb4c4cb892c5f1f755161f4104c6c.png',
    id: '628',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ca34b6bc0b0ffba08bfa302b3302fef490f4b5a0053deeb8071bbc811bd9be0.png',
    id: '629',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5123588ada06912b5d0392b638bfb58f07d88e5b3b605669daac6ad988ba125.png',
    id: '630',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9163f4927bc9e36a1fc6a787d065394231cc3c8087ea19a61a1e767f014090ec.png',
    id: '631',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee98542bf598cbfad33f02eb071df3c126b7dd11983099e1006a397ba97335d3.png',
    id: '632',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6393294a3f5489aa3810beb34aebe157714daa728c0d8ab3e2d61a7528616253.png',
    id: '633',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/958558f702fd0fc87af0a0009cff35bb45224a87c8e860f3a984db868e12cef6.png',
    id: '634',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d23a586357e6a57c8e4ddaa42664f43255c109b61def92b46f88e320cb322750.png',
    id: '635',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcb9393d462c0428e349ab5fce5b430380a86d44c47c392a0335c299e9d7f7e0.png',
    id: '636',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afb6064f3309100b7ef0a386bd9cf4f0fe61a90546cb6e59309184820c7b6a36.png',
    id: '637',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70af5f9a9c98bfe55a052dab02bd136f7bfd742f105c3e3f89885ae2559f6902.png',
    id: '638',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eba295567145f766cee182f49dc687d755195719f378b2316630e1ba7b7f0896.png',
    id: '639',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9063d99b4cc5425965ef78a7337fd61560536628f8ed396c10f80190d10b1434.png',
    id: '640',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce361a46ee6d401e812236820776cff9bd0bcad255a055acbe78be87337dd299.png',
    id: '641',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ef0f8e31c34eb8e2ce052921aaf3e4fe6c4a3eff215fc932020784f8a53c1c6.png',
    id: '642',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c741caa71e2f178c96f67fa47ffda3e17c59adb3a78cb55673cef22795e7437a.png',
    id: '643',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ad1ebb88417c0e403a67478468286b6ceac559b1cb2ff5c86b85a2745f2ba9b.png',
    id: '644',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08baa402972ca1fbc98684fc8d4c97095d30ae902753b67da35f874b56d83e75.png',
    id: '645',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dfc9ff6ba3c0dc5190850c25370981b2a7d9bdb6690709c90bf574f5ffe826cb.png',
    id: '646',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be239a354bdf823298c7ff66b6ee4cfdc453e103adc5342cc0d0e4670dc68f9a.png',
    id: '647',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8cf646826b0b14cab149329384af2e5b5a18d49e47b8dd104c5588b6e438b835.png',
    id: '648',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95570c2b8c3ecb21af09ff4d3035cf9f9d4a3590b808a0a40eaab08093be7a77.png',
    id: '649',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7ed461c81418e503d22de3f6de8556fe1d6fbd8ea8358b46fc08cc9b5f97b5e.png',
    id: '650',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cb7fc534ad6b0103bce11141e6d93f766077ce4f327508e80e47edb96636918.png',
    id: '651',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73902b39f550165a37ccd9511248780fc26034d9cbff602247cb5c3d07de2a18.png',
    id: '652',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab0afb01fe5c11772918bf97e7e1c9c2ccf85fe57921a1d240b96bba45eaa608.png',
    id: '653',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/756848f9a69993a6d94e52e39861e94dcc61ff09c913172ee06a1705ad24c7db.png',
    id: '654',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42afca4a99518b7a14ea37fa9b2ca647d8b279182247b1259f783652c9982267.png',
    id: '655',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6aee4012029e997f922304328b12fa9f37fd5301ea6b661e928690b46f90cab5.png',
    id: '656',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6243cc28bac379900b73952734ae710d58ccd6d75361f6fd5b9502e42775368d.png',
    id: '657',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32100321a1903486186ba80e0a27d0c2f4c47fd50e953fc9df56cb53feaf1827.png',
    id: '658',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ede9b6c9d31e35478031c9d811ba05b30d6f79efea98d51e3cc4063d11b3b0d9.png',
    id: '659',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86382371e90ad66b10a182f3689e2f5506eb33857e043031ab4a01b04921d592.png',
    id: '660',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cfa9e05e957a01dfd9a033a42f091485bce899138eac1d5d9ae172dbc13ea18.png',
    id: '661',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed8a27d0a749192045a9e6d686b368a21e21780204a455486eb587345fd53ae9.png',
    id: '662',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3f9d0c151fa9c6613e0342bc29538eff694d4f0d925dcee05ab64377dacc0d4.png',
    id: '663',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07e470a49cbc1a7ce76ee897971269c13807b1f68c2d0db0d727e0111279e5cc.png',
    id: '664',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbfb707c266d922d734b9f7188a5d9c13298dfcc5d95b1c4632c046049cd5aa0.png',
    id: '665',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e1b555baf03814c2fabcf960a5a9e7ae9ae3127eea8bd0a6d3ff83934a1ea33.png',
    id: '666',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fea7c0a308193406125ac9a90e807368352ce1a61c3c17fc93aa528b5a17899.png',
    id: '667',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/886e4f63269671516bb1c85a1a9acc613c004551e248f4dc317a03c0ab40eec1.png',
    id: '668',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db9f89c643178957653a268808d57710dc460f0792d0facf331eab79559da5ee.png',
    id: '669',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cf282e639da3377873a6daf7d466758411fde0379e49c494dd28bc3b50f178f.png',
    id: '670',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3f96dbd2e6222a863bc8d5671cb88441bf39be9a83dc4a2f6ac05942013939b.png',
    id: '671',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70ccbe1fcdde0eee13462f5d1167133b31ec5ec739c706dc46402aa43b278b9c.png',
    id: '672',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/016f2173164cbd8bcf784e935d7003e61aa5688142a5de1721a760bf1fde4025.png',
    id: '673',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36829aecb8c2ce43dcaa4d0cdc1d4b440cdd3a234e25e03c1b2a8a5a59ecbf4a.png',
    id: '674',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59337c7a25fb3cd8dfd2534125d61b78803f5b929d0947b351a0ebb35b2dc6ed.png',
    id: '675',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afd0c3087119853ce1c057d2be2fb5e4b1559c8f52c6019ab088702ec80974ba.png',
    id: '676',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6112849352b419cb30da241c2ab589619156e17bb017fcd9acd4ffd865ccada.png',
    id: '677',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed1135c41dcd6b84bffc9ef5c2af78c1880b36d476e3fba6251fee0c1a00b08a.png',
    id: '678',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0230255cd4aec8be2fe5b40a787043b06ad4bd0f4f343438cb629c2864faa10.png',
    id: '679',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/457fc0c411462414fd09e462b8c0c2c1f086ef786208d18498dfa78c15c8fbbb.png',
    id: '680',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f95da1086c2831a42cdb98cc3f1994114b417fe446803120574ff22d4d627d51.png',
    id: '681',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad6bce82d798711662835d451eabdb97202b7f854a7bdf79ec1da4ee8aba69df.png',
    id: '682',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b984ae61596244d94fc0b9a234a11a019401a95d275d398a3ff880b37da6c94.png',
    id: '683',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ba18aa15fd42b308d84ca51524ecd2cd55ef49d7efd3337e22b9ec65b1a7330.png',
    id: '684',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58aa6d974c2b4c50bbc5c5b97ec06e02b18e41722882f0661b43db52bd5e046b.png',
    id: '685',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ebfb367a4430e126ef9a28c3c2c420090ef736e1b28c19929481cc29c301f13.png',
    id: '686',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc16875bbe667c03e08c7a7883c45d8cd482eb0e0c0e61f154c9edbef8634507.png',
    id: '687',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76a3ab7bbc6d46717420e71bc79ce43b14dcf5d8ddb99edc7002e437a1756cb5.png',
    id: '688',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b702b1ed7db1a54c93cb0020940130703a54aade2d93d874e1c20c03b8ea52ed.png',
    id: '689',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e1e888f728af80bccd657793fd5e9be36d958daf15adabbb279650b73b19463.png',
    id: '690',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02e4b4b6674c2193128f16237c07dea4eb640231e559dba64151f58cb105f97d.png',
    id: '691',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/246bc8ff79ea88d133c5394c11ad616e4a433478f8d6b2e7c8b2b5503ac1ff31.png',
    id: '692',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12aead0c1acee36f36918bd4fc460527d45f6200715f5ab281ef8670b1df95cb.png',
    id: '693',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c736384fce9dd45b286aeb0be9f45d9623e94958f61c5f4f2827f047c08cba20.png',
    id: '694',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90f48062830b685e3ab531bb690dcbc5a6e495a3d502badcbc4cda3a71de907e.png',
    id: '695',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44f5e64b0a3a35e0091826b814fdf051a1eeeae9ca4fc118f6a7eab738c6f553.png',
    id: '696',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1dd32c4c433c1814afda6047aabc3c824611bca96d1ccc27ec66876dccfd64a.png',
    id: '697',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/097c36a1db0b950ca5312876b074ca29eb4a01898eae7ad28ceb2a5dd224ff62.png',
    id: '698',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d4b982cd6d49356d2a6dbdc34a0ab29d0cc0bdb88cbdb01ddf5081b314ad325.png',
    id: '699',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67502325d48eff6f5c4e3259e950d451c19bf8d3942ea47191e4d65370b9bd3a.png',
    id: '700',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58c38ea54ca342344e569261851407a96955602ab1b441b4290ef92d47225a83.png',
    id: '701',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07269a1b8c5a45b57cb79b5885000a2a95a2bcd998befe6d58df5e125cc90832.png',
    id: '702',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30e5e0cf10dca67662330ab44439370f313d83aed1b8b079be063352b2b92ae1.png',
    id: '703',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a14f58e25e7bc5faab02d47358f31ad1f9668339c503e7072efe8b24f5916ce.png',
    id: '704',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d4bae2c684786ad9c2b09d438bdbc0c1aee8fb8f96b55c7759c88d3105b7ca5.png',
    id: '705',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c2e933425f48541a0701da38ac7e6f3dbe0467bc79abd1fc9319d905c5c9024.png',
    id: '706',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4058edb523795adbbe1ce7baa4d4352ded1e729b2f34bf7adfbb86167571b9c1.png',
    id: '707',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a248cc392d3b138fc22cd354259e4f090f87d94bc69fa0a715f2d1f9f7ed38f4.png',
    id: '708',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c7e04681212d3a5cd2606d6ccd50ee3a48e95bb3d8964e1744b3f3963ef14a6.png',
    id: '709',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b723b17195695d8c87946b8765c67bde29f572df616011c5ed3339907f7dba9.png',
    id: '710',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0eec7be14a6e13e77676d57276c383e83797e0cb3ab3bbb19916511da65eeec5.png',
    id: '711',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ce53511671165ea5b941892b0b1c2724e23ee3784b8482c16588f6391f3fe57.png',
    id: '712',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cb5352c15dbd98aac6a399e556b6923418bd3e47d0662f62a593c79e0817bd8.png',
    id: '713',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d06a6aaaf81bdb6774316e7f54063c290bb99a830c8f477a48c9a9a60479865.png',
    id: '714',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c36226493ddead0bdde1cab146edb2cf64b36760d81f9427f57bca63bb14a58.png',
    id: '715',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aeccac687ec1efa33280658da17f12bff2e7d8540802402e8322dc887792a535.png',
    id: '716',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04dfbb9fa93bad06357440fb144c21d703b0a91c960e8d54c6b048283ac533c6.png',
    id: '717',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f240cabb6fc3b4bf612de43cb24cc01a4efafcfe03f40d080a924136f03603ef.png',
    id: '718',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ec4f81328691753fb392a95aa1aced70bb259fa19886d6825e9a065f2582035.png',
    id: '719',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/086d7204920f2ba8cbc2a26c70cff195c7f59fe56c0496c85957ad087ea7cb16.png',
    id: '720',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e5cfa7e7968ced7f3d3629acc0d42780c27ba97a7f3c90c5423ac9de6c552e1.png',
    id: '721',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6d94d179859ad31f112084fe4befdc0bfc94e1ed5842352cb5a12a99b9a3871.png',
    id: '722',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2c97f78f15516d69e06bc1cdc6cbe10b1aa921dde1a823b72369a6a3dcb186d.png',
    id: '723',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c64077188a3913720f6c4e295b67ea82557b5d2014843f6f9944765495335c8b.png',
    id: '724',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71ea4f30a3d0c6cf06fc846bff318b2545cb0ee9c13f751d71cb8b1097f47539.png',
    id: '725',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0776e78711fade2860204efd6defaade7864cab9c9b52b86faea1bf4410af8b.png',
    id: '726',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e200516a934f385b8a0eeea4746cb314566a1fe5ee2667cdb3536712b9ad4e4c.png',
    id: '727',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c055a7bff6aee9b900af9c6e48064b36dce1ffb267cf4aeabd6b490dcce4cc4.png',
    id: '728',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bfecdcc44e16fa5083c8b015759f6dcc2d5272f0b8591e1049fca799cdda7d5.png',
    id: '729',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bd851582e4b50e0ff813a1e708a4c090eb1eff985e40e94f0f9ac98f6349f3c.png',
    id: '730',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b5e61149d3532c001cb69b5cebe87c9e9041ae6b4fc407e0252ac22570c6bd2.png',
    id: '731',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/051bed066422e56e66112d8fcc114e8fc5d19782090ff850ef54a9293d2e408c.png',
    id: '732',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f122067588c320c087934665cbdc1d42fe560b77524b2019b432f0bca5ae2450.png',
    id: '733',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69409614ea65f23a1ec5ced6f7e0b22409a13c628ac85f1659845c33f4c4b574.png',
    id: '734',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afdfeed73fdaa9f6dbe85886ac605344d139f674dafd4d8b337d3b3975662363.png',
    id: '735',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbcd2b408c36bf60eb6a6ab3936f294bcc7a2215a2f1da6ab3e10f7157709c19.png',
    id: '736',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf4db6d7c04cf977515f9fbb99ccfc0b7c3916126c0a36221b3d1089a869f94e.png',
    id: '737',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9415e1a356bdb98ffafa363060b8142904e88cce61156d0c26708744cde2390.png',
    id: '738',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae73c17af54007fcce17d573aabc6728d14147323e7edeb1abce30d383686544.png',
    id: '739',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a83fe895ba8b6ec5e7117d9fac183225765ceb92d6b0d2624d8bb7147412bc66.png',
    id: '740',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddbec58051dcc065e2ccfca588abbeb958570d95881a67ca7aae5ae236f71baf.png',
    id: '741',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4418f4b8a1a023e55dd14b1472189bca68ef2f0920027dc66fd0875ea10a0978.png',
    id: '742',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eee744e31ed71587a670df90f4041a0620bba3c734dcac3927e0d10efac539fd.png',
    id: '743',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8dae83de5cfe6c4f37fd9278d04c76a65623240b0617aa28607997bdf3467648.png',
    id: '744',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e64b1bd18c5420b8a3128f4d6d99c22886e6c7a15b8dbbc57bdf52307e90be3.png',
    id: '745',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e56dab0381120fb6936f866aa4ccf80d1436024f9e2e7465d8b5840256ad7fd.png',
    id: '746',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5d48c5bc4695d0ee134b9db1bd40f71c58fc1c7369f797539b89aab419494b1.png',
    id: '747',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d124dc12df81ddf5ebe95bfcc9f4072352439c673c757a4e22056b4d585d747.png',
    id: '748',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0bcca8e4baa6ff620e9463136e62e4ff79b196c2f86ad5aba293559eaff90b36.png',
    id: '749',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f169759dbd98ababdc66045ad7e9f599f7d46ed0f5671b0934d3a51e2203e69.png',
    id: '750',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72d450d5d12d9d2af9598d4add0e69aa6bec139fb548eb695adff2a0dda09b0a.png',
    id: '751',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f6d097b948a6c568dc5e84070933df453ddcc39a2ba015b8719a219a83e81fc.png',
    id: '752',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/358933cf2f0618efd474cdf363b6e0ac0693c8b27b77fc58d96d195ad13def7e.png',
    id: '753',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f36dd6e3a45776bd4cdcaad91ff6a864a0ec1d72087445a6ff2a7c67fa6e805.png',
    id: '754',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d39f9178184b2d78448b49db0f6605cf0c6d6c9ac96ed34e53d5ba7ce97d513.png',
    id: '755',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d4b773ee2dc6dd3d151c6c6d539f1514d7b649ffe2da20a3719c238e0b4431f.png',
    id: '756',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ceec9a4aacea737d55e1b7f61a528df52143bdb8e5f7e691cbf3162a0c5387ed.png',
    id: '757',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/373f52b8f5c7924077260102f3c0a6af756a4c0db8b4c49ee354eef23d18db45.png',
    id: '758',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a53397dc8a68c7f4d67a120ac5dbf004e78099a8bc49035faefda57e09c1f3d4.png',
    id: '759',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69da8d741a8b08f7200f7f7e34e850a13af49c2c5fadae3cc748a3c7bd051928.png',
    id: '760',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c33e20160b679cdb1e456e50b772b26886b603cc78fccb7702ee937fb7e7392.png',
    id: '761',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b45bc02b865b92e3a0df50c27d90c3d067a242973781a9d67a0c8f9095a122d7.png',
    id: '762',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fece9106ce17cb7970d9c3a5f3f23611f2fc45cad3edd4ddbddffc9958d37f32.png',
    id: '763',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa4cbef38ca746ae6a0707620cd7ac178cce39fa9173a12a3ac393c0057ee724.png',
    id: '764',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1166a3f1dd3604d750165ac66d5d2705c33c1e392485de6b09a262be58d05baf.png',
    id: '765',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b6caea5eb3a1a2db7800260e809651b7618ef2f4d2937b507cb161968716251.png',
    id: '766',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2075d16cdd0afb98b29800ce5bb1c08e47a0828ea6e9ac637139ed8d32c4758.png',
    id: '767',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ab73f654e324fcea578b7f5106472c4469e2199399fe154f320c7749d9aec41.png',
    id: '768',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb4d14d29ec567d63613f06bc1d19136b32aa113391e47b6f22a587b029eb4dd.png',
    id: '769',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a1beed35e790f8c00a565e41913050e83b5d39040bd788b5bb36b52f0806973.png',
    id: '770',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b055516e391a985a51548359db81fea95ef8b6efe4d519a51fb302ec013c2fc.png',
    id: '771',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c726e2230488ee3cd406e2d3b4159d4d5c73b98fdee9b5ab5d19012d9644346a.png',
    id: '772',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2ccebea9f154674d5371970b5437eb4a26c822ce0994bd50091511b976a958f.png',
    id: '773',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16578909512b7f97b53eac47330b6aba4648bd7a03cf9c7bac379eb45593cbb8.png',
    id: '774',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/300d5574a249f19898e403ed9c917626f7a7d14a4f5172ee98ced33b1690fbb7.png',
    id: '775',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acda8785d57e2ea5cbb69c04eedf457e730121fb30d392613555ddc4c4f7e7a7.png',
    id: '776',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54a44422529ce7eb3825952861e135106aa62b31205fe1c3cf9cefd21d3679d1.png',
    id: '777',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e543a54ed354c76c7bd2a7caec0da641404d4fe7dbe9d163be590cbaa20d57b.png',
    id: '778',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7415ca160b45db3d92cbc83f405f0f4d083852eb0b41ce956701c7af97388d8.png',
    id: '779',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e51f349a9363fb9fba4a28adfd455ccc721823204250ad846c0598df2f6e174.png',
    id: '780',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52cc62a81aabe13acb5a09977b8de0c10301cadd5b60697f312591fd49655294.png',
    id: '781',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f22bc61d26b105f63cb54fba1a813061d3842ee0f67acc4c9add68194a32316c.png',
    id: '782',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28e6ddb5d19b2043e1b10cc77ef924b0d62cea7b6e3b54ce251f975d414d560b.png',
    id: '783',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89f34461c44ca473e70720659c8a686092498b37c6abfa2ba91b9bee703e9196.png',
    id: '784',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc1118327fc866dae46ca8fb93b39d358e6c8f1e11a5cde0f79781a0d6e6f5b0.png',
    id: '785',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aee42a26d8c72760054c1daea28ee4934c02494fe1a78a1580afe95ec21de12d.png',
    id: '786',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Undulate Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4b6dc56fbf14f58daa49915c28d5ed2ca3798aff119287a5e4d359ec6b14860.png',
    id: '787',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1dc904d44d0b58d1ee423e211335a7e25ace780356a80d4c08aa4cdb134a7514.png',
    id: '788',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3f8067c6c56753a9bfb3b6104d05cf03f4ef44653978a2ebee4a5f73efbcc07.png',
    id: '789',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61d75db95c6daec0d56647fcf94f5a1c82ba903f03d5f80b507b1d3e85114f7b.png',
    id: '790',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a3a327617eb9109826fc7d02d04ab7d0fded2e0593d04414eb02a1d746d8973.png',
    id: '791',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7782a5020ff13e2e904e4a2abc381ee6fc749117d2f79a76fd5002c3f101377.png',
    id: '792',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8041efcdd32ce69c2d573ab8fbd74107d376327ed70ed4c71eb10dd8abf510d0.png',
    id: '793',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd2812ed13c6f49a111c69affbc70877380cd459006d9de9c142398b49f3306d.png',
    id: '794',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c2bb834ead092956dc65288160786f4d4a1223fc431a6b0c0f41efbfe9ad1a8.png',
    id: '795',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4ec4387ebbaa709af9fce7567e3f2ceb02ec1233aca7699716d767081b2117d.png',
    id: '796',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e7401b9f031b7d6e06b5b71bc81f38646fa0af6d3d3f6b871da0a22e0340485.png',
    id: '797',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4bdc6ec91da43eb7094cff9a6789344f60b2cdec095503274a4ad683c9c24354.png',
    id: '798',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5dea17032e2287d5d59839ed925d5f2547a8da2e383397557c243d3ca2af6f5.png',
    id: '799',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b40a6bf973bf5df13999471bed26be8121955d57c55f9c00b6bf401c31de439b.png',
    id: '800',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f62b18495c0ad4c0e01daf3e5c4045bbb1144bc005f5e6b2375c1b4c80c7b5d.png',
    id: '801',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/142bbbcfca2fbf9d1068712bd44bf05dedb580953ef8318e5c69d05c0a3a8069.png',
    id: '802',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8f71e86b480c2df7ec0f27050b4ed8b4b8cf2b4390a8d79e6c4e4303f0b90b6.png',
    id: '803',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6581e4edd7c56edd37f516e23fc41fca723e271a512a48e3204f6a8629546310.png',
    id: '804',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be8a86e6d80c76e4d8db9792e3b2cb6410df96715016cc23d1c04df94b4c4c70.png',
    id: '805',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acf87f1157c4ece53e41c2ca8d29fd10fae2a3a6413bdbe964bbcf07c58f98af.png',
    id: '806',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3c81f8f9a9fc58904df277c39925fafbb480436a9264c459b5695464ad85de2.png',
    id: '807',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/573f6a74b9c70c75a3b5c1c544dfc71b13e7673d7841a7eaa7a5d22da857e049.png',
    id: '808',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b46c46c359e2c0dbe757108d0dae6050250e6472ced7a40a4653e07222a9d7d1.png',
    id: '809',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b052451c47e52aaecac8c642958764aae13db7dfa10a26d9c06fcb84e541e46.png',
    id: '810',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf0904742e2138b55a4396130c7fe8c00a379a2601b3b31d76cac880e54a0330.png',
    id: '811',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8c7a78722ed7bac433250f0d45aa2c4f5cd105f006d28ccb8d9124413936936.png',
    id: '812',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5acb1f3cd0ad9c36f6fd3fd1e7cd6a1fb2c24165a1c048dea19ed8ae35d1b3d9.png',
    id: '813',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95c63c67ae3d5fd37bca6045c34de19ba129b9ca3c692a86ce95cbf9665692f2.png',
    id: '814',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ded9a2740bf194b0ea30c993d4b3f87d0d6ee8a64e18a91bb86a67081bfcf106.png',
    id: '815',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Blasphemy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4885ea77260bb8377f8197d507a45eb73b7068b4750567bac0446003edd4e65.png',
    id: '816',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3ef0077736239cd05911185d602f35d2b9f98a42d08b70b46d0505d81af6ca7.png',
    id: '817',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/232f34f5c5bba13d1130e7a480ed85502d4f6840e14cfa1ea0c939f3f110c2c2.png',
    id: '818',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49e74b33dde7373fad9b3e6a04ff7366e196f9a3e87640f8c702995a9c072542.png',
    id: '819',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63eb00b8373142deb32cd9d659f2e35936ca79cfaf611e91c6a6ac8ff94823b6.png',
    id: '820',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa6d6507547e2b41a5484299780c4ac1c3c2bb3809057841819a8631a8953b8c.png',
    id: '821',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9dc80748624c207b0239b9aea1b2bdf9b1c660ec256a8668e4b3beef1636a01b.png',
    id: '822',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/513e9de9d0effde4f627ea9fb188a9dd25f908b6499e535ab9a28dfdedd21489.png',
    id: '823',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63be1c6b8fb3ac97174ecfa15ddb9d5420ee41b3ddc80e4285d9b30fd4147d08.png',
    id: '824',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bde9eb5d627dad1ec73b19b87e8ad6f5da7923781bb4946b9ed720da3ffb197.png',
    id: '825',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d0368f8f9b22b12d6cf6a9e79448f2fab943239515e3f1efcd66c47c4082b31.png',
    id: '826',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48ff8b7f794670410a4cf4adaf7dbfc89a176d021de1d5fbd1debb255db37e7a.png',
    id: '827',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc9d60414cae4a4650a838d68738d3ec3d9d11f7457ce27a06288d6c366435b2.png',
    id: '828',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cbc6e316736341995492a0fdbadd99d51220728962b5b93589e19aeeb64719b.png',
    id: '829',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17bb8e668571055b50c1953576be32d2da2f0c8c77a66bfb5bbcd7a860a55fd8.png',
    id: '830',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4546bbe2eef03372bab2cb82edba1aa6fdda479029284ecee8b4a8c53b5ccdbc.png',
    id: '831',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/478f2d0ec703be297a8ccc4f2520df90d657e35ef0f71b7b6352a1092418dd29.png',
    id: '832',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26397862f6cba30beabcd84ae4fda5f918d7053d9bd89ca4940c5aebea0e43d1.png',
    id: '833',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1450b93e313cad176a7458e219dfa3bceef07fc4e3eb6da2dd431f57236acfba.png',
    id: '834',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d889373f23385379426e611d43b5b85e4d0ac53d2a7ee5c3eca7c4cc02005335.png',
    id: '835',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0492a2a25b3e974524afefa2bf08dc7f0ff50aac09570264c6e56b6c06f31d32.png',
    id: '836',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76720ef8f431ac10f3b7299059cf29711a279d14f889ecf010617999f84e8830.png',
    id: '837',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9196488622ca62fda0c5d7c9316da98ae33a2e4033a30426878a2dab60a8e7c3.png',
    id: '838',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a8ccbae82e8a02f18f51a2efacdc44ad2912e4cc9715988b9048b0c118c570e.png',
    id: '839',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b722e2efa1c81755cc5541f7a015b629ec0df7dfbaf328f9106e500e921b07fc.png',
    id: '840',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1cea00c7baac11fa456d2a16d8e4088fd5b12241e957b04c187a561f8e542634.png',
    id: '841',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0b5ccd0d36340a5bdb1d28d14af09ebf30977c992aef872c5fd8a2e2a4a4b90.png',
    id: '842',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a1077d693363ac31f81f91cbf953bef6d217f71b686bd04125b929e01113653.png',
    id: '843',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/877b0ef77f9c3ca0ee2917d945a479e6671e7541d3f3692590a756f60396c0a0.png',
    id: '844',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f33afc54bc138cb0a5b96c459a023169566de839fe87e805c3801d3e2ab6ae50.png',
    id: '845',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43c0703856715459686c5d88348ffb0a594ba9c666afeb4ffe7b12f9cea07e5e.png',
    id: '846',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99cf8af1b19446d351ea16fcf7c6c51c9e8fbf705f163349f9c96fff97757a74.png',
    id: '847',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9d14449e19f236bf5193535ca95f24a43d9bb562a55bb1fec6adfa27cd0472f.png',
    id: '848',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb49a7811eb9ebbf210d00f7856efcab7b61d708868ba6e32a8fd489de2ee117.png',
    id: '849',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6528aa871e02d9c098045e1812155c66a0db0c42809aa496c6afde71bbab7d36.png',
    id: '850',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bff22aeda0384d8f873fcab22c8fbef7924f9149f0776fa07865660c86f6947.png',
    id: '851',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5fdcf87e6889a346b9369675583ee28f1c5953e9153e57419c5f2d6870c72a4.png',
    id: '852',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/850f9c4fdbb227601ebf77fdb83f75e1e1b601a15f22570dc12d2405f77ddec2.png',
    id: '853',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58abe9a8633bb728a8763b89d69ff67b05d0cfa80a741dfcd59f294d8ac5171c.png',
    id: '854',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be8d87efa95c4cbf00326ffb9e4287241578a2c58ce2d59b6e1f67d4434f0f41.png',
    id: '855',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d04d24d278c0bd9a82d6714eb430a1393fed552dd5ff38303075fef95190f438.png',
    id: '856',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8186d39239a7e78d66f8eb9d2196907cc4edf093eda7085cd0f3560f2b12d90.png',
    id: '857',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2866da7bc6499f0e29bde0ecd560c5a65b050dcd0c5ac7e03825656f255db8f.png',
    id: '858',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd93e4142b3af6666a8d4a7578896350b8a764ed2aecaca4699fa99d96d22f3a.png',
    id: '859',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb39f8a03dfe7159968063a2dbf061e8283d18bbd189032b104648c1d6436d25.png',
    id: '860',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Underworld Spine',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f40e05acb107bceec446fc177482fb04564a82425faa44d651c92e9d9b8bdec0.png',
    id: '861',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78e5e15eba3fe0b82823b67495895d1625dcba349f93eaa70d7aa09c82fa064c.png',
    id: '862',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f030586e104fa192eb17f2c1b2859120815ace019ad59e2963bd73186d1deaa5.png',
    id: '863',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7446ead084510198828c8f27970645f109ef8c8ac6997af8e57ce3ad080c6fbe.png',
    id: '864',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00c130d5f59cde17f4e8b97aca423e611b78c25fd3dfd79efcfc68e9ed993f6b.png',
    id: '865',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0591d109e7c2400ae57e384fbc82894b4fa0413dd7a61587f9444af75a7cbb7a.png',
    id: '866',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f75c44ca6e1c195e5482e1d50e4082cb2d497896e7bc5c5cd5a258d17b1adfe1.png',
    id: '867',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47d690a1648c52ebe7f045355771077b435289b30df638043977fd5541b8c3a2.png',
    id: '868',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7fc5b444ffef33b7ba3bb8303d1a094e423f0c7c96b5b95285399db2e1e53f60.png',
    id: '869',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7dfdcbccf4d48be72683feb90395e003b6b3f89afec1aa8a6f7320f1d252901b.png',
    id: '870',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e797d0fedc483078b458e9faef20958ef58a4914954f2359499286edc38cae5c.png',
    id: '871',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0acdd9fb6bcc2d5189f42ca248c8032fbd245a01f1ef4e0923905d98f1d835c4.png',
    id: '872',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63d90fc0f05af8f10eb19aefc34c1f186c7a34135637e237ecb95710e5e8e777.png',
    id: '873',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21e30973c4252aac5bbd5c0718681d0805151dddd155be936de4ac2bbe8a7859.png',
    id: '874',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79be7de586c9fae113e037f7931ac334bc4d2990d013a24b91926f190edebba8.png',
    id: '875',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2cd4740d0792beffb08fd878e5aadfef799f1257fb05de794475ba4b2894e10.png',
    id: '876',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Spinal Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d20d68363152aadff13da2373b5b81373642eb67e0ed9d7475e38325bfb8b053.png',
    id: '877',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/471ca536d35e9be8c4071cae58baa37fa305fd5f949049b268d98d43dd80860a.png',
    id: '878',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6820c7aa6f701e896b129ac7095d401fcc05e48368faad58f0a2f6e844435bb7.png',
    id: '879',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6af7878c9103a891f7c6575161f946ad70aa05e310bb19937e1eec11a3896054.png',
    id: '880',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0db527e1823846dc2f2625052cd090d25ac0a44b911b6f33b197feac6103335b.png',
    id: '881',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b53aa452518ce3459320521ef5b1655118e8b704d1554856f70c2d3ca741af4e.png',
    id: '882',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Winged Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00a8746a2746c403f95f159b25af21ce35730c8eef30d59c6442da106eb6d1ae.png',
    id: '883',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0c89bee1fcff3fb0a316f20e9d5da2a884c491907be14aa445cc652ac654219.png',
    id: '884',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/132fa346c372339d609a3a875fc78b233c0a315e2fc88d7a9292a1f5765613c7.png',
    id: '885',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7314ec1d0bea688b5087a59fc89c4abacd920008cf0909290a58a312bc3eb6f.png',
    id: '886',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/722e2f0b5aa37a3b79880f9bc62f81f51c486b28f6d8b1b8ea907cdeada5dd44.png',
    id: '887',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68cd7a6b16c7b2112fa73d6ed3de75b58571f1c0c9fa2b7683e2f1dd68c5da70.png',
    id: '888',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bffff534c1427eb1f63aa63b1c9466309bea4c2545905b20226f3e713067163a.png',
    id: '889',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d79c4ced4d0f87d7a1e581c86e7e92866dfa4d8155d0cf68076f12127f9f7ad4.png',
    id: '890',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f9e62257db796b2ca856d5572b5934536bd7215edd65e290049abb6ead0fdd9.png',
    id: '891',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5dd462767ec2c81054ee3eb0dcfe827f100ec0c3b435ba7b503e457e45f2fa10.png',
    id: '892',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e13185eb0a2a0ba9d150220bbd852c6e42b8ae085560baa58ff6962e72d4185d.png',
    id: '893',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8db3e0916c0876665a4e3fddfd589875513d6e456aee0cdf4e611638526207c2.png',
    id: '894',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7a521fcf8c23e7f17119d39975efd99c67b64a419aa449acafd08e52d6eae44.png',
    id: '895',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a2a65af3a947f1be4c645165794046f19fdfcf07e306184a95841a6cb65e299.png',
    id: '896',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04f9cda18850d0b09a490897cf791bc025fe6241fbcf4bbf3293d53731b61aa0.png',
    id: '897',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/311a315478dfe76d4f8c5cb24fd7e5b66b5f1ff4dc127b4503831b44b6e27046.png',
    id: '898',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4230a24c903527bed841aa976e49d099de0d3bd3c19cd6b4fcef2ff6eea43856.png',
    id: '899',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b614e6fe9fd0d4bd9d8b38b0ddc00e73e910fc467784b9ed3da9c49aad96155.png',
    id: '900',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/945feeaf5da68c00739ec3f7527b5222ce16ad697a85ead6f91b3bbbc08bfd07.png',
    id: '901',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f50d4b1b6d6eebd6a64237d9f347a32615b78b678d867eec13c992e055a2e17.png',
    id: '902',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51697f84d42420e689e02b3acc3df67364396834ac2e4946195d857417e3ce1f.png',
    id: '903',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d137799818eb6bde4b1e84394ab7ef2c1e29c1b36a7702b211e299a39fa1923.png',
    id: '904',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ae8bc0905d8a8407c76285845e14a237048fde12edac139e956c82342a1ca53.png',
    id: '905',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44844371df5d69821b5778368ef582ff2fb912c88cdb5fef999de05d3e426094.png',
    id: '906',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90f0c2fc60fc8cd865dc29dae3d5ef939535c97376e1977a4df2ba2424bf1a48.png',
    id: '907',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4cc04b2cd2156775a459b3bf7e52788e6181fd6dd1ec41e682f99634b448053a.png',
    id: '908',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/016911e3aa233ca43aa1d2cd050fd7f0caa855ed1c9c6c3a2aca5836d5c54e70.png',
    id: '909',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06f1db45f1647f1f86615550a214c92f80434a737a8958a3ab88f47acfd231cd.png',
    id: '910',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe5984b936d999ec7bf8ffc1a31cf4c09dbad4fd997f3f67297bed16b4553822.png',
    id: '911',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18852c2271da824b24a04cb6b41fcdc5b03df63b98b75db528513e7441789d94.png',
    id: '912',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6554b5dace4427c941269c18dd9fa9e36847df4c8a6de558329acea3218ee790.png',
    id: '913',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96fc72c948edd0e5ac80e044692af66cec5628654cdcc209521b540f89ed4e13.png',
    id: '914',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e68118282a37d05ce8642eabbc1b99fd5686ea07d161c2315b342dbfe30be95.png',
    id: '915',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c02fe645512534b559eba2a5a22d370c438d7ae0b2d676f03c242cdac631081.png',
    id: '916',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6e8f2b6beb5cab0596324fecdb66dd1438f0b527e971e679078eb6d0160cfe5.png',
    id: '917',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abdeca53549dac6030e2e3a9724a91b0a77f09e7714d23ef2dc06e5b0617ae05.png',
    id: '918',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be5dbb91fd78d6fe1994da73fbca48a793f57fbf120485e620a1cf4f7eeede4c.png',
    id: '919',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9039270ed9c7e42d83d89cd7b488d75584834b17ac0b1f3695d9453d709b52ce.png',
    id: '920',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a327eef6003d0863a71435c29fe09a1d63437f08c07fc2481d2147d915a6b5f.png',
    id: '921',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4db785a600dea58b7404dfb15430f86397ad1cf688a70c526c1db9dd8514ecc5.png',
    id: '922',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/946fcbfd645d503f11dae668af74059cdc0d3427e49d9a6790704dc31b666403.png',
    id: '923',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c5623f60b55968cba876722755254f44360270e58187d49f19ad03c8d30fb15.png',
    id: '924',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dff1a5db107f45757062410d2cf85153c07c3e4352110e1acec6accd02a62e54.png',
    id: '925',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc72962b89a45c0871deaca1a1df0f99fe6801472a9b3d45ed1b9a286e57a67e.png',
    id: '926',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/165d5e53a2bd0d783e2b5c5e6b200085e7bfc637fb573071e3415a0fdcdde38e.png',
    id: '927',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b740611223166edae61c7199efe0bc64c56637b56f382ead5841c2e238c05343.png',
    id: '928',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4b6c40e9714fc98860ee5f51b993811d71feb4f434972b4fc6f7cd14fd26ff1.png',
    id: '929',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22e349f2b4970ee0ab4e2f8a8012b9f8e4a202c0ade88daa7b85af258046bd25.png',
    id: '930',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c3037231da6a157f2aa41edd7b5cb8dd8d610c207146c8571b429a829859538.png',
    id: '931',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab514186aa5381ebcbf7f6c469c7af82f728e4d7685d39c071131fabe72c33a8.png',
    id: '932',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba2ce19c48ed6d185961d11343b77bd585929f9bc1681d7bca415bbc98423af6.png',
    id: '933',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d74fa62fd8fb96ab9bb736346311e39c83aa88cf1da21d9951fccf0d4d43128.png',
    id: '934',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f040be933f3ffcc23e6438feb55dc18b84f788fdafd468b894e1d5bfb64f4d23.png',
    id: '935',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4434252c69576cceadb0cdd2bae803cdcbc43eb11eb55846cc2f1c8982725bd.png',
    id: '936',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ce576eb456dddc34e98a47939c2eb66ca9c05feb076ac7422874e943c05a307.png',
    id: '937',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Lights Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f96fcdbc969329608e657306a44bdb03684421c8e832f7783919042e7543704b.png',
    id: '938',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54350c554a81f899c1abe2d2c5b073a292ad65fd396a57d1c5a6af0032de14da.png',
    id: '939',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6ad4d067f70a34ec152d9c5cf937490adedbed34dc526148f20d9c2d3d870cb.png',
    id: '940',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b817683c8e54dbac39381a050855d189c074c7134524a06fc88e0e3c6acb4da.png',
    id: '941',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b4bc1b4170132064bd54be8aad52516ae9ecb83929225fb6d9c4d68c544cc5c.png',
    id: '942',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64d7fad4fb7e92ca79a5295e49459a137408ef07a2332c3ab1efabdfbaf7b6ce.png',
    id: '943',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d1b6e338835cdc614ee9be85008d24eb4795a9fc5006b059e8dad1d2726cbd1.png',
    id: '944',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6063d7a93f5caed48ca0fe631ee9cd0dc50d4874500a0876e8556358736b1a52.png',
    id: '945',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d23ddf31dc2b97804ebf23664f6af86aba35db4faffe374662acd5f7cc04636.png',
    id: '946',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72897d4284b6ce7ab03a0e10035173cbd073ae265f81cfd36fb02ada61856ddf.png',
    id: '947',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4989fbf560d1c418772edabbd5ba117ac4ece20024b81e82c4051956938776be.png',
    id: '948',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/417f4a11dec81ab4b3fae4dbffdc5377ccd65a627b972791d3376b5a75d3f008.png',
    id: '949',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db968d23bf2930ca0685b0c7a9536d2592b5aae81c3fb7f61566271ed5c41199.png',
    id: '950',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09966af3960a885f5bcfdf9cbb28ecba7b7a9a07ddaa89729600b3ea572faff6.png',
    id: '951',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f147925a35c8417a64e5240e96051679703914da27fe3a71062027617509c505.png',
    id: '952',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0f1a8c504d23a03377200df1369ea18a53c78c9c912cbe936a73b98fc10a72e.png',
    id: '953',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc554f1f3f48e88672dd7f4fb4217f209649467edadc4c25193f9a772eec3d4d.png',
    id: '954',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bce9d210fa9f4f83161a22fce041d4dd3cfc7b9b7c8a85770aef75455f3004cb.png',
    id: '955',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/736ba309977e2218ab5b56eed42c7569c27d4c21e535e9766ae4cacf1cc286fe.png',
    id: '956',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd683b471cfd925314f3b006a745077f3148f6152bf33e753b12943eb05bea6b.png',
    id: '957',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7b16622736db6d27dd7331ea43d499ad212b833553cf2299f7005b508e9ae11.png',
    id: '958',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea534215d9cd75d298d9e2f82809a9d151dda1a118df36ef6200eb98f9ab634b.png',
    id: '959',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f153a96f840c0de06a8edaf9e1e2ed7cfacbcc810e7c55d4c6a575c38145d33.png',
    id: '960',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e92bbfd423b86e0e9e682ffb1a72d6ac44881fbc693ed5b1d01f9f1d19811a3.png',
    id: '961',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe834fe2df7372df6cac912e273f23b7a7ecc62f22de018303aed69c5e76ef61.png',
    id: '962',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3e3cca4f1895e7eff40582df2e40649d8409364017db065ee75bb843979d00a.png',
    id: '963',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/375333a333ce3028fdf54e4ba7805541677ab883214f52eda309f8b06ce25f1a.png',
    id: '964',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66db5cbcd4ee1a6848296912e50c4b81a2c63a941932076a53aa6bff58f53ac0.png',
    id: '965',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/046cba90e8b2d1a2f4590955a649fbdf794678628fd6035f146c358f206d4fbd.png',
    id: '966',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a81e51cdcb8f42b19c6750524b3d79e0510aaa89ee57f56b27f545d704f2270.png',
    id: '967',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a04980af6a4f07394e88755332d9110e434f9cb45ac6e0e70d8df4b91d59a67b.png',
    id: '968',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e236d30e1fab8e5ffa43d1eea961c022fd08bc5bd67203416a26ecd268b3b74b.png',
    id: '969',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c530b656ce72dcd3abec15026c470c5d444fca186173195d6903ab82ca6b63b3.png',
    id: '970',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/698134114ada329a5ab6a5b4e886dd66ac16a1f4b47bfa7b351ec071d7f4ac05.png',
    id: '971',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2871c7acf90d34e360b197c17205a67ade8e2d720630320abeb593e1a14c3e8.png',
    id: '972',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/657f18b1b7fc81bc763908e73046b24ef0ff2b8b4bebe25d83c074c62d471227.png',
    id: '973',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e7ca9bec7a5235602467555b0d040071402dc4d6e3f7c015dc52863c591f4d0.png',
    id: '974',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a047fd55b62e0bf865277b613fda601e7df39740e1dcd6d7b3bd6a1914fb5ab8.png',
    id: '975',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54f7920e7dea72c4572896ed8792b4c61bd8aa5eafbf6be9363f618fc9f1b551.png',
    id: '976',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ec3e0e8aa9cbc6f97415d6099fbcc23c975307a61af9826a98c75d7745fb431.png',
    id: '977',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'The Tenderizer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4d54c3660a0f0c3c3f333fb5aa719a123f27113423bfb13ac8cdf609994beed.png',
    id: '978',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d716368b65f0eb42ff113a46d5bda2a2836f2635d22eee6cc68b4b5d003dd6d1.png',
    id: '979',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d05a5ac6e145636e89dce81bda77a7f0b31a43d730bb8edf2fa818633e8d3a9.png',
    id: '980',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c123a8c75ba050fcee7b8d923857fce9d79deef175d940f17b4690e721583e76.png',
    id: '981',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52deb3bee82f6f1282c538688011013da855f6f00e9e539f5880cac2238c5043.png',
    id: '982',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Explored Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ccfa23b5b3a8a9ed83f77f5230b01bc49e2a7d34cc7e47906cbcd01aed7a2952.png',
    id: '983',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f2964233c0d98d8d8274119671e0fa625fa19cc41b3f234b1c8de968400c171.png',
    id: '984',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75c4f238003b000237236e09607b26179de50e97455b14f6c35c757ecd119003.png',
    id: '985',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e81d5574cc11381527fdf59a94fbdb6e7cf7f672534452bffd28417798f249b3.png',
    id: '986',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af78580ab64f28f06231d91fab48c4f5957378485460dfad6d6a81b1edea4d8e.png',
    id: '987',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/643c3e92520096eb3368e0cb3e667cb14cb37e1cc7a8adefea74700c34609341.png',
    id: '988',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a4c88b677d588853100e993a3b02f5aebacb19b893f94b5ef3d4d1ead57232c.png',
    id: '989',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7db2eb46d670c4a0e64382078e784d85a8dd00b86bd94b4a6bfa901d5a9f5b0.png',
    id: '990',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1c333365f7608fae16da3edd846201255816ac24438b239a12f353e5f1f40a2.png',
    id: '991',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7af44e810a92c13987cb70dec032e8ead2fd3e95c648a2e3a0713b1903a6b6bc.png',
    id: '992',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc3d3fe2cf7a9c568d65401cab2c762a124865c99f46b13baa58bc14b509d9d9.png',
    id: '993',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c38423e8e8b9c9417c79087d295a2f342dbc84049215e87970c660352688534b.png',
    id: '994',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc765d15fabc96cc4c43c392fd4345adb48413160145dfd64dd669ba0c5777c5.png',
    id: '995',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a6927333af797de586ac7d506a37e2391d78afa0ad3df9fab05f1c4de20200b.png',
    id: '996',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e18095d238cab276401ff71a76ddd1763afc926ee4148d12ffc04eb90b52acf4.png',
    id: '997',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Bone Hook',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/883f1c1261b650495804e75e8f3cd9be94d653d14e1d6a0712b4adde1503ba17.png',
    id: '998',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3993253752f0b2dbd5cfd4cdf93a1aa7c700c0f30a42383b4d668f10948c238c.png',
    id: '999',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95e49a135d896ad6ad3ef04b09c3f538a860bafb71fa71c29e09c9c592eb8216.png',
    id: '1000',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1007a2a14f3b057c95c185731ebf5293821c5ddc09651ba36f49bcd0569ff889.png',
    id: '1001',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79ae3281233da06a2b586594e49c725219c29ca71847caf3ec4cc5e233e6d280.png',
    id: '1002',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ddb4b18b243f77d9586871933aba531591a93d88c21611b9bde476f4a051b77.png',
    id: '1003',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Grazing Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4e5296066a47a4f7d7a00828fc08928dcf1ff317426c9183380e47f10604720.png',
    id: '1004',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5de78949b7182de38cc4c026419bb75a13255a700ef40f91f2061dc47adbb912.png',
    id: '1005',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18ddc55516e49cab34bcc219459f2f3992ee69d6be2db00ec1ca0d535994c86a.png',
    id: '1006',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85c60caadba4a9b4351ddc2922eabbc2466a00a7a233af86d45c48d70f4ca2f9.png',
    id: '1007',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78fa3015f0a8154ba5b27bf79c205d0b627b463318c06764cc90099c9dca467c.png',
    id: '1008',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35269d5a88eefbd0ade407b6128327d680ecbc2e88a23153e94be4e5994bbc81.png',
    id: '1009',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7fa53a8e98c6550645cfb2b799f46b98c696d6326d695fedafb3ad9ed2cf7c9e.png',
    id: '1010',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/850c0123082486753b8ac41f3b90f3ceb302cd2e5eeef83b1e53a90044d118ec.png',
    id: '1011',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a68b63a8ba11a89807b46ec45169a2dcbc89555e1ffeaed7627158aec28c9ff.png',
    id: '1012',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90a09ba0105bef533135a8b6327572f9f898a65123f067c98f4d47d87ddce5f8.png',
    id: '1013',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bfdece2ded15431ff9773c1e2cf65db1688489cb484021f515e7110b002735ce.png',
    id: '1014',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81b64f0d038626e71b07f5f0b510c0e35d0e3597dabbbbe5a39e9f5304ece89e.png',
    id: '1015',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02a69e72d2ff6ad9a1a990bb2d16e657d8a50d7346f94c2e6846d9f9ceb3f128.png',
    id: '1016',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a81358f7be2c7ee1e38b73b92addf63ec74e8dd7bf8405fb420caa20ef1f50b5.png',
    id: '1017',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22619d41ff46f18f92d462e3c54a68efa510c91e4014ddeebdbd6a8463adcc88.png',
    id: '1018',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28608dc7e51093292abc037e0579c2e3140811c54d7447ffeb5be03312d8b485.png',
    id: '1019',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8acbc77424f0bc230bce83cffed52d26bd272b3cfdc7e593ce844307db0c5c0.png',
    id: '1020',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/913c8510d2ffd359a8309d26b40a25e2b23ca0cd9d844754b9783487f97b864b.png',
    id: '1021',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6453f94939c7f9cc19697b0f2132549e18c20479d645b51ba3fd02279d144e9c.png',
    id: '1022',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6680606aab15b94f926467bb1d3b3025bc1d7ecebad615d62d8bd311d47af306.png',
    id: '1023',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cdccb0cb9b12741e151fec74314fa16d55a4927676b3fffe503c63a8e96a32fc.png',
    id: '1024',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f54e44a3d44195c574f65dbce5d025c0503c602d7648a77d2f6ec4db10f827d7.png',
    id: '1025',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55695c4ac4294afb3f56b899bec227c2ad3919d8e85f60000f628f02f0fb7747.png',
    id: '1026',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/339500d62d1d290b53e4f9a1b00f5c02e261fef9c4d86f322b7413cc53265688.png',
    id: '1027',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8a32ab5367d190324456b536ca3f2825d414bce35444935fecaa4907de1e978.png',
    id: '1028',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ac6a51f9c510438962acac7c0c7477533e016de33ab1c42dab9c1b611106bd5.png',
    id: '1029',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75540f71b6c0af3d2f8c85663cca1839c00029c02a16591a82ef9f4f27e3d1f4.png',
    id: '1030',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de2474b7b6e04437b470b5f49974dfc6eb045424b958ea2c29d7e7a2c4448079.png',
    id: '1031',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/357e3ac847dd22f91ee5b186c77b544f39aaba384643ca36914195c750c2552b.png',
    id: '1032',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71ce67cb383500bd3b920bef884f3e0626563ba0f3d3bc8ece7593fadd507747.png',
    id: '1033',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc0e4d949c1e5ce6cb321c1960bca648690fdd4bb10cddc17dba489e244355b6.png',
    id: '1034',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9acf49e8af46182410f81509beb807fba9359383677c25bc7d2ab5e64b5a38d.png',
    id: '1035',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09cc138feeb6355d2b61516a546ecd174532f477ccebcb91a21ee8919f415b1c.png',
    id: '1036',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11f983faa799dbfef229b9b6f8b7e55d2e597974ce99a52c184de755b350d78c.png',
    id: '1037',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04c27b7acf67662011b4225feb3d4332ccb37ae1e6beea142b86205729984781.png',
    id: '1038',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42c613e9011b9ffa938e05dffe0fe83e9bb306934e97b2d43bd2df99d5fc1478.png',
    id: '1039',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60f11e44badc913ebf70fd99f865989a280bcbfd529b75a725bfd35518f04617.png',
    id: '1040',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15003b12196a6016fcf7bfc7d2b45f9a50323441ac2464f44dbd78e1a0fc534f.png',
    id: '1041',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5ad42dfa93adeea1cd29b8e3ae53703c441b42aab3660d311d3814dc57326fc.png',
    id: '1042',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/578c4435840d44f31bc4431641473f3fdc067e79e292597f5f4d18ef17ecb351.png',
    id: '1043',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19776df3b395beab993f0a0704e141eaeffaa45aaef6d49eb25d33503d2f8323.png',
    id: '1044',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c921ab56c7b8162f420168161698a038369b46a536e970130713467c6b6b4f7.png',
    id: '1045',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1cbe5abb7c9ef3f70cd79cf1ee2539d9a58a3478740ad617d429f2dcf7761dad.png',
    id: '1046',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7b335cbb8a99ff62d671600fb8c2c2779401f9749d82812b2c4c0572b72ee8a.png',
    id: '1047',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f2aaa02b9a3a3c2900bdfa16d4f15fa6d9bcdb9de2c692f564aee701ddd38fd.png',
    id: '1048',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e340a2f19f58348aa7b92288ab760476c3f3c19f6752a71e27a1110fc73d2145.png',
    id: '1049',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2df55fdf4a839ef8c9cb77167b7dda19f6766a20b424a05817897d08056a0a1f.png',
    id: '1050',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c801431ff6cc0d3e5a1e7a36e65d7d70121276996e33d4c15bd363ed891ba67.png',
    id: '1051',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/314c82ef275507b501df831f62d6c9642a22b7be4e451c5c7bc398bdd007fa22.png',
    id: '1052',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acc5ad9ea2ea6d724d9a45baeeeeeb9564bc913816b1bf834a1c79063aa656f9.png',
    id: '1053',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/968c240cc024e59910c2c69dc0bea1cf5a4d22a90e7e51ef8210cdb3c5fd3c15.png',
    id: '1054',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0536ddc018ec5f8a94ef1bbd51cdcc56766e2d565625349462f2f0786457f2cd.png',
    id: '1055',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8955dbbd5dbca9a571a1104aa10e80614f770c97223c9e51017ab6cc9da31f70.png',
    id: '1056',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5bba89e268ff3fe07914e31fd29f416da409e7d723a64ce60f17e296018541e.png',
    id: '1057',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae33f56b11952f9673554634ad2a9e6e13796a1696aff3a5ff222dbad118a2d8.png',
    id: '1058',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55848af47f69fcc1ee7faadc24b7ecb0edbf8e827e48f0eef8e64e17a00ffe11.png',
    id: '1059',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05015c8d44b7adb8fa4fed3144d5eb4983dc0221c4f0e8330c784e26e58c61a5.png',
    id: '1060',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/454b37340e5ab91767b1a207fe90f6a8a4ce00e2d2f1c39d4fd4963eab3249f1.png',
    id: '1061',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Underworld Spine',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cddb2f651ba9592015feff4fba6eb36e3dfcaba625d0198562eced3750bb41ea.png',
    id: '1062',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Twistree Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a851250d3ec6a0312ddc271ce4b2734f80cef9becdefa97628e0db2d898efeb.png',
    id: '1063',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45149989776bf6cc4c594f3a259821b247e9ba43d2e4df86eae1ad4e04eef412.png',
    id: '1064',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6517d6b1d917d6b58c52961a3b0b85f9e78a66e66868a01e1327a4820ddf4759.png',
    id: '1065',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4180e14c08fdddc0e83857a18060701bb66d44123f7835568974a59d49501f3.png',
    id: '1066',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/386142ea8cf67d7c09643fa68268774be65d32c593bf4a7ee8197f8835214a4c.png',
    id: '1067',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/034c25a10bd45a731556179930db1a224092b0fc58f38e417358e0d595be2bfb.png',
    id: '1068',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e791ffb944f80ec35b800457984a1bf4e6f0ddaf745eea5e047b5c1eb94f4920.png',
    id: '1069',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7144b686325b39170a2e4e77da683ef6ffc1bafd4de12b6f941e8a83fc55ef8d.png',
    id: '1070',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/127f6d53f7db4c2413e62b546a442fb90cfc4199b044983f24df425b3b0d4880.png',
    id: '1071',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ff410c22f7a4ed2e10fcf4e10c39f2821cc4ec14716f48b318f08f082005589.png',
    id: '1072',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53b2992605985dde2f903236c2c27d87eb5a06071fe052ed528f6ce3938f4480.png',
    id: '1073',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a05188386ab61d90d22ceab186072bf8579c95d6e4647ebfde39235424950a18.png',
    id: '1074',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f72b9d8fc74ab8f83e4c6e04526e4cebbb1bcc83cc13bfefa594203ddcaeb685.png',
    id: '1075',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f302b939a7fac95643a02bf2b7b1659cfad12ae37b7499ece01f2b60ce10939a.png',
    id: '1076',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8573276329437f7aff1c9fca073599f1af44942054baaba064571fe688330e6.png',
    id: '1077',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe3fde7337c1f283f8e55364e13596817486f7a22fc1878bf8554ab2f7b91c97.png',
    id: '1078',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/421b8018ba726bf1aae0797f9fc26ef25de5e320f98b7fe4d12a1964a7335c2f.png',
    id: '1079',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da29f63a9a23370a0b3607d52d4ad601f1edb2aa5ad78a59d7ae1dba1ab1a905.png',
    id: '1080',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23a2e526732b0d6d24ed3213207a5c1636aba691a46ddf992580267b68c96f05.png',
    id: '1081',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bfeee6009b4cb08d06d824390e3cf6fea80929ed53429a83889837e8eecb955a.png',
    id: '1082',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08c5455b1ee0a48a826bda4392fd3e136960a8e385ab54a5a9d940f3eeede5b9.png',
    id: '1083',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21e632d1a6f1105fe8f841e40d99135d0537a4d8a41a53572bcc2469a6e5f4cb.png',
    id: '1084',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0862d3cf36d23c7eb10ef730a52dee8ee2f064e568f7cf7f5eb07530946081a.png',
    id: '1085',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5bd296030d44eadd75512ad868c4b0a65e191839e49fdc6a481feb153213ea44.png',
    id: '1086',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85757e984cbc84e8d496ee77c39627481ab0c5c96042bcbff1e63c3d506a3326.png',
    id: '1087',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09f6ca887797d3f005123f590c342149348fffd2477061b879094dfb6af4e45f.png',
    id: '1088',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c34a9d881619dfae488a816d3d9040c1eada19283b3fe753f845cf56b2368a5.png',
    id: '1089',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb62d75fa8cf478b29754967d94bdb9d63abc664b3721d546ce5c8acaa761d27.png',
    id: '1090',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9b03ec89cf821e5bd25afb135aaec00c9f06fff24dc2b8247597bc6f597c775.png',
    id: '1091',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffe7190a02343dfcd2938707bb3f9ddffe2347a39037405f1e77a44f5fbf10c6.png',
    id: '1092',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ae315b3502d92d73706f3f87fb348d8bb133ac5a45c8d6efdcdee42112d0d79.png',
    id: '1093',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2233b065591833f2a8489044c0e876656beb0415463fcfc5df6449bb5de20901.png',
    id: '1094',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/002b9a2a4ba35d9d35da8f21a93c03fec590dc940b2f36f0ded740965466410f.png',
    id: '1095',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc639245b68d63703428c208148eb21482685ec44ab14996c1b7cc7ba044e738.png',
    id: '1096',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fddeeb4f388e2e26154bec19561fe5ebff5356331e4fa583eb77ca60fbe69eab.png',
    id: '1097',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7a17c5c511f6d14b4dc9c666b8d2a80c975fed6dcae5f6f7c91c788b3f53377.png',
    id: '1098',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a1d1963416f739098b33b56193a548323d3b159ed202436ce75392e44e9938d.png',
    id: '1099',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa2a9bdf0248f49089a6152ad635092e8fde57f15baeff9039e609e11ac2d0b0.png',
    id: '1100',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ccd194b3e0a2268894908301805a9b3741c6d27a8fc5cb534b130203005d334.png',
    id: '1101',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d8ae6610c1d4b2766f4d6521e7719ce48bf9405564431a8f64a276db5224a18.png',
    id: '1102',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/546f992d558fcc7d95939581c31df17a057661bbc1de9207f5b32765f9b70463.png',
    id: '1103',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aaed6b1f0de6a0ba551e4c5436e9412d035e687ccc75ec2a5e6af7df7282808e.png',
    id: '1104',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/476bb91450e068fe63b4408ac91319668eb5f199f0c0f9eda687c182992dca68.png',
    id: '1105',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c31604fc14effb3dedb09355a49a24782c943c67f4c29599ace9bfa4af55ff6.png',
    id: '1106',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63c39fef96bea493db5dceebd9fcedf5f25c63e5c822fbc55ea9058bb0c6af7e.png',
    id: '1107',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58048a58ef21119a1c20519b8de40af03b7b696da289a7fce601e870aacbe290.png',
    id: '1108',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa9d2135bcd3cc702047f42224eb5d5b9f44e6a274c503556b26f35db49752ea.png',
    id: '1109',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de52719205b5a0b3e865caa87456a7bc9e85089d29b4f65b9a83af81ddac2822.png',
    id: '1110',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c266905da5f48c68eabc9f98358cb57175d2a55c9b1e0b10c6f4cf0693f4c9cb.png',
    id: '1111',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8f418592e537ea2fd4c8636b93748cbd8f3867883118fe10b955e4a7a0d541e.png',
    id: '1112',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/384deaaaf6abfe0353e70c107d78a0499961191455142edac187f55db1cd671b.png',
    id: '1113',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6d9fdd198b7f49a32245e476b2dcc4849c07e573b86bd69d8fdf148a72d314d.png',
    id: '1114',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c173efe2b7eb0734fbfe45844bf69b3f2ed905116f4c970c6ddf11092891bacc.png',
    id: '1115',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48167201f1234470ee122727ec449f2861039b2753cfe80ae35b360fcb7d1e38.png',
    id: '1116',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/959122786c02688e8504d3741de53d6ed52d5d57386888f1183e16dc3dedcbc0.png',
    id: '1117',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5de90f1f07a58a660399f30ded1c7ff8dee0b803415da6331c71579b99f73ffb.png',
    id: '1118',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfe4c3fbdeb2b244611b9f3168ac7774301b2c8ae65aa56d8163f108f9521c2f.png',
    id: '1119',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d64a2089bae7f9327cc1a62beee758f1e0e3c71b44f27220b1feea95d396f6f8.png',
    id: '1120',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec13fa11c56017093c2a6f7348dc6109802b1ae25366abcf55ef04f5619227de.png',
    id: '1121',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e36fa7442f224e118a0c41c47ec5c3a77ff09e8d850939980150003465f4a012.png',
    id: '1122',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afb85dac1ae8f1e70bf0e3d170df26dfddd74a6d4f39abbe1546f996c38490c8.png',
    id: '1123',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Spinal Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca48d71ab1ed6c753a0711e0e32caf8de7ca6b0158f8213c19253c228cdb5a79.png',
    id: '1124',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15bf1a83371caf1ef36c993dcd610be8fe194ed062d88dd8dd13704c3d8b75c2.png',
    id: '1125',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a9ce83abf04c95aa916dd0d2e4c3ab8de67f054a454b3e907f63d279203c6b2e.png',
    id: '1126',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/231dc974a87eb0541bb3503ddee27c533191ee13c68feb2f61b1c4cc71912f90.png',
    id: '1127',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Batty Spiked',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1df3adab6d7e31d5dfe170f609a31763e40a00ba11a558f06438942a3c56a6a.png',
    id: '1128',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19fe710ef12588a4bea65604282f98f929f702d869a49b47c5c0d281f5c95661.png',
    id: '1129',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3263e3da0440b20f5714d33ae044abf2be136851a45c45816440f32536c7fe37.png',
    id: '1130',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb006e4980ce4435fb47c8e97c46db87fcf057da8778633a7e7ef3017fdb30cb.png',
    id: '1131',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6b091ea2a902034aa31a81775c5ed78528bcc95d80ba18b29d1d76a10b978f4.png',
    id: '1132',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/260d8d2e538d69d94da158ab66ff7df928d6b8a438928f2b00bfe339ab38dbca.png',
    id: '1133',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83580bab45348d5d307698cfcf10bb2ffbea08fe3ae767bae526c64111672cc8.png',
    id: '1134',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5dcf484eb8703660da0da59443ab07062b4f0f1a5917491267f0010e31e745a7.png',
    id: '1135',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77717d3d1c7c682555c66a253cfb67184b0581e89764c2a90fa0eb3df322a2d4.png',
    id: '1136',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79377af02b726a0d5d0a6cf3b67ecb65015ab6d57b9ad252e49688b04ceaf997.png',
    id: '1137',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87b66cdb502f3a82d5321b7b1674dd65521c7844614911752e6829d7de60804a.png',
    id: '1138',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/343c411f01dd7449d397216c8f86fc8bed6a5198c9fcb0a24e527b1ad6d4a03c.png',
    id: '1139',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af57f2002086d1042aab604c5d5bba108e112722572545d703f5de0355597ae1.png',
    id: '1140',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21dc7a870c93f1484b8a07a364941ad905e7fd83831a0b69ced9f0659d0c0690.png',
    id: '1141',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ca640aa8bd1cf2c9bbebefe87319aca3f999290be70291f7313064ac97b2e53.png',
    id: '1142',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dce317bf0dcb7ce12dc087ddaabdf0d870a6cb0d003620da3752b4b4b0863750.png',
    id: '1143',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7075fa8c33203e61714aba8142ad3c7f6ab670e8f7605fca9143a6b5dc43ed1.png',
    id: '1144',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1445269c895feafa1ef86f82bb9492c00258349a1857a7f8ea82a726d92b236e.png',
    id: '1145',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e1aad39a8780680efb76e32e02872dc1df429b5a35977d393e6c88ad65e7dbc.png',
    id: '1146',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a1569787de5d49a18b2cf142791c019d8c2022438b8e949bf08e7a955031b7b.png',
    id: '1147',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c5c2a6067626cdfc4bb0144c363d46136ce5950ebf0828b9c37b9254f008e87.png',
    id: '1148',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57c7410bf10ab935e50c98fccdd45c4d793324bbb61e026a2166c1f4db970e3a.png',
    id: '1149',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62b7d6e8ebf99205a2cf253458aa8b40ad04a0d3b521390afd3245ae959c25cb.png',
    id: '1150',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aefe98ae4dfbfa44c0ccfc7dd0788804033103325171c330620a7bf7ec8b5e28.png',
    id: '1151',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c732ea1bf54522606ffbcd6790ad27b7d5a1ee4dbefbd7da818a3463b2ca4882.png',
    id: '1152',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01fef5733c51c6504bb974a1bc350831a702916973af66951c807f7266d6f3b4.png',
    id: '1153',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f27bc4be3d235b7ba927ad48cff169bfb68a31b878130416c347c4b3acf1ffb.png',
    id: '1154',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1afa2823b2e76751593b6a42a58aeba4c2d661800eb0841e09204309a521fe97.png',
    id: '1155',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b606ee458cb7c3c299c3ff97523df1b6a2d3a53335fe2a0bc20f2492ae9071b5.png',
    id: '1156',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb38417a0643abecb668b9737e44fa0d703c5a99d0b898325a7fbb979c8896ea.png',
    id: '1157',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ede614eab6677fe71d5bd906d43d61572568fd4f8faa5837d1a306068d6c2a9.png',
    id: '1158',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/822a1cf621c46ca98c57f83d5e24e66e366d0ef84d04b7a08af492003531d709.png',
    id: '1159',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71f6ae96eb878cbc5d0534173111db1196cc937f703973a4e5b88191682e821e.png',
    id: '1160',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b03565a0ffce62e77c4d81e3e778855a47fd4f73cdc8b6e5679b35fb0d00a41.png',
    id: '1161',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dee247f2d7314242705d7d284ee3690ea0261ab156ca3aef716e166a2674b7eb.png',
    id: '1162',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91935b6b8b92b942d8305b3bb1181c788f51d81811f569dad7431f649b8011b9.png',
    id: '1163',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0dd6ffe2be94216f72cf6db38a354c83e10b3a9402a015e4878446283f184a4b.png',
    id: '1164',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c61797199944a90f406fed2938362e895920059d430009d2a684e64c83184c41.png',
    id: '1165',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7bf827dcd8cd024e9c627956bf7231e52ea7fa4d42f77731515718388187674.png',
    id: '1166',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c32f53a1c1af88447a6bf36d439b8dfc939a8813569f203335e2af52e810137.png',
    id: '1167',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d05b8e69436c058e528a86d45910dab5705b4df76baa179e79d0a9bbcf405577.png',
    id: '1168',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e842eb04f3896b9544c0bfaf9c1bee8b5a2acbb0b837189ed324fff0df982b2e.png',
    id: '1169',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66b4f97f3f43dcd38f7503f973441e95545dbfdd745c8a88b02d4a4bba52a4dd.png',
    id: '1170',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07b9edc81416ed28d417b45d4d6380f11a1a8437739b7f270b6c97e35a4ec2f6.png',
    id: '1171',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24c89d8873ceb1df30582911fcd733690fdf95f10bd6ab5ed50cdae47dc36d15.png',
    id: '1172',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd101a7372dc4dd408e22ddbe712ba7516facabee06b65710b5cac2b53c1751c.png',
    id: '1173',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d56e0b4eac7439f295077db61d63dc73110a35efb0a4b982b09e5ce1da5e1a7.png',
    id: '1174',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3af506517d1472640ef6f1a163cd674cc080980b873ea1f2af95901617777f31.png',
    id: '1175',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf14146013dee347fc58f287731ec4388052a7a603f8ecd75c87cfa9935a4290.png',
    id: '1176',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6b23f22ed1306e67a7235e15771553e5bf8b6fe0187bd07e0b013843b924230.png',
    id: '1177',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b010a64420835cc84aeeaacd2924c3c812d3f4e2dc639b219af724b4467948e8.png',
    id: '1178',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb4e17ec973bac449fd166f617b2bbc8e8454f84e06f63147249535971e6f16f.png',
    id: '1179',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54ccb108b84423dfb6aaa98afa37825a261dab0ff859c51440e58d90bc95121c.png',
    id: '1180',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/353c5da2ae3362fe27f87303f950625d893cfe9cfa2c852a4cbdb4dea3780475.png',
    id: '1181',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ada30b468081e178361be821c3ea7a4946d0495e9dc4bdf64bdcbea46b0c3c8.png',
    id: '1182',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4d463fa387b1c9a159b4c97bfa86fe31a014b68a2500b5bdae3622e8ebf9485.png',
    id: '1183',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d7fa7b0700789106c88dd0b0f06c4f770060a7b14193d3a697dcdda996fb954.png',
    id: '1184',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b8b84cc6054eefa53556ad54955c33e3abda00197c6b7b08a395c9d6c805189.png',
    id: '1185',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f46a9122de54f98f84d693dbc87501cbfa5adb731734c7add3954c10498f8a35.png',
    id: '1186',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87531726df13acc73a5f2416dc9fb334826fb4d3872bc0f4630599861585ce6c.png',
    id: '1187',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Crystal Stabber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f82345bcbdf5e82628c4edfe60ecb6d6c61da48ded2340e60fa665294ab451e.png',
    id: '1188',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78f78087adb882a6e23434ddf114549e42bd1654db944cc4efd70eebf928613f.png',
    id: '1189',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1aeda6378daa2beab7ddec9309bc4736fa4f75b0673d0d3a46881fe4d2cc41ea.png',
    id: '1190',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1a0a01d8e83718bb2fedfbe8b5947a44d319c96dcf6a5be7fefc1d981ee2e1a.png',
    id: '1191',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1db623d318e8a2d27b0191339c7ffff76a2f21f72818a84272cff7bde56d9320.png',
    id: '1192',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/781bfd342b92bd9737705066186eeec82a3b7a43d719cc7915b2eda1094fd547.png',
    id: '1193',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b2ef5bbf17e9c0625ce2b98ada34cb1e42c6dc30bfed01c2d3e94c0ae62142e.png',
    id: '1194',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6481087cd9730d2d1e32cded6c22f4b320a6edf67b812dc9cf3a1874ef85085.png',
    id: '1195',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cc04e722cfb6c40c40147111092df719edb6c87b95c48781ae57cea3c27e8c7.png',
    id: '1196',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/532f413a7c644b4c2dddfce6ea8cad723e066e69b7bf92681a3b580c0fba774b.png',
    id: '1197',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44fb5e733fb34477fcb0029c02e8dba3dab28fe845188aa1cc3bd77d54886acb.png',
    id: '1198',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04aaad5e993559be7312fd9448f70a762ee34905ea573cbaedc01e17dd9a3fd5.png',
    id: '1199',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ca3e24800fafccc3543c9b0aa84cd995ad13069df515ea5ae4986f7f0edfa28.png',
    id: '1200',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5b21a461dad8903beb34a5a0c706fc8b1d313592af66732fbea453983d1a755.png',
    id: '1201',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4072783db254644ea2a5db8a66e1fbe5a0c177033056056dd9f56385cbb0a06c.png',
    id: '1202',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37f72fbaf19c8b6f8a43f1925b2c52180a8bca4d8b75cb5bc1aaed80cb0ed748.png',
    id: '1203',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8726ef670eeeb18e30fb4c9de58c96ce61dcb0aeb5d1365860ad66d7fbb1bd60.png',
    id: '1204',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e371b81e1fb2b08a49691af75a763a8d166b3f7d0cc6cf549d4abf9ef650c188.png',
    id: '1205',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0faacd7076b0776d8848803b0a58d384cc4d69474a9eacebc6abbb12ccffaca.png',
    id: '1206',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4ed64ebb6884ce3f1bf33d57d237ef2afd129bbc023846d8d12fbae9bf2dec4.png',
    id: '1207',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a0c0add055c5e470a99636ea1d0221742b2c051f0ddbb2bd4961430fea13770.png',
    id: '1208',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c662733d180d8dd16a3b1cd49156f8811e2b76997a6ce050646fc987558d036.png',
    id: '1209',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16bd94b855ad4cf7207c5a24a87728c5386c25bc23640c18a67e235541cfa035.png',
    id: '1210',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03af54b1b96708f82cf3eecbd874acdb186e7adddd8e18ff83fbd845151e6bd8.png',
    id: '1211',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ceaab12c8fb8e94a4f0178016a917489cd3881f92f4d0ab89cab2b7de70468ea.png',
    id: '1212',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37ef8da3a93654e8cb23496fed8de2ff2b7d2553022dfc34a4c35570b66c25b4.png',
    id: '1213',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52db354f9378d9ff786db41bc999856cef27946a218f036f3678897ed6b4bd19.png',
    id: '1214',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ccb34389523ad1743150f293872159153308e3f72e33f3a9cc9b97b8a1ffdb30.png',
    id: '1215',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0aea5ae2aecd2e98951ba45d86ecf3bfbf07e6e7c3d49869a0a0c2aef978949.png',
    id: '1216',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d09f3cc3bad8ba9ddf29063caf6e07adb5b555db15fac38bd547ee9b501eab36.png',
    id: '1217',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24fd36f2fd02edd33540be32d217dd415ff7a202bf2731f3c851ee74d50c7976.png',
    id: '1218',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/037df0d52265d24455b2fa369e05d864663ca00d78321dccde16a9db1884c733.png',
    id: '1219',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65c2a9231ca27236c19e428aca806d0224b001d5b4a110812a91c2e48a57be53.png',
    id: '1220',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/879759b42f291052fadcdac4fe0828c67c3c8c8a2d84f749f3af3f8e23057809.png',
    id: '1221',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/745007c6271b3f1d2026fd4802e3df47f23ab89b782bf2a6373f8e8608d3e431.png',
    id: '1222',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c9ede19c6420b9320f7467cba331ab8b295dabd127b8b0c54a65b65a2b50eb0.png',
    id: '1223',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/401f6149365dbb7c05a75ac6f6317d73fb84b0c936f0d8853d86808167869d38.png',
    id: '1224',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f771be1a4fe524d649997f9d8e030ba1721c840679f43522dcb4a1483bb5b26.png',
    id: '1225',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Levitating Lava',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9455f8396970aa5bc9f6f2d264ca3dbb16c6e64f88dfeb55c42e64b102958940.png',
    id: '1226',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/170ba2e6ae0d049645731f1ab6a322b6be085e80f4ddc0cb94fa4c45d2cbc9ff.png',
    id: '1227',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a00fb3b0ce660e1ec59e9ba9a81c7b300249fb6c810b5926babc2ea28ef92eec.png',
    id: '1228',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c09222b60a3a321206bb48e596364dd1dce35cc9f764c20efee16e060735f177.png',
    id: '1229',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3eb127ccc76b316879bfc173ed20e4c312878aa25cfc76a2d6b1aeac7d18900c.png',
    id: '1230',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/638ca5cee23e8dd34858c16068731176103336228793c6af7c5ab1ff4c6b19c3.png',
    id: '1231',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/908866efce65ed15f1d0b34f04aa54de22e3faeb9ae2cd99eebceee8785fb9af.png',
    id: '1232',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/734d5576464bb70d858551524a6e4799f215b7c0ebd362ad10b5bee8867bef79.png',
    id: '1233',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b03b1ed4aa626f2591ff761022d2bb691f5850d092af8b70ca2bb1be26675a68.png',
    id: '1234',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b2046add88381755c73141381aa33e8dd5f6f997d63ff1c202a16a9347beab9.png',
    id: '1235',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/811bb0d5aaa4c19bd2e21234afa34d9e86ab4480b4b40d5802b3b6c1308c56f4.png',
    id: '1236',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/237a8a17563074818b4dd28cfa463f223ef8ed49b86e91a49c92aa81db0d61b0.png',
    id: '1237',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08a93e73af8fcd232f73ab12e768b350ee614317a2841a27cb50fc98e2a24a88.png',
    id: '1238',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33a03f0b2526522ac57f8937a18e0e6676d6e68f702013e5dead5ee1502fee68.png',
    id: '1239',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bcac1b0822aa07cd85090b531fe07925668fd9defe7c2ee537b0ae7d64c5f586.png',
    id: '1240',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e53ec23bec88be651bd132e2df7bff079096afc37cae35006d1c66195a241b63.png',
    id: '1241',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d5c4a72c1f6aba5b80a7ba0ab9a9f758616a307741719692f502c753e54a781.png',
    id: '1242',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Weapon',
        value: 'Shadowed Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddee1c7d02696dd6497020ed8a941bc7252a0325ffc9b696130c4f2d7a43821a.png',
    id: '1243',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30b14386877605a976d84b0afae3ca0c2e42713e0c9ce61cfed3eca583b42f3a.png',
    id: '1244',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c374a1719436c8756582d7160e95565187f862474afac66bed652b6faf307eca.png',
    id: '1245',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2074522abd992953b353f98767bdb3662252711a4be279695aecd22d50d54b0b.png',
    id: '1246',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/290a0f4e7b254cbce94bf06e1af7b64e16e4f424522cbddca2f21eaa2c2781a9.png',
    id: '1247',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7748b885b7662b11e22a72112267fb10485e4f8f29ed65ed6695617bd622c0a.png',
    id: '1248',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Jaws of Evercrushing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70a36f100ddc6de32db4c60c6d8fe3bea7f4b89ae63736a0d8f57223399cbf4e.png',
    id: '1249',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f04678f4d2e253190d274e3145f9c83730ae4f1f801b188bc4641f92a992089c.png',
    id: '1250',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f05d216fdda0be54334bd9a0e79b20935641ff933e3e6fd24cdefd29e0ba055.png',
    id: '1251',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23cefa9cd178366462f4dc720511e10e927b441a94de7c26f92cc18fed1e5c06.png',
    id: '1252',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5ac2db37d11ff139722100ef428009b6b04edaac06cbcc387e406ceba1b1e77.png',
    id: '1253',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/897def3dc3033fc1504975680e497daa3f7b83f0e5f290d19e850c92afc66c4b.png',
    id: '1254',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6c8e103dab49df22e0d2fc1dd6a0a793337d7120e863ead9bfa0f8bfaf6d648.png',
    id: '1255',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Claw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47daab7b02404c0b218e49f0c6fb0eebad72baa9223b7d1975f13fe98c1947d8.png',
    id: '1256',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea035cc3fc05bf758d03cc86417639132bf0014fffcf0f099fbe2b422fd9e7d4.png',
    id: '1257',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mush Clobberer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/042e772f203f8a77f29602605067daca8b966a1a319018f0f5cebcbdea309df2.png',
    id: '1258',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61790635b37119215d9df28dc5fd02c0ce4a172f33578b38c758bd11e601308c.png',
    id: '1259',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15aacfb2a207a59de3905716fab71a832989e028cffdc47304a24e759992a068.png',
    id: '1260',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72957962c11feb7ca5f05a270727ce0e70e9b457d81d7a06dfccfe7782fdcd96.png',
    id: '1261',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/edde29d184bf72db5c5a6c6be65e131b9aa7b05004f6ff3166a74dd82ae0e5d2.png',
    id: '1262',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc2c1cb8decc5a3e53a37448201f51067abf7788919f86d526fa9d87d5241638.png',
    id: '1263',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/583a5b93f567ce808cc7054e4e7d7a1c0ae7f0fdd44c78b28e00fafffb5bfa60.png',
    id: '1264',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbaa674733d0d7bdc0867e18e0404909b90a60f0af4f08f6b451f1ad866e3603.png',
    id: '1265',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5ae6d4c81fae55189ad72a3c6e52534f54da9bb6c8679e7c87a607d42717079.png',
    id: '1266',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b21cbd07ff103545fe4a28bfb9bea069f4508853b5e143387b92727d6cda2ba2.png',
    id: '1267',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d419af94a6a7f77eb1dd6d878e6a6a15623c9511729366f726b9af74ddccc854.png',
    id: '1268',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e176753b729608ffcef1579865bdb32634bd25b1a6ffc136fe11c32829a34e8.png',
    id: '1269',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2da099220b9709c18a3a8db2703df8731f5b7c5c49da3189cf40e0c274b415f.png',
    id: '1270',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/421ddbcc7e9d6bf0ab9a8a07bf13799ebf51cfa8e088febce4a42a53c73526eb.png',
    id: '1271',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d460ac0d6a1fb2e12c78a99574527fac86d878048cb42ce0b913e6422a44505b.png',
    id: '1272',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd3eca7f3f8e11a746136d509c5eb9a51afdb57d3a4c2436b046cbd3abfaf621.png',
    id: '1273',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8f9dee124d6d1b971847dd7051fe9f8c9632a9a2a79c1fe11f32331d7a7d117.png',
    id: '1274',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Weapon',
        value: 'Twistree Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa8d16252439ac6024f31aa72b41f8771dd88b91f295c6176105e0bdd7b7984e.png',
    id: '1275',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47175327ddf7f4375781925ce34b14b4f0b3fe062fce585b21809bc77caf5c35.png',
    id: '1276',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6e3f1c63b8ae1b94179dee6cac9ccec55be755649b6061f89458889662af9f1.png',
    id: '1277',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ae2722aabed8ee6b37bdeb988dd011d59e016feadfde970a68f6500a289dbc9.png',
    id: '1278',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/188ff727d0d3c37ece1705a993f9e35cac1a87e3259005184656aa89ed27af38.png',
    id: '1279',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c24b0844afbe8002fe1822df10ddf31d69af067efba1ecd5baa262e3c757cdca.png',
    id: '1280',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1fc4a9a3d36fe8de1d530782123ddd4131622b5f7256e8de0b9d1f6119c25b9.png',
    id: '1281',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/396a50bc86c8bdf6f828c5a71e28a06ef8a868fd1d27cec5cfaa5095734c0cdb.png',
    id: '1282',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87edef2b42a5b59b156c7a8df4587643d108afc0b04e8e4eeb0152af2f9083d9.png',
    id: '1283',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12f686b1b414a8e3eccc0e6e756ebbc7628fcf6a1fe667987918ed54be792595.png',
    id: '1284',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6994bf81ed102d5a319b0d79c4894d122fde48647db406a95f28dd610e73c940.png',
    id: '1285',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13eca08199d1c6be80c96d933483c70ad208d1bf0112ed8e92c69a6943f8123c.png',
    id: '1286',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dff5f79b0a39c25a2c4b5bf2b2ff654ed1760c6fd2016fe676adeec8d679860f.png',
    id: '1287',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d67d733062621491b66614ccfb396164a7dec9a1c411d068fb6e3da14a03a969.png',
    id: '1288',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/511777a439292309b103a81311305c3cf44f8b7d6640bfc9aefd7dceff8e04dc.png',
    id: '1289',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b0e543720761f27e31b1890644e4da89e06e4f4a17d8d6a4c58163be467a18a.png',
    id: '1290',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a641cbf59e59945cf31b9c0f64d1516fd069d478d31c66e538443c4d90c34aaa.png',
    id: '1291',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71a38a444402ae1fa76f3fb9fecfc3deb66b1efb34cf91dcf1f3e758cd9a8500.png',
    id: '1292',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd331bfb09cabcc480aacc3a5e93807ee3f13af01bfc7fc599e2043267f239f9.png',
    id: '1293',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b624d39417eb9926d68ba51765ef6801c12dc970235a021abce743841db280b4.png',
    id: '1294',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dce986e5beb5531ff1924d21dff4ce0510edf04c78d5d6a195c219b369c578b6.png',
    id: '1295',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9a027040e29cb56e85ffb4271aa4e7a6d99b50f71f05ef56159cae3e902957b.png',
    id: '1296',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03585801168d2ffd082438a93da9cd7c95e5d1e9069606b865bcb15199917ad1.png',
    id: '1297',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a124f9980aedb2d93fd127d5a3a0823e639c22ae137fa9c6179039e29cd5ffe5.png',
    id: '1298',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/edf64990d0b465449fa08aace90ca54bfa9fc06ddaa58c33fb95b8bac75cb650.png',
    id: '1299',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/184d2e1424acba381e0ddc1ae9cf157491c3dd231dfee1dd54d1f7e4b4e01a70.png',
    id: '1300',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5edd8144ce710f74c778d0a65df9860405e5f7afd24ec90818c343e201d4cdc.png',
    id: '1301',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/937dd20463108fa47b48bc60e21547cdfa81c429cf25946ee6e0a84fe0f173ac.png',
    id: '1302',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4c2dd0f0694731312fd99aaeefbd9a4f15fe2b144077c9783ffc2a0938f30a4.png',
    id: '1303',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cf020e64083c05166cac65aff70b46aeef0530d4da400821d7b2fd23fb14490.png',
    id: '1304',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9783c920a8a18550917c57dc7016a67900403dfb128aecdc730c1d7b9dbe28ee.png',
    id: '1305',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b36716a8ca4460f0ceda37e529fa697f4dea8157d57065f3d445d5b791c30b0.png',
    id: '1306',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62df77b6fa07cd3711746ff7cc4778ee581c7f31409c2216aa1de70cd66faa26.png',
    id: '1307',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21bd36ab1f1ab95b6eb82d9f1893b44f1a25bdd2f0baace78c09f3d88cafa710.png',
    id: '1308',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/180fb7116d086f2badf3b4309776c508e73fdb0c13baa81c48d8b3babbb62e02.png',
    id: '1309',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6375429a31dde203bf7defc8bd1f338893257d6e1899ce5e8c3cb584402e1829.png',
    id: '1310',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f75eec2033395039c2d5e0244eea1fcc0f161e84e51998fbf938f93905fa3dd3.png',
    id: '1311',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c899a0970b00289469b158ad43b09f61bc8932423e74c5a05cc4b824ad0f613.png',
    id: '1312',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df1dae927d1b96c30ff482193b98d8b94746a41459683d30ce923d390e1eeb45.png',
    id: '1313',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a8d0f0b0a58df9165b1d52264d2821367a80984d476a5be6334c86af1ddbebe.png',
    id: '1314',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/207c1d74db97436b432763b6e246e4f621988fa978115d858d83c42c95d685d9.png',
    id: '1315',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/616a36d92215b3f2a2d14e4bc713dadeea65813570cd815add4f053557b46e5d.png',
    id: '1316',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab120a29fb81f7ba4b616cde1186684e1877fdd9cb292b27ae6e6cee4cbb0492.png',
    id: '1317',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/460ba13dc2329717f48b3cd63fe47c409ac1c19fbc5e6c3abe10ed94ba17ee2d.png',
    id: '1318',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17db4bf26d18a1e42172bb8fe72bf8c2eddaa50cfdcc4114be2aa0cdb90d2185.png',
    id: '1319',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8169a1e8b820a241d0f6bdb57af47f6a15cd8200b4c19c1478750bfb134f8573.png',
    id: '1320',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c7bffdd243b3711ee76a71eaa66711464cd51db60e53539856ba224160087bf.png',
    id: '1321',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49601ea5c90cef58d5a252bb77a5b7c505ef676dd26955de946343831298a923.png',
    id: '1322',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/685c322fba052ced82e6f2716be7f821ece8c91e93ec1d4001a3a9078d5c94da.png',
    id: '1323',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d82dd58f52df3c1f5b9a142396bbe136cfa23ca4e5fadf7b8b88594318a75ee7.png',
    id: '1324',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45fe6c1ffc9e1d526df40bd35d72918a5bf9d963aa2173f2008a3633931dd022.png',
    id: '1325',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69c5ea1d1d6803bfc738186e68014a83ebcc72773c7ea2c76def158c6dbe3625.png',
    id: '1326',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be28624ef3e519e6cfddf93e11d8c071202a06224279ece03dea2034c0f3f4dc.png',
    id: '1327',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a18b2dff837166cbb7ccb60f15beaeae52a1405c4d8a5a0b8b7b40afbc947a99.png',
    id: '1328',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6983eee444bd0c6acc1c3bc11c1fe9dddc15cc84fccacb67dcc58b3cce9c1d3.png',
    id: '1329',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3bac873b8d39814589c06312ac1c9f7c108676238610450b5d058cb0c8fab95f.png',
    id: '1330',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a756a72d2c6fb32fe4b381a5a2ce12e6351dd52f95e1be5d41fbc9d714dc3324.png',
    id: '1331',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56e05d48418fd0735d8354f3cc16822808e55246e320e102e532273d8b48f5a9.png',
    id: '1332',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d1f089825a766419830fd1c129d26ca398d27cdbaaf0ba9f3e6081006a75e39.png',
    id: '1333',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16a079c7143855d1c31a813d883efbfd8d449275950896b4d7548c76a7337850.png',
    id: '1334',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14fdd3723cb4c539931fa59e8c1752ffb9afc30a0a1037af8c574c0f21ad7ac6.png',
    id: '1335',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c246f5d8762d6af7bdccd4160b041cee54dbeeaf27b62fe177added4a9311635.png',
    id: '1336',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4baa53ec8f1f0448b81738ee39bdad0b0b4edc5ab4a033e0790bd9f05b874690.png',
    id: '1337',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ea5656b7a07e2fb2ca02681ef2a25b9af14ac25cda51694e6e3a52b506a546b.png',
    id: '1338',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1dddefd0b9294055f2e1e542efbe6352ee0c58afbaf5953d3db0da9f5c4bd804.png',
    id: '1339',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2279c215f0c318d3b8d3a821b29921a3659359da8c029904e9984bc68e55cf19.png',
    id: '1340',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e1df02e0659c534a78da35c3bd0e5cfa798b241c3ea9bf98367f778658a68ee.png',
    id: '1341',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c505ecd8ead96c1a030916ba9ff6a15ded6e9e233d76b5d166e557a2f9827dc.png',
    id: '1342',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f1cb8e9e351331a06edbd8e8acbd37f386d3aec3d535c7955e85aef73ca3555.png',
    id: '1343',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0e1a50f49b0713b07f22b0058e4fed8a9981e180eae316a4d2c541ada430724.png',
    id: '1344',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ef33ecdd63bcede954e0b1095dea39968e14e8acbc4ecf312920908d07bbf25.png',
    id: '1345',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/426206c7548c588ecf4e64b53f552de02a810239d7aa45899b2c4b6b8eb5504c.png',
    id: '1346',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bf9fe5e18649b4db8b08cf13c21a0df5f071eb95e9e4e1885299bcfaaea6b0b.png',
    id: '1347',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/292b0cfb7fd7bef20a6aa6388a40363f0c2fe62be69bfa9064aca259b4b7f24f.png',
    id: '1348',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7676e4476d5e88849cbcea01d56ee6635aeacece4dcbb1a6721570a609992fab.png',
    id: '1349',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d8186ceacc3cdaab8c8134785f11726278222f707f36171000ec695583ae2a3.png',
    id: '1350',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9bc6cbd80d42d4717cf5366be3b152fac6b2e675dbb706bd6fd35de72af5e818.png',
    id: '1351',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4e809cd3d903ff05454e5d1c6fa7544c932838a076f7d21aa616f96db521de3.png',
    id: '1352',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97e9a2f9e16964845b0a9f56e1dbbfd4a3c4d768fc81de4a4516fcb684344bf3.png',
    id: '1353',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b65816647725dbcb1e4fb3ab128dd14a61771c802c6d918b5b15ec6ae2e930a4.png',
    id: '1354',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6adb369914eea85acdc386e3e0663be852b3db5a2345a14dfff7dda6d0ac8da6.png',
    id: '1355',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18bea0d2360b6ca0aa1154af945ba97e79c7480cc7022a53443b6a64060e8685.png',
    id: '1356',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38c9df96c52a47ae3bc042ef061cff9479a5bcddcdcdc2056e9b7f1bcc30ea3a.png',
    id: '1357',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbcaa9bfdf1ab60860966d4d984ef6d4413987001d93809c1e5151d68059f42e.png',
    id: '1358',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51dfc42801b16ff656efb913c619cc16f2b364beebcf5e3e85b55c391759cee3.png',
    id: '1359',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9bf039c6651eac9ff3cb2b6094e1eb33edb1414eb2e158827257413a8d74a283.png',
    id: '1360',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56731be6de3fbafb17469cf02698e4461e16386a1c6aaa8b460d55980104a3e9.png',
    id: '1361',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbcaacdfe8b5ad51ff623761215aaaaa6195b750f966b32164ff27bfb970b1f9.png',
    id: '1362',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa881b0ee8f20d5688a23f6aad1661ebe3273ccac41f12c108c58e856f5f5148.png',
    id: '1363',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/748cd374b914d74247d70d6e7abcd391f6c73c88bdcbf39adaa7fa7131d4a3cb.png',
    id: '1364',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f07ead487c5d608789e7a7b7e3b1864043c29102f82a64b58791e90bfb6b7060.png',
    id: '1365',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/709b95168c2947c447b686237c9ab15c93b75f9f7bdc20548ab98bcc35876ffb.png',
    id: '1366',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9d5625f9fb18dd944d6c0fc9cb3694865b9157bc152fa24042f52393429dac4.png',
    id: '1367',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d28afad70b224ca2a95181373bc45231107c941613d0f395c6201d652a609460.png',
    id: '1368',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17053322652a2afa23dcda4ed15728a5e77308dddd1525c994c53ebf63b5000d.png',
    id: '1369',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Scythe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9c57b93f3ef19ab57fa792e2920d03b6264327272392be0b897bc2e968e7dcd.png',
    id: '1370',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bba32b889a7add601a89a7cfeea204afd2a25fd7022cd211a674193b1d3a84c7.png',
    id: '1371',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ee2d7613d384f41a752eaf774fafe0c69ed0847dd9731f6a8d90c46259bd210.png',
    id: '1372',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5910f979fc1617909e68d79222fca96b709606a93c5c4b1f7cec06457613f47a.png',
    id: '1373',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e353dd5425f5535c3401b2901c35b810b408846df12cbb0939b48058166f8d1.png',
    id: '1374',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cbb5cce05367c4a1abd204d48fa0bbe1e1e814ab3ab2dc3b5dec4a864750f0d.png',
    id: '1375',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45dac4662612ee56c67677d4ef9cad084ddd6084d25589cdfc7fae71e5083f14.png',
    id: '1376',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b9ab4057f49f25cf3be3cd9acacbd03003e5445a9ad76642dedbc65093a0db6.png',
    id: '1377',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/250fcf517958751635807cb4aed8ea156bf0db220d89b6b7eb30f199c8e0b356.png',
    id: '1378',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5351eafed6d9920ebb228368663a8a06c1c39eb481eb0272f9a66abea1f04152.png',
    id: '1379',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/176f36c3dab676b3f7f43d76d0120875879adeb6873d30551b7632f27c9c62dc.png',
    id: '1380',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf403f15e5c34833fae2c2027087c21f8e90ef29cf754d648493708730f0868b.png',
    id: '1381',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/131b9ff7ecdc793ee3b70dd408aa38305e2fc850b51594b1fa8d9cc88c21c0bc.png',
    id: '1382',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aaef332475caa3d536f36eca3d4d956bad11bd80d9a4adf399655840902328ff.png',
    id: '1383',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84a4f28b4e5061e10e1bed9bfc0f2863b16ae56f783763b1b212d971d561ac6e.png',
    id: '1384',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/832fd457904895c95c5f3360884f623d936dd2342967bdabdda44edf566c721f.png',
    id: '1385',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60635d5bf45b4987a33a0385372e026c1882e436af7aa290d1c3b8f9f93c6e52.png',
    id: '1386',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/797853b7daa534cd736ec9adf0447f4dfe595bd093467b72be8008a1fb9faf81.png',
    id: '1387',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91df6e1ef1df0aab73a456c8a7393629f7e03b1fc1950ed42b3fc31ea744955c.png',
    id: '1388',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4176e89ca3d3ab5cd23b9cf924a4e2e8adee71c537e90aa3ac3663b58725b5b.png',
    id: '1389',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f19e7db75b49fc330c6413d59c63e276ac60f5ecbc127bcc623f1900c09cb8e.png',
    id: '1390',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1923ccb5a14c81884bcdfefe3ddf9dd26fa0d8ad4860375594def5c845f9fab.png',
    id: '1391',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87007445d8f394e703770233d9ee95f75bd5e9853413d68eb4eb19e28a3a4033.png',
    id: '1392',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91cfc503bed9a5a48740fd73de955eb2d5be80837541cb8f6094c4d953e676d1.png',
    id: '1393',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf6285e84c6cd941a257409d98b88be5be441218c4687a96536bf6da0cf6f8e1.png',
    id: '1394',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7a3107fc66cdd1d773c0d7734415de56f6c2b69d8f8036528b4c721dae23841.png',
    id: '1395',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a22264f3052bf14f389917648389c579f0eec3eea3982b8ea7f40548cefdd938.png',
    id: '1396',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b7fbcdf36393d0196e07aaac68995dc856e82e758c93b07a8a79d466b6a705e.png',
    id: '1397',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc657a35e6b5a6b3c0db6c39bae08a5ef5fdffcfd93db6fe8eb767370cded35f.png',
    id: '1398',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7a359db264c4bd83ff101a232fa8ef3d66cfafdcc5020058813f6b27b71346c.png',
    id: '1399',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e90a2ffdae50fee00e9a93017ff9b0e5767a1495b722a54406c87ed68fa4eaf.png',
    id: '1400',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be6402014769ed0e3f737071f92738066f3760bdfa1f6efbc1c68e0edd552090.png',
    id: '1401',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3773557fafb152bf1d74093707223db95c66113234cd79f89d4fc31abae988f1.png',
    id: '1402',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65015a28651a453847691b522109fa0af2b9c1d30cdf0ba1d989b0009e0003e1.png',
    id: '1403',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac3814281e25c2d974f5935149adb73973148615733aa5a43c91f62aaba07a2d.png',
    id: '1404',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f2e32e9aa2625deae3e7c47e33375e6e4ce29905b3451a804fc8933ad4c3435.png',
    id: '1405',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27bf9b9d958005f967349950a3fb345963b0426e42b0cf62a26c76c447d3289d.png',
    id: '1406',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a608eac34f3eafb21f435217b9f889d5731ad0db7cddf24c5f6ad77950f30d3.png',
    id: '1407',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa334440b359eccbf2225fc1ec92586c3bdf391accec36ed33698b3ab6ea24cd.png',
    id: '1408',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/046712f1d38960ffeb830964e76bc60e1785ab515020c0584add35d5c4b7a2cb.png',
    id: '1409',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51d52e7710028412c66a8daa8df1ac168b1aa2bed0987ae290eaffbac7847e25.png',
    id: '1410',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b848d0d9bd8bcab048df0e7e1798ea5dab75d039480ee28e0d2dd49298cc0eb2.png',
    id: '1411',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3172b2013ab5fc17519ef01bd0baf53939bfb89a6702ccff972e02341a1284bf.png',
    id: '1412',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/681c205187731eff9377075210751240a08045c9df78ba3165a78d9fb780532f.png',
    id: '1413',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f62ad1b926e6f4318bb4127d7c950d81da25c2b140b6ddec476832d1c8f741e6.png',
    id: '1414',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b9e847c937f80ede411c6f6c27b41fe5db4fdbff87e99bed61299c1078e790c.png',
    id: '1415',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ffaffd9e18981f465b8332b195382eb7ae3cb40fa1c5e3510f545858269e07a.png',
    id: '1416',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb570d5e96b890439682763bd663a86fa628610bf34186f48284dbfc9ff275f4.png',
    id: '1417',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1bcce23bbb506ac4ce5aa1f3a4359687d0bca5c75a34841cb532d8a3ba0eba7.png',
    id: '1418',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/947658bb0f1cbb9258cdfda374c30049ba461c697ba90e52f0f234e4e7e683cd.png',
    id: '1419',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b4a4fe5bd0922015bc954138e77e5fe36426294d265dac6c35d2a7ec95d527d.png',
    id: '1420',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f44969a20bbfebd54eca6448f436d6af9e459262e515b713b0d7291a3e8082a4.png',
    id: '1421',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c390e52a623d91a70b95bbf74bd440f07325fe12ff1e292d69acac49b0163798.png',
    id: '1422',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c80dfa11803184e0453ed7c550707e3134892737413023f432bda34c8cf8513.png',
    id: '1423',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c35459abf938e8a8bb248c53175e632f54e14c95070b393c4951e0047c298d33.png',
    id: '1424',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b7b3a0852604aa7a11b1b73192fbd87957afdb7b98954c98d3f1b0bbfe1347c.png',
    id: '1425',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f60378413d1ad97677a10de5c3f187061a522d717c3dc7be42c6a9e13ce50a3b.png',
    id: '1426',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gnaw Saw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ed616d39c3ac5f6f6e895e3f04f9d045c494077e45d78ac3f7199642c1acd8b.png',
    id: '1427',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1677674596bbd5ebaebf88df1a352a567b457ba5cb1e97bfadc2d74fa26d98ee.png',
    id: '1428',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bdce29e35105ca420b7d626062b1e2baf1562b3291e37696eb9be25c12ed83e.png',
    id: '1429',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Weapon',
        value: 'Adventurer Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9ec8b39f7e3694560404ee6b433954ef67dc3d6b01c1dbade19659ad0a25110.png',
    id: '1430',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/078ab7426f146fed4288278b4abf1f2340c9c91d2eff8a53df96884b31a17b40.png',
    id: '1431',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b42c6a272875ad0be9c8d522a8002f2f037790fbc413412f137f8419186e03e5.png',
    id: '1432',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/800d40f9030040b75f35de15bf78d18725d24ec67987919260e36434f62d6a0b.png',
    id: '1433',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f304b83ec37e8f066b560d76a663ae054ffa771fe186b99f1c65f318b45ba77.png',
    id: '1434',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96db1c33ae333f95a4bb6d3ffd368cc32562b571dd2575a917c5ba77f9d831d1.png',
    id: '1435',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7542956699580df4889de1430110d769f2ee334dc1e0e296bca132002e18a01a.png',
    id: '1436',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c84fb897cda1d1269e0511c8597886e086265a075f5217227fe85ea4ab9ea544.png',
    id: '1437',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3dee4c01459f6deb258a420953a0139994f5289012858f3d725bf2c006ce43f.png',
    id: '1438',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ec3dfdb88f4128b2924ad87b79741104c6a999eaf3a25ee0753dacf22233a55.png',
    id: '1439',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7badc25a40ae341e01fe2080f56cf7192592f1e508025d3d958b559f159f16f.png',
    id: '1440',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95b72f47292d8378ebe654854355391d59fa7a11ef16e46875eae2ba4a9b9e7d.png',
    id: '1441',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75afa31ada64554741b9c6e02a71da729b2acc5dc137818f9280f28eedcd9e24.png',
    id: '1442',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91229f1cc63d27f97f82bebe57f170e3986467cfd0692e3efd5d6f9dbc85db0d.png',
    id: '1443',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d664e9bb6aee38c1bffe96cdeb939349218aac6474df1dd82ad3951394ceeecd.png',
    id: '1444',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1669015b1757c1aae776c146a48f8a6bb26486643de2a5ccc10fcac8aa31995f.png',
    id: '1445',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10dde01a38d6dc8c8ede3a89d628db91e21ed0dbe582c7fe612ef2b98ae283ac.png',
    id: '1446',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b045ddb408449f606ebf437890a7a67fa34904c70f7bf3a3d84fcf53bca1648.png',
    id: '1447',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5b8839ec9566481cd289b9447181a3d1f86b1202cdfd0ff96417df5d10949a4.png',
    id: '1448',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ff4ee2d7ed01fac015262a07acbb7f9c460a4361d7bdd632440b8c25a918b17.png',
    id: '1449',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1052fb08e58e878ed8c5ea99671a790f53a194253e213ab12b9c1acc3a7d5982.png',
    id: '1450',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf30babad4bfae13fe40405109480484732f73a40438310869e2c84a857647b0.png',
    id: '1451',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa6bf4d19380ec1171135ec963f8be071e51233b2e2df1ad1a278cab6f1df6ce.png',
    id: '1452',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff2b710a145f18b7d825713ea4238d1d3bb0fd5c5f4ad4b429d1595e0fa54713.png',
    id: '1453',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9064e54eb2cee9da9521711693944694326be14e8b2ff437642e1d6f37880f6a.png',
    id: '1454',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/803f384e580712be401f484b94438a3de7a5dcd148f90bab0e0ee0e7da7aefda.png',
    id: '1455',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c67e3bebaaa96ab7f09b2aba19d969c9d89993296cbe064ae5fc0590400863a.png',
    id: '1456',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3ffa5c3243e9addf187c79c68b9d54dbf6ac9a688cf9862c568a872ce75323b.png',
    id: '1457',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbc493accd228905f43471b65cf10049a67c8c65cc0427c7f52c5330bc0b101c.png',
    id: '1458',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aab5b37539980d19517cd622a163ac17346426679ec66a2348080914bf082e70.png',
    id: '1459',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/413852eebdda9769ba569a2160e2ace1cec0dd2ba537f1275283f88372977eac.png',
    id: '1460',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36aa0a552e0bfb0ec76430f951619e7e69df32c8f7e45cf8e052d070485861d8.png',
    id: '1461',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f1c53d10ce268b1c81b4e4de885bbf492bd8e5906e2752ce15e0950e70055ea.png',
    id: '1462',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/867ab8a0b1e10dcd157bc9aaebb1b3a6d20dfe9cba2a16c10e7ff1f61ad04531.png',
    id: '1463',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89779ab551711a531dca5901f7680f35cfbdc10fbe18f9b2908c08324693bbb3.png',
    id: '1464',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6235fe8aa7743ce4cf8344364442c122221ec679b19d1181fef7b3973cf2170.png',
    id: '1465',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7de3a5d3fc0f153a2d8256e2ef256d3a509b27a09a94c161c5c31e9a9b512108.png',
    id: '1466',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9b5a95bd987528233b127b519cc0e2beb5a12e5c0569836a513cd0ee3b6cfde.png',
    id: '1467',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Undulate Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be5e3267450e5dbb356c2ca09f15dab99c0ee0306e819d57a3636ba39b67da84.png',
    id: '1468',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d0ce75c04268d13f2b62ba1caa8a7d60d6dd29c4ff4fe682dea2d688a32318c.png',
    id: '1469',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc66c0558e0f8fdf90078d6aeccbcfd05481d86a1e8b23d72aed0f51bcf3bd41.png',
    id: '1470',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a408c2fb0850a5cd2974594f74487e1abe2e10a291d2fe92147bf7f3e624d0e6.png',
    id: '1471',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5121d285e0291cf735913e810b5c458bc80a81bded9ff59f83c8f1750488ecdf.png',
    id: '1472',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f80a8a16389da01ca5b7254378b300bcf45c0018039c6d43b4514b587f3b872.png',
    id: '1473',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e555a69247f1595d306a5a4d09246211b0c7edf1c2dc8cdf38482385bb0e77c.png',
    id: '1474',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0c1cc6ba548dce6d9952ed4e9132ff72515c79be4535192b1d3c0ff266f30dd.png',
    id: '1475',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9449e0daefee8da7612fcdf54c5db2ae0ba7869c6320c3df6d5cf3b79592a9d.png',
    id: '1476',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a3cccfd2396244e2669d7f3227c43d43eab6a783c322b847451399b32bad3c0.png',
    id: '1477',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/704887d7d2fc6135af3ca20a01a4de4575b829d06443fd9531eb7001df09e88a.png',
    id: '1478',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9c639b8946597e60d1f7042dd30432c97c8c1ff357d02edae969d23b420426c.png',
    id: '1479',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc6108a015c5ac4f16626ff66189a396bf1ae69330a3c3e4d2bf3151a0f6bc90.png',
    id: '1480',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77d4da8ff5b82ccb26dbecb5c1b7c1bfd878ee669906d148a77112eb6b3d9949.png',
    id: '1481',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/deb4e81f31ed2615167ced6afbd851bb8f95dfda9c773041d203af01bb875f5e.png',
    id: '1482',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69126eb85eea3eb4328efb0e4680bbff978e3a7b1fc2ac9f69e93743a9cff6f8.png',
    id: '1483',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5de2001d6e326056414f3fdf9fa4035f182f6ad84a6052e048a4acc3c52e80a.png',
    id: '1484',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Weapon',
        value: 'Undulate Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8c397415f07c24afb107ba02244d72073d88767a3887058ec515402fe31953c.png',
    id: '1485',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bff8f07dd2a8ac4b49ea25114e1751bef57bf472cb44c758936a83536083e04.png',
    id: '1486',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2a0cc476abfbafd6f51790111cc3bf42164194901bca8df4fa8cdd9f98c8abf.png',
    id: '1487',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d91244b5dbcf5dd5591dc42064d83ec8c5fa5792df2e140e0cc9eee8c0af52c1.png',
    id: '1488',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38bdd7eb087a27283dc166f4aa8e3ea0fb61e78f8ef658824d668ff80f096aa6.png',
    id: '1489',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/840ea1074cc6d02831ce59043b36dd833abe45a5916656b6b3c58c64ee2fc917.png',
    id: '1490',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/debd52d573adfad49eea3e49aec1cf47de8f9043e2745c7b221561c5f9d46a72.png',
    id: '1491',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/098e549d7631f4baf558199047fd31c71df4e2c4ea22defa9160ce56ac89b46e.png',
    id: '1492',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2341c8032f6a4141a38b1e4907e23ec4efe581f32e2d9e6dba6b46c3a1787de.png',
    id: '1493',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b95183239cfeca364b19b9d2f688d39d3e778ab5670eeb9c8f3969be9944680.png',
    id: '1494',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25a4f8b85b6ba1d0547343c00d81d0da9c0c6010865c04495e52d11042016ac7.png',
    id: '1495',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be46a02b3439de207d907f95287a1df093bfe0715d1f68c7370221414ce7e8f1.png',
    id: '1496',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3dbc5c863a91349112cbb7ea97ad63c3b96b2f49774b9e4d036a2e9cb92fd15c.png',
    id: '1497',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e238ab816a71ace86fa6383a07a22e8b34879a5178c8ce244b91012bcb2b9d96.png',
    id: '1498',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3153129a6016990e40caa895a3306a887650fe09b61d18216545e06fcbf11b5e.png',
    id: '1499',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ce414f0d7e5bff9d14e5f9d3b92ce674e3562a9d9c63ea5df2583b4a253adce.png',
    id: '1500',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5bc7c46efd7f889c6c70227cc09cb99e883a7daaf0c6cd82e9cfb23bb7539cf8.png',
    id: '1501',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69803556067e03de7b950322db165b062bd03cc7a9b5284cf528fb2215c9c46d.png',
    id: '1502',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a7bd01b3517030eaf2dc991d028fadd792dbe0a60ee76a128c7e16e5b6f9d07.png',
    id: '1503',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db9c1ee34b04d1d5e31dfbfe618dd38b1fecdcca6e7bba40f2a18cf08375ab38.png',
    id: '1504',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3263bf6083c4b17f7f2c35b10195736a06984f38770512d41d042dea4bec42cf.png',
    id: '1505',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Batty Spiked',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2163872de057141b50d896a27f1171c8dfc1c19e51188770da943fee5498314.png',
    id: '1506',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/588e78afc28a47c8cd92ebe52a24111412fde6ac932a94177c9c6edc31a572f0.png',
    id: '1507',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94ab36417d76a0b53df17d35174dace6fd4912611b2d52fc3dfbb42a0d39a336.png',
    id: '1508',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83cbff9906696bf24583daf7696aefb04c4a4468aa9e0badcd2fbacae554c4b3.png',
    id: '1509',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3da1ee2da491c5710c911184e0cab457391d4760bfe2ae95bed334b13b03be79.png',
    id: '1510',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13952f5e0bd5151cf12b52b91832955a882a42db4e9052f93b1af25ef66caf46.png',
    id: '1511',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01534255abe57162bc6b7a57ab1226d0e75acbbf249e848ca6e0130d863a5ed0.png',
    id: '1512',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9744ef9c1bcb02b0d15683c04721e344f0ec5234260558a7c0d4082bc447db7.png',
    id: '1513',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d99d2ddc454afdfba38226e4714c7517214c59633402492e081e25d41498e947.png',
    id: '1514',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7f16a7c23f1ffa1aabd52ba55f174d3ca373654bc74e9c96337fb21cbb3b5fd.png',
    id: '1515',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddd8008fc5fce8fa6f784ef624c34a1c5b1cf469157e622693c97df07618687e.png',
    id: '1516',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b72e89ee73ad5dabef232cad809dced244918a37429c658501d57340dc3f15d.png',
    id: '1517',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0031398e7b7c0ee9fc91056f31403b72cab2e705dadd4a130516ca71121c7a4.png',
    id: '1518',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9e44dd516da3ecf83883886aff5e62af8530b3b6db2d6d1ed21ee1455b0133d.png',
    id: '1519',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de94dea0977b651825f81d0cbdfbae68d776448556d3a82a6a40264f2d751848.png',
    id: '1520',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4d22c8952706294ba22fcc093eab7d1119f7b3eca5be498083b104d0f83582b.png',
    id: '1521',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1187d943a2a4a1367a53714ab1b7094fe59e32a33dfa33031d4db772a62defed.png',
    id: '1522',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cef49f8ed21e7ab3d40c43779d357731e698594c7bf3e0e82e676226052ecb49.png',
    id: '1523',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Spinal Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4615113ad460c49bbdb0b49c902d6eb1844c351566cd8c2142aa1949dba07f69.png',
    id: '1524',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1e900ee51af3c268b5dd2ada71b5c10ebcaac13d3ea424a30c3056cf9c6db62.png',
    id: '1525',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70aee497201cdf1e5ac3b254a06422db2d1ae0cbc13945eaeffc5b9963df5111.png',
    id: '1526',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f8d32a69d415814f04a779e5f562e1a4428ebb712d00f394eb80c68a19793cd.png',
    id: '1527',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f51a766856d8bdaa055bbbe1f827657cba77d669762b0d4e8c39500018eb0f5.png',
    id: '1528',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c24bba483c11ef38049cb3400e1f91521254e58b548a09ae26a6cba712029c8b.png',
    id: '1529',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/813674017fc031e89072d458fbf4c7bc16e7f259b6038e0fb7c3e2a8db9af02c.png',
    id: '1530',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bcd780e62ad7f33de4ee16512bf484bb1b0751320f90b99c7410faf8a3cdf79.png',
    id: '1531',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40417aeac121b88554b0a3eb7949a0b20dcb457677cc89f2708f4741f0931e82.png',
    id: '1532',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/221f2e23cf4e90cee9f9051ea761246826815a6516237c33f2dfd90e732ffec5.png',
    id: '1533',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a96c0dd7de8cd15fb53e32471e6d8db3661749df78c2e7403fa64d6b5241f81.png',
    id: '1534',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ae88370740839b76bd5927aaf0978ebc51afe1f4823a7af7ea4e9ef86f352aa.png',
    id: '1535',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1dd54fcea1e8be8d67812052313cb3220d63f838a06f2e9cf1aac6ee4249e061.png',
    id: '1536',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbbba87d63abb74dd73f04348bb4e46a3df0e2593ee83389a52d3f451c09f320.png',
    id: '1537',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5fa1af43b042f087e6c98ecb6e8f40f1f74a0b4eafa20476b2c5e57207f5b446.png',
    id: '1538',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a735971a47a9ae0e0fba78cf32013028ebbb0b893e51a747a09531a0e769665c.png',
    id: '1539',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d99a75529aef043d5af299f85f7bbdaee8fe686997da7068f1df80c966729e5.png',
    id: '1540',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0adfa2fe4b824eb0475743fb53afa3c0759e3c27f78646d87759ef2c2600229.png',
    id: '1541',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f09d5eb0c8c6e30de53e0879477884239ced3c1d44b94659440b48621b2f21f9.png',
    id: '1542',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13789cda9eaf247208a1bab589a3c9289b6c051511e553b8e20d67288895a4a1.png',
    id: '1543',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b261c2aa7b361e408c052968321a3b5f843c17f722f16dabf88a852d63d49b4c.png',
    id: '1544',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb620aec6d94df0d53fb17d45fea1bc3353c5dc4e29668f725017beff32832d4.png',
    id: '1545',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eff797c461067af27b44d0561c2b44af1c2a9596b5ebea3facbcf6904e8a1281.png',
    id: '1546',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23b44e756f9d55d48e786f03934f21d224c6ce3d06ca82d98ff20416e75d5d9f.png',
    id: '1547',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60690fe78c59a3ce7d8f6e6a686366fb2da23fb827e76fd0bb857bec31b40848.png',
    id: '1548',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02f01e7f4371e2762ab25cff9dcf182799ab226f1eaffafe594cf0108cb27f38.png',
    id: '1549',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb2bd148b010fac9d98018aa9febd47924a5e1afd6a4136aeb7ce0eaa38af891.png',
    id: '1550',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75bd65218cbba576f4758ea5f8defcdf747c6d5fed84d857c8932e84f18544c9.png',
    id: '1551',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f08f9ca5c6009c45cce69abff61a3ebf84e97ab5236d4518bc1ac03e3a819642.png',
    id: '1552',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c13a2f2755495769a82d054492c3f0b8df9471687945a057570d8fe824f71ab2.png',
    id: '1553',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a597e55351ff1e2ee8ef8488d3f44249cac17ef11fd3c73f34b6df717561e1fa.png',
    id: '1554',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f23f7a6f3346e50f19c67e6bd5f8ba7c4119162e8a9081410565e9ce5904542e.png',
    id: '1555',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d5a6b37f829c2787ba9266a4271e832f178bb62468bae1c6bfa57b80a785469.png',
    id: '1556',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2844615b8451b5343cf26350cd49ac16c2a9fdbeaa3ee7690e7aaa082ddcab8.png',
    id: '1557',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95b31010743d88512c896de1bc719171771f47705375347cdd7ecc954860e814.png',
    id: '1558',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/373b665dd4a5c160941014cb8b5c881ef6fc2a6a0cc9c3b4c14b5f799a32c745.png',
    id: '1559',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36a6861aaa57e88d7bd94662b2c1458d750774d5e8fe5837c4deda2bcbb8cb26.png',
    id: '1560',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7fafe77c8a109eb8ed66c5f004979566f63b4f67b437f7effadce0405a46503.png',
    id: '1561',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/603303cecd1d098d0cd7f024c27131d6b0a9821676400d93d0867959e8b85a2a.png',
    id: '1562',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/817ad5f70bb5b6b1089dd453ef4cee0936c7ae886cbd61da133ca7169aa6454a.png',
    id: '1563',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a5b1978027cb4bcb9dddec0c548f0ae9210f6eaa80a23559e8bf61a58b4a162.png',
    id: '1564',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a7caead6e039a02dd863b659405122245919905e8dec05741b1aedf5f7e1344.png',
    id: '1565',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0cdbfaab62d4c09a28787c9ba75103aa63396e380db0c0764abe39fa0bdb870e.png',
    id: '1566',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64708b13861ffe85694461a5d18d4a67ee2f7a3751068cad6353c3416ab256a1.png',
    id: '1567',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be5d54c5e3fead61ad5b976047a20952cccbbb74630d0318258411f0cb560471.png',
    id: '1568',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/235ea929c74629f1a08ee01d42b6ce2761f494dd4075b9f2a98782d26af81726.png',
    id: '1569',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e52a0228ce8af81fe3723da077d1b5322e4cf0815c31d0707f94334473ca498.png',
    id: '1570',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a98a568377f17420a4a8b8fdc91119f70abccca71b496b5c8444a013ef403294.png',
    id: '1571',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12fe4b7c5c24d4b0e173cf749af8f9dd29f26ccdeb4a3a8747d49a2917b85883.png',
    id: '1572',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ed01db190fe37d9966f399919c895c51a15c2f6ad4601a1e7604c02a90ba33e.png',
    id: '1573',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac085658e86728db49f3e4fca3985eaa0c0726290c9441133c6a7438268c2be5.png',
    id: '1574',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cb0320703a03cdfba2e841e45139d54b61855c04242ee2d984b4c312d2205d5.png',
    id: '1575',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3735bde97c0e272d62d1ecd2c4071539e07abac6e48a079c67fde692d6e0ff31.png',
    id: '1576',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea739794179627d3cf49a9163bb35b1df3f2143fc76742b0b7379f0252afbc2b.png',
    id: '1577',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f357feff254ed009a21a64398759642599f0ba49be1138c8fe0426d758b79edf.png',
    id: '1578',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b76779abc44c755e3fa53e4b217378c509a4d4ab153423359e2740f820b65242.png',
    id: '1579',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6aadab931f817414195cb07f3a728560ebb629c9a066d5665ba985c232b681d5.png',
    id: '1580',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b259b4ddf4a8045c58024482c058c8f2c9709f1f4a1d49083a239cafbc4bf133.png',
    id: '1581',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b309b7d608927666593ba1c7ec16eaaac66cd459786c97e3e85deef125055336.png',
    id: '1582',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/938303f9882e54badb20adb86c28d85ed69ade2327962391f63c92dedc4f2f31.png',
    id: '1583',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1294aa8eb9a1aa2e3b26aeee5bed817de1a184d3f02c9686621625f419490ad3.png',
    id: '1584',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbdf9e49b1d00a6813496266b45dfffaa854fe32787f43b2da0169e99b85b558.png',
    id: '1585',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dea8d49d4ffccfbaba93fff0268e0195c2abce943b49c10127e5e19485aac50f.png',
    id: '1586',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/889331d0e6125f6ad2e1d7b957f6e2333a9a5f09d08df320697877c690df6ce8.png',
    id: '1587',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae7ff67a016a54d65c6a1ef150210bf672b91a42f1e66a1eff8fa31abb74b2cf.png',
    id: '1588',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ab6fc03725443634478e898e19b4526b4f8149fef6b79f29e6241e5088381ac.png',
    id: '1589',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gunk Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82535c6e8354be6140dfde4ebc3574cc2170dd4c02a15275b0b1329768686741.png',
    id: '1590',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da67a7ea0aebac60ff236d69a8fbba2081e418d3f351570dd94974791d5b8f10.png',
    id: '1591',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e30227a8d6a7439e2ee801e320ca2475e5bebfc06c3eab1383e6f7d508e0a3e1.png',
    id: '1592',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97a031d8f0374cfe60a842faa9660ade7629d3e61bee4ee2ce6cf39505fd1eb0.png',
    id: '1593',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b89fd3566119d37f47a0a35eea52d6a089354edcf2880862591bc412dc73094.png',
    id: '1594',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1289a1e1ac11470832f16fcfa53996a3f899f54e6a8d00a2e79d2037f3033275.png',
    id: '1595',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93e2f8eb2065dc6993e1d53f172c39cc4259f7453aea1e03f513e8f6d12b5d6f.png',
    id: '1596',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2970739deb2fde3a73b19e3cb1d5c63f17905e7e2433354b42c28652aa2e069.png',
    id: '1597',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19cca51edd9ca8e50b62b860c60c1c48ddb4fa46d66b08a2906e98b6dec084b4.png',
    id: '1598',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e6333802573f9e62d6e5a08143f1bd784248ac34de250383017cb818ed260c3.png',
    id: '1599',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/586fdd3c966243e6b1d3d5be642071de32604cad1707df4641bbcd7f964c39cc.png',
    id: '1600',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62f0f5f7a34abdd76b27d432a69e59a89a1aeb4dc3e9c07cd61a0b0931f67618.png',
    id: '1601',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e67ed9eb2ceaee8dcee66396b12ac6bbc7537e130716510a1ede4993fdfcacd3.png',
    id: '1602',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5cfb7c693e8b8542ff221897ca225784b421f9d4136bf01bebc9ed1bbc6bea5a.png',
    id: '1603',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4837cece49a17bf98d94f2f002fa9ef1c9d4f961541a005c80d336ce3e1bff02.png',
    id: '1604',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ca0da6e4c352580fbfdad114d9d0dddfbca411477588855f0ddcdb97ff7e9d1.png',
    id: '1605',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba506ed12dfd3f3ef9c0c2985d2ec5b7d7c2d066613111c6da60bc3c75406d28.png',
    id: '1606',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0567c4de782672f359d6f26cecd76ba8e7094f3ce308490ee839f52d4fa14953.png',
    id: '1607',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a88f6c830fa1964f2be331d95791e6ffbcb7f317a31f372be76841eb3bb00026.png',
    id: '1608',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/767c6ed1fc5ebc95ea0e4a4bccde50499ab87517f8d05f9ee9457fd6a15ab5f6.png',
    id: '1609',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e501f9bacc1e36a6ceb5a241716af2509bb4194e91b4a2ca5419a2fee388508.png',
    id: '1610',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5529cc6549e84e932f260301410c101eb4b812787e6393a21f7d8f119f89906e.png',
    id: '1611',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56dd90628f7087e524d44da5515a419b558371e4c9564b40a37fae115cfc6535.png',
    id: '1612',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b6a10545005727d5a16cc197d61571fd3e1e701495e6f6d4b11996c717014e7.png',
    id: '1613',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9dbdd429f05d9b749251111a0cb0f64ec1ad8bf8f65c8d113321dd4faa8cf8b0.png',
    id: '1614',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e91052a2d40f562e45f0ee78c59ff6a22018dba9266ab4cc89b028c1630c698a.png',
    id: '1615',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5e20f48a8471e74ad0ac70941c7e51eb1c5184541cf518a0c8dbaea4b4342b5.png',
    id: '1616',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6359784c93555b0504a6e68ed679f50d5b6ab881dd00d49cb523d69f2ca3b8e7.png',
    id: '1617',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b5629f379b76af16092b9a50837c948cd075a2600271ab003cac56595fc4e34.png',
    id: '1618',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3de643d95d480ce5bb3f0fb96965aef9de60340a405fa494e9816bbffe158193.png',
    id: '1619',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53c1d1b14062ac74d2a90111934043e8fa941d966f001dfaca1d2100e90bd52d.png',
    id: '1620',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a73f09bfbb85fd46cdc9e51b2899cf4d6172863fa962407b5b7cb19de48f7622.png',
    id: '1621',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52b515be8c9f9649cc930a92e0b4452f4d0c069049ac14e3f3b295fde895b386.png',
    id: '1622',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b2b799a5a80a4e098b07fd39b9f18c3c66adb1e1a82ec796056c79029ed6a05.png',
    id: '1623',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/921ea9953925eaa3cb945f1d5dd8b67fbfd46b9e1c8d61ebfe42001877094a7e.png',
    id: '1624',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a7360fd56bd5be0cfb4fcc5b1ee66f2757f886af50e9b2555d5ed5d27eeec68.png',
    id: '1625',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88aec2e1bd67fad3aff36255a78cdc291629259e9e0a37d5a72a5bf440dcb9de.png',
    id: '1626',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4206a9cde7866b1d6effe716014a9d70210ab16f609f4cea6c6d3731a4026dd.png',
    id: '1627',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16ec751a5b4a43454569bcd8f517d01bdc55ecb9f6fe92e7ae42bc1d0e6cd872.png',
    id: '1628',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3121d556e626d839b6069c8983777cbcc6ad614366a382cb66400240a02ab022.png',
    id: '1629',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/368edcbea0108ee7fd69ef59da6ce02154abe039730e86f20e3cb214b6e104c3.png',
    id: '1630',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d63e691c8f18d1d2a39c510d28f9135f85de542d335e07f213582e6d833c0da5.png',
    id: '1631',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea76bef107096a483cf7b47c35a8da8ca64f8f0ac3b67d29ef73d0024d073fd9.png',
    id: '1632',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63feb807de2a96e6d09a01517002598670ad1bb8492677d45f6eaa7e0bc6af95.png',
    id: '1633',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/055f68779ca37858a11634b5fa1c71c973c98edbba08e9e04c4f9df57dcaf56b.png',
    id: '1634',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/535972abff1be0a8d22b23a5ea0dd273b6776809e3419706989333661f1aa0ba.png',
    id: '1635',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/283a40ee453f986165e0c5bdaa5dbb1877bdcd94e4026e04ff5ca1fedef9b2f7.png',
    id: '1636',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df365a9559bdc130b62648b8cf1aa0ec9f99a6839820671f68d81f2c47c3d69d.png',
    id: '1637',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70a891fb0db1a0c2196214f77bb64fad125a617a4a296bb53881a1660562a801.png',
    id: '1638',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f34d9a10e00b8b935e03b083e256c0f84c3d8a7a131405213b6d73d6c3901b6.png',
    id: '1639',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5dafec8ff5dad4cb3cf6b09baac57ad8c40aa476aa3c0b0c900918f0858986ea.png',
    id: '1640',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a27c1fab408dcb8f7eaaa84c74c5a5951b0443a36858059e684aef943d9912c7.png',
    id: '1641',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5194a8bce4fb17ee58ff4803c6c4d68da13e47c543ca482fbd7fdda3377e2559.png',
    id: '1642',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98ab7cbd1a7152df624b388cb3f9a961be187412fc8a80479cc0ef50cb593d29.png',
    id: '1643',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2b8c6d69cd772a496b2d1ae67e1ad4f3cbeb1e501c3a78361b91742798f6bb8.png',
    id: '1644',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/838872a3af48ef93cab57931bb694121c8807ae2d96a1bd408b546e81fbf1fa7.png',
    id: '1645',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48eef959a5d8560a1b2399bd14844ce208d0e90e1f637013bb567d331ed224d4.png',
    id: '1646',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00e795851dfb737375567f352b7f184880f9b823923ceb45a12e9cb5ed0aaf0b.png',
    id: '1647',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b296aadb3e5f8ce6d6c5cdc70244f7588cd35999c9daec76c21e6a35a58e200.png',
    id: '1648',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9b26dba01f7708db1929cd8f49bd07ed1e903242cc3abd617b223a5a88788a0.png',
    id: '1649',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Betrayer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18be954965edce6b92befdb61435c388e43f1bc096494b02059d3a2061ade00a.png',
    id: '1650',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6c632879554ad17172a229a92e8bad7ce13094144736f0270ec89d515d56651.png',
    id: '1651',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5de9bd472e1c2c60a73f5868d6eaccde43c0440bd76ca15d98a9b12122eceda6.png',
    id: '1652',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4a1046e9e6a5e8a9095f481d9e90243b759cc9583be52f7bae0e6e6d5280669.png',
    id: '1653',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4ede8e8d68cc44cb67d3d7e8c08d0c16d491cca08f5bc4cfd4f5affda91801d.png',
    id: '1654',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f55102dc529184b21a267ac3dd5d2eb192519201297a42001d217745afc7eb7.png',
    id: '1655',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8d96dfcf5f9e66b28c40b2214bdf92acbb25523c5293dd9b90d6ce5e9356b8c.png',
    id: '1656',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b440f53ae0dec3132f062b6699382df3959909eedbb6f12c8d2662373e6e30c4.png',
    id: '1657',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24637074614d0ebe48e1613b7bbf4a3f6ef79cbda6167559d8f82e4204f89e37.png',
    id: '1658',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70d9bb896a7e8111c3ffede42a09340f786e249fc70aa8c85b49dca9993518e5.png',
    id: '1659',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e03cdb45accd6a5e09cdf56c9d688724f4313f14971653f6a579fa2470bb4b9c.png',
    id: '1660',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c40a712c0e89b1459aa2d460e8644a1df8d08731cc022eed051ec400f59c6e23.png',
    id: '1661',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13ac4819e12373f5a63e992464e9a90452a59632bc9f7251144081e31a8dfa56.png',
    id: '1662',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72a646ac57329e1f2c41972ddedfe6f833fef097ca7214d42ccff197d2939dae.png',
    id: '1663',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9f20b5a2624b6ed2612d262ad0170d549d139d43ca1be8d42020b493b3d1921.png',
    id: '1664',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f12db07471039483a42a3f0e37c72a59c25c5f30da99fa5ecf144465a26be269.png',
    id: '1665',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec14a40099de98231a42e7e2fcf10d0f6f76afb554a048f36f2a1dea23ce9867.png',
    id: '1666',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75ad7ae827f2a046c9b0b9c633977407bab8a39617c964a962a56203be0f2ca7.png',
    id: '1667',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51e01ca412958b72a277fd0e9e5bdc0be40061eb105ca90a31c8489c5b14d512.png',
    id: '1668',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b717f02fe72e0c1987f5c16f42134ff85742cb43913488abc610f9b2a308320.png',
    id: '1669',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6fd4dffe18589918de8f9066253edc08993fad022de04c2ef9232759cdaaa82e.png',
    id: '1670',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/384223da6faa02b5ad9f3fcf093e04001b93ee6e64a49d3de6fa5168a23bb207.png',
    id: '1671',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a07084bced20253e5dedd1e43fc198f590654a8f6a2008a1f807cc1d06899a6e.png',
    id: '1672',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb4b14bc44913ab994c36bd6d7ab79c4e95d65092150ac63715c659c7667ac33.png',
    id: '1673',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfccb13aedbf8c58c36fb2fddd6d8e5867707bf79ea84d1c903918dcf37567eb.png',
    id: '1674',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eda6d5de9547ce2c456903e4534b9cae8d59f94ded1be42ddd9877a02e549265.png',
    id: '1675',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7beba6364d9d6d2c0f1f1b8a669c97e31a8608f9245d6bf963fb1b04b642bbfb.png',
    id: '1676',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/464d4370968cbaea827ca974cc3d4c7fe642af529fa601d5b8d2c409806cedf7.png',
    id: '1677',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d31ef3b4c89b69eb5f5fc8dd2dded0c1e8caf8a26b2ce53eaef948ac999744ec.png',
    id: '1678',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skully',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd65334b753d017c096c89c568e928be21f5e1366b2e48345d1f46b24bf92d21.png',
    id: '1679',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85d06e3ffb7af9db12fe68edc31d97e8cabd340966ee17ac27645e3f397ceb97.png',
    id: '1680',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61a1d06849787701f9d553d878a694dc4a79c2bfae3e3cff0739c7e8708a4a41.png',
    id: '1681',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47fb2360d1d3fca65124b7846e432f04a6d59d9ece8c2fa208de6d59ad3a9a8b.png',
    id: '1682',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e9d2951bddbfd5a3793f79850d39227b2d541048fe4511083349c58e20d965a.png',
    id: '1683',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a61a59a5cefa35834ec843d4e2778002bd42b39db91f432f3c97f532a680f02.png',
    id: '1684',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f17cd76f2004da3ec68889da092851314863a0b0dfc592718c1f970605b7802.png',
    id: '1685',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'The Claw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f836830ea963ae08f6de1dac7a4c2ff42e71951cbf0ee27ac1c8d0d558cdd206.png',
    id: '1686',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5271e5b3f29dbf07860eef0bf8ce57adda47754fa715779f8390350a1ccf5ef.png',
    id: '1687',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/46cf9cd94da8098bce9e181e3a050dcc4422d4a716f82e4fe34f880771bf85c8.png',
    id: '1688',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e5f40a46cd10863eadf4bfbe69025827891e799bff8bff562b83b22eeecc312.png',
    id: '1689',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d76e1baa91b154cb462534363aadd5670ccf4feee48cdac71b62484ac1a7885.png',
    id: '1690',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32dd32ea6f65c4ea0c2d43aec7827aa16b5707160d940220aa8f25dbfa56b174.png',
    id: '1691',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d30cd80f96bd8758684fd33a2e01ce9bc0985f7c5bbbcc59b46371a0e4d94704.png',
    id: '1692',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17ac77089b63243fc47726b77f01fc3bda9d4a8ba003ad44e257a7e5041d8e3c.png',
    id: '1693',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da01c2a88d8db1d75f093829d3016f1ee3785f76ac2da41a404f849c8f5ab4d0.png',
    id: '1694',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f080439c1e92a01678f2bae2019501798e1e54398c2e5bcf48a24c5f5a850d92.png',
    id: '1695',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b273a65c086f008061dcf97d750888e6e033232863dba713407e3377514962a.png',
    id: '1696',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77374a5b6048a8613afc5ba1754f2efe1acd123865c5125237ceccffcbda5cfd.png',
    id: '1697',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9088d9fb5bb48e483350c2810cd7f78898c9c0275d8b6b10e3d28a041f9c18a5.png',
    id: '1698',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a3155bfd6d68a49ede45d3b46b52af60e0201fa852cfded605df3a1299776da.png',
    id: '1699',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf6d0d89937498118da52de4574e9a4bb06ea2af89b3bf4b3e2aa5c627c3ba87.png',
    id: '1700',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b930da8a0c056513141edf3ad96d7419f86a8caf1a14d30e7b13f57bbc089d38.png',
    id: '1701',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7da451bc2c73ba41a9887f9718e53b25e673cdf434ff726d35be87e29c0d7dfb.png',
    id: '1702',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ba5cf6bfd6cb84851034349fda684f73ba6ed71e04c53ae5f2050ad86361675.png',
    id: '1703',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7527c4733011e1a1a588be2658594b111e9979f84ee22b2b574dd2a30ef7524.png',
    id: '1704',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8cdfe4b7b57ee5a1670df25794c9b1aba814c122b5ed6f2025dc4303d6626456.png',
    id: '1705',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de9ac4eca246f2e01ec87f73491391c49902edc6b81c3aa616db372c24b37d2f.png',
    id: '1706',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9740efc96093e3c482aa2de99974ae7d66ea4135107c9bdadbb9ec3565fd658d.png',
    id: '1707',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4aaca4f97a4233fd0fbd50efde174d3b7814fc63f0540a180e465886b7c86166.png',
    id: '1708',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c378316e19e56a404542d6acdac979cf80904a36901047dcd3bf0f6bdf1a29c.png',
    id: '1709',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d0e7605cbe1063d660e79afde15761336544cedeb80a5698c09ae6ee19827f5.png',
    id: '1710',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7406f67f607653a4a90f9258fa1a2b8665efffef2f62d5f4153c0a30fe605a21.png',
    id: '1711',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/480fa1dcba239102101932ebe264e500c6df40402a2f87642e326aea4b2fddb4.png',
    id: '1712',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Adventurer Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bac397e2b962773494f612b0de3e7c10f6ec9b259ca668f7d51a1eae9a3d20e0.png',
    id: '1713',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Bubble Blower',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b263537b89e6fde05a441ba6ad66f9e0a9510ea861d22d62a9e5a1763f1d69ec.png',
    id: '1714',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8ed35ae761356009af84f40d96f41c142bc39969f9898047a34b3baca039e8c.png',
    id: '1715',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3986704343efaaf666debd19837a6a3ebed30e96178d64e418af6f33b7eb41b0.png',
    id: '1716',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d0d2657902d719a68e7aacad552954beb4c63939f1bc0eab6c61301bc740b2f.png',
    id: '1717',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12bf22adc4092591a0b25932489903f16d074f5fde270e39b4340b5d00ea5782.png',
    id: '1718',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c0ac093b2cb45f8ea975c5189407f1299ed860c09b0e0b51aa4f402f46b9ebd.png',
    id: '1719',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8fd61eb44828811759398241849426f2055ca5be4e7bcd5d3d98667551409f9a.png',
    id: '1720',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9eaad800305b9d69502277ec8e0a469dee8244242d4732415034295207851c01.png',
    id: '1721',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f81db502390e53cb10c9b8a86043295005ec6d6c76f3f6354ecfc12765cce80a.png',
    id: '1722',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31cc63d4df5363f5a8dbf179d9a78b71b27cfbb19f5c7148d9f8cee0879c775d.png',
    id: '1723',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6828c3f73b45accbae606bb7b4168573e2753d995abe6d183f21174af00f62a8.png',
    id: '1724',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ad670d87b79113e98d92256939bf911d01181b8674772d6f2be897394f846c3.png',
    id: '1725',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf25694c285a134153480476e382a844fb7ad5289a55024e6016d5050978ffb9.png',
    id: '1726',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a61997073fc5095f71393bb36859aaf761f9d35c056de97b035c39155e5893ec.png',
    id: '1727',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/056b3d8e24ae51fecf90d2ae2d8ed2dd8375373f84cbd096cffdc732730c171d.png',
    id: '1728',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b2db261fabfb5efba9547e622a3bf76895c3c40feddc30ac558bd03e263f008.png',
    id: '1729',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/815cb471b42b0b2fd2ec159bc57597e76ff3a4541d7624b457ae3e6ceda6f75d.png',
    id: '1730',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3312895c54c765c0c84f52fa8de0d51f8de828e4474c318f21ca95710a6cbe36.png',
    id: '1731',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af28a0c9919092337e636a7596e3a58cc3d2c55db3275cc81ac60cfdf083a37d.png',
    id: '1732',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e83aa33826c99f8f49be3a3cf87bb434123c6c814a758a6edc467605c61a2e88.png',
    id: '1733',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e13c9d525b5bb9c19e14c75e02e4a2b61b7e720516971c89466696ce1a0b5d48.png',
    id: '1734',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/884107d9ae0dbc1abd37e0b932663f958ed939c7d40d98fd978624ba0e85b29a.png',
    id: '1735',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd42e645278665e2042255901f0234b7c2398cd26c3cfb4cf0546521ba14a40d.png',
    id: '1736',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2c6f3c50dde8d535247ca3107e41c40dcc462f73f3c213685b3a09a51567d96.png',
    id: '1737',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76aad91162165b802d274e7a9591a1f7452c967f9f1eb8671df4610861b50fad.png',
    id: '1738',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/297b3f1384d7668f4209bc29ba5497e403ed5510f5bd542ff83e374fa700d228.png',
    id: '1739',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acf5f9d499fa37695be22b176ce37e71a5bf2747d295557eb1bb293b3df0351c.png',
    id: '1740',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/795e2f286547df5bdb7fc60e64dd4280fb4f8314b0cd4ff62ba9f569c1c33ad6.png',
    id: '1741',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3199728e666138c068a46bbd282a73815fb549d360654929086edc2a46d13158.png',
    id: '1742',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Levitating Lava',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90de4ea8b59382dd8e5a4d11b18b576dbfbe8d327c17c3a73c8994e60f85a177.png',
    id: '1743',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc74bd5a0c9881266510a060408bb69b8bf2bee43dd3ec732eedf3162545e4b1.png',
    id: '1744',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c5b873e960c43c4c29c4a01d3db1a5ceec7af951f976d19cee2a96be58dd5d0.png',
    id: '1745',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a4eb863fe3d0cfbd560d4639506880c4fc41937342ef165902d94ca08efa956.png',
    id: '1746',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de11aa9f8c323e9a78e467bf7502c97ec338fb319f0943655072d83c708494f6.png',
    id: '1747',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae22aa02b78b070ea459e53250444e212f2af820cedb13e8aba5b0dad6bae6e8.png',
    id: '1748',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/163ec0da573f31aba5fd070e3932458c25b22dd7d24d43cae22fbadba0cd422e.png',
    id: '1749',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64262135cf1a06ea4b549902bf547a9297af8b382993c8575f109b8c1ba19c7c.png',
    id: '1750',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/980239c49255ec2eadd2aa9351fd7e116af9263bb6cf7f85777cb161369d9d02.png',
    id: '1751',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21c70f3554a141bfa093b1c4d81bb78dae10554675a0b0a5c76e544ecd2f3f95.png',
    id: '1752',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12da6fbf71d4606d32b4f05b438f615eb5c76ec7d47ecb68d517dfa573993bcf.png',
    id: '1753',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82a7124a70c79ff1edd4dccea4a02ead2b21d45c569a48c9a674407cabb6712d.png',
    id: '1754',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/090c4c258e27c479d456caf583e1cd0f5a938f6c70867ee6f575f4e4f3b39f1a.png',
    id: '1755',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/191401cdd3ddd0f8e70f7cd33b4458a48cee9bea4cd652b6f83fce95ebfd7ac0.png',
    id: '1756',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'Bone Hook',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0afb63dcedd469176434b6ea704492317fc9e51fd32b2a82b52f71b9c69065be.png',
    id: '1757',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53316f33e890fbdc0f8e88e55f789818c865b459860463f4ab6fa99fcb832d70.png',
    id: '1758',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55f670a78deff1a6f5c3464c4e58b867a87db46123585e2eaf906f6ed645aede.png',
    id: '1759',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ff986c11d9e7992d44c1412a72d25f0b5619d5ac3bcd3e65fe3e719e85a6938.png',
    id: '1760',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c31b591c6d78623a9aac2227e4ac3b461c14d346701446911260b2805ee27016.png',
    id: '1761',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ce17de126f78ea416bfcc5098b1a9d2f2b7fed8dbc27b0efdfd7b7a60f6cb12.png',
    id: '1762',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc47eee848fd834128805124d10ef5ab1ba906e9869b26c386fa9f52bcebc6ce.png',
    id: '1763',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf7144d20752f14a827bf3f8f483d4438e5d2fb6f5b4b9a1b0ee3edd49da587f.png',
    id: '1764',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58670b8558a708e722f8284131f0d5f44d7872438bf387459dec28655ac23580.png',
    id: '1765',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3264bc4ca49294f4d84b9570223b2ff96a7d76159d93d83f4b0ac70c3384a987.png',
    id: '1766',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01b70abed300981238cb6486cb27607ce94f020b3e7fb4bfe61355456ef44ecd.png',
    id: '1767',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6f65df99fbc29c6cdca3c4f42deb4b8136682bb0b1a8606d965c41e47b48f88.png',
    id: '1768',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2913bb848396ed0174a520be4e72217cf59d75924eff2d48340ecba7f27e7bd.png',
    id: '1769',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ca5725dc54ea9b951b36de04f7659d38cf99b8bb6b8af13cd5428e7372c7fd3.png',
    id: '1770',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f96b9ecc3f8fb85c47dfc6c807f083ebb2af5768303a8bbf70c08053a6a4faf9.png',
    id: '1771',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72596bd7589d0fb68bfe86128b21bae4423fe3abf370c0916d4dc9070cec6bea.png',
    id: '1772',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8edf2504529bbf24c469c6a1d09490fc57aaac06cc0cc9876bc58a64c0c59706.png',
    id: '1773',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d8dc9c48d12f5213443e54769cdbcd77dd69c6d956e0a3dbbea3e60e898a5af.png',
    id: '1774',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03249edf7fc20ce78c0d79540cd3da1f12219e0ead81b144b5049152bea02085.png',
    id: '1775',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1dfc0eeca6448ec46ccedcf854fe8a9989e09665d7d50a28b10e0afeb3ec6ea.png',
    id: '1776',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f6bb384e4afd2ef437896cd37afeb02736727ae0b7af4417e8d31398ed42295.png',
    id: '1777',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14a346cc983a81872908c6e3e1a6d35ebddeb55d04b6d6fd5163a4c3de96f463.png',
    id: '1778',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9416bdeb08c44b8a702b4bddbe8f1a584ccd0e86e04869b86e6173ec0f088a6.png',
    id: '1779',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/861fb3623863767d8018018593c2f6053923565e7a9296d919398487710307b3.png',
    id: '1780',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfb0a32717d7898986341057fccaa3e32e46411411c44fabe15d1c6677a2b2ab.png',
    id: '1781',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/436d1999e1b59f0a3a513932debd0d6fe8b8c3446f3858b5392e8b163cf700fc.png',
    id: '1782',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92ce36f6bfad3b8c59d3a128b728315a6a4f51eff7629b220c62f558fcac0519.png',
    id: '1783',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f70da8f7ea9a2fb3fead6a4dfbdbbff565a8566c59b80d5e5ea7252aca362da3.png',
    id: '1784',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f44fe24c851c0f9f33b04eeb645151ef1539f369c4e5354009a860a0b4d48c19.png',
    id: '1785',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b331a5225e94a2435b0efd58840867c84cf37d13e463ce192289167bf04ede55.png',
    id: '1786',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73badf31d8ca0035a250f76081c309993e6eda2897b87232f90d0bbe6b169b7c.png',
    id: '1787',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8de23016ff20af3275121f1f4dee079ec9b24d5de750139c53b105c6f872ead0.png',
    id: '1788',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/893a41a753d51f4415dc0570a09c4bd8115adc4e8e0a515ec91ddd5f72cac483.png',
    id: '1789',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec9cc33e74da6520c27aaf10c8f8c56c3c666cbd7029b1ad1003b14b27e65f8d.png',
    id: '1790',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e732f4cd54cfb78bdeb1f5ff4d75d1499dd5e2be9486c5ef8bf24c6b038e56a.png',
    id: '1791',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07380145eb89b1ab175a4af60469f1f7e0ec928984880d0f8ab4cf0f7a60a057.png',
    id: '1792',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a259efeabaf91fd83a60bfc45fc99be3b0a6c3a1ead408f0cb6c58e6a6ceab7.png',
    id: '1793',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27471410aac05760064b196885d3392db51da78045e70742f0d2b7c363218114.png',
    id: '1794',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2672da08078a4802ae1939fd3ded56d3a98ab77b9d460f1bf563797235dcfb7.png',
    id: '1795',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32b0d1c63a55ab05f6a616371b1b30764aefb7c927f0900f586b92892239c436.png',
    id: '1796',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7fcbfe6104221357090fdf4f1d68a2636dbf1eebbbcd1d9f095e8f4317fa7333.png',
    id: '1797',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e5fea75f3c11e2e49471ca5d1d7f73b9e33bbf8fbf4b555a8951780d5c7c564.png',
    id: '1798',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e33d89bd43be245d0b85e1a78a5c09b6a54fccdbf9f83a318dc3f0bb5566c925.png',
    id: '1799',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbfdce97bee4d8a499e6aadf72babf700270236902ad43579713d14b3e0c2df7.png',
    id: '1800',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2c3a9d41fdf6fb119fd5695f2089d319f2b122490d7f3c655d6b6176887db0e.png',
    id: '1801',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1802297cfea4d3f14ca5994b87f8fdb4f9e120d973d5adb521aa2367d68d327f.png',
    id: '1802',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/399e4773655dce82e1e0aeac4b3d337c46cdb2d250909d7d9b237e658373a45a.png',
    id: '1803',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ca5febadfd617a13a646a6f103dba11af819067afd33523bca17585f2f14da7.png',
    id: '1804',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1b5d3d991d5ea7b007ac60f6086574e1f916c39ffaef3c83c9142857128aabb.png',
    id: '1805',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f35c5986d72702212bacd5baf0ed0fce8b945f20de5aa2ccb9546725cc4135a5.png',
    id: '1806',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71eb8d0cd119ca86ff85a8b02c664cf3debc37c6b39f4a42cad6d4dde866fcbc.png',
    id: '1807',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/392fedd71309131a4dbccd9652615ef18fef4d903691ed38adcb9d0f10e7af53.png',
    id: '1808',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/945541488f6f19752267455780f8c3dd632af9a33109e13216be98d046fc3e21.png',
    id: '1809',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/633ebd719a84934e1a138e80fdd27f6559b000ed8d0828f92517d0a53cb4190a.png',
    id: '1810',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Weapon',
        value: 'Bubble Blower',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afb4cc4bce65dcf6ea189c9fb329fac1b1d3a56c5caa2a0a3aa2ebf8690439b2.png',
    id: '1811',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f92b32617f6137ea6110ca03e741e9ab43f0770a923e7fd90f1611207ff786a.png',
    id: '1812',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79e08b77b039f4594f04c6d84cc79e381265b9da3d75ade57b7bfc9fd2a6f63e.png',
    id: '1813',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90d4b93605ddd1aa85d7369318c01de8718f11ba9ee514134bd776d1f87912c5.png',
    id: '1814',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3c9b15c83bd864e0eb20f7910188b7318963becf69403a2124098564bca8ff4.png',
    id: '1815',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca12ed70c6d41e3f83f435c4cf216aa5a51b341b789c89ba6ce22dc91ec3ae06.png',
    id: '1816',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9eae9b5254fd69e859678e82bf1def05788cced5f29a4006e4ee1d2c5f41ed7.png',
    id: '1817',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/deed262b2dfbd86e1401f6da3a7259b5856757bbccfbc294facac987b5df57e2.png',
    id: '1818',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e96bbf4a47969aad44f9447d70d3494c3c6ffe339290eb104ccbc8d81e0d0f2.png',
    id: '1819',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6fcc181c48dd4a879792c651899af8f06aa5e387fa3aaee952af219ae010c802.png',
    id: '1820',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1a8c2314eb388dac477f043c7d75d125855aa2b571307a60c6961ca8ab4a34a.png',
    id: '1821',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ead8b8eb8c41fcd67b999fa741cf8ebb5d4f2a7205c6c551273afaceee3668ac.png',
    id: '1822',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa5061cbbb61ab6cc01e4359be270948f0e14b766fa3a3fbbab092f77c888cab.png',
    id: '1823',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4463d13528f53b6d816067a3b4e193c65f08fb29de8839c7af34d575bc2d524c.png',
    id: '1824',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12d6f8ab4f01e64025c6d5163123c9268cfabadbc7f32929ba6b2503c7e7950a.png',
    id: '1825',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a2d11b7a966387938d4fba33c4fdd9745452f0572346138e82d36953bd947a0.png',
    id: '1826',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb139ff434f028fbf31b1519206ed6b392e9c795f982b77f61bfc6a1c790f53c.png',
    id: '1827',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36b89c7f71fb5814225de21596d12fd3a885dc117aca9414ac4f7689c23d040f.png',
    id: '1828',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff72f0b1cc86bbea3a4e77e67254518b187d98c0a79ef03c56612c50aa09ce89.png',
    id: '1829',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2d678b18968d6475c285af34f69a79eccf552ef2840454075b3b0adbf631781.png',
    id: '1830',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25fb462f9b6818fb18bad6ea8e3cf34c1cefd12374ec3477adabea197923dc5e.png',
    id: '1831',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fefcdea9b1e5f42a05ab944c77ff555e6d52084abe667121c24ab9f4b0815446.png',
    id: '1832',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e2380d8daeebf4be4d311866698019ddc4e052e563ba03938fc91d3917554f1.png',
    id: '1833',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94cb95db8ba0c2b70c0fabbe6c7a1a583788facb2fe5a7fe272f5cb98a4b1ead.png',
    id: '1834',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/821a658c1fe3620028d3580bb2328b9d2f10470aa5e15c0e86d9949ebe6af52f.png',
    id: '1835',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5d3e9f79e21974d7d6baf0a09497f0bb1c57738e8bdd084ea5b1b241837f678.png',
    id: '1836',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b63d91f91cb2f45fe0c49f6112471bbb8a79a2aa9aad7173f500cd56378e0149.png',
    id: '1837',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7dce1c456bc2cc3397340780f9e4ae11f72137dc17ebc6f54b94651e845e15b2.png',
    id: '1838',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62945205e513b9fe08b060754c11bb071fe452394c0b013e5f26668bd5579f29.png',
    id: '1839',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e0321e2f9c26e614fb9df5e32bc090334111aca2eaad02807c77c428e1ca4be.png',
    id: '1840',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fddf03bca85e2335c0ec4c5b85073628ff65aa334a7de2b76f367c7f4962dcb7.png',
    id: '1841',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9df6d17602d958b4922c13ccceece967e5d607f5431ee73922a2292031ea5a9a.png',
    id: '1842',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58fee20afc80041aae3d10a6fa449cd1b75e6e378fbd136c1b22407190f8c5ee.png',
    id: '1843',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/efe9c267794afa870dd3e71ef65b8e6d9ac76956f11db68fbe6412da756cd79f.png',
    id: '1844',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3713909f16358f74dbc9b49450ae6a2ff9601c59e5cb17aaae930e2ef716897b.png',
    id: '1845',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f730f6a5ccd3467ae393fdedf5397e57bd551770d1542dd0eccd060e6e64566.png',
    id: '1846',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b1cdc8126dc929b6424a0d7d61d686a245310a22ed1feb69afa858894707fab.png',
    id: '1847',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/147a53d379a1f2a7ee17c8e5555e8c01e33a83c56d53573a7e229cd35b764a95.png',
    id: '1848',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef361e93ac8be115fe07fc2bb74ec0f0c95d1a0f5c949465aa72fe050fce1761.png',
    id: '1849',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae970db17ab5e31959d2b2f62539d594044b1c7ecdc091ddffb0ed7ad75cfd24.png',
    id: '1850',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5de8eca093426a3872f67d034b984760ddb6723d991c7148540639589f10457.png',
    id: '1851',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e70057845920cd06d96c2cae6cafb375ba53672f705f8101b4985991549c1da.png',
    id: '1852',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/469c3b056cfbb45ee0944a723e8760ee0c875835373a03f31bdd9ab1b6a980f9.png',
    id: '1853',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b7b6279c699ffd6a09a0c96b6b8fe13e30e038d8d2cb03b7a84015a3186295d.png',
    id: '1854',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/215bff0bc5e5675dcb66a7cb90e92ec2bcef95445c7abc7fa923444d9c4ee94c.png',
    id: '1855',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ffdbced32a1d0508b41e1613ab8dcc2a7a59875aa7332c32651ca96210f89c2.png',
    id: '1856',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec9254522631b7ec67e77f4b4b2e37e228635b9a2c8db1da325c71440e0ad924.png',
    id: '1857',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1e96b10dfa95f9b54722cb0036045bfdb04f2da2b014e0ee8e3b7ff51d726d6.png',
    id: '1858',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5a334e32f1cfdee043f4ce0b018fd84f3a0bcf83447f5457a123eead20c50dc.png',
    id: '1859',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/756407412296d229da3672ce865e1e7a0c3bb3072d77875cdcc7bbeb3672a933.png',
    id: '1860',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3871a5236d8a247f68fc68b1e3339ac90a46ce5e1ec9a73bb8637481bcf2064d.png',
    id: '1861',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e18f2a3d4a5407e2398c896f394902ece948abc587a42b96f4752f6656322ba.png',
    id: '1862',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10ad296beb900cdd9d15dcb71fbfdf3d3d5e863e0da88341d483ec8d3484c731.png',
    id: '1863',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e30c1c8bff471dc361ab85628960397ef9ca26435c60f6f8f87260abd0d89d0.png',
    id: '1864',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1dc7e8a0fd4369c5b292ada76ec2caf3e194d6336d57b6ba52b809ed75265504.png',
    id: '1865',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3acbb1c56333a153000454679e42b1f96fe32aed535215e976a52702d4a610ad.png',
    id: '1866',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2c889e4e017a3936a4bbbdfc46964541af9822abb5fe0047be4998f9c98891d.png',
    id: '1867',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a11cefe8dedfa9a60d12c048018e8e3a247c7a2678f53a9d59db6baa7e0981aa.png',
    id: '1868',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09e47198fef6d3fac60e4004e5ef1f5e6d05e904246ff4daef21fd971e47df29.png',
    id: '1869',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5cb943041ef39e5d9604a49ec27b8629af2d2173922f0ec43550d026f04acc75.png',
    id: '1870',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6ab7035ba3746ffc6065823e653d6f3f793a6c92fceb450d9ea837b378ed169.png',
    id: '1871',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66407691c81cbab0704c0c10b4f8d0b3a45428f3b6da4fb04de6848ef79f63dc.png',
    id: '1872',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ced306b69c3b8ce6c9a2bacbc0572ddef1e49b745230a8c93f7dc254f331db4.png',
    id: '1873',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06b41ccba987dbca59cf394808fea58a4c9abe1f868abdb2ff5f8da66f5892b7.png',
    id: '1874',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/717a84df8643c0eb3c61de6233cd3a8ad5fe2e326d01c3245db584a04f3db29c.png',
    id: '1875',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd971a46a8cba56186e37f6e374da9c1bbda53faae005ea85e855b7048f166d6.png',
    id: '1876',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90319de9c14eab934f548077b2b5fc0f5ad5b36c7a1b13c198ec559515a168b3.png',
    id: '1877',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e707ebc7b6f40ddab7e4412e46e326250ef905022aaa846a2a354aa3726dd650.png',
    id: '1878',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6ff24cc7344d650a29dd8e7c35165ed1ade42fecaae65e62530cd9d5e5076af.png',
    id: '1879',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63d63079fbc40c667b82ee56808b39d15f83fb01bb688c129019bd87c85ec137.png',
    id: '1880',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a56bcfb0aa6da8a2484016632b2032d14b2f5abbed64e893a89ed2fa90b1cacf.png',
    id: '1881',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ec84dd443db69079f4a2a551cb520ae624c7a8448c3b399afcc804ce657b9b3.png',
    id: '1882',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af6942b54a4cbe8138b106126ee64e5eae69999365c1649a0c8d0678e33ad7f6.png',
    id: '1883',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dacac01ea66a7ab414b37d208b23eb2b643386abb490071f8d9c401bf2f78c0a.png',
    id: '1884',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/180d0d12d312cf379fa9d509f6a44ccd22cfa44a7df9740b052cb5fc091762be.png',
    id: '1885',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3977b28d51dde5d7ed10c90f0687d023cfb642bd9ff71d3e1efc27421ced5b2d.png',
    id: '1886',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca99330d1a21e4d7f7a85a7429a8daa4ec0efb92734ffc18c9bd84a5b082b932.png',
    id: '1887',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c5cd2f1bd656458d6c85b0b78962572de0f22eeeb6868e211f4b87f6549f601.png',
    id: '1888',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42aff08da7d8ba13d6d5b39062abf437741e5d7cdeb4cde93164d68b7d5a0409.png',
    id: '1889',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c320612745aa9fbc73cb8e32cbb15c906bf4de2fd60d935691b4a020a5165594.png',
    id: '1890',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/061659b5c0c76d6026e6d41dc407cbc629e1eacd3f5dbf2e1a853a22327afcb7.png',
    id: '1891',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4ade265f66b555fac60c14d5abbfd028377153dae5e67c04f263142631507c4.png',
    id: '1892',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e3498bcc3274176d04ff4eb71b1de78ff32d7bcce8d5f80413267b1087d6f13.png',
    id: '1893',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95402c4aefd14ce89756d17f1d9d3ad1d1fe0ec0bdf1708d5629b6688aec2cc8.png',
    id: '1894',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62872e49a96bfce2bafd0112a9240fe1a6cda994de0fdfea41bc9c33c2c50123.png',
    id: '1895',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63cd908f1c71cc37e6226735520b4e96bf69e1d19fe06fb4dec01cf24d9847d4.png',
    id: '1896',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0842d4a7c3d3891e60fa62cdbf618323d5cff5cc490111f4421f84a451cc8cd8.png',
    id: '1897',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a30b003e9b6775c66338abbc275d5ffcd175a7e21f4e9ea2a5f1f58ef87a4ce4.png',
    id: '1898',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ca3500b554a2bd48bdc7dfadca30d27db7eca2814827b1d558bd0298283535e.png',
    id: '1899',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc3b04dce7bbd2b3fc2b4cb1602f90fa212ba6fd22fc1e27178fc74bcbada62e.png',
    id: '1900',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e887b180efb459dd5c056df25aefcdd97b64f80cda765126ff063ffdbda83af.png',
    id: '1901',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4cfec3fd41628e3d6710887e9c6be93e2f37bff7f0be52682253e5ef1277774.png',
    id: '1902',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba7dff4120b0d1c5e93e1e0d05e9781189128eb84de76cabcaa82c149e5dadb2.png',
    id: '1903',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7493c6806205c841d7fb6fe8b364e58945bcfe133f285cba9b2e342390bee2c.png',
    id: '1904',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6be89731dd000a87321bbba32f36abb2e9ab1807731208af46e3c670770beaf.png',
    id: '1905',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0fc24e2c906358811740d682eb1841e8e770cf3ab37f8f1fdcadbcd0b1fb53f.png',
    id: '1906',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40daaecb4be3a4e3485930bef7be7a5870e5caa15663f080537989d9a3a5c4e2.png',
    id: '1907',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae6a29d2e9d28b25aa3f273d11f28a850020ed7908d2cf4333f2cbe9c23c05a7.png',
    id: '1908',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88a0e64d87047d18152e2efbbc552996f4cd5d4c213127bbc4f30c34f2dcee71.png',
    id: '1909',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88142b529372fc68a623be3e644966d34a38e06d7e60a415baf254d2c4a9540e.png',
    id: '1910',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32bfd5026b7f428e8f0c983e255e87fb22ee4d8868f9f5319a673a6d9cb645c6.png',
    id: '1911',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b501fb49014b3ec0185c8cfe4b45b3c4a4eb3aa8cfec8f5991e94f52f4eecc1.png',
    id: '1912',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f5c4d16ca3a994b70862ec1bf3d4e9cd7ea9a339983978df2e74f8ecd2c479b.png',
    id: '1913',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e674cd543e52e73e4cdbeafb5a40556e81ccb601481cd9c7eef4ff6530f9928.png',
    id: '1914',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae0d5bb8571fe134ebb8fc6cb9a7aebac35a9fd9d58fcbdb5a7d0f9556032f77.png',
    id: '1915',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cefa21274a89a5ef2e23ff5046147e1d70bdc8bd170f0f1917886624854e1d3e.png',
    id: '1916',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d274e7fb1ae2ad202ea342a56f97525f8802e23adce066225559e5a23d96d1dc.png',
    id: '1917',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c00684b42cc456741c2c50a3122f927b1fde39168643a7e1e3b9d971ffe161dd.png',
    id: '1918',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20031e05aa584ef58361ea36de13f419bb9e6ede91060227520aab877a413842.png',
    id: '1919',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e504b524d7826d56290b75d3b5f989a726ed74385707681cbcded5dbba83de7.png',
    id: '1920',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43f12ce1dea03a0d729e5f4214c191c25b012f33edca71744cb19fa02b9f6beb.png',
    id: '1921',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea5b8aed6bf4f1ce61883f8d54e3c2d6c33aefcc8999c9bfcd56b5624e5c2f8b.png',
    id: '1922',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16bbb42e9fb8a9f2759aeeefe7957dfe9ed047ecf672e06d20b6095f9de7e26d.png',
    id: '1923',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/236c049c81d903ff67c0cdb85286feb9851ef3f0cfd1e960ac430bc2e5b177a0.png',
    id: '1924',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7949be8c0b3eb89ec1cce025d4157681432b669f4604fcbf73d2a2f68c100d21.png',
    id: '1925',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/995f0738c7f32585f7f83a2a4c7c934c6da49606d28b6804b6551956d4d60811.png',
    id: '1926',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99122593374a981cadf029adcdd4953f57767a2d736e55b4d2aff98a1a4da962.png',
    id: '1927',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fde8d7da42d88c1dc885afbdebd52198cbf094f150a5a936f9d2b93272edaef9.png',
    id: '1928',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/438196f1f02220f9840b34fd24f4aa197383fa01ef2394fe65c02f76ca3090f7.png',
    id: '1929',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a76a5093ec16072fcd9a4fef20f2644a0768d1b846baeea82168787eb84efdf.png',
    id: '1930',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3a9c0f521254c5b92a4a4ff3fdf823803f0e5f05620fe39888937f6a643fa4f.png',
    id: '1931',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mush Clobberer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88e9e057d88313cd61966fe3c77d608734fd25f125bc0282c7d7754da12ae501.png',
    id: '1932',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9df3e246d2f8b5319b0464177d561960b6bec1c2361e22fd99db15cc234aa0e6.png',
    id: '1933',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9845b933d2339c3a7ab91b277e72fe04656d5f0f19061d267f19ebbfa1e12c7.png',
    id: '1934',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77789fe2eb4ea3cd3b546e43e0b6002e8f9f90ff5cd67d0cfef559aab0fbd11c.png',
    id: '1935',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b91ee75e706757f1e5988f215c7b63910ef0be35c6d6a8f26382228c66a211fd.png',
    id: '1936',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c0fa2522f25f77e7eb547c9add1d28fa56ebdd80c01f2de1a2d66e1032ac029.png',
    id: '1937',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62bdbafbece219f73bd014e2a1ccdb0f6ef778265f2d4681075375901db8cd92.png',
    id: '1938',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/178a6b2bc28934de4559259c81ceb3b34c00abba0858d3d4cb9f398a12425232.png',
    id: '1939',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89bcc9a929dfdc27d0b67c1f7c7ed57b86a718ceb918f3ea2498eaa055d05ee8.png',
    id: '1940',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36fcb25788a7ac48fd43bb16c34c3d803ca53aa81993c898dd93798e49f1ffe1.png',
    id: '1941',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d61d7aafcb5ee9fa76af049a95e9ed854d1ebb045a7fdd3d9f43609c4320fe2.png',
    id: '1942',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f85f236a92018a1f12591a6830a42d85c2ed9bf6f4134048c1069416eba43c0.png',
    id: '1943',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1be481d9f3fc3f7708cf83eb136c8b43ef2672860d6e740f73dbb3e6580854a.png',
    id: '1944',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd044120fd343d9ed0c7b8892562c494dc433177f0dff2737e0697583babc83d.png',
    id: '1945',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac6515d7a5a4ab77ba7d07e4e81375b48507460521d183ea0bd7e9db5a95a27f.png',
    id: '1946',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1472f219fa49ee8cf141d6c37dabbc973397a3e4f8ea69f562df3465a0002f0.png',
    id: '1947',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb10c691425991332ed2be178b063db83e290306585c825b5a5b93e12934ef0e.png',
    id: '1948',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90efbdd5612e374ae39e6b4c04e7530771e0d0d9e54555b1ae20cab8caaa52de.png',
    id: '1949',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e650859ab0e716ba929add7c0720af4f4f2ec8c24190e7fd567ab6c97a4ccbbc.png',
    id: '1950',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5563421b70ce9e92277d8ba850c877177f007a64fa06fb60d68e88ee47aa8613.png',
    id: '1951',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3254b6c87f0fe7b271bbf652165e0be24572384ec078f29dd8488b8e7428ff2e.png',
    id: '1952',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0c3b2ab8f716779345622a43583c11002118f223353a98b1fce0398ea90f67d.png',
    id: '1953',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b6cfc4d48f0d20a7d75ae8813370e3a0cb980b53a5ddadf11e49e3f997b614e.png',
    id: '1954',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9c7952667d607efee769adeab8065693841ae9ea741752e86a53d95d8b35d6a.png',
    id: '1955',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c788a2c0520a18184f960bdaf61b7048c27044fd70a265401e9eea6924636d8.png',
    id: '1956',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7da7ea02d5a447ce9a5fa8e9dde131a8cf0091a70cb114e8703b4dac62e16af.png',
    id: '1957',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16a829716af380f5fe8716313bf92a44927ba739460e8e6a4604e4fc0190d924.png',
    id: '1958',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/daa8c62ab39113ef2f4db20f557a37fd400b7b823f351808a562efe544497fc1.png',
    id: '1959',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c627b24199a28a90c0c3a9a42ca2a6aacc769793e4b24d85ba2f80fd19095995.png',
    id: '1960',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff15d257c2444ff17e08f3ed4977da81bda8a668566867839ad3b37812aae251.png',
    id: '1961',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d0515bb4c114fb8892faf544c767f09ef4a5e6d40ced6c332a05834bb940bac.png',
    id: '1962',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c68f54740d6dc8682aa0ecb404fd2acab515624db76699150dec74f9f43f87d5.png',
    id: '1963',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2480c3fb556c23595e9f30fb9bf88ef3d74dcf95bea0fb8f0cfa00577ef8f450.png',
    id: '1964',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb52d78e47952fe742ddd8446f1a22a99efe297d31fad891a4dbb6551d03d6b0.png',
    id: '1965',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb43b239062724159c29f1afc522f789ddca44ab541b515a33a8e7ea5bd69da3.png',
    id: '1966',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af6018183e752ce2ab11044baf0ee6be4ebf62bd4596b1e19db38721de09ca84.png',
    id: '1967',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a96abc99e1fe6f74ce64fdaee41049c4b4ebf5be073811a3a76bc236cc1df3a.png',
    id: '1968',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d152caa6162a643f8d91d1e3d40c6130cba876372092108a6e79f379d30231f6.png',
    id: '1969',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d05a3e8dcf656f01d8aed1e2ad0ad1bd3d6309c7e49b0809afff552b39ceab5.png',
    id: '1970',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39ec389a92c44a4debeeaf04a43a307f2c8d2976a8585e2c93dcec4fd5f9cdc4.png',
    id: '1971',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/278271eae77b73dfdc2d37a5368a5ddfba0c4b30bfdf5b0a048cd0068eb4afd5.png',
    id: '1972',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23a7cd2414f5e5828b17b01c7a3020d565b3e3dd591a4e8afd2bc320e26c03df.png',
    id: '1973',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9da87a61f4e2d6d8cdf9463dbd2d5673f8424e214d7c5b821352ffa766c6cb09.png',
    id: '1974',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d559d04f5c311f78a1d3fd8dcdcb00ec1bc5aad0653e3f73c942f2db3a8548e.png',
    id: '1975',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0b2d83af888736421c2961ffa6346e013e65f4e70110b38a5e33054cd12a2d1.png',
    id: '1976',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cee462faa3001024ee7715670f4fe8faf62e49b575e9aa3c3b16579eed7d5717.png',
    id: '1977',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3743ad077c6b51fd19044ef609f45839a0ae4776713185fb13466bb71bccc5a0.png',
    id: '1978',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e767357112567a0b98389b2c008d56f3d1cdfcc3c390ac426c25fb78b5844777.png',
    id: '1979',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
      {
        trait_type: 'Weapon',
        value: 'Blade of Great Heights',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0f1f9e5226d605495236162a7fbd53147715f82fd45d060b30100aa2e9d8e73.png',
    id: '1980',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c13306fe89d98c7cc3520a4d97d6dfec520c8b29c3dc8f8a69e4f11059d7a409.png',
    id: '1981',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75c247ec854dbc1d2619c41d465239feb70209a181c9dc9a90c7ef91c3b72d39.png',
    id: '1982',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa2c616f2b8b24ce3a2517a1fdb986b5efb4822ac911be5f3e8707e69f6b3f9a.png',
    id: '1983',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5edefaf55c68f1a63022fbd7297bd42d5b56836f6821931e9b2798c1ca4eef5b.png',
    id: '1984',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41409ad62452175c7f5d3fd99e413f6aa9fe4c96ca302483da45a937851202c5.png',
    id: '1985',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4601c0940ab0aa2ca5268d6017a68369b2e208f10aa2f071e1e62c24a7d5685e.png',
    id: '1986',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a46d648b03b03ea869fe45d9322b1c6aec0c591404e33e98cfa3c4a29419e921.png',
    id: '1987',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03ae83722d64e6b607e9abe33e1282002262d3da563373b6390dbc3b2ea9da95.png',
    id: '1988',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Jaws of Evercrushing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a36933e0a7deac1a0c7c4cc691b305adedc7f8aaa7b56b53bd837b10d64e072f.png',
    id: '1989',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc52dfab4e98d8d3d80cd542183e3f3c463a2a61abd1207ee058ec03c3d94218.png',
    id: '1990',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53d0ecdb16f974dd1010b898886841ecc96ad69b0ed2567f586282637170aa8e.png',
    id: '1991',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04a1af77bc6dcfa2fad6c848d42164e52980aa514670849cfa43291f3d3c0826.png',
    id: '1992',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16766d1cca02762144d9884d3648064498cbfc053da26fbf2cbc4eb20ff2055c.png',
    id: '1993',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8888b7b41b1bf5d22d7fdebaa18852635542b75019c577645549f0008ecda604.png',
    id: '1994',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4859272f60970f231eab68670e34a2321189ed216a73cf1a48c0edcb3392b528.png',
    id: '1995',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/359ed266bd78b0685672541f2b8dfee741bb317b390ff0e9f6822f259bd5ef3f.png',
    id: '1996',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28918ac90553a7c358b6d533bec36b6168b858c7d26d853dcd65c2f32d51d171.png',
    id: '1997',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9218e8c73f8052f93ec9b9f06cec89ed983da681fb070c3101d1d468fddb97a.png',
    id: '1998',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/106e2ede0276b89ea24515a20e06baed724fcc21bb06f9de5b8d13328abd216e.png',
    id: '1999',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce175b7153a9f7a7a10db54af4994726d15147bc3b4ca6bdc782dbfb060aab2e.png',
    id: '2000',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/503b54baba134d76f660e9f8fb06d5c404dc9193270f14905eeb80dfe02b3423.png',
    id: '2001',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3dcf3e320dc9680f928173dd2aca7d4a657f8b8184831bbf346016f64cc8e92.png',
    id: '2002',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92cd80930ee1eb603b12723fcc742fb61aa2c71f96d71a0f033a4c6acd504cf8.png',
    id: '2003',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31efb25be83510ae9c56ddf8d20336ba51080178b182935bb5268d0306b21579.png',
    id: '2004',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e04ed5432cde046a029229934986a6ee2a36aafe0aa19e6095f118383aede0e4.png',
    id: '2005',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82f765b7bdfe067ee3a6a5b9284c97058584546dcc37e17689ca95da30fd7554.png',
    id: '2006',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcded7aaf7742f98b6b9e02c220b924a937e663200411802d3dca2e0959744bc.png',
    id: '2007',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a02a7458f6915c3f276253159c2e5ed5333764ebb9fae0f50669d1f55b3285bd.png',
    id: '2008',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19bfeae0b4159517f56c4d763c295ea2696cf0104d9095af78d81d18a27ba860.png',
    id: '2009',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35eadd9ec325e55acd697ced29c4aeff0e42435a411f488a14a558101f2f6695.png',
    id: '2010',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b82142839a5c095d6f437315098b475c9cbb331163ed311bf700e79cc8bde4c.png',
    id: '2011',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d55721c161a235b3b97360d512c18c875a9f6c812e120835b6929a75437a7e51.png',
    id: '2012',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91a28d5f84d4ef18c12ee6f9e5745d61e99c1feb61f8664ff1c815f921ee01bd.png',
    id: '2013',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d7f8e96fa287e56bff20f3e80e858b32e9e32e351b697183674d05549d952b7.png',
    id: '2014',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef7a64313cac2694df83770b0e718a848314e874a27cea73a6f91c9a3c4d89f4.png',
    id: '2015',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a300d20c6659237ded1d45d80b1cae454c36b2d2600046e03dfb6d246b38747d.png',
    id: '2016',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c720d793bc71976eb312f3022d6a30534423ac8bee0a87c803b6d68194a7663.png',
    id: '2017',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f05a7c91311f827beac5373078c485755c2531ba43d5b5d5b00e45dc434883b1.png',
    id: '2018',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/978ab7f3ceaa08b0c119e18b46afcd69e49e2e5ca75dbad649ba6233e5f1161f.png',
    id: '2019',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/323bf9df951bfebf5a902ffecb067fbb4977aaac94f7500c0c1930076feaf7e8.png',
    id: '2020',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bone Hook',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a6d4f9a9452dbed58062566df4cd31ac48a1005ca9e08f65d819992accae636.png',
    id: '2021',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/50c67d1e29ff8ee1a34b0ddd95542c4217b92f2b0732339aa6eb8d5dcddb643e.png',
    id: '2022',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7488b12f607e97a052ca55771d799b9000146b9601a87a75204955c3768fab49.png',
    id: '2023',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e38ab82221d974c844eaf2915a4dcf191c39bd065a6157a3ff07f0f77f7e6e2.png',
    id: '2024',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bb6506367f0ed4e1bf4210c6329d418d7bca76cb1c3639cb07fd001a9033edc.png',
    id: '2025',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5006095aef75d107838899bc22346d669d131a24a78aa950b6b04a0542d1b957.png',
    id: '2026',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a4a1cab02305987706ff0f0fd199db32a7f8779aaf65975952fa44222fe169e.png',
    id: '2027',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/544c87cf555e438893113a93d701533df31f534da559cdf259f4e203418e874b.png',
    id: '2028',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/463d5257f9b4ae8d7c65955beb90eac8e242dece1e4d66b4eaff8b7d100f2e0d.png',
    id: '2029',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/deba681854005a1a3c2d1eaa280e3782ac5b76e963369be06167adf8ea606306.png',
    id: '2030',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dcf9bf1ead3061a445b6b2df6d8ee2e66437d1113422eadd9c4a1a313cfe084f.png',
    id: '2031',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d67a7e0405c6663ac102a3b946c6ba4d36e9dcf702d8d00fba44baac1e678f1.png',
    id: '2032',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec11df8215c454a9cb27229c0b41b5f76bcc27e2705f1f3832e8cc4b4ff1f807.png',
    id: '2033',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/844a12247d5e259c5a317cd31917e50d1c4e8ad5b711ff15863b5016f6bd20ba.png',
    id: '2034',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0bf7ab4d2c674dcf6cc036c6ca0ab159fb354ec5dc9f19ae3c399e23e1d43260.png',
    id: '2035',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abee9d3002f6b50bd3c2c0534a019018cf95db5402f65270c2c412bd776b738c.png',
    id: '2036',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da30e8290b493123c22c5a32cce7faac4f8fb16e34c87d68f4b5c00abb9f0f3f.png',
    id: '2037',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4c6202229ace38c144498e5e9b54d70f5a10a2791a7d57a30351692d5229e6e.png',
    id: '2038',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/156d85b7a3b733c8d4375905628c47c48b50727fcd3007d0a12a9a7fb3d20b9c.png',
    id: '2039',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40b2f3ead02b46aa32f3bddac14aaaa2710eafba9870e6220847fcb75276a684.png',
    id: '2040',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f94496afa560148c529f73b260d745ac2415f4418e8b7d666f4e84433a4646f4.png',
    id: '2041',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7c6ecbb734b96e1b86a3c698cf0c01be699b9236b7da25594b16df996129b6e.png',
    id: '2042',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c1cc20c3c0240e4a2affa37518e478a2ee37cef582cef9bf7c7df039d0f639a.png',
    id: '2043',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43ec1dea308f0d8c00335d1deac6053a88355e1bfce8d25fec6cac7f51725482.png',
    id: '2044',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cec0bddaa015ad7dc4daf1799c373ed6229dfd1cd4ae44f391f6946e1ec46273.png',
    id: '2045',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b34eaaad222d492f31e2d5977add367b2aeae3ff3eba077717cfa4cd4d2f4802.png',
    id: '2046',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90d4c1f817c81ba7c9da8fd23495f06352716711d3643f7d7d900acbbb35b18e.png',
    id: '2047',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a6b01c862dcc06eb832586db49a798eee1ac471249ec254cb69401ddb9bbd64.png',
    id: '2048',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21300b95dd7d241fb6e7bd52953ac9940dc19e68964bb21ef798cea58dfff9c3.png',
    id: '2049',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5d52df5b0cf90d8c93c6b1f0d58a4b65b717e7cdbb73b5d49655b520fb9f2e8.png',
    id: '2050',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5fd3e23c80ae4220f81cb36408dabcf4bbc9cbf29bcc1fadb01ef23eb83ec83b.png',
    id: '2051',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e6d6be14294e83fce89b774e36c2a72b0cd429c7be7577f6f50542aa0a2131a.png',
    id: '2052',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/183edc1b3bc74b3835b95bbdbc0dccdc9b51c48f6c3070e30ebcbfa0ffb9afb1.png',
    id: '2053',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9c7b1cc896ff83983d8dd506b108b33e6863fa3d20c3a65c2e6ae53e5fbbc20.png',
    id: '2054',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55461bb0f32cbd054fb3a23a5ef3bd548bc37c525bbb1fecf7f5f4893b407da6.png',
    id: '2055',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bfdde0e6bd793edd1dc38c2073915d81bf9ca4e2ba4206356b4880d6b48a9994.png',
    id: '2056',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/856729151f18361fa3ec005e90e2ec66ade6984cc6234b27021c009e638ca977.png',
    id: '2057',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/077000835bb347ed8ffea66c3ad7f675c6febeb3d14248e2a3d034c735517093.png',
    id: '2058',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a811466f11ee3ca342864d646eac108ec774133fb26583ad3cb8d2c5dc016ef.png',
    id: '2059',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94d941124433c1727cc9494840cb8f134c54a0518940ff6195a8d76d93e568a2.png',
    id: '2060',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84c950edf779ff1bcfe7569433b1d8d57b3dc54c36eef1de26184d3beecd1de3.png',
    id: '2061',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/201d5254a648c976c894399716aeca9e7446aa99d4eac3a0014c2a78b5ca5c03.png',
    id: '2062',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df91dbf68aa9ec48ac843cb0ef4f514e54a6e15a7bbf1c9e54576088240d4060.png',
    id: '2063',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/584961d0328f10bdf36cd2d92304754bc74de0d1c5154a575540309eb1fa2f41.png',
    id: '2064',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28c8788f387deb86a1576cfe66a88394701656db6c303cf31e841316992a81cc.png',
    id: '2065',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2591ffcf1d6e371a1d4c7abf056f2303f924c0b5db64b6b5d041688f42f7bb05.png',
    id: '2066',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22b5e71c992930451f5da2011f5877cde60097ea67d8a503701dc25809dbf7ea.png',
    id: '2067',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Gaze of Mysterius',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1331b7f2e8eab2c3fd6bfe5567460647db891c002bd5dd959345e56619d1c20.png',
    id: '2068',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/141615c8cf665089afc25d240c2447a55e515340869d8e188b6c3ca65e3893d3.png',
    id: '2069',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/655667345624a3d5211298e8fab11945e4bbbc43353b906cf308dc185aa3a345.png',
    id: '2070',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64b64a02d6042d481ff52855d7e9cb132610b4a679117c34fb7d375ed629e8f2.png',
    id: '2071',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba9bdab3c193241af8dafee91a308469e0a755b2aeb1a92d5164202157e668f1.png',
    id: '2072',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d85d67a41e76174dffc57b509311c01033453c11b6b25bcbcdb57f41b08854b.png',
    id: '2073',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a9e8b407d38c4e27692d1a12946d4449529b958476fce0980d81f2276fa2300.png',
    id: '2074',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7871aee6977e15608aa3a11284351b347ba59d2e4cca9cc3b5289e020ffd1d7.png',
    id: '2075',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c3aee8361a06e74dee9ee868c75620d431914613c18138670270725044d68f9.png',
    id: '2076',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6bd7baa5f55dc1451971b7a08df26aa9b9a57f9b77bd00b42af85e85b1da951.png',
    id: '2077',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b05e28fe7faa89fc4698aa4287b0cccea009d5b1ce2b20bff6053652c3c7cefa.png',
    id: '2078',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b84689d2101462496f6bab025b9f777d1ea83dc13bae64fd3aeae7e9810c3b6.png',
    id: '2079',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b05ddfc78d83686a1a574a3d918cda9dca488e5fd476f4b93c64b7ef8581bf9.png',
    id: '2080',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d994cc192255c845b2b5645b63f9f0c2db68310aa1bcec7c6521000460b7f3d3.png',
    id: '2081',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9da13fc06310db63cb2234ddee8a70220ca100f22123f69e8b6e7d2fd43f017.png',
    id: '2082',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8664ffa0f718220f3d944e7488d5de7963d53bbe3777fd8a533845be807499b0.png',
    id: '2083',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e920e2eb5734c5439bf3ffe44baa1e2120673f1d4ddbbb8864de63f2b35a52f1.png',
    id: '2084',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a99d6fa7a4273c9e5b34b8d0c3215c84e104b087c9d369184292d6f8bd381e86.png',
    id: '2085',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b0b20686a27291b54a2ee7732303d95572407f7da06a78cfacf6de0d5c78417.png',
    id: '2086',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3cc3fc1627fb85ac1e79a2bd9060b64bf97bf9c0fd648c7423626d34fda19ce.png',
    id: '2087',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4c01630e7fe239d6aaaf4617cb17801dc926fbd87c9d14e7e19eaba161cbb7d.png',
    id: '2088',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/782ac7eda7de012a752bb898fec1b144a57cd4c18fe7783a507f2850ea21504d.png',
    id: '2089',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e46ac584787b0822e7e9c8cf67f5c9a132252efa2ca8e65efcfe1de43b553db3.png',
    id: '2090',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1fdc8ea238f91c2113f5894634f24269649a8fc44f98048bfb853f7cde73f6b1.png',
    id: '2091',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5028d9ed183e2d7b2bb5247c7cfb914f819d0a6029f8ac1b0332533da620bb5.png',
    id: '2092',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Lights Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1d20867e9989f59c7630c53c715c8e0c182595063e0a996d5ea722ca4d51a63.png',
    id: '2093',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/950ad7b55941af5cb2f5c80e6ff0f44ea40eb5fd2353e753099c998080cf8459.png',
    id: '2094',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/295ada694c28bd401d2853b2f3c7a952af5eeffcaf83782c12643239c995a925.png',
    id: '2095',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24ee8fec3d285c9ce26eba7a46d0e12db1daa06c0fffa3cf5b8195c49fc6dcbe.png',
    id: '2096',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/635ff84d4f252a704705b6d5db06673339d59ce4cfa53146a4171f52028356a2.png',
    id: '2097',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f84d82a1065ecee4c3887eb836492e9a3b1bd70009bae2f719cbc4880ed614d.png',
    id: '2098',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d214bf1a64d6d5f48dfb0c98d9445288575c0f8b7db752396ca000fdc5f29efa.png',
    id: '2099',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6c0187358fe47ca95a1afb8d0ebe6450671e7e0ed60c576c75d2f2973c819dd.png',
    id: '2100',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d3063a8e98f56b1a5224c0c97974671aa9772b039b07ff334263b2978762fa0.png',
    id: '2101',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67ffda3baf34b1beaa12897d29f7bd47681c3a911c17b5231b1b4bbf4715658d.png',
    id: '2102',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6f1f5d453b62b9eeab7f411813bb7a981006b90357eea2839f58106ad482542.png',
    id: '2103',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4c01b875c9870367d93fb51874b034838178d64cab2489e556475ef1e0dea98.png',
    id: '2104',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8b63e3469dbedaadaab919c9293ba52c93c20010e65df7610dcba3c2a8dc298.png',
    id: '2105',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c4dcd835419bf6175699d4be7285ed4e39a09721f7ba909383d2ea8295db0f7.png',
    id: '2106',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spinal Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0853e6b467027dfeba9f27581111dd5783a0ba33642920b02257d6a80110e9b.png',
    id: '2107',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa45fdaf2d8a82b824af58474d35ef5233a62a8e1a99bce207fac15985ed41b2.png',
    id: '2108',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55e59b04acf2a716c69f13b05d1d8443fddfade4385c13124ae2b448d05396b7.png',
    id: '2109',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e476344b7a2e71ca81ab78d6179b15984a60d4cc8089b3eef8e3ba361a74cf2.png',
    id: '2110',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc9e58769527fe2e8dda110659c151c27d201728b8d041a4ec901198457a4911.png',
    id: '2111',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f71a49f5edb02d400f20e72d0f26a84cc52ff91e98c090fee0ccd61746c512be.png',
    id: '2112',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1397a2e9a4f9055f05701fa972a3e989fd3d8a1bbc63ef551fa2939591ce6e57.png',
    id: '2113',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08688223db3a3c11fd5abb465d199febd16dc04a6a99dde075dee9c444891403.png',
    id: '2114',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ed4bc4ae888c70445456e26001a833e3a7656081e18c280c4b77262cca44a1d.png',
    id: '2115',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07b96046e4066c5438ae77971c868d384a1db7476aaaddfbdada38000dc430e0.png',
    id: '2116',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffe313300cb34daa0ea5232acfcdfa2768b06fbdab8a35fa9a219735aaa5b0f0.png',
    id: '2117',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ef970a97e86a551da78f087e0582f4014118c6842ba1321594d30c01670121c.png',
    id: '2118',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c11201e21f0944fac315b1cd689e95c922e0351ed987c8e0c696c561426f7e62.png',
    id: '2119',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4e06379edd5a38f4f84fe402d65c1f2af544ca7efa962d0a34fc385afa840f7.png',
    id: '2120',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90a41fdba0071b83106bdeb6cc974246dde21e74c3b0599c028618f0780756ae.png',
    id: '2121',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a12ebe5c78d7ee9f1f158ca72211aa0ab69ff137a88ea7b4d77dda880284bf86.png',
    id: '2122',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33604d2242df35d21af079d06c0ed6b41ce3061b9b55f264088b817a0505f9e6.png',
    id: '2123',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/727ca3afededf773ed1ffccf50424b10931adf68bcba4c97e7bd194f1b9d5444.png',
    id: '2124',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d23e3bbfc53c17e2311b3698d53835d1c0be4a469143ad66ef85db65f6a12329.png',
    id: '2125',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cec6a1070668aa06577bc0cfa3bdf2877d0dbfd4a22ec7f139e561bb1c4c942e.png',
    id: '2126',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6fa7903788247615b2e8182425d2638714ef125fb3ef45fae29697c108020931.png',
    id: '2127',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c2cedc9a022b1dd0d39bc2c716e777a4675302a0b6b1930ff6621385e36cb0b.png',
    id: '2128',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd2514c72c93c95eb15c52d1ccd61628fec8f6719e85a6f4cdf4af88c89209af.png',
    id: '2129',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b1155d4f9d9857666cd80894f011c432e3b7961beaeeee17bafd8e11ad29239.png',
    id: '2130',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eaa22d89cdb225933526e32a7c7b11650631cc39287c6c518281ef304af8d5c4.png',
    id: '2131',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/074e4e344fa2716285ba775137c88ed81329317c5819cc47a2654e453499eda9.png',
    id: '2132',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2990e44c0419c1672b939623d7e7bc345eaf66a13f54e1d8a4ae125c63031044.png',
    id: '2133',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f47a281b66d9d76fc10a364b9ba6e362085adb2eb253946e1fd77697b0d7ef38.png',
    id: '2134',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2712a6ebb7a8182dee1969fbcb43a77137d0a18110a4b295f54922da40577d24.png',
    id: '2135',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1aac45ff98b6d1bc41f607a7ed7c80ab86efd59736e1edf51b011791ba5da48.png',
    id: '2136',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d072fe013fb04c814b902ddf7eb8dc3eff94cee97d98656e47106eea702f0ad7.png',
    id: '2137',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d10b0b11f59cf232aa9167691103260cc7b54fceb998e5f9f0ebca234e460ec.png',
    id: '2138',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b50c6f82e9a620b3d35f86ba04c8b0da276d5e14b802923a956b0af673f2c441.png',
    id: '2139',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aad590d7a9f233f64f0b05f9e9bfc0e77ca7a82e8053e4e21784f3dc94a51395.png',
    id: '2140',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64ccbf99b4f17f87a351b1a734f6e4c9ec4b8cb4b305c66234e1b751cbe24288.png',
    id: '2141',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7e068adb0d091ae099d922c57bb2e5907b157a0686748ac5a5f73ce041e9854.png',
    id: '2142',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5160d66b8305accbc754d8a5140ed090f163517b8d0aef615988ac8db7876318.png',
    id: '2143',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1eac80fe26e96adb2ca8d26abdf689bab61949dfc0edf4290a8e4c40d0f78fab.png',
    id: '2144',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0201b398aeba3369e0618092a104234d48ea9a58b90fe68f87dc30e9e07676b.png',
    id: '2145',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05f499a326a803fd1b133b19b01310a5ac3a2b3112371757ce6bcc83d9369abf.png',
    id: '2146',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e25edcc8f68a791ad58e6eca36d831b2f7ba5080067cb2360e93a7cab893d524.png',
    id: '2147',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29bd2f981ee1d3e73f770c66a604ee187880cbbc74536a46c236da004f470cd2.png',
    id: '2148',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27598126ede6994622f5ac53b81f772130b8476e4fad1cc6aee0ffc6ef189082.png',
    id: '2149',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea5bf60ae185d5545368694765e8f81c0b0a9fef4b667b1a48734b500ed2d7c2.png',
    id: '2150',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4c4b95bf4597db0cef8014d2c2f1215e2ab3991a5476db6e554435dfc548f77.png',
    id: '2151',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/543850ddbdfe6607c16c8a80109733103890ea1f0e2b74f0f7764a5478753311.png',
    id: '2152',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47e0873c619abb0e4b46d91daa98d4c4dd728044138d1ccc4a24653ab0922fbe.png',
    id: '2153',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cfdcf82676c750e414d6b9cfd4190a7f6ed0a235f646c9497a5f3d4f7ebd84c.png',
    id: '2154',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fdcae59a3b2703d37da29817f5093ab864b0394c2e98c92662822e03c8e6500a.png',
    id: '2155',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fabcd396cd645963fe365c3f548e2e39f70b5227755089b46895b9be7f27cdb2.png',
    id: '2156',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5d06adeeac511a9f63edf3c6af8a8a1359168eb8b4a910caa854981d4df395e.png',
    id: '2157',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2841b1c3cec0cb9fbcaaadd29da0221503ff21e2349f5550cd21200f374fae7.png',
    id: '2158',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca188597d319c6f7c741f3a4e888debe60db8a6165127e97314a392f1cad3964.png',
    id: '2159',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/261e3cee983db6e85d858be0ee7e6d510e4c2284c7b481d4c6228a730e4d74be.png',
    id: '2160',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/194b6ec774290c52d2587d7802ff2621afb08a0e4995d9028cb441afbd030396.png',
    id: '2161',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea28b9396196c1e55e2be4b1ed0f78757b3157716a8c47c3d5f75ed1569432d2.png',
    id: '2162',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72dc5737fccbfac7732594ae99cc1436418f57f99c1e2a8d879b14c7c9fb8faa.png',
    id: '2163',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd9bd05bc07972dd04cc040355db71a1bccc03aa59816405749d5728fc99429e.png',
    id: '2164',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a875c4125d66e103c98975f16890f2d2ff25e51e963fbccbc1d1c5c71776785.png',
    id: '2165',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a50d4c359cd24d6bb9507986219fd382dceb233e40c8dfacec4216943560580a.png',
    id: '2166',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40b50bab0657a79498691a2959eee760ec807ba23327656e452a33e3c1e31825.png',
    id: '2167',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9027a9a892593073ccd87f64468e2258e774ab504e74b9c5f91154cf9bf9d43.png',
    id: '2168',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/313b0b5682daeafa6c2982bdb7040d2c52392bfbd56b10becf1abae1f733b9a0.png',
    id: '2169',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af8d6b2a9dace20cde0db51c9d80bd0bd6fe2ff7d278141a948a30f94e4f2bc5.png',
    id: '2170',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d2c5d3a4a1af805ef3c4e2f8635c557533728eb223f840a113737c3d2bee8d5.png',
    id: '2171',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8dc5ffe39ae5e86c3cf732f66e182b870aae329f2667aefcc41ae4fcf23c936.png',
    id: '2172',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a6b6921873b5ebde52eacff4670ce5342ace9e40107df639e422e5d2c40e71a.png',
    id: '2173',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89ffe31107b1a7327ad6dfb12b754d5e1d037d2ca65720dc0af878c1e38500a5.png',
    id: '2174',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45ee51493b5409ad7fbefaa5f11d3261fb7b09d8596906a8bd1a05a0f035de2b.png',
    id: '2175',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4285920876265079ee71e87a95be9c89c8c139d4c18e467e57dbe8b170212add.png',
    id: '2176',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed33f449fc7c43f79fccd04aecf7223e315b6a35f64d8df17839d25e0566d0a0.png',
    id: '2177',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2dcd433803fc9bbbc79c6060fed92ab09bd6d4e69e88a272f90284e90e2096d.png',
    id: '2178',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b78fc00740e715dc165c8658b94bc03adf2b0c014a5d56891bbae632cf00903.png',
    id: '2179',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b16213f61834df39f497158d1057155c13cf6b1860c6ed2891c359e0a4fa5a86.png',
    id: '2180',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7ab3a5f281ac17b70212e35f836dd3bda8130ff514d2eedacc0df1668c892eb.png',
    id: '2181',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9eaab154ee024dc6ede4622d94ee99cf8b94dd97df875ecece7b3e540959e23.png',
    id: '2182',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c14e5354c041c6e9b6189bf594697105b4fc6f5ecc6c19b427713323344a51e4.png',
    id: '2183',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dd7bf94ebb5a43efd44fdbb8097c74c14b8d4a72f0a26e3e38fad8c4e414c7e.png',
    id: '2184',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f3e16ab92e60ea7e47d36eb87cdffc3e71300644f89f938248a73ff9782dc20.png',
    id: '2185',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/534a031699de8b38708a785b2f9feabf1077af352f036999fae5b0d11f8adf40.png',
    id: '2186',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/deee084b90d1abcebdfa154b98fce37cc11bc6ced5dc6181013582713b1e6a6c.png',
    id: '2187',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4082cc901783bbe0f47648bf859c8dbec06a89643656c6ea1448e9c4164b14ba.png',
    id: '2188',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d9b1903d17105e62811ac5c9691f82625106e7dc31cacdb4cc1ffa5f28889da.png',
    id: '2189',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7fe4f21c55b1d2ebd506cf3ba9f473f59f6ff78a8f6fb80a9409cd7de837a60b.png',
    id: '2190',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b674d6db0aea65a326ed193ed51ed021f91998fc27b041def5bf2044100dfaf7.png',
    id: '2191',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c0f6eb9f65b878a2ff537524aae85c93e64ea4e341215ab1a72d1df44ebf21b.png',
    id: '2192',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0c76d53787350a2664175267ffadb71fb3b9babd96cbfe5232d346310899c78.png',
    id: '2193',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbdc99e2ca2c5da22766e7e884198ea86245208b216e45f2d6de64a6a821d7af.png',
    id: '2194',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53b9bfb860c6b9af471220a8227f86521bdaecc9df9108884be48774624a0633.png',
    id: '2195',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e81fcd0b399f66912aa62f695dd262de24aad8600f8bb68e4160e435d65216e7.png',
    id: '2196',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dba58b5ab181bae274369610da1de2b2ea70328047413cfa1ac1f1cb9de43b50.png',
    id: '2197',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5681b5d41368ad2a707a58db26ecbf05d04d2038648357ee0e17eefd3fafe893.png',
    id: '2198',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e40d4dd1398176d9c0a0c264889314acb7f6cb7d11d8ad2c847c973f0642e4e.png',
    id: '2199',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7647ac1e676b263e3849bb0353dd8763b4057cb75550f872c2899f2f68dc329.png',
    id: '2200',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f99bede0d5616525088172d531bc173a47078480dafa33fc9bc293999ecd4744.png',
    id: '2201',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a3d8c5e0692c941c065f073e364945f08b3b6386a1228a7163ff25808845436.png',
    id: '2202',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1262d48b47c6f2491d0c34dcfdd9c5d5df0afe1d97609adba3256d45c29fa61.png',
    id: '2203',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8824a8b095e0db3d1485db81ffa9e4cd90f2cf1c0380bf4227d5e5c02bccf4a.png',
    id: '2204',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d53785ebf23558a2407be187a5bf134a6ad2ea8fe70a59f070a27e23751d4e4.png',
    id: '2205',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fda471f78b85b38980a1f0544e025d1a51806618b303a37dc39a8d1f3e8ff6e3.png',
    id: '2206',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e5bc1f2d3c58c6eea1efa24dbc38637f888c1c63ac06efa452c3c9116a861ef.png',
    id: '2207',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d72eb1a0ddc2979caa312158dce7d7fec7787d0c020f7239984f4ab0f53a53a8.png',
    id: '2208',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1498734598494e27adfd6cc64c7932c56e590d723fe97cd473222fd3330121b9.png',
    id: '2209',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92322df2e3cc550c228435bd1a72c51cac21a8b00237428e513875bcde2448f4.png',
    id: '2210',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43ec11ef5f978671ee2d67d0893138e7b85b53f497086a9da2f0aa1be72e429d.png',
    id: '2211',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad3c55406c0998f5332f929403c1b3933206fd651675f745344ecefc14dd419e.png',
    id: '2212',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d67a6ff92b96ecbe048d9cf9e327e150f6201e914a8f95976db3eeffd918c91f.png',
    id: '2213',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc94d2fd5ca23902459c5d6e04852a3de20ef3241225287e0b1f9b0629a43c54.png',
    id: '2214',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7eda8d9a4f8c49fde0b43414973b1468b6914e211c42d718e83433101eda055.png',
    id: '2215',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/275f801fdcb9e50e08cacaca35cb829be7ac28decbaeaee5b7d2eeb800166921.png',
    id: '2216',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa2a8f862c1f5c47e2846596a2120c343ae64563b9fd51a54a41cebb2a491739.png',
    id: '2217',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0ec146fff86747e43fc2657dde74838c82e52de894e90daa0da055f0afa4aa6.png',
    id: '2218',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62b7bc0c4d6a59ea3fddabd3016c8035b660a51878b5e16a41606f6d26b18b56.png',
    id: '2219',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecbc0fd39a91716121035dbafb83f6f4b9a5715725ce589b5ef4fd96853c0d42.png',
    id: '2220',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3208b2f2fa84cb192aa507d9c5298f0efefac6f5640b347ddd2bcee10647720.png',
    id: '2221',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06ecc59cd9d29802b8bdc19259ed1fb3a0a37287077d07c3b7eb751c512631c7.png',
    id: '2222',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf441d4d99d2345c7ef5951ea2fd393b6e1d5585075dd0f1a759a46664213efa.png',
    id: '2223',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bbdac34d35e189934cd158c5fba349c5e64ca42ef1249ab1393055d0afe807b.png',
    id: '2224',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3f0d364ff37d44eddd49ce729c2688592ed0558e8707ce8de78709967e337f57.png',
    id: '2225',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/617725561d2c00c00d7472774420a567fd6c8ce9b34d96f7ffb4d7962b0f603e.png',
    id: '2226',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92f4a5fdcbf43e690a19931a36fbd7d5c9f14543497cd500aacd5100e406f570.png',
    id: '2227',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0634b523d8919fcda0294cce80302cdb3fa77ec34e9e2204d940b6af3392185.png',
    id: '2228',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82052b26eeda419352faed6c65515909a17fcad96279638c7acbb92377633da0.png',
    id: '2229',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/165620ccc096d4217546101d03c2c13c397ac068c05c7f4f38718d3d74a69e5a.png',
    id: '2230',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a36c2846c87181eacc5da471b8e4269bcde6a1f75edff91940ae10b869433c94.png',
    id: '2231',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b56a301e75bb0ff4a64d0b9f79891e04d88dd2242a7e1952124d543a51230865.png',
    id: '2232',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba1bbc8cc12cba2e70420f1c91f48a3f8af8d36ed7ce0a69d55e8c63a086ee95.png',
    id: '2233',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/80be0187f209e40667d7889a8fd616eceb4ea8b3166d19bd17b58d231d8d1e74.png',
    id: '2234',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75b6377303153de6a214931863eee2ef034b86e0912fdbad124e97b00b2eb1cf.png',
    id: '2235',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a45e80bc04f7addf7cc0ea0911d34dd9562123d1302d1c4e9c67d0860d23983.png',
    id: '2236',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/50f1e35b415f294e1ac47bcb247db2d92dff3f9fff53b2b6fb46ab0c671b6e65.png',
    id: '2237',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9285a62b3fd4de0c1ec96c0b3f0e60e948dac616200859681b1e77f4f858e9c9.png',
    id: '2238',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c93475e7076fd6dae7c8a52df561ae0f5fb12aaae15a0eeb1ae967505552979.png',
    id: '2239',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b63cd9b8c6438b1ef37a0613f1856f9e60f8cbabe00c189521ec15001fa392f.png',
    id: '2240',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1719e4273f76d7fe12c378c53c1a88ab3fcb01fd2583a0fe8e5350c9de8eccb4.png',
    id: '2241',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60bfec210c067c92da29e2e62fe4fb83d9f6fee0b316bfd6937ff4719a4b12a2.png',
    id: '2242',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a96cf986a02ea466a00a732ec58770aef33fc886b0a472b6f57069ca86166737.png',
    id: '2243',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acffccfaf19f0b49205c52a27776061727d102f5470091bd5d09a2c6c84ffa63.png',
    id: '2244',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a313620e55a1bf119ce24c2ea0f51167d6ff1fab6b221f7ce0945167a34b0101.png',
    id: '2245',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d98987b33b8551756c7bf7663450da3ada1431e07c8fc81ff8419b5c6212fe6f.png',
    id: '2246',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/015b24bee3d5969c69e2dc5a06b70623c5781df789307ac8ec7a961ccfd3cb6c.png',
    id: '2247',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c814d29423451cfcfcf48efebd4777f0b152357ed2ee2602d3a53a831cd859d5.png',
    id: '2248',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28754837fd620d3acb218d2bf411c1a658fd7634391ef1f9d10fb9ca03cd2ff0.png',
    id: '2249',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a8c0c13a3622cc9aa8adbab06a7fce6f948d148da2e28897c021c8a76d18a8d.png',
    id: '2250',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ada4af288dc3813cf6658155f9ec29e17be7c1a05ed879a9f6f5d2fd0461606a.png',
    id: '2251',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3eceaa94d87215f34b0e139a33b625b3a3e614bf30da4f4371f903be39b4a712.png',
    id: '2252',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04e0e4edd46fd56e21ae8338ebc42e8735cd84e3ecb7ec0427fb40c3f7efc146.png',
    id: '2253',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a259844cc9862f45f9f72b3625a415ec4f001068a59e0b75404c2fab09aa6cc8.png',
    id: '2254',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/477edd3efb683416465c289c0cbd1af1752dd548f420e57c601502d624b29cbd.png',
    id: '2255',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18fd2a24d1f4439d02f277135a2c886c71954662849b4abc81898d53115e77f3.png',
    id: '2256',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1299fc123f0bbcb4df08a514b2f8790a0e31ea5f6ea3fbc313b0e866fdc1f498.png',
    id: '2257',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78f988b5cabec93543b55e3595e25a65a938de9e314692ba3f671300e092ae27.png',
    id: '2258',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6315b1d9932d291065f83adb736b655bc141fb3387ecb1f41412ee3d906a8ac8.png',
    id: '2259',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c4a6a9c0232951ecfc096e4e433d85d0903bd76a2bf7ef1b56c65104de44f3b.png',
    id: '2260',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fb7b791c577ed955889b2e26e6a03a0fb73a6429b4a71476e6a163dbd3d8c05.png',
    id: '2261',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de064e4f76aee3099ad62a22a9ca5221349239ca742292d45a03c71155535a79.png',
    id: '2262',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcbf6f69e9f15a9103627df918d213f6c4dba80a65eff7993b088d83788df8a7.png',
    id: '2263',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/984b75fd8551cc57f2dfafa1cc0ac659b20bcaeffcbeed8f4c5abb415ed587e1.png',
    id: '2264',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4aa9e83c509de428cc7e98f58f75f67ed3d830015df1077d05bf2778bf02f1c3.png',
    id: '2265',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/50304dbaaca74aaf5d203bbfaf1b21cfb3d5e149cb7b213016193b5ea473cf7a.png',
    id: '2266',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17f7c9702bbaad198c89334897af0687fb79738f3d056d6bbfe412601935d4f7.png',
    id: '2267',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7195f5bf23eda8443945d6c5d4d5fa62f9ffe84f5da2ad90d072fa9c3c8877a8.png',
    id: '2268',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/750a4f21c6df62cb10cdfa425a347162e2fcd436d3fc80ea75f3956ee43f59b5.png',
    id: '2269',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d80e62f65fbe79985433f4ffb6c5ac4c97f698f8f86206319e9cb8c9de536ba4.png',
    id: '2270',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a387e863bf9fee87111c68a6515cddf38d789cc36c9e21516de3509e4f9d810d.png',
    id: '2271',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/521d37030753e9e0bc131dab479ff9e1c9b952e76318db330d999e8ec632e4d1.png',
    id: '2272',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c919f464edc045e3245df882c423b86e69e6ac0635869e22df04ab4065668fa6.png',
    id: '2273',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17d2c9ad9ddfce2599a446d4373910530c6ce875ec21330dab92604fb711391c.png',
    id: '2274',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Batter Up',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e0eed799bbc7630f01cda2106306eee2de15f872e28262237a34ec47d691cf9.png',
    id: '2275',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d22fb96676c461c21cba1e4a277196f1fe2ba9ee8c79992a2bf73f4fdadd2af9.png',
    id: '2276',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca963e3a1b4f305f7a25fdff70311ac96caa93a122ab87a994adf2c780fdcc55.png',
    id: '2277',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Tenderizer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6955e969a4da139bbe6bac74cf444dc3f94eafce5761f53bf608894c10c32ff.png',
    id: '2278',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3333e297034a14fd7a4c4a6778d074787740b7ffc2b94418f568d69b9de02073.png',
    id: '2279',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9997d3357f8a051d50d3e6a773b08f3a6b9d23259b424ac9751c0ddda52b0b32.png',
    id: '2280',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e9a61e659c01062d1fea9c0058e4b82c9400081960bc32840e338497cbde79e.png',
    id: '2281',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52563e09ab689a859e5bcd7aba73f85d4bfaf47fddcea968d6aa771e59ab1dba.png',
    id: '2282',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a068c7e71bbe4675b28a6be631e82f7c7d4c36c11891d5bd3658f0b7a3327f88.png',
    id: '2283',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2dc3840680dce5394a8403d69b6285423c2d23a97af9d4e56125a075942741c5.png',
    id: '2284',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebd37263bd3e47cd3e1650cab9f0dbd1dc6c364ac59d600121c9799683855241.png',
    id: '2285',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/444436f7845a1ed5778f0cd18016eb98ce6570ca9e9357e15d4be076aa07a20c.png',
    id: '2286',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb85e363eb37600e91ac486786f01e44dc70b3ecda9d25b805252ed522ead255.png',
    id: '2287',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/045f4b3331d75e5af0c822e2a46b6c25b16cf3c082326d46c7979c3b1d82c52b.png',
    id: '2288',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10c08db4094180425dd28d85a9602b4674c54d106e8731c9dc804cce6b1454c9.png',
    id: '2289',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/072eaac024b1a42e939bb828428740cdb6344ff368693257eb723102c43f1aa0.png',
    id: '2290',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e1cd5054332f184f933cbbf3f1cb298785426759954ed545add99747aba65d3.png',
    id: '2291',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7dbb9b68b75de9efc1d4eb4dcfb8e04e2ed986e083fd52fac70eb58507978cd2.png',
    id: '2292',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e810d8f093304d92f1f6fda26283f141463b1123b8952f8e418b8f32e5466a47.png',
    id: '2293',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c8880db67a5183ed2482cf8aa372183f505661775b764e4529daee8d17e1f97.png',
    id: '2294',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61962e9beff4bb228bc94632e6be789ac59dff8f7829ce9b616d520a6e7f7ec8.png',
    id: '2295',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/baabd796177617acf6d28ea0d75517c3455159db4b7d7a68cb8c5bd417bbfaa2.png',
    id: '2296',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1cec501d5f8950db206e855496c010b01827ba004171336c48a45c25bb932411.png',
    id: '2297',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/468ed4d4758736808ac9b562992236c09636be9b3e420ad6ce77178edcfddccd.png',
    id: '2298',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4e47cda5cd04d1f98941c5fae9586c1fd2b5a62bbb4aa566724913e2286a49b.png',
    id: '2299',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1e92fd96dbceba8aa2549e7eef152d942c1c09262bc2fb45207f0be9fd9347a.png',
    id: '2300',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f27fa8e4c0f092ef4763bea548a9256e12fd6b5a20d62e9f1d9d9b1e76a917b5.png',
    id: '2301',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa3bc7c830c0a920fe208b3ef6a039c3afedacdb0bd5f8651af020b74a8c1b08.png',
    id: '2302',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3e6ceb25eeece0cf3cacae4219021d521838acc25e10aee605b6dca7cf3788c.png',
    id: '2303',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4eb5ec1f173b4772ba61afae584d42e6c38b30d46ce9cf47a67a2c2047d46ea6.png',
    id: '2304',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70ebba2a4d564bdc4b1ee4cd8894eb6f768809280fb335d3d6837d2005d351e1.png',
    id: '2305',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8b160078a30054e45a880cf190322d2a95267503e6324ecec66713a76b891d9.png',
    id: '2306',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5571d5c1fbffaa3b169a27c6da4603477d3d310f72a465d7324360caac4c401.png',
    id: '2307',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87258b6f54365bd32d03c36adbb69bb60c5f45f95bb5253eec444f5b0bf5590e.png',
    id: '2308',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c087ddb22719935e5bd9944d595002cb0450b7527a89ed687ab3bff950f626a4.png',
    id: '2309',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/574c48657172ba4ffdde2cd33491422ab972508e0c21e4dcf53a6669a9edd347.png',
    id: '2310',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5806877c3b327395b8497aa6b60c3b31a46496345808ab3c4eceffca8840273.png',
    id: '2311',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0aaee2570d4dc53b1513763e3dd7ec7bbf22bdac23ef568a3473a07316900ca.png',
    id: '2312',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f89ba6830eb6d1f181c20bc31f962aedd8a1ed115b0360bd2fc89088f8644934.png',
    id: '2313',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac6793d0607ad0ab3f4e43e96220a85c4a93351a09d78cffccb1c868ff7e7bed.png',
    id: '2314',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39ed6b847df7ab2a5e4b1be30e56a6f9db0947c7bd289c758120702bba72ecd9.png',
    id: '2315',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/190280ef9832291588e431db7dac7f8054a428fe41373ae90cf7231d12add97f.png',
    id: '2316',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8843a00fe8d66e14995869ed550184bcc3f552f89dd0acf08ef3c697807d169b.png',
    id: '2317',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef5b724cc3ed9898963f54cfe6e3fed788b3ee4b35a4f23ac281865736d98d80.png',
    id: '2318',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59b5ed424b865292935c1da0fb9d865798fa846ab0afaa6174299fdc3aa0a89b.png',
    id: '2319',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df281988d46dbc3939f0876b63ca8d47725d019a4ae77c698fe5f72dffa554ac.png',
    id: '2320',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8467c15eecfbdc2543a4927dec1b19f350ca15c92a4ba2414adeaaeca1dd3733.png',
    id: '2321',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7141497a3def50c54ac6605a2b07f2b6e86c3b1086d329dc807cab1ad71f6057.png',
    id: '2322',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a5e7aaa4753e9db36b3eea687878d1cd32951bbb015f514fb37b8a3e88d5eb5.png',
    id: '2323',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/527386c00320ded9d68ee6df936a80105bb516c610393055a81c27fb79a388ff.png',
    id: '2324',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5b6775b2a0a11553a1de1f734450cff61ee1a89b3391d300397eeaf5d162edb.png',
    id: '2325',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a2e591ab5a69525e568a2742547ae866ae689574e2b435fec58e9d9c8507d48.png',
    id: '2326',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/deda65c2c0ad199fa44fef18f16b431bab7a73f58a13b6dedd875d5d84f46d6e.png',
    id: '2327',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62efaa1cfc61627b19c32bef55505d99f29639eef8cf3497930ce71764d24f1f.png',
    id: '2328',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8cfa4985955a0bc6ad1a26ca54b1d9f2e7a5a052983d729fdf9169b24039f1cb.png',
    id: '2329',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54a701ebb3403ebe2245686df32d625ea2a9c73707456696d4c328e6a73f41ba.png',
    id: '2330',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/925529e75e09df026c91cbaec3cd629c62f50d99251d533bb30a1bea3dabbb00.png',
    id: '2331',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b2f5bfa7736000894a85d9db3ed0f92b4eb6353fb6b1ecb238e1ebe3ba9871c.png',
    id: '2332',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6471d1659cb4c60cf0d5a5c42c0a287f219bf77e8dd93c8f6808d18a6005abd2.png',
    id: '2333',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7361c37552d62b846e4220db6d46aa24a71bf2553baddb92678bcbeded71062.png',
    id: '2334',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48b5af26c4cffd6c59d1725c3bb38d0c4a565a4c03b216911e09132cc52ec13f.png',
    id: '2335',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9940823a706e25ec6d9551da04deb79c37a08ee37250669e8e3621058962f5ee.png',
    id: '2336',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec7420d58c31eb97a9640ddf800b2b614a6021fc0beff9428b064fefb644829d.png',
    id: '2337',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Gnaw Saw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c7a03ea19ebbd12311a473b94d76d0ff2eb043efb67e4a604771ba1c3fa1bfe.png',
    id: '2338',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ad03e378d94af867f02a1623a9ccaf23eb997d0bee2e1508a10470636eb34ac.png',
    id: '2339',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc40ae22f7dfda2ed7fc3ac5623e5f295771647eaa5ad8651ee108fd80d7837f.png',
    id: '2340',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10b74940529a87da9b9b969b3bc71545d3bd229980631064d00d4d3fc1aa64c2.png',
    id: '2341',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/177116adca6cb1f517a9f7bae37cf39f881f253422d03324efea0b36bd8bd227.png',
    id: '2342',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d86f525a8d674661d94c6406c37b939c6d153ba27cff25a146a425b2194c59d.png',
    id: '2343',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3241b2d8dc0b2d374c221135aca235426c5d13bc902689f3d17c43fe4f3e654d.png',
    id: '2344',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5a133e5eb5c84da4611a4051289919702ffd490ca1bfd384715789dc1f9e111.png',
    id: '2345',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4adc7de905d608d5a20e12ec80e654842ce20a5e8dcbf1636c82f84b12888e6e.png',
    id: '2346',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1092270c92a1ce4133dc9c6429051bc7361f52f1216ebbd0aab29ef3663a629.png',
    id: '2347',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cactivorpal',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d913357939dfab205ac2d88f78c8d42d6e4831fb528ff4ec804710616f2eb5f0.png',
    id: '2348',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18f0495d43df744ac75e84795b52586aea0b962ae28ac538c6ae11096195a043.png',
    id: '2349',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b24adaea5052e437684aaf41d7d4cb3a3d650cc1901496ed0b77241e0e455227.png',
    id: '2350',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84f759735d8704b2117cc184aa75a49a1c904cbed3dd03eeca3a3f6f360e6318.png',
    id: '2351',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4720071fe97db82657a5485a5ec02f9dec825ac6849e6f2d0cc65d368bc6d0da.png',
    id: '2352',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89627c75012784a4dafed5d2010f92850ae6ef00dd901e59076e4903ccc50d89.png',
    id: '2353',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0181d62924af3d9aa75ae24737d9b8e3fb578d7ba240f8f9311ca9820811384.png',
    id: '2354',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/266b6a65dd26ae638f91cfc962e333a21029136c17bbb173f80f8976a1daa129.png',
    id: '2355',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ddcb89b17147558bc2647826173658280a47b7052c83410feb489a1a172650e.png',
    id: '2356',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea94fb73b6a551399a2cb936b933559efc7aec721dc4d4345f7d9808559885d5.png',
    id: '2357',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/872d069ac905b9d5ae1ffbb65c5f7b2bed8c30aca82b1ff4914b56ca013969cb.png',
    id: '2358',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4d1b03f92930e0e3810dab2fcda017ad973cff9d90bb9e8ca2b79838d5415ec.png',
    id: '2359',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/830d0e7436b8c37fae25fedd889958da3e82a1c0ef2c0ded8967be51be7599a7.png',
    id: '2360',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6dad52b98486c218cc39d41dab813e0af2caafd27ac5fa5f7d7d3610a7a3deab.png',
    id: '2361',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88813e87496591fbaa5e8ff2c471d245c01287a728c9b5678a4772c672a44521.png',
    id: '2362',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
      {
        trait_type: 'Weapon',
        value: 'The Tenderizer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b1a14a1459c6e225edda390c080693481cd0802d9e433f3254c5c2779613200.png',
    id: '2363',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba94b02fef6b81b774315db5116bd940656dba9fc6e36704578f70cfab733d70.png',
    id: '2364',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afc822281f74ce215efea08508b951c2a60fb892e1e04d229ad2e4c6ad10e7d0.png',
    id: '2365',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/252902249a268ddf98f3b2aa79d2eb0fb4eb8dd0f034e569f46565c65340660a.png',
    id: '2366',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42566627b10c62561f0202aeeeff5d3d0b82e72e7e52fb236796be47a13dc339.png',
    id: '2367',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e845f7107255a0774b9302bdc7c94b4f3c7a669d08a933508e1fc1bd47875658.png',
    id: '2368',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4315aa7010be97e1622effe55dcc1b3af1d9f4dfcc3c75288d78ee0f4c21e2d6.png',
    id: '2369',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ceb956244a9ba7eeec429f8ed7164cbf519c5e448857bb71bc9e0e9032e7b7be.png',
    id: '2370',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39e10bba0fc017941f730024d23bf35ecfa53807ef44eb8d69d0b3a0369087c7.png',
    id: '2371',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/085972e0bd783da78d1d84f54e08e9e8c26c13a51492bccbb80b2f9c3409b08b.png',
    id: '2372',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9988d6fd872805b4ec3b3309457fde8dab5a5b5fb1f24c74f915b016f54d9c95.png',
    id: '2373',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5d42e28bdf741acb6eee72596472c29ca82c28ce0796417459da05c7c6198d2.png',
    id: '2374',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a972268e371e09638a43f77492e89b49e5e175972c9d780beb951c2aa5adefd0.png',
    id: '2375',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f14d028795d65157e1e0e08b8ee65688d716371e35262ca8a48e8b2667bdd7d.png',
    id: '2376',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c2f83ca34b889dad6d1f7b63230bae4f2162d848a28ad19bba77129e6b52bc8.png',
    id: '2377',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d7e96c5b34640d6116cc120916a29e197e7c66bd956afc7a34218d4e700eb97.png',
    id: '2378',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98ef07a7aa2320b7e72c3af9f6c53d695bc15aaf5d4a3700424af4243edfbe2b.png',
    id: '2379',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8207ff78bda1c3af620d3abc353019325205ff9d6c2a50b708f1e29d49ba3cf1.png',
    id: '2380',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/496b6ad66c684d9054093638e9667d8450cacf421758e6aaaf3ccad76613f1b3.png',
    id: '2381',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09c458158440d672f9696909028136b174b1684bf239b36d4d3797ac17a94d2d.png',
    id: '2382',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56cbe81296d1c9203ec0f052fc84fc6336478e276f42af985969edde98055b34.png',
    id: '2383',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/edb4416b0bef55712c988768c64b4c5054968b991aacfc04b8ff743a7857e750.png',
    id: '2384',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a63d2277463ce9a55449455dc52cf1b559ff2088e73c8f91fdbe696fe7da412a.png',
    id: '2385',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11d504c759a218686f220676f6153b34e5bfd6ecb20bae25978fc582a7053465.png',
    id: '2386',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63163801e64fbb71adf298a339ef900421cd8dea6579d29243c817683260d1c9.png',
    id: '2387',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b86cc5aac0f7fb674b06666dc97ac9f8b3ae7f9cdd9a0824d628442437349c2.png',
    id: '2388',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a6c49dd5e23166dd7f78b0155495b10794f33b31852b866f9f27365bd92e27c.png',
    id: '2389',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bebf91a3f5746b78b50dc0aa3c0849e7e32c042bf85be6069b97c70ebc367f78.png',
    id: '2390',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b88226dfbdc87bcb010a19aa7b6fbf1ae400d33a6b86d0fa119687b6bc6a9fa8.png',
    id: '2391',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7a7cb7f47d776aaa007e3df650a20b97de62efe7187d16336f399bdb53591ab.png',
    id: '2392',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4cc0b76dc7bc87ecad61aa1624b8f943c9fbd71922c916b8a14bb9436d0e6943.png',
    id: '2393',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1e4d5152542b4b83c1f57ea03433614269e070f1423aee3c1ba1121122058c9.png',
    id: '2394',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4403f581c32ca01bf2d9d7824eff34037326df7a547317ad76b853c8cd214245.png',
    id: '2395',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c041d6c3172f0ffa1d254bd9cf5726399da43b755bdc0a3697d99ac1a4bbad9e.png',
    id: '2396',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d0f58660a6bbcad4e4e7a26e94acd02628df95221ef81757b45ab60864221c6.png',
    id: '2397',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/739469e5461e266db5037dc6fa4d059e8c21ec7e31a8afbbfc44f7e88d126348.png',
    id: '2398',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d6ad5551248c27e91cfe3a7fc6c527ecc26a0f4f7e1b4d1927c0c1ee2105d9d.png',
    id: '2399',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef4f0d799c84e8b7c5d3d3dfd031067aceb316abbdb06b78af0af189c7ef7886.png',
    id: '2400',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b1cb8819be48edbf2682781845b9dce131709f070f77f6cd2c299bfe2e3a1aa.png',
    id: '2401',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/097005fd16e521440ff2bd9fc6fd64c8ef02b4d0081f521b0b20926691a99e22.png',
    id: '2402',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a820b25240658cb9e3d2760c093dc4472f688a3fe4e2c66b0507b3b3f6467f52.png',
    id: '2403',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a281f05c2068aafd4cea92474129ad70f5e1a9257df8e22152b7f7ce5bb2f2d.png',
    id: '2404',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a414ddc520850d792e464605a8da5f67e2724fe1486177b01e0738071ac4fe85.png',
    id: '2405',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf95d7069f17f98a2dcfd67073176d1aec4c41357c7826835532a57dda4d8899.png',
    id: '2406',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f010b7c2a20f02ef874dab0c2a3febc30bb1394075c8480dd4451a7160b1172.png',
    id: '2407',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c42b4a3f9527c81f34282a63b39961103d35c601a6c625697008a9b2f43a583.png',
    id: '2408',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b42ca9e90dc757c39b1a46962a8d342dc5f0b3c8e74ba75fa04c1aa12546ca0d.png',
    id: '2409',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0869949d72a52a0dd52252598a875bb4a4070352f2ed8f784bdccebaf25a7e2e.png',
    id: '2410',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a80fedc8deff613c7734188f9743ce2519d949946aca817bdae360c7e7ca92cf.png',
    id: '2411',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/219a961a6351e5212c9d0e5dcac5ec43e0e513ba88cac00fbe1c808e84117b39.png',
    id: '2412',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4050899c9253cac9b197fe9055d4129db111de6146183aa6fc6ac4d1dab6728.png',
    id: '2413',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2f1cfb108ad1c06d9f42aea340dea8230f7b1fefa2d9a1fd4a561909e189c2c.png',
    id: '2414',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6568d46bf7f124b233defe0c1a0277778e43b850489552ea916a0beea3da238.png',
    id: '2415',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bea98563d7e7d7291eda96a753ee2229fc1af7dace4569c8cc548f3f800db1b5.png',
    id: '2416',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5bde7a82b26f34aaaccb2e906cc8ff8e3692749bb190b771e6481010687a801a.png',
    id: '2417',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22d06a23a77681eec0c695c8183174380bb3d37038f2cfbdbc4972a154524c49.png',
    id: '2418',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a6212735742bb6d911cdbdb7e3edd2177b918f369c819876d337e70b72fb2a6.png',
    id: '2419',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cd006f9d3903a56e9cf656b70d2b169afb7546386616f6cad0fa5bafb0fe016.png',
    id: '2420',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a590763cb83a993403bb69a655b606aa21cd2ab5ac3d62e37119974805e3778e.png',
    id: '2421',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/611c4e730125d5f192990fd1f0857ff7ed43cab562db1fe4f4ccfa7c6af86f2d.png',
    id: '2422',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c9502a9fc3a88d9734ceb39eca8cff4be983f385b9563ddff5f3b6cd4f888b1.png',
    id: '2423',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b46bf289325282de27a3e9027a35d0341244f99f7e2ba51c07731d1ef5af1977.png',
    id: '2424',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Levitating Lava',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06f251efa27c3b7fa7ac5245972d60a6cc1a71ac92bca7a379a9af1e9e7a567e.png',
    id: '2425',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0dd13966d5835e4872b3a23d01c716763722842d01416d561aa8ae1b3f06d981.png',
    id: '2426',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c84c02d3a6a68a502919802fd7f482bb4ddda042dd9b1725c263a4566b142eb7.png',
    id: '2427',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ce5b1fa255d3b614b3ac5ee031477f5d684c5c2d88b92a8f9958f40712593d7.png',
    id: '2428',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31895ba22edb82cc8cf4bb8d94a28158bba29a1150c827c40ebc67048dbe7cda.png',
    id: '2429',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8ecf87d57776334c7afd54933ac1e976777fcc3d96e046ff173cbf49120014a.png',
    id: '2430',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd22deeafeb3e88387e36a656fbfc07ddcf389eed292886a77d91bd07f1bd88e.png',
    id: '2431',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4ba94a230182746b8083000425dcbb0a6cd385816cf953e3606a70d45fd10f4.png',
    id: '2432',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd8be89b0424e5defbd059966120e17487c60cabb3a6c411eccd004d821299ba.png',
    id: '2433',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Batty Spiked',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/796021121bc7da01c6c91ddd906dc2880290eaf0def2fcdf92a3083a8622ca06.png',
    id: '2434',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6dbaca76e19462c636b803b7b87fc0f38a70c183d2f7c5383a003de12c5ae4c4.png',
    id: '2435',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/848f78c9ab855985932706390bfe1e1af54d6d90510f3c92aaadda40f19d4649.png',
    id: '2436',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2690ab591d646898fde3992fd0d23a0faf03f8b309f0934a3a9f2819fb894c90.png',
    id: '2437',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6fbf08c7bfb1688b2cd57840145a4a3432fed37ce4382598b0a2da3b1598abb3.png',
    id: '2438',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3599c4b972fd47a4b971114931a7323cc41aa758c0eed0ef20e8a39b40935ec7.png',
    id: '2439',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb9ff7c849b967bc4a757029af9d0c21be48b7c60eb203f9cc3ff22b57c98620.png',
    id: '2440',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5166f80cad71f23648a3fed999461b360c9b412f53efe8a0d52d1d6f4494b27.png',
    id: '2441',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6a90746995f98f1410534aad04eda049299b9006f15c7dfdbe47774ddfd8dac.png',
    id: '2442',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d384ed286e7fc2c14896ea0138deb987d0ac0dcf6bbc9d0e44a1c36fb362bac3.png',
    id: '2443',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20e378d93d9e0479cde38e4e4302f84be79ba6c20d72707bdac20c0200bb3847.png',
    id: '2444',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6317c5ef6cb7f61f153443a715ab2faf1ae07a6684986199a196444fb03317df.png',
    id: '2445',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07d3ad93bb4e16d98417e1ed2ce852e7f1225701755987a9fd72f5774dc884b7.png',
    id: '2446',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab6268c051e2424a94e8e73a661fbe7c7628a44ef5360acf21b7b2f9315016e4.png',
    id: '2447',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57c86770505aad6356232ddd6a9bd3e548d68ce5aeb9897580f71295ab2c0ed1.png',
    id: '2448',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6cc81209c1f0e64e623997e1db19598696da84fc128254901d8103adea920d8.png',
    id: '2449',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd3e1537f791bae68878338f8d66517f821b34234b23f25f97dbabfd25d78cd8.png',
    id: '2450',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b69d720a0b5e4fd0c8c364470e8bfb921ccc76796be2d1519408ae8451ba73c.png',
    id: '2451',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5783e6b57dfebc185fc29c46e2ed94d5ff91689a5d6caaf1e1333b0b3e9f5897.png',
    id: '2452',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/052ab99cc3514b502cb8747e5d74520ef5b3a8543e98ec2eccdcbd70b62eeb51.png',
    id: '2453',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f7b592e990f5b9944ad7ff619dea630a5e76e02fcc273b8c506ee6ad8f242a7.png',
    id: '2454',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e57b219b44d1d01784a172aa74d356d998f85cd5a6688af307039d50a1e77232.png',
    id: '2455',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9dd249141d1932b0558ab85a6b739a40b0b3106456e633a4fef55ca8a74d03d.png',
    id: '2456',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shadowed Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea859ef8216c985500a073f5cce345f86cc80bf9f0195e69a99fd15807055fde.png',
    id: '2457',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/956b464556a5a173fca7822b25253eb2e555f047726d756ce0b0627a2d98136f.png',
    id: '2458',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0332cae16d2481b60add1333b6529d6ed48f94140d81ae4d5be31f6565f70b4b.png',
    id: '2459',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/792a52aa2bce30e588b845ababd19c430af8471d42499c50d83e5db6b6b61b43.png',
    id: '2460',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/879476f19cc503b3f83b98a577ca8acb0ece8d7658bde5c83ededb1c4c0276e0.png',
    id: '2461',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Adventurer Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/679fbb90513e063d2aeee148f83fd10ab2c87fcd3a30c6fa53aecdbd28e23ee9.png',
    id: '2462',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0127c1b33ddd3c004a5595e5386ea6c7cf62249800afb604c0fed8992acb925.png',
    id: '2463',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfc3f10d06900bf731b8028c6295ca1a5a8963f5cb0a39739abfe296b30fdebf.png',
    id: '2464',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/759916e544fff1e2317bbf41b47091878ad7e40e7f11c5e41f23f23a2c323582.png',
    id: '2465',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c073f169607763d7e9e522604cf11ca9a0ae2abebbd2b53bb760529ebddf0ce4.png',
    id: '2466',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f00599f1ded3e2ba6316aaa9f731b7ea82f562f5f3ab007524cd984f4e471c9.png',
    id: '2467',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ce99c5eb0ff3b0724b3c0ff4e9740efb45c9b2a3a3d36cbfa4835483c5b3478.png',
    id: '2468',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ec7e524f845bc9bf8501659fcd288967887f34350fcbd09d709205f57fa329a.png',
    id: '2469',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48384403da44657359fb224df426ce0aa08b31ea2c3165f0877d5b3a4c89d99d.png',
    id: '2470',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5fdf6408bf4232fa8444657c9b21a3ccb17d86388db266b8cb68f55e6075140.png',
    id: '2471',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36da2c626a77d2e26841b9c683dcc1df7f77f7b2aa67f8373735e5e26561e5b0.png',
    id: '2472',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea8aa8e027c56801082098274ecf6ed4faafa8969b6587ee3c7105d8f5d6acb9.png',
    id: '2473',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55e6f3b9072397ef21abb8adf2b123f49c565b8088b4d05094ddb5db1aa07da2.png',
    id: '2474',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a43532ba3b1dae2e41d4c02b02f624075ddcd0c92de4184a3dc8e8a202a573b.png',
    id: '2475',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f0c8f28f88132c51c21776fd2f5bd5bf3929202dc165f15132d2f979fccdee8.png',
    id: '2476',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00f893e31cfc00cbafc925b1346ab620d43f89730c354d7ef5b13fe150a3f837.png',
    id: '2477',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/143d5c5a7a7143ca7c61bc70588d28cde82036582b8b0357d3da1696a5bb0922.png',
    id: '2478',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b55b40e2c1103f3a2b9c0ad6ca528a7bebca83e27c5c4b7a5e3c303f3931474.png',
    id: '2479',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ceafb706d8bf4ff25419ca8d6fa2fbbad390f65ed5857d032cbcc267bf570f22.png',
    id: '2480',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/364fae9d03a2756b32eb8b27e4c5306ef0772ea5b094afe198bfb5236ece65b5.png',
    id: '2481',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16fa854d7a89201beda6f200c39af408ad0e4bc79e73e0cea2cb87702063b022.png',
    id: '2482',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/126ca19187fbeda43a49c7e285128237de24882af6add4ed5a3a66bc2653188b.png',
    id: '2483',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8caa934ec137d98330dbf121b8ed7e9c0d25c3125b274e983bd41512c646e291.png',
    id: '2484',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a4a5d432d4c39dded4545ec0051e49df996047452cbc973e707b05900834b43.png',
    id: '2485',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ed07ea3f37eb41f27cde4a9118a07c03157088d604a22b95eb9d78d279b0fc2.png',
    id: '2486',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab2b0a966fb76a9080887ef6ccee00dce9a43ce0ef2b05e2883b6a779e2c1c48.png',
    id: '2487',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de72167fe805c1677dd0415abc52cd8c2e4298b32ce4c376a469dd80966d61dd.png',
    id: '2488',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cccddc6c444f010eaecece31ee2dedab558b6db7283b0093873b124dc6622151.png',
    id: '2489',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83ab79564146d8386c0380d4bd837af986feb48443d53c290e3be370838b4003.png',
    id: '2490',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38a6d4c258204d4b0b8fe781671a32b1b7e6072b4e063a94e242060e0f3e1386.png',
    id: '2491',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/169e5b99bc2da04fe4ba4be56fb8d2b12b84859edd3f87d1ee5dab36ac96ff4c.png',
    id: '2492',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93aa6b9e2fcf21d2fa8c3e4da396b40b3cd919eadd0715c0692527e483bc03e3.png',
    id: '2493',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03130bbd2155cb659820c1c23637369f18e7b6d8513939847c8f96bbe534e907.png',
    id: '2494',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd1bc0cc9fca7a0da72debbf162f84a0ebf0c067c5d756128574398f733a0ed8.png',
    id: '2495',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ddad9bb8a22fdb64359478919b8e2b32901f158317abf548ac27890cfc34eb3.png',
    id: '2496',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0431bf672d37e52262f6167f9ac88c3a479d4e2000de870b2b8e6d4af43dcc5c.png',
    id: '2497',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16c972a9c32eb2477ac01ae96585ba502722fbc0fd9e646036669c857be28e86.png',
    id: '2498',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea0ca09e6a99d1dfe26a6f52549639bb14930d07298028272f5be5f9e0cf7649.png',
    id: '2499',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78af6f58cae305ec42ab3efb70397d824b16dcfb2a326b806f727d0608561244.png',
    id: '2500',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df05b1d3eb0351e6ad1ceb99a3e7591621ee3b0be9f8e0167ebd1f55a9324562.png',
    id: '2501',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1fe24f85e8b1738e136385dfd5bd937df580bf9457ccd32dcb2ccbe27d0b4a2.png',
    id: '2502',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5565a0878127e3dfef0672ce9b6a90ba5525f7a57f45c801a8a4a9e64f58094.png',
    id: '2503',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a6d003891f37e93ca9f1c9661b481ccd637cba56ef1ee279eec14aff69783f8.png',
    id: '2504',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/475d5fffbf0f651ffde580178f7d377935e8ba65aa16bf3a493363d6147a6e44.png',
    id: '2505',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8707097b5388124bc4a02c4dda70df6a3435eba443bd4df27cdb4eccfca709a2.png',
    id: '2506',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7baeb100ae7f1e081f17af5422fca3cadc1d6299966ce43d88872e4d879bd799.png',
    id: '2507',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a18668e440dc5ba26f2db8b19d6972163129aca595701e96b5b74dd708f04fa8.png',
    id: '2508',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1db994b8c3fef3e54147ecc6308dbfcf5ab061d799d27ad0ae06abba2321c20.png',
    id: '2509',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4956f36b4f53b0f92cf8a07841a147a79e5c551d1bde5c35d3b76e864dc04da.png',
    id: '2510',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f19771b3a34d9c61f0d1b99b2c0f17df6e3c00fdd5827f04d96f046c47b24c5f.png',
    id: '2511',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6e3642e6d99fd1c38e43b74a2d12af0a9b3ef1cc4028184f315e8db21a75228.png',
    id: '2512',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da59121990c74b9efb572f609b38eb27983afd3d6128ec7bae7be847cd34170d.png',
    id: '2513',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/282b8e4cc3a26574e20b9e3edd0a55723db5d980a7f106cef5a98753f75495c0.png',
    id: '2514',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/517768e5ecbb5beb82bfb5cdb80f9c03e9cc967b37de5b4e35a7e92fdf133b8b.png',
    id: '2515',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb78fbebee9b4bea84d8eb6dac305ddde9cec316bffe5fd75f0008aa60b46247.png',
    id: '2516',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/975464909ebd4ea05101c6d30aae3a3783829907b62400e5f5c55b47965263bc.png',
    id: '2517',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/700b61a1b5f75939cd7cf83e9dce33ffc17a1c9af875e2b7f37083ca571248b0.png',
    id: '2518',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bebf9f9228f9af979410b0beb4e88969efc0d1831e88e178ff2d4fdc00e2a7f.png',
    id: '2519',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ea738641a9d9b3a0892b514814a26df365d84f38ba0bab00eca8cf5144dfca3.png',
    id: '2520',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9688754b38165a566b340151b8b0349e34bf70183e1ad4b92599771963f0e624.png',
    id: '2521',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0afbbd9145bddad919e3accd9442f89fb876a31e0b2672880ddbfadfed6b576.png',
    id: '2522',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d1795c6d81f293951c9a15358689038354f589fb39cb6fd4c9b4acbfe9edb16.png',
    id: '2523',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f833fee0253441577fc7b07f527d4ef6a4f60ec53094f31f93ac62edced5ee2.png',
    id: '2524',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97ae8cdea4151a9ef2bd58df485d38de13641fbbda1c58b644afd40f7b21f343.png',
    id: '2525',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b15c5f4107b378ed23cb5f355a0dcca63af1e4949f80ab207518eb4a92bc022.png',
    id: '2526',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de8a246d20fb2a68d27a22267e3ab8d9e86c260e6e6c6012a55aec8b171d5355.png',
    id: '2527',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6813b7f2cfc8cebfdc74fe643b78daea47931ac2e05e5520ea61b5034f93125.png',
    id: '2528',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da61d5d71b90756846e0973ca845f87a61ebc3e58f377f38b742a23b7ba9e8cb.png',
    id: '2529',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7f82f62b52adf7a4b1a512c12ae0c478623e455c73e05c5f3c9f0c0a46c8802.png',
    id: '2530',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0eb9c2a11495a9a38fe613c9e3f8865f5fcdb90f1048457926888584425a7f90.png',
    id: '2531',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99db6891ad3836124b77b61bceb5f20d24eac2e06aaed2a5227a2a44d20dba0f.png',
    id: '2532',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd45822b2ca2a3fa63d697a94b3ed2db7a5b22a5af808ed9caeb99961edd4ef1.png',
    id: '2533',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f809e508af255c74db9f2cbe7a8b9e154cfc61f0f39c9541cacea65ea92df8b5.png',
    id: '2534',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/227f76fd3bf792da4fff5a366bf13c1eafba62adb11d8e3c5edcc4489d5cced9.png',
    id: '2535',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebc49d6aa91e5d906fe46376de4a9de51eea38cef886c2c361137b01aca9e9fd.png',
    id: '2536',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e294a546f9609aeecebbf48997d888a59c220ec6f815b2bb557aec168e8895db.png',
    id: '2537',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb11637b89437713b08c7a6d25a21ff00e666445a4ecf8acddfe00a29d03013b.png',
    id: '2538',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d6b20190f71603df9e455bc2cadc2f98caa48689a543720e88e99a6709236f2.png',
    id: '2539',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6702ab133424759972f0bd4585cb8c154fd8566c532c6c107f4bcf7ee64de18a.png',
    id: '2540',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/80a6d658ff6873ab9a80bc8596bf0c876ef78c945afbea025ca29aa9d57e7059.png',
    id: '2541',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea324968163e14040e04fd2b8e2c97d3caf97d9a276796812e15e181de9e095c.png',
    id: '2542',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/678d62c0cc6ff303a6fd2ec986d0772db3c5e12acbbd9d5acd2aa6f5239eda20.png',
    id: '2543',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3881a96b0afddda1800711940bbac0b9c941666cd87b2ded99bfa06026b84a7d.png',
    id: '2544',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c94a2d8e52e079e6f4f910a87934931e1de89ce080b91004eb3e39d2d1f3c5b8.png',
    id: '2545',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5cb3d6691c8eaf9fbcda8df4044baf7d52d0590bd961abf541b2268853bd98cc.png',
    id: '2546',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45f95237f66784e90be5828d90b71123882165c26e067a52fae070465c26996b.png',
    id: '2547',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26bc62929f8a5d27f7a52f4044aae9b422405f4eb3d4713dd9e0ab1e7b8e23de.png',
    id: '2548',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e06502e9a03f3fa97c829337486c54b76f372d7f98b918f825ffe9687a151eb.png',
    id: '2549',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d8ffada7702473528ccfa7f488fb212138f500ecde02678ccb85e4049eefb6b.png',
    id: '2550',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb4cc00c477abbc1f0e22553141ba16bd02a6b9a7a5c4f0862736528550b951f.png',
    id: '2551',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbc09281cf7c7d55209dbbbf21a4378932bcf0e49cd70d89fd8cf0de28c1f8bc.png',
    id: '2552',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2a0b9732fedb78be5a4a76d9bc02f4ec2efa8e5472defd35c8c038b71d220c4.png',
    id: '2553',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8dc9f976163d2c76bf24d93d2d32de1d4891b314a0884b673f57a44d5fe61851.png',
    id: '2554',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eae76fba4b566414de7b567cfb8446db576a974bb1a1266cca60cf7b08b1462d.png',
    id: '2555',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a8f29d94e2a56148225ba849b19d9eec2fb30a98b49364d3394adfd08d35f2e.png',
    id: '2556',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0d4f03d6240e4aafa9b7b0a4653bb766deb3c6b59efc460fbccd81718b73c21.png',
    id: '2557',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/125180e815245524f7d03d475037c3046ec57d002b02b5b1985d78723b9f5758.png',
    id: '2558',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/000b3314ca7e3d291ab28946ec9504941a509e40cef5ca05357d2d621f828ad4.png',
    id: '2559',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/289be828a7cd592619077b3e2e48231a6b4e1e0f46debc233ab8f82b5516dc20.png',
    id: '2560',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5535911fbcecd8a865d7a6bd223b489dc79298a3d6817b64e6ad394253c8a391.png',
    id: '2561',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9318dbf4dbdf10650797085aceb222e3ff27ba7baf066ecf2cec64112e046fd.png',
    id: '2562',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2acc4703513447846ffca9c17b47c078145f758a1f8f52d62a20179c555506dc.png',
    id: '2563',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee15b78c0c14c7ad6b4708a033ba37aacbf7f403b2264b06e55b3affd076bebc.png',
    id: '2564',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6c2798ee2bb855a29594e9df3fde066293ab6e1433152006da756a75f9782a8.png',
    id: '2565',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c65cfd060c3ece078a03d9eda22cb0c16fa0348a374505cac3c27c2f44591729.png',
    id: '2566',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b68e834d750ba34c9ff6fdd50d2840918458122b0c47ef5a28fbda04cbcadb7.png',
    id: '2567',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60f60f0cd18f2fbcb7572e681e7e8439216ed9aa73dfa061a96aa57a8cbbf2e3.png',
    id: '2568',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/174382344bf3ca901e454d922918b23df9383e57a2fd4e1036961b79b4bc7145.png',
    id: '2569',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d156f4050007119f2026f136480db8f8d249bf41ddd6ae4deb3517961ebb2c25.png',
    id: '2570',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1fd179868628d80a50d17eb5e8e2a60768ee6548f213e021f7f6aa721dd1c82e.png',
    id: '2571',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/249ffbab707ffc258aed59e96db90d336900c7245cd684651b1bb3c4fd098a49.png',
    id: '2572',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6632851961a88facde23f7c2ba07e66dc059db39c1de8df5ef9064d610c45889.png',
    id: '2573',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/264a7ca855724f6f68dc2244e55ccf2c8f9b991cde23c59b911d15ef3fccd7f5.png',
    id: '2574',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69bf9aa01b69745488b9d01380b38f83a134c0f099908099bca8df9206d12630.png',
    id: '2575',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/946358fdc1d422c5379ad3c43f43e22b47301ee209da62e4a9784c3647bdc8b5.png',
    id: '2576',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9838a9e886d2c7fc7113ca456577e6b515f9a3f355fb9f438056e5007009542b.png',
    id: '2577',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d715224dc65fce2be308aeff0c86381792438a4e732a4692d100f476bce1e2d.png',
    id: '2578',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b3ad50489ec134d5f4faa224b2887a6b2be5c58aa15bc60c96a8f9e67f53ca0b.png',
    id: '2579',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b849286b85fb421651153bb316019b83c7c7406d6cae5c5e26c86d4ebe8a0dd.png',
    id: '2580',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/655a034a33505d348e874cb26ded4beb7cc6c88b9d505e3775049ee81a0bef1b.png',
    id: '2581',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dda0403757ba7e4a0f46fb766515e232684431dbf27771292543cec7fcb6f327.png',
    id: '2582',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06b1d1871b3194c41dd271038674fe41e8c13905a7f76e5cec7fb2e6c8571ae8.png',
    id: '2583',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/852b72e8ee4869c3c890cedf847d47c353db9a41ba0eac7f986c49f0f3c04f39.png',
    id: '2584',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e81830bfcf7c9bc6239f3f4277ce7ab62ebb93351de60fc1c1007f499170bb44.png',
    id: '2585',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d867203fedcd2a982ee0817e479d3ae8cfe8e4d54919040b3202349568788e84.png',
    id: '2586',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a65858c24d190049865070fceb9710a12cc2c8830912d8667a04d3367e28754.png',
    id: '2587',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/489a239cc4ea1031b68d58c22018c96a38e6afc862e66f577b30746b738a8c58.png',
    id: '2588',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81aaea2e09ac1ecd9a0b96b75d422e2e4c4ec9194927667e07942624b14f5ae4.png',
    id: '2589',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cactivorpal',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/252b407ecfbe65d9581cd63feeaa4625305d5bb66b5b71e9fe879aba7aa4edb7.png',
    id: '2590',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2879e807f6b1429bb0d186b86ad9ebc655d9b563dcd2b0b49ba9b7e1171ad51.png',
    id: '2591',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b9a29f5070ba380e02d3d11ac92b09963d417ce44fa46054c537ffb4263f4fc.png',
    id: '2592',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4fb3f45c09db75fa6e540396f1b37abdcfa50b500ce856f49fc2877d1895340.png',
    id: '2593',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e47aa99ad9f5dd656ff15beeb51b2670dfda8750e7d77026b71e6f7fa8809b9.png',
    id: '2594',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c80f56c1bc100c2fed5f55c5645cadb7ec87fd83b479faecb7feaa3e6f781f46.png',
    id: '2595',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4519887f1bd0c4cd9944d5f112b206bdade2b2af80d110488831096a981c269.png',
    id: '2596',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88820ed3120d99c0635b0fd12a527a916200b3cb69643cf4410d3e6a96fa1c9b.png',
    id: '2597',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eea79267acc4ef8582da3bf1c373bf07692b0340f8155fba9f32c959b62374e2.png',
    id: '2598',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd50983f5d4cbde5176652c831905dde9624ea4ff7fdd41f6625611625344e64.png',
    id: '2599',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5996fe16543a278f22fece9db930851a6e10c9e63e8f079af038ec594772362.png',
    id: '2600',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c38e0e7d52e584c67ee31cc101f05f7f0367e6d614cc78d463d561f2a49dea4f.png',
    id: '2601',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff0535467c93852652abb4d687728f4ce3ead902953410fdda7c1f94c3c0bcef.png',
    id: '2602',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1872afe77d1f975a0dcd696736b0633c64e24e29d64a9b9096707ffc843a0da.png',
    id: '2603',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/628395536f97b0ee91738daeded090df83c8610794643b5f4507d9eefdeac5a9.png',
    id: '2604',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48b98197ab8de98be26739b85a5f97f2455d371c55ac228965c89ec64586ecc3.png',
    id: '2605',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbb23627f12d43c6eea20a515f9748266d26123cc441eefa581a32fb004707db.png',
    id: '2606',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42f1e5f79f06ccac57754efe7ccee694e09aaf313648a00c577f61163592caf1.png',
    id: '2607',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1ec81a8c20bc55be3808b41c71faff2ed14046a0c1805aa8c356368c951e441.png',
    id: '2608',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9043b3064af4dcaa3913c74ee74f43382e023f49dbe00df8e6044f68e5b2e539.png',
    id: '2609',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d301b5e1d3ca25d7d6283bcd08619f96f0a378eb1a108090c2f3ac24b9c8682.png',
    id: '2610',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ea1ae956746f4f160bd60996a2e30dfec9274afa8fbe4cc86b2789a50345b70.png',
    id: '2611',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea089d40a69c4b4524104c495e59ca4e31387bb33e5c93291ae27f1b0bff18c0.png',
    id: '2612',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f51173cea5610fc0dc28745ab80e98a73f7c1351318dfc77fdedbebdd1359e27.png',
    id: '2613',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3f7463a3ec772d9f911f14fc60ae06012527d74c519dbb2eed6048e2db880dd5.png',
    id: '2614',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9a932f09767b144bf92d215c9e9728903ea8048ab537efda347086b2be6813d.png',
    id: '2615',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9857bccbad62b478588c803c352c27c165594905fb44dd05e161ba24cfdadf70.png',
    id: '2616',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ffc17b18cf9de021fd7b80b8b06c5f39b4d6fd64a985481ccbf718818454f61.png',
    id: '2617',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97fe269fe49e7ca7d61fadc7ae55aa6aa25d581e9f450b28d91f9d072aecefc7.png',
    id: '2618',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e5e8397489c19dce8da7bdc7c38dcd0467a13cf1f8b0f282170ac09cf4182e8.png',
    id: '2619',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/090e8cade2911d7fed9bea5852569ae7c4a7e05b47fbbe3516612947cf68d5b9.png',
    id: '2620',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c89f9775291f047fbab317059f05d26b44f202e7b759d095c0283c3b96ca54a8.png',
    id: '2621',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cd24b70ce3bb972612cf6ec48cdb9282646b334ab484d21d53646784560d053.png',
    id: '2622',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11943d80246237e5e96957dffcafeded5fba339611f9333e78b862ad06fa54a8.png',
    id: '2623',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/871ab5a383b1c410b02df795a3976e00f9a5e9d4e1165dd5f7b46c25ce7fb308.png',
    id: '2624',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74c6fd9e48d0221c7b8334fc57b32e87e83ad3efef8124685cadfc47895ea05c.png',
    id: '2625',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/652430ce56ecd14e78e6f21e7206b8969c0290a7a32e00490bb3b85b542cca83.png',
    id: '2626',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd731bdd4d1ee04e3b00144ca688ef182e39b40f29838a80cd3751407714c07c.png',
    id: '2627',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e11015ce641acec5f2b8b55e29ed715bfd3433b5c15295a139cde1f804f5d361.png',
    id: '2628',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0600a996f3df8fd95a766b49f3ec8605460aec0150adfae7be0538fdd5524d4.png',
    id: '2629',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5f9e117aaffff2235bda051fc83a70f53a284adbb67c6377aad71ad59bcb8be.png',
    id: '2630',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6628af103f85ae9f2bc38814b00955b106800e05232c1a4bfe0a7c7aab94473e.png',
    id: '2631',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0629e022e69c363995e46455fb6aa50e663781d0a0305f3bba2ef10afcb41827.png',
    id: '2632',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88dba7125fcfe85ed99f67e60b64c85fad5c0bb9a30910621aae90aafd8b8e21.png',
    id: '2633',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e05df82e3931c8bf367461570e988a0cdcaa3aae49c65e63945826a7b63161f4.png',
    id: '2634',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04b86ce802eb7ced89aa4709c1626980fa63f5d0667227f8b79e1afb2d7f7df6.png',
    id: '2635',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5efd630e1804355b1149ab153fa51eb431fdc947e51b9be7e5c98f58530175e9.png',
    id: '2636',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/616017557080e3e1ca3eeba9f82c8fb71b2bc980f67ab1fec607ee7c19c72efb.png',
    id: '2637',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d6437ee08f1d1ef830b2ba072d6ff7092e247f87dc9557105a766079bb797a7.png',
    id: '2638',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shadowed Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64610264c4a6296b07838e29b4f6fee13b9eef7a23ef430f27aefb1b898892de.png',
    id: '2639',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8088b05b32b45b58fc363aa19f3e9670412184ef6aeaa9910901f52c922fa3c8.png',
    id: '2640',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81abd32d7c9e4fa93c466af31252c2dc3252c9a827db37584b3e11ae834735f5.png',
    id: '2641',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07fff30943d575244133d2e05c4413403a88330e817903bf68a39c93b54b4340.png',
    id: '2642',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1d644d72a8a536c219033d90cf852f4b8ede27cb01234739c25bb4b34c0b5c9.png',
    id: '2643',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20032d3344f3f46ca4ce00c9796de02468c0e6acb519277ffe5f2df4f8be20f4.png',
    id: '2644',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4e81139939a6642df123f50049a6ef92e1266a50d2b957bc4e048cd90da56c4.png',
    id: '2645',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e63629ad1cdfddc7b95819a4795392cf1b1551a20327c555d5525c0d4218029.png',
    id: '2646',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Underworld Spine',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5821485999510e24d72f6e6c645fb25596d293a303ec62032d8f3987b0eeba6d.png',
    id: '2647',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3fa2b9a1bdd47e30907eb6104d3a82a3f1341e2b9067b0e56a644fa5e610f391.png',
    id: '2648',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c394d60f94831666cec276e88090cd8ef2ca46779ee32d959c94608a1c3c102.png',
    id: '2649',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83ef883730c30216bf4a5bd0bf8ef0fcae725058c6c651dec33ad6d72ea62010.png',
    id: '2650',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a1e44b90713b6d98c55e4ffa6d2a97b5658be4440df8d6089554962a62a597a.png',
    id: '2651',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22a3c115f682d43d89129b47875340a4c06b39d7d1fb610ad8bee71c9373d224.png',
    id: '2652',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be18165e28c2b5a53ef31414629c5a9b62a9179a58c43d3757d09be24cc0cd71.png',
    id: '2653',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9749637d7a0bcc24f9ccb3f6a8e82f991509af3bb5e0403f3e34de31c47a1a9d.png',
    id: '2654',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14760c11a89699254b5f5920b31390b9d4bb0355737fc0d2bbde1addb664bd72.png',
    id: '2655',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64cf6245cb28c5da158d6644267812ef65ed85e1806ad8b9604107ec6e687487.png',
    id: '2656',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c43a4651bbc45c58846882c88e1f2b3733fad989dc5dda14a2a26e835bb4788.png',
    id: '2657',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43c0f39c49930d0f02486e60837bdf6e07a94eca67ca7018d77d9d9783cca8e0.png',
    id: '2658',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9de6509d70438a49666039e4d27f419012e294db5b22c659566e27e44a60251e.png',
    id: '2659',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f868d9e437c527dc8a7a50ea82d2e6f2ad03b81198d9bf5db58c67fd36a61df1.png',
    id: '2660',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25d63bc0f8f75a68e5edef626bf82a7433edea4cf8bd3703e426208d3125def4.png',
    id: '2661',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69edb4bcccfcba09042b24ff99ca9170677ee6cb43578206cde371e832d7f39d.png',
    id: '2662',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51dc08d968ec86e7dba1a9fdd69a683519ecea3467e601c49e2b45ce873f92f6.png',
    id: '2663',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6b4838cf74c1431a6946330e4b327850ab0cd09323fd9b5c34672abc744471c.png',
    id: '2664',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe66b9e40bb5ff551e21dedc8ddeb9164e7177d3b3a7e82cabbb711cb1040d7c.png',
    id: '2665',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9114c7ec5ff42897727f1e26721a249ddb99ed34ecd118364d4fc68983a16d6a.png',
    id: '2666',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/faf8278fce6207b63a72da629f2ba84ec8e37c80e8bde88647e2379d8e36d551.png',
    id: '2667',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48d44e384d18477359a6d0af56c15620632658484c5cdcebed5107d59355f588.png',
    id: '2668',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c07a94f27479a28ece04676aa6bfe22704f4799ed4e3f976f206f738618dd11.png',
    id: '2669',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab4daa05f9c7648d74bc1b9baf0fabdbfeec4663eb926d310c49d7bcc3752658.png',
    id: '2670',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/878c3dfa6342b626e31e92abee645548cbf6ecb552851810298244e86d881639.png',
    id: '2671',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21a72741e5305ce913260fa65665ad6d6163a690be6b648f9ab47ac433d76eec.png',
    id: '2672',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cdb9a5f688f067948358f7857ecbdb01741642a956738630c62336efa2d8e6cd.png',
    id: '2673',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d76d59240c8ed1d408b11aa0887d2880d77b6ecc4ced3569743d1a845b40b35d.png',
    id: '2674',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c08dbb237046e9f56211cbe6ce5a319aec285c3b632ac6f1f1f7dff92905681.png',
    id: '2675',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/384f781f7a4a68f24ab8a0cae4047676f69525f132c03f8222b32fbc5d6cd20e.png',
    id: '2676',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dfc8245456880405673f0b44d6af087a44f741101913779ae93f8bf0ce3108f7.png',
    id: '2677',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3709dd3900ac668102c7c69b81f5f4f9460783b9eb29d31aaf57c983892d71d1.png',
    id: '2678',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4536ad0f0dcfe5f04fa7d6d4e8fdcf71f389d72286e0bfdee0749640b6209257.png',
    id: '2679',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/adbad637f4379b0b5eeb2da2675d0db24beb08bddfb54603e0a24d24a2859ab0.png',
    id: '2680',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01e293b0d5bd112354d694573bee3f362fdcab8975fee74dd6a3c56b05dbdef7.png',
    id: '2681',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21e89256237852c6f0a2972551943d6674f7a69f6083bb28d9319bc4a455dfe7.png',
    id: '2682',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53873e50424d2792d6a163906f750911f404c48acec7a60c028d62a722dbbbc8.png',
    id: '2683',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d9c08fcf643476bb15775d783e05c6f4d841876948dd5b72247161bc0170820.png',
    id: '2684',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4684db762c41664dc01e65066c3b507b6099e960789ee85c92164c646cd1d87b.png',
    id: '2685',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/502224df4e616973100cf8390365763bd3379c95e55978b19847dd483686de55.png',
    id: '2686',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5f2d6046970bfd3d5046124c24f4caaa573fac3b8eadad331fca5c5bfcd3551.png',
    id: '2687',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72de03be83eb9981792d6700716d2415ec579fc2ba84b7dac2c46a7c456e279b.png',
    id: '2688',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee76106ba4b75de244f01085bf7c468b7723d8b800625ee9d039421916b780ea.png',
    id: '2689',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcc4e410985b1218fa7389d3614c426ab3cbaff49739be1586514c78b4470c20.png',
    id: '2690',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5b2ea24bdb3507e71b8e2c4fd7a40228a45ff55a09a11ce9aee2ef97e13c94d.png',
    id: '2691',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d1049049c617d95f1301d550bc4c0636229f001cc6ed53927772f311c1c48a9.png',
    id: '2692',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b4d29f548ccf4c171e0d05e9d0800c66295f7474f4966ae85a0913613ddff37.png',
    id: '2693',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d4eee4d1ef58bdee9045ad25afe68bd132c447c779913a1f3b4cfc6158b984b.png',
    id: '2694',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a048f329d34fe223ee10fb2cee3ef34864d74916484d75ac488aca3f1a58c9c.png',
    id: '2695',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7fd3df230eed85ac9f084a42aaf881b04096a164a6ad5f79ed127a5b757ecbe4.png',
    id: '2696',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f1ac193e8f8aaee6e7ced6c5ba6368bfee042da9e24fa0a8fc11b05671978ad.png',
    id: '2697',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d4b550239550b570ebf6185210ef7133c0243c41a1fdd7d55bf4d5282a341fd.png',
    id: '2698',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54a8a799320c2163bddbd8974d66a3dee65a0e3664c3565482390b6947e8dc16.png',
    id: '2699',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a83488b2ee6bab616f072c467d2a38697841f1913387c090f3e5728872f09b8b.png',
    id: '2700',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81c6d21baea815fafbe29e151f8a9f23cca3add1775476c6fc347de03e55f66c.png',
    id: '2701',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b798d7ec6b63b435db20fe1eeb33788e7bb5a5071981ee82fabd6754e23a3493.png',
    id: '2702',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c02fef0063530717a9d47b8a1a918a8006ab38715821dae01a7b4f92fcb049a8.png',
    id: '2703',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0a324553a3b9af3d401669711875adb803b9d606678adffeaee70319b9df466.png',
    id: '2704',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82fb58c76b9557917b3a76029dbd57963414b571fcc99690cf5ea0c733b3900a.png',
    id: '2705',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79d8fc371007f18f96f72f75a6b68add671e71433d7ce2fd981acf5e49326b30.png',
    id: '2706',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff88599214c270405e002accae00d6285a5f33dba00ac39e057798c9b88aba59.png',
    id: '2707',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dcdf08d1344e915610b0b0b2bc4e28154f60eeef7b8f5aebda1dd75e56f74372.png',
    id: '2708',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16ecc15cb64d591c672cea62a006a832a44d5510601e0e116914d98713d7c74f.png',
    id: '2709',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e01f87d70cacec9bac1d2a64cdc6d6e264bc1c02b5705950124713615f18f25c.png',
    id: '2710',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a27f3d7f297e0dd11b95132ba43fb732bfe80a30987801ee1e884de5ee84549a.png',
    id: '2711',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d561df313e2fe40e20eb4297a0f10969b147a786b17685ad170272210dcaa5a0.png',
    id: '2712',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f3fd223ada28168d943409385441f2a01b51c373bfb191bd76b0922c40a37e3.png',
    id: '2713',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c29ffa5311bef0b0c2ec32c0552467dbdffd674ec39f84e734479bade8c99a0.png',
    id: '2714',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f515f9a04af895df582c0682894a36bfe52b0abca363600c5fa1686a4508df3b.png',
    id: '2715',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
      {
        trait_type: 'Weapon',
        value: 'Batter Up',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/579acb49566c3e0df99ffc757fd7f0978c84824cd388ad24c4d2036a46832043.png',
    id: '2716',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/622a1f465adf64c30879015cdb4374c18847dea5f681ca086d307f5715b29022.png',
    id: '2717',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f028d83251a65c92fccffba43918902685eb4516ea9ce4f79766c8fd3c1da707.png',
    id: '2718',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/105b91a807a17314454aff3641a2f6316ac9db18d35e494a5187a8775b78b264.png',
    id: '2719',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94c89a45efafbfd63d13631563e91d656734ede8784b752de1e3a9fdc7e25791.png',
    id: '2720',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fde336e0aed7f783856c1c9e066af327c5ddf39327106f6d0ce7fe9196157855.png',
    id: '2721',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb754413b1cc31f397a6b69b4120205b9d8fbe5768fef145b415501cddb56e33.png',
    id: '2722',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d5da6e9be3a83290ed3d678035de5cdf3629b0672699580d2388816998fdae8.png',
    id: '2723',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/356c68092e1a031f5a1a9473c726ff1d8aaed9da9772c21ce2eb886ec5794bac.png',
    id: '2724',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/160eac1ecaaec8760322ccf4e01708b92cd8cd4a57e61da838342bc5f8309493.png',
    id: '2725',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4db62e6030c1a49f57fa546089b4eb15dab8e889fc11d7a611da0e666f878b0e.png',
    id: '2726',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/caa2adb84b6f6498388822fca61549b8579034991f811394e3162134224a57f3.png',
    id: '2727',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/585be11d6ab047d6c78a7f520c5713a1257f09285600005b6cd1cd28c4572485.png',
    id: '2728',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e5e32538a08c5e0f81dcef78855037b9393c1042b2da83f3dd2c9060a17edb6.png',
    id: '2729',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcabb96591a7d8cae48b11a9f31336c8c526d26edba5e49434da24ea045a04bc.png',
    id: '2730',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3bdec9da22a7f8251239133670edaaf6ea274193078678b4b984c67c81793407.png',
    id: '2731',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f82dbd867da7aa74262a586bd7bb45f4a50bf21dde36cd17b08d6adb6872c9e9.png',
    id: '2732',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2998d9dff2452ffb27d79682dd352c82aa7d2008eb05611b30d4ea36b15534c2.png',
    id: '2733',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/519f5cb43cc455760039a9cf61d9fe9fbdd8688719d1559853c7ee8407763468.png',
    id: '2734',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc451862e3b0435549dcc14fd29df1ab11b890e1c5a6bdf775c2a47eee97a919.png',
    id: '2735',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8733f92bfce0d36bd8c17d5b5d4dedfbc64726b337a80d9ea1af7443c8d8f9ed.png',
    id: '2736',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d25d6bfe77154c5e0d4540c61a4f3315aa4b8fbade83df8712352a9453dd1307.png',
    id: '2737',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1391d003c626fa2579484f6a8bdae0a7f7d954619e7684b359c29c7d9f8f93c.png',
    id: '2738',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca57cdfee40336ad9699e602d8ae49173795a103a0141c31d961f908d24c1ba5.png',
    id: '2739',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c00c7a55c247d73b7cb025b9d835d11a163830c0fa95406f467355544148cdb.png',
    id: '2740',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/467216716e8d8a50e561bdb043eef357f18f53ee1f88750f074c265b585909f4.png',
    id: '2741',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5aa5ee7e65d7d5982d08f243012dffc548fbf339716e2b634e489880f65b5cf8.png',
    id: '2742',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f912d8d207e5b79c7578f9a710c53e29bf1d154318749af87bec9426db2d7d0a.png',
    id: '2743',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6873a2dd400063da6e8303393dc1a7bff29276ff787fad88c8791b47b5c1a38b.png',
    id: '2744',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33167eeee55c53f6197d6a1ea3091d132a629201cb50119f935d59fe9d27abda.png',
    id: '2745',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a88455144e26d93fcfe59449ab03594a268c313c03cdd450cf443a13505bdf11.png',
    id: '2746',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98433e06945fff068bf905212ff3e4979cb153cf74cae29c7957cc51d9d1531b.png',
    id: '2747',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/699f05eab908931518a82b7b2ed2fae8a6c0ee586042ba42ace65d7bea688745.png',
    id: '2748',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d624650444fa2400936f0b36cb80c0b1fded87a1ee7690e9b945dc8020ce3e1.png',
    id: '2749',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53dbb050db7746b454065ea26bd28baa42f7c57a711669fd999a7c1841e8c2d0.png',
    id: '2750',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/397de983ba6a1e908bfd525b40a290a1b1b656dc80cf0f8806738de756c8746f.png',
    id: '2751',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/141b15839e2b70bb0835f2d5c58ce850adafb8844bdf9feef23ec3db9b775f3a.png',
    id: '2752',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6166226af63b6ab0a98e5bd7cf7ca5da10e1af9e19d976d6314e370913387716.png',
    id: '2753',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/984dc047d0dc62211f901ccdab316e531c1572e19dfb62f451281845740755b5.png',
    id: '2754',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/474c6d3d53c402c77bd34a4ec2641b88cdc09cd9bffaced8d1fe03b4b738535f.png',
    id: '2755',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8991c5f4c3afc97aaf5f0f13d055563cc5ffbf50705c8f6d0b1f857bf8f63678.png',
    id: '2756',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af6f46dfa6fa0b30683054095b67da792f9dea9bebf0f5a3cf4502d237e8b324.png',
    id: '2757',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c7ad7392c71d20289e8a4962ab6d5d8943e4a3d6824a7ab9279ec7d943dd0f8.png',
    id: '2758',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1461910652b04ab7df99b200b9e08f5eed9efb2de563c1c808899d9f5073e7c6.png',
    id: '2759',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5bc373b2f059cbc368a9729e1d0d330391f59373f3d527f9196469181f30d6e2.png',
    id: '2760',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/265866a1bb88bf223c16e4fa7ac92b7e06469e8844ba5039bbc77ff5129dd950.png',
    id: '2761',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/888d30d9a67029f045b29e00087a0343e38abc5b4c04b46afe55baac0f562f0b.png',
    id: '2762',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/953a6f1dbbe5c78281d88e49d8665fd9ead8597bf37f62e876ce452ad98ed71d.png',
    id: '2763',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01e62ea66e1bafeb12c5940cf12b3ff5ac4295b05d6c3c335e870ccc8a39ef2c.png',
    id: '2764',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc011f672f6ded07d497963aed06963491633cc1b18964fd1721528819640b3b.png',
    id: '2765',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bd99fb2115bdc237b135db4d76118ac843b2cef0122b330be672eb9da42d070.png',
    id: '2766',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9375f62a529471f4ad93c1b4ec1d5983dc002b6b01e6e5fd9025a790c3efaaf0.png',
    id: '2767',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f51eaddda9e13f8e7d7342c0c7cbee1de3dbca34ad7c7d4ba92ef4c4606424cf.png',
    id: '2768',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4408edc2714fec6f7e440af6bec77309535291f935cb39da0c2082c930444695.png',
    id: '2769',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3dea5ed347b7f6834aa542db2ba92ce77637cd004608e89f2b925468a04d6f2.png',
    id: '2770',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5906110db88dca0c5aa10f0a05bdb3c4c4330fd0dd06ab5dd46864864561758.png',
    id: '2771',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e6e83da814797e8665fafff88b7f01bfa8e39231485434fb57f7df02d9a5a4a.png',
    id: '2772',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b054b6d8809ea5a95bba2b31caabde6462d27cfada7ac92535c47ca23104e955.png',
    id: '2773',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b88a07cb18403ecb9900f5880da9e25654030212b7eaada1fa7a2d922c3fef0.png',
    id: '2774',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbe97bda4f52445a986547fbd1b5672b16ad6b925e0d0948ef0c3df9e6a3cf84.png',
    id: '2775',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f799f0daf4b28dbd9da97a635939b962a4b2c02ebc544464fe17fc356ea80baf.png',
    id: '2776',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9a7c2bfb23bc1bf50e28cb22a5035b9a093ccdeb1e1a4195a3e886f857de010.png',
    id: '2777',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c12dab988965e760343f4697bba88ee64f9d9fcbb009f304141ce5c8f53e8ded.png',
    id: '2778',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c431270fc06e35b2ea9a89907d266eaef9ba6ce6fb02682337a283a90f0a089.png',
    id: '2779',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98e9ab12ec1970250cde68065991ed517ace9d5001dba0817b6a9af26e4aa074.png',
    id: '2780',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6e82de4a64071f25be2b9f3c8600c8f1be3f5a9bbf49b361485e1d0aef80ea7.png',
    id: '2781',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/928ea7f5cf81ef7c9cc082012954ac3bf19229d7914bf60d2e0f26ea7b87b471.png',
    id: '2782',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33ac1d5475c86ac66a6f97e8d200b4142e00c48e856ca2c01193fa390841b088.png',
    id: '2783',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15e7cd5311284202097cd0c3fc7642819270a0dbcd9d49701be7fc78dcf10478.png',
    id: '2784',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63e438e2ec49d186613e81e5daaadf323e6c55260d7a04e9b9808b9eb1d57a28.png',
    id: '2785',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b62e5380b092d6cd4faea2b06db81a160da68348d24a237955b0468a61441793.png',
    id: '2786',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afcdb2235c9b7263c5de46f9f5ee11a4994378b6a9265d6d1093c2151491a90c.png',
    id: '2787',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a201ff96263423a2f03d2c2d285c24f7c14d387469ad202b4237cf65f6ce681a.png',
    id: '2788',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72a790190d80ab0017693927b7218bf8a9ba482b470f26cc88574244de3d14de.png',
    id: '2789',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0dae37f8d5a70cce597de8a506f46153f0b75ffd5d706ab1c30de3d2bbcffd39.png',
    id: '2790',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc6a4e15dd52f9b15dc4da5e9ef36b69ed2b1aa02e812d7d0c2589cd96563e39.png',
    id: '2791',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4534f3d19b0ce98a173030ff4fed363c7ed4d998eb7992ea92ffb654c490ead.png',
    id: '2792',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shards of Iridia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/105767007b0da6a7dd14f5424a32af3f399cd93c60f09697a99850cfc85164ae.png',
    id: '2793',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ca34200645ace8d69f6e7e14648b1cab260792040d3b9cd76921f098e26fae9.png',
    id: '2794',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abb85a215b7d01adfa9eef1a5a9da6c66b359ef9a4d37dac4cf986a437af1998.png',
    id: '2795',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a6fd842ff6fd250e3b30afc0744c3377a4c6fc9c44f390b11c5f7e1dbd848a8.png',
    id: '2796',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56bb30b266419f4d3ca2bbc0608393e897d67f3dc8fd062ca235b37d7af6a342.png',
    id: '2797',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/902ac51f59240f01990a3d7fde50476dd1b9e51610262bb21abc145d73b110df.png',
    id: '2798',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74e1796f437bc8d98869f474653624bf1db3ef69b7b27f25fec8ad1ef6e904d9.png',
    id: '2799',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab8f08a71d5aae532d39cb2ef0273c4cde7d11eebc3fd08e17865fa45e68e5c9.png',
    id: '2800',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ee143cf95e5de548374ea2712ebcca3036ea27d0d272521d733e9963ceef081.png',
    id: '2801',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84cbaeed32eda91ac83b5580884a994642ce57ce50c7bb8ebde984409e31d186.png',
    id: '2802',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f07c106f746b38856ad8b4c78a642eb89f417e47ef3fcbbb9306289e6a3d7ba.png',
    id: '2803',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/402f791c22b8828f72c6265c52acfeae3bba7532e69321d210de175869e5bc10.png',
    id: '2804',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37e832d8da9eb961f1a94cb95b80f79c452b2dcdd21fe58cf0e8c9992497aa9e.png',
    id: '2805',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c058da2b3195a33dac887962107ff377146d896d0f22fc2409352c189c2fca91.png',
    id: '2806',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ed1d4f5bff6c3189342e1107f671c822dba3c78253d9d7fdf26dc68bc4aa6c8.png',
    id: '2807',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61737702fc65126090b9867f12af9e44ef266860b1c56610c5f5421d55f1e83f.png',
    id: '2808',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cafb40c3a5a6737da095eaf13ec41cb4f9cf2bce5d8a2fb97132c78fb6f7f9c7.png',
    id: '2809',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cd28e421db83d35789a08574c879628c18fe013dba3044f064b28c088aea16e.png',
    id: '2810',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc6277b409dbc895cb63be2b6e60906dabcc9275f2e576c5bc69991779e690ba.png',
    id: '2811',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61715ee54fdb07c11797f1055660fcddbbf60dd0700cb30cd0996e9486ee09b3.png',
    id: '2812',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d176868fcf6e83517a88299370d97b169d1c79b08effd641e1aa40b68c0e2886.png',
    id: '2813',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae878437cdb8b0e1d0ffe59b7d310541d59a85f377153d8f3497a3bdce426544.png',
    id: '2814',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2db9185169f35fa050b45c2013773637f987df378c83375a2fe22e798142e716.png',
    id: '2815',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68eb8963a7d1e2a052668b23bc88dcda798ce688ea6aac78885113b70474e152.png',
    id: '2816',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94762b23890edee9400143877b4066467219212ec1d56312a6bf2b8e5c44260f.png',
    id: '2817',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5f73b49557f6bc70147d20be26c3cd3a4c2e2856f5b446e66989d94975c0c63.png',
    id: '2818',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1264fc7957d513447045e8933db072772d7e4ecc9b35c1dc52c142fa091c637c.png',
    id: '2819',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66ac1b82a5e97c294a7a2691e65b5edb0c84c63bda9900cd9dd083fff51197ee.png',
    id: '2820',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71001b422237afc8e9a8e2aa3c3fd9b9f45120f98e0d564e0152ce3fc7411aea.png',
    id: '2821',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b854d0106bc1654800dada8aab2def0404d0dd8615a5af3067f880217d20ed4.png',
    id: '2822',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e24a499ef5539562404ca640c6d2c067c01b630183170b3c90c9b5b576e7adc1.png',
    id: '2823',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27cff3e7a6d72f922ec7b30ef97a53b360c352138ab527dbc1240b525ff1ab15.png',
    id: '2824',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc7ea82054a1b827015969f8869c716f3e903859945deed39cc9aff807ccab45.png',
    id: '2825',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc169561486a9f94dcb5ecf3a8b5ca53125eac78be7c44c395fbb492d7412618.png',
    id: '2826',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c77ae38fb7aeb4beede9f21625a8cf45e0b33074ca006eaadcb56cf797255be0.png',
    id: '2827',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b09c28014c297f3116c706f65dff023cdc30b3ade2cef09f4753b6ba76d76421.png',
    id: '2828',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ace1fcbd5b24f46afc4e78fc85834527b4b72f3670ead2035f49b8dfc02f7a5.png',
    id: '2829',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28796c1c5ed0e37f073b5a37249bb426f366fa4e2c196011ad61b6b8fef791ea.png',
    id: '2830',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/649d9eb0fc3f1435c7c5113bac03a44f52cd090d05a2ce53841b196f28bab48a.png',
    id: '2831',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d2c59806718fdbc245ae342ba55f2bc3c78fc7d42047589ca38ba822a5f8b98.png',
    id: '2832',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74e675fe7c1626027407ac4552017fdbaec786aa7d3c3ba3ee740f57b8439fd8.png',
    id: '2833',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/903908ca288814bae4e747533a54fa036687bdadeb89d7c60c1e4f97f438d62e.png',
    id: '2834',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1236f8699888780351570cbc293dffaab0768d6f9ea9b84a26e33674aa9c05c.png',
    id: '2835',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/356788b4691fe20996510b1759357a8afc5ee23c9f48cc297d44d8efc8556fa2.png',
    id: '2836',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b187fab54a5f919ab82f3b10cbdba72a4530f421a1417480f0915703442eb9e5.png',
    id: '2837',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5bcbff4bea5b4bbbc4397bf800f21ebb20c3ecc8b9f0c544808e29a13ff99d64.png',
    id: '2838',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40913341d8698c552e73b7d381c8a34ec08bdad4f098366f59f6969eb665717d.png',
    id: '2839',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Mush Clobberer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de1193cc0dcc708949a5643da03e0c628542e270581d6796578b7dd5e88dd16f.png',
    id: '2840',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d3a01a632d470afd5319b276cb213863a959bb05bc952634301ec45bfe95663.png',
    id: '2841',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4af19256e5417b1d499befe59c525f3a27c6e4cedfdfbd7ff2624e669aae7ce.png',
    id: '2842',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/816e47ecf4b8fab9780665747d3ddfa1b9536d6a23f4af8cdb69dc6e07805de5.png',
    id: '2843',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4230a6c4c8fe1516f0ca09b4296e67227becacd001088b2b9595ef163db2448a.png',
    id: '2844',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29e0e8375d13e5973f0576b63f31cc61a90a53c5fbf41bf14670cce8f81665a8.png',
    id: '2845',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24d1939473728922df25e19cecda1fd973c48a440f3c457b11f669c3ca1f01a8.png',
    id: '2846',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79e28bd09e2bf2958b5576b66302516dbca04438b7aeef78a8175fe532a23deb.png',
    id: '2847',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81561c786fde3d643c91b1e9be93863bfe9ba7e11a5e17c78dccc614638a7986.png',
    id: '2848',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a82f9cbf84053389272e8600c187ba7b51cde4eb545500a4f4a30183816b544.png',
    id: '2849',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f78c6a0c08e23ebb6cd055ae136bb3f3c4aba2503a70c741c32b69dd134838f.png',
    id: '2850',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebc4b464f1866ea3a58090df674c961e5c3fabd9ef711d24e498f797461c521b.png',
    id: '2851',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/777482d262a9c81d05fac9c9a7b288e2b4898d4020e18e7fc546b3b620ae6084.png',
    id: '2852',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce6f713746073822bdcccc36cac3186e6f091c7b7331613ca3f16165aa6356e2.png',
    id: '2853',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8787181e1d3809395d7d0e3bac228ddf9326cc4dd5f4d7ff66f9fab2fd5f769.png',
    id: '2854',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d106ab7ed4ea597967a25fe9247726c9f55cb29155b950b01835665caafdd457.png',
    id: '2855',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2420100e360d79f9c64aab3d5b3140ddef5015190109b62a70c2dbf89d49bd22.png',
    id: '2856',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb17aecbaff1b2758859634077a1b8fac71795bd6ce8cc0723a89f290c333dcf.png',
    id: '2857',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/129adf59bb8b8eb5b708df492439c06083a00e3ffda9a02a848d1f715908dffa.png',
    id: '2858',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2313d22a97f9e2daedcdf39b99b8edd7e0efb67e8abf1d49b9112b220161a6c8.png',
    id: '2859',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4121e3e5498a1de387b0ae95a0b02386020d659fa5b58f4f00b04806781152e1.png',
    id: '2860',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52154a1467dc76c8ce2afe46a3bbd57d4e9de8a8041ad611bb38b9cdc5ca8d01.png',
    id: '2861',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43e14800110ebf8b0723f857c89da146228bdf39cf7ff642d403d7bf8fb3c57f.png',
    id: '2862',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6470917f913d680443609c83a59721ca436fdfb01bfc9cb0be675f8803631281.png',
    id: '2863',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/740ac9683f487d1041c35f15a3f941aa6d51ad929b1411dd5370a0f081139041.png',
    id: '2864',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d85250014ae3276cc9df6858a338dc431fac61ceb0405f2257ce8b5a01149e1e.png',
    id: '2865',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd31316f7333f9291728880a6a0fb9c4ebaef99b66bac5b39f54d9e9782b2b93.png',
    id: '2866',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf9372669dc3c6c5a8b622827dfc7b162f1da5f96230067b6e82167c5f7dab4b.png',
    id: '2867',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d735c0468d34fc854d1b4b2f5cb958a1c7c826007f0c1ac8fd76e74446a619e5.png',
    id: '2868',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a83fe477e85abac7fbe508aa0d52e2991b42e6118717209dd25ccb78cb14d2c2.png',
    id: '2869',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0aeb51e7aedc80b299f8097751e833c2bbb6e2fbcbfebd52cb8eb6a827475f73.png',
    id: '2870',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa22b5f8ee566e371e8494ec6141bcbad6f50be616b4d609d0a45772424d4d73.png',
    id: '2871',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8909965755ce5e7e43b7c9b5a2be53a3c604be15ce8988ecbe89e6a89fcb1e6f.png',
    id: '2872',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31b4aeb471a9e71fac036da2bfc1c178db85e6818e4c8a59cbbb144695a18570.png',
    id: '2873',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a92601684e3cda8108396353b37a555d0578e7c10c97d1a3c05d5e347dbd13b3.png',
    id: '2874',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/479995567ac0587853615e33966b89a51b53395ea45e98ac3afcc5d1fe48efb2.png',
    id: '2875',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c69261d090c62332ad3a87b6d6a5fd678f7388623652929b2b62c2ef021f058b.png',
    id: '2876',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93bd73c7576d7160661c19e7f019d3907a87549bc72fe3707afb40754480bd35.png',
    id: '2877',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/481c8cab249071f2e0da6acb42d3af26f324066eb46dbb5de4e692f562b052f1.png',
    id: '2878',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00e29c13b029c631a44c78f92b8d38b8ab4ed8aebb35fca0f6f96a1992931e09.png',
    id: '2879',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/329f03e2c6022b3575b58d070173086ef88aa6ddb416400fc8858c8a99b7722a.png',
    id: '2880',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b68fcae5ef3ea947bc4f8b939474039828442f1883a038151abf1acd6586ea3.png',
    id: '2881',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f753aeecf4513162ca54b7fc49487122b085c0a055f75ab9463d58a61895f86e.png',
    id: '2882',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Wise Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e069d58918eb85ffe078d8fa3ceed10bb4acfd0ab4059dc2df782da7d8b342f4.png',
    id: '2883',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce0f5034fb34f10aeba96a5d4846a823aaf0f79524b9b99cb8fa838f486aeea2.png',
    id: '2884',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3dad3cd52fe769c93a69fe3aefb92952dda85015e2c5be3619b8f8d3d8fc23dc.png',
    id: '2885',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/514fee893c57d6a3c7e5677d562b890f5c2e1e0a35b243e537ae918ac566119e.png',
    id: '2886',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee5c97d4d16c9708e77291c4ee208cac498abe225c9122eb7fbed300e9f9ec6e.png',
    id: '2887',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5367e1fb3ec93b3049b1939192a8e06317daad1d065a281193f880985e628883.png',
    id: '2888',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43343cb5bc470462d3308a8ecd2f7975292acd483d70e17e8902f834cbd189c6.png',
    id: '2889',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f781ad2fdc55a1d843b350c22adf967b26002b33c54801e786f30855245ceba.png',
    id: '2890',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Gaze of Mysterius',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2affa413e36098c093561291588502ddac764dc6d8f5989236746abd89f2aca7.png',
    id: '2891',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6480265ce3a5cb366b050b9a675b17b26a25f8464cb4eb261068930839884e17.png',
    id: '2892',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c26065b34a2b6d27096fe9948ddfb7d57340ff40096f73482e25f369e7cf5c4.png',
    id: '2893',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ba88607c5837aabcabe8c9b88017b2e8ef01d09066ad24d0cfc9deff3d3b8ca.png',
    id: '2894',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03b2c2f9463a8f043de821df0ae37b30a227c2e0b62a6c236fe1ba42670439d3.png',
    id: '2895',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07c6234c9f2dad1956b0d49584940a6b8e0e3d1da5c5a5cc8924ef97b2485ce1.png',
    id: '2896',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72a8ec11421f94f192a6ce7db06e741990d763c2183fc247ebcafe3cfc4b1e05.png',
    id: '2897',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c33ca9feac22878e1bf068d68c435b15e036f2110889ba805a553aac4b1a2b76.png',
    id: '2898',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15a358f72fa4ee4e7ba9e9c668ccc56bfb2bea9a5305cb8271946bb07ae861aa.png',
    id: '2899',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f0b8463dad9f114447f224ed6d66f55960a6e10dc5439709f77705f5abb5a78.png',
    id: '2900',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1086514f4f39b58aa5917e9755cd662b3e83dd89ced32baaaa57a3be9fee0ee.png',
    id: '2901',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b67ef14c2041175b15e1b7e37332a0c5ef90f8e99453d6a885c83c4fe2b1785.png',
    id: '2902',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76debb3a6c9ccf23d29de6f7dfa6ce04c94e9c04c95ac1a19ae3a5c73be07922.png',
    id: '2903',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/532500247cd79dfd4aa7b3a213aa728415593e3affc5c0c4f58022894f189b92.png',
    id: '2904',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bcb750b8a4db19f7dc679616fe2c3ca615acac1a0b9d5eb8c10aa82fbe5a911d.png',
    id: '2905',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a308d0a7b5a83298157a4e34c32a5092754c96ca5d94efe38ea514a97f9bb980.png',
    id: '2906',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ab7fe8388a503b352cc8be1b845a76516b437b33d316efa84eecca112b98442.png',
    id: '2907',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47a27fa6bb8a2562884bb6c9735bea296ae56f97b827b384dab1e1a706f105f5.png',
    id: '2908',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3f4292b76f521dcc6cbe2feaab8e1dcc443198e4d1ae3880fe75f8a1dd86a32a.png',
    id: '2909',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98c43d57a9e3f609a6200bc758790aa4e2099a4937b95c2a3d069fd09d3d3cbf.png',
    id: '2910',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75dbe7b60272696fcd1852fc2969560a0eb3ab9856cb2fa2fc168b63f4d80b2e.png',
    id: '2911',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd61e4635955605dbe2f76e8cea1a17c4d10c0188e1e554db9e6f6095980d2a8.png',
    id: '2912',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skully',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b29933fc3dc8fe2961a7db6e98e136335c883e0fe9f8f1bb7ba47658c46c46fb.png',
    id: '2913',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bf245f50b4604057437addc37abd5d48f724ca5a921f4d55856118c75e7efac.png',
    id: '2914',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe035b18edbde3dcdb9478c3956d478792a97dc5f7c4c400dd49cf52c12ff033.png',
    id: '2915',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c74410fbbfc4f41a933ffe532fc4383b9c28e2057a00285ca04b31f73106edf.png',
    id: '2916',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6fb2d0e4fec7969cae37e7b48a71f20d9fd84a94d03fe221c87a8d0db05c288.png',
    id: '2917',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27f680d6ff7c74c95af3c1b2bf390e3aa71ae350771d634c6ab55687d77225bc.png',
    id: '2918',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85d604d9da1b63ef3940aa267a47483cc4f6f5968ab029e92ce13ae5bacf40a6.png',
    id: '2919',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1055ec673fdae94d9975b1cc6311a9afd41c83d8cbdd40826ccebae34e946863.png',
    id: '2920',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81e323c3b930e7285e07f999b60bc7d17a8815fb105051af9b7e2414b18dc286.png',
    id: '2921',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86dc364152acefe6b07af78e75cc595419caa0cd77457f7d47f77e6fcfed63be.png',
    id: '2922',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57b3e0456fe00d2b903d038bba76bcf06cb26c32c400dc38c1ed558fe80a1c0a.png',
    id: '2923',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/006fdbb4deae752a51ef829416ad54cb509451244dc707b9b63f6493945db315.png',
    id: '2924',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20f36f26e225cb97b6a5c2e0fac9c6eca24ad0abaeac0abe0213aaa6bc33feff.png',
    id: '2925',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9cf2b4b034fecb8aab713bd0df82a86bf6aa62a08b64f0f864e1e4152bfa8b0.png',
    id: '2926',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a3895a736b1debff5b54feb128f155cb5f25080bc53a334ec16e38b55fe6fbd.png',
    id: '2927',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/947e80e1283297d646f7fcce0abc8208bb47b0341c3badd2a20599fb494095fd.png',
    id: '2928',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fdec038fa5b38d3cc8595f6de3b6be55691ef75d53426ec1aebb31e2db23dfe.png',
    id: '2929',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0cb6b309caac2e6c314f92e13c7f1e54cb51c07f894c6cdece25cf5b0bffd19.png',
    id: '2930',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8aa0284d7f94ae06aac13ccd9f8922a7a2f246c10e3cbe3652549b71352cdcd0.png',
    id: '2931',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd64d657fcbc39798fbd9b7f9850cf0022feda23687696c5d6b13e398354309d.png',
    id: '2932',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4f9419f666bd38aa3540cd452a56fd1282bb9c4c566a4fd677af21f80d4ab01.png',
    id: '2933',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f3600eba236305e332b87cbeb038a68a1d4f1a5c8338787869fb959ce8ce6fb.png',
    id: '2934',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b258bb4a6ef55cf95e95a55a57eda66836f415cbbb9f2c37b30aed17dbb4a38.png',
    id: '2935',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a90cb4098dff9c727616a9753a85bbe7e5f812abd9fccde2fd4ff1b72527d34f.png',
    id: '2936',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75cc79a9ee2018eb9fd4ed18f6eab057886d21155bd3ae750a1c01bc69c9b6d9.png',
    id: '2937',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8cc81f9b10be3e0d434c635ec398f74c9b042433ae8ad9b4b9864bf82e6a2487.png',
    id: '2938',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98b1c619415531b0764e18613096a120d1744e853b28b7af2499b5db40bfbc27.png',
    id: '2939',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58d1d412f1a079c96a5a0b8e31332b74da2363bb5cae3c0d8e2b924ff62050cb.png',
    id: '2940',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dda30d16dd9d9cd939bf1f6eb5055d51a4946d6f5ce7b59ed48d801217c8394f.png',
    id: '2941',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ebc8ae3d91e1e9ebf6b1b64627348a6b2cd9c0be99f40a869d7f0085535b72d.png',
    id: '2942',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a88b6816e227b8ca4c4d5101193442358c881e9e0db62868ec6ec6941d51fc5b.png',
    id: '2943',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26d2b4f05784cc7834eb519cb4730bc4ca086255719f87cf8bb42444e066091c.png',
    id: '2944',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c78a47a7c95a79be320c93c504d8fddad739c296f98e48a6f71f72a5b815f11f.png',
    id: '2945',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76d8cf59d2b635d596cf542aab30290b72d90569ee5ef17c3b39e7fb9d4a9ad2.png',
    id: '2946',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecfcc57d3a0c1905c68031645378036d7ab141e381d91dedb55e0a12c0ad319b.png',
    id: '2947',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/716c3b2fbe3133de4cc1651995e885f94c4f68857314e4400383ef8177fd883a.png',
    id: '2948',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70ddf7ceb03452437c88e17820f36ed4aaf8bfbd35ff3a8ef8dac4dad698970f.png',
    id: '2949',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30f66c7f57a54260a5fb473a62df59b0498c0830b7bcf8a7446c5533f8b96cf2.png',
    id: '2950',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6c89c8b3c8bc2ae8758bcc2f72e5345119f316c00075a8dc5416ee935106dfc.png',
    id: '2951',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ec8b133b7b3dcec1cedc35be994c919b31662041a78e453b3e299f0cb01c8cd.png',
    id: '2952',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6fc3eba525e1065f2aaf4ff636a0aa7e2fffafd0ced5396783e24f50cfffe98.png',
    id: '2953',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3a10801d3efc304013b8f86d9f813f255ae5693587ac65528b984a4c94fe1eb.png',
    id: '2954',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff50831798f2d4fb82e42243ac0504f48abbd3a98dadbd2c343f74f19ea016da.png',
    id: '2955',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7fbb4b37fcc9375389263e991aedde68aa77c1f764fa2111d0413ff2337df2d9.png',
    id: '2956',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d89d07f82e6bc3948e23d35d622386c2b7b4b04d14587d4c3dacaa76965f4bfc.png',
    id: '2957',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b58a313720be8f5dd208f5821899e4ae9e530874cc3404e6426124bac7f808e.png',
    id: '2958',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec9333ff9f21efdfd30cbae2ce90743e810321b702ecc5019f5332ffea58a665.png',
    id: '2959',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba59a348a5ac44e7e68fed93386e1277b593b410b02c723dd859297cbae69e29.png',
    id: '2960',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/912df025578e35725eb3a50c923476628309bb5ded1e848a06e57f615696ea08.png',
    id: '2961',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e90d6e5a4fe9867583a2c18dcebe2f348890ca45ece5a6fba86d8e487b5d638f.png',
    id: '2962',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd4d29662bd5b5a01ea7c63da345d32bc7a5dc961323d402821ed56ac9555c90.png',
    id: '2963',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39a72990e4b63600f243ea9fb3d6ee0b43410d9aa1f503c29a6c97cb3821f6e1.png',
    id: '2964',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2e283c87f0ce190ecd58e90a84e1097238279d94fedb29578aabd432b1e2458.png',
    id: '2965',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee97a56862484faabd91bdd5c52816eeeab1b86ab9f49afb6ac44fbac2fdf8ad.png',
    id: '2966',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/391f4cf63052eb4d487d6dc6c6c9b33868af819a9460d2f546c69676d07d20b8.png',
    id: '2967',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92be3890d10af33e8e9dcf7ba066920be28eb181025954c1157a4f145b52b393.png',
    id: '2968',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c53cf14dddc8441e5433a1bac44aeaa092168ad530c373c6dfc2ecd48648dcd7.png',
    id: '2969',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa2edf7f0df0f4ad4a00198bd962bee5e7c9d90b678536b982bf8f2e518d0129.png',
    id: '2970',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/279c0134053d437aceab37e5bc2faeeb3b935feb7950922b53b24097bc5a757d.png',
    id: '2971',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebd6826ac15ee5c49b00ebf1b9c1129118b9ec5f782d68a66f8b52e48ea942fe.png',
    id: '2972',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f034e1533a8838bafffc6ef50b6e97a3bc835270fed2cc38735c2cb207c7b1f.png',
    id: '2973',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc1057fe28a471284c9e7bcd55e0a03b28317bbe7d79de017eba06bc7932bd07.png',
    id: '2974',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbe653da8d522fcf453ab8c5428917fd6331989362e56dc4519660e202fb81c8.png',
    id: '2975',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/060c584026999811cd774816a0030b59256554564c3bd949d28a468a04ef316b.png',
    id: '2976',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14bf1ecc200127de7a361bfbe2f9cb522fc81c1f2b8859169c5717c2090bd584.png',
    id: '2977',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a473372b798d44c9dd52aed3e7450337e514ff07d5f221e512ee2876742ff1b1.png',
    id: '2978',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53dc7bc6dc282bb02306ea8b0610c66b5673faf9c316c11457814868a363bdc5.png',
    id: '2979',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f707aeb3e561adaa8e81131ef1768683e880738a766f76025fd8af4ff1f4a22.png',
    id: '2980',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a870013fc53d5397c4a5c7f96d73bce774e42f4c15c586f326c24d2fbbe6805.png',
    id: '2981',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00579076bc1bbf4cc090dfde6bd28af123fcc22043c70caa6efccd3d053fe1a9.png',
    id: '2982',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d64d70a4e12f25deaefc32b2fdc94f714f455f7fe45f820f75e6e20351edf16d.png',
    id: '2983',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b0e38ef3f096f844ffe3be8b7b85952b3a5323f314a7aafc0263d473f178814.png',
    id: '2984',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/486d13c30137e8596c8d0b314844c78c516187908b24924a0da05a354be094cd.png',
    id: '2985',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73faf2b755479f16564e4d3f8367266372c5613b610e999172e07304d2d9343d.png',
    id: '2986',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03d315679518f9b8b102956fb647e4b8c313789544eafa1de8b7fedaf03a7d5a.png',
    id: '2987',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a925312b6c529bf5e453ea2077c5a95614a8cfa4be549c876f5b35982e70300.png',
    id: '2988',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f148e6e665d25a1f03f25332dd53ee1261a191ae78f4939455a3904bd6b5a7e6.png',
    id: '2989',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01e852a923f5fa94baa9b802492b103e4ac8172c3c911ffd429c8597aac231f3.png',
    id: '2990',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/351d8d878acc3593c4f3f60ee0f7d4945a4efc21aad8a3fb68daeb1c1793212c.png',
    id: '2991',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/902d62b221d4e78101d1dd25944ebb5f13fc0494bb053f4d360c5ef33baaac07.png',
    id: '2992',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/104aac915fd098865f8a2e795b092664070579a2ed929821f3322b603da04c42.png',
    id: '2993',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a86a0ccdd35e90f0a8d0887d8b1925838e0f4f3020b57d2d52911c202c4cb1b.png',
    id: '2994',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0cfb0b2cad44355fc69e9b23ee1c346e49bb9795de0206d074e9829c75084c3.png',
    id: '2995',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/46108a15452fc25f961dc416a609f755440b014275bc3802138ebedad5351ba9.png',
    id: '2996',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08f8968f1e20492d37f4a6249f02551c34844726411380135a34be97784eebf3.png',
    id: '2997',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/927f8240d89e8015b1a4a12f164d6db386ae86434a908b2ee30b2ba7f0efd839.png',
    id: '2998',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6ed99adc8cbc859bd73ac60ade7fdcf8e806fb201bede008db1f20997300d5e.png',
    id: '2999',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6c604dbce7670ef5c5ae1c7b8afe905526a47949b6a9bc8d0df2a3c3431e94c.png',
    id: '3000',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Lights Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61d2effddd916dfac068fa889c6f4e906a5b66d21dd2c7a969cff2e8ca484b95.png',
    id: '3001',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf3ef5f9694f682b98ec7a4458b08e65c8637d881d49e99bcc4303bc21aab3ba.png',
    id: '3002',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bab0d9f141c2846e9813bd666c4462f9ba4f18a21da960927bfbf257af5da992.png',
    id: '3003',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82a33188c4086384edc36cbe79b89f7b3b3a59ce6b3e86ed1b0726c78ecb6254.png',
    id: '3004',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0463d2a940db1e4e6cdbd6b2701da2ee64c2e140cb26b68e3e26cf5780da9a8a.png',
    id: '3005',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad1249fb5328d59fcbbca614cd1770f6f9d7a0fc8807e276c4c32b5580b8658d.png',
    id: '3006',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91c48bd7ee6e3f394d34b81e928b60f0da313b2b93d71d82764028baa2adf785.png',
    id: '3007',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b641608f1a4a2e0a9e4a353a514e7fbf6100d071900d61605b0ad223117f7fe.png',
    id: '3008',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5802413d156c3eb770aea0d2739bca6672d13dc50b061d09b72ae25ad1b6795e.png',
    id: '3009',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8779ffb2b7536a4b9bf4ebbd171b4ab6bde6cd7f5a64448a04502185f689965.png',
    id: '3010',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c967abf3213cca00a7be4430bbea2cf1c41beb5410ae9509e121ac1e0b8456ec.png',
    id: '3011',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5c3bf1773c12ff2616efcbc2ef1aca61973ea3d32693f8bada448694cfedd9a.png',
    id: '3012',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe09d61fd2f785556907162f8d795b67e5f412cf9c991c071f98978bb36a6657.png',
    id: '3013',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/556df80583122791a1919f880b43837ccc586492e802dcad8cbb44d50d0f7b6a.png',
    id: '3014',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3300b869d660d01ac46ee81171632d7243d54085626f2fb4877a1bb587bda487.png',
    id: '3015',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbc0ef17807c1a5bec8de1140214a264e35fd0ad4faceabe9500a730feba077c.png',
    id: '3016',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0626d022a9f5300778abed7389ca4f339b4dd1c394a15408d4fc3e379edb6cdb.png',
    id: '3017',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc0de148a998ea5d8ce42a016e460abf0f22d26129556ed44b526030f6078cec.png',
    id: '3018',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/872cc536c56d134c2bb295226c2251967c6916e9a5d310a7fe5dcebea5b5fd16.png',
    id: '3019',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbb5754b5ee099f7c085e7b319c2591565375d49190fd076a8ea509866224672.png',
    id: '3020',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0cd401c29a7f828b4b17f46bf7156936b1f35a85a717c44fccbe0f8fc59f5894.png',
    id: '3021',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a1b6ec67d2f9b0dc43dc829dd6325d27d2211789cfc20ae94f651d991fd1973.png',
    id: '3022',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2ee27153ba3a2305a122d4d9ca34cab380659daa9467fb33e80b1a060f51692.png',
    id: '3023',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02a7c6be2871ab2fe85f5baf2111783ce8e3d355a0ee13e99c22af7ceaac2eae.png',
    id: '3024',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8dc04ac48727d2c7bf8bbdedec4b0da3ab1ea50343581438ffa5ae6c5aeb390d.png',
    id: '3025',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Batter Up',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3286e880b3a748cee5b8ceba43777fa1157d61b06fa07e34c9ca3351b835da1.png',
    id: '3026',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8a67be95621158f86092dbe5fe54a8d145ac7c8a3453007145cdf18d4d95bea.png',
    id: '3027',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d719988333253b69d7e21e9dd0a0f193107ca2929a0f699bcedcc57f619e9c95.png',
    id: '3028',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf406312dad406461f1f2805942b55c80b4950bbcc2998885d9c8a94d56f2d31.png',
    id: '3029',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/299e9335abcd16f27c11918b8fbaa5f540dc802c0a52ecd574595b6bf5eb9421.png',
    id: '3030',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22350d66757b4475c61a648afef3f88c2a2bb73c22d40f24ab7a27af2b60ebfc.png',
    id: '3031',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e59d449f48423fff38fb4fa4085d5649ee286cbe8d5a12cfeb3ca766e41fc22.png',
    id: '3032',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9944c1687773310852ac91d3cea51fb021cb1220d5343bf26821e55a0686a342.png',
    id: '3033',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0093573f2a2b3cc7523970db5f81f1e512806f1f4e7f61c5401dae48a0a3008.png',
    id: '3034',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b21115771cd286a8bae2284970ca19b9c3a7ebda5fc7216d4c0ec32492c1c192.png',
    id: '3035',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f858aec039a88d827964b4306cc4104f5b815a168984fad804a616b3a968c289.png',
    id: '3036',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a2c35d8c74e7d039b4aaa3544ff6efccae11ea265a335b3fc7c75806fa4177a.png',
    id: '3037',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0bda245db714c48b75d1d15ea3c4ceaa7ef2ce1b97efea7e74f2cf1b83368b0.png',
    id: '3038',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34134ef92caa8543e040d413c0dc8a3f1fdeaa442ab45d5eaccaadeca6e74f41.png',
    id: '3039',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7dfa5712e8ce79de92f9ec368d2cc6246a225e8ff1965ae8d04eee1326718554.png',
    id: '3040',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9bc1c6349e27c7760dd8f0f0f1fdba7dea328b4a153dbf9bac432e780a63a5d9.png',
    id: '3041',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/825bccdbf92e8b11a7168e6c63ac65cad3cfd0b5615b87e048c8552c8fe27290.png',
    id: '3042',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f977137333b5bdac23ffaf27fff25d0fae89fc84ac02ee7b5a0398630a68909.png',
    id: '3043',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/334282decc6afed7da6036d072f79cd5a3f5a20c11d2220f5d2a2d8393916863.png',
    id: '3044',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8337d81a80cd3828f0e32ed565a4091ea5765e3a24216d060b12ee66c7aa8aa9.png',
    id: '3045',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5458c9d19da36a4b473204c63ddc2b4318d6b04b0f4de91d193f350584b2956.png',
    id: '3046',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c122f1a8df8506443572e52ce1f0a3fedc4bd964f7635cacec441b5bb91cd4c.png',
    id: '3047',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/473ae44941ab840dbe16ee46f2bc55721c0039f3ce8a5f6a23c92f21c363a53e.png',
    id: '3048',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ea34e5469650d20fa5311b10d87092c2a50b3d3397f70709d25ed723f5d96f3.png',
    id: '3049',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08a448a77d55fe9595bea886def90b1e2bf5863614e75ee8ebdcded6ed4d7d94.png',
    id: '3050',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aeea3c11222bacee1fd7aa2bfed27d1dbccf618f9f8ff698b475e5fe8a8034cb.png',
    id: '3051',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27fa1431f109cdf1bbd041ac47d6a737a409be273a0bfa42f96e76f2b659be9a.png',
    id: '3052',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b98df6e121592442028c9793c2c8f494f7583a5c121b206a331e8205dcb00e1.png',
    id: '3053',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cff3c5fe1883ef587afebe93b888298f34b2bef4d12a292e29660cb2c55285a.png',
    id: '3054',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd955defc62499ec2fca968344b04f41815983ba491b1fa37fd03bf5a4527ca5.png',
    id: '3055',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa91b850b525bbf0aa676c22e6fc9659c898a91a39458e3a7d566fcaca54dc10.png',
    id: '3056',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b88570528b0d26e0610c373cf0052dc5c8bfe4e8b7ae337edd0e972cffb301c5.png',
    id: '3057',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08252d5edadb2ebb8f42780690906877640dffb47c76433976aa416b2b548201.png',
    id: '3058',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c88102268bf0a1a0b6d977901df433f42449a29a43c90795cf6f9c792e029dd2.png',
    id: '3059',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0c8eebbb368e1e012b73e7a4da81ff68cb7152b5eecc3fd8f982ad90bc1b214.png',
    id: '3060',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e7a6addf2835ee892349021083b0f5311052daa3ef2023f4f6fa57fcec66254.png',
    id: '3061',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ecf04a55285d6934a304ed2718632215d1e0c24848c925b0cae544296a3b094.png',
    id: '3062',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6be4141de76b19ca77be2333268d1539b1f34d787690ad0717172a32d0e7e20.png',
    id: '3063',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17f11b16ed51f25a5f79b9732754c61cd7b8a889e82c2296f43b9fb29eed7674.png',
    id: '3064',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f6607b2be93649b4c309d7f6bb45a28889199f371ce4e3fe33ef5bef551462f.png',
    id: '3065',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8999b02767c6f422ba37148ab642a4924bfee4b8459a8e9f3e0d52f6e788930.png',
    id: '3066',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/899ab0a5c844c35f5105b9ad104719813fc6da82dd3e00f1f15737c3bc1125be.png',
    id: '3067',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d50beb22b0186eebce6c8d0d11f99e52ad2f0fed2f8d8c9cbde63d235aa2007.png',
    id: '3068',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d09a83712abaa271215c54dfcfc490815b181aaaaf027decea318ae595e6367.png',
    id: '3069',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab91ff326145a60ceb79b6ff43c0920a6971c82c6dfc5d85c681a61268058549.png',
    id: '3070',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3f25793929fb9958d07ecf1dd6b2e422100f2237fdbd1379f39e3521f601ffcf.png',
    id: '3071',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/beb8920d252aedcbaf36e2c77cf6f1e87d02ee45b755c3b9c64976a0b7ce0b42.png',
    id: '3072',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Weapon',
        value: 'Twistree Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5dc9763b58694906b45bb689002c918ff0e9d115c88a73452abb76b43f38080.png',
    id: '3073',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ca707b3b4ddb82ffc0c030d713b1390d3199e8b4ea00297d44d478260f82049.png',
    id: '3074',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5efd8cb9a252bd682347941678e951826522a1127a19449acacafb4c2d3f052.png',
    id: '3075',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05f4564511d754ca5b7239e20d3bd368b0f51758001b60e5362932659320a96b.png',
    id: '3076',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67ba7ca239082b1a38a162275f834b37ca5cc41f142046a6f7af37f319f3c886.png',
    id: '3077',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29fb261eb00b5cba3f1776b69d06c10f4883aefcaab269b13d97348c468c3a0e.png',
    id: '3078',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce2369742bebe089c1f2267b97220c8824e38dcdcbfb372ffce82c90a82c0aa4.png',
    id: '3079',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/898886826ede5ff026c331d9107c85b212204b10b0a8c4762d508dffe6b5b272.png',
    id: '3080',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b897bff6deae1aac4bb7488b2102dcf97808d0040e9a38d84f9e158747b07388.png',
    id: '3081',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6135d4ad9a81b32f2bc89b37010018fc24766b5f62df822433ca9feb972808a.png',
    id: '3082',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00cb7b3adf05c01188f3fb85f9c72dca2874b161bee3f29bc6453ca35e83451b.png',
    id: '3083',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/046bf2a59fa91bdfee192589209cd37a7ffc579a94c24167e21b9eb2f46eeb8e.png',
    id: '3084',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f5feb248d5fcc6b7fd35d6ca0763c39948a3e00b089ddfdaa1890bbed8267e2.png',
    id: '3085',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4506bb0cb8ffdd3f8900f124f48ea42f3461c96a8d67f1f8a932dccb504dcd29.png',
    id: '3086',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8507eab3dba1f2fe35c91f9c82f6f9b10c9c827d1e6a171087448f354740ce28.png',
    id: '3087',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dddc4323ae47e4485509b9ae4ac0afe4cec7e8078d2396b44c815aa18db80861.png',
    id: '3088',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36a2c1117eb2d61381727f31391093c5d9f70f27173449a3c42df046b7c68399.png',
    id: '3089',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb1d3610e2954a74ce2a2557f2b329573308983429173aec564bfc27c715a1b8.png',
    id: '3090',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8b59b679269f983da415006114e8c20b8df2fcffcdf8d8666def1e3747f7218.png',
    id: '3091',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fac330dd6b29002be95a539a4154b2e8cddd77dbf1f6351bdde2a1b9f57adaa6.png',
    id: '3092',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6233a5367629ae184b4b83cd2cb06d4ae05a3b059803e3e89598cd197a9ac5a7.png',
    id: '3093',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/678774fbf5c1dd8f6c4db6791bf0fa42e978697f189bf9225e945b3d4ca0d2da.png',
    id: '3094',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4ed3cdd53b352730491d80ba12699575912449a90283c56d8605663652840de.png',
    id: '3095',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b7fda5c748da5da39d4bb2b402b05de62760ee063b548dd9308462767dbad7e.png',
    id: '3096',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81567fece355d569f8a59d4165845e7ba3fcf5dc07f35a0ff790f261032fcc40.png',
    id: '3097',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d19044efec3471fa8d5586a5386cd7ba50bc4285b4065c72c5c4f84c277aeb90.png',
    id: '3098',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/793935b9eb6f70b1a42ec7d489de9a31d047c0c05d5e4d08e7040fc85e9f7f5a.png',
    id: '3099',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09dcb6325ffc8d545bee0223015555b441ba18ef5547a4cbd79a382c2ee5bd2f.png',
    id: '3100',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ac3bdbf2f3509c277e237856ec4df1d5e251f474a53312366ee963687d74b4d.png',
    id: '3101',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0f44bbe1dabe3f05481646dc72b3621311ca6b264a918de530dacee4a7cd368.png',
    id: '3102',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7fe42aa6d4b97d31bd41367345a154c45e0f5b4628f4c38e61a45a4b66630f5b.png',
    id: '3103',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b76d03f5d6f837906caafaf4521c5058a2e597e3e17f83c15e02e4403c33744.png',
    id: '3104',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f03e5cd3baa6886af26fbdb91e58d54ecdbf06fef99d72892dcce1ce379ecebd.png',
    id: '3105',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c65a84f76bdfb45eff1648647067555da2e164dc994888aea974e7bfab4925f4.png',
    id: '3106',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
      {
        trait_type: 'Weapon',
        value: 'Batter Up',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24e6b4b5152236902f4ba31dbf32546455d6c1fa69b7d2551e6ae695893dc207.png',
    id: '3107',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Monster Ball',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2f5b1a52ee2e25c52d603e9eaaa4f7f9786298d036eff9a3d90ff81b70f1bb7.png',
    id: '3108',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/687282847c9d245ad35acdc82372440cda998b0f98a5233c2bf5ae0d5c0a1aa9.png',
    id: '3109',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15bb75ff73e68787bf4acef8675762954886e45895b9ef473b543ca51b1d7962.png',
    id: '3110',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c1f7e3735f315ce12bd346e30b7fb8946d96dde02aeff6aa8999c0d004a7762.png',
    id: '3111',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b02029875aceb0569c9dff81074da66637b30378ad84dd717546eb63d91d4cb.png',
    id: '3112',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9478aebc1d4d8bb0b4155415c0d72bdd8572ae04758fa8ffc6db92f553069929.png',
    id: '3113',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5cf0137885e1a46e1840cf350fd2817157e81e13baae3d329ff4168f7b2cf3fa.png',
    id: '3114',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bfa83b7d8c11ccbc8ba3a9e9816da0eb0d6404ac9f38a9145b51dc5efdf9ab93.png',
    id: '3115',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e0b68c4eb76265a42f4aa49297e0f328cbd94142770f504307c0cd094b4884b.png',
    id: '3116',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae24a14b6c6429696d415f58f557d86b0577def823d67dd263904acf180f5600.png',
    id: '3117',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a9ef84fca3720c8b3e7b00d2712d050b05c81d807473842611dd2aee846909f5.png',
    id: '3118',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c78e11d7e9b00fe6d71de2b528b5a84f6ee8ef7403e5260b8e78cf5a1b53f271.png',
    id: '3119',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2c92d189d363e1d3eb730ed45f43aa03b411e09c5f8f193b0297c20f40f6bbd.png',
    id: '3120',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ea32c13fc83031578b4084a1bb242503a57ebc550d668774fa31228c32ba8ae.png',
    id: '3121',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c0ad75c1764fb2df76c5cb2679c419c5ee46cbd8f8bb7f3af3b0546643bbe02.png',
    id: '3122',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/667861c982dc792f2e7fed17d41459cfa0246eb396dbf5d7a790a8a98f31bc53.png',
    id: '3123',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ad40499a01a03a7a9c9ae1465310f3358cdd83182283a010564547d0e53bbb2.png',
    id: '3124',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/046fc5ae1d95b362d5c6f3308ce9bec8ec22279b281bb0825a8726afd5f86f44.png',
    id: '3125',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b2055f7324575a4fa76a9ab31ef26a3afb6f9d9f19f427904075a51223655e8.png',
    id: '3126',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/899a44071e0f4253cb3331aa9ac97e8a9a2a56df827c1989d35576b11d66c08f.png',
    id: '3127',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f78dc2c05de5d17182849e4aed4c19962c98069b083a205f5fa3012def2eaa4.png',
    id: '3128',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/034d47cd5b2ffb44e2e829a1da3e9111d45f335dc7159fce58461d46c81ab746.png',
    id: '3129',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7e6da516d66174b9503b4a0dbace4fccae3cb06de98c7abdad4b9fd7a00e302.png',
    id: '3130',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8d905497751c78f718b54ab0172ca2abf6369f0c4547a7e106b38448a8db099.png',
    id: '3131',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a1dbc7fb51eaf405a004e6d7b1710dabbf1cf5dfcea7cf545a874a0e35a848a.png',
    id: '3132',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb27e41c9ae945503eb329c994dc17460ba5396954edbcada622b00e41ca0090.png',
    id: '3133',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ba7feaf815711d0f249c3389b7c658805c55e72825d7b77d003e38c0e210aef.png',
    id: '3134',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b432d353ac70ef62dffa36d1b2826e43db5b23d2c730de355a1da29f534e8f2.png',
    id: '3135',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79a578d72dba7fb5d6e2b1fa6beba9b09bfe02f72b7abfa171082be82d191b7c.png',
    id: '3136',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1af11c35174f61d977a83846eadb0103e6993c12147012a0e8f74c9b6232853e.png',
    id: '3137',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbb46b22ffd43c8e9f084d3365427badd527db5159d20f75b62a8f93df28bc3a.png',
    id: '3138',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07fe6fd67ddda61f3b989d0fcd29bda1a7bf973e6832a31ee3901a30ba92d49c.png',
    id: '3139',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97fc18217c6219e5bbe3c71c088c79212c83fb4a7732aadd1c00700c896a1803.png',
    id: '3140',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc7dca1c8bb604f66ebb2277f62755c1aed9142cba764caa1c964d45c5e6f53f.png',
    id: '3141',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebbbc74719f8bebb30ab68f6ef0f08a3382727b09bacb65cf958d74bc07e90d7.png',
    id: '3142',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5aa230ebb8249da6fc48e03628f49530486a76a9908f72d868a72190a76f8794.png',
    id: '3143',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a06bfc974b8368298b0a42609e27505361dd0b24c5685677254584b78e6a73e7.png',
    id: '3144',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f67fbba450cbc24c3435657777b944aaa768f737bf3159b4ebc0cb4881013495.png',
    id: '3145',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e5379ede6ddd5bbb6ca83f48205eeb8a870b6844224a666a012ecee736596be.png',
    id: '3146',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93809ea057fb95c97a4e9aff4bdd58fb9555e4d9fbf66b3d122cbb17e147870d.png',
    id: '3147',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e3d7bc26b4f0a6c2c807046050b9796b6e86838d758f8666d75f6e06e23fe4e.png',
    id: '3148',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/539d41ac37e8f2be81d66fd085413d35e6a5f3eaa3e27e5e908f3c60b4957a78.png',
    id: '3149',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4ab45ff88311562fbac97b9d737d4b7a67694098dc6e7511ace3b988c2a8bf5.png',
    id: '3150',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7177ddbd1261506751b8e7906e7560e9336e691a9b0976442836b18090a0271.png',
    id: '3151',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19c61042399331907355936abe9c144f277e52d78117075136fb8c9af74b54cc.png',
    id: '3152',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1badb19ca2384a67f0bee6593d5cae36dc95c87651ec81e22e0691e071772c3.png',
    id: '3153',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9a86caddf7ca7f5f4d17ea67b1e0472b1da672a3a8f23f1a3228867bf8f2a16.png',
    id: '3154',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e77693305d1a5242c4be7e7b2cd22104be7cb35abb83617c3bda69ec68ee5703.png',
    id: '3155',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e542e3c34d1d17c0045916c3fb18cb03c06ca664e523fecdd8f180a776e7498.png',
    id: '3156',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f46e3ceb9b1ff16f5ed9ec3154c2027f8a63d0b42842a62cdd828d936fcc33f0.png',
    id: '3157',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43b14631cdaab9291bd51ddd7c7af85a82364c1ecbeb01d1c304b80d4512e8b4.png',
    id: '3158',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48649530c7476d60cdbcb265a3fc102ff194bfbc0667cc0f30ecd0f1c1f3f584.png',
    id: '3159',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f411b8666a8a52a2739787aae4267ee2c93a16515cf3f82028deb0afcf4fc7a9.png',
    id: '3160',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/502ac1e0fb5042b6d22fbb3f3732e46d06bb4030eff4e6b2250edee67830c026.png',
    id: '3161',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4707e5b7b30d2f09a00f75e4058a1ef99f4c6afa959bbe893c6c38079d32b7b5.png',
    id: '3162',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9750468f68e4c8dadfa2c1282efad10cb28c3b55c263349db8b29cb534b16c81.png',
    id: '3163',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce79cf11b72823fc21edd3affd7c7f2a531b24f52e63666d2f5727d4bee6a5cf.png',
    id: '3164',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43cbda846bbd8ba8cc2c570117d20740c295e02e0f12c25a852f4ab4988d6a8f.png',
    id: '3165',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43b946c2087f40689e84cc7edd35da33ab02ef9f1b1a13183dc0408d46d0c1f0.png',
    id: '3166',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/553d2b5e10e8483c5914b72d40ce9dc5c91e4d9a49379a1020fd6c346439bc48.png',
    id: '3167',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ea8c645a6156195a50cac32e5cc82b5b3f76ce2683ad295db2e60aee65f1251.png',
    id: '3168',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/836874f742398864406c9d13bde139af7f550bb0d010442402c0c00505d2a2fc.png',
    id: '3169',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f85f097707ca7837c1f758c02e1e858ea2fe1db9c62a337008e165d85f61ffbd.png',
    id: '3170',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30c82482993594469a14bb153768df07a108660b772bc40f1cb063049b66c61f.png',
    id: '3171',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a5894a1ed68d3bdbc1a559a9ae3309ee3a9069ebfa10895b8e56bc45c4d4387.png',
    id: '3172',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea228ff652cd8071aeeb618fa109a571239846284b15a1368708f0e8a7a9e418.png',
    id: '3173',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07dff9dc20590dde8ca4f199f43cb3106a8eb53bf81a142a239ec206e0bdf0ed.png',
    id: '3174',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/daf4f38d2b7ed4a38e05641e2f03241ce01d89775b36f80b1206792f2a28a4d9.png',
    id: '3175',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f59db6d9185f77cc19cb55fde36423a56ef89a22bfb199cec08be8bbb6f6f00.png',
    id: '3176',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8dcf416f4a4b38f8a7641a6e8b3c397359b899da2deb57076d46bb94e5077b07.png',
    id: '3177',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/564bbdb7f7fdb94ee34dbfa8c57f0c1da92b44e05433f5aa0914cea9f60f6983.png',
    id: '3178',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f7cc9bdd20ee1b7f4b1cb99546547646a00de5f70651445a119b5fa4574441b.png',
    id: '3179',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d11c0e7c62c28a7b26d8f0706e4d73a21b2119f0320af3f46f8283b2cbc666b.png',
    id: '3180',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44f9b1941bea4172eae3a49f5e55d7e13999ffd531819dae454410db8ffd0633.png',
    id: '3181',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa25fffdbb93dcd0670fbf9a922e604b256a16d2500a967144c00be48d993ac1.png',
    id: '3182',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16f47fce6a5b2b4252d429767ec6aaeaa32de03f28f355f49dbabb3d30ade231.png',
    id: '3183',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c39efa1b0d7e93ffc054b3c437399f9dcf18991d84303932e5bf0cab8ac937b2.png',
    id: '3184',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/816ee0dafd7034caeb222785cab44b2d4348b3a3db6a3f0735a96bf2269938cf.png',
    id: '3185',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/014d6696e6760c108ffa33510cbd419e1f557ad8c6d83f9f87c18485f1ab7555.png',
    id: '3186',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71d013cb28fda8e61945649813c6746a66cdfa418b13bb5ec99c9f484e20c806.png',
    id: '3187',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49d59f7570222375f9920bdd0e9fd3cd0cb57141ba50518d22478c58a3889ba2.png',
    id: '3188',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f639c73e50745984c035843bb0fba5b6cded2dc1061b4ff47fe9290478cedadd.png',
    id: '3189',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af49ec7b9a74e6ac515ace962a6667dee599084ebd96fb48a1961f50eddce3b7.png',
    id: '3190',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df34661258339c33b718a67a769f2488a628df6c876b6316b92514f32064b351.png',
    id: '3191',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7215a4b4b09b0978c9bf590077ee3b366521de4e90f6b9669eb7fa83eca3a158.png',
    id: '3192',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ba4b3e731597d54f98f0f7f3fcd6b4729c695a21834612c5e81b2a4be08a12d.png',
    id: '3193',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ebed71cc0d042719860a2b6a332268f294f9524733f30519981db04246eb37a.png',
    id: '3194',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2557597c074454fdccfb92172621005693aa6fe2744571ed47e3658110764ae7.png',
    id: '3195',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcfa477525c03ab177967011ff2743be997abf5c0dffb8e06383613f06ecc007.png',
    id: '3196',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab0f9f7eaefd005134cfa518c74cd9eebb89fe3e716e1e4b64351033e16596d9.png',
    id: '3197',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Monster Ball',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6459a400415f2dbb1220aaf89957a35ee4872e91f023f5d6df60002fb7513e1.png',
    id: '3198',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21cc647976fd9109de65c300efe1d1f44d1773d515e3b970dcf584926844dc0b.png',
    id: '3199',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54f2563b0967620a1f4b5cec832804a69b0b10f7e079c74aeb5f3c0124e188b6.png',
    id: '3200',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36c72a3d9123de48c6784de9208bc9c094c121b0d63d0d65c119558675329787.png',
    id: '3201',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c69ca4a7af88bd9e908463494456a6e478e70651d53c5a20c7ee7326c34fd9d.png',
    id: '3202',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83c33f178c95070f27c5a166f38b99fea2937747eee92bd39679bef0a7a39e83.png',
    id: '3203',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d88a90a66d5a83ca28d8d370297cc5ce652f08a0047d2e6e7bd768f3ec3e47e.png',
    id: '3204',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8990054fc836ecf91b01f9e8bdff4162b7268ca5be3b9bb6c9e1ff6814add15f.png',
    id: '3205',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/199f5fdb5bd63be45c87528d2268b25ae1f2e9f61fe697add4111c727e5c3349.png',
    id: '3206',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6883632a409bae7faf677fcdd23647a37beaad798d9bfac223c57aa1423d3a52.png',
    id: '3207',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8043d6ac0915acb32e4a14295a9160477cad772655ba9323521e163d9f56a600.png',
    id: '3208',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Weapon',
        value: 'Mush Clobberer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1156d61896a99405399a8b094621ec34e3438211f4359d12a5b3ac0b9564a0bd.png',
    id: '3209',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c4c58d03a86e99831ecd9964015f22e0fea3043a36c6cfdc7c702e7f743fb19.png',
    id: '3210',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ef86dcfaa74c8c5787272b8e79f9aea37cfee14d76e6289b0f8f2157b96d5fd.png',
    id: '3211',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36c0051460fbe96abf7d3c2e7c2cbf424f1a57262b9f2409a6edae6421f6a521.png',
    id: '3212',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59ccf18ae8156d4d018b58ea5a48c61943662b842758cd0d6969952d96ac8985.png',
    id: '3213',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ccea992db31dfbcd52f29d40abd320b9f85dd74f412af09788e5079cda209d11.png',
    id: '3214',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60369a56d7b6c36df286af278986a6fb3e347dffce03c1aba204521608f105c5.png',
    id: '3215',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86e80c9ec035b8dbfb16e191ae0925677bfacf96f681730d046caf6284f8178f.png',
    id: '3216',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/040c1b42a3317b7a5b39111c63cb4a6133ae1c7f1552deb8d6741eb6ad6dd88f.png',
    id: '3217',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5822e43026bc5d66e56376c26150e859fa0344228bef65d078b929d47916eec5.png',
    id: '3218',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e5b95fec6fe94e3e46aa830489c9829a695bd116248148502b83ac12e6a356f.png',
    id: '3219',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7313f4a3b621cc1b92f342f4d937d1d58de6740f0c69c8f186ddc73bfcf6a7fd.png',
    id: '3220',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3f8c200aa7d71fa8cfd600f2dfafe502add67aeffb8ec673d242ebb54ed454a5.png',
    id: '3221',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba1f19c7aa3785c39ff21ac777a6cc0daa8e99f4326131eef83e9526679c836d.png',
    id: '3222',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea4ec6622855b42706f9ceb50b285276e2f2407231b73b2bd42e9b0fb65bba76.png',
    id: '3223',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf3a5f7bf67c457f40cac4301d875d3f397d5c7da512d214c1d2e385b7867609.png',
    id: '3224',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1aa2e1cc384230cbe0ba9280892946e03f1d6e8aa55e907cb599acf27345064a.png',
    id: '3225',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fc18c8da836dd8a92a51db67c0d6bc195f0639929d4a764f6f748c0dda45a1b.png',
    id: '3226',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb64480bec8af27626759ea3a48a64097034a7780fcf070f464bb87a2a147f6e.png',
    id: '3227',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e63b9274f67206543887df5d05eb4c583b423665120bd6b13307996d104ee316.png',
    id: '3228',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f70db336f60944ffd59a4e7b94b07b283f35dac5626bfcec36cc5d8439a8bb6.png',
    id: '3229',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/147fa75c071f4dd713b15a6e07d9d7315e2e4e0d4cbd2ef0a0bbe900c53f3955.png',
    id: '3230',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c22b5da69ac206ae8d52331970a72a9848b0248e49c4b06d0c269e1296a616ad.png',
    id: '3231',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61be5fd4612646b5d21c3373196364d9bb45f81727ebfa6fcd4b944dfaad1c6a.png',
    id: '3232',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c4754de300d85842e3c3fd44a5f01967764c7721ed04ec092be47338b215ae1.png',
    id: '3233',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66330e3bd154a1d6475216bb92d2100e0835ccb64e520186d77478672007f7aa.png',
    id: '3234',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/713d00cedd0274fa1df93cf701b0183b5145d49f16ceecaad28cf8b98e5568dc.png',
    id: '3235',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab7f187977fc8d3cd8243034bcfe82d2cb4f0192a603337962903ab46ca77461.png',
    id: '3236',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b5155b9c2625588fba1723eb132e9bf592f538eae02a9b913e1be93007ae2c6.png',
    id: '3237',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6f9757cc77efd3c583572f859f4d7b50061666cdc03d5cc539d1713a969ff71.png',
    id: '3238',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75ef0fb06b2a0e0b6285df38bc348372f47f5da8ff842c0839e5003482a2c4f1.png',
    id: '3239',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ed7c51465a6a76f281ad7e0820f4378616feb695a1f1e5f219ef10b498ad76d.png',
    id: '3240',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/239c9b2ced9bdbba9d8374230e6b8b3721a8567af70db6a68ed3b3c66d7ef344.png',
    id: '3241',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f46167c31d1a891e138a4485c45b9a5169bf5c8081574a6ed17d090ce3a80ad.png',
    id: '3242',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e7d1b33995b50628dfb261ac0244938828b1389c407b34756675b3b4ac2433f.png',
    id: '3243',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be6b50aa11c142339202a70d21fb5964a5e405742cf0aee94a769acfd073c480.png',
    id: '3244',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/637cc53fc445a7d07e67750efd3eb1e9f166daff0336dcfc1dc9b6b704e6c33e.png',
    id: '3245',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c47b8c27afeeff1e3044d8e65553e7b47a3f3dfe1616298b441bdbbbfa8b147.png',
    id: '3246',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c252b98181dc6b9fc3dda89531ff01389245a4d403f0a27f39b722e704a83f51.png',
    id: '3247',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33b9c7f85b3aa9d53ad2f9171fc7f62ed352fc9afdafabf0d8732dfa175bb501.png',
    id: '3248',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a797d3bebb16ff2797fc9053b3a68cdaef947573aff00b52b0c3deb2bf0917fb.png',
    id: '3249',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96b05d2f9db7ea59e4717e160c895949c87c807314f733c30faac3b3a15d1c92.png',
    id: '3250',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7332746584fd26f35642306e732593853597adf0b86534905907c40016c0c8d.png',
    id: '3251',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3c95c338727f37b8b7205ab4b9679e87150260a45c2dfbb09dbf493107015d8.png',
    id: '3252',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bf059fc3b5b23719282da56597b33d0dd76e436938978b860f92059ba8c722c.png',
    id: '3253',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04fb7df21013f66bb5092f0b149b4f8e2e17d274b0b059d202455aa990a97874.png',
    id: '3254',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddc2af674a35d87ba6ca6eff9dc931d445582763dbfab3c3e22b833b7de01ece.png',
    id: '3255',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0dad59cbcf3d7469cad16825c2c902389cf138c7f2db004b2dc9e2fbca22db65.png',
    id: '3256',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Alinta',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/279e294b381344282316b84b9360b4b166f68c7dd899a318caef55f7a4bc4203.png',
    id: '3257',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c21e3076ded1197449d0dd38403a31686ab411ea4f5361d2fd1f81fd00178024.png',
    id: '3258',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cf020affefad4a49b3fe932351fed2a53a9bfde24e0a8addde32a0d6cf9d23f.png',
    id: '3259',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e1dd89296c163847b347c4c736332ff4bb8274fd9077706017204586d584509.png',
    id: '3260',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/260f5bff494b50569df1110dcf7f9b99d343b847d1fbad6497af394448f41fe4.png',
    id: '3261',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f53d7c1be1d033aeb8e0efcfd9708b11eccb19ee82def48b389c4bc84d32d00.png',
    id: '3262',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e1acf9fe6caec7504804e04541b6bdc284d1d0b359ef7d1ddda1c42ee148a53.png',
    id: '3263',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7afb1f35b5e3905a39d967e7273004e06acd45e26b77f8c520390bbbf7b9636a.png',
    id: '3264',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77df9f1f5cfeb87da8ab6acb5cfcbd7a9b542ef1bcbf748d89daa565811d9cfe.png',
    id: '3265',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdbb76d104c57bfedd57fffd976f820c0a8bcc3a774eb43788325179751fd999.png',
    id: '3266',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9607ceed2cd67333b5ec8a82bff3d7d0b6f3cb7f3cbf71646731661939441fda.png',
    id: '3267',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/50f198e164bc76eb8708e0ed79d0a8a3ddf5ae043267e1a7dcb4f4776aa74360.png',
    id: '3268',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec93300d9e62025e8ebf5692cdb76de0d55166f54bf4de4b6169a915cfcb53ca.png',
    id: '3269',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de70019ef8bc8781b51259bfc9413e4126332c30f507ed5648add27719e457aa.png',
    id: '3270',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3484c2d3af4339d0ea0a1eb528b5614354c3376626f912825b5670fc8e0abf26.png',
    id: '3271',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a3f9c637b3b839420e45498352dea3f77ba5c3a92cbacf9ae3e4d7cacbbb15a.png',
    id: '3272',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/826fe463e35e6ded71a6baa354a7401488cd6d6b81e44aa484c9eca894b4ce70.png',
    id: '3273',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d8518a025c1b23d9ef48e7028ec5660933e9bdba148e7430cc1c4ce264ff237.png',
    id: '3274',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/584873f9880e29ed6d714d8a81e0af245ba43d79b4f6712109ba100fcfff6da0.png',
    id: '3275',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc9386ceee84208509fca0a398a7af90860e51decc49985f45c3cfd15c30ac96.png',
    id: '3276',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5028dc550aec3cef9d0c6f5e4caed72d7f9e8c7b9dd199cb8afbe5918fe8bcc.png',
    id: '3277',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb23eb93781baba6c19401ff87479fe77d8ea7bc5c913531c62be7efef07c0de.png',
    id: '3278',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd949ccdfc46abadc57824f4b82de7ac264756aacfdca61b91bd59e2e6261ae5.png',
    id: '3279',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5df988769fc21754e512e000307fddd8ce1b9513fe2ddf6a60401691378a18a8.png',
    id: '3280',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7280cb7909153c44e835655732d6db87176f155e967b00b12e448f48754c0246.png',
    id: '3281',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skully',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41672974f3e6a0874d6583bdc7384e1c6ddd8d652464ca7a8405c7c0fc9b832e.png',
    id: '3282',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9146e26f66ab18cfb413ef9074df9cb860d98f8b935ae763ef1eed406b5b007.png',
    id: '3283',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdc933b2e7a251fe58676fcd8d251bbf04ff79918accae1983590f1494bb6fb2.png',
    id: '3284',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f26bff03434425f4f11f9dbd78eb25126a3ca9002a8d303651f69206eeac19c.png',
    id: '3285',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crystal Stabber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b3f71b1e65a3606dac9930b3d2059485f976e1bf423fa0e134377ab00e7d449.png',
    id: '3286',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3da82cf1880dd15d8a26de2b0e0e33b5fcc100dab1338414bd1528df7c289442.png',
    id: '3287',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c452ccc7214eb50b6c5109956c9351ab3c098829a9b37ac5d71bc9e0f10ed281.png',
    id: '3288',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6aa457700524a14f8c034c7e3814d43b7d440fbc3ea4d6544e75580a65299f31.png',
    id: '3289',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4444760f0311037be392e990f21d77ab7844c8c5f40effacd4e890350b4c0a43.png',
    id: '3290',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e95a28d69e0d8b458e73218bf1e9dd8bfc1d8696014fd8e6983d589863a28484.png',
    id: '3291',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28e0550b9d7d096f8665a7e0c1f57e251291dd88cb583407582dd577d467feee.png',
    id: '3292',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/678b503cc9d0ab5df61acd99c734f232998405da952116e3915c00e087681f9b.png',
    id: '3293',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2381f0f915c6851722eb6579df57db607e54f115c0660c34f537e7fff7b52f5.png',
    id: '3294',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1fe11fcf0c770c1a56a9846ec999ff675e18a90df57536c89b284ff2239f35d.png',
    id: '3295',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecaf7088c151418253d395e4782c4bd0fc7852bd5cdb7db699639fb1eb91b63b.png',
    id: '3296',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/125fbff7b1cdf80d55a6811c80ba865ad98c75a0722e223b8336aafe3abcadbf.png',
    id: '3297',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7793fb540241097c16913af297bb8de798e1037fbec06c053353d0eef5b2b67.png',
    id: '3298',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d8fad7348243d7b5ba2ffe9d42eedfb69d4c4816cce34a268eb525d048a471e.png',
    id: '3299',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f9f3bab6d4ba039b4db6b0af4430be9bebb02066cc330f658d6c8e05347ab6e.png',
    id: '3300',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59c7ee86b713ade23be7765e63c2c088a83c866e9647dece0aabb9c1f313428d.png',
    id: '3301',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f8af123d00f961e31f4ca50af8e62edf79405765720f1a83c653f630c602fd5.png',
    id: '3302',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c697f25fa313b778e881691fcbfaae187bd0d0569ffd4bec94b2074337301c9.png',
    id: '3303',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5523896024d4fe56a440354fc2e87bfe125689676913289428d04ad50542f8a.png',
    id: '3304',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c38601f93619c40db440036bb809839dbaf65fa91e9cdd899f2c63af2963ebc4.png',
    id: '3305',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8fb01fff7c096bf295476671226e8758039f03e3a83a47e9a2ddd3a452a93ed.png',
    id: '3306',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c56f7df58b290036c57262613e929c02e49dc4ebfca2eb462a74da8a8b23f79.png',
    id: '3307',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56bdddc582baabda3b6965a3b01a0c45a5e3bdee9109b4ac1b0e2e8e4b67e41e.png',
    id: '3308',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e08ef04526f52e0ed4737beb60c7f8de915e0f0b69034ab85ecd8afb1986666.png',
    id: '3309',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6993bb1d6a8352da451354dda324881792d0055093e16b9394dc3cac1547a09.png',
    id: '3310',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d110693f66a1877150a431e4f6d552bc2da9a5bafd7a9f24d4df59acb5e99e66.png',
    id: '3311',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ade0e95abcae0621a5e13af7e7fc0233ca47740e4cd7adfa87bb9498490fcda4.png',
    id: '3312',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6dd577d0ab43d2016e83a4ee45008e33e5c33659c776654d8b758dae642d6443.png',
    id: '3313',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c661f04be71a8d74f07a9db45515f00d9066c2c934e27a70536b796623b06584.png',
    id: '3314',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa4440566a37249d2fe14fabaedd3406fbd9d509c040175d7821cf2c36eb5ab5.png',
    id: '3315',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fcd54add0f7e2fd98f66b453683dbd641e42e1c41470169757fcff1e09445bd.png',
    id: '3316',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad7a69da441b2ea8ac2514a55a49924ea7998bc1318d1b71c2cc5cc54291e092.png',
    id: '3317',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0473fd4df8c4ec74882e61245aa75126954e9ad82eb31de44e1f9f792cc2cf35.png',
    id: '3318',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7bf850ef0839cf01c50e1b9fc93496430b34ed57d50944a7f1fd5336c035f07.png',
    id: '3319',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41de02aa78a6a9820398d79206246e2e9887b258412dee41a08be22f22e57953.png',
    id: '3320',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aebf1cfd26bd4271439f093180b2651e48bf55d02791cfc2e4d84470bfffaf6f.png',
    id: '3321',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7e12a0aac870c8cf648454e62f9816ea1e55902c9d3ca0440e185cb3a876adb.png',
    id: '3322',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc94bed92a9b6bb773114d8df89ab30e07db1c66245e476ae9c1f024882e9a9c.png',
    id: '3323',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b64943c934329350125acfce63f33c777ee7a52c4dda0959136fb9dede9c53df.png',
    id: '3324',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cfccbacec70951d31be7956174caf1ea3b156c0f3ed8d6db2a9485200205b71.png',
    id: '3325',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d951a2918c517fde57ace7c1c5454e0ea1a31d1032573692edc6f99b53054757.png',
    id: '3326',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cd2427b6edd9faf9bec5de720539bed49cd4541eb1fd57104d135910e744b46.png',
    id: '3327',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ea248a82e32e0d74eca25f77d805feb77dcf04a301691743690145e2b0e1481.png',
    id: '3328',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17d3d17e5250cc19370cf784f48c9f65e33d73b4884feabebe17799f11156acf.png',
    id: '3329',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5249464fa9690271fde403c8f61b49282a9dd318c9c049236b3acd1d0fd198ed.png',
    id: '3330',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/924f3b72ab6d83feb5adfc8fa514eac2bd50514d14025ace0fbb03fe7992c146.png',
    id: '3331',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8534abafa0fbb5b4c791715d592b1453b5308d511006c140b29eecc535da69cc.png',
    id: '3332',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4228de3d9160a528f3da3e6c513593f3a251258f3b98f727e71df0c0e1ce078a.png',
    id: '3333',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a0f679f9325027847aa508eec64b4522f7734900588eab89cc3f2ae05d0dcf2.png',
    id: '3334',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a40a8d867467b2e2df6f6d09e3a3d6a3eefb59815b26e4e8e2bd5cd270a1626.png',
    id: '3335',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5540cc7a4b50e55777e33d50255caaaa02037f31369ca6495b7943dad3014102.png',
    id: '3336',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e540953a84c274867691feee286202c4d3712f21f492ed31af79ab62016bdba0.png',
    id: '3337',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bf50b978b50eaf5d1de070a5d42106d3608de8acb3a9700af66ec90489919d6.png',
    id: '3338',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/694c2e5ca791ee89dd4d07cccbac1fc212d2ac2c16e708fe6857c702d14f6020.png',
    id: '3339',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6e941ba02faa310b4303a0736a7bab1b027aa73d2cd85c654a59f1c3b6b4392.png',
    id: '3340',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57b6f4ba34396a39c74b5dccf7cd19ac6f2ad86f26125505e08f1c4c3f879ce9.png',
    id: '3341',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89385e1de7b466f0c43e0cb5fdb3dd758fc9f3e28955da48ec845d9fb28398e4.png',
    id: '3342',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca60fcb84c2de52b2c0e5da693b4f22fc3863df96803a03d4fa9c0ff4cdd7d0a.png',
    id: '3343',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c42b401e773cbd7e80bf7181666d792ea66d37015abc4732d8023e50f27fe1b7.png',
    id: '3344',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1c18336cb5bdc33354d23eadf4d4ad5ae5280458bf3d1d18172bc5574d8ce41.png',
    id: '3345',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fa6801e8b69039721cfd561ed813189fe84e92445a9274c7adff54e0de360f2.png',
    id: '3346',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4d2f6d57ab25e37c845dc6af6ed0d6ef7a205a8fc509e1903fbeb7ee3ad0605.png',
    id: '3347',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5435651f3f38b47e726982ad5e6bf98e78d0b26166c808a7962bc28e8cc880e.png',
    id: '3348',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51d3e4ee01b929a8b784c6c4fd2248ab29dbf267132e3f02566ea8534a1814d9.png',
    id: '3349',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23d7d1eebcff1c8255873f801bc522c76ebf57ae7f73d0e3cda6581795589713.png',
    id: '3350',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa78f77d7036771b86c59cbbf5dd16103b55d47b4b6ad4774019335484d7e020.png',
    id: '3351',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e168353b0380c175ed0986603ef548b4dc665caf3ee41c1a192e153a681ec3b.png',
    id: '3352',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bfec2a173ef55cca656ff08c22a6645273f39c1739a77af332b1573d56cc07f3.png',
    id: '3353',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e7cec44ca88a5ce4c57f0dde4ef54a030bcc88e51471fe51c350955d399c535.png',
    id: '3354',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36ac476250ab57a188956a75ce081317001a29e31948ae583153a8a6b4d18a99.png',
    id: '3355',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f65664259b1ef66f38a03b1a1fc602244fb627d05e5b69dc418902e529954720.png',
    id: '3356',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a93fb04637127ca64c1e9bae036009cdda5d51abf4755185f991291bf68a958f.png',
    id: '3357',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1aba85e77a38b594dd37065fe1a3cceee9d49d3ad42d187ddfc35a2b7e36e8e4.png',
    id: '3358',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e52a5803086030190dfe6925608a983eaf9b528eb9fa56b4dcb8eca552914750.png',
    id: '3359',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6ca038e65d597d2c670cf2e70b5372d3c01d14269f910c9a150eaecc53abe3f.png',
    id: '3360',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9feee771185cf4ccb498fb80995c6170ccec5d7e7c78fdd02d1ef34717b57f3.png',
    id: '3361',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19f8c95e011ce8587484e88873f6d0225610874d72b3f7272982e06fd6d35e47.png',
    id: '3362',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82eb8ea1bea75c4ee1cc1581ea74ebdf5ad87b60ed3709b155776726429f24d2.png',
    id: '3363',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/209eaeeeb00cc6958486cbf0ea92d664ef6d5a33798c5a3d54df604f5dc050cf.png',
    id: '3364',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2507c7fa5c9faf98f718e1062e1933cf10790dd36849985b74ccfa606e950aee.png',
    id: '3365',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18540e41740c70e9bbc8f3adb3421eeec4d2f92f8e7361d3142ddac6b4e043dc.png',
    id: '3366',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2dcb46c1534e6e3bb1d3d223885969d33238b935876f66c8ab58a7cc1d48ad8d.png',
    id: '3367',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48a2ebd4fd77e97fa8a756192670510123d0928877bc215e847f914969d5fc3a.png',
    id: '3368',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82485ad9d2ef6b11a87745e34b71aaaf2e153d348aefe813e84de4da13f348d5.png',
    id: '3369',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eea7986380329fe4a2273ecfac5ec8ff5ca3b13751d6c8c3ee2d4f74ad12a69a.png',
    id: '3370',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc58c12b85bbff312175c279842fc024d0ef8b538b40277aed9ba296ba68d80a.png',
    id: '3371',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f123ccc7330a3c5d1c8bda8abc8795b7256560f2c1e0bef4910d8c4f45539fb.png',
    id: '3372',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee59eb454709c49a648dd8cc81d0f1f825e0ea43d6e7359e46652d65e1d9d345.png',
    id: '3373',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee1ef502af2b6368208cffa9699ba3e90be0b530527b3f22f2d7712d90737690.png',
    id: '3374',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Bubble Blower',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78edb4871b05985c5439eedb420979edbb310f82cc0b35579a0bb65a45400828.png',
    id: '3375',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0581b585e03c4601a1f0f21a2b2ad472a574a29b8223a6470d52171054830450.png',
    id: '3376',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f2473c099ee79e599e6cd671c43349da60035a67cc16f6578eb0707c17dae37.png',
    id: '3377',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1706e949249287b837fb8acc5417a07f6a5a1cb58cd1f471f965fe6fc829e98a.png',
    id: '3378',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2708f40c72521d77994e46d6bac77a7d662c7a6beab8f3186604c08eb80c9214.png',
    id: '3379',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0bc631dbda539e221897a8ccd2a262a5c60fda6cc972710d35e5c0a01c2c194f.png',
    id: '3380',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d9498395058deddd46f2a42674bf6a35771f240803323f7605e4c7c720e3234.png',
    id: '3381',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9a944573330964feeedf2a847a1b7636b015d23741c3ed98a736d3c8532ee8c.png',
    id: '3382',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f2264152c060f7569eaade102ff37fd96fed1690939b84ce970458fa165f4a9.png',
    id: '3383',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f654bf638441d3cc6080a538143b3ce406067438b808b08337c9c2f1fce84b56.png',
    id: '3384',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9e781d662a72f20ffcdaf602d52c6641e26fa653b99a78cc761fbae07dba50f.png',
    id: '3385',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ca14cd610e4933af8030c64455ee23d14f7dede98743582e31db636f2449632.png',
    id: '3386',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6343286f032b1bce679af1542d7fa64efa20ba2dbbe7c83b62feb4a8bac7286.png',
    id: '3387',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0bff8bb2631b4cf603fb12180cfd16faebf9992438d7c10868d3fbb0eed3f58b.png',
    id: '3388',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d967ab24ac7b821dcf0923e4f3c80973115be5673d3c20ce6acbdff14fa22676.png',
    id: '3389',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f5e486d8e71a8227ded67fb0cf72c5aa5339a481c1b80f571f9bf1639579fd5.png',
    id: '3390',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56bf7dae548bc6f14c6b81e34cf14809ced1c3c62b02a65334feb2bf08b54024.png',
    id: '3391',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9b90f0de2c491ab7b9ffcaa9140f1ac2007f8f77a49138e55d82aafb5e16a7e.png',
    id: '3392',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37d050766b2c925df2db4dfef83e39328d5e2faf0deb66ba59f3a12a4149fc37.png',
    id: '3393',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1894051b98252c1393698049b0e5573ccbee9c55b56f581007c790fefb501c82.png',
    id: '3394',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae76051f0f5e19348b72ff45582c98d09fc3ce89f6a0d916e8c8a6e15ea30472.png',
    id: '3395',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab8f67fecca8ce42f3040758ea709939cc3a693e98653518a424022e3498f3ef.png',
    id: '3396',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a63643c44c26355a65fbc678579024f626fa41578e52d9b34d727eebdbf23bfc.png',
    id: '3397',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a46d0c5b5bd5a2f8900e726049b1a238b4b55f2b070a614aa63a342830c85c79.png',
    id: '3398',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf982d4e4ef15cc583a5053df9b0d3b9c8f2fbe36abf24eba7a4ddd88094c48f.png',
    id: '3399',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acc9d4cccf5f6a3acf632be05bbfbd6474790a244287694490a861c5d5d90d67.png',
    id: '3400',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/382b931ef1dc22ff53714bc4018a4b432ed7d3ed6906e06e9a246733526d32e9.png',
    id: '3401',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/337f48b5606e1dfa4376a4020dc91bc1ddb078268f4124fcdb335763d7f64f0f.png',
    id: '3402',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b22338109536fcd17c24b35db37965a26af2441984152dabb144e97034468bb.png',
    id: '3403',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a80922e77ad4692d16e3fbbf0dded78ee24a305584d5c3566ae7600b7319f27d.png',
    id: '3404',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/047ff9b2a0dd0227910f2b674b00b884100bbdf471e85256b1a2003d20d4f490.png',
    id: '3405',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a64b4fc56354b3e3efb2c74c7b9448d3762aad54ea671d199e8158b41e8599d6.png',
    id: '3406',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c0a5d885bbcb6ea9617dbed081d58848b8c5ffb9727983cca2e5a8417110b6b.png',
    id: '3407',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ddfc6ee5fc6101dae2c0b2c7242f4115d80f17d0a50f23ffae5bd0df07af2dc.png',
    id: '3408',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d237b8a6ebe68941536aff2e5a18cc62cbff9b7a5fa9b60f52faa85a3dbf0005.png',
    id: '3409',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d24a2bc1d52c2cd537009261043a1c73944afd62bec42053e12cebb1eca2eba8.png',
    id: '3410',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7e41183e28f702aae476ac88646d0a9e5e9694858d2a0202ce4a9acd7b7f8f9.png',
    id: '3411',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3bf1527193c59e1fe6db0ef552d00678acc825c2bb34c806d10c12e3ac2bccdc.png',
    id: '3412',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f61a4921f574ef03eb0ee943d6c0f6bfdc2ddb89f9f10434ff440c84dd22efa.png',
    id: '3413',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b68a30200d78e3f7610e7274af6ca8cc10f75d19b9903cf302d2892f14743c64.png',
    id: '3414',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e1e66b2ca2fcb5cc89fd691ed6e73211c645fb9deff82ef9e8f0dbe5fa21034.png',
    id: '3415',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d206771fc2028ca248854719ed0c90a81240df4886f0d679a9f14b927c7f868e.png',
    id: '3416',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b3521baab865e7ccf49fc5d89800f136c7f1ac020c5668b1294df826cb627c9a.png',
    id: '3417',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2585ecc739f5e97459a14c676f9401c25ad1f9027db61de0960ebaf361169b85.png',
    id: '3418',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24d06c5786f81760aa2041d1e79e5af98ee8e23ed3e918802845576b370abeca.png',
    id: '3419',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b19e4d9dc142f1d979aa06daf875caccffefc5602988f04b67d973761b75509.png',
    id: '3420',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0bfc11cdfad110262325e0cf570e59657f4c47bb4c24dfb920a8fa0b9229ccc0.png',
    id: '3421',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8b64a93281e8b3e3236b8ba0f423a44bfa6bb2005f65641fdd140acd16be043.png',
    id: '3422',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/effb249db4956e769dc914922080143ea8bd82d08610e474e61a0eb174b4a396.png',
    id: '3423',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a69270904f0e5a8006b1f117a4975f19f9aba9f4520952a3a6dd920b2aadfa0c.png',
    id: '3424',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e217b86e18e2ca6f3f8cd5cb554697742cb18ffa58eb1f43e1537f9e6891f130.png',
    id: '3425',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea9502cab2ff8473eda5bc0c1b29c82c735b5392fda10fedd49d308a6e601255.png',
    id: '3426',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/80eded6c3d67a94ec26bac9751c981200d875ab326fa5957b86aef3c6093c78f.png',
    id: '3427',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13763d67f0071fd0c5aeb217b06d48064a454d265f05c6a0e00f3aee418a584a.png',
    id: '3428',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3507d508c11cf13b685c0c86371ff51fe9ccfb14e4302f296c76af3b9ee42d2b.png',
    id: '3429',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b348012b4de7fa00ed3ef20e43c72a4df837b542a4805da58a5de9d53aab2163.png',
    id: '3430',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/475302ae5448b1f57cf924b1d2efdc3e99b405747e64e11bc2fea8d6c4b0f8d8.png',
    id: '3431',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96cdc2fc30092eefb0aabecf7d2083896750cd5b3351dbcf0453d35383180e7e.png',
    id: '3432',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e6d26df973cfbacc4cfe2e50d5b0d89234bed37efc210a89faee9f71a265a21.png',
    id: '3433',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d788dcb9168e7e3bb7df42b8c910192e62bdf478bdd5be020dd16b4f99f64e7d.png',
    id: '3434',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
      {
        trait_type: 'Weapon',
        value: 'Spinal Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9df60c00656b0136f1b03f6fa1d14f60b3b0cccd714cad576cb98f93afb35be2.png',
    id: '3435',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2d32fc35df09acbaf3a29a22f8846883f2959805d03324157a211665514b367.png',
    id: '3436',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8759649e5c02e5ba52408a3a7a11a991cb27bb57306ff910066e52217494f3d.png',
    id: '3437',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d3a6d0ac52856cc3ec3b31af211401be959de4257890a0e13f18bb98e461013.png',
    id: '3438',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e06a44aaa1589936ed31bea81954ece2255c3a393fcb01bcca432962da5cd8b.png',
    id: '3439',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad5442ad6d8d825c27098199c3ced33cb9bba29bf8f326a23ee9933ebbabbc60.png',
    id: '3440',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c4937b07e26090a74412ddce3b9596a87e3f37590818a66a69c3cb2f7b2804b.png',
    id: '3441',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f0d6f78fa420717526b2b78864c29b9770591e10be8b3897b9e6a25075597fd.png',
    id: '3442',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cdfe3247fc3126676b087894e9357c97c31b03330ba04fc27142117d897b9a7c.png',
    id: '3443',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cca54b33659105047d12eb307da029980c90317929abab3c3c49731be003a20.png',
    id: '3444',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73002a27add1be1c67991281778f626bcd29d098cae0108001c97e847d1af77c.png',
    id: '3445',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c0c97b2e5b4fb6472d16a047b0d391b1fd8d9bf3da7c02a6d2d1a8b62c8a86e.png',
    id: '3446',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b0862b0af63cebac7de084df808c87749fafd2754075b94fa7c1de8be75c345.png',
    id: '3447',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc9aaa9d5c7d5981a6d1399db7f1e3cc7ed83d8f49123cd15540ec0f9297711e.png',
    id: '3448',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3938dd929f4c1573f8996a919c2391c728ebcbea76a7082bb3a1669150fd3911.png',
    id: '3449',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d0823f911d1f1704634e5d4b5f5ec26555bc982c985f31277c1dade7e4662a0.png',
    id: '3450',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c08c2eb557548a6a099c30f405d7d1743cd3fa9146d94dc538258ce7e857c8b.png',
    id: '3451',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bc9603950edbe32c64658355b37e925a0541a9744cf6ed73e0dd5f8b9d9d50f.png',
    id: '3452',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbe8fba9965181239a4bc8bad3312c52fe5c58adf592112474e35bb7113d025c.png',
    id: '3453',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71698b6d8dcf2dfd962ba9b4999a6823fe66d5b359492eaac6d2ab575493ed63.png',
    id: '3454',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43e179cac519e6f86ccbb994425ef75e798d1031c0cc41d21949c8b1d53b0760.png',
    id: '3455',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99e4086140097e641a87f47c8601210d34d9c6d671f0f4a71ded8d733e58feec.png',
    id: '3456',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f0992a0fb9f0f05f6bf48adc92dc427af839f94862c79dfa3d55ffac3f52623.png',
    id: '3457',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7a5b4dc470a02da5a61c210d23a7036a210baa6aaac518b1839b72df545dc8f.png',
    id: '3458',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df556d25c1c9e2f911bb6b8afa255324173fa86aa107b7884eea923a488d6b0b.png',
    id: '3459',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1ffd29974f478a26ca69f2f96810bd168e2b45b30b2290f753a0571d9277e65.png',
    id: '3460',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3932754e2d73137778386a8dbc6c88fe055fe02002932724def10723c28b36b2.png',
    id: '3461',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e95a8f107c16fbd1c942564254686f461652040ceac4460a5e2c8bd5517395e6.png',
    id: '3462',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd5de6269947a0ef2134f3b507372efc0adbea971e3439cc6368d5881a658b3d.png',
    id: '3463',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42bca01c87e3f9935ba93c9d76a0ac50b2ad4676bcfd33489cb59723628e39e3.png',
    id: '3464',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff63ccb8f258dee18b01017ca123f4515fd35a57a4015d2b157cda30b6556be3.png',
    id: '3465',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b423015c1d30d6557ea13c92923cd402dab828bedffdf3465ce30dae4a9cc4d.png',
    id: '3466',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc8ad2b344a14f67a5b6c1af5a5118ee2f770a4a58a4d5bcc25cd9fe0c2864b9.png',
    id: '3467',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c95ca9773a59ec57aa9e5ca8e672c2da3c61a5a3e634b10b79783bfd28bd81e.png',
    id: '3468',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c73a001b3b970baacd4628cd6cc78a6df75a6a1f3b60747075886018bd1e4382.png',
    id: '3469',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c82bae529c4de34952858d83fbae916b7648b84db7fb630749505abba2e6c25.png',
    id: '3470',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3b103a0b2546c2c057e549be6760548db6a9842e4e8c05bbb6915a29f23088a.png',
    id: '3471',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b372e427d57c06c94c6aa694d2b155b9839bb693a61dccce2441c1c9440fa227.png',
    id: '3472',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2c12d6e86effe10710d0697ca799071a1647e165e740cb3f9ba0c27749f8ab2.png',
    id: '3473',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/734312f4ba754861f73261773c6ea4bfe2a9e835d658d1207fa80042b4a103ef.png',
    id: '3474',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1be64b9246326e43e008b8a42528af109ee4aefa69e5ebf16b33189cc3ae828e.png',
    id: '3475',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c633d6688ad61582a67f7de9b54867eda38686bb7655652e653673c0a9cd657b.png',
    id: '3476',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Blade of Great Heights',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca2cfe08bf24865c5da6557a63c4879b6f092714cea16a6bb8ebb9d5ccfd0f9a.png',
    id: '3477',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63b35961e7babdc88d2b03e650a20743961b29bb3d18407c90631e3a6921d9ab.png',
    id: '3478',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47104507c42945b45bf429d5dfd1a1d4f7e90a7656cfb70e06095cb2faa2daf6.png',
    id: '3479',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8085d621fd52a60c37fa96474ee933849cfc5affe4bf86b5952acba4be54456.png',
    id: '3480',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4877364971ea5861139c0c95c756f2ab79e3403987a2e9ff35a3d5749e94f41e.png',
    id: '3481',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d29ccd58611acd6bd06506bdec089f1baeebe9d257a0672950fd4789fdf8ebe.png',
    id: '3482',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11efa825813e0f283356925d1ca729fea2b552562429d6857e0e70952dbf2237.png',
    id: '3483',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d95215f1ed6d243aee729a6951c99cb8e9f3e54ec56a6bfcc121ffc8a54ff2c8.png',
    id: '3484',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4618919b850bcd3540823d874346e514571afb9687ea3ea41a469d44b3d9b069.png',
    id: '3485',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9cfec9e27d189baefc278a7ddb7c380f445bdde409a7fc0acbcdf2e645a6fac.png',
    id: '3486',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90dd6fedfc2fe7ed426ba14f4d48d0d5c943d9954fee76f61f464886fb2d60e2.png',
    id: '3487',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c494fbd1458be368db864fb141350bb50a43dcea12f9a9ae83b09c54b99db4fb.png',
    id: '3488',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f3a2cb5174cfcf3144b128520792c6c1703e95cc4f0198b2a59b6e85dd8b23d.png',
    id: '3489',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4b35121bf20c43ff91e34c44ecea12c75ebfbf91f2f650dc7084dcdbfe4ed15.png',
    id: '3490',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b135aa1a1b737b0e4506082a4e082fd74a85d5952d9f02e268edeb04a9bd582.png',
    id: '3491',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66e0a88b6c7dd971cb1f336de17f5d1e338a4c26fff2095dffcfc770ffa04dc6.png',
    id: '3492',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d366479840b11466098c9e8ac6c9e4f9c0fa3bf371a421537a5f287e9982424.png',
    id: '3493',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9eefd6f09840d2efe2a7f84fd56f0cf6c3e0f8f5cd4b76e9f5b385873a3560c6.png',
    id: '3494',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8cb716ada0dff4f2455e80188246463d842097a73e85db910f756f958fa9a9f.png',
    id: '3495',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f5b7793f5bce736fef8eec531e61b108af6d66a0412756ddc7ef0e953165491.png',
    id: '3496',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f16cb7825423c6b0bbda9a186eb68f92724fa3e16eacdf023d680341b4dd633.png',
    id: '3497',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5fc914ad735f5a973b69ac24e90072c3700df6104a4b19e6b642de205a6a550.png',
    id: '3498',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffb9030b04f89eafa9a8b944dac56472390fd6ca8baba2e39bf13a9d603ca81a.png',
    id: '3499',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cae26a84f72741bbc0db159cab36cb3d398c45caf0c093cac412094b011029cb.png',
    id: '3500',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eaddc237758aa013955e96307f1432ce2b509a5c1bd6a6b527fe904bf9b464fb.png',
    id: '3501',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0d225d73f9647d8c5672a636cd59b34e169f03511522b53d626d7bd9532e8d2.png',
    id: '3502',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd87e9d72fb09f88eb85ba195e208e215a78acba7efeb4bd4192b6d597ed582c.png',
    id: '3503',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b890e10ef05548ffec9aa9b5027ba73f96b42125969308c0c4f9813020d1df20.png',
    id: '3504',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5632dfad182f5112488657cb806341497c0b38931aa905312e4072ad6c304052.png',
    id: '3505',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b06266081ea0370f4efa8ad696c74305c517fdce7a48502947969333660605ff.png',
    id: '3506',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f983cf0401f9b3fa0f47f9684242618e32e85ab102eb7d4eea3cfacb61fee71f.png',
    id: '3507',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8139d81a1b937f9a5306be38033929bcc41ecd834afecf5ec68d5c730d1b1103.png',
    id: '3508',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/007073a656b14341e46d8aa0d9c1031fb86cc25ad2551fde2d808c3f78b6788c.png',
    id: '3509',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bb264a56f7f88ce8ad55584aad0df22f08722fa0a0cce7ab2c166c77218217c.png',
    id: '3510',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aeccbb4e1a8c621dc907e3d27a5c965f587a493589c2056b15308f1f89aad133.png',
    id: '3511',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Shards of Iridia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2bcdc9c30658c9383743ee4833ca87f83727f19c055d699814a879ac376dedb.png',
    id: '3512',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e477d9cdec4ffea2f0a5c3ec0ce9c2904d392c89f03a38dd181749505189ae4.png',
    id: '3513',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28c615dabae2d266774a1b4444fb436dba49c9335e1972b7c2452097eac9ede3.png',
    id: '3514',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5be0d27311ead3d580cd637a8b91bfc2d27cd78c0192c94c54749a5d28853795.png',
    id: '3515',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e4978604940ccf9dfa24482cc7cd765c4e32a0f58f6c21806584f851db241c6.png',
    id: '3516',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/090fbbea47a4b72b341c70957637b67e40a74539e4d001ff95f105aaa073239a.png',
    id: '3517',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b46e5f511c51fb266a73018b5fa1fd8542129ef093d48830efd2d0b6011ac1f.png',
    id: '3518',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0785df8076d01804a6964d6f02e601f64bc68ee716b6706a3e56ab45b6bc4003.png',
    id: '3519',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6816459b958e81fd0b0babd506d474a7f6944cedb714149ed32e927055f26042.png',
    id: '3520',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ada5d3f8b38c3693bfbfaeca312fd985475f7f55ff3e8ad00a1e9de4585f388.png',
    id: '3521',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/802a89aab36cc5737625d94e8b5e8b47e241d36fb647eddd76806eb81d82eed1.png',
    id: '3522',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/794a654e79ef2513baae004933dc3d68bc2bf43c4ff6794a2bd460a92eda16d6.png',
    id: '3523',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da69f6aab1fad27a745271e19b8522f5f1337315c34b650ff55e151641d46a14.png',
    id: '3524',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24edfd470608de5613e546eb69b5a9842e9463cc0228013afbba2775782d5584.png',
    id: '3525',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b48d7ff254a209ab6f3b80646307d1f5a9717e09611483b709dc386d369b6ed9.png',
    id: '3526',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08b6e734946f0222c11fc060c98e3e97634b8fc1b6f8c656af37fa5cbff5d3a0.png',
    id: '3527',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e343ec72a1abba96828bdc62a6adffdc3a0f69944ed6ed5518be8dc16468286f.png',
    id: '3528',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5eb72dcc770545a734615f7eb4c60f7ff202be33a0b2e28502e169574121c86.png',
    id: '3529',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b6d567e79d5f1c19e8eff28fa76d31a4a83c21c9522316069a5ae9f98c64798.png',
    id: '3530',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f576a9e1519d0b2653e7d723ee616f0ae29ae13ee75204056273750c9c49d717.png',
    id: '3531',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b390d96e140676da5f9373ac900d9143d53ba10b0aa8449e1cebafc3a8d293a0.png',
    id: '3532',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b02ab2874f88eb0ab074883c5ebb5f9dad536f7217d70017beabdd12a78dbf64.png',
    id: '3533',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/584b9c7598941ab34c50fc9852cc4db2dd9df860ae0416565022600693e93814.png',
    id: '3534',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/083e7629d14cb907fb93072c5ddc15d1e1c1feefde97f564617cdf22ed762cd0.png',
    id: '3535',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fff9d8a475fa851186b1e5fb78ad7df4d88b50d72e91d1c3bd9b320677b81387.png',
    id: '3536',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51e529c2b8b4d463ebb0b6c116e64bd2f36c9ccddd8bf20dc3818f3147e615b6.png',
    id: '3537',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbb8ef322e3c7530b0a41ae5d221ce471b8e5b9a3f9b998d8e211c9d0e1c740c.png',
    id: '3538',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b3682211f695a54eb0ecd5b3bb89aadd232841f530a7398bd61e6cfa362b91f9.png',
    id: '3539',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75f65f7c1ee8244726a3db8b3174d847e3c1824c703afd934e4478d5a2d96895.png',
    id: '3540',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c22517fc639b92ca0f4015d1c03392fa141540d5f21f94f0e9cbe06a5aab53d.png',
    id: '3541',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47996c810fb71474ab492298fc50aea1cf80080c22f3a81f8a440833dee393e1.png',
    id: '3542',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47f45bedd4c673c1e16e9fb0707968433d1a7ea51f7ba3f0f9153708fdec9ae2.png',
    id: '3543',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/844bc097dc2d9ffccfbbac78152561f418ffea5025217519de7c6ac059c055de.png',
    id: '3544',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a116de33a45193ff76ea8b10016d1c6fd99f5f8206d97d38c8d92d581e361a6.png',
    id: '3545',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0600f6696f71432e5fa3aa8bf71329baeccdaa4177f152fe685d51af8ce64bab.png',
    id: '3546',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Winged Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/601401277bd0f165119722def82cc8f838f27d7509994388b423c2abc64445db.png',
    id: '3547',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c72f242b36ccbe1ec6b1933028c414406d65f0296b7bb6bc22df1b14d5ebb0f7.png',
    id: '3548',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c30cc960a49d8d48eb1023989d8fd07c896671407b6255e664a8a1442d64c0a1.png',
    id: '3549',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/935df788ddaec33c53c6e83f693b2544cc1a75af605786f55eaf95d07413ffd3.png',
    id: '3550',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44b62d80dacc22cc515962fce91c735e5c2e9f67b883c6b9d12872487dee92f9.png',
    id: '3551',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ada6c65eaa1e625d90a474a8dc00fcc6d156c05d584223e5158d2fd7a031ff65.png',
    id: '3552',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f27b4c1ad322ca07956f14e8d9a7e605ca24552e4348f811fe910a8c8218d313.png',
    id: '3553',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e46262bd70f35f3b5d5ab21ebc51c65fe2256ce7dafd1f174424e718c5c54078.png',
    id: '3554',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29e506816409c8836290dc3198edc69ca14233f54ff16ce170b95066d8797c12.png',
    id: '3555',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5923f9712ab2c2bec9581b86fd017c6ba7464f1331491d9ab6b274643fde337.png',
    id: '3556',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f93a3a9457ab722b0df596fbb52dc3e91ae80f4a6db36ec731667b8f33d2add.png',
    id: '3557',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8fdc4cbd74edf04de585aef6d93c45f05e6dcfdc1a831716157ed1bc0f44b064.png',
    id: '3558',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a611abbf09741a860aff41aa354ec12de858910ec74afd93f372572071a704c.png',
    id: '3559',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b93081ae7f53d77b5cffd7ca912ea6c4bafa13d222d5f5fd44975a5329e43a0.png',
    id: '3560',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Monster Ball',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fc5810e9e5ff0878cae870198af21f327d616a848a85998176d5c5c79494e37.png',
    id: '3561',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d66318f85267db77f46b91876b192d90c4a662d41e76b722dc8998504a711e6.png',
    id: '3562',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b93e64cd6df4b75329255f6a10260fe71942b21d3920cbc04ea0c4d6fe83761.png',
    id: '3563',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31540ce4e3ae80244fa98172323036d7511aea72c3109a2df2826c718ae99de4.png',
    id: '3564',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd960ce5bec5750182df814f156191cf4a2483a520a7a859d6c071b35fcb24d7.png',
    id: '3565',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b956661e231e2a912b21aae779b2fab6c36feb1f849a933bca71ae91723183bb.png',
    id: '3566',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86133c00c66033ce65100aa7d71903c32918d2ad697e801ad91770127a2a8182.png',
    id: '3567',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a39c483ad87f82aeda033543fe8d97ea8f9602949fe28152211aaaa88d29059.png',
    id: '3568',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bafa5128331d67bbef66463bcdbd75812bfc4ff8c657343556c948ef60911f90.png',
    id: '3569',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/701e3fc1e126dacf1845bfec9fcc2a4a99a7f0ed94506b999539fff1df2e1a0d.png',
    id: '3570',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49dbe8b27508447bf0ecabf4e2d9c2d62d105ffb405bacf5d7981fcc907c5a91.png',
    id: '3571',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6884327dc4d9451cc87d115256ab708634d81b2ef28e977a5b7062e7fc258d9c.png',
    id: '3572',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c258d50914e6ec5b82338f7ab7d9670d82f33f493b7d4feeb77bcffac44ba210.png',
    id: '3573',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f417e5e183f0ef125e86b899760c04119038c2dc3d24341a144e64b1772b98b0.png',
    id: '3574',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea0ab7031f450008636b08ad61a2e5b61e7c0482c71960714da5ad5a70023701.png',
    id: '3575',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1785fce4644fee3cc4a369a5de2c9edd49037dc511e6e6a4beba0348d1d3d54.png',
    id: '3576',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0839d6015ce4b1031428ca446e5231c47060bb99e230a748ee579d2e980ed0d0.png',
    id: '3577',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c715a04930cce0317a52bf7bf127bd80fe91aa2459b464fbf73e245ec3e34ab.png',
    id: '3578',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2965f684bb6cef4eaca8ee1d489c6e2aaa24e0847b0fd71da7d53d5e6d031c27.png',
    id: '3579',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60e05dc4528e89cfcf0d544229605fba3c74eb63b6fc6d6140da1abf4ff5bb91.png',
    id: '3580',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff3fa49dae52abaf88a0cf276facaae8c0b6ce8ab7c4f511aa070bbb556ad8d5.png',
    id: '3581',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8dbb604e1c05464737d0ac0681dfcc03d0e979f8712950282449b891e63f2342.png',
    id: '3582',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10ac4401aa732351099c62d64e3e3357a7e26df23cae510fe6960d6c0a648c0c.png',
    id: '3583',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9730d3772c7e17d456e231c98e44525d31d75922abd8798123495023afe5a74e.png',
    id: '3584',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ff4dc660bd70e324f45acc0d59d510042a0643a120920795c19fe00801c8315.png',
    id: '3585',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5322164a40ce8811af4a1fd724e9b223b9ad8c01997e9d345f5c4e3d8582ea07.png',
    id: '3586',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d498a63a98881186ac7d86648699990342dae29475016a24005e959d5fe306e.png',
    id: '3587',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d45d70e15b88d7130a667fdcad65755a364adc65e2348e099065a15fac6480e.png',
    id: '3588',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84a590bee146befd003d96f787bcf1f6ae3ff27cf300df23a767b8684ff61f0c.png',
    id: '3589',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d80fb6017d4f464f459090e78747eb2242b8888ea524857a7c9ef47834a43bed.png',
    id: '3590',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/626aff918f0b7b07d5882e611d0c9624c1608175186630ab3b42ad554599101d.png',
    id: '3591',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e632e5924cc8bf03bb4c4a20e3cd3f0ede439d3e0c7aa4f67384c90153f17b72.png',
    id: '3592',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05408581a1be3fd39be45e1d40e092bc002a6ba801ab9fc49090864cc5fb21f9.png',
    id: '3593',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/396970d04303ce4dcac77145c98be425d3075aca2023d0df710194231d6731ba.png',
    id: '3594',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/367678906377962ddc73274d3f3d0efcc9840b01bd16c7b5524b60a6c5eb29fa.png',
    id: '3595',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4e45953d487985725d36eca7e31a795347a33e7b65cc03458472838a39765dc.png',
    id: '3596',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34dbf729d780e380f00a8b3178c07c92a81e89d26f068c97d6f1fcf2f666a7cb.png',
    id: '3597',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4239c7887c9c3437ff51ae8dcb7b031987df38d4b866132f16a82d011c89aa1c.png',
    id: '3598',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/471b3323b9b802c156539e50825774f1aeb3150030902a52b98528c6ef978c97.png',
    id: '3599',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f286170190311defe1f3be4211c75bf3ac46d20731b5d269ed3f418912473f3.png',
    id: '3600',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/980d2be14aab13e9a49210ce4fdef3fb86dcaf08379e7894999dcfc6607a256d.png',
    id: '3601',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e782421499722b1bd460883ec8ddbf479960c653fa9ac4c4cf03fc27b9f81432.png',
    id: '3602',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/690d06c734364e0ed1cc94961569f0462ed0942169f28d1fec816a8a98da226b.png',
    id: '3603',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/013bfeb1302abef1dce83d7b4597b72c32acfcacb3a6f41a2a7a120043fb8816.png',
    id: '3604',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75ddbbaf4a4c6013773f40f79cd6edd567d81fd7525fb81f6d9cbe170d6b5933.png',
    id: '3605',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ee352aa596d74e2a78c682e09732cfd1f0eb9301ec5bf3162e2735f92629cbb.png',
    id: '3606',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98af6412376f803239bdc21033969bb2c5c42787dafadf1c982291fc087df26e.png',
    id: '3607',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/beef282bb7d819ad6c1b3b0b1790bdb1ce841c7947e279c16870e20e05d436f1.png',
    id: '3608',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/834adcf96005298cd527b27d18452a7c22b0352c0da69d8a397c998557528f32.png',
    id: '3609',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2295cff92d78cffcbd594dba3e256d1190f9244df12e8f57f2ce62d847e7ebcd.png',
    id: '3610',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffc6cd2b1c4a5c0e571cf18c3e7b6246ab89c0bb7a391035067d412c94b51b5f.png',
    id: '3611',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aaafc47c2ca3df488d71a1dd03196a29ba801b474ea69a7ecae996df6a069fde.png',
    id: '3612',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28b6f20f47aa429f540d2fbebfd792d8824ab1fd4720297f270441437a1ba339.png',
    id: '3613',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8e06311bdfd5f983c07f9a8a038d84305ab172c27401f590252952d072d6a67.png',
    id: '3614',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d20094164a38574a4e3c4f2c112addf2ca69a5daa4c3c99a81531ea49d6b40e.png',
    id: '3615',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b917d5bc80b6d1ed8a59186009b4c6146a8cee3204ca988a53e88a83ce0ed59.png',
    id: '3616',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd289328b94a97492ee47f6a9f139fe4dfdd82d94dff44b654c4d6cb31317147.png',
    id: '3617',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20044f5623357258b944264c8e8a06b6e75bfed8de649c8a2f2b532ebf534598.png',
    id: '3618',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc0aaa17e718a421ea92d88bc38324143d84bf23e4b97603aae9a43deb224395.png',
    id: '3619',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53ea8edaa860cb8e05607a78c7bf2e91a1d04e77024b9ecc886729f543bceeae.png',
    id: '3620',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73dd2f8b21dfc77bb520c539a76cf04520562aed399413a45fb5dd3bf756e198.png',
    id: '3621',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b0594fe9a38d298e9d3ddfc2db54ea23c2409a57130d909725227ae80cec796.png',
    id: '3622',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb467256a1e5f58e1b8b846b0933167e0222b5cc6c8a43b0600bef0876b61008.png',
    id: '3623',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a321ba7c8bb933b1b493426a670f3b48042d1c3d91570527714a3c42fad5eb2.png',
    id: '3624',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4a92457fcb8344da58dfdf494789079d150cac7088f91f158e95393bcc354f3.png',
    id: '3625',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3357306f005181af8c1a95bb90a3a0fd9f15b59537f1e1057123f97424fac686.png',
    id: '3626',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7b00776a160d78caabaed5fb4d38b4f1d7175b9c6e1911dc2da11a69207d92b.png',
    id: '3627',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12764b4ccf8ba9ce8c03f268cc75080f637b3d11968e0e890056d5f102c5df9f.png',
    id: '3628',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9584ae4d108e1a1447f15b45c862afe15c6f2178d5758402e3a5ed66c3d2ca1d.png',
    id: '3629',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac2199ef4b01f4c24cbec791fb37092e4d45aaae8f3cf9abdccd9087518e938b.png',
    id: '3630',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc74627af28a6e9699015869d0cb6eb85936214d488a49e5e6b9c8ef0b538f97.png',
    id: '3631',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1332c3c7d80e3c36f5598984e0fd28d2b6e6af6b64534016287a5077db84d8c5.png',
    id: '3632',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02ae88ea4bc209149d8f863cdaa8af3be2d56f8e757c38dd1739b0b149a558f5.png',
    id: '3633',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a85b9ec0e63d7f63abb9bbbb2750b7af99a48032cc082a88471c120a35122732.png',
    id: '3634',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/585e58199a9a84ca5c3774cd5a99f3dd305f90bbad4cbf0e2f9641dc15de2c21.png',
    id: '3635',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a31ef41a4a7837f30ee2195861d69b81afbba3e7e504d2edf0fa4ce99ae0682.png',
    id: '3636',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0bccc529d42fc176f2fc237148e218c6d18e8080ca8a3be7c7890512f7196d9.png',
    id: '3637',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51b09f2c898a4e682694c4d29f7c8225d178ef0b6d13740178334cceaa36f0fc.png',
    id: '3638',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7f2930362a030499eddf5f226a1e90870ab7eea4c1c7635a3c5adf455f515e8.png',
    id: '3639',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c7010ab8546152079859d43c739761c0a48785a7f7d01f55a7b450df16fb275.png',
    id: '3640',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2dc64492a245368ed6d4dc9a7c26a7149d1a97f4dced3be8093064e58e0b6a80.png',
    id: '3641',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbfe62881bdaea0e9ccecf90680d93e017d3c6644ef05c8fc9e55cf670c368c9.png',
    id: '3642',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5495e773a107b23959d0e7c8ad5a7bbc9e51f945adcfd92725739cd6d551b1d.png',
    id: '3643',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc1d99570efce2657ee353db26d2bda7612f1bc6d4e897140f147e33c7d4be6a.png',
    id: '3644',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d88805f81ab66ae41a4aacf7a76e789b9cf3ab3ce74014b2b0324e5f5d42554.png',
    id: '3645',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f184304cfd9bba2f5e3448752fed4b52b0bfc1833a061264883bbe572d35c29e.png',
    id: '3646',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e2f4dea37bde77e49654cfc652084c8ef7e7e9eded01c2efef86975eb3694b2.png',
    id: '3647',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aee1080ca21780032e8895847b890bae16af23756480301d84d80a981b955fbb.png',
    id: '3648',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c56c5f558c626cdd3e38246c26c2b3a14157d33561665f2185a382c64a5bd0bb.png',
    id: '3649',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cb34b9962dc16f2417cd13ee8c5c03bbec9ad4ceb8a3464d9f9d392f617b4b2.png',
    id: '3650',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d75bbba797c55b84453823198f38c80402d2881a18fa044ecf29d01c65590ae7.png',
    id: '3651',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Betrayer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/080bc38ab84936928f8b8f65cadc9fed819a0a03a09c78068b48a3b40fa29d85.png',
    id: '3652',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c89d1205429bb791c312acebf962e0ff2880147c84e67098c274605cd74f7b83.png',
    id: '3653',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83916652a50a1cdccf9625f256e9631a01b75dfc78b57aae1e63c783e09a5f96.png',
    id: '3654',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1f960c5289d7273e4a33a0000bfee254c3ba7dea98fd3b64a05c57c662c416c.png',
    id: '3655',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/129f1ae0ca63df504552d1efa402cfd689837de988a528f8e220e408cafcf9a2.png',
    id: '3656',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5afa8808d59d7e50e87b867ddfd4ebb3304f03f063dcbc26d4cd9208963e33c7.png',
    id: '3657',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c749461cc24f3593cff48a67aa34809033c73611513bd8fb7cc7d4ac17e23fb7.png',
    id: '3658',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0a1f2439f5c49aeab11615c8f8545c9523ca353439d52e765227481073df470.png',
    id: '3659',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bec08a3bc63ff2a1a774dc03bcf318ec2995e29e9bee8b8cee04d2b97fee87d6.png',
    id: '3660',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f17c54c37189729364ff21a8af87d44f64d6767200ac89a862c556be0ec47210.png',
    id: '3661',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfbc92ed14ca25456644eaa5e15e42375820aaa66b69e3ea70f2a5177419949f.png',
    id: '3662',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/661fb0e39bec7a70adda380a08ae99eabe35afb738c61164dbfb431bc08c2b80.png',
    id: '3663',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b68754cbab6449e06a804a42be8de4b953fe3653d7c1c6caeaf91ebb93b4ba52.png',
    id: '3664',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8798733b49f2952ee6fe70f7bbce1148779ff8bd13f01e06cc7cbeea5877e133.png',
    id: '3665',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b99fe1eba0bb0becc97841e70f1d11228858efb0d11f3f754565260e4598b49.png',
    id: '3666',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea3c3d27e1689649272d23c06c74b6d2200f048f0efebdfc0c75d02f37df7981.png',
    id: '3667',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7335767422cae63a551650c878088df37b5dc3532b930076b7f6f75b28838a7.png',
    id: '3668',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eef39f319357ffbe483c697d37e3b07387728e7c73ebaf13b0131f2b30300b4b.png',
    id: '3669',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/963da33fc466b4bd5ff67ec25b62bfd5ad533811420deeb2a34cf5cd12e1e9ba.png',
    id: '3670',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fe6d94dc2db0ebc0b732f22e3cf905438b98e59103dc68ba086e48257edf705.png',
    id: '3671',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08b701a91d1834b22baab275e17385cff7b9881e84dfb9ff6322983dbafbab75.png',
    id: '3672',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/019d0cb07bb698cbf63e9d01e8796eaa6f24ae2357369df2f1d0ab522110220e.png',
    id: '3673',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f91cc49c5ab29ee909c05d7fb3aac784daf8c2be6cebeb502f987b5a5e5fdef.png',
    id: '3674',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e63c88803c1170f7ad1685088f2441edbc15617c0681c9ba9f4b86d006f99e46.png',
    id: '3675',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5d69e7966bb2d644fe10c12a77c1160a2131907de728bdf4063d7b8451ce1dc.png',
    id: '3676',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a383dc79d692077f15e8bd07ed3e22d415d681142448520b48b39bc6a5d4ef2.png',
    id: '3677',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fc324b1ef131ce44bfc57ddab751804462bef38d4f1f258dd43f35fc3ee1fee.png',
    id: '3678',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7eed17d5532e9255cdbaa00947cd24a4d7ac8ffc40c172b3a72e3c1f43c68cd7.png',
    id: '3679',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b114157f7116ebfb221e33233bb7a1c7452fd5f226cec8acdb07e9656063172.png',
    id: '3680',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9356a904dcf2a95e87aba3e3e08dc89b370cea7b0fe1e695cda0edc9e46e01e.png',
    id: '3681',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77fbe43fc7479f313451ed93a34ceb05b06b97f58103112008f60f624bd1ce91.png',
    id: '3682',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cf1c66e227b26982bbecd59f6a96b4dc560e3c8b1ba586b360838e81a836c78.png',
    id: '3683',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03589723d8216427f53e202c14e43f1c9790fcf2ace736dbd6982c3e3b939457.png',
    id: '3684',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b0d9f45171c03c604333d71ed80e99611d92d029331b882caa655f9ed376f12.png',
    id: '3685',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4e6d447f24b9f4c87667d164c0d713d80375de7755b912a3e232bcca7639425.png',
    id: '3686',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0d88d44d4c584b851077a00d0afad02b5cbbcf1bc91c0faf54eb7e43eadb236.png',
    id: '3687',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bba878392f4fa290d209981499234729bb82584f331086f19fb09fef128ab15e.png',
    id: '3688',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0cac3780a47c59fd9d2d8d7ac47186908c8dfe4a666fec295f56e93ac5740b4.png',
    id: '3689',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f74ebcfdc0688833117c8520b6b1568b124953266759c713b610f30d1c751e2.png',
    id: '3690',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c753a65ad63ca72a6546868bd3f36dcc0441895854c5f69e7f6dbb5496e528d.png',
    id: '3691',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f996a1a937eb686d989d829a7523c25f7475e2404b0c3ba5265b3a670ae29f7.png',
    id: '3692',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb3ed714b42a2cc5197a6ffb28973c9d175ce67eff1e00a8e36dc35c6890fbd5.png',
    id: '3693',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b64a71611534df937eb84aa0d2dad9fb551bd1324294e080bc3bfbafe10c9d96.png',
    id: '3694',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acce1d7b0492990ee49318dbab31eabd675730b9a40ae0da43979a3dc3150425.png',
    id: '3695',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/205b6a7f711edf2cf7fad89130622da4e4aa8f59e2d161febdbd2f63cfafae37.png',
    id: '3696',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d352a1a7183c36b41ffab04f2932f086c6ecf9c92325a402e757089280940fc.png',
    id: '3697',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8887cf6324cec5a4a231f0e8ecc53db8b8240deee6dd5d6738f53c35b67b0a97.png',
    id: '3698',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f489085212e8a21e83ff45c833261706ea6a119cd848b6df11f52bebb0b79a7.png',
    id: '3699',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28d6110616456b0a691c063cc533cd5faafd72220c96b66063b65b7b6136b1c6.png',
    id: '3700',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe3c90caee9ae55ec5b275ba6743cc4e241e72e22df2149d225adcc493ce8eb2.png',
    id: '3701',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d106d45ff582f3f92378be5794ed5dc9d0463f969748b48cb821f59f9c8fe384.png',
    id: '3702',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81d1e54ba6dcdf8a9539d3e5df67781739cb31536357ad52100234ec04bb0b24.png',
    id: '3703',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
      {
        trait_type: 'Weapon',
        value: 'Betrayer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e8790c65b8413ca6c7eec3d31e66422ae60380de6464be8863775388ae7a845.png',
    id: '3704',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7abc7245b36964a0f24ddc1b106d0e60a1176537cdfd2cbfdd38c5741f9a1077.png',
    id: '3705',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15f4909531faf9d0b0e1b6b977183ffd411f60d91cc84121a8cc3b1a754fefc1.png',
    id: '3706',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55c18de9bea8d7c4b639fcd36cbfab98484a1d62aa6e6d40c215578b7dbd5e34.png',
    id: '3707',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb67275d258e8372a7f0d9a6e5929fa4540ca108bfabda2e89c64d1dca596533.png',
    id: '3708',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9964adb9d8282207135ce173d42b0cae72ffcdcf68acbf7445b49328c074e036.png',
    id: '3709',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e992dc2c4e24b70551a5db62694878b7296678c2b122dc2401aaaae9749f03ff.png',
    id: '3710',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea4e27225ed2e7f8d1e8dd3ae5a6ae9a64c26eeebe6782d2d30789b6511172f6.png',
    id: '3711',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c782e2c423fe095c1c2f78371e07c72947a90779b985bde746be778cec932f1.png',
    id: '3712',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/510491b36983cfd76323751ba54f2e9f512fffc92de415d32449be5d83e8efc5.png',
    id: '3713',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ae47254c5b8f1698ca0f53c1e385e3e21014a64e2f4afb0dcd30e480e7063c4.png',
    id: '3714',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86e1a75dfef7c3073a80ec28a65c324911f4990e0a59166fc5b7d261e28113bb.png',
    id: '3715',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5fc27ce53ee6285513c19a2473d9af6b65bafc5f595a5c08d2d0518c04dea86e.png',
    id: '3716',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a6f119dccfd49994bcc11ae3a236d5623271f45a192a5f13a30aa3aab13660a.png',
    id: '3717',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5320c755d9c3ef82a4cba4b1c80511925b0d94cb666d8c6006eff5c7c4f7f574.png',
    id: '3718',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a298b4144d71c8f2532d3df3d2cfb0094777f4f96bf9364dbb60406cff259843.png',
    id: '3719',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/457f03b6c10e1f15dc5ed30af617fa65c3245e194b13003d1579be5975f116fb.png',
    id: '3720',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7664108ee7c44884f3bb5e589934a00edfbc78423a00016b6a3d475097bdda8e.png',
    id: '3721',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/156b6a3be1b8f4774ce82ae6459497a5f01dd68d42c3a867dd4cc4df69e3d94d.png',
    id: '3722',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/684f3852e91a6b6ff9123ce089234833d8eb1ec0d6cdb7db8777072911a9a794.png',
    id: '3723',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eff15ce1828477af47231a64b022cf48fbf90d23874b1762ac3088ea9f8d11aa.png',
    id: '3724',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae20cebf74b893d9e43e60cb86c67c2b8e48b2f4d3aa4e9b7d48d7c83f5099f3.png',
    id: '3725',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/edba24d0ab62b9e71650130d69d6c2cbdec00dd26d3d8ab14147c0aebd49c118.png',
    id: '3726',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ff795b00fcc473381785bacf1832b4cb94cb7e3dd48cac37b87f258323e5b73.png',
    id: '3727',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b8e202c7be48dd240281ca1e4da67fd46afd5c029703cbd9d7f01af36bde557.png',
    id: '3728',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce14827ebbc2d92d973f9f28e5124593a95073b56ee7173995d3490193b1d9ba.png',
    id: '3729',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/837b987597af51a60b05569ded46789a35cf375221a0e0502d63ebcf2106e844.png',
    id: '3730',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c826bcded027ea2e359d798a9ef236f7348615dbba658626d8ff8398dd293fbc.png',
    id: '3731',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/414148c491d81fdd3e1374f078ac70c34c4f1ceee11ce5e582ee02303d288624.png',
    id: '3732',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53688dada43a3700ffb3e605eda331fe73b4c8680dbd9c1d17f23377faa0b2f5.png',
    id: '3733',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7391f1323425ec32914fa442228d001acf94d0579018790fbf39cd312e50aeec.png',
    id: '3734',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7c9f18a5882bbf08db165677bce0e532cc0f87827c88c063ad033a452ea4195.png',
    id: '3735',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1121347c3538b66832a8738bc8e63e7eb95481d48ec9dd3e5b2ecd13922fa1a3.png',
    id: '3736',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3d3e504597e68d308afa07574033344ea34eaec37eaf2537ce97247bf785e2e.png',
    id: '3737',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43565f27338977f5a50003f59691fbaa14e11bce6ffa69a47caedff0a5790117.png',
    id: '3738',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1770e7813c6dc35c4e641c342e5bcb8a23c9ba567ac17efa02b2994ad5088bb6.png',
    id: '3739',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df51889cfdf529df1e6502da79ef6d74fe4d62af2f75f4513c1dbc62ffd93a95.png',
    id: '3740',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7baf5f88f300ca6a576477c79d76a71a26bc35898e224914e3db833705b38b5.png',
    id: '3741',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08dbd793d35371ebb6a13b5ed118ec49ba80167a74fe9400a7bc392e5067d2e7.png',
    id: '3742',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c496d3df1c47c9c7a281263f6c710c58ede70e6ff5a71203aee34c6a29f383f.png',
    id: '3743',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4f71410175ddacad2b17cfaac1147ae5fb0b414c17db8d1b4c19674e21477fe.png',
    id: '3744',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29e251ebfc1d8bc8932529fc15e80ddb069be354bbbe6e0a4d74a052db18a192.png',
    id: '3745',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10c16d220efd648c6d62bf4a21176656b376377fde84677374f64d01907588ee.png',
    id: '3746',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c8753933c032f692d1b12dc1ac4e0fba3bda96e918d68da0086d5c1742c61f2.png',
    id: '3747',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2594de96c9c66822c37940eb0978ec739288769d85949fa7d81da2385e41f52.png',
    id: '3748',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1950bc57e4bc17f2725752acfeea4492ba7da02af2f1fdd51d642754b80a6900.png',
    id: '3749',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ad04f8aaffc32fb1f10b1871187d1fc6037809145a6b9fb1eeb4ee94cf47615.png',
    id: '3750',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b92a75f1ea1c5b8aa78eedf08688011f881ed8b6dab409cd5e1b373f2a3c97d8.png',
    id: '3751',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d648f86c5588909f48e714be902fe1d543f290109ece9f63752fa644063007af.png',
    id: '3752',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c15a135db4735f820d9df2b4f737fd21713d93da1261c6ed27ff7e215ae8c9bb.png',
    id: '3753',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7cedecaf1be6f0faed150b066c46f8b983749383f73b5b7bbe5223be3437bb8.png',
    id: '3754',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c746158b2d858cba8c238eb2ddc7a76e929069e7184f6141b257f1b10dcaa0a.png',
    id: '3755',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86e4e283c90ddb30cdbad3e7566eae0854cd059aead6a274a3ee257fb2df080a.png',
    id: '3756',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9076433ae2d6e3eb0b5bf43a9a4d422a12391f7949af5f647b7bee5f9211e428.png',
    id: '3757',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14cf13dd872abd3749714e99a2a9e33656dea2984d9c9c3d84f7eb5fe51f2dd9.png',
    id: '3758',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44de008a6d5479cf1906cac5d286ca03bb19a9322db52638c49da30ebebc194a.png',
    id: '3759',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4cd45d7e0108c797858a7ca8d46594db7b10b3e15f41ff137c7a4a3798929932.png',
    id: '3760',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ada23dd83bb22d93cdfee3ac6b1271442344df53d0edc384cc9debf7932235f6.png',
    id: '3761',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/46192346dabb53707f04dffa504a790c839e119253594e626c13d819a9e469fb.png',
    id: '3762',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0b95fbd50671579850a73927b5c3490ce7b28506137575b493ae7b186cc607f.png',
    id: '3763',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a838b63c79c3473c006e7eee42519c653350c6ab3f9f16616505db4b5f748e82.png',
    id: '3764',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6604c1d6e5c4d17432232c3008dc4702d2873eb3ad166b576a9cd3e2dee8ca0a.png',
    id: '3765',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2972aa5829b415b0d0d8a52d0fe0a3f2bfd3cd36c4b310161b8c6f516dc525be.png',
    id: '3766',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e86752441d4981d6e4009f0fb431037d47a8ea41427cfc2aa492b06d03e93878.png',
    id: '3767',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12aca3c5c1afd6c6728ce7fbe585272218ca4135522908ff40f0117644b98242.png',
    id: '3768',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/879c4760b6b4351cf4ff9a13b4f37a76cd8b4dc49a4c53fae9e38f45cfd5cf0a.png',
    id: '3769',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1f156ec847a907fdca76be58d78a91d16e49c1a7163e6830b658618d8322681.png',
    id: '3770',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/450dcd6acd28072ae1e8dce95bea8edd5c5712698fd2447e3a174b2c04d5fdfb.png',
    id: '3771',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/644edfbc1856836ad6cb514eba3aaa34ab77d878e4152afd2b451ddab6ad4a1d.png',
    id: '3772',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e43adc435ebd0caba42f042671a2f35df3a16b66d6ba539bb2f38092c6bace1c.png',
    id: '3773',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Explored Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14dd6e9db7f2308bdd2f9bf364b06788d809fd11b1950b01a15277cbbd80c0ce.png',
    id: '3774',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0172b3e800ad9eacbb3f2e0202cb02426ccb46f146415615df0f86024f482ef4.png',
    id: '3775',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4146a6302bfabf03ac2aa7e32d014468aec1605c094e8545162f89adce06c1f2.png',
    id: '3776',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0d49bb5f1fae6ae179ebc2e4a05c71f00f75cddcf965ec3df927a7721958953.png',
    id: '3777',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/064f91231c79fd362cba9166bb04f27e2dfb6399d74f971e273f99a810e3833a.png',
    id: '3778',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Mush Clobberer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/862d07f8fcab0fb6d40299d2999deba8ff4201fec1e2ac5dffa30ead9762cb13.png',
    id: '3779',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8722dc7e4f4824227b354d4ca95a58185e2271429d5ed141df277f52ee7d7f30.png',
    id: '3780',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/736b1786ac4df4137dea9521a634a67323dc6abaf2ae8821f8f91228ded000cf.png',
    id: '3781',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d7b59fa693884e35a1a6439516aecb26a0943385124cbabbdc42445884a58c3.png',
    id: '3782',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68e1271e79347e4aa40c57aae68b3d30c0ad00ec9bd4afec63a0eec7322687e6.png',
    id: '3783',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa6a5d3ba5c85545314a9962786b3da08f0b4a4066e04f33aa2c998e52a51e1c.png',
    id: '3784',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af066741a02d690151eedce2e36a58ed3c7b7bd64a71ac8c8519f7213bca0041.png',
    id: '3785',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/288714856acf56fe1b1a77ca2533e5ba274e74144a113a90dd09c285b4a52b29.png',
    id: '3786',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1780e0e0c9f1433fa24f25c0668ac72db97d49b2cbe43cedd76292bab511e9c9.png',
    id: '3787',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c309183bd86bed24aeaabcbbb530bc4b5d3b547e775bbfef9f70e65e1b3f106.png',
    id: '3788',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd25c1e35e549451ccf23c9c0025ba95bab589fbe4fb6e60fcc0900003ae18c2.png',
    id: '3789',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0bf2d28a10e28befe9605b07e6ff95603a64c05b253a0689feefedfa187ef93.png',
    id: '3790',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8af8a0486d792f7c4eaa21d5a09457ac947639e44931c497a3e09ae5ff756b0.png',
    id: '3791',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e299a3a0e6c3cf1123e2449708cba43b292d3e90da3aad567574813d02f30443.png',
    id: '3792',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be4f23c35530c32156b5db2805027e18ec8b2d30db81931674a9ae993a0f6b75.png',
    id: '3793',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3553988513ac3fd95ab3ce2043b29db9400c2ccc73d23c5f37b7d434841c2fe6.png',
    id: '3794',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d4644c216ab364b1a54005dd62e07654e1be9d061c693e552783311c6b1789d.png',
    id: '3795',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff6e35ea5f14f5fe5d6e92a09633b315ce9454715f3d78c93f53714e44bb251c.png',
    id: '3796',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/343fc7211f89ea5eb776d42be024d5e379d93ef99d2cd1414f2702cf545568f1.png',
    id: '3797',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62e0001d3dacafba8200c30f0af62813cc78e659cbc54a3cf37d40d643544544.png',
    id: '3798',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9eb9c07b6493adee6deb11d42dbff63deab4b992bce867b3ddc1effcf958d41.png',
    id: '3799',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7f0de87ed581c2cb6ca72cf64262c6fd73769d2f1e79afd37713e0e7eb1a449.png',
    id: '3800',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Jaws of Evercrushing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e34921bf158c1dc8c18d157c8a449bfc41d4502acc7de27f0fb9741c3bbdf5da.png',
    id: '3801',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6decc760a067823bded9c901650048902aa751f77c541e678da9a0d73404272.png',
    id: '3802',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2e570a17e424aa02582b70254c87a0849972fec8139e85bd0176618606c68a3.png',
    id: '3803',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53b1aa008c7b4f91930e5a107f439c32c768e93d108ed7033e6263fd742fac9d.png',
    id: '3804',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c15dc9de7fd21718cf765594e83928bffc215f5e423a18bc490e2f476dbb66a.png',
    id: '3805',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2f3408c1f4d228aad2481befab4522e35d768530d1285149bb235189d43d19a.png',
    id: '3806',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc65c9d4c86693b2e47b48645f55a93aaf489c8ab40b87c437d8dcceea9a21bb.png',
    id: '3807',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93aacff217c7c0fd24d125a7769542851f0e37212bc9e4c387246cbd9ace7a2f.png',
    id: '3808',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df33dc854cede60cddb15ea84acab94aa9793f7198932945a23d898af9992ee0.png',
    id: '3809',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afb059cac9ef0e1b8d1e2d18913787c9d644d9ad20c3d6c21e12c2fc47aafb1e.png',
    id: '3810',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f1ac90b1f432b80e86b407605edee8e1d9fed68cbd38ed52c90978d0a1b999d.png',
    id: '3811',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34f1ba6a1d4435bae031b2ec7313a9e715c5334799425a110623bd7f9af8a4dd.png',
    id: '3812',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb15f1ac4f7b8bb63fbb3a7d793d91cbc87f2eba1b7e96fe52bc809401d6f3cb.png',
    id: '3813',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2babee3aa6cda1ffe483d6692d2cd8e6dc65301035c07bdd9ba189862af3786.png',
    id: '3814',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4227ee5d12af601d44ca71fc9038f194e24bcf65998b49d3c27e29a01881f4c.png',
    id: '3815',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30dba15b83c09203b3a46cad304acfef4394ab2c84f858ceb14c6b9cd9a9b77f.png',
    id: '3816',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/192560e4536454808fd1971d7e2a2c14fcc76fd642f0ec7469db3bd253d81147.png',
    id: '3817',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1dbb1fa4d977dff062aef3c021dd3322e1849e3ce42e3fdf8f4403b7a7a756c0.png',
    id: '3818',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2f06a266765876974fd74ab30677bd0c476a5eabdb558661f5f83bb7139bea0.png',
    id: '3819',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/171a2c884c39e584e78b5d4095b3f461b5fce681a95d0692a631d52424e1e91b.png',
    id: '3820',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e33a42bca91c0c4227408a2ef6ec666f1fd278940aac19ac99ac5c230667e627.png',
    id: '3821',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb305830a2104166538cb81069ac6d4f74a6dd1c96fef3ecf146c1359095c1c3.png',
    id: '3822',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b2b2c2a2e21804910bc6d40a1665b6af1a31382127622f556ef6a2ad856b8e6.png',
    id: '3823',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60101050458f8b005acfa77ba26742e1dbabd493947c8a34e1b29667dcc5b578.png',
    id: '3824',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b2bd07c65a2d4a5771b6b5bfec9249a80456b86a5d0bdf2c8bcecf93c4e4a87.png',
    id: '3825',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7edb2eed8c35d2282e6023590a2730cea9086cb4913300faad77e18abc7766c.png',
    id: '3826',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48336a968caef88b01cbfef12cee4b2dc61d413e0bbac3ad7598c825c2d9fd85.png',
    id: '3827',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eeef4e98f33bdc61228dd3fb3c501bfbaa2ac7706d03e8b85b7957bed69d85c5.png',
    id: '3828',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2ec7814d5c9b5a7181edfe2f377f49cd29b1c4d6f5fb69d62b6a6dbd67f98da.png',
    id: '3829',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
      {
        trait_type: 'Weapon',
        value: 'Undulate Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9740d7b9f4a6a23e3abb7870448bb174f133269e5edfd47acf638119bf71952b.png',
    id: '3830',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d93bd191af9fb830906dff57892e0cfffec01a49aa025fae9c28dba4412fd9a2.png',
    id: '3831',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/596812e7ca307ce5562ff0e9de95ef865d9a8489b823fd21683c2ae807741680.png',
    id: '3832',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee38b2bcfd9132cdcbc5043aa1b7a9aa707360efc72a472529080ba9b0ad6b47.png',
    id: '3833',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/899bb6de58995631228feb125c86f40e5405fdc7df85eccbbc1cf817b0a2abd4.png',
    id: '3834',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26d111effe26ee95c10061a9681b888b99f57bcdbafe84ac27c55e2e07ebcb3a.png',
    id: '3835',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc1916cf0a9b974ab5904035ddb4de6046a3ea0ca2df3df698b5b3d1ef643a40.png',
    id: '3836',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e99c4e7df3d5168fad1ea8c4c6ccbb8ab07c49c09ee546098c0369855f6fc17.png',
    id: '3837',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e744d994eb20bc3e0395adcc3bd2f0ce4859894f9deb7e1aed35b2bbce42ce9f.png',
    id: '3838',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cc045dcc12a39a797a369c02e7713fe8d3c0760df7b5e67c37f7a208d11be97.png',
    id: '3839',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6824bf8439ac30fe5c2a412324cedf2a85d5d54d03e997efb2fcdebfce26ac5f.png',
    id: '3840',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d42a73095dadabde21ece513f3bb7c23053fa02791a88e03998c0103264bb096.png',
    id: '3841',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7a0a63cc3facd4b0e1f9acb776c0e2ff4fdfcef625e594bad8595b5fd6d1776.png',
    id: '3842',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9711f5e73cb41c02cee00420e091b95f852ddb20fa71aae6bc3d8cbfd95a144.png',
    id: '3843',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20658b15dc26b220eeada79715622a94378db8cc9f312d9e8270247ef898667c.png',
    id: '3844',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43b0344febf94953d580602ba331b54137af0067aca089209f9e54b076cc3118.png',
    id: '3845',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a112861aacb0f014006b21f9779c38ba26a06301cdf7c945dc7d3fe314b6084.png',
    id: '3846',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/628cd33f2ceda855d8331c438c81bb97499426d02a302b1dd7325925751cdf7f.png',
    id: '3847',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/46cfed5f415c5166786bb5cab91dbe82611c89a1ba974b35ac7b8e7760128193.png',
    id: '3848',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab82ac84e6d6d398a79aeb14824c90685807e31a63ab4fc8a7e093043774b19f.png',
    id: '3849',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d256424bb95654a8e9f7ab12c4749aa7134b35bed2d7ec09e78bf12cd7a9848.png',
    id: '3850',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd88495e99cee82dd387ed777c71622534c7957517c80c437ce4fea3b90dfcd6.png',
    id: '3851',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b08e090e2fcf237b4d5486f344b31487537854d6e72aed510740d226e3eb6f1d.png',
    id: '3852',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98c438f8fc0ee4ce288a574d4a83685145500178e0388b01a2fd59d84be401f6.png',
    id: '3853',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0cf09d0f3a800e1e1284d3f4c3951134da0690c1cfc7a435601af8fa6878da53.png',
    id: '3854',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc135f9cb66afa0f20d04476aba64729ce573c31ae409fec2385a354ed06a8c2.png',
    id: '3855',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/855a939269e1434463ed32f75e07a9909639b76966787d6685e106c3c907dc33.png',
    id: '3856',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6fdaa5fa1e6e7e7d000065a88d8bb3e02132a9ba3a2ac5bc9098fae0ec59e1c3.png',
    id: '3857',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/339bbf206d1d5559ea7250383453c0b00be8965f1d32b7ec26d078993c6c881f.png',
    id: '3858',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b139750807ba32019bf232b102827e86f9d21aad14ab8559ab3b00abce6ffed5.png',
    id: '3859',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5f0a8f0dca2e252c846b2b7920a580152ddf166f8579670aad6f85c0a05b66f.png',
    id: '3860',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f475a285263ee0497dd6b410e2907afd90d2e7cb3df4c61989e3335c72e4076.png',
    id: '3861',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00bd7dbbc19343c03b46991730a34e31712be65cc8dca73eef30ae16f851a524.png',
    id: '3862',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf37976950dd450876b64bf27694bfd0693cb8271cee9e7697c3678eec51364e.png',
    id: '3863',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1077016399c0f9206d2d497b8ed76919113eefaddd0812045a7a9b853f592a7a.png',
    id: '3864',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43e820539e04d087dfe30b92d1ac2545b78a3709c6e4f4bdeef335e313859ff0.png',
    id: '3865',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ca20cb391654fbd596a38ade0576d025cc2ea3aa28ba13ff125ef373bfe29ac.png',
    id: '3866',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dab1ebc88a160521cab515b34975e6ea2d2c77f8e04454451cb26ca6e661ca47.png',
    id: '3867',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3aa9cf5bfcdc96a6e5851ba9409c13f8c4b3c393881ee0e3d27952629dab87bd.png',
    id: '3868',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/220ad10dc2e1f8a38bc5cec6df90b1e2f51b0fa078df56f43f26ace5d701539d.png',
    id: '3869',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2079622ccd243b5525f4a7d19948d2535bfc033fcd29d38f75259da34c627b03.png',
    id: '3870',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c8e703c74b158e81095380b424ab13ce794cbf9530c6d97e5c0ff154308bc3d.png',
    id: '3871',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc9c75d80e39bc38dc2658deb68d5afd62ecc5cce5446a6d4bacf1fb7fb99833.png',
    id: '3872',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/daba4880dcc4bb05c37ab44318d6384ed9eee91a3cfd4a39160aa8929af208d7.png',
    id: '3873',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c11b9ef6d5642955d369440c9ceedb7675b2c9d187df30da0669bed6dc781257.png',
    id: '3874',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddd41821319b53d36c49051cc24b8761061009424154dec0a3b0d1be689df9f0.png',
    id: '3875',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60e27d1d46c97641d6c20d95ab3bef061a9b27a9dde858909b6b038ea8a5c309.png',
    id: '3876',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69a3d8dd547393932d612db58b0c125151e8887151c7c17a73ed606093eba8c4.png',
    id: '3877',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7489dcdf4df73ecdb394fbe8bbe61319d6f48f821660f2aba8b1fbf6d8062ede.png',
    id: '3878',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4bd8f88d3051d528f710c7c700227cff84e2ca5ada0b2c7176a8061a549dea51.png',
    id: '3879',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51c81e8c7a3d43b3c7d8f74cc88226ae3a5ea73b2b242eb0f7b05fe6cc94364b.png',
    id: '3880',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'The Tenderizer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f08b65286d7e401383bd504502b17eddfd0e974bd3abd8fd818b40a27569760c.png',
    id: '3881',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96403b01be991329c58dfe61e20924b38e2000133b1b08a7a6500f0acecba684.png',
    id: '3882',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b95ca9a3ab654fe12de795546e9007b1be91abd05813f17711dfdeec86b81426.png',
    id: '3883',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/629e6f3de28266563020e1a0f82e529f5e5f2617ddd3b5260e7f73d2fde17086.png',
    id: '3884',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75bac35e1535b5078a98c1d002f9265018b5569d0cbad6f654403b3aa15ec896.png',
    id: '3885',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4917540aa1accfaf5b865efd7e869a1c45841225fd8411d756b9de91795cd529.png',
    id: '3886',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bae1a7bb3c0716abc8eaa8e11b34b0f0806b309a69e202c6434153215ef9ea53.png',
    id: '3887',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cb20aadd246e8f431867c5e3d8a08b4e5f953f3bbc2bc5e52fa7a41494d7287.png',
    id: '3888',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99116d0ad25485466e8c310dbf44d7c1f15aa5e74d51369f2d362b481a63d037.png',
    id: '3889',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Gaze of Mysterius',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a40e8a68df9ef75eb1a84882735c56e7cbb4f3c5ed665bcef12a363c21b7d2d.png',
    id: '3890',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a0ab61d01e650f1cf46013f830f568c35f25be49f487af8441e430ceb092e07.png',
    id: '3891',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e678bed01bc988a6153eaa9132f295cabf0c06994829d7d5273707ddff2d6aa1.png',
    id: '3892',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06d0f1b47a980f69171cbdf052e5211923fb7aa32201e596ac61cbd0d9a857c0.png',
    id: '3893',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d48bf8d4b9a9ef39850df713df70cae071e365140dce297cfcdf1c5400e127b1.png',
    id: '3894',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa0182464d4cd93f0db220384cc38ac595d1ce5fc06e5f7c0192c923877f2a8c.png',
    id: '3895',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b27bd377b19d138b2fa4bfedea4955d17d4132879749a9f4c14ae1e85126426.png',
    id: '3896',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fac2534dd29bd99305aa2756a12e19bcb31167e9b565299681a13164ac8b97ca.png',
    id: '3897',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afdd494a7a6a6d3aa1fde1e64295cb7a200b295850fd46fb78b763b74e57af6a.png',
    id: '3898',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bd92cc8afed5fc3e54affd9d52791e8d3dd6c0f41cd3d2137110082b2e3f8f4.png',
    id: '3899',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2aad07592f478e8d9e3073e56b68b546f2704b951e8ddd2c533a62984c70005c.png',
    id: '3900',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/812e021631622440e99340493cd7dfae618e5767e449dd74d5af6c5d8d15211e.png',
    id: '3901',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f78bfafeb22896592d9d11620d77c876a67ff6f127d982069cb3d8904caa9ec2.png',
    id: '3902',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03c2847fb8604a8a409a73bb9b79e04e20e6bedd1dd68ad00fd6de78cc218d6b.png',
    id: '3903',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54a40ab5c3fd7459e3b384dbd87d240ce729b753eda71ed6f911cba8b8ac665b.png',
    id: '3904',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5155b7089ad9f47cd8304ca501c21d14d771237ea126b2d970b0f0e3356e9a88.png',
    id: '3905',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ec9cb528f246db740069b4cffacacf0bb333b543837613777df7d0ce1c49d80.png',
    id: '3906',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6a49b83a4b0a7207d83a1286832ad14f4a7a928d056e4cfaf64ef95d4c8987d.png',
    id: '3907',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1ee2414fed5afb4302acab80113c02852c4d9a7cb35280cb31b1d73bb88309a.png',
    id: '3908',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shards of Iridia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3252f6d9af26710e5feeb6b77050fe0cfcfae0879e7a4524c3b022f42e69508.png',
    id: '3909',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0a7485ed717bc99dc5f284eb4bdcbc3fe67b8b2ac46510849d3ac6ac46b6a28.png',
    id: '3910',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ac646c701a417653e2ffd5cbe8f750036a4abe855a0709e19ef195fe86d4d53.png',
    id: '3911',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'Underworld Spine',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/637de725a9c7391182adedd5baf76f3aa4c1e440a1513df2a5a7c1b51be15d4c.png',
    id: '3912',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/839e1c434addeef8173a6d5dab3fbf67a19cb0fc5aed720cf3a79efd6ee967a4.png',
    id: '3913',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acc6d0c793067b0e6173fc7f846e485bdd76fddbeefcb2b72c70d93fc957bc4b.png',
    id: '3914',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/529f5f1c0f30f1763ec7502fa993d415e813ec4dacc0913915183c44645016c6.png',
    id: '3915',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdd9df3a3c49c86a556a500d47b6d975fcdb0dd77423d275f8c566ca8422d193.png',
    id: '3916',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17b617a8aad01e03ade03e221177af0e97afe0c7dded9b78a948194b66272b4e.png',
    id: '3917',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f0d5843e67f2e7a3c1301ef67b714d5c01782806df6c9c2c76a371aeb71cde5.png',
    id: '3918',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e4ecd85a0203d8894fecab6fd6fb3ef99047b7193d4dc685d1357b7c4efed7f.png',
    id: '3919',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6df2add511ee8452258283e3f2959b6654f6c2f7f0b05d73c5401091732730b2.png',
    id: '3920',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cdba10b52476aeb613db62d9b976f3ed2039a6bf24ee349d0749b920ad0a55bd.png',
    id: '3921',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/789dfaa5fdb9f350d023b14b7861a7e30c9beda8904d3322e1074835e6e659dd.png',
    id: '3922',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/735f1e63da9d09fc570421efd14bb7477daaf1159dfbe5c6f55d28e887bf1424.png',
    id: '3923',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3934a347fb55f3373c518a714ddf4065b3ecbdb87a6b6e340b85890b90703dc6.png',
    id: '3924',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29fcede3e66abe35910c7aabc2ce95502ed418afa30d32c708ab295d3b6eda03.png',
    id: '3925',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58c16f3c659a8fb70262f5c1dc26fda48cd455adc3dacddb91bd88fc9347b9ea.png',
    id: '3926',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4427af6d9e430e1996217e5881a5375b0e6c51ca666b03438bd71b96c9765100.png',
    id: '3927',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb996ac85975bb457effb9ed82b6fc20efca65c390a78ac05cf652c8f7fe6e8d.png',
    id: '3928',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00ee3629a0a58e0da9e26b701ac77cf376aa9e445e4b6c0102fcd2650d72f529.png',
    id: '3929',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb4f1a3368db15d1db771ecdebc32bc45b7420116a38f6c1ace9c5b316b3bd6e.png',
    id: '3930',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/685ff2e0ebdc566938ea3d4e133f3677a62f4048a2a82baa3d343f3c3a79b4f0.png',
    id: '3931',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/197f6093e98611c539cda070a33f7c11809970159031e5ab190c54773da73572.png',
    id: '3932',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/084bc3cc971f996a10ccb8b55ac8161bffbfc8f3c6611a55d82ca37c5a23ab2e.png',
    id: '3933',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/853134b8682c3f42647d0f6e683999b7c349553758b3c603a8c4c97f5a881674.png',
    id: '3934',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebd31700afbe92d0b24d82d2e3b4f63c1ac9dd49f15c4c42d2f36e591ce95b5d.png',
    id: '3935',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/374a5cd78cb478553126fb5902e21d0932f8306d27216fe27cb43fcafaffce0e.png',
    id: '3936',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/964f3dc85bf80894a09586b6aa123989372a482b89b5010c7787ca8ca718e69e.png',
    id: '3937',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d186ab34e1e5885110b21d6ab1f48c7809bd45296bd3bfd586208a4da0dba73.png',
    id: '3938',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da3cc08b06e5b3d5d563cd86a887d0d106be39c25cda76d7ec1d19c8ba078039.png',
    id: '3939',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc9637925b16e008639bb5fb9cfc0260f5dbcad64ec5000b8f3b32696c178571.png',
    id: '3940',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81a3509ffb988ae74ac754f4533bf865c727fff83a9e991134b586316391bd65.png',
    id: '3941',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01aec1da3ba9341bb54609c733ca67f6bc0a99c741827cc87fbaa519af867c74.png',
    id: '3942',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30b4057c186847bd8d820a5b37cf197ecdab03a78a30831e4af580e953731193.png',
    id: '3943',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94c4154ff2c91b3ad56eaab0f94353ef6a66584449965a3d18ba188a5385cbf5.png',
    id: '3944',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/746d5258eb88a4a5c723337d8d1265ef91cec23fdc23d3f46ee19d0ce3c0549b.png',
    id: '3945',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44af595653e2d9c5ce8bfc590a60531fba636f178a4fe654b9dd5834c960d518.png',
    id: '3946',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0786c7bc9532a8ba840e44e18a00eb170381b0a8d01c6d1ba582ce76ef12bda4.png',
    id: '3947',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/008061eebb1dd4c32e94087de932faf79d0968b4b31eb2fff102058a46ef8ff7.png',
    id: '3948',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skully',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10ab8ee36aced00e6231258b7929289cf97570589b92a3d2963ac757e80a8fb4.png',
    id: '3949',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e150b4c7e1248a44f65bd4f17c5608c720689319828faf335eb2d6fccceba7a.png',
    id: '3950',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7882c7dbf623fb9af791ad2575d830cd6ba38a1c1423ce37219ca1cf97d45f03.png',
    id: '3951',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c33bbe5e21a86e4f99704cad1df06237ffc20ead7f94b5edd7a03cee1ab16543.png',
    id: '3952',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b6423b4ec95d0b3fe741117ebfd7779e1e0c4695a6d1ce6aa77fd45fba25f0b.png',
    id: '3953',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bcb973de477f2a51b757461b94be52c62cab00e2d773e09ad95e580d91544e28.png',
    id: '3954',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9bc6c336074c0c01426b330d93226e51ea58041d9e696a9099cafe979ed3b033.png',
    id: '3955',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b001e8ef09e9205109440720ef624b5687fd4324a385023556e2772a48888a2f.png',
    id: '3956',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/197246674bb40a3bd26be20586543912c30aa0e5f902f725cdf2a78cf32dd3a3.png',
    id: '3957',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abe5ab101cf9ef2a52adefc181b5b260f54b8c6b358f126a0a3fea2ca7fb8572.png',
    id: '3958',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c167b821a4b32fff2ee7ddbf26342ca180044642fe77f10ac09cdd260c4837f5.png',
    id: '3959',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2aed32526641ac0756ff56a15587b9971e79360be46668c4460ddb0464bc6ec3.png',
    id: '3960',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f3dc858880b9c3450f0b7ee23e12779d557f156101a18afd9de3e5c1af5c752.png',
    id: '3961',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f540505fb94a78e7a358d44f1d231a6648b814aa617c4d0d7a7bf0244e9b247.png',
    id: '3962',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f576ae661a40d948f600ca9a334a632459286569787112a4cfd50fb089714dcc.png',
    id: '3963',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a6ada4869bd54ce74e39fcbecc72cca4b733536f21c7e072e23e1ff7fb28a94.png',
    id: '3964',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41f8d320eebf98363cec85e25c3b6148ac059c8112f96bdecbb417d988319285.png',
    id: '3965',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0aad670d577edf2f5b9fb11b0c09212ae6be1836ccc95158249589c6f27a70f2.png',
    id: '3966',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f928c198c478f8baa5241a7d3bebf76893a3147b0045d1f1d851e0d1cd6836dd.png',
    id: '3967',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a21f92b3e3cb2ec366c8045456a7e97a18dddcdb62bb284befeaaee265a5f6b1.png',
    id: '3968',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dcfaead490028f838e3c7acc667e52c866964195535716cceba410ae033ecde7.png',
    id: '3969',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbf23bb7a5bd53a58c6dbed9150882676df64ec941c57f0f4e32f44546f6f4d7.png',
    id: '3970',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7419d19b682b437749609fffc1c6232af7138db8678fa628974c245a4084376c.png',
    id: '3971',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f2e63b2a909d2d62c43796d245a69199f9d03e55974d7a30ccbc7f08f13c9a3.png',
    id: '3972',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c67e7f0dd4ba7cff2475e8f992bb7420fb585974fb86b1d80809e83ac33e1cd6.png',
    id: '3973',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3dedf7398ec4b01f6cbe4bdfa0f083a60a3384eb3b649ce7a8f5a5ba1cb7a37e.png',
    id: '3974',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbed6cfc9996e421e37e03c081d863c7a46518edaf3c672eb5f79155eef49d1c.png',
    id: '3975',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63536263ac68495bfb839be8cda4e96daa3b0d8f41f4db9c66c40a7536fd9b62.png',
    id: '3976',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cded0fb433e263f27bd8b0feb57d5c04e5ea82508b12f7cef1096de32975c611.png',
    id: '3977',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e3c800555cda5b8b6acc4a828e4af408db0f8f416980838a57bf93f46601477.png',
    id: '3978',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7a312b0284b102db338e0ef2b75dc27e74b617f8bae53269b12bd2cbf9cbd15.png',
    id: '3979',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02fcdd851a9a3ab4205974c572a1eb57530bbe830992d7daa6ce6bf5dbb8b751.png',
    id: '3980',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d03bafa59178705c40913f4d31d29e5080f8115ad410d0461e965951567a0bb.png',
    id: '3981',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9811bc0c3ad1d6c93075fd0fa6899449a87d25abf93167fbd1754bc21cfd891.png',
    id: '3982',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d241fec6b7063f1418aef08d223408160cce7230153676fc103e50acfced4db6.png',
    id: '3983',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e93fd9e1f1aa0d4a6f93ef39d83ff89b4918e1e4e2bc7828517e789c41608e4.png',
    id: '3984',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74786e1fd75463642ff362800adc1ba52c3f70dcc19068d84c024390cd496020.png',
    id: '3985',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7aceae79162100249153f68bae64893d56a522a12f2669521513bf366713d694.png',
    id: '3986',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ccc621475370bbb4055526e774adc4e448fed3188b25d8501927c5edbaa830d9.png',
    id: '3987',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/273b7586e9f84bc9ac1fed1aaf13fe500aad8bf0b361925eb344d45a287a282b.png',
    id: '3988',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/277b0235615006afbde3f54b2a05df269bbc53f2ee9109527aa91a9031300091.png',
    id: '3989',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5898beec4f504b51725f8cb739fa272ebe698f94f890639c220aaaf5104b1798.png',
    id: '3990',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bfd7067205ebdbceb36e7ace909d4c774f5f21258ecdb6dd9a5926c05b5912c2.png',
    id: '3991',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74d6ce554cbda25c2fd7874b437e071140350f747073c23056f1fc4f65ec6dba.png',
    id: '3992',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6447f4044982c421b9667b6353aa25ac8ef2d61e82fdbb89cbd23cb2b63151c0.png',
    id: '3993',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ff9ac3614c9870b09d29227647a21810faca2e9f0aec9f7bad2bba50158a7de.png',
    id: '3994',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/80c009bc5c0259e21ab36d9f8dea332f3d093da5a962721a86ed64afd9c0d0b8.png',
    id: '3995',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18226c7826fd6cae5ce05c51be94ee61f1c82479a4ca07de69ed7b827ac92889.png',
    id: '3996',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3f311732db56595238663b6cfb0159d64f3319f7ebd2e93b45adf9e6260dfaf9.png',
    id: '3997',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15cd990a881ed0303b7d0e264bf4885300c15bc2b745146d9a990019f6a12587.png',
    id: '3998',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae6e504295ec596439fe77061f16278a329d636d7f4bc3d392b5307dc3b0b747.png',
    id: '3999',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c190a34590254fcac6a8877ba6f4f7602802be7a27474ecada318d27690170a.png',
    id: '4000',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Weapon',
        value: 'Twistree Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/786d3befb974283409d7010210a88f690f19a46e42f195733f144e74233e834d.png',
    id: '4001',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5843fe59bfdd19b9cc99459e4688dd81d470beeecd1488f280e7ae04116c88f0.png',
    id: '4002',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e9e451586200d795759d5aaee2c7a51b12f5cf408392cd452635ea307019735.png',
    id: '4003',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b37880b461227d348caf05b0414ff94ebc6aa529e00726a4f5e3ab2e1d5542f.png',
    id: '4004',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4f89b56be879945f750150f6cf38eee234fe800070b60f64b63ed6919e20aaa.png',
    id: '4005',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2853cb7cf224d2f6cd9d1167ad07bf515de5f753b35aa9190ee952b60b08c998.png',
    id: '4006',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd3a421048ec936d325d24c8a49081af02f450307eba55a1674a9481e03166cc.png',
    id: '4007',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d26ab208f0fc24d09fe682c2f26a54fb20155fb31dbd3a2a6c6c65d13ca7c031.png',
    id: '4008',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07db50df59e32f8a6eeb9a172a6b1e5846b564e6c1ccc7019f1a5c86d7ef63d8.png',
    id: '4009',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9286c7e0b2ea351ab1917977a9206e293e0d434227defdb972e3a3350f66292b.png',
    id: '4010',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2f0c3167c939de947acd9945a4971e3a7cc453ac6bc8f9eb50727158630e2b4.png',
    id: '4011',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48101059004b5614a1f27e00417bc971655371a858a711c906bf36e7a9b39d89.png',
    id: '4012',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76ae4cd1b511eaa1a515faaa8e6373573108bae6699455d6652058ea785d5cac.png',
    id: '4013',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d59cc329056b6b979a495db4348ada23ff8b787005e539c56dbafb74a9c590f.png',
    id: '4014',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd8df09603e12e5745c0e0ce0c71bb6880dd4007374f5feeda4a8394c5643828.png',
    id: '4015',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39c0c61dabb9b0ccfc85392988046818cca18407915022f702b09ab2f6ac7394.png',
    id: '4016',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ea0c0c86204fceb77d5663ee15241f0d8138510be135cfbc8fa83a79840a9e3.png',
    id: '4017',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8da004db3511e2d621488caffb938e85dee9f2f566c388d661e4b97c50aef7f.png',
    id: '4018',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f886723aa9380afd6197bfb9d0f377c6542b3338d76e0143305b7d02fc12777d.png',
    id: '4019',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff59df1c21a6b313b1b5fd0a7bc0c5a767e1fad6a248d78ac4df6339a3f4aae4.png',
    id: '4020',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbeaa134537d1e8c246d1694cb8c4059b0ace51883ecb15755a5234c1b37dd0a.png',
    id: '4021',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6742fedd64e5a8db70f094055c660ec4b1f71d24e4e7ca284278bddf6ba6313.png',
    id: '4022',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cbb6ca4cb22634121b3124f92bc1a3ea37d8a920ceb79058cfa633eb463eeb4.png',
    id: '4023',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbfc78a68be118cad0a617b6eca86eae370757750b6c49865348eac443d42ab2.png',
    id: '4024',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd89b79454a22c19e4de7a2a2a9af9093e1682965065288b293287d417eedd0c.png',
    id: '4025',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6daca76f4f8d824454399e17672dedcd485c8c718b34bf373e01a3313e5cbfa.png',
    id: '4026',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e836f631dea40c3a67338221b7bd2b5531cef18761e10f8d1cec2bfec7fab2c.png',
    id: '4027',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1d38eccf6b7d4db9a8ae58e1bde2e66e0e4a5aedbfe420a48f64591d63ffd1a.png',
    id: '4028',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44800ae12840410c59f82f265be890b57c69f57a6e41ceb9011edf7ef3f7ac2c.png',
    id: '4029',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d085fb98754cadf1ae8cd47f9624b632f2503b9c1b0ca2c0dcebacea8b9352e5.png',
    id: '4030',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5108ba47ee702cd2c31e884c44db82928604f43fc7141dc1a12717cd080405d1.png',
    id: '4031',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/808209657583ac6f1846ddacbe38e54b040aafc0cf2a79d2e51c6f54c823a584.png',
    id: '4032',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c97c3ea56d40e9d68b48db1586065f3264cb343d70ac430947bb420cf2237e9d.png',
    id: '4033',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dee65ec0eca221b05750d374f80de9cc140a32c96333b2d04c75c1179ea45c45.png',
    id: '4034',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd3fb29f9a33a1dc7155b396dab7756d1e61ffc7c9602d57f605c5a9b06f2666.png',
    id: '4035',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gaze of Mysterius',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11be9e36d91267c9707e1272d4f79acdf87f0e66d6ac5eb4815ce5d36c7afde9.png',
    id: '4036',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d1d14862276ac250b55919133bf755f3ee2aada94fb7ce57cebf0d1d0fff870.png',
    id: '4037',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6bc85746293cead1862c34490b633b55b1da9be1405d7f6b336a7c052a5cf34.png',
    id: '4038',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1e46ddf3db4b4828bb39a293ad665b3c623f5215fe3631c0ffe3816a58db9bd.png',
    id: '4039',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Batty Spiked',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a1f63a157de2147cb32422e67326cfb6458f3b5dc6e23e0636f006d972f2924.png',
    id: '4040',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/906d4d41d7a5c0109fdee997a092cbd331411c452ca1f03dd06e6d8faa942815.png',
    id: '4041',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fc79d254e0f4828210d3483db3cbe752108a86361ff9bf76ada3e7a4fd8a08a.png',
    id: '4042',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86c3ae10d8e3f20b102fbf8f35170af9dd200a27d8faabaf78e1c8ed4845a423.png',
    id: '4043',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b476817b8f741484ffcca026a5e66b77c6907d209d42c092bbc61cebce6083d.png',
    id: '4044',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4522296b669e78952af46b205369bb26018e9f2fb0d912afd5235f2e3ce5bb6.png',
    id: '4045',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/733901ef5a7fab2f11f3bb6b4edd8561c5c0d9182c49cbd17195840db738bf18.png',
    id: '4046',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb0624da27f33fbad67227faeee0d5e611f4f4c5f005811bfb51ec37eeea86d8.png',
    id: '4047',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/585d13ba2a7d56fee69e50bebaea16f4c6cb98d4e8bf16f0a86733e4e6710ed5.png',
    id: '4048',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3eb634c0095fce97203dfc1245480c3b9d3c00b8cad0a378c148e44b6789c6b4.png',
    id: '4049',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2dbd10062645c461d50feaaf6b22fda643d003e18d681c9b5cf6a61181764aad.png',
    id: '4050',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31e76416697c314ab327d8af1a07250f85f9e401b2d6fcf2d08a00e7872a46d9.png',
    id: '4051',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3334bd5e59473c9b578fd62df08ae643d58f69a3760a4aeed7ca82454bee8f79.png',
    id: '4052',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83d9f87a2c945957f160f4faa39b2212ee3c04f795f1af126b58aa1b66b1426a.png',
    id: '4053',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd95115a9690e04dbdc36594b50d0bddc2fa41fb675a569787524211f853675b.png',
    id: '4054',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05401817f7081fda2f914816b8da0eb96f084c3999398ceb0787d42efb6ea379.png',
    id: '4055',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a55788bac5324f607083979a2c8ca8b9e654572969963c4aa0716ab29ddc809.png',
    id: '4056',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9912689548556ff3eae9f0ece5f0aa2f7e8ae9230666bb1ce8cfe212e08377d.png',
    id: '4057',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e73cb461676341cdc0062fbebdf831f93e4b3db8ed955bb764754110f0ff5457.png',
    id: '4058',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32f5251447e201fd4fac0c85f5ec0c24b500ff923740de5ba42354478e7763b7.png',
    id: '4059',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6f2b863052370715e61c9bec0628b2210159193dbcff42fc34e98f0e9b78563.png',
    id: '4060',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f57238e1d909384bafb9804487f1c26e92a06ad171accd25a22883a9254c2372.png',
    id: '4061',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4cd4def5782485cb888c529b08e6256c9262103f9a20f0657169bd4990ce164.png',
    id: '4062',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a3d70a94588fd4dbe909492ea6c77c2ca3342fa93996ada72fb020714d0f86a.png',
    id: '4063',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1c03a7bc28fa3558e40f18d14adb3b2847a7f49127ef76a7fafe28d24718605.png',
    id: '4064',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0cf4ecceb7aa9bf3e8f4462480b5377c9fad46cbb20dcdf6c9e57b3b3acc2ac3.png',
    id: '4065',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/315945fe946bdc50459a57a1eb71c7773504b1d5952dc53a785311aadfe2b3a1.png',
    id: '4066',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2526703c46a8c77e23ea0b681c24069da8931f191d0edab42c53b1c2cc6a8593.png',
    id: '4067',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8f8e3bbdd182005787d1b8b29e849d7b3612910f7625f4c19d94fe18d92b1a8.png',
    id: '4068',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e42bc1aa7a4db2661f10349c5a5b1fc4ee8bbca858dd16d00d0d72dbdcc982a5.png',
    id: '4069',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8fa70cc486105b5e39d960e3f7dfd8bb3d17fdd3ea861768cb8408455d909246.png',
    id: '4070',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blockhead',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71b37a1d494fe6ab0ad3954196e50c0abd5fe938005f1bb1a34e3b65a499a8f9.png',
    id: '4071',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb5a2c2a39a6530646a1dbf7dfd431e886602a9738731ef0a266a91fbd172674.png',
    id: '4072',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fe526f15ae9d8f520847e16fe5b372123d77afbfd3416f9ceb1ea3118200fb6.png',
    id: '4073',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e8678f28ce115b821165fe5f7c9876e5c3078b0c1b2d1a49d37081ea5047a1c.png',
    id: '4074',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41fec587ddcce383ff07c28b8b985bf3fcbbf887075957a418655d172341397e.png',
    id: '4075',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc21f22a751185885a9146153a87e674fc5c75e0c8ea12c794534c5f9ec176f1.png',
    id: '4076',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8419c77d9de4e113facfae1de2e3f2072b3d8499fc98a16e3419fa883207de0b.png',
    id: '4077',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5fd53170021655e0a3553ccf9e7b00f74da1d69966f0ac71bf22a0003c5baa43.png',
    id: '4078',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/586e4c444c9c67b2ee8b9d37220bdf2bc14b2f511eef0d048f54558f4d5318a0.png',
    id: '4079',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b6baac2a796753cee03edcab787e3de60bf5b491ee68a2f297655a30bb63266.png',
    id: '4080',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6085bc5f3addb7b81cfa3652d8568178f4debc0ec982273fdd72613876a5b1a.png',
    id: '4081',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fd933cf1ce9b521b39431a348b83e28e4385ffb90d4497acfc345700de9ae9e.png',
    id: '4082',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0c05c386230c44eb4b03e6096889f6848b5c6465c5ffc89d697e9cd9f069a6b.png',
    id: '4083',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a49d0ec975d7dc993b8ce9a4223190ab727582d3bd94d077787460749dae75b5.png',
    id: '4084',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca9bd98cacc583fd1aa3e5aed1a2e690f302e10a00056860c9e0978e7f1759c6.png',
    id: '4085',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/369d3663332ff733adf7cec985b0b3cac12392c03174bfe3702a33b0cd82ccf4.png',
    id: '4086',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75ee3b62feb0005f3a29f8c0fa18a0c1ec867a2ff25bed225e31697f8d5f5c8b.png',
    id: '4087',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4145d2e00902be98aafdf65938a87e97d9b2bf0dafb4039da66e68bc67733fc.png',
    id: '4088',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2dd0539f365dda941edcd222acb889e5550f114394e519d7a35a81249f5f9ee1.png',
    id: '4089',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3891fb0f738099b274693bac5848ee0231e6eaa03bd14fa163f35ab0c661a05.png',
    id: '4090',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48d6b8b4ce3c73b86ee90ff673ed9c07369ae71a350d4a4c5728365e06e5eacc.png',
    id: '4091',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6cf14849b7d2023039349508424b4f55a643a06ca97e2c4ee042d4e8be3de40.png',
    id: '4092',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13002d59edbea961a9b7912c96fd93e9d0e9ab6ff521e49fe27af7343e53d7da.png',
    id: '4093',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5af333895d995425a02fd70c80a4a4ebdf2f4f3fa9d0c21bae17e05e1ef044a4.png',
    id: '4094',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45ab106c1a48defdc4d267e232e15a618e83d635916c04ce07d0f05bd3c8215a.png',
    id: '4095',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1902e06d264684e483abaf236bee42f1186b8241778f5b80606974518534f88d.png',
    id: '4096',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55b0ac0446dbac4bc40ac4e52f7012c65ec311ada1e9a9974c7009a54fca3c00.png',
    id: '4097',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4512b4e5c07a4762dfcaa25fc8e306de9b58a8e1fe916cbc523a732a0e1a98d.png',
    id: '4098',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad81609cd2bb8d5464887be8b56ac898f3864a73f7a70582f902c2750a6dec23.png',
    id: '4099',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66ac08171d4fd8dd0f386834487306506c4f2092a42396e0f8ea2c784c9f86d7.png',
    id: '4100',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12de22d3ebe88aa7b3e426ed03e5a2803c88a2a9d863b240ae5c3b02500a4b08.png',
    id: '4101',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ec31878eb38a16b4f23c675877c590aa24b376fc235d1af0cb9dbb6321fed1f.png',
    id: '4102',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58856006f3e656eaf181d93a9d5948849f0195d4f39d91d50fc5a0717cdd07cc.png',
    id: '4103',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b249b05ee09811b300e1e770d5c1d9c05d0d9b771ff164ff31162fb3314e5c33.png',
    id: '4104',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Winged Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b226b218a613ce74018d0146f8543db74461ba024edc46543855a4e89958553a.png',
    id: '4105',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a70b08a8feaeefee3a72ba169b4c9ab86e1a3c4fd683fd8216ed578997a3bcd7.png',
    id: '4106',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Crystal Stabber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/777f0bb76845cafe8722f835a57ed7f1a75d6a6275969bfaec7e46fd8eda415a.png',
    id: '4107',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbc5965330343f359b2eb8c8c7ab42e5b579d456c946403af21bfc986f653dc3.png',
    id: '4108',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecbcd6cc33e4b8fb7586a4cff789f3f6985d7c2a1a6599e201a6d6f7194ad853.png',
    id: '4109',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ced0736a87022ce905ada9baa0af4960c701c1fc3a26c2cc5cca854c79d3397.png',
    id: '4110',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da5cd2c731c126b5502c61ae3a79df297c1a5d41ea208be8482dfd7611fe87b6.png',
    id: '4111',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0f22299c9bd0e6a1fb146cd12b5bfaad4d0d844a601f2349e1b6e4e31532952.png',
    id: '4112',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/465429426f99bbcb27c7bd33566f2c06d6c94fe2b6d3ac5caeed2662a34864b9.png',
    id: '4113',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c54de47ec24e6edf2794dd39e847e306d91f50ca41d76e76bd02cc4afd5ecbd.png',
    id: '4114',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c1739d054b12b48bcd5f33c804125a95f7bd1978a14607d66510aea3298a52f.png',
    id: '4115',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/daa6ad2ed3059dae7508902a3d62f8dbf155b1333de6b0a68bd31588a1002bd2.png',
    id: '4116',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a96e4dbda5af9745b28b5002f157a7941e71fdc301e798bc4198f32e33350ff.png',
    id: '4117',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87b8e1a80caddb3f065555ebba38471c9c2e675cd7d9b379dd9e6a97fb33174f.png',
    id: '4118',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d130596219c7fa458ec126053c4071a5ceb07aac77164850a4626c3c16bade3f.png',
    id: '4119',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/088d5792bfdd67dd3a12d2d701542183835779593a59cd78b0a123d0009fe2f1.png',
    id: '4120',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7e513a99eea0d4ed67ddbf220fdf58fb1ba58e81c7929b6f1493aac144bad1d.png',
    id: '4121',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7389b21e1066852ace3bb9953f0a65448677e8278c4f2d7b1171759a532c7ce.png',
    id: '4122',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76d49c7b4d3782e660916e72f827904e6efcd486cd214adc9f6a2a9fe74cf97a.png',
    id: '4123',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60705867c0e43ac603b06f601a2283a2330f1d21ab48aed1bbf940776d9786c5.png',
    id: '4124',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a3da3035fc484cba21c05a6e8d96564b83db744619d46118b2ed440b1e78364.png',
    id: '4125',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/955d104127cc619b97fca11fa57e98db1a2708f1e0be8fcd73116f3329ccd0af.png',
    id: '4126',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d24fcedda9506cc840e031066bfa6871e25ec9e1dbb1b9d7bf5461dbd8306633.png',
    id: '4127',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8ce9d83b5b105ee2124599f89d75879dfe011991ca46071221178adfc77287c.png',
    id: '4128',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1268ff39f7c49d7ee8533d2be50291adf3d89e01fbac6c4aa524b516e4627668.png',
    id: '4129',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6fd6f6e2fc2a05b2ca1e15f12e815b7347fd707f8f620b12183bc435d855e8a8.png',
    id: '4130',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5859710c989a9729949a788b26132e90da8a2dc0ff2c1660ee1e1b694fc31495.png',
    id: '4131',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2078497ee83804c50dac6d3489e2ef4958ebd2adfdaa080b8d66383390dc479b.png',
    id: '4132',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9692a6fed0b04e0cd03e1cd5783d2616de1595bebbcdd30e82e8957c1724fb8.png',
    id: '4133',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e44041ca7a43504e1be6ca1ee4cad70417e597e3d95e61084c40243932b46ec.png',
    id: '4134',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e06b82dfb3799d333152c8a86639a65e0c481c6247cfe6042cc87157550ea005.png',
    id: '4135',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Striker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b013f7f979d8f578cfd1664b9d60578213f14df77a323c9eb21e860c78a05da7.png',
    id: '4136',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55bed810c88d9a423dc6ee7cecc3e43545ce49c5f578a9e094acc40883c4f970.png',
    id: '4137',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4e352b78b476c1b23bff5d0a08fe7388ca816f5640a62bf149695090ae9aba3.png',
    id: '4138',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad59cc67b3222cfe51336a8c60921841ee271226feb7537316f6b963d36457b1.png',
    id: '4139',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77a04cb47d1a615b69b4f5107f6df8ab4a877c3a6dd136ae81c3ea6435d4d205.png',
    id: '4140',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d8dd13964dbba24af789b4e7ac119c416ef94c9d8d35a62377c86302109e23e.png',
    id: '4141',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33b0963e83ebc6137f9e448197225e946f56622a4099e52760d52f10455af9b2.png',
    id: '4142',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c6cd322e2cdf536b771ad5412be23979b4223f4ae70b97e3f82b033d65f4514.png',
    id: '4143',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33694944cce3eb574a2fa785de489181409948d0ddb5c21eea6ce42a829ae933.png',
    id: '4144',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbf84cd66cf0b983f4ca45aca2817a399fe40329ea733e3068e9ceb4c7c62309.png',
    id: '4145',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f596acddd0ee9b72450a47e886f0670b43e17e6b69470cd36ce4a3684f06def.png',
    id: '4146',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35b54ff653e78014a6f19e856528de7e98885e4708e0a6e75c193767f356f267.png',
    id: '4147',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Levitating Lava',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc9913ef8927182a0a56d89e665be5488637be511841916c6021f5128249a525.png',
    id: '4148',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/080f8f2e55957e653f538be9d64c4871a67e8c5fb0a6fd4bbc3aae6e6d1a244c.png',
    id: '4149',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7081b46a98b5db9ad5f0ae3f69274c615909dd605634ac64fe5eb030cee3902e.png',
    id: '4150',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe6370834914c215633f114eb49e83590e5b57c84049a80482a563bceb268f59.png',
    id: '4151',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2307d75c4f77c9ba753376e22f9c4edc04061ead6a15d2d0b6bbf8404426520d.png',
    id: '4152',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51ea6f2a494abf1945fb306729fa5bab843eca8d583d3de9b49635e7810ed57c.png',
    id: '4153',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/903c87c1a11fdd68c177d71f7a37fbc0e80fc620cdc7adbdba61a9c34383e128.png',
    id: '4154',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e375169bcefe11e8af34c0e3ca1c48312817e65f0be15df1f7aa6aa7846ae97e.png',
    id: '4155',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36cd8bd6c3e36e293835ccc4f803fe965010d04da797cf73b5975b826bca8066.png',
    id: '4156',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3cc442d9692c32da463c345380cafc294bdcd9687c977e103663c491ac3bd9d.png',
    id: '4157',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a85422f4af13d906e15cbed89f616bef149c141126c217e6ef0ae77092058c8.png',
    id: '4158',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fe9b18cb423abf1d37bb967bde90252d18e5b692c6c90a2144d37a0d25a8b96.png',
    id: '4159',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f87f62ffdd522660213d8497d43521861f49fa3da6a0c1986832f30ee88948f.png',
    id: '4160',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea5a590615d6e01272096ca2ecca872366c4a90ddf41ab10ce7830cdf8b8c59b.png',
    id: '4161',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e341c5837c920940dfebfb88156510aaa9121f0350d23fe0e4e66703522c17cc.png',
    id: '4162',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56f007c8c5c75f40717c21e84cb8005b5685eafad4d1c51edca953be19b0c87e.png',
    id: '4163',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65936c4d0af506e7d94d9b66bfda5f57dcd4f41d763d021222e7c71df15b6e9a.png',
    id: '4164',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe6cbdc52c2c3a46c9b9ecac1bd39b66f34fb9a414274ac9978dda1d49867fac.png',
    id: '4165',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09a685f0a80d6bf26a2d90e383fc73f68e3fee79666523980de62b3d660b4276.png',
    id: '4166',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0aa0a2fb9ef1e5335c7d672a37cf62c7cce9b42a372a6a4e47627777b951ca4.png',
    id: '4167',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5148e19997fd04b3ce2ad0967e2c1705831eed85242a58d0d0130f5b960fc3c1.png',
    id: '4168',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21a8ba78996aaff241576307ed90c0c959459cfb09818bd34db54050ff8eab97.png',
    id: '4169',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f06110dcdfec28fd741354ae68c595c74da09fd9959b5c33716cb8251b259be.png',
    id: '4170',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e3ddf0360b32315050d235f68035e8831f50ca61b235e06d6d33a67fb6bc9f8.png',
    id: '4171',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3a2f4ee2f5a022c34010fdaf5c5a73a5cbbda5d08ab4764eaa5830a205de29a.png',
    id: '4172',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/044d0162c722cb3fe1166a37f6da3d055b3a785c056a6abf4495e0ec3c0a2ee6.png',
    id: '4173',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3db42b4ea03baa91e25ab17f4a0f4a92628a0eb10c0beb9f9dba69c3abfaf5a6.png',
    id: '4174',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ba4977768708efc12e161bc25a2aa604bc9b548a3aca9e83cf7594d0874c826.png',
    id: '4175',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fda4a9850c06813c51edce61c74baa346ca52d4f2e25adb1d667a4577d3cb67d.png',
    id: '4176',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3ddf9650b592bd4fb229f3bc0b94d2737f8608b45f6c199e3c1c082022ca8ee.png',
    id: '4177',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/240333928f76b24fb75695d35d29d333c1628ea577b76bd13c4773fd1a2923b8.png',
    id: '4178',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89dd8de3819400db11403b2e666b8c645d0c8da21a58a3afb08bd7bae2974bac.png',
    id: '4179',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7d71695c073a5ea382382e999953facc79cdc8178009ff50a3efe1f909a76ca.png',
    id: '4180',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5f0fc79b50c564986a830c5532b54d0fee436179d13bfae9bb107c6d0f5840a.png',
    id: '4181',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/192838eca9fada201f7be6108231e8e911e8cf8aa61a1a7c09c37dd09e0cb0be.png',
    id: '4182',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6fe51964cc046ab382bc8209e2f70fb72ff6beb60e54cf310bd30db9156a54ae.png',
    id: '4183',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cab0aaa2fa986d452df229c392492d5d33321ca022e00812bb9e796b112f33c2.png',
    id: '4184',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68bab2d5a4af82c491b734669133f652cadfbe2b26d6835d811c9a8b39b33453.png',
    id: '4185',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44f149b34d775c0925cc6bc5ca0438de433749aec0028fa076414e3596af1dd7.png',
    id: '4186',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8d03f60b4abc3ad78f535c682a3505df902deb9c73d649a5de537c8c4493eb7.png',
    id: '4187',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a12bb10c23ad34fa5332cd1afdce7fe7dd16d0e75b2ef4e83577dce1b9b97110.png',
    id: '4188',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dea5882ce896743a853f6d1913ca8a467c67001183a5ef1f76646cc97510c5be.png',
    id: '4189',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e035f5feb7c73c375625e819f6ec5f7e64a368a65a502ab5e1be6b4f249233a.png',
    id: '4190',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9da7d34103ad21f0dde7faf0380b74f4800b4397c55ca10c58b704fa8fdd3d72.png',
    id: '4191',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e2054c8936ee7d6b389610ecf1121f6a885e88ee390874dacb50ff6645a0038.png',
    id: '4192',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af50bda780ed48f59aa3b4fd8fd9a203ffca2177e9adbdb5abd4b872e4ec49ba.png',
    id: '4193',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77d7b3682de06eeec0065e8cc8acbb484a23e2529ff7899e0d7b9f6c0176b72f.png',
    id: '4194',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d985793ae4161778f3c424039120adf5351f4a5cc89c88faebfc60ba81deac1c.png',
    id: '4195',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/935be927094c849aa2901f0ff6d13614fa92961df359901867612007f26994f3.png',
    id: '4196',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3a2b2d34a410b71cc34167efc973792389a13f6c62fe8fc854a6c60150c80f4.png',
    id: '4197',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/072e43d7a8fc99797344a2be5f50e4a8dc126f8e931efc4819a6382072798919.png',
    id: '4198',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c80bc60650eebbb4c4bde15508831cec74eb3e0f24552d64336388d628661479.png',
    id: '4199',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad8ee303f70dfe499bed917bfbc7462866778219d94cce69ee0f98b0e796d5ea.png',
    id: '4200',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/adf262ffb32eeba6b44ccff0ad8995c725b7b874d632e89f903317c7c852097d.png',
    id: '4201',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f1a69ea2fa759034b3f46017ffe0a89994420de7c3fdb2fca9ed6a1bb2d8165.png',
    id: '4202',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d1b1bdee51cbf182aa470befe711e50aed25b8bc91c58a6091cc1ad97b3ded3.png',
    id: '4203',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/146999f1905ff006620c7f56aa6efc84c4f112a6d5abe0bb04d1218db1f61089.png',
    id: '4204',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/540a7c5e16bb7200ffd82055344ddc70b16ae0bc8f6fe48c1743efca4a39091a.png',
    id: '4205',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67d7fda094ea687b126df5ca0b5f4fa2a910457b33a5e9683d9568c18a9a7a23.png',
    id: '4206',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8cd67f777a16d07850dcbe968bd5a715e4605f46f8e8db1363f1ee8a7758e13b.png',
    id: '4207',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ab1cd30faff99f0c945890f63f41f4552bfa2882943abec31db0bab99ac2522.png',
    id: '4208',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03ca07967d1452ec629d9015ac639dc7ea0f9408948fd98a1168ca0c726373b2.png',
    id: '4209',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d34b73a7fdc5ed96e880e15d6c055739d99c694e8aff161168ae3a7e3f27c45e.png',
    id: '4210',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72d914fe15547e39dd3b5844cb27ba7a2b48df72981e097e16a7461c7868d5f2.png',
    id: '4211',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dfece514ada2d96e26ac75fa438232a60c22fb79e3cc3db0cd66aa40e25fc8a3.png',
    id: '4212',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d84cb0bd22128bbe9a7312a2d6dd4fc87d467a3dffee3ff7c61144cd0dc56186.png',
    id: '4213',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd41bb81b8eead2c9eb2e0d8256e2262f387556d1d56318ebd1db4374a0f1993.png',
    id: '4214',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba2f105697e169ba01b4fb55b09ea196a3d6127e543b58c635d6519f6490b40d.png',
    id: '4215',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/661de2cde5f8e6034e96db37d35450b409071110932781210158448fc605dd00.png',
    id: '4216',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/142ed188ed0ad04166d686c5c08235da64fa4c1f7a9d44e97290200e8e6d37ee.png',
    id: '4217',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e7ed10868d2024ee0778e5ef4915e97122b139fb0d8d444c964d13bf8f2199e.png',
    id: '4218',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/044d872377a2641528dc4f820ff041cb0cf2adbfeb1fea298e23d5db7d0f7d15.png',
    id: '4219',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06f37afb1ce873ce651a90f37ce7dd9bb76446176a097114e9382ff30c9f1507.png',
    id: '4220',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d8cdbfc4028ae0e932cb54c4cbe973c3ed17e3c1aec068f8907610cfe72af69.png',
    id: '4221',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e89958f61ff4d1085b4ef7d683a82a03e2f1941fd6ce6c0d52aae0271507d22.png',
    id: '4222',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ec3f845880717e4997a5069845cf4215fe220567577a5025836cadabc1aaad5.png',
    id: '4223',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/267b3136346f207088d91a2ab8819a6a1d15135e55b01068595e925da0e1dad3.png',
    id: '4224',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f3a6f6e700b40cf9d7eafccbb259941b41a3040b0416bcd95f9d8cb17f407f9.png',
    id: '4225',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35393cb120c74d77e548ab83423943215b95cd2ac75839299a17db9739c61277.png',
    id: '4226',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abd5f71a4a9eb0d1a45c51f44ddfd5b8ebfc11b60c60ac0840fc10134485e862.png',
    id: '4227',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/121f2d5b14c3163b6ffa53f5b1fb305daae51ab6e65c73ecdf3523259f77908c.png',
    id: '4228',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0dbcaab991fba6ea3576aafcf90160e267d7841c5eca2836e72d1cfb794faab1.png',
    id: '4229',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a58157637503c733954aa7b029416cbe1474ed0fb3151efa472f86d266ec98c.png',
    id: '4230',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c166f57876d49bf544a0a34f7a67794b97244ed4030af243fb18a2081a52fdf.png',
    id: '4231',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d81832fd2e86672afb31a591eb86f5296beefe5a0ae43637e647f625626c9ac0.png',
    id: '4232',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ef29341181b0f7ac31faa43cc20d4862892f86b7fc27b5ffd5440eb03b4ddb2.png',
    id: '4233',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c53fc278a35254f7b56516bace1beeff0f1a3f70fddaacbaad00cb89912e5049.png',
    id: '4234',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03525ad9675c137f6168e307524203957c15f9bbd4287491d21ab8e4d113b631.png',
    id: '4235',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e16cd21dca5871ac3a6bb797e9fa9efb14db7d5cefbf25fb7a668ea7aabd6cdc.png',
    id: '4236',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/536fea9a9420f82c6de20d0bcdcd1d2db02cdeb3d7b749d5fdfafbe54fa4d276.png',
    id: '4237',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/666bf9941636d81a0d0ec31a4bb8496eb46add126d94e1e29f7f07d03ab1e51d.png',
    id: '4238',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abd764aed0b2307e7b26d61baf9b4932954d09397b6eed283a8ba814ae69129f.png',
    id: '4239',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/201d9e1af24b7b22edbcab8bbbe629cee85b14cb48b1ee6a4060c4892370994a.png',
    id: '4240',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d2577f288abb9dd6e5922baedc5c5f438a4336191395205372d9d8999c8748f.png',
    id: '4241',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3747ccfa0a5cd05a0ae6379e4c69b09a33b48493a2df981fbaaea86a2513047f.png',
    id: '4242',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78432b3b4bd736016c974cd853ee6cdf1d9c3cbc601e81c7f034ebcbdc4f204e.png',
    id: '4243',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/80f05728dfb48e24397abe80d6a87c51631d05613b8a342a23d366ca52db32b1.png',
    id: '4244',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9588e1697da99967b44f01825edf0c4604847ec55fe6472d49ed03bb80696250.png',
    id: '4245',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03f99cee6463f97fb72e472613bf7f7e4f48b850a5cf021615d8022f80c6b9e7.png',
    id: '4246',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd6a04d09021d599f58cf64caf9f577dd5d77aa5b10846b06ba5b809f2af1b4e.png',
    id: '4247',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'Betrayer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f90fb8683cb7a6d0988500641a970dea74bf79abf366754d28d04430384dda8.png',
    id: '4248',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/893c58bfe3b03d99e0becb9d3a552aada521f2c7678708aacab21558ddcf1f9b.png',
    id: '4249',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45aa5845a47cfa6946038c211c0e10f1cbf61877e57b8b90cfafaa78fdeea86c.png',
    id: '4250',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c15a592036ef4abe90604e7aad80e83787696402ac243ad1163d4a5b37261c9c.png',
    id: '4251',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/995cb010b43bb4ef025c3ca642453a8b70df2ec017d35cd8a12b9d7551149a1c.png',
    id: '4252',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05aa8b0618ceb9e962340340da5faadd3fe1a5d2bd73e0b7b62745b2d9ca025c.png',
    id: '4253',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec7cef8714d8202f9970b9ec2259b989074d4bb936c8eb85df89462b9ab8d17c.png',
    id: '4254',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb57e77f198919dede9054f90143a2c2b19901bfb8f98d0286cb8bf652859458.png',
    id: '4255',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f894a4adbf6803f007e4000b853610420cb189c8e90252e476bbbdba0abe0d8.png',
    id: '4256',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fdca1ec0a2bef47999d9f2198956b8baf6881fda22855b4219832aae115a0ed8.png',
    id: '4257',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a6693dcf98a5726f8ae99e5ea397f0ef96dcead487983955655a1fa888468d1.png',
    id: '4258',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c3275631e6b6cf69e2790a02cdfab30f7dd3503f82cf696ef6793960462fc38.png',
    id: '4259',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09dab4a48b973c8be2705078a9ca00ff662c799d3a9ac086f14bfd43670ea445.png',
    id: '4260',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb1528293b59835f90878b9056bac5c3ff26df603edf425fe67b5312cc2632f4.png',
    id: '4261',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a73c730fb11e2db154a2549e76d2c3f4303ce367481cb0f3b3b1f62080cb09d1.png',
    id: '4262',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a7daa38f42f22dc5cb3bcfe0b60fa3b782e637dc7abb3b8cc725e56dacd8070.png',
    id: '4263',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18b6643cec475e7c3b8623d1542f8439dcafe4388c1f1342fea83a3e6daad862.png',
    id: '4264',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49c43ad7d796c0c56753e49fcebe71229a175e165fe4e7d888da01d3a3f596f4.png',
    id: '4265',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa84062dc6342f90372bfcd45d274425814ffac1a6ef6182762ac2e7d8800a11.png',
    id: '4266',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b512b88b0a646d6b72e0d820c660be9954a285207e860ecda6906354ffd81d0a.png',
    id: '4267',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ece71fb8e97911920a820922e0c95d053c7715f0bb5cad9e3e8427ff88cbbe3.png',
    id: '4268',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/408a5360e5745c9c687b5233d397ffa8c1b89136575bb9e688797983b9dc4524.png',
    id: '4269',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a64fa073b4731a7a3cee5e44cc48d0738c02359273bc760381aa04079921a01.png',
    id: '4270',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6c1dd3604757694c2a10ee164efbbbec4ca272c71e956e5d880941ad546d851.png',
    id: '4271',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e11254e2032e0e8255213241eec3656502d948bb01ee9d1f80cb8a8b4ac192a6.png',
    id: '4272',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/311eba4bdc1c47234d1518d5f626db8b216b65f67a06c34f19fe15188906eaf0.png',
    id: '4273',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60bec5854b0039fbcd1914843724642fba143bffbd097a2b3e623db9b4d9576e.png',
    id: '4274',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96357b79f9c2c6bdbd0f62af4d39580fc1eb060c2a9aadd6e59c7cb591d7a3a8.png',
    id: '4275',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a05d961904837f86218a8614f447a67f67167bade4aefb3e2fb932de22adf74.png',
    id: '4276',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1a9919d2eaa6b2664348de1accd25828b167096ba0ee7a01a0d1944df9530d1.png',
    id: '4277',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/439b13f8000c856528dbaed97a4afb6d3af760e5c4ea5dfeb5f0b4df5ca2430a.png',
    id: '4278',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72196a2a28308c3ceb0d7b677c7e9f3c8b2276e64ee643b742b80683a365c666.png',
    id: '4279',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/993675ebb225daeaa82472c458e20fbf8e03b73bdb38849c6d9deb14532381d7.png',
    id: '4280',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61182fc4f53d12f516e5ad2de1664271b3f693fab54eabd56bd95f026f69ca4b.png',
    id: '4281',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/736f99e9a47fba5d3ae9c1c5b9f54fa5ef1dca62971c51a3c6e1578195dda17d.png',
    id: '4282',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ea178b4112d80845111d0b9d226ccd920127626892836fc0bdccf90e9274c8a.png',
    id: '4283',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e561812fdaf1f2a0b6ba01bf15640794f0126cfc327c11ecc157418f3338b03.png',
    id: '4284',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81fe646cfe6ffa0922b39ae1eb2c9d6128907db8f37a55f266a61f05730286af.png',
    id: '4285',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7591d0509650a1a7bcfd7c4af5460026560de3532ef88293551baf15d04fb23.png',
    id: '4286',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d4bb78f334124baa600eb206cee0d51bd33fd6dc9e1cfae41639c1e9743a232.png',
    id: '4287',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3c9eff2942a6607b5e3c6c001f39936d28f9375370cd96e01053353d40344e9.png',
    id: '4288',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0c5b68f0bc24a335940c5e8e74d1034238d68ef5b7c2d3e47bbab778d4b52f0.png',
    id: '4289',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abf07da84913cd3c697bb4cbe0001be97aa144e30f51082d7db0a58f0241bb29.png',
    id: '4290',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a742efbd63f41443d67679ddac72be793f9b655cab89f5731118b099e681884e.png',
    id: '4291',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b0f663db008c35e5b0638963e12c582744b43f29da362f5e00172e29b2afe22.png',
    id: '4292',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9dc4e48fed3e845c512537e3af01e5a2d00fa1ea80b27bf67318308f1a86284.png',
    id: '4293',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9ba3688e476c76180508ba17d97dd221eabdfb1c428df1983b103d2b2a3f404.png',
    id: '4294',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6dff0cbc3a75bd1d6db9d6650d9506d3072f2e327b65c696ce6e57768da2fba.png',
    id: '4295',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/858f3e7053d3570ad9173546d1cdfce295d69734ba73b105b3ef5e9acc4752f9.png',
    id: '4296',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7336545631a94f94cf35bb772bddc47df929108bf3e25869c059eba3612daf22.png',
    id: '4297',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/701a05f89fc5f9c7d2994e97a6bbc01e52f4477c1e9f3ebd1de2ebab84ad3adf.png',
    id: '4298',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f958c129ff5709f5ad3f789471dc058e685b52edbf6482357b003fdb955f19a1.png',
    id: '4299',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af8f71090e4a19174d40ed989eddcde7a09a2428a39feedf7ba592085ec513ec.png',
    id: '4300',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2232905743364ee2b46257312499e87a97c999e6066f323e34318537bc475bae.png',
    id: '4301',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7158fa2bb614600669ac9d13993ae769da9922e95848ceb61b7c381b01d159b7.png',
    id: '4302',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdf247529b8d8e87bef3279cef5920859fecdc210a0427997fde897053fc1116.png',
    id: '4303',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/589dcfe779017e568683debf9c15f3845ce7fcfa7c717e97bdcaa3635cc115ac.png',
    id: '4304',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a40a3ab541d93d4378c67859bba0755fda08511d7baa2ea2769a0c03b2ac674f.png',
    id: '4305',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3a6f859a83199ad45e565a8078eda2f0184f8466fd757613c4ab7129589c628.png',
    id: '4306',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6accb78ca55782996df15924288f80898eba1fd90efd0d1489c97c25c51a4df6.png',
    id: '4307',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2ef654c368a60909d4ad7cd67edf25af320bdd549ece795eeeef6997be6aa88.png',
    id: '4308',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97a229dca16334542fb306f12dfc5b081a6c5d992a9dc7b24063b4aba5ce72d8.png',
    id: '4309',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed21e1595a66efe38bd09ec7caa7541e8b27818e9252056a66a2cbd6bd6f255f.png',
    id: '4310',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a92fb295dba7ef7e21b4e6fcd5f79fb156f6926e62673a03827f7f1a23d1d843.png',
    id: '4311',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5e65f275283f02886a7e73eadc05169ef739851810a6df73e8f915f2206f7bb.png',
    id: '4312',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14887d18fc39c75fdcf36088252e9c3d8f05ecbab5193e4b554d2fa2a4779020.png',
    id: '4313',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4971693ade1b4c8ea4f6f00f565e2a320ad3f90c05ca0fe22d8648466de21a03.png',
    id: '4314',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d76e9362bb70d96e3f2855ee71925ab2c0bd1ecad72980eed164c4fae5a75339.png',
    id: '4315',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5015f85f65f9c8a9f085a2f98419cad58219e22dfc87323c504ae364015fb9e3.png',
    id: '4316',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/602ec64644f74ae567af7b5db2aab227cd92e34515e8e5924402f0c12e3eb316.png',
    id: '4317',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b82cdbba54eb02957c3a3414992a13408a54d2b424dc4f2404038cc185d1d248.png',
    id: '4318',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3aee12523b556a124ddc532e36551db7c995bd83b58d3737e750608e3593c6f5.png',
    id: '4319',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1cba410506a109e5f0c123e7ecf93be654738b6bb6ff82238b4824f5be9eb16.png',
    id: '4320',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee40cf50afb6760a3f80046a59e316c16e6c37983c484c13f905e435438f2f62.png',
    id: '4321',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21b13232fe146c4b24f509de48435aa02ce18a72f3c754821e09665f9277cd73.png',
    id: '4322',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1f7a0889fb9a95404681a9f1e093283f5e6a969231ef36235664d9ab51cab25.png',
    id: '4323',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/163930de7f153a8ab92223b8aab0b6fb076056cea8ab68a88bf360ab3ed76cbd.png',
    id: '4324',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e961b251fd6b7963bd3105329a743194c8c485e68663c8ce8488cee65b25a99e.png',
    id: '4325',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b3566228a83769a9edb3ab42546dd652f265bd9369c695ea872cc1468b14636.png',
    id: '4326',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c2e4a04ff443d713228cd34fc0a9e01521e6a7acc857e22ecb76dcf1a773e2e.png',
    id: '4327',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa4f32eac82b78a95b8010b0d8bab3650ed7adecb15ee0e2159b9ca1bb4d8863.png',
    id: '4328',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aae0e56dadef91d21f5fdd240ddda177ace229a89b04d777fe0b83d843e963b6.png',
    id: '4329',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2fc0f76bf5122e64c6818e5bcedf7af9f520853b535888f3e6b6e7f07179d3a.png',
    id: '4330',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5fbc9b166850109b4a8708929c6cb89c3e125172b061b8a0fe343db19a16199.png',
    id: '4331',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/604fdc511665a44e55b7aff63654a3bfacdc17e01ebb3d62a36383d0c3c77719.png',
    id: '4332',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/449dea3e1702942163c0779b3b494ad5a0489f0456ed63487e465fb6b2993b85.png',
    id: '4333',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a5133bc929ee455f86055c65d963c278f28815e945b48e6827838b2c030fd6e.png',
    id: '4334',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67e98d83443b18ec241aec6e35b724411f78572d0d2735cc7b55d69d7ef197be.png',
    id: '4335',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/164507ee77f6c11e1f950d1130b095b637a5a177dd30ecd504979ca32d8a09d4.png',
    id: '4336',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ea9b0c584095fd80a40e4917476ed17945c8baa62c13601f29c3f12f0457c04.png',
    id: '4337',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1fc1f730cec3b5bd88c82631ef59c8c42678ed44bc6e382f6c981c7264b7408d.png',
    id: '4338',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3f796185f403e39436dc1090219f7642c014b41949c207ae12e1c8aa2cc3a58.png',
    id: '4339',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e80991ae2c4e3ad6d3b593306e7ae59ae1beab0a295e2a7947edd611b507f0c6.png',
    id: '4340',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0dd39a9b3301d1f7f9a8c2a0e39d1372e2f03be093f1b3fd58ed64dd7e64c24f.png',
    id: '4341',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eef3831fd9dddffb31e1d44257d8fadf9cdf5a900aef92f6ab897fcf060f58e2.png',
    id: '4342',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1a3c29ca295a9f5f5096ac10ffc82e814007d23fca33626a408bdc4ed042598.png',
    id: '4343',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f974a87ec5009dee51813a506d3cbef3623766c68e900bcc1c3064d9e889b831.png',
    id: '4344',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/446f8404364c02626b39c6889e98251b941851c0ab83951c6dfabd2084416e83.png',
    id: '4345',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c642e3c14a28259e8675921fceffc8bedcfb4afa2bac03703551063650a3544.png',
    id: '4346',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43dd474d0947f6f8425eaef4d29cd463354e32e11b1f4bdb93129cd12d043240.png',
    id: '4347',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba6fecffce5fcdfb4c7c0e7f9dbba0b08fac196a57df89a6a159365427104d28.png',
    id: '4348',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d921d9eb1404b08173b522d748bdaa380b63bb0f20b4a80f3dd3733c5ee5b37.png',
    id: '4349',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae4bf0208901ef421cfbd31124e83b159b0ce838b8fdbc9593706b015c7a7feb.png',
    id: '4350',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b29f8f6a81449c03bf4688e88f24c6d35cd80d30894a44cbbcd5487533eff05b.png',
    id: '4351',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a8b328b4e3bcd2f237df9805b597ca6f5fba80b6b9b8cb66885d26686f06ce0.png',
    id: '4352',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4903b4b13f08b4a18db03b2e0d53fc9b499d52ea0fc9074aa04e1e5f801c787.png',
    id: '4353',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b83dfffc8b86b17c9c0e6a846ca7310f1a7cd0c33dc25113c955cd4aec87e6e0.png',
    id: '4354',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b90b7cddb2e2ec92cb8c56b0033573546234755d9c7aea97a89ee2b184ec853b.png',
    id: '4355',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/573ac28c85684af984dfd157a95f2eff68316e3a0ee0deea7b54d10c2e391da8.png',
    id: '4356',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8880bc9881fd06a7599818bee3b8f1f9ee92d62a9020bc1c355f5ee34f80079.png',
    id: '4357',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3dcbb5cfbd676331121ba546f7223bfd9f4f768795cb54605238238ef7deef7.png',
    id: '4358',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d037918316364e7b84f9ca13b12fdf29b292cda1dda491ff24648a7ea53e238.png',
    id: '4359',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1190691db5714eb606a770641cc8acdf7b189585656a2aa3c7bfe3a135e0765.png',
    id: '4360',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f8cfe04f0dc9f7a8cfcc2bd41b374c0384fb66986a1f3c8e034801ea5887b27.png',
    id: '4361',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/718f0e7fc5a5242ea95f60ff0e97ba95e933e244d7da8dcb7ddc74b5664d41e1.png',
    id: '4362',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5098fee2fa69b714b572ce39df354a17ca53c9505fbfa3db8ce67afca362b7d4.png',
    id: '4363',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b5efacf41bd5db425747c0ea72111ed15ff308c9e9407ac5397f47feaf745bf.png',
    id: '4364',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b7847a472c80f3fca45aa2ef57559e095a672e6d1a019b95b3982f24fb105a5.png',
    id: '4365',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fa9f7a26e57e8fbfa4f7e516717b2c930bc735d8be028bb0db559c10a920b21.png',
    id: '4366',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/612da725ea92c4edbcd6e8f515b2b7e7d50e8cf87058a83baf74c5b8bbbe9aae.png',
    id: '4367',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ece0d4a33c325440300003a8fd054b31e7c4f23063a609a0b01d4dfb524d6c3.png',
    id: '4368',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4beeaa3d8516a6eaf4eac44c9d1d7c858523a6411e38a8666c96ebcd20b95e7.png',
    id: '4369',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30f9fbc3bd931ac34bd648ae5ddf9834d8b49680e7d21f6713dc2000c54e4c01.png',
    id: '4370',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbfe48a2e07c302061a3322448120027a89ecd91e1aa50580c0ec30e46a932f0.png',
    id: '4371',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b934c791e4093fee82f27f03e1d0724dc6da770869262a58f28bb6c79168f712.png',
    id: '4372',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5893347546c7e47e8b98f7955151f101af53a28f850a7a64ef774a64f200aaf.png',
    id: '4373',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc973e0dbd86164d6032b3b5523d1f4f5c1bc8a58a72a09312c3b10d4f30325f.png',
    id: '4374',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8610ce1ce7325747f1444a7d003efabe51fce42d57c04538f2820640ff5cd5a6.png',
    id: '4375',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b8b50754b52cf1f4256978074b32b5689e6ffd3c7107abd0824ae622cd2e989.png',
    id: '4376',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/163092ae5307c9637fc9e3b15017b4ca97c3629027d4bcd9bd1cd3bb83c0ca34.png',
    id: '4377',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86d92d8f8db5698b99ea48cb2a145046d06c735ac4fa882bbb77706223ab1554.png',
    id: '4378',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/368fa3bef54b4ba00afb9d34b5dbc067962e22d3ebcba18f1100327eec096eef.png',
    id: '4379',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9368442801fb5bee1a806b47563238feff366028873aaea6de441d2485ca383.png',
    id: '4380',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/092463f6718a1af0ea4d5398e1ea74a702fffd85626b68fac50dab541ccc6b95.png',
    id: '4381',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd83c1d55c074d734b1eb4ea781823889ac9393e5bd39ac1cdc37e21b224720a.png',
    id: '4382',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/546085e5fa173df3a306b565ca4d6d5bee388f934db457177a9a6bf30a21381b.png',
    id: '4383',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c09c9af92b52bb183d3e5d31cf4929293c0eace942fe5e5fef424ae89a443bdd.png',
    id: '4384',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'Sticky Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23d9e8e2ccddccf910f3c9375ff260a71f4e84f346cd3f63790fb35c1c2480be.png',
    id: '4385',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4447107a9163f403c9ecf2361e97f733919d0b1375d474cebf03edc35faa64bc.png',
    id: '4386',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Striker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/583e35a4e86b82b7bc5b42445e1e3264538d62f1ae1cf43c51fa87d69e0a1637.png',
    id: '4387',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b4680f0cedc215d6fd93f29aae1acaf7d4cf04f088c2249f0609c32e7e3a56f.png',
    id: '4388',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f6a5dcf70f8a0f3e1de40a856537957d5b6ea51cf485eaaaa18c4d8b2f4c921.png',
    id: '4389',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c937462307f3e42bda9f660af7ba1e7f94aa3310cc027c990ba4f9ec376d6652.png',
    id: '4390',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea7d648b83b612608e8656f00393d8d914bc32b95b4aa94efbcfaf21a235888c.png',
    id: '4391',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f33cbe81aedae9f6990cf8a0b30573d02000dad7bd95ec61680f3d316399875.png',
    id: '4392',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d694cdd18ea4e23ab48c4148548c7106838143b8276230d37556acc3a14acee.png',
    id: '4393',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2873a729b4ce8b7e634e6834598751fe4c6265f090d1894302808a629809dbd.png',
    id: '4394',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4167bc1e2e99bae22603b2e745438f30112e6905977f865f83862b564628e6a.png',
    id: '4395',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47f154efe6f7109ad39d4c752f631557100269ba9187f319bfa7d942450cc34c.png',
    id: '4396',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f91cfde4a134639ac32f166a5bea69ee002c23870cb19f2c51847fe8dfc80a3.png',
    id: '4397',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17483b7d1363eaea8e9d2d5aaf9484b4b650d1cefa4793a32f16632602fde726.png',
    id: '4398',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b25c3dd4df110edaf8716fccd793f5db3bc5d4c72ffcfc47f6578315023c819.png',
    id: '4399',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02cd86ad1d08bbc2f8582db1d5ff6f09356a0c8482fac518cd9f43539ebfc0e5.png',
    id: '4400',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d15861e46285eb6768b5b436fb3d16a4ebb605a0179b5d75baba32e8076e5c9.png',
    id: '4401',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03ef3a22d3b4b9b5ea7db47e63616452e39a355b6e270a6a3b0e17b3e9adc726.png',
    id: '4402',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/960e8f7199f3c84b8985c6def49118b6abbb8280917e8b4a0a09a9c676cef75b.png',
    id: '4403',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2ecdc8aaaec58a6528ffe356be53afee1e7f8647c359a48ae68e25843101d09.png',
    id: '4404',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57b6bcb585ae69ac18e6fb1f55dcb6ba60b24a870cd1dd3130ce27a442722f86.png',
    id: '4405',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1948eecb8481af923fb33c461420bd113a05802abde00123ba4177dde8f96773.png',
    id: '4406',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7391b6cda7f8be9fb41e7d77bc0530bdb1530689223a8a9281268de4db0f516b.png',
    id: '4407',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d45ab08ee9d78167a9c124904d3fd81741101b5df791d8550e25a66116f1f0d.png',
    id: '4408',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0132adab086f0d9cd85a0f2d7e7dbc25733398e689ec423c67d5d20ffbc4206c.png',
    id: '4409',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f427e8fc7cde4c2dccfb1be50d04047fa95a5ef2bbab7f129c9bdd70a946ce91.png',
    id: '4410',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f3f7b7d9f4045aba3d902237d058323482f7cc100e9cfe30b0ed7a2d13471c1.png',
    id: '4411',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0662b35bfb4092bb9bba044e70379fc2ec72ab161ae7dd0813bf8e49a9960a9.png',
    id: '4412',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/771431c6eda19eddbb724325e72ccb433877ed2ca8b877bfe4fed5a895da4f45.png',
    id: '4413',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a5920eb08a2caf8f748de2382fffa79edb99cea65da06e1a2757b956fd2ab4e.png',
    id: '4414',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f053c2ba5c093b6b490202f40d4e60ed12766b55e1c511d2e992000b60b1ba06.png',
    id: '4415',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f4e65187773a1750e55adbbcb27e13f861109aa770df9d625a8025195ee44c7.png',
    id: '4416',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0bb4f627b29a007a2b5aca80b3436e1074d340e086fc07296db9f102a1a5030e.png',
    id: '4417',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f533654e6d94bbaf2dd6a40b1461f7c2d68ed50fd3feac32689017dacf087aa0.png',
    id: '4418',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ae4606cd072559c0200a3d3d80a6d966c4df6ac50bc8eff903993ab6f738fdb.png',
    id: '4419',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82232694350e71cef02f2ca4c66a1dbfbc73e552e9dd4a5a21f91f49ab0003a5.png',
    id: '4420',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10ed013a13b17e90ae4281f584d36708009bc2999460d2c6b316e0df266963cf.png',
    id: '4421',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/270765a8c81aadca7eaac9c614afae15994350aa0c1df8a280f04dc5f0e3179a.png',
    id: '4422',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b5435daeac9b085e94bacf9c8fb3694aac7bd2ae302bb19ac709560d464a1ee.png',
    id: '4423',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df5f67becd8a52fb388dcea2237768d243ec307c1e0aac881e0a7c6431f39309.png',
    id: '4424',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf7cd516a53acf76bd92fd45a4cc082a6f614ada8047a451bc5f6dfa7702c04a.png',
    id: '4425',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0283320ac179b802cf37c6ec5368b30834598577f150c9dead538b7bf4aa588.png',
    id: '4426',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42b64941c1fe5ef2467f669f52a60c2f91f41322ab1f448671872d7d6f715f86.png',
    id: '4427',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2ea614b3d4c1d697761b73033a85828f925cb7d9c94e1acbb21c67b254eeac3.png',
    id: '4428',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30dd14cf53637035fe70a89593e4c0cc7d86d3e9b0c9807ff9026c8f80671b82.png',
    id: '4429',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58d20db15c928fdb23cfe8e0034d264ba85843cd19894e7fc16b5658ba2c4026.png',
    id: '4430',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0ff2fd845a81a5b1052c512760b8753b4fca56e382073498e49db1f1e2f05a8.png',
    id: '4431',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c453d887f21f8cb9682695c3b4ab847c391177ee89465f907c9f052c50b6414e.png',
    id: '4432',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a62aac6e117dd42020464f5e2189873b2cf4ed855ca0608565bd45fe86c0aa1e.png',
    id: '4433',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5621ee41789fd7705c47138b231d8ff23a0ad7d39955a738614f842233a72639.png',
    id: '4434',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbc4c91208d2d24c0e6a7e8b87e123adb8d3129f53bfdae40ba28f86b2363a75.png',
    id: '4435',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Underworld Spine',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee75ccad476e6eb2bdee7782d039d65f6c11fd510e00002ea80e7357274394c0.png',
    id: '4436',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12f10b12d4c983b5387a1687fc8a0d62d8d2375c88939779f85764ef4e9e40a9.png',
    id: '4437',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ad848197acf79b70d793ed9c1c477f8b0680f57a4de772f42f2f77cede0801a.png',
    id: '4438',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/608bbb1ec8863a68ee8d9893debca6e442b13744d3af26147af3a2ac6002265c.png',
    id: '4439',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/638760b5bdfcf1a5f4a41c79cfe074aedaf251b8a1e913c42d0dfae0fa15d5d8.png',
    id: '4440',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f87813830dfb20012588ff962df8ef4f514f5cfbef9160e73fced001e4e64f5d.png',
    id: '4441',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/643b1faac34f697ccf824d510c443262a586ea410574c8f572412117d4ec9e08.png',
    id: '4442',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/054b2464f9635f6301c61f6d9c116d4425f40839749f3ef9a0adb9a3f439bae0.png',
    id: '4443',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/caec7f3d43434ab9068aeb0d60a3ddd402e617471272a73825db9b1229639830.png',
    id: '4444',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/252caf414e6aa54f62469908e519a81257098b616edd65873b09ab46a510ea46.png',
    id: '4445',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e330a66bd6e24c3fc1fd49374fef30bef588ae4ef450f1ae5a350e2bf96ab4c4.png',
    id: '4446',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f9de23648894eea187481d73a7f636dedbdca1230bf58e0fc8f09ee91baa49b.png',
    id: '4447',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d087edae5144cdf69e948a5a7e2f740a0b02f3d88fa900c0bd0fc7912d13eff1.png',
    id: '4448',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3a573197e8a9ecc7d253e569a2daa5c95fc89157f0b7d1bf0c9fef6d50ab4f4.png',
    id: '4449',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64f1ea45cd0d226a659b338b0044f2391613eaaeea89fca2b57f0fe57c2904b5.png',
    id: '4450',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b80c5ca0ec4d9d651c59995e5871777dd9e1c7180fc50dc1d13a28c142eb4fd7.png',
    id: '4451',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d621ac3945d3f0c59d82e4131092e5dbce8d8d9ec47fb3ef0044fbdc1ea0043f.png',
    id: '4452',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c72c1755d56dd61a0043271ce7bddd460f107f5a9696c2f594990dd848315295.png',
    id: '4453',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbe5bd357cb49668e845123522f6b41aebaa1a379be1886d0f556c9af6740bfe.png',
    id: '4454',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29105cccbcc5ff249f6a2fb1af9ed32212a3af124bc38c761236a28eb8918dbd.png',
    id: '4455',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ac161e93a178e9e9776a9860e8bb8d3b555f12cbe1fd1524c22b78045799a93.png',
    id: '4456',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bae85354c1fa8eaffb76b0273620ce5394589b1de3321f45112625355609e36.png',
    id: '4457',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2d41bea622343cc236e1f366d4d03975d516453e2378bbf1fede4ac22503658.png',
    id: '4458',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d311fd8294c25e7f29dab7d5be541470aa76be7abe7ac4c769ccfab134296ae.png',
    id: '4459',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5089e4848eedafd728210f02a96774a37c855585418a7253efca8f8499b067f6.png',
    id: '4460',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e17003531387ce26ac44d692051a9044b25b9bf246c2911dc41868bf4614315.png',
    id: '4461',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d59316b5b6663b85eed5935f8f798723b34ee985e148a44f48e6e96b24ae5cc.png',
    id: '4462',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88e3f9efe67948198065b22e902d492dfafa9cadb886ee2e01d99ac4bb801996.png',
    id: '4463',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a04a0706cf8f5747749c05fee428ccf6d38399bdcabe02575eee3dd02ee39c37.png',
    id: '4464',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54c118e05c1d5b91237cf9f840ab066d7e72164cb7ff09f6f30e23a87b7b36fe.png',
    id: '4465',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34546630cfcf2806216236359dc29565a704638d684868f0bb91f5cc6efde110.png',
    id: '4466',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54b3975c165688bdf872bcda08cc6a77aabf616534c9434087a71f03dc10fde9.png',
    id: '4467',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c3ae155aeefcf6e5eb82e1e36f0f6f1127029fb85eee4b695fe1ccc3c19ee71.png',
    id: '4468',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36fddd66b2a2061531cf47e9cf9251cbc298abdc038cee3f7258e0aae699430b.png',
    id: '4469',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83b304deec19d75ede684c647b6ce8e839e2e188e8c8fe00e90f39832f736c8f.png',
    id: '4470',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13c23fcda8928907a9921f31415176f77503a09e98fdccdaf3d386b558912a91.png',
    id: '4471',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7bcb52d818bbc6decd184a19fc12afb6a5a31580f6d15e6c3ba72fea8bff457.png',
    id: '4472',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d47cf74d008b17f5b73af6f26d6c186e1629d72d9e326608429564bce3344bb.png',
    id: '4473',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8b50db7249e8bd026674a09f104bce2239202a0ff55053d40aa650410c57a9c.png',
    id: '4474',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
      {
        trait_type: 'Weapon',
        value: 'Bone Hook',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3d2b17c432af030fb7d2a0ad219f3c9cab22425739e0b7060c929e0a76b3e14.png',
    id: '4475',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd66cf71754bbd89c059c7be3a4f1c2218e2b41b32792d9bd9e7b7a3acbd7ac5.png',
    id: '4476',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/317aaf4dc469bce67d7c9fcd25c1e01d89dd37d64416088d0788e07c2acfb7ae.png',
    id: '4477',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a35eb41ed042a02c2591c95598af2481350fcc3a084290aa62c21b415fc9dad7.png',
    id: '4478',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a5527b7ab3cc47aeefc61def3166330cad6a957c0e5d8821170ff7b564d7652.png',
    id: '4479',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/347a8345797f9076d16b42de87ab900bbcffcd0f8438a104ac882ebfabed9f38.png',
    id: '4480',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7af011c612b567054735b42d3e35ebd156c79a003a06959afe91c7ffce7d6fe2.png',
    id: '4481',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb8a9bafdf5b107735d1c6aad05bb2df3c880947353ee4deb4e730c5b49891c3.png',
    id: '4482',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f9ae8278e70691044b220098dd1e6f7dfb004a28f55691764c5175187a118b6.png',
    id: '4483',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8696248c4740055d9dafee98f8737c5a7c7af42d16f63359f93f4219a2add6b9.png',
    id: '4484',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05f630774bf1ef148d67de524acf281ca09d52afa75c9c8b091fc90112558a86.png',
    id: '4485',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6718ed055749a2315d47f19590f0d83345e7d02dba9dcbe2cfadbc1ae2c47dea.png',
    id: '4486',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef24471e1776aced81a1655ff54e2e979fed84ec33379232ca7dfd9cb4d85eee.png',
    id: '4487',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76506129d9cb2316357f4f4165cd6395bfdd05ce061cb7d287404e9e388da725.png',
    id: '4488',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4afc258628d9cd1d3a6b5db04088327bb7293f6dafafef5dbfb511fbb7a0fda.png',
    id: '4489',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea476eddd994aedd0c89f7218b6f9ec36ddc7994a2efeca66f4afc702bdface1.png',
    id: '4490',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0a09f179f8510da5b87bda43179da2f310c0c3499756305913efb0f2a2a33df.png',
    id: '4491',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbd9175f0e715693bcc087c04409680ede8e614f606cc059dcb85adf4468d235.png',
    id: '4492',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94c53db9fbf0d7704dc07c7f4e8ab47b43bb5554c8fb45cb36c047ba1805087c.png',
    id: '4493',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
      {
        trait_type: 'Weapon',
        value: 'Shards of Iridia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c92c07c163e78589cdab231eb454c62a25789f4fcb36afe9f722ec598002e7da.png',
    id: '4494',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ccfc86100cadb330febe8ca896a1f318477d218ea6e98ea67385fe44db128839.png',
    id: '4495',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d14382bef9b7ce5705fec506a222a582eb726e592132415599dc31af2d1e8b86.png',
    id: '4496',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
      {
        trait_type: 'Weapon',
        value: 'Lights Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c4902fec37a1cc5801b07788bb7e2515cdb8daf51f5fb2555a2e90bdb1c584c.png',
    id: '4497',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6d99fc6b3fd524ae651882cbbef4dc5c305bb7c775155229e7f490847fd4d07.png',
    id: '4498',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f731b497d798f78269ea92cfa672c3455548ca2458ea97847331f92ab2dc7adc.png',
    id: '4499',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/527e0ddd4dffcb5dc825a3dded7277048f124d238d18b36f1597b5f30b6de36b.png',
    id: '4500',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a62082bc56768a937f4bad02a4bb5e36cc695c287dd283cb1a3ad109b48a571d.png',
    id: '4501',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d75bc229b5bb23a9586ca50f30d12e6d6d715d8e023a1a917e6a7595759c45a.png',
    id: '4502',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74a00e6ef0d1e967bc194713c0a5f908cf6af5e9a495a56c8eb9fc9e68d852c3.png',
    id: '4503',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4aee1796fe77d4bd4f621ac9f8436694281400766daf49ccf63b563daa130970.png',
    id: '4504',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4424dceb0ab582be701042942e0e6b943317af6c8938aa0decf48a8e380e82e8.png',
    id: '4505',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76dc8176840833a97afccc5dc9d79410b9faacafeb4fe70be21e8b38f5f4d821.png',
    id: '4506',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97d459c2a76ab47fec364010c4651e24e2382c188a62dfe5a8957d6824788916.png',
    id: '4507',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8cc02bade25d39f0d376b464ed2162742790c6496d8db856190a5c37334e83ca.png',
    id: '4508',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/300f163fe527f064a7c81b2693457784e7eb44bf2b57bf5eade90d2857c752bd.png',
    id: '4509',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/845ba7214e8e6caae3294be753e78d2b7297c0eebec213e836a004dc43ba5901.png',
    id: '4510',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dba15ab96e3e44801654aef173d3c030b478c6299274036508bb0f012e47664c.png',
    id: '4511',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63cd5c60895bb8565aebc1e56985c1a4b3cbadc2bbfa6667b231628de484256f.png',
    id: '4512',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76f0dc417b7725c400b59821e9d9f4d42edd727d786d5668e6236af98ae0a098.png',
    id: '4513',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09e0fa4826ddf20b6fb382d140e97eb303d68145062e5b85ae4024402e2a43b9.png',
    id: '4514',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d5bcddf53718034b94cea8d4005bea712edffc74157a24db1c8f4dd388d1ba2.png',
    id: '4515',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf7b9770aa3911641714f049998448bfbce1b2d3072c63bcdedf4594c48834a0.png',
    id: '4516',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7683b1992a0f885a7f4224a1f4b0c786b0a625e5bb6da20e4e6a6a1d139d729.png',
    id: '4517',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5fb4806662e55c7a544d62253ff939398a86c2fd5d85456c3a05cdf5c205479b.png',
    id: '4518',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6cab9005a5b27948cdc6007d7742290bcb1a4968b84fbba71cdaf1464a34e8b.png',
    id: '4519',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5af8c00e7d890f2c7f29afb3c2d74422b409d4398a3eb8ba7b93a78b4fa17cd8.png',
    id: '4520',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45e4cde2bc1bd493848fc2c4fdd10f206431d7ff6c68602ffa1351b92955e377.png',
    id: '4521',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ab374458c7f410fad7919a721ad4d31650cd51519aa864ab6905db1e3a7416f.png',
    id: '4522',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bea4ae3b5d8e4b9b38673aebee1750a5f15aa792817a376c034aa3756d7970c.png',
    id: '4523',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b2b1f870b55f3623f3291775667e7cd208c40fea37d91688bcca16545aa658d.png',
    id: '4524',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/715b9582b2cfbd45941e034657447ba36c3f0d62be6e49ab5767863602f23439.png',
    id: '4525',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/900d131a4553f19a59f42032efad67157dffdaa5d7092f590d741eaf72bc4b14.png',
    id: '4526',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6d661facec314953cece0c891edcbdc18fc0eea33f656a7d00ace148c25b9b4.png',
    id: '4527',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/255acfba9e29438f028171bdac40c917f9c6a51c2d03b7763fba60e555862bfb.png',
    id: '4528',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e4f2a11ac553fa63359e3bf4f130d9fb11e1a43166a0f776a1cab4a385058dc.png',
    id: '4529',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91c128afb5c24f2714f458af7821fe6de8bb41c49b91994059d30071cfc62856.png',
    id: '4530',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06273f09dbc62bfa05c7d3c448557428e04dbebb9c8fd092537e90f858b75933.png',
    id: '4531',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae0ce5ac6bbb2ad879c05cb03c418a1ea1a12ce4cac5ae9e5aaef392950747d2.png',
    id: '4532',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/464797f9b207f70a8927b44f34b1ae717a83c5acf3c55567fa2f61bd54a71106.png',
    id: '4533',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c5c2f963e1a40c60954a98d174deb41738cc2f11937252d43cad3596f71dea1.png',
    id: '4534',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9662cd64c49efb8065cc75891d2ccac2efa651669448727bb602dcdbdafc55d.png',
    id: '4535',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f92ea04f1bc809e151aac54e873d6f006d1b508c4bf1eac4c19b1f49b278105.png',
    id: '4536',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29330fdd76195d33ef804b1d50847be604d7e2444f56e69c3be67fecf0e83b0c.png',
    id: '4537',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skully',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0827377f26bd0e1ffa0325fbb3f8c7bb847284aeaa38efae0c847323e8d571c4.png',
    id: '4538',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d82bfe39d9650cd777ba0274128f1f8a70921c11820abf948c29dcf3478822a.png',
    id: '4539',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba28319dca49649ae670ca20f118837e3e75bac4f3699af7c9852cf1e0312146.png',
    id: '4540',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/224c6e75b43c4976be1c392bf8fe235126e0fbb70fedc6149191e770fd427d2a.png',
    id: '4541',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9df5325331ff37918df0ce094324f70d8152d6473703033b492f0389ab413ec8.png',
    id: '4542',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9581a951fa570aec11c5da2c88882f416e727c56d9642489cd8a1d525d902d9c.png',
    id: '4543',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5c9eceac533ee3007f6fa3c48f8e10855178df206c845ae0b5fadd333b9d506.png',
    id: '4544',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe9c65bf6fb71180a99bd55e19ce7b14bfc8776a8bd9eb4b2aa3f894bf2bc31d.png',
    id: '4545',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a01f2ddc2028da898a6401bd1516c04fd65eeb609e00623b75ce2c3bf8532cc.png',
    id: '4546',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c350b20bfa4108f35a0e786f6031c21a0f8e01e682d3dd3e4f0d90dafdd7234.png',
    id: '4547',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a84d18943186f94840aeb0542b5c22287b6746acb57f6ce0ffdb7f8ff5422989.png',
    id: '4548',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63b07cfbfb9b44628737e2ad934a14acf73dd2e5f743078a129ff6a4b4931552.png',
    id: '4549',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/772b2e9f02348fe01283206a3e02cd9139649c4999cd0d859bcc3ec879baada9.png',
    id: '4550',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/342264ca765130e4556b2318e6cf731d13b0808b1fdc33d6570418974a832047.png',
    id: '4551',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1454951b1518a6dbf78921b58a762ed9e290a2e0076d5f0948637525d1435d46.png',
    id: '4552',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61f7ca15a07086080e80acc4825f74aba9e738fa48c23ccd89dc9f1d2b894dd6.png',
    id: '4553',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1871d855e17f53b5303ffe8c9a4dc880a0379a120c2dfadfca7258997c31f4d0.png',
    id: '4554',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc7493ab008613b79c9310177c0890b9514224c1be23a29753d87825dd1d24cc.png',
    id: '4555',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3694bcbb6eb895ba1e239bd697073697364e2659fba7797f332dae327337e45.png',
    id: '4556',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2011bb8514c455635d8b240e9cb945a089e8c7e1ab9923ba36afc20c5132c017.png',
    id: '4557',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2099ae8a7e0284bb7b891d861e61bb36e769464e630b4b964aca6da118b2a0ea.png',
    id: '4558',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b4b8668f1c4488a4b2e467f1471e343445b30cd0551b1063743ee68da53e7c1.png',
    id: '4559',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77e0c0900f78175cffc7955f01bc820613ca51a9b164c110b5c5dcea23391aad.png',
    id: '4560',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd9006e5ef3c514e4b889ecfd9c3a7cc40ed2bd7e0b8c8c4c87f42d14b41590d.png',
    id: '4561',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5957f58aede69797d9d0b781b51340501bc432b918d22a24126af931e0a6ae51.png',
    id: '4562',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d6d4b2076b406054c4e5d62a51518e07385d33c6d88cecfa023053729b099f5.png',
    id: '4563',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e1eb732ed2c1fea1919cfbbc2411ec2a415f96d6a087f1368058a399e55cb81.png',
    id: '4564',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c783bb2053555d5df894d1b6cc69b1327496c0d4a510f0bf9788a6a645c0d5ed.png',
    id: '4565',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/753b172ef717b7c8c3d13b4f452451aa6a3f71d7fe45fd420c50a63fde39f925.png',
    id: '4566',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db548893a929974c56c3f545e7a423db67d1b48e0ade22182ec09a78e08a3224.png',
    id: '4567',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69e024cdb61f8fe5154b1b604abbd38c96bbd7867d9e2fee646df57c14f7adcf.png',
    id: '4568',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1318c44c145e402e17bac3d4a5a5b2309e04570de00a8751c8b987ddab9d7df3.png',
    id: '4569',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f29734f989a9a4499da28b00e9e4909dc49084d2290042e7986cc7f269dbb19.png',
    id: '4570',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f3d8bcac9598125b642bdeddd372afa9609267d25908b5a60cc343e583f8481.png',
    id: '4571',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddeb96f5920b1d704965029a8ac49acb8a4199b6e6205714ada52c705a619be9.png',
    id: '4572',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04f6dbbb906cfd868575ef3db82418f377b0b058dc0c3acbc08195014296eeab.png',
    id: '4573',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/194830f6a73d95ca9f2a58d1fc3e4451a9690e1b8b69f3556ddf64bc326ac751.png',
    id: '4574',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7d0c2adf5e1f792fe4673aed80e0d6adb788baf84c550e8ae10ac8d94020776.png',
    id: '4575',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89f6e1d817fb9761b44c767544bb689afb012d3d898342d1ab5267f6d25d0942.png',
    id: '4576',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d82db95bf11f74ba5b5a4d7713b991b624e623daa1017fba6539e76eb01a6323.png',
    id: '4577',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78b918b390874afc9cba5cd679ceb6801ee6ec4d2bde7558416e7b71155f93dc.png',
    id: '4578',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0a96101e7dbe3d961ff2666a2e7faf1bf792f841d478aab765fd0b030b17f17.png',
    id: '4579',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bca08d8d606b06a17c4bd58476512635139659bb2519b5a4beecc73eb412d31b.png',
    id: '4580',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d1a5df48befcc1ae49dc5730ea78b1bd2b0d62e0cf20df18d996368fe2110b9.png',
    id: '4581',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d86d36d106957807c6a7b799295628f2c81f909bd7b97a37e91c24d8a1dfae29.png',
    id: '4582',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9f2fcc29a3168ff286780c54922bcfc6e910eb0c18f43198cd5e689f272d7cd.png',
    id: '4583',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Gaze of Mysterius',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c28c5ee50be32268d7b3a2b42c3bf1dd6319ed77eba0e9f8659dedf7e41a29c.png',
    id: '4584',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56701da978ff58378e52e2561891475c039003bc4bea39b4df9b6b6398fe92dd.png',
    id: '4585',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc82078c505bc028aa55be36ea181ed8142ecb42eac6ad2ed7d026ad99bcddf1.png',
    id: '4586',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/703cf0a3a079fd3dc9494494dea7f23536d1923e6d22f6b1c5fc053600041a65.png',
    id: '4587',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b3c2f2448a616cc197e773caf7246ab9798cec0a8838f9e9491a33a15be3fb4.png',
    id: '4588',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31816f5871aca3ca80bd423c8929f5aa47c95dc64e08ee21bfc4d438208802bd.png',
    id: '4589',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b52e428de531c1e555ae63a12a6fb83d91d448ec78a5c0e380dc21014654029.png',
    id: '4590',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6034417976156a5ec6f4a81fc10227c350c32c4ff5c087cb5564424ec4b8b5c7.png',
    id: '4591',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b21254662f40f474a299136ed88660d6030d47428da0384f899e2873de3fac4.png',
    id: '4592',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b905b9b2d2b678d8caed6c9cac861fe16cfc1d647fcdb765c2a648331ea550c5.png',
    id: '4593',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eebab36d34f97d2592f26dec864a7e1a1df15dffec7388862f336c774d27037e.png',
    id: '4594',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c8ec30764817bdd69670eb9baf7c613a9d09a6c7f0632fe2b08663ab31d3050.png',
    id: '4595',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1358905989d8875ba9e1442d5ac81de642a2c6de033084557005af98a49d2eb2.png',
    id: '4596',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb9c7702a3715e03bafe2a6a961e1a2b3c231850246074d32488ba0d358449d6.png',
    id: '4597',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/018853ac33432f60462f86738ebd3e01a92e48d40bc32c1dd40a5f234022f53b.png',
    id: '4598',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/941ce4ce2862be2b31453ebc5a48aeb09031ab98b5f3608976014ed94bb461f6.png',
    id: '4599',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37471973ab24f5de79421a77a34d4bae884f4e5765f1d9be43e0784336cc74dd.png',
    id: '4600',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/485ec3e8e08a4fa4611f7110a47f9e51ad1d5ebdacfb97524073ed6369d229d0.png',
    id: '4601',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd58ac9944b7cd123e340e75630fdbb5b0d3019cb8dd00576efb6196a0d25ec2.png',
    id: '4602',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/618e1592cc613e139e69d3da7fd706ea81bde424590f308c485b7183aa9eef0d.png',
    id: '4603',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdcf7ad89de1ada50e468c6920e3389c7bc6f9f9e528f27b6168bb0d06d460d9.png',
    id: '4604',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/747c5cbd52ca892c75d22e41c173ff31bcfdf2b38dcfb54f2af86f395bdc284b.png',
    id: '4605',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f138624a0f04719ea491fc5cd93db99efb367328a498409e1b909d3ebd143081.png',
    id: '4606',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b4a5f3b0fb03ef82d0601f919667330fdc08183a02ff3bb8722b5841c684f13.png',
    id: '4607',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42b27e207a612141536fc546293aab0833e2d07c670bf645cbd7fa112590666d.png',
    id: '4608',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4699637e028f1075711b3d9c5c646d862afcfc96e90999badba8087fca2a6945.png',
    id: '4609',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e2c54c9ad88f27d1fafa933a1d4d24e07c00c22a56d9b6753df6415e2f57750.png',
    id: '4610',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c98c38b2481bf0eec85cc1a3e23f9cdceacaefa1e1e57dec2fcf56ed520b9bfc.png',
    id: '4611',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94ccee5fc0e39d3ccfa8a19ce05f5159ea45ebf3fb3c4355878fdb6abf7875c6.png',
    id: '4612',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bdc732e09dba98e66c3fa26de2a0f2fb4cdd96ceeb7093bba5162438613d93e.png',
    id: '4613',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe35df9f487d5c87cec2bf735eedf8063392868d0aa2464cea2e34d2aaa9ddc0.png',
    id: '4614',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aecb0f54de7829a7eebad1da1ba8db6954ceeca55b18b13eb0100e50f29a7105.png',
    id: '4615',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f50cd2375d6b54f4d371387aacebc94644023a08247592864ed266e8c78f2431.png',
    id: '4616',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/80b2a412a791929140f86a6ea6fc73334793baf74b4195e678570b6ec5710d89.png',
    id: '4617',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45e213ad9ba74c45a78007a43f22c4845896edb78213722301750eb2a9784e40.png',
    id: '4618',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffc0bc9e9bccbf55a1ea3ff93aed1e37eee8284080c6172293d6f919cddd28a1.png',
    id: '4619',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f57e62b6906a94c01e8d54fa65c8ee072f977517fa7e9928d94a749a9f5be5f.png',
    id: '4620',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41613b91dcd5a138360ec5ffe4c10d19bdc655e63a4278d6a3741ace833d5ff7.png',
    id: '4621',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/775efcc49092904d822cdab1281afe98df49b68494b67242141119c18f17e723.png',
    id: '4622',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e978d788f89d2f642888029532bfeeabef1eadc84278c67269cf6ca20a44256.png',
    id: '4623',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d090a9ab3e94d7ab249b8f46960392bcecd727e3d2e76fb23b6efff0671e1ea.png',
    id: '4624',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed5100fef6844fc9dd3ce4908964d4515c1b233dad4081f4497c32e710b55465.png',
    id: '4625',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53a26a756573c476a76a86675c475474fdd66112adc0b6dcca7b274e9a0eb5c4.png',
    id: '4626',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af61ba868be2f7ae62ff360a2a860805651162c2c636cccd8523cfae091083f0.png',
    id: '4627',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5707cc2fbdc0061a2111d575a881ad1130b1f6364a3a3aef8c5da20152fa344e.png',
    id: '4628',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d8b89ec036135a59db776c1d1717fcfe5446c297657bb52934228d7cc2efdfa.png',
    id: '4629',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c99b4c648bab274ce9ff6687a8a17e393bd80f3456d5f94cec560d35f1ff76b0.png',
    id: '4630',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Batty Spiked',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a876fff49a5bee4d4e9ad9f040480110aca4db3b6c4a7fa0c9361c8872c1817d.png',
    id: '4631',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6921bd89e9e027ba0bdc2cc3cb277f0acb267f143cffd5976e25a0157f02416.png',
    id: '4632',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5bb630192fcbe8c63ddd54421ef0f5c3f378212f17f317a781825a6914c22873.png',
    id: '4633',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f1f2a9ef7b2f5345472dc3cee386e022e03bbdb2df2692ab3264a7b8e30c838.png',
    id: '4634',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4b392fd9c5f282ed23462ea98cd200d093ca58e3402b8e8f0c8bda32fff5837.png',
    id: '4635',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fb1ab402a782c94d1ba4331504f57db368644bc64f08f53adf823dba8384436.png',
    id: '4636',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3f0130ac9122656fb83cfe628ae118df8b3dcec953849520325c50195c4e742c.png',
    id: '4637',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4b9c13e3da9540102adf58f91071b3559bf017d7b63b920f00b2ffdd8e085f7.png',
    id: '4638',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21f92fe1a2f8c850eec19a59477af537777d7924c6f867098fdced513cb973c5.png',
    id: '4639',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'Gaze of Mysterius',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e5c220f7098616e86c6dda307bcf421ca3232c39818a5cd6ff08764c78f2b3a.png',
    id: '4640',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2ba792ef3df10c59ffcddd2ce67c029c31352f37d761665ae3d0509d4511f17.png',
    id: '4641',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0af167d7666ac656c79f40b32926ea1a375d4d76a3a343d5693de01d145ad3d4.png',
    id: '4642',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/629c63c65a8838424561a600b64590298f2e599b86346b5a2728d0302c74eb4e.png',
    id: '4643',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6260f9cc512a19542ba439f1043bf6f2818b64941d683846d8a1cdffbd7bc0bf.png',
    id: '4644',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23b050d85fa8bb563cad10eb156c310648cf8a4cf4e12e2858c5a49e64261079.png',
    id: '4645',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9a1bcaf94af0034d913d2b19d7e374546af917c042e233ecb45f90264fac766.png',
    id: '4646',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1777db740ca2dd5d39cc58cef7deebaa09ee80ffd9d27596a34f5baa5ce8c8ca.png',
    id: '4647',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9aeff7260ab0315392a72b83eb001f7a20887d38bf0054153ba93ea847daa0e8.png',
    id: '4648',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/656c9cca7e74e87a370d1fd87c223e5f747c5fe403ff5249cf4f4d5e0764b0ac.png',
    id: '4649',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6313dc148e8c660e7127eedef11bf2c13a3471616596bd559fa252806e78fdd1.png',
    id: '4650',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3753f85865a48915a10e440fa6d16a5084a01098d20eaf83146ea39dbe3f8129.png',
    id: '4651',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5fe91d7ff652d736a2323d19224bbd5bf93251a0bafa07f88494f59313031b42.png',
    id: '4652',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3d6d5147b30b9d809cd3fa18dedef21127ab3b7cdaa473f51b37a544e7c52b6.png',
    id: '4653',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/182794a9ff872b52a80631b088845bffea9f9f93342b05e338faa40d39e30c98.png',
    id: '4654',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84d53a9ebdef492d709bc18ce71bdfc7a555453074bcfd8c6a22cb4c88aa6c64.png',
    id: '4655',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d35a9df45c5ebd9dca6b05912dfabd7f50956d4547e9bc7f6dae166a07cfc241.png',
    id: '4656',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4a9814b92754d6316ae4685ee18a360d220c14d72d41b0db6e4ac060737fc3b.png',
    id: '4657',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/486db000196d805a4b093f507136e740c8757817b4396d4746e6b2d4679223e7.png',
    id: '4658',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99b8630ae5e0f702d53955c1b91017d1b23a80f1f3176469af8caacfadea291a.png',
    id: '4659',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58856a7070fd1ec349115d4ca5f79c7656244d62bbfb61b0a1a7a5d7b41c8adc.png',
    id: '4660',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5485152cba180d5bd6d7c013a28bfd0c61e13860d2eef70e4e6a917cd8378c2.png',
    id: '4661',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0092447886396820610e803001fca1250a62c673d8c62c7c77f4542260b3dfc.png',
    id: '4662',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbd4492d76c9568e6459a46fc1139ea20329396aa123b3a4dd315351fb0175cd.png',
    id: '4663',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eca3277a2da05ddc869b33336d72a9018e2ee15f189a9de427493cc2096b2514.png',
    id: '4664',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b0aa5c30f12f2cf42ee08d7bd4b4b0ecec17f3eb0786a33b06dcb68d1428255.png',
    id: '4665',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d25343d2efb7c619c55239d51dd9e82cdf45e554c32fbbabb6db9fc260d05edc.png',
    id: '4666',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1d1cbdc2ecee7d69631bf862c74131be6761d6833b60a25f6e347e5f5b61487.png',
    id: '4667',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9733438e474443963c11aeec74d6cabd3cca920efc2ef0b17ad30f0d8b4704dc.png',
    id: '4668',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fca31a666cd52adb29d934a39cbe20d3d203de915e37ffb7f49f834e6585a9ce.png',
    id: '4669',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fda957786076b91e40d31c59403d955cc85d632c1f1b58759d995546f5abe12.png',
    id: '4670',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03edf4da2fcf60a8d5e9fe43d0f9282d25ec5df6213d212f1f6db7519fac0ccc.png',
    id: '4671',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7dd5957e7f94f8325ec53a8c65bd642ff2e8e2bf32eddafd3d0ff5373f2f6213.png',
    id: '4672',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32dfea810cd8175395a1ca78bd797ff6cb9000b65ee4278d6c4a9a610311c153.png',
    id: '4673',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f823815eb7890fed4baff342aa2ecfb6c685ae45d52bfe3c7c7062b5cb0dcff5.png',
    id: '4674',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7550320ef4b8974a8624be9ad7322de35ed412c0f1024028420ca3c9b47f9b51.png',
    id: '4675',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba69ea0eda36eeaa25d7dfb011cba84e3704e20476b09bc424f54d3125456ed3.png',
    id: '4676',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6325538027a049b7ee5d8ac56ff23e22ebef6000457db5013fa8eea14270942.png',
    id: '4677',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1585abc904845a85084f9a8353c72000782b8b4ef368e6f1386dc8ebb68b7e9b.png',
    id: '4678',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0073b6f709af8b5097587b982c2f8f6164892993761b147e11f74d5c6fe3d030.png',
    id: '4679',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab8711d19e777f87c03241aa46eb5ff7b26b8f229ec8aa1a14e2bb33b8a8cf4e.png',
    id: '4680',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/097cfd636e57b089d1e7042d6cba1f1ae7ff69f29499158940550394fce434fe.png',
    id: '4681',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55bf936b5840ea5d4d8384f7663706b55987acb1059ea2161df32b45a89cafc8.png',
    id: '4682',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfc62405cde177aac73637a86850a2f5dc086e23af0d8e464911571b5fd2de42.png',
    id: '4683',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1616db72f54f450db95317458a8a85596557d8594d897f2b81b46257d865dafb.png',
    id: '4684',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69f83e9a73bf3fda4c30d18c6f796d20d3fed8ae439a8c23efe0b4330450ff5b.png',
    id: '4685',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d77827af41a78aaca26c2c914ab3c9b7cd0ff5ca0a29a62fdef24368728086b.png',
    id: '4686',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24730490915a432f96bb87aeae6e894b1ddfd49287e6e216f503164d5bdf03eb.png',
    id: '4687',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65ad962f5ff51135c874c39ac8576d24af9eb6e06701628663573fca1ac10c62.png',
    id: '4688',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3dfc4ab44bf00218455996518bc993c45c68d5fddf3c6a4121764dba518ee8b4.png',
    id: '4689',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3d81a646c0b205b61770002b35fe1e6fe38d6e50357a311f1a5fa28153c4eea.png',
    id: '4690',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36e1953047ff86bc84cb929007bdf4aadee3acc0f7f2f8380ffa49eec6975db0.png',
    id: '4691',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cc21f621c94f02118226bfca10dc3c7a769f46af776ee2d94a55013bc65f8b2.png',
    id: '4692',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6805a99796c622006215d4d80c66c95b7d7bf3e09e7508930e3b1ca85c3a06e.png',
    id: '4693',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6dfa0acddba8f8b61da5d1ba7c491f16b59abaf44e4d55f6222e2c116da36cb.png',
    id: '4694',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c0dc340f69139a299dae0974ebee6635f3a39bcb7f0176e8833c4b1d14f1a6e.png',
    id: '4695',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3268df47c88e5b540d999ca14328b303cbbbc801412052e4b6f711fa0a4295ed.png',
    id: '4696',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3322f57a0ae90129fda9a895e198ca8b830aa09eccc1bfc462087e452a011bc6.png',
    id: '4697',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8a406874a727a37292e3fc382dc327748f3ede7ac8ba09c81d28243838ac397.png',
    id: '4698',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de9707063e13baacac7c72ba68a5137871c5114558eefd60b2995a67daabe9ee.png',
    id: '4699',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d1de4af0cc6f605620fe1ba0cb4b5c49ee5f6c447bb297a7ce47cf9d494ae8c.png',
    id: '4700',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1af7ad315c653626b5892d1d8fdd17d3638e0033eec1ed8294fceedfd912c336.png',
    id: '4701',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f9a32e0b1153b9165cd094c422a3b6249466bd5f1aa065984d4cc9ddb1b6a64.png',
    id: '4702',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a92252eaecd522d5a8421bf610871093bcae0b34b6d151dd023e5583924a33f.png',
    id: '4703',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56f7d8e99091de6ae5397b8cc4e660095c89fbbe0e538c8dccf61d2286be3eec.png',
    id: '4704',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1577544141c13445bf735fe12148bca004f43a031052ea06fafc2c1c28cae3f1.png',
    id: '4705',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1723dcd256fef3d280597602fd0735e4625a79d32231a234ce2f68e0bf63c44e.png',
    id: '4706',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/685778a36c2052e5f6fa019d5d78f85f087a49696ca9f08b135368af70a2ac39.png',
    id: '4707',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed9fa218c0937df55e6d1f72207615c854202a2247945cd6def7f5b622c9626a.png',
    id: '4708',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7e5743ec375b0b1c0154e3edaeef46de5c693fb0cc1d8aba80836d984869405.png',
    id: '4709',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29eae51334c96d1ff060c940df70f81ba6ef0c3a5eb6a69cb35544399ac5e317.png',
    id: '4710',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1da50cfc180f2aa1f5e257b26e7ad2c9a702428035c1a255cf3bd2a5cfc7f72.png',
    id: '4711',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa76aec42d0e66e782fc08b0216224b8e4de8eedc5ef7d5fbe16e9a15983dd26.png',
    id: '4712',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b09c2166c47915701b2085953ff7419ac925ba60080d880235397d0cc8ca7d9.png',
    id: '4713',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/445823a758b44b2dff7884bad7a2130d8037c722f2d860ed224f924f812745b0.png',
    id: '4714',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9906340e92f8f561bf756233d861f2edff1f21890127558c00becc4a3349f8a3.png',
    id: '4715',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/859f533dd5649d6c7c17b121f200230f5d8378b5c44848aec8852edbd2890e34.png',
    id: '4716',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4b9d7ee48620dfaea56a2dd5433020341fba527950d566eac0cf6965d0c1be8.png',
    id: '4717',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cde01baa45ef69ca7460749ddee5bebc818a418b286b9c9a13e5d53e2576f15f.png',
    id: '4718',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10a17b62b384de0a45aa9a916f00c5e6f1ec7053b490e8de6519f50b3d7d54bd.png',
    id: '4719',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/732684014bab6f1dc9ace2c76638a8371af3a007e6e8757f5e5f32b8ce052e3e.png',
    id: '4720',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de11a8fa2c88b9897fe04e992802ea131efd1ea546995e4356b139b26457143c.png',
    id: '4721',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2eaaa7abddb689deefd8bc083684eba7f2b5d5657714598aacb3f8b9c5405bd.png',
    id: '4722',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/366ee0d5f3628a6aaed78c8bac96477d62bfadc2cccadab470983810f30e2a9f.png',
    id: '4723',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5ab84d181cde83e7c15059e22fc714d6c483ab64b99b27e227bc6b4e7b99dfd.png',
    id: '4724',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5789bad30da4416caa83159e0ac8f952fb7be8bff20948017c88251a6e143bf7.png',
    id: '4725',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/082ca9f7cc5a1b9d3d66c5d940c9e4795d8e736445cf0e8bb833ca9a87929937.png',
    id: '4726',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a3fc152ac586d6e942ce77e3b62195ca4f909201a8ced7bb6e285e48a2dd16c.png',
    id: '4727',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a471c861c7431ee5ba4230560722f81597faea84ca09c5fe912d6f310db5722.png',
    id: '4728',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2251a661590ee1673a5389f310fcfff6c769e62c3fc3e94bc001156b3d0313b7.png',
    id: '4729',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/211d41bde8cc82275096208d75d3949dd06469a9f568cb40eade6392aba8b500.png',
    id: '4730',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9902ab917429f453a8e82fb446fa8ea909b588f64ae62c5ee9f21207c655f9c0.png',
    id: '4731',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f41811ef13a2cf80f78ba3a198340b7facd1629255765442f0c304e0583c6212.png',
    id: '4732',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d14c3d16c76fc52ee145f746aa7e14f76f2b19e2857c77453a3c1df8e5ba423f.png',
    id: '4733',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13f7ffe5d7568583ee8e57bbfe1b743b43e02f60f1a3b578ffd5e014f44eff01.png',
    id: '4734',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c83e9afbb0fcc15b079968f5f0730bd3f9ea296031b819f5f1d192f44dbf682.png',
    id: '4735',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ffdd14b2ba4afa3996a9397529fb4dd0d6b08f3e0baa8a36865cfab19a3d266.png',
    id: '4736',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79451a09cd6c1e74b629814ebd139a5fa479413a25334f22c628797be08cc6b2.png',
    id: '4737',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d220359b70d5c3d5bd7763c5c597b5ffd478d2ef57621aa5adaf73712fdd57b1.png',
    id: '4738',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17ce39a0028fd5f6ba5b04e882020e685b4be89fe2b0f950e52e93d55711dae5.png',
    id: '4739',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e5333ba127dbcd89c111f5fc924d0291c1ec1779239978664c350e8a161c0dc.png',
    id: '4740',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a085e469d29d65a4c17787916e6aefe3beb5cf5861736b052580a8dd9ae18e28.png',
    id: '4741',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/933b6a8e0c339410ef231ea5549319f8d92dfb8b0eb3c9cc4da1585265334892.png',
    id: '4742',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Blasphemy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c7aecd747a706cf76f2f647db5f4b63ebd99128ca0eca69be1d74a8078a9adf.png',
    id: '4743',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/367d9046b4ca918aa42107333fc1f39b25770910fdf5a79b915a8e06e21d0e6e.png',
    id: '4744',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7ffd2ab899415154cc7d3952902073adf6bde118ac736f941fb0e605447b8a7.png',
    id: '4745',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/801a6e3697f62ae77c7ac1b2e316c3e17985760b93af8ed55eede496f392b1c1.png',
    id: '4746',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/498df212d1c29cb86e3b0a282ea269ee26686cb3a68c7dcba0220b04c9f7c43f.png',
    id: '4747',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77fc0dc004a14a587e5e2340f988f2270aba189b94baca2d5f6dcfca345f69ea.png',
    id: '4748',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e2c5345f0dc83bace4951b52c8521e3ae4159b55e5edc7ca87901217819d245.png',
    id: '4749',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47cb4fc8dfdded256f4e8b62fc791e5deb4ae61e748e996e28bea4a4e2441e9d.png',
    id: '4750',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8a0a7b30b118e1f8576d029e06b50050beddf3aa3e7f1ac7475c6f97ba757b3.png',
    id: '4751',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5beb70cfbddddeacf6023899c22723100638b6e14f6ef1bad1be7a3e1c56c0cc.png',
    id: '4752',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e31475979f89e53dcb7dd5c26b21099bff034f5f44b59969dee17bb175f3999.png',
    id: '4753',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7911a9cfb268056959494bbacd91b5e46ece17709abcbdf19adbea635a21b925.png',
    id: '4754',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Shards of Iridia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77a2fac0f76cb4e568b3f472e16962b192216010e79915e7d81fccfc40b01661.png',
    id: '4755',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a290a126ed0dbb237af027a5fe57a188be8ba3bc2f933b8da44d19cff04dd0d8.png',
    id: '4756',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cafc86c1f3052d03c5937e568654b30643929e644bef08b79f9c315fbee5bf54.png',
    id: '4757',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Blade of Great Heights',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/327a5b44c36e8f66186a2ad51b8427434d76d4c26429f748443b02ef336e350f.png',
    id: '4758',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c9ca118a7c0bb2f00455d1d7fcc74044b6288649f520e89d39b51535d7f77f3.png',
    id: '4759',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c37f97020910202c960857b802da4153cf53018ac9e22b18ff50734a193ab008.png',
    id: '4760',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/362d4413cef9ed7f4fda86403064f8726524cef7da3f300d8ae8e5c4df98a7c3.png',
    id: '4761',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Undulate Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a98c883df7055a22facc8153f2e2fd7651eabd311747d8b92d50bd85ee65c874.png',
    id: '4762',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e378d6a42e9d795d35c7cc57ea5f7fe6b3a7d945e3bc998800a8fb0d6a4fff3.png',
    id: '4763',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5afd074c5c92910b8696c5a1aa272dcf88e8c20a5b78dbebf1a17b979d6a5bc2.png',
    id: '4764',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0602b1dadf7788143754ed8eae6068cab68af8eae2653775595143075cb5c436.png',
    id: '4765',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97f3260d22ad62cab07ce5db68fcda1e8834fce1e551f13e888ac100783cde6a.png',
    id: '4766',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6ad943e9fe05f851e38ee30ef73b74e2659e9b367f69d51d1ac764bcf4497cc.png',
    id: '4767',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b094f9cd984d5f0099f6af54a5344c8607c8e53aaa691b22df4486bbef3c4452.png',
    id: '4768',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0d2a3fc97144ae7831d20006b89c0dc4e9d8737e221da141e3479884fda4793.png',
    id: '4769',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4cb68910f645d94a9c727711fa647b4343de7a26043bab71cd5fc57fab11e6d.png',
    id: '4770',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59e7130bfa66128363622f619f937ecff14ec751fbb93280bca342ae9ef0b0bc.png',
    id: '4771',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/244657e2d1fe9ffceec7178b35e6b6987d6173598a62b06be954bccec0302afc.png',
    id: '4772',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c8e66a858e31d9b4a0a8c1c24e9338108b9153fa6363232c44c334277a63ab3.png',
    id: '4773',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/698490d2acf184dc621d205af8be0bc596a8a0b300e274ecb9109c8f45f99a51.png',
    id: '4774',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c53e8def34e697e2dec0703682d780c54473998c75336551e1a758b5de8be61a.png',
    id: '4775',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d63b0644f0d9a0da439d77e7d6019f9b1e3c9b252a805fb960e1aa0dc69562f.png',
    id: '4776',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28e4817a139d209f1464551bc97233c23c4987e03947dda8afe3c09480b76f85.png',
    id: '4777',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fe62446fee6090b1150cc44a1ebf3f68e07526ab1557911557a12e48e746eda.png',
    id: '4778',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ca50a85e6c636e002f5ca53b8cba8ffeb9c7aa6a7607bea710b1bf10d3a4d56.png',
    id: '4779',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43c3766001929f47d4002a7bcfa65b39dae45fd8a89e6beb3de1bc50535300d0.png',
    id: '4780',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5abc46ef964ba1a45475da4d9a761be5061b9951c1925bbd935659c9ca331313.png',
    id: '4781',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b838c235040e501c89f2384d4a1521ed32215a3097829c5a19ff64ac9a6b1118.png',
    id: '4782',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc7e950d5dc6a0b7a1150af937de1816363fd4cb96889a227c2f74e7cafb6d93.png',
    id: '4783',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b478ab9ecf057302495625fe35d4d302c1b6862bd29d0390c3aaf5e9b445a537.png',
    id: '4784',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40521f7d1d1afc15a0375cf6a32a501cf3eedb4b25912a3aeff774354ac180fa.png',
    id: '4785',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c982c550f522e3909010fa2ff6c22e0c7e233b7eda099c145d3be398ab78ac73.png',
    id: '4786',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Bone Hook',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32c007e95814e2ecbb839a74f8d98d00c0b9ee9a924d5ab134bed5c3018abc0a.png',
    id: '4787',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a66b5302b8e9889dd761f2d144a5dd0ba10506043077ee1fe3affab4dd07dd1.png',
    id: '4788',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a63a88582000d5d9a6d0783115b048af5861533e238e6b91cd4be695f8c0acad.png',
    id: '4789',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e56f0f2e3ced47c6bc8dd6824e1d005045820f53a7c2b5058e36d441f0b65626.png',
    id: '4790',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d7d4eedcb210c9adf129ceeab831f9b98d7fdbb3048702b44f1f031e523ec34.png',
    id: '4791',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7fd1d35198e47f36a08f028a10ed52e73667fe1be3414682b84070e7cbf12dd2.png',
    id: '4792',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Monster Ball',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8fa5063da61f994d9b8ffaa9a8471f4c18f7aa4194c496698d51b66b2fa1308.png',
    id: '4793',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e5fe85c6b239c273e76d10ef46b7e5b18bb5d23efadf63d5d777771aec6e42c.png',
    id: '4794',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ed985d90afd48003edc127b3f05fac8fcb636293cd511706c3ef81bc23129e6.png',
    id: '4795',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f488a870a3bd42ba83e83f5d6a5e1833ff25ff1059e7735ad5ddc0252788ab4.png',
    id: '4796',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41ca0117ca83bd284819752f9664971a95f96bb548dec0a51714f75072dc7a84.png',
    id: '4797',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10beecdf5b0c470ec6e5646dcad389af9ab46f265c27556db691d2ca538796cf.png',
    id: '4798',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92860fdf2c0e1fda7eca7c389e176225ab12092f3467fec40bef002a853ee6ff.png',
    id: '4799',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6b544c09793e1ec535857cd4190cacc7c23fbc3faf1d2cc5e3e0de74bb25f88.png',
    id: '4800',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b3099265cc4a00f0ce4e836d2f9b0cebf451754998988708ae8691f7ce564fc.png',
    id: '4801',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2e4ab9e3a827a866ad04664e9ce91c7b559200e6d7c343dd75f36c8a7bf9980.png',
    id: '4802',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67c6f08b921211895ee17436891ac0e06e2db5fd95f1336416e390f0c899ed40.png',
    id: '4803',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20176a48916e02de5bf452548b0f0321cf0d552373df12b64e8c393e0fa6ff13.png',
    id: '4804',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2c8f0f53627233edac765dc95241d246496634ba4a52cfb92619fd309d3e5d9.png',
    id: '4805',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/531cc69b915d78159180b70c333c04c66c569581fc4d83658e0e0f1706c85a57.png',
    id: '4806',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2db035ebb04ae20dd9c3ecdcdae8b15b2693a861589bd35c2baee6b33a62e418.png',
    id: '4807',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f65904405c4b290a03d367b30267bd40d8e3ab027f615a7161df332ddeffc57e.png',
    id: '4808',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca6b207ea7f36e611602d5e3b21f7661843005614e1e94d681e99c81a0ed6b23.png',
    id: '4809',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87914c2cf398b166005783c7436ddd878bfe73e7d9d45e3c3c79cc61ba71d719.png',
    id: '4810',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b2f7897fbec47b2197d531315dd6ca284b3d6e8cbf407fbf4afa4a366aa34c2.png',
    id: '4811',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebb4b4cad9f8d358924990def4d2f4d14b06d4b4692313bdd8e84402967e5654.png',
    id: '4812',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6e98158442c1c235e3bc118d094276dd7428bae9f7b4d51e0ffdf6fce0c92b1.png',
    id: '4813',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca1bd8cc3c9877eac16510618cbe36c8f6da62b13668db3448540de1572c0cc3.png',
    id: '4814',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a5ce6e8dfbb539548af2b3a9906f71063ddd8318f90f3a702a3d9f51457d1a5.png',
    id: '4815',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03098642798135433e10ccaff7e0d0e44daa9a197ebb1f995a2edf063fe1059d.png',
    id: '4816',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6a3d9cf2b0f3f09a6e2dbe21b413b152a1f719b90df4b93972d72935f74366f.png',
    id: '4817',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7c6972abe930212bd7fbb4331a5accbfb4646554ef558ebf9aaf4efbbc094ca.png',
    id: '4818',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'Grazing Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2448ceaf80f43d45f667c2be8c5b91214deeccb94cb3e44ef6f9d0fe478e92a2.png',
    id: '4819',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/647e4295814bcd7e6a09e822419c7db4c4f3337f26fb7792f5b8dcb2acd5f8a3.png',
    id: '4820',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e464af6cd1e0db825def6e6bc2a7b27fe5c5fc939a6da2743ba50a764dd93c04.png',
    id: '4821',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7458f7578dcadb5a2b8045e7edfca84b16d44666a07913662c6b341bc079a9b3.png',
    id: '4822',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c051f926c7244f3aec06e134ba4065f39405afb9fc17ee9ebce15b9c32c75b6.png',
    id: '4823',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d5c6d058a8ea6fdec782a47dd3e1eb8fdfca56511161d39fb68a2dbe4d183c2.png',
    id: '4824',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0614cf5a342001a4ef43280d8b9383836010d6463a5091d96a2b4dd855e1e2a8.png',
    id: '4825',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ef6b5ce04d584c4d32f99e3ffe48c56763101bd024c883e06c369cb769236b7.png',
    id: '4826',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/251a5352df4a0e13a9d208fe4e057d8ea1273bc7f5115005be9ebf7366f1c021.png',
    id: '4827',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64ffbf0bebfc60332a57dfd466881abebafb2785f6bf35a5bfdece7a1c9188c4.png',
    id: '4828',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c6dc7435ef8579da9d59e56b7b5a37e9fa47f4a94b699fd86dff54211b60d12.png',
    id: '4829',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f9f782d021456b967baa34ea719eba8f084e0a095c540b3bad042f887a32600.png',
    id: '4830',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5c341ddc2e0e572f9119380e7be6844410b0a2ce63ba10deb9106299463fb81.png',
    id: '4831',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bcf7c601df9734cc28a3e0f71105e4921a76bbb6211c4c7ec19b18cbead29996.png',
    id: '4832',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa0e710031fbd292ca25cb54fca0e118c45c0268f238230ea305f332309d6d07.png',
    id: '4833',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88db594d2e765343a900874b07a76418269d2985904e53b5ac4803f73886e2a7.png',
    id: '4834',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75b5b57c6ed1ad944933c6124f6927f6b459c96d238c9c646f49767cee871746.png',
    id: '4835',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Scythe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41f359fcff8e8cd26a110cbd25c9c86a6cec7d9f33914b90ba117af6b10ecf2c.png',
    id: '4836',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/169ea0ddbca6e7e6e34ebf09cc94e169be8a52e95c805c4fbed504a9aa9a7c7f.png',
    id: '4837',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08a14f034f698c83183eda2d96748311a765dab0d3848fc266a0457c7546975b.png',
    id: '4838',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c033ae652289204be6727cd0b0b4b56f77a8d7441a141fb799aff5fa48a52436.png',
    id: '4839',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83113733ae48f2900250fe8d95d1410f4f7916d0378f7b47f612f884d4511c28.png',
    id: '4840',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fede0c8edb14d6716c5cffedd44f8638f248187b8935e9bbadb9e59a3fa0e8a7.png',
    id: '4841',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3bfdec41eb0c86a125ce099cb6c38955005a7be29227a196d82a10e50a2b1bc5.png',
    id: '4842',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7017c03a9dffc4bf894cdc34eecbff9f411f747a212423cf3b435b864bd3d081.png',
    id: '4843',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/823b13f6ec47c1241cd432ab23b1a2b82e1b08f186dd69fb2586151b1e4b0b71.png',
    id: '4844',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f12ebc4a78cc63f7e0049e9b13507bbdcfa4e3859342fa94e99eaa951d91c3c3.png',
    id: '4845',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d7caf5f75b27436236553a3af4e73366983f7a5eaf87e870c5cdb35240587fe.png',
    id: '4846',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/421047a8d0babb2a0d00a289ce366504db9ba034e08ba18c72d167be44a39f6d.png',
    id: '4847',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69c70facb6119988eed83bd7baa773baca7e0f4817b6c2731b02c97926c8f09e.png',
    id: '4848',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d10cfdafa1dab26cce79ad3bc081d655391f1bffd363d9224aa8abf638a00bfe.png',
    id: '4849',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d9b1cde5b32efa9e5b2e1c839d3b73017071a01698c81819ecd862316579527.png',
    id: '4850',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0322b2dce3486ab5923680bb09f86fad884e7c4efc2c96e34b4eb3607d8cf91.png',
    id: '4851',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b1fdf1ad73b1db3e154e3222701d417f8002e0e23cc20cc669d09054499e861.png',
    id: '4852',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77a0f5417e6e13ac57a8ed43b539f9ec64c0c77402f7c639e43a938a2a7101f2.png',
    id: '4853',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a32e5c194806122b8e3a64042ecdceb8bb1b0c422bb5500ce80828657f7136c.png',
    id: '4854',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b4c7b53e84fd129d292558d29952928ed2fec063fab039b2282617ca1fe1988.png',
    id: '4855',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1884d8fb33368c7b278457a97a12ec2ef03d1671a2dfd7c929289ff44dff2e5d.png',
    id: '4856',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0fec6313edbafb8725a85debbb8c0159fd7e28c79f7066a269933f5570f53ab.png',
    id: '4857',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/158fd8b1db45749e4a3520ba24a926c74dfac6a0a189648aa64fca9e1b849fa9.png',
    id: '4858',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1e9799ac754196fb52781242fdaf9f9347d3cbc1356fdb2ca02ca0b1bbad2e6.png',
    id: '4859',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cbc7de9a833e5369a77369b9b329860d5ce063bf74c07650ae97ece288d52b2.png',
    id: '4860',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf225c4b9ed262d655549efee6cdf954504d33587e96858a8c60ea3ceaa66bd4.png',
    id: '4861',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/240a1d8ca98a06080924a56e5fcc2b6d2fe12d14af399434651ff883e0116248.png',
    id: '4862',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e7f43226d8ab8ad8dfd494bd149bdedb1c392a5cd99a305a44675d8eb780758.png',
    id: '4863',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Scythe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cf532aa8d0f039c4191ddfdbf201aa174537e645e95bdff7b6139b1904dff93.png',
    id: '4864',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a63ea24207270a885b7a81a8ecaf10efb3b4742dcac734e163454866bdddd75.png',
    id: '4865',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee96e6b6c8b5937b39ee5a44da7946dcca2cfba8cbaec9178f7d1062bf266732.png',
    id: '4866',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44d7804555cd910717482684e6019760ecdfa92d8b792d6ccc0a853aec715807.png',
    id: '4867',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cde4cfdf83127e314ede69d31a72b4ef023e1e004dd304460a0361c8cdb1c09.png',
    id: '4868',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2931bfddd3247a510953b00355672ba50d7bed6f3e9723bc36d93cd377a39f41.png',
    id: '4869',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56a16a90db3114d9bd6bb471083cf6ecd9c83cbf140d339f080f7bb499bed313.png',
    id: '4870',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0724d61e4412532e6d2cb49bdce207e4fdf38b67efb610b6d3b67b50905d8f07.png',
    id: '4871',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32ca69a8865d6b9c9e81e80731720f159bb988ba2790339fdad5d539725856a3.png',
    id: '4872',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a5f45f1e73013311fecf57836ce6afe82e5517761f068735fc4758fdf0549a6.png',
    id: '4873',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/435916433c905b5a68e0408e310c34e036a849fd09fea017c20665580ecb4e3f.png',
    id: '4874',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d67aa230102f7a69f9fa56010cd2e376f03c905dd7126fac7e6015f6f8a617c1.png',
    id: '4875',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae32df8d308681289e1fc708fe23ba28d1d76f9bd4d9c997bcc77b9d588644ed.png',
    id: '4876',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f762a0528fcda3a53fc34ab4500361e4732c67fe54f959dd3a6d670009f1a63a.png',
    id: '4877',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea88862c3e4ceea99f1464feb3913c0bbef37b4384993d174739fa59208dde4c.png',
    id: '4878',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4bfeb98c766b98252eb11206072be2cb2c9e556033f6b9a78c079ec3449a72c.png',
    id: '4879',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1aac8f26ea00306cc3f206326aa66fb1d85f4c02f44c419a74eb5dacfe9d7e71.png',
    id: '4880',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b050b7b98cf1e35521408065d42d00f54468d398c09367e47cfeb5d5b5ba63d.png',
    id: '4881',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b674ca5f4d8f4a7c476c5b5019c40cf8a33207830b19400e40793e90e562fe98.png',
    id: '4882',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0fc53ae3e57b56965ea8e512d8c19b2fbb3bcb9b76a244071ddf38b19abd24f.png',
    id: '4883',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91e8eaf62e4ee0f5266c26032dd7cd1be4cc304435d1b61c353374ef22e90b8c.png',
    id: '4884',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61280cad0110d0d265498885c4e321c35f471a95c18a3674bbe7f5a2d8a6ae6e.png',
    id: '4885',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4cd7807b3b202c1753f7a2b325217b1ff9a62b3a26685b9847e844a768d59d0f.png',
    id: '4886',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4097c24cbde7115cbebfac73e41eba8b72cb7feb66c0878ce06a93633d564a3a.png',
    id: '4887',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33e1c59423ae68163672db918ded773d9f381c50c6dee89bea0e255a46eed0ff.png',
    id: '4888',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e63535e353dade31e9d61e07f83b83ad6f3a4b9bc05e567ec92d2521f1f9587.png',
    id: '4889',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5d6d43d92f33101669a43593b71a2f9261250ad07333155dd8c8d06e22fa3e5.png',
    id: '4890',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d28033058d8372d544d8dc95d214fedaf7a8e9d278b00e4012d9d86cdbf626fe.png',
    id: '4891',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c2a2a55c0b6614fe8e3d42b58fa087f68fbc21fdec8b58f7da4d91cc2ebca06.png',
    id: '4892',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06fef8b827e75d8612e487f338d530be3a5e47549652b24b8da9dd7eab05d18f.png',
    id: '4893',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b8ff3f59f3aadaefb1a8ef17aa0a85b10ffd7dc72b66a432eae73105821b816.png',
    id: '4894',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd158fe02addae2605be7aaa0fe8c0f71440a3627955bced0c7c0e965da05fbf.png',
    id: '4895',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aab990514ed400a17f193c84fed3eb8b58a63074d2f3903e93f560ec35f8d90a.png',
    id: '4896',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c7cef3a45919cb080eea04bd8244386fdf8108599207b6b3231972c30cf8c5c.png',
    id: '4897',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1572aabf88d6b4b99bcd8b359d01d2c688574cb031cd48600b972a61a504950a.png',
    id: '4898',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4eb822ffff1c9db8f2d73129a45e5dd3f1c3f75b513d5ad9efa9a8989ecedb50.png',
    id: '4899',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e3656e2ea4a24fe2d6466a80143ec9ec2d2212cc50d45eb84788affbd7139b7.png',
    id: '4900',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34fd93c8f1cfa0a88f5036f1a5c9552374d18e3caabef89c904b3e52782953ff.png',
    id: '4901',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1aeff2fd79b92d63a0d7121584cfaa8c80e63fb682b590e3a2a35b0391befb4e.png',
    id: '4902',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19d068bd3c9585bfa0c216ff7652581a541d062c3ced5a9f37102ebda8d10330.png',
    id: '4903',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c28a6543ab8f5a3a7d58f4f8308a548da0306d29370a73d20ed89a34956d307b.png',
    id: '4904',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98bc8dddc5e7afbc58c5b951a69f5ad0b55d4ba58ac02057b2c72db62dcd7512.png',
    id: '4905',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f5cad687e1bfb55e8abc869cbab46f07b8c2d27cabb09002f408a9483c506b2.png',
    id: '4906',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ba11d045b5553f242760f5fd11089234f4f86e377200682d9dc70dc236b71b5.png',
    id: '4907',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffab20f95fbca04bc9ecfd85cd8530fdb8608344bc96002507fc5df50ab424d8.png',
    id: '4908',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d18e5cc49101647861cc39d04b6b8493ba534de58a5e85d17382be08c8a46f9b.png',
    id: '4909',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c6790acf986ba229171297b7f7e2e13ecb060b579f240e6890d9be5df1216da.png',
    id: '4910',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ba642cf6ab47e3000456c464e11198c1b68e729006acb5cf7a1b9fb68d1d4f5.png',
    id: '4911',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6979e59ac876f06c4f5b28ca21bcd6494e9957128893d8d9a09dc34072139af0.png',
    id: '4912',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eaa04671193308027ead6576bf96ce1f0a8ff6ad9c65caf3c6798b9df7d7dc3d.png',
    id: '4913',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0f5600d321bec06c0e093636d823f02afbfe5c2c800f502be9c6ac9af1fc416.png',
    id: '4914',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b60fc660fed7b0dafc349f351f9a81b14c531e8130105f618a31f78b4611a356.png',
    id: '4915',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35112f316e66306e98db90b81fe308fe126370547901842ecab9f4b15a22f2c2.png',
    id: '4916',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gaze of Mysterius',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a87dede86e8472132c869e27f97d8629842b132bd9a052049f736e5ab992360f.png',
    id: '4917',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aaf7261ea29807bf119a3d589104e049c7778709c82b023f60d910c21c63f7cf.png',
    id: '4918',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ca9f091b2b728d11ff346f6070f7fc2a0306030c7d1506121daf3fd5c4de8b9.png',
    id: '4919',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2870eb2832909c24167ab61b09e5e304d21452aa6673ef44c235e4d0f69782ca.png',
    id: '4920',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73f3b875e69b6f3c0076ff05c46a5f63b8cc7f1d56148be30b008d0e293d8db9.png',
    id: '4921',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/112a5a0d11bc07a2d8adf808242940a28ae9821f89f0648a03647cd25a94ed88.png',
    id: '4922',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1e1bc971dfe90d73ef3433b5b473476b6380dd8c91ba5c8c9ae0af666b0687f.png',
    id: '4923',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a03206334d88fdcff7965c92cea4fa8d048e8e9db77dd7d3894b4d715d30c165.png',
    id: '4924',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6723f11bda26f8267b6c4f27ec6b8aa50dadebc298f01c5b638ea4431eee3ee.png',
    id: '4925',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Batter Up',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2654e45b6ca82f987351d7289a13cc34d6d60eeef0363115232ee22e8a22acef.png',
    id: '4926',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a9ec67bc1c3f42aaf188fdfa593acaab17f8788842d1ba322ad14151b603896.png',
    id: '4927',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e004a03e6275c16ef4c08c0ea74f4a308eb8cd774e88150b73accbd300acbeeb.png',
    id: '4928',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba6420c80836223554dd265f39787e50746f137feeb08046e2685d27b7b2c5be.png',
    id: '4929',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f847dc7c0cd2b32c58ae35900cbc4f28b4b6f3110b0ba7ab2d30c70e2cc2d124.png',
    id: '4930',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fe853c3ae2847b61ab316bbb4c449076b95dedbb5aaee2454066f8a81f8706e.png',
    id: '4931',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ad396d9db1d1f5efdd2ba060da8b0d28c76ef69330210bf0fea9cbf6f104701.png',
    id: '4932',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbcd0e1c2208f34706effbc66df46b245115e459ff6273fc27771831d09960dc.png',
    id: '4933',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e202a3ff61a6f865f2b210dfea67b05cb5d4cf44e46d1f0979c3ed7ed06c427d.png',
    id: '4934',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8621e70f3d6a55cdadcb1423bd80aa10ad7264ea966dd8480f2dc35635bd2e3.png',
    id: '4935',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19b461defc7d8c371d0588b6aaebec9c401ee3f27e737a7a59f08c3a1739e7fe.png',
    id: '4936',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e82594bbc27720872932455d3ba4fa7263c175bed3f2035c18a66f27c52650a4.png',
    id: '4937',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01e35f34df5133a3fb10f9761675b8fe3acbc17598b87d2b78e8f09c6ee06c15.png',
    id: '4938',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f652805e7ed234cb3898eec15737c07bd20cfe1217590a7a4a4f93982dbd067.png',
    id: '4939',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84d549c989bbc2d3f8a0c287e2a264f523e0dbbccaa90eeafa3acf7a09983b88.png',
    id: '4940',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2390a6d314d7458badb7cfee207f859edec51a8e10b94a049a8b2583137a101.png',
    id: '4941',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9b9b66b9fbd67f1ebf635658f39c948e8fa6f2f1bb5501668371652b480ae8e.png',
    id: '4942',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7d6eddfbaad3716922f3cc7a87b49c120ee21876d49b581304e5bad22e48ae7.png',
    id: '4943',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d56872e334d3de649d23a111043327aece840fdb4e5a46edfcdb1d9adae48a6.png',
    id: '4944',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7e2c31fee121b41c5f64417ee60ad588f9821be1e89a85975e5b74758836051.png',
    id: '4945',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/291cf40f2c686d89af029370fa5ddef36831995ac2d0a995b7b60f9c6eb45145.png',
    id: '4946',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a79ef187f0baec3945704f11478292a0e1b0efdff40aa3b80a8d1c334426be72.png',
    id: '4947',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad0b8e75ac139dc7983bb4c936b703173092a5f006c572d95dd353b84aa2fd5f.png',
    id: '4948',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11c6fac64b31da478bc3c4972b2034c6040e3a83311e42ce848d3158e551b8ee.png',
    id: '4949',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b02ab1d64cf4bf8b6468f755a2b9c2d71c0fc917e7bd9caaf1210ce8de0a8e3.png',
    id: '4950',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bf009e85e21f53c9f7d7bad4c7ed1f2b3758b7bb3a8f1c3ff14a8a29d3b5c53.png',
    id: '4951',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/035b5a072286da03f09ff0c0b533f08b0d2844bcec88bbf9fe7aa26be54e1796.png',
    id: '4952',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4559b7d2996522e8afd53ea926c04f206e75765902b9e0cefa2e3b9088645a2.png',
    id: '4953',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4834a2909b9ad20d84192ad2be5fbaf5e4eca7be2a0ea165091e06cc4bb3cc1d.png',
    id: '4954',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/344990b7e8a98e1d6aaf7816c420e944a8a71b93980f1fee61185518c17677bf.png',
    id: '4955',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7a9b5cc22df380172a798c8261791fe902dca6920108c9b944656bc191d9870.png',
    id: '4956',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1743a1090b081f7b12607be3422b12af67319d35fc4fde0ef19c2ce739c3e894.png',
    id: '4957',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b81f159eb5c9a884b657838996a8aa7c83adac16ea387847c54b3fc059ac27b.png',
    id: '4958',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/800ea69e2ce94e7664cc058f8da3cd773d2c243c1ffc1b82cfeea5c3c161ae80.png',
    id: '4959',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bae8702ba383284bf0660fd521119dc3e71e9ca54abeb64a56b2beb7be978c4f.png',
    id: '4960',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0dfdf032a2246f3fd9717b6f9fb32eb92b048be4dad98750bccddd22424f84cd.png',
    id: '4961',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4856ca74f90386dede8a971176dd5071fdbfb57acc563af6186a2f71798a44fb.png',
    id: '4962',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/077303755387219142245722b1506973202db304fc8b13f57e8b56feb8764c40.png',
    id: '4963',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41286192a0e02d64ee9ed5746b7422108eb3f927b801f9184c877d975fd0ddcc.png',
    id: '4964',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d42a81bdc1f14e4c4110d09395bb66beb2c5b2dc7d8ccf50dbfcf961cd67ea27.png',
    id: '4965',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aca102c400b542e3f584ff4234ae547187e3b9a9af33451520e516e98e69a79f.png',
    id: '4966',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4182d488bec2c11c525d7d44cd019649c6e9afed675cc1d744e1823456beaea7.png',
    id: '4967',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e9841b621a61c3e32bae41f1f446a01f663840ce1588056cbe62a4263b759a0.png',
    id: '4968',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Shards of Iridia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d204892dca1809dc5acb26bebfbea91f8074f02640e835292ee31b9b2f01e42.png',
    id: '4969',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce35000fb942c7735582f1e6f2d965191efd4bdf9633526e963c2c389a51f8f6.png',
    id: '4970',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30e2a02ff4a859c8a5f335d19f463588994abb9b337c62973bdc79cfb9170c0a.png',
    id: '4971',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6dee6846bd341597fb1f95e69915b1fc51106792059143a7a26378349f9d1bf6.png',
    id: '4972',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ca9a8a397faa11b6b99ba0206c4725a8114fb4f0b2bbc2b06e139a32c716241.png',
    id: '4973',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbca72b051b3109189a4850441df1c2415c126c9be71d1c112437bb5e5407531.png',
    id: '4974',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66641e6bb66fafcbd665067283091a5d52dda0b2a8a37670c970f235aa88d24c.png',
    id: '4975',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b67cbac73d6d4498f67618f6369b6de37404812b2e64f798138108d50a9adfd.png',
    id: '4976',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a9d6f80a19742f237fad430131de4cc3bb8a5de104c72597b6a0fe43dba30530.png',
    id: '4977',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3c592397fcd26e2b7406fd78787604a2b884bc0eee1a8e79f636185bc670db6.png',
    id: '4978',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2d7273d3416c7a53d8dc124a4b33fbe980d5e3e4acebc24cdb137917971110a.png',
    id: '4979',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a39779e39e2d8f9f644ff73faf564527f74a5ac465403197476a47791bed400e.png',
    id: '4980',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8be8409640aa203dc5c1e721627fadc45d8e742c2d785ba351f49639ce87bce.png',
    id: '4981',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90cc223fa1f4fd78c5369cb6ed4d1e8d622ce868fe0d4cd814e4ad88cdb20e29.png',
    id: '4982',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd89b2e7e0ed095943cad557f052c009a53ac076bb7f9701ba7a4b03617d307a.png',
    id: '4983',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e578c18ba5e8cc1b30104ca09af1a7071f52315f649a4478533478924ad4858d.png',
    id: '4984',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad6586eac6980341a7968cd33c29a262ab99f4b3fcb1836c4396519409b6197d.png',
    id: '4985',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6db77b2595f1e11ecff158e3b146f202f78db308e2203a869e5d39f260b738af.png',
    id: '4986',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6aa56e7e6806b24aa36c1b9297e9a27211afa4cc221cecb42e61fd882676aee1.png',
    id: '4987',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/148c9ce6f61b9adb9f4718048cc1714c7d1df15addc7d51e0582babbfffda3fd.png',
    id: '4988',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5c26b70610e7120461391a1c6735a5a6603eed258157a46a4d18723faf0f49e.png',
    id: '4989',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Crystal Stabber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e98a815869294a18ee999718dabb1dcb7bc14a17ec0b2282bbe64bdfe14b70bb.png',
    id: '4990',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/baaf99c9b924faa2f27cdfba4f57a339559cbd37167565d2cb7063c850613fda.png',
    id: '4991',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98cd92dac3549b348f48b630d4721cb0b7a1a63f69908517bd69f9b323f70813.png',
    id: '4992',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3208fee595affd5bc844184c2ee26ef5f6b2756c709c646433f5c9fcbba94219.png',
    id: '4993',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a2ec2929a909a2073a373f9249a1e195e5aa4c8affa8a47b657b74df49a30fc.png',
    id: '4994',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Adventurer Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc52964b7a2864dee740eafb7c4ababab768a67d2858613111230b9b6b5adaaa.png',
    id: '4995',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a78150eedaac9afc0bcee5cf12cd13bf26a768247624ed159ac14864416a220b.png',
    id: '4996',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/872492f7484fe228a1f09de57b3bf6491366fd2f73f3426e93dbae24fc4222db.png',
    id: '4997',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8cc225c1ae6ae7b7159acb758418c07385f6bd88d38a0b20dac157311265e452.png',
    id: '4998',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1e7e56b4cba46439838e36cbdf9e8483311d56f3f1c3d06ea6647ef32c7a271.png',
    id: '4999',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d868c288d1ac0708d4690d6ce1017b87f3ce1d37b96e5e6c9dc96d810ae3ac0.png',
    id: '5000',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/833bff8a2528bf9f5705cb70b352debf8a2afbcfcc3b866da4e7489b1e267aae.png',
    id: '5001',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/010af29f353cb19c278b0805663f2b96efec6c2bd9fe62ac025eeed4eb8cd40f.png',
    id: '5002',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/765859a1a099ef492799bbf4b2c7fa562c4b785847ee583b77f9c71cd7b207ef.png',
    id: '5003',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/390005b88f4e2d1581bae9afe6cb0ea5e44a5682b01d8e6979a9056bd8b291e6.png',
    id: '5004',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/588bc8f7171118bbe3edd9d561e749f074783c118e7c9096003d72ca2e875f20.png',
    id: '5005',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab02216e04826aa3cf7fac71b546198a2c446fedffa569634b8c440882faeb35.png',
    id: '5006',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7f7d4243320db66073bef250981cbb100c60721cac917d91b5b18ebd1c308d1.png',
    id: '5007',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce7081178c5663025585fcf1182b8ba3a44c6b9a4f92a15a19c1b55809459c1b.png',
    id: '5008',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a08b087376773a1eefda04ea00191f4b2f75b0aa518dfafbd0de42f3f8b2a870.png',
    id: '5009',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4891dd1c4d065207e52205e505e9ee8bd8a990d409f9655d9149ce44ac244ae.png',
    id: '5010',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd3663225b3cf9e75314982d78097eeb919c8b94bfe6b814748fd8c1902c46c2.png',
    id: '5011',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9adb42df27d4ac801b1f5e4e36d89f84438e49bcc4f6562065b296fdb9ffbd6.png',
    id: '5012',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e59ccc979bf953326ade8d9fe1fb4bb6174b3dd0b287f017a4eb0958d10bbb6f.png',
    id: '5013',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c63d6fa3b17d69045e5af4db0efaec57f06b31ac161e3a756345df7ba463c33.png',
    id: '5014',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c203a589258dcd6a8f6be19a00940418b0b6ad63e2a95073a041d8172d537e9d.png',
    id: '5015',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Winged Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11347fc25f5138787af250ada329ec38a1a271b6b1dac49b632113ae5a76b5ca.png',
    id: '5016',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skully',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99103ba01f306a9644a7f04c30d3e2c275e054dfafb64decca40ca3f7511c7af.png',
    id: '5017',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d085e76a58843b276edd7d58ffb5a610e9f7e4efaeefd9601f0d28c08e3fec00.png',
    id: '5018',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc2bec19e6693a661257f8e04d2c41611b95a28174231717ebaca799a62bdbb8.png',
    id: '5019',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a1dba151016b92072e5a6f1d01c570f21a0546c21f3fe698edf627277ca70a1.png',
    id: '5020',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26eb3a5354aaae2a7de70f65b01c83be51f36887a3edf339e7c734459640791e.png',
    id: '5021',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f81047fe1ba1ffa1a389b3ebb4caa018d39a598a10c70061c143d481267d017.png',
    id: '5022',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c1dad3e2b92b7cca5fa4ae82d98674fe7821832291ce885a19712366e1c1686.png',
    id: '5023',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0960eb4c9fde22d7b0cd1c0bfb91376418eb3cf636e03c0a03c4df5ba2be12e2.png',
    id: '5024',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93112ca6e304ea63036c4bcd2947cc07c2d1e190bfc9e820df72fe40d6928224.png',
    id: '5025',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16b48793877a49a10ffc37452b318d4d52cb2f305826cd4f553a191f73f963ce.png',
    id: '5026',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e79ed3e9002f3dc41cc5396e9fb0e1e8e55db38b9bb4187156e1cf81a4b05f9.png',
    id: '5027',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bcc86177e9aeae2419dfc8a91f9e648b121a7d38bcb9eeac0f0142d97aae3061.png',
    id: '5028',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26d0dcdb82735ed2a95e356214dee3ec6b9fec39430f34d94cc28f80de390f1a.png',
    id: '5029',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23eac9abe635cf3577862c19ca08944155bc325def0b9ac1aa4cc393396dcfee.png',
    id: '5030',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/685c6de984fc87ce4b03ef9ce352328341542707d8bbb24e9062966fb370c66a.png',
    id: '5031',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85a13ef2220aa71e6e97cdc6b8a085d5ee23c50ddec886a7ea5e64098b818b63.png',
    id: '5032',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76d85b95310fe3ae2c2624e42ba7a30a8653634816e7ec2f592c0af44cd4d307.png',
    id: '5033',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8dce6c9350e142fd47c6b4ea2938edafd7426099595dd3a6112384f09627792.png',
    id: '5034',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09dea1a559d47609fdd4837cd39039c4ed7b01502318b5d92600eae1547ec950.png',
    id: '5035',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/449af80ae99d3d0d24858e550c7aaed8b406c5800d130533e0b7636b4c841fee.png',
    id: '5036',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a55c15ef4a9372a64bd18e29d27231f2e80c9bf2455b969c83d60e16fbcbf8ba.png',
    id: '5037',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3f8e74e89cb98ee880ac3e07f0c05c1fd67a6d345017af99a43afc3950a96275.png',
    id: '5038',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fbc6aec5a3b4f05caa2285abb969409b4bfd3d4696376ece0908f03ae2fa9ee.png',
    id: '5039',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2ac854814263a5f764009815bb1f51766e26b8d5cc41ff4c08ec062b2b94901.png',
    id: '5040',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6f00117b84d279b59857a98cc8b316248604e344a57d34ad7013943c0b26919.png',
    id: '5041',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/447973db40db5493641e15052fca747164cc82acacd82d5c876b98de4941cb9e.png',
    id: '5042',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c952d152f698fec571eeb22fca3fa7796787b49ffff4cab2c1aa7b509e71d00b.png',
    id: '5043',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59e20d6f8d40d84f0762acae68e4a99edbc07c747afadb99489914353a4a9800.png',
    id: '5044',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/504ab1a7f084a65cdc7e5f17c82f7eef39a7f0022a5a6a9c754d470e962cd883.png',
    id: '5045',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1c87bf6c9e5d5edc0d3477620d4e1c6d13621ce07db1de73873fc5e54f59f59.png',
    id: '5046',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f66f64533a7ac61cce79c3a8584720bc984300c109ae80ae8d2f0e40d455787d.png',
    id: '5047',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b48b82dccca45b083f452187623b6a147b11b74579f57f131f26a04e4c15204.png',
    id: '5048',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c066203e40882063a43cfc5df3d5a344f8ab6689779464ad2665fc0ddd3327ac.png',
    id: '5049',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6456efcac1bf7190b14f1bca8c7d969772c057355375d978df22ca1934253f1.png',
    id: '5050',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc77295a05c96075778aff0ee6f7f009201cf00d6922827366e8b165ab621dc8.png',
    id: '5051',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da1133fedf8a1ed7b68923ec04d58d4c3fb8eca2cc5c882eee9211f38624df0e.png',
    id: '5052',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cad54033e3fc5988a5d9f83761a8448309713c0b4d2a89fc41cdfa0d22aea74.png',
    id: '5053',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16b495b89b7bc1d74d676c7d10620f1741d5964604d182064146f11e41f2e1ac.png',
    id: '5054',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f2817c7a51ac8a7f9fb6d44276ee3b50ce902073a03b2189056f3f26e97d45d.png',
    id: '5055',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09c36f85c30025c7c27983a0ab0f6aab2558cfea169b9724ed89b4d79c035393.png',
    id: '5056',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/999642cc3313525a134b5d599a3331c287c07be1870db69ad3198dfafa37e10b.png',
    id: '5057',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3aa9679091015f1d510749fcfb4f0415b35f1c3f9b55b76aea747fd02578528e.png',
    id: '5058',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5fe305febbbf69392af04a3e704e87e5569433c9892ea37c8fbd685a0ea5de08.png',
    id: '5059',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c378f8e40b16208a4f1a803bf3652f9832104896f29bdd172b2aa64a6fbc2c6a.png',
    id: '5060',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f0ea85a656292f133d95aad38ec10bbc99e6e55713473e1acd9580540d8391f.png',
    id: '5061',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81f88723e84857e152425d7a8616418b4b5e8ea8d465a5d20fab7a897928782c.png',
    id: '5062',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88b560d6c7224c65b56af1d57701f6161485eabec6132559a297463467ce4b20.png',
    id: '5063',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/461e594d27734db9a1bcccd35b23734277c1c483f35accca321caf7d707757b3.png',
    id: '5064',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b65367da85234ff8e310c2faf71c8624340c67335341856cc8132f7150cc2509.png',
    id: '5065',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc3dcfc602cc1a1bc56dec892336af835a14a7231d6d52beb6e79bddf45eab87.png',
    id: '5066',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0b4e6936241ed94e481df55161303394b8d481c17f6896f10a5bfc1dd0c1c21.png',
    id: '5067',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75784973bb5caed19a4d4b042ba2c37f200fd9441c16856ed50b03c08102e2db.png',
    id: '5068',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/776dbcd4ebc4dcc7f07d1ae08d23fa482f4c1f5526e7c69efaf75be0a99391a6.png',
    id: '5069',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4b17aa7d15c65d995a6364b637ff8e6c404afd0da14769f79d465e4c6ef1030.png',
    id: '5070',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f49ece278465d9713e163ebaec86cb71467584c31380ebf4ad85fc5ca0a09089.png',
    id: '5071',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/383ac69be9ee21df177d7ea7416289596f2f76d22f07860820ecbb724b7c18c5.png',
    id: '5072',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e11c7d6f22f36968cdff996f8d809bd99cb564b84949d83056704a7a9c2de77.png',
    id: '5073',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7282f04deb1d43a6dd5696b7028b71b839efe52b3720288a8f9cf02f5835d132.png',
    id: '5074',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4351bb0d2fba802b3f77e029a1227fddf1ce93b795742b53ee8d65d83cc4199f.png',
    id: '5075',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0bf8c23be0fc2fe004679a3e101633e2105f08a599e1f4aa23e69a0173b45a3c.png',
    id: '5076',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5515e6b5e2c935a07b826dfb940b96c46c845da12af1a5a4a975ba60d077164.png',
    id: '5077',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e53bb1a536049fdd0ebcb5abca818565b1c6e7ecb35b03d2384034ed592b85e.png',
    id: '5078',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27de23fc50d93e28b2db56a5cd6bcfd3390320fe519b4ac878f8fd3ce1f876aa.png',
    id: '5079',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/355d48ec5c6ca998ffde7c47e1ab3f0f12f8fc895972041c3231444d2c281c9b.png',
    id: '5080',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ada07762653f99ed7fdad38576a9d1a59474b7d7f073203fc67bbbe3d1bb586.png',
    id: '5081',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b030702318053be4c86c69a6164429ebca062f177aec5004a34d01717aafd6e.png',
    id: '5082',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ba647972896e409c39efbc292d34cb27bf33fe1b999a60dc645046f113a4b2a.png',
    id: '5083',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c8e8f754a70c468be44714521085cc7b83922113a710080172203b054bbefd2.png',
    id: '5084',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca47ff10842294b303d1cfd89a6a1d4377e2d068add4038de8382c165d080e3c.png',
    id: '5085',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/827a2f6ddba22b1631009a8abb293078dddcf8ea32a2a647cff099e96f9c9916.png',
    id: '5086',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2c30d82f4c31ff3f7b484b54ce5820050ee4319cab369e87e3d604011b90caa.png',
    id: '5087',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f51d0acfabd29879871d66a3a6557758f1fc3b43a73ebbab7473af804bfe6a7.png',
    id: '5088',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6e76b78d9f8cece439189bd0a01fb52a21599247c5091c0884a3834a230e47c.png',
    id: '5089',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4ef78366efc200db3dcde8b44b1cb5af4276303a7d064cbc9425133c4f99516.png',
    id: '5090',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/947b1d2c8e7a9d033407acd40e9887c49c1b58dc73747f249f30f19d0cfbe556.png',
    id: '5091',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/692353469aa83fee43c0710612eb2747c2b0521bab16b54690b0fab77c91a710.png',
    id: '5092',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0e7fece766667e8a1f4bd8d96df5ae20ce813cb4094cb0a44b3835ba2c3df8e.png',
    id: '5093',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d88e590849c7559e0a85088c1362aa2163cdd2a55319322185cc3eb275f65e91.png',
    id: '5094',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a644bc90355cf60f786487a14912415c2f65c65c590ad80200191a0c50203d2.png',
    id: '5095',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/491e3e1d202f2362f1ef67d1c8d487eed9a0c31fea3a056cdc9fbfbdd6f3a9c2.png',
    id: '5096',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c9b21ef430db7d8deba44a6414ec6420e9ba71e774023ed3199fde8424c6611.png',
    id: '5097',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb204b5cc3b98d41aeded407f2ad8d2b50a18e253ba280f4d01fc43f0b0f6ae4.png',
    id: '5098',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bf02688a706f7bbf32b4d9dd5b20bc19072148226c8e17327426d65a5b25521.png',
    id: '5099',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d313ef70671c95743a3ceffd53d6eed74ff51ad114683623625945898660ea86.png',
    id: '5100',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4b73e8a5c3d38ca9df5fdf6eecd29124bc7ffcba9bfb2c061c93ad2a9307156.png',
    id: '5101',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1e1d95fc8490e10529af8d7722fb87064aa91817505a9f74eba73edcdeead7f.png',
    id: '5102',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee860cd6d0c517a5031458184e50212d53c25fc33f55d26463caf4ca88224a83.png',
    id: '5103',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31ce37a567b638fc0aa1664598d017cfe17f5a753eb367e6fcd54322d666a9fd.png',
    id: '5104',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d0d7f011eb0df829adb34adf7076ef1612258696aca48e52b81b80feec5aa98.png',
    id: '5105',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01c8a594862e097d2d452d10b6deae8184e5baafbfc1684572f6269d194e64fc.png',
    id: '5106',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9c350ebe1dbd7c4ec8a8a89883fda5ded658027f3560643cc1d5cacdf4bc2bc.png',
    id: '5107',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5630d3afab7c282578c444a12bca52cc25acd281698bc635073f330369c22fee.png',
    id: '5108',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/deb66d7a9ce6ad09d8307f2f4da2979223bd64be4a8b813e127e1aebadadfb9d.png',
    id: '5109',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eca562c4df7818483a88933beb76d1aef1a6149d4233e44845fab20dd8f47543.png',
    id: '5110',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/357bd5e0e549689cbaf1e22c52db0a80c45c924672446ac54c6b610f37bbf39e.png',
    id: '5111',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5aba7fb730d8a10ca7a892586b1844d571d73366a93ea4b779ff900fb42cc170.png',
    id: '5112',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ab8f01040d301d8df918d7bf62ecca213651796436ad85d4ca72f2224256e0f.png',
    id: '5113',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92d27a667bc2c2c10c71aed40b7bf62b2faa90b4317d9a7c585597f40ff7eeec.png',
    id: '5114',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79867bef84f42267c67464740c43b41e5d0df34f1a62dde92048976170a1dd9d.png',
    id: '5115',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/559139974e78d51e1b03a4e5a34aad44aec09fcd9e24fd76143acd9415fe2380.png',
    id: '5116',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/756edf0496f730accbf521bc6e8c3b279b97c266c99dcb4f7c164168ef95cb74.png',
    id: '5117',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ac01c4efd65755fa8367f23dc314e9b6586dcbc4643b31c2caa0b7c1f6c97f0.png',
    id: '5118',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8645d50d2d105d5e67b672ed792b5f33dd9277e9875bd2eceaacad7ae98457b0.png',
    id: '5119',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97801b88b4e9da95746cc47ec821f1e6b2331eda60ff3e7501a1fe0c09477d3d.png',
    id: '5120',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7f2979503ee6258924a741b4978c6f75b0632a6690f509bb8ff1ef81f804a6a.png',
    id: '5121',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1bfcb076c2b566b500c0c9a97bd230c075c2d8c991025bc59451a0cb10daad7d.png',
    id: '5122',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b053dd1bcfb01bf5c9664f9dc33a762cdee89330d978235eabb96b3f65abaa0.png',
    id: '5123',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e30f81dbb2b24bedb1d8449c30f3145b7452aec86a10a3d4c6febc247bcbb08.png',
    id: '5124',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac08ab2711a8031c4e19794e5516db20302cc1925f2ab4becc3aeaf0468914af.png',
    id: '5125',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0c71b9ae8db5476521295de73030523555f7894680cecb5a6bc0524a4a14b0a.png',
    id: '5126',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5a9b3f48df140dfe4608252574599c6d0fb4f32fad84957d5cbd2f075ba70d4.png',
    id: '5127',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5372acbff5bca88f25a0f336ee189c55e5c5ac28da313b96c7f2bac30a91df38.png',
    id: '5128',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3439491b4bcfc33fcf1fb3a2c2b509cae22fbf75fabb28e17a42a2e7d8facba4.png',
    id: '5129',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15e480539be8d414cb0be6917f677638f781b67894aaa1ad3863a5f5c986a869.png',
    id: '5130',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98090ed984c0c5e52f3a282a6439efcd0b8468f7a1616f00035671966884f31b.png',
    id: '5131',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25869a2b84afabd0fc88d47d0346724e43f7c884e87c928e98bb8f556da53d2a.png',
    id: '5132',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5a7ac0bc3bf08cfea6979a11df308e13effa7e1f78209fd60a131afc561c4d7.png',
    id: '5133',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca67792901c54545f1e752c8ffa1361fc6c96dcc49970d851513145b3546210a.png',
    id: '5134',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a13ee9a4338173675fd297b9c0b8f45d4cf8b9775350534e2a8070f75b808853.png',
    id: '5135',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de24fc7a749147492a32b5dd09a5ec3933382ba026b179dff49d26a39c035ce9.png',
    id: '5136',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b770ab4e845f6b88dd3f2e697f4dcfd1995e247df2dd1e341c98ba3de84002a9.png',
    id: '5137',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9c0559c901e78c2cfa959eb117961598119d89237d724b980f5322eafeb347d.png',
    id: '5138',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20824f0bf113b9bfd883cdb8db4d7c0f9924facce9bc7adaa30204a2742156fc.png',
    id: '5139',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8628f390e1ede5ba831d7a2fd604f475f26b23157e5af44793145f3c7e9497c.png',
    id: '5140',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3250018c8338587308ff686b4d0395b7251f739be7d4f782f279d20ab96205c8.png',
    id: '5141',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1cfe35b6cecd48ce76d055a35354632d5785b8a643028139e68562e42f051b6e.png',
    id: '5142',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6757da7963e16692098816cf43496b9aaa8a61430a8a1f91250f0360d5c020f5.png',
    id: '5143',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a1cfd4d274b020c411a6fb1874e1709a0a2709eacbc63f2ae95974a60493f19.png',
    id: '5144',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9869009eb96d95dbf2a224519f4a40f263ed4be2f7a11cae30baff4920d21359.png',
    id: '5145',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/561b326048df7aa2c545ca9334254e57271246970e72075993a80fadf64ace03.png',
    id: '5146',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52aff1d879f8c75dde5114f2a9b8f8e73ef0c4997d5bebc565fab3f8e571bf4d.png',
    id: '5147',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/777035fd1809d50dff2ee3e6841b864d30a68f8eebf81e718de9416da47a7856.png',
    id: '5148',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bab97a7c0bb96bcc43d9e101b148dbfc99a468162873a7e20040379cf9fedaef.png',
    id: '5149',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/364ce9c0c6e0513ba231cc865aa36213438190730eefcc63ba55ae1190707223.png',
    id: '5150',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbc19bab81ed4c34375018b3a948909f05ccd66cba3d2ce6d9b6989549dabb57.png',
    id: '5151',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b61e2d9aa4625dc1be7668572815bff578bfe08664301e217b741e641fe429bc.png',
    id: '5152',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb339cc736b2c704b648b8fa21ae95152a5bd2dbec43514af23b74ca0abda11f.png',
    id: '5153',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97fb0375af44fb70db87d31d518f5f1a2bd193bffcc56a262ee325a005cbc124.png',
    id: '5154',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d77d21cea458d3f4eaf542a74857aeebc21d2441ec88a6e93321fe0f72bbfa56.png',
    id: '5155',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77e7222b7a554c653190cd121187448ae0ee58a95727a8757b271b701bbd5eb2.png',
    id: '5156',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3392aa2bcf5f2daef81b906373012e5ad241504d63a05ccf2ef451d2033faa10.png',
    id: '5157',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0641b993d110f26fb3980cbc776df95564fb4deb3ad75653dca685bd9a3105e.png',
    id: '5158',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/689ed7a8c2777d9b6eed21087e1b7ec0d181c2f65b203badb310e76bc2ed8ad9.png',
    id: '5159',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d9664e7b38259d12b6793c9503f3299d7b74df26140692d73eb85213a61b855.png',
    id: '5160',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/278424554e44201c7edd9d2d5e425b7bd6250ee24e3b3bd8e9106004f5a4b681.png',
    id: '5161',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d4fdedd44364b054642944ef932a9382010bf23815a5965babe963893881baf.png',
    id: '5162',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68686095767e0392ce2ddc607c2b30a676da5010f612247e2ba233a972f53ce8.png',
    id: '5163',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6e90782697a167781844a8067f8e990aa4f64813a8fdb951ea644cd67e1b585.png',
    id: '5164',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f73156a261ca6a7984368eebd6aef9454ab84deb2601c6b6177cdb7755516b01.png',
    id: '5165',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db1142d5645aedaeedc0b14076cd1b6fe037a9502db89dfd03de6b71501ab50c.png',
    id: '5166',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/960d7e198d4de6c5b9701d68231a2c2ae28f5dc22634612af88b20a04e73606c.png',
    id: '5167',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d282f43c2b45aa2fe46b130fe41d9eaeb6de7f60f34801ea1195ab0f9ecfa127.png',
    id: '5168',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9884f99560886c19dff5e25674af18e5cfd8a27584fccfc22cf6bcc714f30162.png',
    id: '5169',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0205ebd123c522ce12fa8f6d4b3d81339467c519d70097517a398f4cb939526.png',
    id: '5170',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a61c583fbf059a937093fca4976d4a171fe74fd17ef1cddad53ac3cccb553d37.png',
    id: '5171',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7de8dc93282721c167aa6a65aed8bea810128e1fd95ba789c4961793a45cedc6.png',
    id: '5172',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11773092c95ea764465c7645a769d2a135d960b0db6631d9205e22b04753cef6.png',
    id: '5173',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd7895f075adf43af3375e35ed6a2ff416767cd22de70dfb33d5aac83a0c4a21.png',
    id: '5174',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d942dd05446339df57aba7ae0408ed507307d9718ca2d83b30f1025ebf02698e.png',
    id: '5175',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f1fa9e49d73904df5bffe17135bcfdc399bf48134502ed932a4a49066bf4200.png',
    id: '5176',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58de1b1b5758db022288c5a752d59ee7f06821e8a3f0cbb6f98e20ffc30a77d6.png',
    id: '5177',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ddb90a28727196233a32e9f55d6ad3f9ca714b2ba025e295cde56ab07b69285.png',
    id: '5178',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ddc525e3deadf2bc57850ee89213bc86886585cf52d2ee2fe712d4bab79eb2d.png',
    id: '5179',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43086ca10cb63e3f58a10fe23ddf90db29a7af71cd4cd50b097f639e984114c9.png',
    id: '5180',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3bb9a67aec4a5ff902864b1799ee6be4330f3dbec25a9a69e04173ea80da9cec.png',
    id: '5181',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a77ed2b3592b4333d8b1b4d4fb7467540813761627b230239b999563d982cfa7.png',
    id: '5182',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03f58df758b5f05dbb04cb0fd6c9c2985854d015162370a4384ea95f258ec1b5.png',
    id: '5183',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afea08fb7e39189d933a4c33fc708f43953697f002a6263e293a448ab088eb4b.png',
    id: '5184',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/138c8d248b74168eeb3e1e5dfa45b2d5fd014035a9a5539f3e8a112299fb1492.png',
    id: '5185',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0102ff132939097aed0aa4217872e1c04ae48843499e8b6eddc4acafe638032.png',
    id: '5186',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b40d2e620ee99b9823b488a9b9532235f6755cf0e75010e0f6fd5fa11dcb4da7.png',
    id: '5187',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5775e18cd354bd87343fe038dcd43a53c85f1c0f22350936551ec6df804fc828.png',
    id: '5188',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fb545ff35daabc7d813ddf570d54ce1185138e89e7ea8671024c5fe4f02a327.png',
    id: '5189',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23c9de957cc26aa77a6733abbc32175260c0ff6dd4026af9972168c6f1dd9258.png',
    id: '5190',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45d02b97abfd26ef0db19c22b71e30a4eff398f1597c4791758c0c21d1ca5fd6.png',
    id: '5191',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7894e3097ea813cbf7db7f65ccf5da13262e4bfbca5a8edd16ea76cb0efabef6.png',
    id: '5192',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/544a4d7a8ebd4e2fe474d0f69e983cf2dc26657c49abd1504a1cf490e7173739.png',
    id: '5193',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/564a2b0eb19a412277845445f4c57f36169a1a61472aa0fde62e4aba2f23785b.png',
    id: '5194',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8aa9d6315656d9fe9412feccbf545064e168c06f73947b767367695ea133ef9.png',
    id: '5195',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa73b20be2d918c3d8d4dfec9c1a39432a5a967615b20da66bf04b0437800f03.png',
    id: '5196',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/708e00d088852e41237312bfab89d9aa62f965016fc7933783e31347828b930e.png',
    id: '5197',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69a5bb1386d4f3521b8717d90e143fc1b47c22393ee698c415edc52e6f540860.png',
    id: '5198',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e43a04bcf1231eb386dd65fc048b4c5878a63df140f03fc40e36bdbe214c2e2.png',
    id: '5199',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6508b3279488dbf9ee88efa881110969ac66a8ff4c3f1cc14390ada2d5b5603f.png',
    id: '5200',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e737a6bdbcfa64f35add66dddf687f09e9b55a42795933ff51042f271d65fef.png',
    id: '5201',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42c2684ed69822f6b76d9c6a0ea04d6136e8402e42a45a573bd16609639f420f.png',
    id: '5202',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de53faed7305b190790aff47f17330a0c4cc3d71e1e02c01497ad25cbe3b7e24.png',
    id: '5203',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a3cb86bd847844c84505837bd2eab6c923fc2e4db9b3338178cca8c7ace8146.png',
    id: '5204',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7bac78d57cde7020e97856fdeea8a2a707f2e46d42d0fdcf620900781a2b7f5.png',
    id: '5205',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dc0c2589e41b22c8d2d77cb926daf03cde90087f41e1b226d0f9b4828e06d11.png',
    id: '5206',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e573cfbf97d1b3dc0803d7d19169d204b6365f211e80ca80391f66500716128.png',
    id: '5207',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f91d6af94606e755a5a1b02a2d35c16c0315008b204b46c88e16611bf965d8f4.png',
    id: '5208',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skully',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25e9f0ab7589b56fd31c5516ca61fdf8a88a9e43d7e711cf340366476e282b26.png',
    id: '5209',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc1a5c8b1bcfe37286559ae5c901608663e459f812d4c8b90972294a02e8776d.png',
    id: '5210',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/014eda41ef4719c5954bb3814fe1e2de87c1a9d0c3052fda01fbeb7fe01d1c49.png',
    id: '5211',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c11c1636a784c99650d557f0f1d162c38648838a4df8fb9cad0e450b49af636.png',
    id: '5212',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c9670889e54503a9345698bece457ec4c990b1ded97a595dfc8d2a8b4620d74.png',
    id: '5213',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/556c430b3af014bdff066c6148e5155d49b7235383d22bf352e7805208dcd223.png',
    id: '5214',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2025420205a027b937a4b1fecd383e6e08bd70a9415354063bb943855a3293cd.png',
    id: '5215',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acd00e28a8fac07ecd7a77764a56d33cca07e33b2fdddeb9b34de478c05c8425.png',
    id: '5216',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9105efb514dcf6367e425ae0dede94c647d8310f92e8bac9af0e68cea38cbe85.png',
    id: '5217',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5e48539dcc7eded73b5c4f490bf0c93887727f656c38eb3e5bb07ea186f663f.png',
    id: '5218',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61797a6c499d6a3e1f8e72a3e878d6b96f77a5990f80526a0bca0f61c39f2b24.png',
    id: '5219',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/651751837808d4bcf3b16e1d08a170261f19dc6e74aae09760d2e1eb1189ae9c.png',
    id: '5220',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb76eab5a3c9d788d9285156163956deb4e4a5d7e8f7c0d6d78730969eb8b3c1.png',
    id: '5221',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b3ec6a3b67b0922c94ea898173afaf3266cb08c4f3ba9e853238ebe05609e503.png',
    id: '5222',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee998c06e70984c3a433fc9852db1e92bf3eb31579fd4ff39b3fa60c1e24d9f2.png',
    id: '5223',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5821864a167690e00370e1f5c01069db90dfe078511b41fda0c015da38723f54.png',
    id: '5224',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52dcb73b45e490f8b8bf6a18ecf1a3a1aed054c3178e45bef45bef349e8ee431.png',
    id: '5225',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89bf6f1643e8ce878e699b3ee3a0400fd95c15815192ee523773d86f49b6fc15.png',
    id: '5226',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01b04256623b7bd297b258f9a31e72240f803e051b0e3fa50f9a9ee624ea6fec.png',
    id: '5227',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c476d642b3fbdd65e255fc28a33f5195135bd63324400355124794421e2977c.png',
    id: '5228',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cce5c72575f0baf8e4d30aaed3a06984b1c7517bdbf43dc85add6843910db9e3.png',
    id: '5229',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/523202d3725265f01063630ab7bfcc9905104a0c8b730134785886c5e15f3127.png',
    id: '5230',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2455fab736bd62ce5596565194befe35c4bc1755f69dee1544e2f43b03126f2.png',
    id: '5231',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc95965463dd20a3ef6341dba3c148f8dcc9bfabf8e40f0a1b656951ea6d4287.png',
    id: '5232',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f071b86d16f6bbedbea0919cd64d6a8e72fd9d16fd664bdf6cdda3c763e928f6.png',
    id: '5233',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea719ac4e023205eab93a19276402a0acf208c92a51b2f8f2091ebd32b09e040.png',
    id: '5234',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8e605f0c0b6ba356c60ea1085d08ce4400fedee47e337c5e7170b802db216b4.png',
    id: '5235',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/429aea34a3aefd7bb930575624c59ede5121c627eb852cb93eb2e88a660528be.png',
    id: '5236',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70fb2d074b899eb1bcf4910173b5bd3125ba1d108710c467fbf980e2d86ece2b.png',
    id: '5237',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/558957af6a2af069ea2a836ae1c22dd2e6649e038c522d8cef070ac8e899a7cb.png',
    id: '5238',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2175a0d29e4217b65837c136adb8d68cb79e16270508148b9cf45bd6c2cd8d7.png',
    id: '5239',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f713a856c999e57f4204b7b993eb6b1bfdd55ab11f3577cddd56885f849724e.png',
    id: '5240',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7e9237f313dc57a6089ae526ecbc241da7b304cdd03282e1e8b80c27f9f3f58.png',
    id: '5241',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/271eec63a649761f32182724944597f7f9ebd42cabf67bd90be3fd72a279c5b2.png',
    id: '5242',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8f101435607ccd6c0db5653915bd80e15ae7d7c4afb40b8b028b4d800dda86a.png',
    id: '5243',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81d81931984d1d275531901eaec140cccb7341c9e8900f91912e6ed46504991d.png',
    id: '5244',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6ffbe71c1c2f056f683f59ee6b79738b5242b199209ed862935c55ddd6c1670.png',
    id: '5245',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94aa690275fe9e2b9ea13fe78b213415cbe876ec64892b444865b8d9fb86f8da.png',
    id: '5246',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0e5d7b9733a6fccac80bcee72a1ab75d06e9cd859194a6991b86e6f8062a8bc.png',
    id: '5247',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13a4f61574320c95abf8234751465f38fb2ce4f6ebbd31f44913698519a9829e.png',
    id: '5248',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f76fe1d488cc7465101b02b4d5251e62cc9662fcdec4444f5b92f9d579f079f.png',
    id: '5249',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3ef46a42691c5e4a9be3fefd3704bc598fbd9be051fb6e8173a6dbe4b5ad1e9.png',
    id: '5250',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d153e34acd5b44b3b8f2550b38f6781ee2b22ca8e25f5ef8a0037e0752c407c.png',
    id: '5251',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3dfc4b11d35375b451d267af9c9070da495545e391a7287dd2c0b5077663747a.png',
    id: '5252',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/911bd2b1dce949954630e58e51b76d1895c148a51b6f26049956ee6976b5e043.png',
    id: '5253',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a3e67c887095e026793c12ebb8da6f9353db92c10cecf8eff6c2bd0479eef65.png',
    id: '5254',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2181c41904aa8e42683e0f7797866853f8029efcd2ddad8bf54a829754388ecf.png',
    id: '5255',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3cdf5a61d37677660923a0670f98d1e21167f5118983d0264bcbb86a4668254.png',
    id: '5256',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75d2b61dd0984c51c683d59a785ba1ddfb012d5c44c4dab1620049b4a7e7a3ef.png',
    id: '5257',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7bff8f1d5baaf9911514a2679d4047c754fc6043f81797e37ee56ca94604460.png',
    id: '5258',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ece511682c5908e7e9e7ccc13357be24b5380a0b6a4943ab612096553f7039f5.png',
    id: '5259',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7617d9292ec843292d6b72404d95fba18d09e61159a46ab986d5c4c47a480d8.png',
    id: '5260',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd113ff5c3a7d20a61187e1e5b6657d90b328e1a4cf7ea8619cff4ea01ed52e8.png',
    id: '5261',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d6209b89b9c4fbbfde203076b31e330a33ad53c5b4e4aa978dfa7854d888731.png',
    id: '5262',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f4c94560c673f085cee87170a96bd44fb38626d665b15137778d2ca73a812bf.png',
    id: '5263',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/589f16fa8d1c2525fe55a257a4fbcecd85cb1320bacfba2ab985909249e39041.png',
    id: '5264',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a84a8032b8693c4c8a478022c80c3e49d009bf942dcf6f82a22dec45f0d673d2.png',
    id: '5265',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b301553816194d19d2acb65caef5ea7785f32737b817b888cab2987f132db09.png',
    id: '5266',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82b1402f6234cb97a621dcc159e4083434c2c80fde80f8bb6bdb4048110f6f37.png',
    id: '5267',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88791e58bba6da3c2c326b533185ada6a81b87429a66dd63646c9258a1b66c72.png',
    id: '5268',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f57fff8e0319a2511374cdc8ad355604cf8470b488a0a3fcb36e2507f55af3fa.png',
    id: '5269',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e349fe18ba2e194696303f617e6ba94abc157bac07908b80ce7d9c9e48ad87f.png',
    id: '5270',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61e0ac28dd877f94e9a5b97e2c3e4329442efc8d7d4028e58348ef5f794ac853.png',
    id: '5271',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c059a84d1c0bb67708cc8400c02f54c320a6412c6aba9f1a0a71dea888e2582.png',
    id: '5272',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/242abfcafdf1e0903c788ecc1545aae3d27511951b2ff0f70fa8f0e0e2656d2f.png',
    id: '5273',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae11ebf48a9fc259e2b048564bd56aa1b6e6dcc79542a0006c20cd50244ffb39.png',
    id: '5274',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84ede07877d3b9d586e1766ee06273bc7e0af9483a1e8750889187aa88ab9c41.png',
    id: '5275',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84cc55ef0b8e0981f95bbeec58feb8fbb050173dc0b1c7e414bce96e82e31ef1.png',
    id: '5276',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c13bfefc41866589de0a9675bd2126d21ce35bcf8d211f6c2eb4aa36855f0ea.png',
    id: '5277',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7997ca91fb4032395835945086146429cb87b3c2d0cb13ae8da0ab23d062719c.png',
    id: '5278',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4a367435f8a96eb9368b92c8a10f3ee404dae59cb097a92758b595ea2a0776e.png',
    id: '5279',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b7662082c742f9a62ecbab9e32fce6ee4411a01d9b2058cca41df5819abdcd5.png',
    id: '5280',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f1b514a6c1fed81bcdda6c3c67855d846cc273e7a4b308d0cb312907786854a.png',
    id: '5281',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09dbf775b94d53b50e722b90f066edb30195ab704d7761a931be9dda9b2551bc.png',
    id: '5282',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/462e1d02e326002e5a8f38affc1a5d2574b504cff88ea670e36c80384b7d4dd1.png',
    id: '5283',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e2aeafebf59a816fb75e7166d1b338bcae4b03d40a4dc37bc77c276c09900c7.png',
    id: '5284',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93a34b762e3a0dccafba7b8ffcfc660b060e4394eda455267ae2341d37667787.png',
    id: '5285',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10bc5a4d5d832583ed90bf31f24419aa3e92710336d182f0d7db23217809ae63.png',
    id: '5286',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d64b4a6e20dce8ed672669c1f9942974f88ecc94d9cebf98bb581c3138a84ea.png',
    id: '5287',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c2f264ef64590ddc1e1688ece09d40daf0c89321c51d9b46fc4132bafd42ad6.png',
    id: '5288',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4399b0aa97bb402fffab19b5552d3ebfcc0a8cdd640532ef54a8641760aa3bb5.png',
    id: '5289',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/650d9b412817286d2a081dbf35bc54b68374f2b7831333dd28e986089945f0b9.png',
    id: '5290',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e66a1fdc43d133311612c9b75a16af992aff9f745bc43600a5c7be40d58b3f6.png',
    id: '5291',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a267898d785a6b458da2ae1d429b7ea78d0256044bf90042abae46d73220a72d.png',
    id: '5292',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6eac126bef36c6c740b662de3d5705d64179742c6f4df55db22f8f975da07ca3.png',
    id: '5293',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2e5ebf8393032953cc1b9b6a16b617608af68465bb8eb6ffaf05c4ccf700c84.png',
    id: '5294',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d59dc39cd515d8c1b39bd0f65cda60815e3e6c7c03efd5031c81f370e9fe0465.png',
    id: '5295',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/283d4eec44225998f2b78f88d75306b0114f3c1ee5a7a6984620c8ebb39baa5a.png',
    id: '5296',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23511b31535f8b29ddc6c20ff9d95f1ff579cd3a28e8a75dc66836b410c4e8b6.png',
    id: '5297',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a299150688f39a25e6fc19c3da50e05941604960e99984129577595ae60cd162.png',
    id: '5298',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71b6cfa5e06c4c4f4addf52c58107ce21dde5e9034a8fb2088a702aebf9a413b.png',
    id: '5299',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27c462f8dce7268af0ad26d8ca5e9548b140d6f9f8a122fe0fbfb0cfd9612a29.png',
    id: '5300',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6acbe2ae4ff235c9ad0a7d5a8b57bb9f2bb0f1a5c5fccadd87d1a5a155e29635.png',
    id: '5301',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c06cd42e2f02eaad08fecc8cd0c0c43b102a481ea4b551ec958a0f52bf328d4f.png',
    id: '5302',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d70e7f35b701a96290384600836a8dc4f5614fdf2d8b1d4ce723a57aaf6ed01e.png',
    id: '5303',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d8467aa793275affaf4701d8866dbf058b50f46b893ca6655075e40d22832ff.png',
    id: '5304',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f85a4994f2404a9a5996ce8208258054212a3a3240d9ed3d2bef549080f5a6a.png',
    id: '5305',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/277d725a42fdf9f6b233ecad3656aa93010e9808073914450676888a5abfb673.png',
    id: '5306',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bf9175e2cb12b3e3c1e2fa1fb34563004214065108ee9ef408d02d5fdcfebf4.png',
    id: '5307',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e70920e19f41ea7f0b4769219c8867fd9c073c8dc8a805a69e0ad291cf40815.png',
    id: '5308',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a49c7bd9d48931467abece746fe64d40f52cdf8850209beb8021ad92c496915.png',
    id: '5309',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c8babee63b329df31d621d44248d33cea390db858b5ae5737265d42d160296e.png',
    id: '5310',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c81b34913be543537c0ebf0cc699735ceba7774cd6f7e4dc716b94f6a0943ca5.png',
    id: '5311',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b479b6c7382431e690b730db342cdb917ba10c60ba172228cacfdbc7b5494d3.png',
    id: '5312',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6aec84a5e66739eddb381977280f9c218fdfc7ae14f89c925127254921a16ec9.png',
    id: '5313',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4f3d40bb4dca67bf39f3813f7d48bb5ad65e8e838452aa0b0776372c25a431c.png',
    id: '5314',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a50deaf676ac03571eef4ea39e9047b9b634242b0905b77e36534d66211966f7.png',
    id: '5315',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0190a9d887d8e1466874d61af6b870b3a44e70d467dd3cc40b7a7b75900675df.png',
    id: '5316',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5d726dd282f424a93067f680333931fd906c27fa97c42fc24ff6ed07d752dbc.png',
    id: '5317',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5217f9ff1200039b197358192ee6c8a5224e1817137fd9a15bf457e9bab0f0ad.png',
    id: '5318',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ac41719f0b3705b9a98beb0400a16fe733f6c7ee650ebf24122a32aeb0125e6.png',
    id: '5319',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d48592cc94dedd0862d77c5f2beeac58eb333f3b8ae499b0fc12a68ee3d2a9b1.png',
    id: '5320',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fada460466f5f4f537d4941e08b54c42abd032a6117f0a5824fa6d822ec65ef9.png',
    id: '5321',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a90850f378d7d6b6ca2df76e510a29efc0cb438cf6670973b858e6d62f2c8b8.png',
    id: '5322',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/353e8af7eb0b2ab43424557dc1bac2808d3d116e3bf7d912fbb0c2499251694a.png',
    id: '5323',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa2bb6c2726ad0f411ed7a26c76a5e668745642a8753a11f86ccb20e2d1935f0.png',
    id: '5324',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cfa09f4ec9ef7450ef0502ec33fb3ca34ef6b08f3d504d579c96f71590502e2.png',
    id: '5325',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fac8d2f0aebfbd69f5cef30010951a4ecaf39e985af9213f72f200ce28da278a.png',
    id: '5326',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c16345b3e3fa9aa6b8ffda4c7ea3dd23a3f67377f3664336ca382734ad2559de.png',
    id: '5327',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/436994a378cadf51ea092703bba883e89b6a1473fbe01c04e00350eaefc5f818.png',
    id: '5328',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5ec8a6f2a502a98306bd25b1ed5bf704f4badf6698455f398e20edd5872f6a1.png',
    id: '5329',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d278cf6bcce3ca2f80cdbac0fe73f9d25c74cb480faaf663b2d80d82be565d09.png',
    id: '5330',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/395a40a7c5437dd67a3ce6a2204c47de3cdda05455b3160e9c7a2ef8a0f1814e.png',
    id: '5331',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/372b0275a4d8e4549b33a4a67d1f28ede98eec5bc3704f4272ef66b125d5a469.png',
    id: '5332',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de5de329edb4c0f8784404cd5cc094983b118c43768005656287a49ddd8a2faf.png',
    id: '5333',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/321c26e216c0589cfc7524aa296b7687f342eb2eae69b0fec3dbebc7898c89a6.png',
    id: '5334',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bae6189488d044ec1cc841f25993ad65ed341ca82bea14a452591c521f02bbe1.png',
    id: '5335',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4aaf1d7dee2322444703701ca80bff82299e0635594b21f469241323465f8abf.png',
    id: '5336',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed3f1aff8a54c5071bfd9744ee018ae5a57a60f8c5731b78ec3766c53da3f273.png',
    id: '5337',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbed8732a15c816c970931a7d31e2183e672eb225fb3634f21255e6c546b253f.png',
    id: '5338',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7c209165acaf4d2c800b4030816f1fc161a39af7b9dcb2e02576eadfce06dc7.png',
    id: '5339',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dbf5c0a22efcb17f1f73ec9e42b0aa044f24e774cce6f4d60e40739e4a6751a.png',
    id: '5340',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b888e79603f0f647a7d0ec400cd5884c9b6fe8ec97ef5beace97fb0af60cdf5b.png',
    id: '5341',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Shards of Iridia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a880422671dbee78cda44cb31f7eac79d4e0d20371a3ccf15337930650e08e0.png',
    id: '5342',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f95aac3d5dc912f3160c14bef17df94e352ab90786a357e31cb8ba958c3949ba.png',
    id: '5343',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c308e663f46dae95769cdea25abc76ddebe566ae8d42afebbbe586daa8388c7b.png',
    id: '5344',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c34b31eef89cf3d5380693ea7a2f870209f182dc28a24c8871819aa5d44ab826.png',
    id: '5345',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7473911aea9bce3ec83502c88a6d1d968c47c7e5bb33f784db84df127496c604.png',
    id: '5346',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95ac223155eeb3d9707745715147841d85d30308d97102502dabe7dc5a3e9fd8.png',
    id: '5347',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac2b9186a1d86157c03e9c571c354224bf7478ab660183e9931545dc3a51536a.png',
    id: '5348',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c83c6411ca8077fcf1225768c93e6ca45e17539b194b28d35ba7f74753206cb2.png',
    id: '5349',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/943bf6ebd73ea46043cd31f2edee5158351eca7288dea8da2c388b451353614c.png',
    id: '5350',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Batty Spiked',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9311d1176ed15f38bda74597842c29dd4b89db77abcbd74f92eccf963ccceae2.png',
    id: '5351',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c246859848974d97b0d3c5f5a773a3e39670bd43af45e4e32c915b176db8187.png',
    id: '5352',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/918ab344112c8e47014b3be5e1fc18c76b625a3ab3cc4bb3bd1c8ab7d5c4fa19.png',
    id: '5353',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d07c63bfbca69430650ab949771a8a2c979d96010985f663cb963ae07c353bd5.png',
    id: '5354',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e05e884b664a37037f069361cf120c1493002ce10184b1cfc4e6d5e5861a3ce.png',
    id: '5355',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30e8836e8567d6763b8c4dbe229f64d6795349c43467489293acf247dcadc3c6.png',
    id: '5356',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Weapon',
        value: 'Gnaw Saw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d5718650f0e079a84e579adb37f813c2ce7df1b066dd77cfe16ce751faf263c.png',
    id: '5357',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/840c49dd1f2d1fd36fa483bd58d6691ba4212555a31476159b4539a167b12828.png',
    id: '5358',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/397f349345c31ede1b97d648cc5bfd8df8473b1a2ac150ed714344c122aea098.png',
    id: '5359',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb9e8e3f92de185d60c649352098feb85c0d28c5d1d13b485854d130687fe658.png',
    id: '5360',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/080b7ce2652ff658355f64ea8b8001778dafc015b7d2e03f23094d563385ef2b.png',
    id: '5361',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60cb19d4bd9cd8f00073462da255ac4c072d6a2cd7a93ddd87658ddc2faf7659.png',
    id: '5362',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2633dc14a1f42e279a2bac7719004c21c01a32fc804130773ebff5f760536191.png',
    id: '5363',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8adf367ef64d414bcffb4a64c54d68b356f00636882a26c2e4749321c7a01776.png',
    id: '5364',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d86284ff8c2a3a0ac78aef521b7053c6be70b4abcbb93bd9540af9f4f941a636.png',
    id: '5365',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67de0caacccdcf83a470b03236e0e8b5cd201a2ee62b7f8e1ca968dbacf29df6.png',
    id: '5366',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/692af4a4d9b49ee4fd1d8c95c0aa6457f1afd609f15d661b0df84db4072d6df9.png',
    id: '5367',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2554559c65bfc3e450c9c1f19cc9edc8ef6fcc97a5c9ea831fff6a918decffb6.png',
    id: '5368',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5e0b17a7b1d613d61fdb151d4680e3ee3d82e3f053893f00dcbe1fbe04b2695.png',
    id: '5369',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8473dfc76c63b9fbbccf17ceaccd724a788a816fe89c7724a7f0bb17e7ccde49.png',
    id: '5370',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/830fa86a5224f498ce00c8251e751ee6279195156866a1768dc82ac4ebedfaf0.png',
    id: '5371',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16b961ad26a847dd50b396779c12dfffae05e73b08dd50caae51ca0db165f489.png',
    id: '5372',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8de2656882973a9957e91579fd2b4a8cb7cf2893008f51e81f1cc3a0e1ae474e.png',
    id: '5373',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/515f9a1f99de7b2babc79c4f161c3b79b2e490bc8f90ba816e32f9d76f39a29d.png',
    id: '5374',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f68a817151249cd711e21e46cbd184aaac006f8b1b76fbca732c7155d904b1fb.png',
    id: '5375',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/947e0ef5bbf18e385aa4bd4bc4ac9e073551fb25f12c0efa7b586d25d6f7dde3.png',
    id: '5376',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/074cefdcc4f666a63a625f446a43055a781050fcda1ed4cf46d103f424acd311.png',
    id: '5377',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6421b3f1befa1d85d5f9b186a6b7c088ec6b024a933e4e43343ffa3737ec0f1.png',
    id: '5378',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c95c7b7aeab1f8564448f0376155c3bba37ea586c95aa93f6e1944b9d64d82e4.png',
    id: '5379',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b7a7530b4ddecf9a2530150be4967a28bbdb6bd9f88179efd00b5aedb8c8753.png',
    id: '5380',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3255014ec1d6550017c0320383c43e268dccd1abecefb2ee850adc9684b3402.png',
    id: '5381',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d78920654942150d4bcdafc88ee332621e3fd3608835ff6ec52282be40c9dae1.png',
    id: '5382',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8a1b986a93bab94831a0cbaee8c40894518031a6e3448ceacf5990eae8fa781.png',
    id: '5383',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68e3122bce7fa73abfaabd097895a37a639037e19a0e641d5027fca179d7f114.png',
    id: '5384',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Blasphemy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed235d2a9e1fec15ccf10a10199a952152cba3c6838360e862de83ceab14b298.png',
    id: '5385',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98278f7dacabec454952f4a6a7165e7aafa1e00fd6531bf4ffb34b1c7022283b.png',
    id: '5386',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ea01b3e21fba1c964765105907968a7695ef4143b2a9e9b44a389af3f25c851.png',
    id: '5387',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/efa8cbb217d2423041b30db32703d0489cc71be22ee52b3744a357f6aecc7454.png',
    id: '5388',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c894d20787ea1a4b62bcfcd0d41d03f46d19e759e02b1cf52c26a0dce5e9b761.png',
    id: '5389',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/896dcb3c12815bbd0086dec84e77476aa625b170197b426c564ed0319b4652e6.png',
    id: '5390',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b308822b48899435313e006714623fe457961487a247143e65a94179f8972bc.png',
    id: '5391',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d1400910ef3e58226308ab66c15a91cfb3137d1023870ce253186118210a90a.png',
    id: '5392',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d7760209fed302dcb652361afb96771f677020c5379665a6d3a4344995d8b44.png',
    id: '5393',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5dec3a407ae6bc1cae816a2be1f2dc2988778196597d136b8c8b91164250fde2.png',
    id: '5394',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b2da2802e886598bf7e8c6958117a11b11c8e8df68ddd469e839a7cd6f11504.png',
    id: '5395',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9998e052e462d953980b0b4278eec7a4cefab45b0ae48c5f07ea9d89bba80348.png',
    id: '5396',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af7c6840e16c18fc241718b7ecba62c86a16e2ee263e5e0bd77366661c875c65.png',
    id: '5397',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9d9ee0494d8e71bc47c077875c8bf860362c35601c7c38c3e7cc638ab075845.png',
    id: '5398',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4cdc836e20d38d527bcd6d8a496646733c9dc2c901e15ce514e438aed89dedc7.png',
    id: '5399',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7db57159f32a4e1808020a4a6b6cf048d072d3a48e46b3613e7073808991f337.png',
    id: '5400',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4468d9f8200d73b63d876da222f946d87bafdd27abdeb42d9ce0d2f8bbf1b0c7.png',
    id: '5401',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8951351d60dc31dc8f5253dd603cb92e0d2714c0cdc42243e9b1b6f290818971.png',
    id: '5402',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33dc40304984ff2f113ec1ea1f5e5c0c832d8d6a833c60f118822647c2515d9c.png',
    id: '5403',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d0d66000ebfd0934a96e720ec014d33da599a9ccad6aa7216da41e065e37ebf.png',
    id: '5404',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/736b59a72adba428cf9fb9084428953dd667d0901fe5a429d8ed374e08394f94.png',
    id: '5405',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56d20c9ec0d49727f738a60dfd62123848346a4231bbca0dc37abf3e6f5b807d.png',
    id: '5406',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44bfd524ebc978cf0f96a11ecb777c20f091cc016cbe1ad07d2faa20e888018c.png',
    id: '5407',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/553cfa50a7eea40fa8e0691807ceaec24952a8cd78ee7b9764dd61b064fc8b80.png',
    id: '5408',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5374bc42b6a410ea0fff6258c08f8e4ce1c0ff8e2e38d83a8682da4089fc1180.png',
    id: '5409',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8d8952349bb061f8ee69367d0b966a6b553aaf968b4a1031c30b5cd93b5cca8.png',
    id: '5410',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2ba21b57f3463691565816e79ee703756b5d685b5f186d5279424a0627658c6.png',
    id: '5411',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cc9bc09f95975c728e8ffa665de3ab16d10bb39b3a3721d493b6c097770e5d8.png',
    id: '5412',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de796bce85c92921fd2580a5e63d07ceaaf9b6bd1684798c66bda9fe0cc5c700.png',
    id: '5413',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6af3a85727a5a4f9cd17406ad84f930e10dcaf7e530c15be82c55c0fb8d1817.png',
    id: '5414',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d910b1319bc6be206667e80dc85b4544d8f9023eb757a967bf73878d345131ed.png',
    id: '5415',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddb6b4771fa26eafeb56e4854b8f7f7e959463de1235bdc8a60170e139220fc8.png',
    id: '5416',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a85257e6bfe2a5e2ae833f3a744cf17a010c4c243474e24a842bdd13f5354b3f.png',
    id: '5417',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa0c1db40c6a8cfb855029b2fb7652c99c358d081f3499286794a0103083ff5e.png',
    id: '5418',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1f2a0f481cca07f01991ed40de31bfd1182495b60245958235bd23b4c87f339.png',
    id: '5419',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c72828e461a4fd8d3894de56aed667c210c0c7ecd5994e9e7dc89ef21a2fe29c.png',
    id: '5420',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Lights Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bab3f09cf3cc5394b7788a1c806eaae547284b0f24639e63870c961bb1fa973.png',
    id: '5421',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41d4120dd4bd22e1cbf52547b2a203700ccfefdcad3cfcf7b2897759edbc62ed.png',
    id: '5422',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33665898aa75f3873442a709687220d7049a69428fd6b27542136151185bbb16.png',
    id: '5423',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/660af88f879ac0119d192ba08c71f6494295b62328dfc5679e7e799c74fabcf4.png',
    id: '5424',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/990a2bceb736a7c6567cf442d53e7e54997784ee4fc6dfebe8dec3e62a1b0bd6.png',
    id: '5425',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2122c473c6e07b82543290715aec4a35cd9d43885c6ffe1b579f40b7100501fe.png',
    id: '5426',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c382f54da273f086a33951d5a606064a5acb14ed2a61ec7ebc4169760fd4e8e6.png',
    id: '5427',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e35442af064a83c4b4e50a7f9a7a3c8e27412b1daed8752f65a2ec48253ed84.png',
    id: '5428',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c32c42e261e1fa4039843399e66ed1634ec639debd663c05345d0c382518a01.png',
    id: '5429',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88c2b771982704307228f5983f15372447e6b9f13fcd170120d2335ea89bc0c7.png',
    id: '5430',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c82bd18a39d439c6e669f4ef46124e3ab9d4603f7598c33465e12ae8e3a1e5bd.png',
    id: '5431',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01f15c326c9b6fe2a2c9e7ed4e3660e02fed1a05985752f72eb326c85940b427.png',
    id: '5432',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af97bdf50e23d99620bab7f0f543dbc078bbf15f35ddedaa497b07336e5061fe.png',
    id: '5433',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/252fa764b49bf213033cdc9ed19a1655524a0588f149a8b45eb7dad70a7880ee.png',
    id: '5434',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ac63276d6940adddf51d39b9cef87ddb10fe4c712f2c7feefb07e898e3ab663.png',
    id: '5435',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff8d220b20b0361c1391db8c85dcc2dbf3a5235938ff224a58fc01fb3c4396f6.png',
    id: '5436',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf515a4f26d99ac02a8c24179817122a4ce37f5f71dfcb988628e8118dfafdbf.png',
    id: '5437',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec9f0f4b0ce5387718fb9eed68ad765b116d759a2eabc95c475d8e46507c044d.png',
    id: '5438',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1cc8150b62d567cf7af47dd4662d1fecd93076a1111ccd74407686395a9e9eb5.png',
    id: '5439',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba54a48090cd20fdcec2d72f0d1c41aabe89c28ff3515ec01518bda18aa6ed14.png',
    id: '5440',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e398392164b9457d8bb35876b8b2c70c185b3f7f57908a0733a6a7d599cbd1d9.png',
    id: '5441',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63e82ef4d268843eb38bec3385bef29f1c253715ccc50890c1d870a91aae62ca.png',
    id: '5442',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d49f7a286c52d1e75698cad536dde259a031a2892f1b940ee1345532c30f7a55.png',
    id: '5443',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12720091434d1ea9d6857b3e47dd7eba6706696f950c7ea8a682e35e44563459.png',
    id: '5444',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cad1882db914c7d9fee5c3e345342125c2d32978f292349ec69cc37fa445f6f1.png',
    id: '5445',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c940e7f86d87e25f9923799f7f08edbfc9975275cc62d224f74a28531a15c39d.png',
    id: '5446',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/affa5bc0d03aac9a5803b29468c87e582a64fef26e254c74c08ba2ed91d14289.png',
    id: '5447',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28cc505d5700ab32b327f0f4c7e23d245448606e9b434a9f893719fe44e39bd5.png',
    id: '5448',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/465a5c453805cd3a9da66eb690e1846faff28a66ed342897934019e2b0c4293d.png',
    id: '5449',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22f4b638b589cc7151832d74b24f78861d52c2b3a9ff804cebd387116e650258.png',
    id: '5450',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48e4df1b23987fc74857cc2ab0d74f06dc873e8d358a8c109049ded44062314c.png',
    id: '5451',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51e4b04db66ab735701fcb6805b6e907264a4b2150ec16b2da6b452b006fefbc.png',
    id: '5452',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/262c005a8c41141291e8613173de0ca1f0687fc60b1c37cb21e6cbab3076fdf7.png',
    id: '5453',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3ed948ee9fe36a6fb47425b3fdca6acaf2f19282930a59eea0e27853146110b.png',
    id: '5454',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64146b05f9601585b0b39267d3b67db05c2ef2edab834e6f55e72f0bca413977.png',
    id: '5455',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/845a4b3bff64943a52d0e0ff3f5dba8c73ce0bb2bbcc2d4fb274a2506e8aca36.png',
    id: '5456',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df612f2c7142d7dd17f8697e2061c58c6178c721cb0ca0f21acb8eba7836b378.png',
    id: '5457',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af8617bd07de6768411dc21a107ba28d7a2e5e038adc0a8f7e0a7c273ad8569f.png',
    id: '5458',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ravens Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58ec0fc4d618f33971b01ffa0255e6a6b766fbd2a37e26b5e131f174854ed147.png',
    id: '5459',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0cba58242d3232ce7ac88e5cd2f4c1f1f75b7523694fbba5f07914794ebd639.png',
    id: '5460',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4edaef1e683f264fbca55b77f2c6ef322aaed8c9f8eaa140be13a59059f1b701.png',
    id: '5461',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25c18e23ff9d8a9e4f0b8de80a1958378796154ca5be30a214ed561e762e1a3a.png',
    id: '5462',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9710c6745afcb99d3ad5c6ec52559b248618137ddde79313218109bf819a0de0.png',
    id: '5463',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bf79f2074dafd2dd9937a4d8ec842d9c7841cdfbaf24a83fc010ac00230ffa5.png',
    id: '5464',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25276eb529343a7bf11ae8078197ce861e15a6410b3a76797845f74993130455.png',
    id: '5465',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1be0b6685e4208a6170322e166433c8fbbde0ce66ababb5588fc1412bba39909.png',
    id: '5466',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b947be5daea59fa169a555040a3e1845f3a7ae8461bddfd2f085c62a83be5931.png',
    id: '5467',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Jaws of Evercrushing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3466e102b1771684c2f395e737dd282477cab92d8de5df9f54cd354fcf5421b8.png',
    id: '5468',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a2e23741ee068f9163fe7720ebd28ce5dbe32e72e9c6466cb2ad6a48210518f.png',
    id: '5469',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e716bb1ce210013b85b914c6260ce6aff691d05b76c560540b5f2252d024299a.png',
    id: '5470',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7a3197b80e4ee6d8bf3b2f7f6b34fc04496523bc227d298ac4ff1e857ef5f3e.png',
    id: '5471',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa800ab80ea407d88427838256793e4dc48a0f2fc1b7d5d4353597ea17bee717.png',
    id: '5472',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8a32aa9462158b4bd0c26461f33877dd81810c971b13c8895996c494a1f8fb8.png',
    id: '5473',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a11e57b5f497808a81dd626ab1c0774e04b0e7b9beadfe22e1df6ce0a6261bdf.png',
    id: '5474',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f36bba8a3ff9ded7acb1fccc428b180bdcaf655d17e3d2cc08eb9da765253422.png',
    id: '5475',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1e4cd75cc8ebdb311cbdace1c3c66a8223e37444e98c2160c0ab3ea762ee4dc.png',
    id: '5476',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wise Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdc5efc70d85bc7b27b63d294d9da926bcbc4bb083640e9a3b19d0128b832448.png',
    id: '5477',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f139fb953a9e0064cedb347962125fb3f74417b39f83557fe984acbcd41141b.png',
    id: '5478',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2869ccb64090a46ce42a339cc4f6a8354e09bfba10d947ee5f63592423ffdb72.png',
    id: '5479',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d5adfb9eb1140519df3130df5852114992801342a96e5ea9bcca5581db4a730.png',
    id: '5480',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05c9ee74da6f92ee0772d066e14130f97ea363172b983c045d766c3d9c090c0a.png',
    id: '5481',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7dedc7e19f8a2194a3f8b2e8eff18c9f9f5b8fe8ad0ed98737aacf975f030535.png',
    id: '5482',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e272b1d424911ea22ee4871d824dd79e95fff1853471d7150dad3d0628b3c612.png',
    id: '5483',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebc571bc453eda9c527582cf2ce51f004a65bcdbca48c079337a1b487a19bd81.png',
    id: '5484',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b58c5093cb240869fd9ccb9a7ac728c939012abc94706828cb822af4894ec45.png',
    id: '5485',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5ff47c26af632952d257fa57733d8032da61c813688b34b1fb71322fac13649.png',
    id: '5486',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ee4155ac55aef1e6b02295cbdaf7697fdbe61382e97be290250e238d794367a.png',
    id: '5487',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Bubble Blower',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20cbd477ca1a7c904155c79f4f945fa0f967329b806425279c73f734ae70b973.png',
    id: '5488',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/337b67059b78b7931d912a18ed05052e5023e1e60fda6c16c78b9743b5757684.png',
    id: '5489',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f01ee94d460b88216517efd1d2054a172943e42ea1c2f36a0910d28aaeb2c22.png',
    id: '5490',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f56f60eb7c03e52dd23a7b5663945a909377891097c7292486091d97a67923ed.png',
    id: '5491',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2f18e964715e410cf467fcefd4fec37091d7fe474969fd27e8239870ae95996.png',
    id: '5492',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c0fe98ba5fc46a12d020545b11708cb46e450c00e097baed6c6111a21d72f5e.png',
    id: '5493',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/244c828d4c9cca9ad235599923a6ae15c91498d8def0e3cf9e6ac7f0fc0ae9b5.png',
    id: '5494',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd0cf2566f80e143c349a58cc2b6c4683c71f9d13a6cb4ce6ea0c10017191461.png',
    id: '5495',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b55fcb8935ea0b66af8c764787214c2649533c3bfa4a29540d206f1fbdab4e2.png',
    id: '5496',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85fb26dfd60d57371f53c3769e54587f1bfce4a7a00b479f484caf8fd44dd943.png',
    id: '5497',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69800991aa18bda0afd317c341af04d5338b8953ac9e9e390fd502c7d9f56085.png',
    id: '5498',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6724245a34594fdf0a613e5e8be22dc38c215414d63d57a319046481804038bf.png',
    id: '5499',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77de97721b7560de1a0b4a1993f1faadec212553dd7324ac44aee844f2dab323.png',
    id: '5500',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09dc75f65152ffd28e51c07f15000cc09fb30e7d34609078b66bc0995b7ead9c.png',
    id: '5501',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4cd9f3bb1471b5c9a5652d2fff77da238965ef3286c26f932e5a6429091e3948.png',
    id: '5502',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48d50fa53ade660b57317490609cfedab13d40c370eeabdf7b8ec9a06da37e3b.png',
    id: '5503',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3ccf0d8a7f38ecf5556f262bab5da5159b08532089e09328c767ac8610ff200.png',
    id: '5504',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9936f2a514d08a3964f5633b84f5a8cb8acddcb2ee97388f2bea0da4f974a79e.png',
    id: '5505',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c2982b9b1ed2d89a7408f79b593aac56d9e3904da968f52bc67f58e064232d1.png',
    id: '5506',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ace4cad2c4efc0e955255f44b3c29d76a5f1b657f50a00fba8be1003eb04f012.png',
    id: '5507',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0c8be617762f7d7bdcc17910a1b1d03def72b50b6f034ae826e1b749e1dcc8e.png',
    id: '5508',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b18f24a4e595b0b0d971ee4879f7accf4397a00a6f5d75aa478c3f955ef7ba2.png',
    id: '5509',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acecdc25748c8ece70f5b97a2e23fbf621c60de97a318591be7f33685ed9b22e.png',
    id: '5510',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f76965d841ddbab100141d0fabb1dc09ac902deaeee51d5ae77b92837912a07e.png',
    id: '5511',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cfa5da2b1799153cf602a603079f83a5b985d05da3a1cee6db85de69ec43ad6.png',
    id: '5512',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61ad8a038facfb4581cd92bb52bbf470a002339be1c401c1bde804dd6b8bb902.png',
    id: '5513',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4373754151f866aafa3017b929f5d3ec297b56eb8cbbbe2bd9fb3a59bc893481.png',
    id: '5514',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e386f163852733e22e24249fbc6ab3f48df974c291d25f15bfb29be060f8392c.png',
    id: '5515',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a1d199a996e4a2b78340d73dc9d281aa6b537183231e15f511b8b7a09417645.png',
    id: '5516',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abeb2e3c7d728f4c397083fe1eff85cd06a75e92f40b244b3542b5e14b584f23.png',
    id: '5517',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f74df3464bb71042843e1657704178ae116d1ffad116d2964913c9352a4324a8.png',
    id: '5518',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00ce925788f6ee5a3efc06b9e7fd21c2a8717ca247474719d8d6379138ca49f7.png',
    id: '5519',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6109e222aada50aea1fe1c04dc6744981816473aeb5a139e7f88ba48f3811ef.png',
    id: '5520',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a529f8de6866b811f2fa36e85e2a676ff189c306279ddfae00aec0706428a47.png',
    id: '5521',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7fc4b7cd61375b4d954b774c35c136164021778a28e9cb58dd958875728db6d.png',
    id: '5522',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02c149130e587cccd5c67a996baf9b8d2f249749cd6f7a079c84ba43593a1a64.png',
    id: '5523',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/201d0d7231fc68dee9505ec88fb7bf875fedb6bec57e74a98098a3c728a971f9.png',
    id: '5524',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24e78ffdc48447f71bdee3a4276597f8cf15cfb14ba2fc38d69ba7c1f2987f9d.png',
    id: '5525',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6d2b06c6be07c359e4753747c86365be6b3e8e89f20ea21673d248f4b468400.png',
    id: '5526',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4bb7a68f7b3758316f84a3a79fac062843c6719fe814491aa3c7cd7d9b3ce7db.png',
    id: '5527',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/992828f89f6c24d5e5557fe3175c0a4cf2e13226b1140f8ef1629349b512cda5.png',
    id: '5528',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c46594977a17291d42de47a0c2bd622401e382292fa7821b454d0a0ad4944018.png',
    id: '5529',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa89abf78dc4b560c77f217659b4143e617f95667afb6f5000b9c6a3b6f600f1.png',
    id: '5530',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e199563a5688654d718b1380b64cad9851c7fad27c53d491aa1d86a2e1cac971.png',
    id: '5531',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dd403f52457417d14bcb92d110b78be6d676299c8b78352464a5f55f651f0bc.png',
    id: '5532',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef7f2ecd4d6702ac2c084cdae49793e2f88c8d18764dbe16e79d0ba53ec89570.png',
    id: '5533',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
      {
        trait_type: 'Weapon',
        value: 'Explored Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f471f0e6380cceb13bc24eb0dc9e1fad4502a61511ed8e4a236dcf01bf1fd43a.png',
    id: '5534',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97b8be0a5a7514aaf73ea1cb3697229fd9d08418769f43a3d18aa4a66cc860b2.png',
    id: '5535',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbc9da537b6aaa8bc82a7d676d7bea97b7497096e9ce4dd2b3d342cf9055957a.png',
    id: '5536',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea8f31e6fee0d52d2da3c234d2a1339fea7bd9f9c2fbfd3460ff70222ad5d53a.png',
    id: '5537',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f6936cd551e241e896211b2cf7941d0164bb0e5b6d37e96258e3a11b207e50f.png',
    id: '5538',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1c33566d7a8c5503dbe8f32732d4bbbf9d7dc1e02b239fcf61a46bec159c08e.png',
    id: '5539',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba6a35cc9d91ceca4934ae14cc9cc041696a3f722e54a6a91356a4e4bbdc6213.png',
    id: '5540',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24bfa96edf293d6f81357a0acdd91826655ad7daf34cf9a5e7bbf9dca75e01ee.png',
    id: '5541',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6b2386326fd4dc83f51a0fafdc8728ef6a80cc03d00bce2b9cc0a55d55de628.png',
    id: '5542',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fc9fcad2e4da3c9e08b7ce82fa2a0c3d678a13defa740f3c9ec8549f2340821.png',
    id: '5543',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/804aaa292438506ac66a730a0476d341e05923b4de0d3197962833e4a1a64ae7.png',
    id: '5544',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6fc997bb77c382bbda66b695fe2d2bf4f79246d07ccbe41b9b0d1b868010c12.png',
    id: '5545',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc0f3f0a2d93373d024c757cc6b8d188063e5eb45fb3581707440b3154fd90c0.png',
    id: '5546',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e288ef8f25b0a79bfa9fd7da94ae7196a5f67a2ab72a8d876fed05c7be04aac.png',
    id: '5547',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58057a760359d89842f892f0dd1d959cd8db4ce96429a569b94d864065423977.png',
    id: '5548',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d4adbac9eea89e3d2ebf9f0ba739d49342b6220cdf5448dfc3d24bdcc5c0a66.png',
    id: '5549',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8e956ef6a6857374a2e1889da80dff7e7cf553209be1c5597f158c1f9bcbc30.png',
    id: '5550',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Weapon',
        value: 'Gnaw Saw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3896543be06746dfe2f07b0dd8eb9c6fce42c1278c103e35aa80b18d5626f753.png',
    id: '5551',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/931271b11e4c860db4f618ee3a037629113ca64e00334010a951e635b5880152.png',
    id: '5552',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1edc200de6f96c78b64d82e94df99524f65aef2afbba3fcd757b11534bf8fcaf.png',
    id: '5553',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79d6c9e0962be52ca85fd52633f24cdd04d303b3e14e62355df0e70ae02b0d19.png',
    id: '5554',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e5d01c7f5ef8b4adbf24f4e788732564ec844f5eb3d7dd5cc27c555633bfdaf.png',
    id: '5555',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4af26295c5b7dc6962d3c2cc8245e7f48f3912904361205869e07bee3821e140.png',
    id: '5556',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/085cb3fa33cc0271ba9e6f854c9d5aab9793cbe8e34327c2b4dd84d88d275541.png',
    id: '5557',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d989c654cfcf82528181e4bc33053adc979d07ced74d386911f772a42cab605a.png',
    id: '5558',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36cdf9a572e7b88bcff8b732edbcd0b65cfb26ca8ff286fc31930c32f2aec5db.png',
    id: '5559',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eace7080c6d729f5482f2be227dc2fc59002857a7720c185985a06ed73dc95c8.png',
    id: '5560',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a503a28f33ac93fc0375262451f706ad7e406ca7a3c704027934affdc3e8e09e.png',
    id: '5561',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e718e8ac0f276f3256e0da55d95094be06d09f401603bd79292769ae5e65e096.png',
    id: '5562',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73ee0a831877fbea41f4b9278389626b2ef2c2cb89a65d8142193daa5ce8693e.png',
    id: '5563',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/122d63516c6041bfbacc14ad1a372e374819b49dcfffda9b920d063285638e80.png',
    id: '5564',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5fbdd0bd62e262665223cb31bf060ba8497f124b51ed78a570a71728aacde5b.png',
    id: '5565',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ed2e3ff2b0f9509c11b029e3162a52b301c0a9c472770469deed4f694fd6c7b.png',
    id: '5566',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3460236a1818456ef86028c4530ebff8a3f5d99d8a88aad7e0a690060df607fa.png',
    id: '5567',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2402e8f59e0f6c021dadac1a2c31c50762e215aea899fce33cf2520e5dd17f22.png',
    id: '5568',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84fb0f95d01f6ab31c48aa082fa0db23bcb3a7cc6a2eb43ee0707f622852b32e.png',
    id: '5569',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/567ad67b4dbf18de0f73022b2b182d487c78d19f6d351a6ad3068fcc1a72edbf.png',
    id: '5570',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Spinal Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22acebae9849aa555ff6ba4bd612142392ce8a1a792e897f5de2bb1557f7ddd9.png',
    id: '5571',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a57102c485a670ab1c7d985a611e52726aaf5795645774520e13764e969a0afb.png',
    id: '5572',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa1d33342189d6ed0c5ca0cb508b33a00bf450540d4c2ada91c7bf0aed84a657.png',
    id: '5573',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e45034dbe5d2e0ad54fedd7f0e1e036b41bf58b705f7f1d13c681694f5ebff98.png',
    id: '5574',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0d295555008f1f6e60f6d417c2bc854d894433ef62109f28002388c8d1dad93.png',
    id: '5575',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf563d3b32709ad2ba321b9021e902e844cdd8086b35c6c4aff6e201817fbab0.png',
    id: '5576',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60497483cd0bc8c05fdceab09fbf03a827635703a562e57fab77d1acd7a7c395.png',
    id: '5577',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dfaace127e5a526217369f734032219dea7df01f913b453a442a4ed82968432.png',
    id: '5578',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7117655eedcfce5de46a567a94aca48b25a10d8b46c053f2d49e43a2d0613bcb.png',
    id: '5579',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3d61d96ab2d7f83a44ab97e99336498f29b0eeac7ef34059611f9050717736a.png',
    id: '5580',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e67dda32e6f5b21da6c75da0a97b174e70baabb74845749c706f20664bc2e4dc.png',
    id: '5581',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c739416b3f15f0d6b6e217da567eef508087eae354d2f1fc6a825cc32e3f252.png',
    id: '5582',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00f6e5574dbbec56b6daf06e4e781bcf5fc0f01990597d918e6f8bca2db31e3f.png',
    id: '5583',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d24558cd48d7345e43dfd40a1332e71b5b9e0885ec5d4f044a0419db27b70602.png',
    id: '5584',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/836909bbc442d54b9c5f0cbc5c242683db13b58614f059f29b9eb6d01967d42a.png',
    id: '5585',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c881ae420452ec04b7e404262894cfdb88f711290bf0abbcc05b0d42d0126398.png',
    id: '5586',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bacb5fd6dc6090331b3d9d2975169d4988419046747e9eff3d9fb96e1c93799b.png',
    id: '5587',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76aa9a0a99b9a95b9dd9c21a80951f47a2d24f7ff5403c0ca9e176a811d85eb1.png',
    id: '5588',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ecc89c2fb417ad3a99ed3eafe8b641922058fea29d0eab279acee6635302642.png',
    id: '5589',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc779301be8abe192ed862eedfa38afd058d76b22f4f98b451b972be41bc332a.png',
    id: '5590',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05f19431ea09d2e5fe66728bcca1eddd67358b72e3ac8f12e2dce36073886fe2.png',
    id: '5591',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crystal Stabber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5703df8699b8f88c4d39c186934f1ec4b905e3be86e12a9838e802e746c2d657.png',
    id: '5592',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9bb95012e5808598ee00e5999409727d2bcf930619fe6df6e1df5c6266bf2d10.png',
    id: '5593',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97178f33f613d281cec219d3c434d58f85537b3f71a6a017d069ef290fc2b886.png',
    id: '5594',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2b4dea8e39f391d27263247824aec314ad86e74f9d2bce0367c71a4311de50e.png',
    id: '5595',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b679d91faa23f643631e7ee27c8ccac147153558801f339ae63510dc3232c908.png',
    id: '5596',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c64497a9357e62ffdd91b2ec7bd4f3b4494986c83f94331a55ba6864cb2f08ee.png',
    id: '5597',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e1d5e2d26e2e7555ad104a86a78d1ace6b9dceea80e9249dff81b52d34b994e.png',
    id: '5598',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5edb8c91cc0f2d296948489a31241ecd732ac24ac230a1bacacb671853345953.png',
    id: '5599',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d6afdd2294626ca022ae0e013cb4ff21d470c85ca4304d010072ec64f1e6cbe.png',
    id: '5600',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fe30fc8e2b3d3cda78d741a2c6ef250574ea234c51141c27754f6fae35531ca.png',
    id: '5601',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/733f730c0dd5e46d8f5f6079475d4ae80e20888ea6393d7f0f52fa461cd6e3a0.png',
    id: '5602',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ec11a8eca2e8ca10cbcebeaa77ca5f3c4d304efbe5bf7c12b578a62b0606434.png',
    id: '5603',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d8b25ae233655b90be9ed92a299f833cfe6ec5fab4d7c402998134e97a8e5af.png',
    id: '5604',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa306d08c59e97222502f73bec65c68cd49d8077ef6b5e9c31cd70a217c22831.png',
    id: '5605',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b9f63bcd1c5c3b1f105b7777dea2f2b49414f50e249bfb83089f9f2590cae09.png',
    id: '5606',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51557024c5f6f508c32fc281eebb55e8dd707399406892e7e61b72c5b8ae8732.png',
    id: '5607',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/343eccc1d03ea79b42e8ed0235d86c5bd877a5a5e7da0112b24ae066fb278196.png',
    id: '5608',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/027e5c2262fbddfe4c959795450611f387a5d3147a79f1e9414e2c827cdd6efb.png',
    id: '5609',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2da888d954777ec4fe56de5ff4a57512df2684cf292aafd9bb234fb86f5580fc.png',
    id: '5610',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91379bb9fde82ec10be3e2ff9bf1c40951b408e1800ae5232cc973859d8d4ebd.png',
    id: '5611',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df29edff2d2fc870d57cb1dd32f7a6351852de0fd4f34e4ecca13056d3026352.png',
    id: '5612',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91aa5c48135d53e331b18e05671d589421727b29eef59d8ce9149486904f4431.png',
    id: '5613',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08bf3c1dbe8837540aa002a69322f8835ee4ce6093bf2245dfee0de0f80f95e6.png',
    id: '5614',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5e6d016b0d1045af69ad7fd292427f42117aa3058d2640be2dfbe396c624b01.png',
    id: '5615',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5baa7b131cff785998b24ffeb9f5de3adc9c8c5f91ecc5bd0bccf9443e79b2d.png',
    id: '5616',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d7fa1bc80b806bb044e78afae002b2375984e98619e6478b4e1c76cbc6f100d.png',
    id: '5617',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7935a8b662d1e1d29495fe982ce2eacbd9baee09ed6894b617d4b4a841ace3ff.png',
    id: '5618',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/959c8af9864b375cdad1ceb10452b277978a7539c02fdc0d28c8d8cd43a3d993.png',
    id: '5619',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a9e024a461e526ef413ae5a3c68dc5a39eb34aba1d0ef0f8231db0469409cf93.png',
    id: '5620',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39107ba954c7e6e1622c337a4e81ffed9cbea52e588ac7c94a0554aabbc98691.png',
    id: '5621',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/480fdd53cb856cb25d7c7875a357d3fea30f16b7d81bd346e48e85a4f3035149.png',
    id: '5622',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bfe9bde44e8ba91dd7fe09895f2ae0aab370966dce1a2470199f8e7b2370e468.png',
    id: '5623',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/055c0f36c984b8d34ecd5996ea08ef22fc2b9548ca278929eb7a541c6e254ef2.png',
    id: '5624',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0eb67df4c236a6031d4787b75cf15d19807a704094c0aed184129c19290b1b5b.png',
    id: '5625',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dfee4db217d75a7c678935140d4d7e115687622cb1eef9f88ed76da0dd2a79e9.png',
    id: '5626',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e542eecc820844d185d5d6c975c13a3346d2724d35c52ac4979b61f4bc5074fc.png',
    id: '5627',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f760fa24ef3a32a683b116f71a8b76d6a383b510b0cdce25b8c2ea3b80b85613.png',
    id: '5628',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/138b1866552f1796db0638c23659e96d19d5081b9168a778d65c2196202a23af.png',
    id: '5629',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/358a63899f648349647bc1fb23b144316678326e08da92dccd907719fb62baef.png',
    id: '5630',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8edc8b76be86099cc8e485281ed3c223ce3bb13a08c9cc959e31777923ebad40.png',
    id: '5631',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e504d03105d61eaec5174066cb44320f47df2e8f2455daf1fa8dfe23c450d4d.png',
    id: '5632',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb0ca71b846fb434cd8f04a31b321e63f51336771ed2a703ea8ceaea61d0c105.png',
    id: '5633',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afa87757d4b3ea10e172af6831fa91d5c9e56cc84dc6914f4057ab5b5e6a7faf.png',
    id: '5634',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e7fc0a64d62e83287fe7cbd29988b38ef68f4833148c8ae2af104f78872f9a1.png',
    id: '5635',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/103ddb0b5a06ebe1eeaef31fdab5bdb58eb76c5f2bb874486d4d54c4c1adf5d0.png',
    id: '5636',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a9d63de41298319fe7ee4c18a51b6f2301ce605dba54ba3cb8e2ea7c1e9bdd44.png',
    id: '5637',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf25a7d2ebdc0331022a4b000b8e84e61a8c863868f054f91a71a94dfda126b3.png',
    id: '5638',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4bbec65609dcd2375c79b490fe7b2b73b9947d4e9b79521061aa2b1ac71708a6.png',
    id: '5639',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0399bfb8f29c73826bedb5f77100ab874ca4edfcfe1f6eb4385dde5efa02c637.png',
    id: '5640',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4d9338cf524b1d58fe6c7083a40b94d8c7ac2aae5b0fd01e21c246859d65497.png',
    id: '5641',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56ffdec1e44ec66d3465e218268c204b54987d509ab3cea988c68ce20a26f48b.png',
    id: '5642',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1663b04ea7a3f8a30a9ceb1082269764b3ecb36ab54348986ebf25195125b0a4.png',
    id: '5643',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/efc2f213eb8d2ce7c107c7c13f07ce21953de44927d3e2de315dd4c0f3831b68.png',
    id: '5644',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b66983c536966d73bdf2f2a2122b5c6c0a6d85fda22e41efe400901d8b93c2d.png',
    id: '5645',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9304750d59575a5b3fb0a527de0f14343a660150ea05cddb96369a88d1b6e0f.png',
    id: '5646',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03e02beb210f80211b0b1d17eee3376d4a5c00364d5a9c06008c678b9e1bfe4b.png',
    id: '5647',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8003be2273f87c7c40c5f362a9d254fe19798991c5d3afdf2f31c3f735e1a14.png',
    id: '5648',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb66b3cc54f90f52a6118f195203b0aa63e211058c5fe669ca410c2701ed74ad.png',
    id: '5649',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00246d2a214dfee3a43d7630f7827dd5fc023fc762db18f7ecc2f210a890abec.png',
    id: '5650',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0aa8fe558ef151e944b402270e93bb6e75e48184d3d6c4e47a57bb80cd13d54e.png',
    id: '5651',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa04255e87f394a561c82fd84cd7e85003048679442183155c2a7279365c832e.png',
    id: '5652',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/273fbd5dd1eb03c040fc4db7165eb36a5e9fd1e917061403a9437aa2a1612bc3.png',
    id: '5653',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08b8f1b19b50ebb04554a83805e1a53fd69c95259c498d8d3493eef8cc3fca46.png',
    id: '5654',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31079650ecc52a064a95a4828114996ceff262f687bb7aae4fcc0a6c13aa0313.png',
    id: '5655',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6dac678dd17ad1a94fc0de09316bbd9e777dc30e03f2c287fba7b51b83e2c3b.png',
    id: '5656',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Lights Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64045e82a918c9e7c67118256ca34be482606f813a4792d4330bfcb15e222152.png',
    id: '5657',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e577606b7f5abd38b121c812baef7701bc9b4b525881237cbd23daa1a149c907.png',
    id: '5658',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13c3a92a4671990a626d8328e72f384a14e56877a20f67ecfa5bfeb6562d2d67.png',
    id: '5659',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e47a38c9d54d1859db15ae8f29fb610ad316704821b7b39872f114485003983f.png',
    id: '5660',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34d0d0acdca063bad1d4146810de4d3c58e6e455efad068d28c35198f3b4e978.png',
    id: '5661',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Gunk Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/803049507760b747e00079856429a2387778ec5583e7abc4fcdd0994d826ade5.png',
    id: '5662',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff4e07a30d4540b48d1f00031f1e62a6ce50686385b527fbd8d3f7d3e0171c48.png',
    id: '5663',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27cf615751af027dcb4e358896ef575475cdf58f152dc21bca18ed1d458451ff.png',
    id: '5664',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ce2733255106c1635f44c9e38a0c17fd53ed6f6568987efc435e1bdff34a53e.png',
    id: '5665',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/721728e6ddae7564dca0b45ad66968f0edfaaddf6534948c7ce708d1f1ef1ed1.png',
    id: '5666',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba0be9d7bc4316693cabd0087b3ecc7a34831a93be833f9cd6b1ebe4c5fde522.png',
    id: '5667',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4952121eed6d9e7d870b0ada638dbcd613b8f60f178837fe043af00fb0cc7e8.png',
    id: '5668',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b010e06c65558296caef6fd36131c718030057caaeebd902a209290d8afa7536.png',
    id: '5669',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b4a8822c34fdf7501740c5103cf95de203fb0012eb7a6a1108bfb7d5cffe5c8.png',
    id: '5670',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f25f4914afc2d621565854fcdd92d0ff0327f4b4c4db508a05bc34d55900903.png',
    id: '5671',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28929ddbe774b3da9c208a459c11413061ae467f943ca5589923a2ddb920df6f.png',
    id: '5672',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b92be1209592162ae445bbf9387311f737cd994d740f152e13178390aff2f00d.png',
    id: '5673',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cef2c71be129427bfe8582e75ed0ce28ba188909a210714195f5690e2aa97621.png',
    id: '5674',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f3851c66f09967a1b41032cfe612b58620b46ec48ab9a5700effa828b29066e.png',
    id: '5675',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/827012c5a5fa233df3665439486c1c7c500d6ad2fea808181f1e97059b6d5f43.png',
    id: '5676',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af79be4e4719f825adc71500fd57af9c02676dc8c50be6210c1f420dd21c3ad9.png',
    id: '5677',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9aa9615a498b338c1aac1cbfde64e2e67eeac1a33a6785091a28c4d43a1e8814.png',
    id: '5678',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/911ac1805daceb81fce0c5be3817ff8c3fce11733ad406a6af532d665a6fe6de.png',
    id: '5679',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d84900d5e14719c49426b477dff1a6c7de6e366f14b8ab5e322752c73216564.png',
    id: '5680',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8341be490a3378709680f82b3d374c734a98396cc5a6ef9eacea8261eca1607d.png',
    id: '5681',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/090d01deb67e8b96a04a5245b3efbae3711b7be3ceb5abebb7e15bb93c438b8d.png',
    id: '5682',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e5d4aa40437811b52852df64d110d5c7cc610bcde9524284f9dc2baff9c0a41.png',
    id: '5683',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ecc5bb9cdbd286ccaa523ca7a156779f9fc3e14b89692107c92f4c067774368.png',
    id: '5684',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4049af37e18a352065df6376bd30325d873f0cff847405b491f5d00d81524493.png',
    id: '5685',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5606dfae2b0e3c6a84b1c356b050755c052775dbe96c32d7714b38ba1987e34.png',
    id: '5686',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab44ab34cbe6e445a43629da19d8bf494879a4db730917de05d45c58dac8c214.png',
    id: '5687',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3aa2d66ea232560cf8602e103388986aa85d163aeec788d5c76ac194c4d64635.png',
    id: '5688',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aabe2142feafadc9abcb16e56dc3ba6decd4d50bb4aa048642bf14099e6e2608.png',
    id: '5689',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7958db1f5241f6eefd2f1bc7a5d00d58bd475abc0e12f18cc6cd6f60e2bfa02b.png',
    id: '5690',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a11f58a4ce8f5d7a3a8aa38017363ca42f8a8e66a205b13b1a7dfe48dffecef9.png',
    id: '5691',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b07c4d16cd90f8ff3f682aec4677acf353dc2378e3d92d9b652164268757127.png',
    id: '5692',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac62dc546d2a1e16b32a63d5d89d72520f82e0a10a62c29a0f4a385a98ce7dc3.png',
    id: '5693',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c014aa9ec6ca32e78bd270bc51ddc0bd07a4f8b0324ed78d71b9a97aa4861240.png',
    id: '5694',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3f387dda51c8afcddc89c48a9e73c7ed858820ee9cae904d8bbe9d44c6920f2.png',
    id: '5695',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b8eb63e51b144c83967d7d61db1fc39960c44896d7e38949330dbd42ea99869.png',
    id: '5696',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5fb4baadf7c0bc4c2829923bf9116b44c93ea4b79ac4b0808f0c2a5f80cb15f.png',
    id: '5697',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Alinta',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eba245b196caba7da47b212c8f9cb1411e838a24e622fb5d405ceced2aa390cd.png',
    id: '5698',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/008956bf95747d3e609a189d34800f471f896ec20b72c20708229a5a41090a59.png',
    id: '5699',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ea47aaaed94d649ca50a1d9fb217bd97274ae8ac3fc3c82a2d70e7832f95226.png',
    id: '5700',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/feab66b6d22e85f36857da53979c34c16e13f8cfb928547728fa1b4af7016f1b.png',
    id: '5701',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44afdf114188699afd1e2c4fc310be6f8b3074707976869151bfd56ce236066c.png',
    id: '5702',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0487c209facb163b2da1014193ccd63aa463e146fde382531a6e24189ac2771a.png',
    id: '5703',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e156a984ae7d125ebc8d21760f855ea0778de5f047f1f20d2279ec5896755376.png',
    id: '5704',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5dc77bc513ea407083de21399bef4ba1d8abc9e182793a98df8fbb950d589b90.png',
    id: '5705',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c974c02c12a10a34f79b1f967ba82280a17ff165a720e030befb35bd18f45ca8.png',
    id: '5706',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d618cae5feae992ed702a99f20a50fc86a7dc1910376d3a11007ae083ec76a6f.png',
    id: '5707',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5523f22df185ddfe773b0f106436eb94ab3cbd45074e7f0852dd375910daeaf9.png',
    id: '5708',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3782d86e1ce54895c36d6518877500e6d12e03c03dab3c19c950a06d34b871b9.png',
    id: '5709',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49efb7106e0cc8df6dd7dcd639699cabc7a738656b5c708fb3e771dc688cd8fa.png',
    id: '5710',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af93b032b665230f6d1dd1defde50b041de71ff08206838458f081c9d9091028.png',
    id: '5711',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd5dccc5b4d2910fb98ef2fe6e053523ae703b8058fe95b9687a8685a76e9e81.png',
    id: '5712',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef1692e87c14d5fb4178feaa3f6e75b5c38cae3c1433ca2056c680b76563e5d3.png',
    id: '5713',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c21b9c370b08d3cec247aa02c26525f8a8cf8634039ae56df36a1b47bf91dbe.png',
    id: '5714',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d26a476aaf208c7799d48093f5d19649c91ddc922420beaef3b1a278d3e82b0.png',
    id: '5715',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76c755ac91369685611fc76ee27a0678a560451cbfd79c7a919366c83f79b31c.png',
    id: '5716',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67fddb69290c1ddbb12071fb27596c9f8523628b82b8a566907e5add5667f8d7.png',
    id: '5717',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bcecf757239050dcbd8c249b1dca6414d281d02369898c67fec3699743ee681.png',
    id: '5718',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3785cce8bff8753ca0c4745182270c370560d990b0ed5bb23e971ed16abfb8dd.png',
    id: '5719',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6985da73e502ce423fcd811caf0574abcaae374039a0bd1f0814e621b0eef783.png',
    id: '5720',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85012be40cb2a665ec6f1a1ebc276ce707c95f29b94abb779ae9e6d22c439b4b.png',
    id: '5721',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/829b61979f79ec706c4daf001868491f769df0d3821bd650c4f70122d3d49bc8.png',
    id: '5722',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/531cf7aa3a94c250f2634b67b776e7c40993b9093742a169d16f42eab13a6396.png',
    id: '5723',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd344d32f737abc3f6c30bdfe72042d03a4b0396847a34079b80c24abc6f87b9.png',
    id: '5724',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff0a740a728bfab489151e7a437c252b606c3c632d32e3214d2390a5ba2e6af7.png',
    id: '5725',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0835784afa1e534807d647c260ebd8eb1a6d1f80548b524e432a8a124e0a1b36.png',
    id: '5726',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc5cda4bcb53e82cbd04a8c8941408df7a1010a7099783df56f1a1ff125ac369.png',
    id: '5727',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a3dfcfc7c67b07d36bf70c3056af1009fba4e33d965bd74c3e81bebe435fb06.png',
    id: '5728',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a72d44e3fef78d53d8e9fe3ed728781c5b54945b4bfcc6f0f0bce1222baa40cf.png',
    id: '5729',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a78d62215da6820e9de287a5028fa0d10a5b620b6cd31898700f05e9dac1537a.png',
    id: '5730',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'The Claw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/016c71968f93e6c27f1cc279f78346e7d23804739c683fb576cf8c4684b68a1a.png',
    id: '5731',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5c444d32c52ac6ddd49db18efa1d49617d3ba6d12fee4f1a4e2c9878c99e737.png',
    id: '5732',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2dcba14dd8cbb19640568d706b594b8609932cc9aa010bb49f37d73918ad0c7f.png',
    id: '5733',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4df01ea772a2419907872d9af3eae18c12f50df7f2e33648e6b1dc8ce4904338.png',
    id: '5734',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc4489919eab3a95dcc28b7d86e8bc1ce7eba664b9824add0d5f303ea62692b3.png',
    id: '5735',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e9e756011957605127752bea377fc320c836db0469a540e2af0ff81c95a519f.png',
    id: '5736',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/308ea3af7026a4052f1a7bef458838d72696d27ea88b712a3d0a28a2c5df3040.png',
    id: '5737',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b98003b6ff40b34f8ccb9f7c43f21e23aafeb2fe14428b3e2a852ed9c08357e.png',
    id: '5738',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6a2854315801f7072823fc08a7ab6abb34aaff56a160f98cb182716830645ac.png',
    id: '5739',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9efe831f77b73235baa7ba8a8ce1cf5e8f0888ffe768c8e80afe75d77b01a22.png',
    id: '5740',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6ac593c511e7659e0814a819c987ae20ae8ef711eba0b8ea7dba79d18d85d6c.png',
    id: '5741',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ce2fda1197893eaa853a0f7032b4730534854b89f8e0b8a969d5c00e9f00f19.png',
    id: '5742',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1458181308c3c56a8c648c75b25c62ddaefe1b275c4519196097b144dba0908.png',
    id: '5743',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2ef74ec9da4cc9cb9f9693aa7e10efad09ad08b0b1f45170c932f040fb32f00.png',
    id: '5744',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e0fd37709d0ebb301dde117ebb290a2586008c792e05bb2e0e2ad96cef0b556.png',
    id: '5745',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c036e83cfc16164971f3a487388ceb5359b1ed20c2f8becd23b78afb785de773.png',
    id: '5746',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af31c2d5265495ac12d8aad914d91656efc16b406ef861ebf8594e956d5216b9.png',
    id: '5747',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f34dc977d7163fb46ed07dc26d26813f63a58d46a6407b82850d3c9bda2314b0.png',
    id: '5748',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc2c1482b2dc8695a85f7cc66ea80029952c5b2bcd59635a21e24fe17ab901fb.png',
    id: '5749',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea76ec49659676b340c98660085346c7b432030aaaa431e55eb72d30376deb1c.png',
    id: '5750',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6576c999f9e3c9af9283bb2544be555db04dd6e2364488cb1fc6b58b56c86262.png',
    id: '5751',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e0cd06eaa2db8b058120d5a7111a5779afaae438f63691bf8868f928714318f.png',
    id: '5752',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3de961e66304383fc92a6fbd76d02d6f14ac35fed813dcfad8652c5abe27fe37.png',
    id: '5753',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df477d3d4d2479b78ae4b54760bcc32ac9a647d0e465365cdd3f20373a2ece74.png',
    id: '5754',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2af4f517020fc6c1a738ccf805402354d0296b3c813f3b42da28d56a2942e876.png',
    id: '5755',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32101b8d0f9da3e358c24df3a0aa69c311518b6cb359df8b2d79434c20ae90fd.png',
    id: '5756',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wise Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd3ac1ac605397dc6fef512e5bc022b0a0fe1e5c2a9e225b966573618a40aa5e.png',
    id: '5757',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/518ca3203e254e61249a459fa2f1250584663dea4b9ae8359926890e8526f6e1.png',
    id: '5758',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d9624229fa4710e12d17554c2f3c5a267222594ff10305a39274ec4b2d8c340.png',
    id: '5759',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8b4a56bc23ae5391fa9d8182c79d4d354428a10d4201704967690b56d36ca91.png',
    id: '5760',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4fcd81ac73f1b6dea544e2c99590753606808333619262cb5d0a5473df25e34.png',
    id: '5761',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5944e2a5a86619ea9377e35cd2186d389e2d779e16c958c07f40e6aa9dca01c.png',
    id: '5762',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbc2f8c8f0d66a5d9b007b6141cf24befed1787f07c78eb1535a8177b4e81784.png',
    id: '5763',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29de137733c0cd3d6c39a752b8a0c1a283dd4b52f4f59aa4a0d0e1f08ba03689.png',
    id: '5764',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fdabf903aa0741ad04e3fa872d21c54175827f4480a4c5f718d0c4440a7d977.png',
    id: '5765',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/343f2a8ce1d13c46d6fd18b3faeb121a9b3f8a2dfc90fdbc43be09f79922cfe4.png',
    id: '5766',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/782b8fc3100dd2ec623db9c6681137af8565ccec11e9a29970b3aaed3ecbe932.png',
    id: '5767',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a662aa8d68652425ad8d3670de86127683c7d5bf3b9234a027d75437db59127c.png',
    id: '5768',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41c056d439cf444dc4966c47b62ae243f094423788d3b00eda6f2fc332ced031.png',
    id: '5769',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ae77766d41f3645881be5fb8ce4b80fe0afecfe4664ff025d6a187cb1e81472.png',
    id: '5770',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ac04fa0a0cde5d2bcc43d0a2acc4c351d03a59e52e3615b603cf2abd848366d.png',
    id: '5771',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/097cb0e1e03c9ac8c4145efc82d4fbba33dec37facc2ba27c742541301679426.png',
    id: '5772',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5abf0774ac4c9e5bfa51a7e142f0b9e7619676e66baaf385f1658ce26fadf6e3.png',
    id: '5773',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72587bf028193f7be39d395141f31dfda7fef5c739c4ce843c60309ca076e290.png',
    id: '5774',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b23b1a0bd73348746b292560047b7093dbd003434567e1345c1838e06898cfe9.png',
    id: '5775',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3c15e57a6527987982187a17f5e080d5dd9a802fecdf409f773f2b34e92a667.png',
    id: '5776',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/996bce5546b9d6991d9986683b6134f5d086f481045e084c3ecb868986c73d71.png',
    id: '5777',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3ccbbc1bd86b942a9b07615a0ce54ca488a04ed64eb2f727317b442f9feb7f6.png',
    id: '5778',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc2fa692f795538b52374ec47f1416f91e4ee2a7f0b74e3ac8d53485a6bbe381.png',
    id: '5779',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ff67c3fe2f2e6f4c88d68ddff122e0e25e640e6fc40c8160def1105171d8d39.png',
    id: '5780',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74785136c9d0073b088dd22206a4d5935cd7f367688a8a69b210961c830e4d3f.png',
    id: '5781',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'The Tenderizer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/baf134219da19e251012e4c1b3d2e7ba1eff9d9c9432b0cae41c7c022869a317.png',
    id: '5782',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8be1887b0abea8c8d9d98a62db5e2991516e3c4d83276aed234c52f9458a8de7.png',
    id: '5783',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35cefdfc9b87c168bbbee0e7b5af57050e33425f674a8ac81cd3b355b2e4f20e.png',
    id: '5784',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f154c492b6d05f558fd6c692cf0ec3aa72ae71979605120cdeabc5492a9d960.png',
    id: '5785',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01ff85c1a0cbbc270342a0029a62d8c78f32bb2450970e48370d01e82eb513dd.png',
    id: '5786',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07563e43b412569d362092095e5aff2b397953e25e12b3242647545114407d63.png',
    id: '5787',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd8400a526d37e216213ab03e0b9e1f43a5ccc6b8c94692dc1581bd55e6afb5a.png',
    id: '5788',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Twistree Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e8f03fa19ac703048546321f664ca106f202d4ad8e12b4d1c05ea2e7b23a2f4.png',
    id: '5789',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a222611b4203151aba5e8b26610fa9f3e9ff5ccc5b170827dfecb34557620706.png',
    id: '5790',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d999215770491c80e40755aaa92c81ea9a8f8db291a550684dd1eb5e91754a0e.png',
    id: '5791',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1db3df2827eb78c38e97dfc07a8e452123853fa342436b0555e5b10aa4e7c8ef.png',
    id: '5792',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ee402d97d6cd607351ed95d2a0241abce69923b78f62d02783d1b1ffb83e8f5.png',
    id: '5793',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d100956301813851c461477f74f9c8bb5441c24bdae88a742cf278d3bf03f5e.png',
    id: '5794',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7aff34237b278a1efc692595c9bc04f6522c830f36e9c22f99d61bce01faec46.png',
    id: '5795',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf6dcae2568de091ead71f52b7da10cc5a591565fe49d9906394648f66cd8774.png',
    id: '5796',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d3eaba5292392b4daec1cf46ad77cf769cb13b8a31c1c5294af59e8d2ac6063.png',
    id: '5797',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2af20a560120216c158db5491cd4aeeee9b8480386e47f788319ba8452cc2a0.png',
    id: '5798',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/136e0efbae009cf5c418fbac7a7aa539c0e90c57d841a758d84783454e5fa4be.png',
    id: '5799',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63d64d25f71542970525b1bc9dcbf08e8830b90420cfcb8c982098418317dd6b.png',
    id: '5800',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f4ce6427f95d1fefe749829fc05d725b3d78c31dcc295b96d2b955785be0478.png',
    id: '5801',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3e0976d3b814a98e07e1f4631d59b92b387dc83f9a2d01eb6ec1335df59460d.png',
    id: '5802',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/077873fcf6448837b171ec764c76ff2defe55d08fbbf4076815f501744585b7e.png',
    id: '5803',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/535c14735539ed64f64a411774257d912c4dfa0a3507874e0d449413d07fa28b.png',
    id: '5804',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b096dbbc177e2d30129533e93ac277e3a9d31bb347ad9ff312aa954db4dd98eb.png',
    id: '5805',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/470b7fb5e58104ac509669f9c8b7ae030437bc6b9fa76ef05159eddf9991b53a.png',
    id: '5806',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09e03dd5d6d656898cae4266d675aa43c2f2343a98972f4aa3a1567703faacde.png',
    id: '5807',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5bff279d82da5a7df4c4d82e4954bbf9d6873893579f0ca3f53d5cc4caa9d80.png',
    id: '5808',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6511d5b672158b247c81fe81eb0cf6a36e0d45adc1b08796d25ac6f31cf861b9.png',
    id: '5809',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa894f996616f96d5b257d8c59865ca5b387c403e21170714f0054791c71bbe3.png',
    id: '5810',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d960fe01e84763e3d8a1b13c1f64e528afd8ef40c000a787bed9045bad158ac9.png',
    id: '5811',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0858c11f6a615a7543cfdfd9d53884357217e77686e592a57ba645b2b5948bf.png',
    id: '5812',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecdcc343b0ba59622ee175da64eb40dcdcd3003e89dba5136f9ecec5f20f2ab7.png',
    id: '5813',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/818c0881ba4d4e6d858908976b6a97c60ef639356d2c8e713db12a9ecf021cc8.png',
    id: '5814',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd9b0748a2bae45955770cb858cca5301415e252c12f898c5dd6524858186785.png',
    id: '5815',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71fe9be83b85c449665235a7963689c926362e202d3f99153fa44c4403adc9f5.png',
    id: '5816',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8753e0789c4668fda8bc9d5a24ed846ad0acac83e3aabd0ac1bc98fbf32bd7e5.png',
    id: '5817',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a83c15dc289620b0d5a36b90239405f6c063f8652e3c1178fd92e31c1373b307.png',
    id: '5818',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7638749f0fb839e19a48f6bf0b966b040b0c900d1a5f19848f3c7593e8fa555.png',
    id: '5819',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5d0dd10d9707ff41f7dac934fc8f6384f9f27f142b1ba14924bdafb16434150.png',
    id: '5820',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f02250d9eb7361872202d001a10773f7d9ca1a7ea5713ea81870c97d050182c0.png',
    id: '5821',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b13c5909fe5d0f72a1cabd9b0809c7eb1a4cc8ea3793bf765ea52c7f63dda14d.png',
    id: '5822',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cde1507a2c4a3f1b00189ebc7949802e4c744cc7309b9bc1b2392982305f1251.png',
    id: '5823',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca49a6243df2225cf36d3cec4dc8bfa9e2f57765ac18fcab6dc5069a98450e3e.png',
    id: '5824',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e66dc7f93dcab67a6519b402a3e1dbedcfe166615c290c4dc45a869c2f323b02.png',
    id: '5825',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7500692a832d57d33ecd720a2eba27e98553a9272fbeb06c18ac37e3f157b887.png',
    id: '5826',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1c1968360eea5cf3e53a4a446acdb1db61c9ed60c9a75140c084c7ca6952862.png',
    id: '5827',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b65cb103b91ce90bb49704f236eecb8ac9e66c1c40b787b425f3cd3d256951b.png',
    id: '5828',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3372ceb563629e3d05cb5bb5be7c600876dfd6cfadd916c52321b8af18bb671d.png',
    id: '5829',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/859d92a1f060f4ca2e73b21a23945be3c8aa62f2530669feb020b9971c2c2b6f.png',
    id: '5830',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09800ee4d2cc2ef27f345c7c352c74c7f663cfef6c8cee4e74894f0f47cda3b5.png',
    id: '5831',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/404b63cd91be9c8ab4baaba8d3dc237348ba04e1e838be43ecde02b2d6bf1103.png',
    id: '5832',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8fd0545845c88f071a559c040eeabf69c599c4696db2b62b3182153005e2aab7.png',
    id: '5833',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d87de7b06a3796d25d049d90f835ee8cfd6aac75c22b239e939f9a1f61f6b0ae.png',
    id: '5834',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0209010ddd35dd1a5af44feeb8c63bf803f9019bfea18690a040d7a6f5306df.png',
    id: '5835',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89d276c5be6601f9ae0609222a25636dee96071488f4d51aa2cab114a24f5746.png',
    id: '5836',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98ae94fcde34f378ae6e5546650b5efd3d1afc8832993510687df8b042add554.png',
    id: '5837',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Blade of Great Heights',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31ce240b5d624097613a7a7407afeb0ade41e7eef57ce7a5f760b81dc1ae8c95.png',
    id: '5838',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02a629d7eeedb42c08107941c314084c127e1d5ccf9be7874f922b89d97812dc.png',
    id: '5839',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73e09677cbfe30f33b266c552d1ac3ed8970ac033bfd4c9c098bc23ef1772cd8.png',
    id: '5840',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ad41d0f1c74a56b4c76f93e1a70045f47b560a2b26635a8670e9e1998ea3e3a.png',
    id: '5841',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81beac6a5eff802fa616dcb812d15a979318f024b9d2ee2f9caf8074f55e524a.png',
    id: '5842',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9dd36795f7ec35737b3d6160c157fd0c2223711f16b2abe386ca807d92551479.png',
    id: '5843',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84df1a47b536feb7418e7369b556430f93c6c131b6b5b8a5a193340e903dca0d.png',
    id: '5844',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e57769c117b75183111a8d207626f2bdd269b0cc55544385a87bd7539887cf5f.png',
    id: '5845',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93f65885b3180294abfaa47639aa2b2e378a8b6e38fd13b3906b6470389d3bb1.png',
    id: '5846',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b531a8ef16aec3da3410fe4fed2dff2bc7b82a91eb1227d8f2c9b9a96fc62ef9.png',
    id: '5847',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08d66266e41e7b124265f3d286b4dc1d5b0a007686e61aed7c600775bb956e72.png',
    id: '5848',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc3e20c8a02235ee7b66b0b05016cc181ad457917529fa0abc05ebcd4573050e.png',
    id: '5849',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6cbbfb848a52f327b6179f82d8230034c6e693c8307507d09b58f3e832b6788.png',
    id: '5850',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ba4ac7a75ec75c86e4f0b16a144e59f06b77315d552abb418124b1a0a08fa4e.png',
    id: '5851',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e37143af2d1d77fe7a508035356e810145420ecd4ee24dfd7c8a246c34631f2.png',
    id: '5852',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e448b7d8e20fda134f2a58cd67ceef2d09b1574d70555969ad8b818832e00ca6.png',
    id: '5853',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a04a9f0f7dd5156589c19a4285e8361e2f8833d717287a8fba1e94cca38df656.png',
    id: '5854',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5969eaf0b69cd4d7e7784b4b19c6338412dd8fc37748cb9f7b7dad6a25aa5f11.png',
    id: '5855',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/adac5ff3b5d42ece64e2cd12e9ed39a8de4951d4690905120a509a8277dc4076.png',
    id: '5856',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d6ab1b9dee0697dc0a4d4f6a6ca85bc70eeca5f7560d64655018066d39ec861.png',
    id: '5857',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/324a4f793dc955ff997b7ec5b04bf8238a29b755bde872255cdba4044ded9372.png',
    id: '5858',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c23e4955e8aa9b1a5a4f901fb31d93465ea52268725cd1dc3b397565da77e969.png',
    id: '5859',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df36a7f75df1a80bf19a60e45e5715de9bfe86f30de2c1f84dfec58a2470c1f8.png',
    id: '5860',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f070758d2cb3734866d764dbd196fbc16ffc8ca322d0424113e73ac00029bc09.png',
    id: '5861',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6606f3194f032f08441904dc054236edc4067d1d31038a0363a3e7a6d14e8ab.png',
    id: '5862',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f44c126ef0afad03583a2cd50e859c40cc6d55627ba7cf374f0a645f8f2fce1.png',
    id: '5863',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6305c6fac7dac665e7bfe3e89a0d349ef4f0dc6e229f1a50fa56ad902d38bc4.png',
    id: '5864',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb44b19e5280ad8de4c9d7d2d6b8d3d0930be6266cc5ac79c7327559dc993e9b.png',
    id: '5865',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/085b8d44397ac51881eaca662e14abf2fab3cb3fa1538ebfd9bb7c38dc8fc4ac.png',
    id: '5866',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b79544afaa919a2ee06a8dfbfa3c633ceba6e216f7b9abedcc98a8d3bd72cf9.png',
    id: '5867',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39d4acf5ba826361867bfc599e45d42fc28ee5e0c4f524e8e8f1a10caf74ebe7.png',
    id: '5868',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acc683e05782b4a123543dd3d5791ac997d483071ff2ee9103e671a6bbfeb1a4.png',
    id: '5869',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c588f8b29b736ae704c711212e57d56301a0100ae1d776ce8b11d1b5fbc724f.png',
    id: '5870',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a6d8757634be07c3e8734a0b879111c0e399c60120150b4a1766c561120de5f.png',
    id: '5871',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5b80b23cd52e6fc947dfd1d0d6815e3f5e7d5d9ebb4614952b40a087f8a218a.png',
    id: '5872',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b10ccc5903f2e0ed3014264a61bdcbc406307eb443c53f2a06f33941d18e8da1.png',
    id: '5873',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Crystal Stabber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e8e6ecf6dd95188052bb8552862d6ef116c6f40d4eed784ffd5e87903912bf3.png',
    id: '5874',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/797a24a0788ef4459ef95725181a27bfc6a17affd0ea9e592a95e827395920e3.png',
    id: '5875',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Grazing Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe78b58f57c7caafc90cba2e3ed686d77b50bd9511175a43b77438c338f00926.png',
    id: '5876',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e70c993305a70e82d875344bfd55168b1801c0486c94b83a987b5ff76c9e476d.png',
    id: '5877',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab465e4480d7d86badbd3c8e08575d98617059a066ab82e2bd53c615f1d67cfc.png',
    id: '5878',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebd77bf78abdf029b0709c2ba8af0a78b2e53ef37e110afcccb9ae47b4a1acfe.png',
    id: '5879',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7a9d10d667aca5788d68946531f19fde222a70badd182513694eb3f319c2717.png',
    id: '5880',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c11aa07b87265e94aa0595aae39abb5a47960138386e99acdf8d3561c31b32d.png',
    id: '5881',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0614ee103e3d13278a430ef478d97c33c00c27ce57a1eb814102755f14b69cf4.png',
    id: '5882',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36747cd8738f1e8698b900ff1a6765ac175a46329894e6616caa83997c25c2be.png',
    id: '5883',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd477adb9e95700291db277c3fcb00d1ce96f557abf424d6b6befbdfd1133d0c.png',
    id: '5884',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f42ad7cdffa7cc19f86a766d4767c8b14651d393ba7cef054138f16ba33dd472.png',
    id: '5885',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a7e3d6066d54c5e52dd35bcbef4aa91e33939cd1175a937f3a31f3816869106.png',
    id: '5886',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d3e8fd90a1546c67702bba26d2ebf6f40b3c8da54b52869cbb7cc9051709fda.png',
    id: '5887',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b9d6a259dc07aeeb84e2be126e7febab3bbf10820c93a0e4cbb541db036dd7a.png',
    id: '5888',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd89e74e76e034451de3a6b7fea7ffb603f47d2c4c50f102d4d1a0ebc88ec8eb.png',
    id: '5889',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5beddf840b7330dc4c22fdd0f0644d2f606ec84bf864ec4eac9db8d3306d1325.png',
    id: '5890',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a810064136959c7d40d18c6152cedb2bdafd00822b2598bbea5b8e8da76ec54.png',
    id: '5891',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfd2588dbcd26eb8ff50d5216f2fe62a10099fe09dc247e77bd8228e603f5c4a.png',
    id: '5892',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e869f2522b2dcf5a05db364bf5263d0b6a33369b9a35eebbe7bbcb6d7623b66f.png',
    id: '5893',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4c73cefdbb30df16b30897d4cb1dbf3315c7b8fcc0c2065b1adaba5ae2867a3.png',
    id: '5894',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d33906f201d6a842a13829be2f88fb6e336b3d678cd4227c624284c9eeec799.png',
    id: '5895',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fca8c4ceda98df331aa36279b3f1c5c4209418090372054aadd55cee6fd7bd7d.png',
    id: '5896',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57a2044fef288dad17912ea2960b2930b3bde9500dc64cd4028fce66b05af219.png',
    id: '5897',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44c1885ad42151fd9b6dad319ed6da5aaf18e4f404dbd76ee8e4c0a58bbc790d.png',
    id: '5898',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b745b6373a548aa6b61a829cf1bc297d59893391b8a5901b2beef22d2ab913d.png',
    id: '5899',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20c5f05dcf1c3ae2ce837e6e5a339634edbb9023bf347b793bb3797dbb9e1cf0.png',
    id: '5900',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37e11a6d8f2459e9b9d8493fd0032fdd51b1eadbf5a9a963e44ef15f89c3bac0.png',
    id: '5901',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f31c64b45ad12d1c76c7144dc95b0b783b8badc3b07980ec87dd3852ac523f28.png',
    id: '5902',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a05f4e22398eec126bd4310c47e16df6826f81501bfe6595c36e71946b9cc57.png',
    id: '5903',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4af999e14ef1f1d081c393a1d1e6b8066b71c9efbc06f2aa91a5d973dfa3e10.png',
    id: '5904',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f2e29c89864cb112a6787a2ee65581adf1a69fa637e2d84b2ebbea063a03449.png',
    id: '5905',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a156a38a75107948f5a0c21e003fa1c12cb8165238aa5102bd0eff20f0bff22.png',
    id: '5906',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77954b7defd4af88119773a7a0324c75d8d927f5675ffadb6ace993d7f4156e2.png',
    id: '5907',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a38ee49af2ca600e686ba01cdad8f56547fc687de38a2c1c5614e08044ed84d.png',
    id: '5908',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8594d59a23e55197fbb9e9f3b903ae839b8b9e4e16c8799b9cac092432f6d2ba.png',
    id: '5909',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4c9d0470550707ce4284f66db11dcc9f563cf0e9ce57bddfa0ee62c19e6932f.png',
    id: '5910',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f411c6f7b103fd0c15e8d87ba39f59f3d714cef1ac331ff85c05013b4d7b3f1d.png',
    id: '5911',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/825fc396cb9393e6cc741cc55e0aa53b77d786f3536c88363c0c1359be9a0256.png',
    id: '5912',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df113533a7836e6102940f4c175a7fce3f0dabdf29ad54ac0b329633a0d96305.png',
    id: '5913',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2631ebab745909e986fb20ae00fb411005a7868c65f7dc10487b654e4b43701e.png',
    id: '5914',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/304a721f8f6d9a90c9c03427755397fe6193caf3b11b79a820fe1869c383c71b.png',
    id: '5915',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5233047563ec7e5cad92e8bb4e844063b3192ce196f8be1fcf42e4959ef094e.png',
    id: '5916',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/770a2de1897703acf4f2fb2fb968252c658b512f3b229c01ec5203260d9c8cb1.png',
    id: '5917',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b6f549de78bb1baac393e61c5db9816d18cf489edb5c77d27f8d17296abea01.png',
    id: '5918',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01316d271727c407c4b57bfc2afb63db38ecb6e2a90563f192a6c2b498f9a5dd.png',
    id: '5919',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb18bddfb7b5eeccf15bb4843e9de9b17e6c96d4c001ebfd8129c34d279e7a39.png',
    id: '5920',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96c9a4aa0302cef54ad4b8fc02831a26546348bfc3f2100f9c09309ef440f0a8.png',
    id: '5921',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14d6b904a62c792b9c28d21f71ecee639b4fd33c904358b2e0195a09d2dad123.png',
    id: '5922',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/846f7789c5caa8b8a4a7aa3af81942890b77dce33f429932001b50d55c79c33e.png',
    id: '5923',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d2a00161e2cc3a39e481179fa839871a67deec06c9a63e1a352d88c84598f72.png',
    id: '5924',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e554e143e850666a92d91e2846478d52a3d13c13a1807d9376a7c6f6e6d8855.png',
    id: '5925',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e53095560cb9e49d309d54776df5a7499d67800d3a934213e9696f1ef61737a3.png',
    id: '5926',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d3902f05f7768b323fb9dee2d9320034a07d619bdae36b6e8b209fdbd78414c.png',
    id: '5927',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1fe1173e397ae2fa5bf7ba362ab06e81301b65be2d9d56f80ecd43ddfa19691.png',
    id: '5928',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eff9ec9a54d024d42dbaa9865531bbfb5d4adca4a2e875b69d498746345d6383.png',
    id: '5929',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6d337681c1b94357ead73603e6896cf3b2f47a71c20dad471075a4035ed1010.png',
    id: '5930',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f169b7484b3e6a9a23d0e366647ebb38c1f89fe519600b694109203b1400a724.png',
    id: '5931',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c08135e3166d38900a443bc857c5e1ecd2523e926534951f3d930ec46f65cbf2.png',
    id: '5932',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66821160dc803e065be4653be7b30a7b866dc9cc614f94a129c6d52aba4dec2a.png',
    id: '5933',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/412bab4a4a470e9fdd8b27f3a0e5d7ecb500713f3b214e6c0190a65203672329.png',
    id: '5934',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33c818f67e5eeb704fdcfa4d643ba51c6d71549f1bcce7022c45b5b877c69620.png',
    id: '5935',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af3fe1f9e91497d2797b695f54a8c4fec46a66efcf0470aeac2a287d5250e1a3.png',
    id: '5936',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e33e6070ba24dd0fd2d9922bce9218eaf7d93acb7779c9fa930543c2d3571ee4.png',
    id: '5937',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d4d8274a074a813048e5e16f3f5aa700527646f1613a1d26f89e2b97e9bba07.png',
    id: '5938',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85a37b40adf55a5fa7c624266c59122bc8db2a22be435f9c341b22e63b218f13.png',
    id: '5939',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f9123ca064f63ff16d57f880da256cd43767e07705a9adacde0e6513b94a48f.png',
    id: '5940',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e897522aad9ef8ddda4dc89a059607de0fa17aecdabe20292fb8726893190b19.png',
    id: '5941',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/547f846e8a040ef0d59b488fe2c0794952444cdc43ab663bb2446ab9c2c48ebf.png',
    id: '5942',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d067a0089e5ffd0eecb7fc91e8a37ac8b694560e1d970bce01457533b93a0232.png',
    id: '5943',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d68a0060f1a68b04ce3152d644ca77559e2b0bcfb5ae7bd4937367bdf26c10c.png',
    id: '5944',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aef1ced528f6c3220db0d17e78320b4b914364635ae01b078ee9ea9b38ff32de.png',
    id: '5945',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa137f662f007245e672005c64ebf2a74baec14c4407a7bf9ea3c32626d6ee1a.png',
    id: '5946',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b42084a425affe708cd7a1b2a9509e6bc72dcdb035aca5781c41cc460aa7b7c.png',
    id: '5947',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f82196d9abf62541185f72187ff6a1f7fb56bf2f0c3d1d6cdb45f64c9e7d950.png',
    id: '5948',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f1045759470073cfffcfeb0ea37d9077046904ad1a7257ae4e8aec0e916720e.png',
    id: '5949',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc5fcf9c0468125536d6354f461a251e6f3a3e62f9e8bd2389237192f0acccd2.png',
    id: '5950',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc13a88658eec599ddbd6cd93ed66445e541b2b5e5be85e3dc53abd237c18b2e.png',
    id: '5951',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/972ee0eae0dd2824b4832279b1461631dd170397823122da592d3fd8167fe496.png',
    id: '5952',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a845e1f496d35ab820480e94effaaa6a6bef47c799df2ba91af8dce4e55df2c8.png',
    id: '5953',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce3877ca0d771a0f6265e1d37667af6f861ee51058ef377a6d364213371ba7cc.png',
    id: '5954',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f9abb9d1d48a34325f71910ddf07c1f66504cff22a6512c2f392d77db653061.png',
    id: '5955',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75d1094c4fd539ec5bc7d25520aff8c3d91df87ab4c3d6b36d7ec5fd64359a4b.png',
    id: '5956',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b6c44932953b0286a0c619045982500d2b72ee7af3ee32691768126b646f49d.png',
    id: '5957',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a8c2b460b5abf375596638459dfd44ea9135add3d70b03ef15e7e1bbcc0a624.png',
    id: '5958',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/faf68d528d57d0a5de78de42ded98004bb836fe605f00dcf6af8c3545a779a3b.png',
    id: '5959',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fec55148e9828f4f2b0db32e02fbc4677023be7bc8bf0c4e5892e7f12a2f8e37.png',
    id: '5960',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f5ae47281d7d1e26c8b3f07a6ef1c666c81f84037a9055b7fae5506d545b71e.png',
    id: '5961',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'Gunk Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9f546c3407d761ad6879313c90b6604758579e54d556f45a137e5061b719c56.png',
    id: '5962',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa2ef00df26eaec0d892b4f7575a00c99adeadfa72068110014ed44650b0d2e8.png',
    id: '5963',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9026f9c1fb3b6954b721144871ca7490e86bbc0a73e7de43e9f573834495960d.png',
    id: '5964',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad43cd3cc569b7c6dd4397f57b51b16c66125a29502335cc7dfaa2b798f23659.png',
    id: '5965',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab4a12b354a46b2897d449c4a0853c75c126de1792abda8e78e74cea05934024.png',
    id: '5966',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0ced575b10b86d12076bec761b9dc2cb1b55f15cbae63c3c4706173a3639fdf.png',
    id: '5967',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6ec108acebab1a69b2a86cee52ed019ea5ce04dec5690908ee75795feeae370.png',
    id: '5968',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
      {
        trait_type: 'Weapon',
        value: 'Ravens Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/881261dd877324cfb4fadaa4c47d831fe70b93b50e91adc23dde5d4a5482dae6.png',
    id: '5969',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6720a60b7992a57953e5d62d7b9ffe3fd3197dc7a1e481516e0b8d026b25fed.png',
    id: '5970',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8488f4451f9ad2dc38d1733e12ece85f760a2b1269110e4bc96cbde88d71335.png',
    id: '5971',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95e639567ace8ae33e330afb2dbfe35dc4f8f52a8503173a2c5c73383f477590.png',
    id: '5972',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3f8e0b9979c2055a4a2f759b5283f71749763cd22ed36496561131944879526.png',
    id: '5973',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dad41ee58bdd6f45482416bf0b5a9813ca15fe224136642f6d9211df655a2219.png',
    id: '5974',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be8209110c18a9713b8b8ae3fc2cdcbb30d99f4816234e6c18d9e3040057d32d.png',
    id: '5975',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00c8740da7adeb3564ca56c0b557f49cd842241c9ffdfdcd8a6688ec32fee150.png',
    id: '5976',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8eac41ee4bfa92c75a52282d18a7ede788dd7e129f4b6cf7115bb1c0256407b2.png',
    id: '5977',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8df4fe9d44c0f01ef4fd5400fb4fc3c1fb46543d5a61c538a2ed396c7e2b944.png',
    id: '5978',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66a678dfd6e60b44ad03a024c4a2183707f68498c51ff56485519d3fe6f79bb8.png',
    id: '5979',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe5e90259b9b18f9142e1bb13cf482df8607ab720612b531ffe83b7d5054488b.png',
    id: '5980',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85d087fd9c28cc8041d494715eb081511e87b485938d516319f4f04766adbeba.png',
    id: '5981',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6761666b66a0775eb9dc859dfbc72b39417155b1bc35bf83747acafc551df6d4.png',
    id: '5982',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1b817f1aa4e035c411702a15f1ffc304b7e5c85ef97a185bd3635a56be9f815.png',
    id: '5983',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66949cf05157b7996d154a4498f5e2f5b227c798c587f21e47a26b5ac0bc159d.png',
    id: '5984',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25d124352d6c53ff954d965326dba56d9701fc32df3371c8a784f7b0165a6bd4.png',
    id: '5985',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b41e9e54f6cd2e55b35ded8ce48da759b69e309f05af08fc0729957552bc7a02.png',
    id: '5986',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce342a01d982cff5626ea0e3a7b81ad9a74aa19cd8b4ad5167f07db4eec83b2e.png',
    id: '5987',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86ac0623d7e84d54a2e1dd4d93023e5e9b42d87faaaa42a92864c30637201528.png',
    id: '5988',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/249b7f7d66f701c46df92a76436a6131e1ee993c7a753d79e159045de5bf02b8.png',
    id: '5989',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a230622b54b0226fb03a9e339101e5a87e48f1f9dc5613a92cecb70a39f69af5.png',
    id: '5990',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1f7e4a49bfdc1746576161b51e9873b4294b4138d8e6eb221036f921852423e.png',
    id: '5991',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b096f6c8fbb328e36df1c3dcba2fb8702489adbc9b6891ec7fdd0e96ae250ec.png',
    id: '5992',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9406786bf1ea1ba707de160dfce763f7264922c63e0d7cc2b0bef94081a2de1a.png',
    id: '5993',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcd2f43042e2c8211104065cf898da34d3ceb2fd5a428f3f3664b21ba91316eb.png',
    id: '5994',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecb05847b5982a66f377945dff2817886ffd906750e51a64085f1d47fb8c17c0.png',
    id: '5995',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e40e84e1953c02aad9733eb94ef15ba4e4250c03addfc4a1d51e42126d694f3.png',
    id: '5996',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Blade of Great Heights',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ccb4d0642bbef5b2b8738326179ba37d8fd843a9f6e6fe1ef4978483d7d999b7.png',
    id: '5997',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4ed9c60f76fe9a0e13706f49ccdb4698ecc00e3f8bf43cf596df088706416f4.png',
    id: '5998',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e1157ab504160d70772b646bb54db97076682bc85da204237f311f3cf21453e.png',
    id: '5999',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f9199a7e85e7f4d8ca8431b38a936208b68f8e2b84500265c400010c5961c99.png',
    id: '6000',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5b819d9c5853914518af84d5cd7a0e4819da801d23e6543dd3847929884269b.png',
    id: '6001',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/394c30d1e638962e2cd4029f9d5250854047f5e2ff4c579fdb2ec9d50c64c0e6.png',
    id: '6002',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8f63328aac33c9f1714bc3c99ca9a607c55130d3a398569e5e013f19cc5d0a6.png',
    id: '6003',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0d86da7f3efbe84af27c2bbf4aa093621467e6d9218df13f4c30348b3723101.png',
    id: '6004',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bb2b396d9ef097947ed636654433f3d5333b391b75d9a7d69b6103702fd1b24.png',
    id: '6005',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2029761fffb2780691842cb8aee1fd9d7e07fdd44fc5f26e57c312da318aff3b.png',
    id: '6006',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d535a7ddc0751d177645d31c2827ffe344d89e80e39b51da20032e422721a47.png',
    id: '6007',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1087be1bed35445f76d9f55c573bbb0989cb2ee96c3b6321580aa8f864813ea2.png',
    id: '6008',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe34351da5e4a184df70a05eca522041310906438fcfc1decd237bfec8feac76.png',
    id: '6009',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/938dc6ca8adeefb2ee1ff653ae51203b4b4acffce545264be57dff4f530b1946.png',
    id: '6010',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/478f074be17cd2c9d6e10797883b699950cae98a42cdc029447bbb11dd2ea681.png',
    id: '6011',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76eca8065197d0e9c7449c78d647806fdd7f1276650c6d0fd01be675e2aa06bb.png',
    id: '6012',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96ae7448e5e3dcb08878036434e290d6d850591c74c812f25f44d76ce31ba465.png',
    id: '6013',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/80a75e47b7479050ddc16435c63910624fa42ea3a391fd3b9599595d3da138a4.png',
    id: '6014',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54c6238ed00475d6ab3997e18aa1556fc53b624d06794f9b9784248278ad7955.png',
    id: '6015',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d08e9dab568be03b16ca4c3113ca3b797ce49a7222cbcd8c9388d4d9de30dbc9.png',
    id: '6016',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/378217b7aea3f50bb88edcdbfa9d8aa503cde82e297daa3b7ba7fc59dbd4fd90.png',
    id: '6017',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c2755d7d92ce730b7a7c9b8219d3543834a53da5cc52cca15276b203796e50a.png',
    id: '6018',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aeed6a29b936ddde6b5ab7eb1ca057515dda198a4521f5633c7f0889c21fdb5a.png',
    id: '6019',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd58e89a9f259d54cef8908b466ab361e00f2a34372f1635868d24fea7d8ea34.png',
    id: '6020',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d131383e8869422cffe10c60e11e54ebc3b13da6812b64ee8e24567bfbd788c7.png',
    id: '6021',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16b2cba50b3d8902adfd7be66ebdb87974315e99b926e47911b86ec74a313c22.png',
    id: '6022',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0bade5957757fc94fb1f1dec4134f9a2d2e497d2858d225b8e31ddab1837295.png',
    id: '6023',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a9c0404b79d0f713befd9d11ba2c9a910c53a670d4d7bc2b7b8a00d7eaf4a83.png',
    id: '6024',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c772c4467b767c52a1d2a94fa6cc5a8cdf6d82ee75cd61f545e3db77bb2b971.png',
    id: '6025',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f84c836b7e6ab88e25e1e0aa3c50010ddcef35595aa3c376f9dac832d1806628.png',
    id: '6026',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/723aeb4ced6c5ab614d3cdf66c037549d7821b07f7b048a568fe762379f4f46c.png',
    id: '6027',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff5d08ce42a6ce51ff09288430bab34ecd7d49cdd4548a496a179c57d821e314.png',
    id: '6028',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/372e9fecd52b95ac86aab1e87f3ecf2064de85f4f4bd84971c0c44ac0955fb69.png',
    id: '6029',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d900b54aa4c162caad595a7d80d72572a5fcdf943102a85f399ad0e495fd9fe3.png',
    id: '6030',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/636a513d163be8a34fa8a3d0129db5576df10c9a765caf3ceffc03e9e4df3ba9.png',
    id: '6031',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/50e20f1deb920c81e0e7ea4cb1fe8d30dc36eb39f9ceb2b3616cf8cb1d9e763f.png',
    id: '6032',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/46b14e75e725a0c0f4cb9efd2706bcce4b23373d4c8f90f75e1a196d34d262c2.png',
    id: '6033',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7e149feff12e91db20c4a78a151d37c51ad7e101635f3f18607d5a11335b1a0.png',
    id: '6034',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbb55b415caf744e982bccc06834c967c39f90ad72074ceea76a7c32501ff37f.png',
    id: '6035',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71bd491edf5a5751a92ee3a7769f61292774b836a08f37a92d992935ab193fd3.png',
    id: '6036',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28f1b22322526539e399a2f59f89b94c7fe8941932b8c8cff3fe102a850347a2.png',
    id: '6037',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd4c514c9611dc49437df8a388e32bdd8834c7527ac6f09f5cb6cff3dd20be71.png',
    id: '6038',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19729984b68382b643cd0b1d681a106dcd660c4a3cb18cd85e77b5b7eda1dab9.png',
    id: '6039',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98b28a15725a8740adf6bce0279d40b02af5eac961fa912a6335ad7a1c6eaab0.png',
    id: '6040',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40a98461d2efa0322bdb6d018c154721c4c68331ebbabb8b985e0f22ccfeabc6.png',
    id: '6041',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a356957da9d3d6c30166318ac4b2320edcbb72c0830eaf52374e1a42acb34b4.png',
    id: '6042',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/efa864fa9d2ef2e4df70a26a89a3ed281f377ba9ee7ee004bc75da766ca2b2c3.png',
    id: '6043',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a35f419ebe35c8439dca23af3e7e06dc1a416f9729ced21b3c0671ea7041e6eb.png',
    id: '6044',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fa724c3a743af22e69a5dd2694d29f578458e20d15ce93f140f4bca247d8bc6.png',
    id: '6045',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d494f09829e1a4914d6be7ce88d8fa7467e972555a0854129b7aa1ed5864026.png',
    id: '6046',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8dad1991b00fe1fa2b81d282651a71045b953c39122b0a649f2c63021a68849b.png',
    id: '6047',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/865c8882b21c7ff8731dc376eac26328f21e88a9f6b68db481bd51e8423dc52e.png',
    id: '6048',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6bc76360088010d912c62afc6c1a9919ea2d536d47d8768249cd488dffb8d3c.png',
    id: '6049',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/841611bc4022b5903980923df8c33d2f19161a2bc56305de03cf955930cf005f.png',
    id: '6050',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/522eaffcd3557e57432cbaf8b3d88996d9cfbf5125543c99bf2e9cb8ef7bfd32.png',
    id: '6051',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/621accd505b992b8a688dfd7039b0d99ae470f043d33f7b741f8d7c4fa68672f.png',
    id: '6052',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e704c3e549a7d5ec79c4a6dfbd5095a5b415d76c59be5dd529aa006fe3d7290.png',
    id: '6053',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b03480a3d69b6b4e710e72cd5784b275894f01b50cf3120b8799dd2f4deea10c.png',
    id: '6054',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3f89eef08c095204db7a0aae6dfdfbbb34d70cb863659bd44fbf7131657ee715.png',
    id: '6055',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/432229c381e30560c968cc715ad15c6b884ca54a51e63da1c7d7970f26fb1796.png',
    id: '6056',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/adb0132d91f52eec0646dde923be3c17d20e4cc4daef96e23973cf799c9448fa.png',
    id: '6057',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0d05adbfaa030c8f67c4aaddd6240e84fc1ad61ba83bf109f1109e6b92a6f98.png',
    id: '6058',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26caebec70c162808da3b5b093165a5b4fe32d5e1df126e557531290a42ed5e7.png',
    id: '6059',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e95f27323a858938a683415cf117e4322bbd3800722c4f51da1a4f7743dbc26b.png',
    id: '6060',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b13c26623eec06193c751ed5f11695751f1e27b89acd0fc9d644e14a2449684e.png',
    id: '6061',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ccb0f176542711242d5650d2f0e4154cfc22da430d6ceecb1203fa9cbe8e36b.png',
    id: '6062',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/caa78b0a0e59bc8b82d814c91d5d7c9aa98d17a19039b3f0fccbbc95819bbf88.png',
    id: '6063',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c0a3cf60fd2d7c78b79b89d222a92a6aa97b7602227cec5690fc546626261ea.png',
    id: '6064',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd02ce79603efa7aa782cf31e7344289c8450662be625f8a30349f0bc8ec4285.png',
    id: '6065',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19a76f0c610ba762b52e81ca2b0e815525d4eac77af51929ae3f396e8f110b35.png',
    id: '6066',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18ef4957c1333b3f9560a2997429bb7ea631ee52cf4475a5f37ae81f49fe5a62.png',
    id: '6067',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b3fd7c496962f21ca002242a77990c721023576d78a5511bf2aa76ba1a23a9b.png',
    id: '6068',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2e509f70069701015cf0ea6ed244a31cb1e372cdb9fa949bcf24b828a4d18ff.png',
    id: '6069',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c362326e5e442a1ef8272107940572ab8ff44532bc0b193c84b309c0ff7a5e9b.png',
    id: '6070',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54c9b2cda0c706a57028588709bf67d069ac61984d7785a45c562de486a5df20.png',
    id: '6071',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a48223c28b2fdc9cfca21cbdaf34b83fddf169fb34b7c253887c96b0ceb1e69.png',
    id: '6072',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1b08f953ab0caad40a8d8cc5b8a2479f94b116937b309a0c4674a73bfacbee2.png',
    id: '6073',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/849d2cc07452cdb2943e82040183eb65faba3972e20659aeaa878688c77d9f02.png',
    id: '6074',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ff25a4c118f20849ed5dd3cfeaeebc93d8506583019e75cef8702ffa939ff59.png',
    id: '6075',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c5060b6d3e0c5dbd1e0de295ada3a6854d2ce826df2d1dbbc30edcfdf95b0ae.png',
    id: '6076',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55be0558885494dfe18731cc3944cb6fcff3ee1717093ce0b1771e3729deac24.png',
    id: '6077',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ebaeb5e2e80c5b0b269b7f12cd36957d997d294ebd0be310ce40dab01115d17.png',
    id: '6078',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d89d2ed2849f6352556805891a18a4ac7c333bbbbb6805a8c78d8c63515c974b.png',
    id: '6079',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/efec9baf9f623218f1100f3f8ef4928d5054fb65286ab32b23acdff69732fcc6.png',
    id: '6080',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d8023264ff2fc712d0f6128bb76f6e90db98f0ac842f77ab689e88788fd1047.png',
    id: '6081',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b97d2a0f3ba926301210ce2861e6c38067b42a040f07cb8f2f8129bb8070704.png',
    id: '6082',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/50026a0c3f2e51be90ddc45e8304e84ae5fa67a5099e13dddd0a600b1f9da2dd.png',
    id: '6083',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4337d44b0ff13c3f0ee395d32768a1e6b380ce7804501971aedd7c5a20baaff.png',
    id: '6084',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c2c26d5e4273253252e7d5e5a618df31f12fcdfd97710f47b1d4398c33a1491.png',
    id: '6085',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d60f1879e91a3c15bfab5098c2addc0fd709c5902f60c7ef038ed75a5ba86251.png',
    id: '6086',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b94eb68bd8be943867a1d5961ced7a414074a501dfb49da2277d09e848d1a7b0.png',
    id: '6087',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29a770fd34b6a6d0d0317264eaa3a8bdef6057ace0afe93f1c666c038e68bb20.png',
    id: '6088',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b43ff780e0406118f2d1da39d82b4f448bfd4812cd07c4e42817921ecff6ca40.png',
    id: '6089',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b62d4b7c931fa15684600029af27985e3abb5643c6e6177d365a1243f12c64a5.png',
    id: '6090',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a0a995d835d28991bb5dfd821aac28a6c9f5eeb780a4960916442552203ddcc.png',
    id: '6091',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68557a5b3f30b0874b78cb76ad2475235f0a70a0f88a22ab4592e3463f7cb8e3.png',
    id: '6092',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c22f93c70b16d6bbcf0f2eb28d6111c1f96e8ec7fd554833989fcff10417aee.png',
    id: '6093',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bc183766573cf854d1e079bb319d4403e21cb1bbb0052a6ba8f51a8c71a9f20.png',
    id: '6094',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce183cb5ddd468ddf48820b7b29055cf1cd074100a314cba6e372dfa5fc4d117.png',
    id: '6095',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/075ee1d6514c84f540ea94d5b5d0bb557b9116109c8657c599bf3616b9215ab5.png',
    id: '6096',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/666f8d29e9145b627e165169dec306d425ed975f40324a261507240fe9aa5ff5.png',
    id: '6097',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5622d2ae8aba68d32cd9ee2bea550d38dd13364f225cac9b4c47be6bd49749c0.png',
    id: '6098',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d4a472a3cd7d3e2f8f1636ea1d3476ad445fb3344c2238f5a4ac7f66dbe050f.png',
    id: '6099',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bb28571ad1b187309eeac2598ce6ba890ea91f1830c6ee968cf30697c8a6103.png',
    id: '6100',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/736bfb4353a8172a4b4c57e869c050b455c4b962c7ef5d6e5677e0daec4ac4f8.png',
    id: '6101',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fb8df7ad0e96aa828898ff89c1a527b9fe780fe32aab9d06d3429a61d809e46.png',
    id: '6102',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffea6a055035bae555550180aa153774190e6d2766e6c298576ed894b733604a.png',
    id: '6103',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08ac320323565b3be25ad320c1a392250bc91d73d999383d6df6adaa7fa497e9.png',
    id: '6104',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86cf833b64dbe28ae410f01dfef21a5fed7bd4a1d17686efa994a434ba3cc1d1.png',
    id: '6105',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
      {
        trait_type: 'Weapon',
        value: 'Gnaw Saw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d38a1f0bda25dc2461b7f54cf5ddae1c9a25e5fab6412e80664c00e7c708621.png',
    id: '6106',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73101c0cbac530c109d14f56d5810e206970ddf411111c97d90152f98611197d.png',
    id: '6107',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7608cbbd9860804980fafeec166f5a484dbbf024fee74e7c66d4a3d2120e7c4b.png',
    id: '6108',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a22f69b6512a1545ad03a7354a69228700db19568a257f8d60b6e8e68170d7a.png',
    id: '6109',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b04445038d61b132d513e4985d7d3c3449b76710ddef1a2db809e7279383b68.png',
    id: '6110',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70cc89b8b52369ab3431959bd0cf071966fe8b85664ad23c3e319c8b1377089c.png',
    id: '6111',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d399081dcb218e850b8328873364927645e2be0efb0ca08efde082a540006647.png',
    id: '6112',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e09ac2c4fb60542496e65f661a6dfca0cee56d832be45027a8c534396a91e123.png',
    id: '6113',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/915e5bd7341610fd4388c487883e8e240f8761448f5f89ffc22ecba94e59c751.png',
    id: '6114',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5c916278c01db8fbf68ffed46fc489206288c706f5f348e5f67b4e3bcdddfe7.png',
    id: '6115',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e3789162400ccae5d3489c815cacb1bcc98bb2dabca6434c546d24ce3b8b6fa.png',
    id: '6116',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff0581d545a817fc8a9cf9747a21e284d6dc0796c4fe9a4c11ce3bce4a79fc57.png',
    id: '6117',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0754c118a0adb86dbd47511c905cfd5854eade5d596ce7b149dbfce4ef4ba597.png',
    id: '6118',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c4db12383c82cce40aa85b78dd7debe5c1233cd5e2c77b3cafa3a72e6e79c59.png',
    id: '6119',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3abe4b15cf8075b02cdf7fb68f966b8a29931238c6f8a55fa51a361fe633a68.png',
    id: '6120',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c052f1911d099b5aea2f4708cf6aeaf876a588800a6ce97633d93164a44fc9cd.png',
    id: '6121',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4f6c980d18d5c68db42883e866ca8f8c4cfb6ed7c13a254b5ec084ba8eafa5e.png',
    id: '6122',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/131b2dd8d767a0d71694984097a19019879e5ff9fe5a8dc1157cb7fafde594a0.png',
    id: '6123',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b44a8d95817e28d657a3d64271ccb05863894e86be7185cbe5343a7005b6a176.png',
    id: '6124',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83f276fc98af67b669cc53827ebe96069b3e4ef66f0d671db9792f262bf415b0.png',
    id: '6125',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a54bd527fc6de12047947803510446a698565385c2fea0fad000dd06451c6b75.png',
    id: '6126',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cffa59c3199c10aed6dd939866d44b05866e194ce1e71e47ebb37533cf98ab85.png',
    id: '6127',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/80d316753f4634a992a32906320760d547244ae61fbcb7426b0352ada45ac8ab.png',
    id: '6128',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a9d805c977a787971bd33f68ff656dbc34752cdce4fdfe69d07bf801a2c51c2.png',
    id: '6129',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c225b7e34d272ac6e3a6f0039dcf3da040cc629f61d603091bca5535810e314.png',
    id: '6130',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79a4b09976c68a8a1080d3aef7bec8cf45d6a6f41d3e4d1997a1e22828f314d6.png',
    id: '6131',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3be7bdcc7df6f32766b5e64cf9ff4b371c336bfc3b9969403ffd5d9c944c20bd.png',
    id: '6132',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a91a2c8587d3cc3059c76902d42bb32537dbeeb84edda62040e6e180ca89727e.png',
    id: '6133',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5dae502f27588a70fd1b766476f754cc8120f15d6119257a72d8f31f8ad30fe.png',
    id: '6134',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff604e14f3b20112580f0720ffaf29af9e7307ab4796a25532d1436e370190eb.png',
    id: '6135',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91bff7879813e6fe21e5bbcbae85c1a70814f6e63057435bd197bee955f6c00c.png',
    id: '6136',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4609c4d497dcf134fc39f1dfd113013faa49f449cd46d6a8cc00f23c7c73cd68.png',
    id: '6137',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'Winged Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff8f43b20caecc0be14f29bbd46990dc28b4dc61d7c0124a12322c33d6e4da97.png',
    id: '6138',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1f3a77c92178d54385065aa2e01434bff94533a482f4b329085322d9db028a8.png',
    id: '6139',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7fc616a57fbd611f63f0eb73d60a92c6472c93db356f657db0bca329cd11b66c.png',
    id: '6140',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1eca23d7ee39d262a5f2b226b253412ea302aad3dd19bb7e4ffb985d9ea68bfc.png',
    id: '6141',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00c1b022284a2e3a26869f76eb889ab8df6635c3231d576a508c2d04927827b8.png',
    id: '6142',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7710ec19611b843013b3fdad707bd593dead4767a441df23cb23c26d88974bb.png',
    id: '6143',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7e83a289011c6b72320964879c60b59986b4030d931172aa5d6afba917382ab.png',
    id: '6144',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/782fb546d1ac10641f059fe2995df7193cd808ec5ecddd73881aa38353657fe2.png',
    id: '6145',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/534d633c1a0c74c865f0b4d6a1fa71e30295e9b5ac9f61a5e5e720403256b590.png',
    id: '6146',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad32b24598337b0caf09ab1250a87f09e6a50ae3649e0d3fbf82c0629734d90b.png',
    id: '6147',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd9443f6f1d801256f8336082e854049d0aea091f0badd076a3c543c6da65abf.png',
    id: '6148',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dfd613a72bf8c7786573e1f97dc4c731a474a86a5b1f913a7848976f532cda0a.png',
    id: '6149',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d25a01b8be3c49ee5f3c8abb95335962a19f47f1826d26ced47ac4e24edbafd.png',
    id: '6150',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d17293bd36f44480e5101bba9749a4a00feff25d90f36812f2a04f68f6ced138.png',
    id: '6151',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d221557bf35a9227f6c89b0b992773007ade83a155ae7e87ab919690773da054.png',
    id: '6152',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/465665654e58e8bfb6481777043d95464ab3ddd456f82d1db10c9f14a941f62e.png',
    id: '6153',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1630b4d8cee765fe1efc14baa9f808bcbc8c93a4cceb4b0456d682e4c53dfd04.png',
    id: '6154',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8686f1abc16e785d89ae88e9000cb9ed037a34bca3d731005aa5da80df761f10.png',
    id: '6155',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b3f57bd3139770db97504e9e24570e8c97bec979f05b19b6a1c980bedf0e3818.png',
    id: '6156',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ccad8498086c5de2acba453da8236f3363180f16fc7f9528ec1261b46aaa645e.png',
    id: '6157',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b991eecb09a2541f65b918e16982d292c63949edc92886c2cd13f97e4b6b557.png',
    id: '6158',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2023760490ff1517d3fccc702636bf6901092f3e2fd0d95392067f66ddc3c5be.png',
    id: '6159',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a86d12fa4bf059d791cbae7133db45a6a9a8d6a2e101a985d26fa33e4c55535.png',
    id: '6160',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12dfe4d996d8dedc6adea7089db96d9d2bf0f540ec9fdedaa590836705c9a0a8.png',
    id: '6161',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7af15b9a6e0f8840a75d51c76a92af35fadb59002bb0568daec0186e189a2834.png',
    id: '6162',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52f2ab91ca242d4757d37c36b1ad53ba055cbeca830a4988f4610fc4cc3280df.png',
    id: '6163',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f69807dfb16199460f49d09107a9302f933dac723cf582d2ca103640e51ab797.png',
    id: '6164',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc39d31943d63d141bf6bfe460c5f136142a4bfe87e263ab63ce032d44cca1ab.png',
    id: '6165',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afc18bc1722184a934ff1e98156288788e2d66f651d9da34a4ffcae1f3438ed0.png',
    id: '6166',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e23ccd3465d48247439fa65f54ccb25408d6f4ea6f1975345308180531107d1.png',
    id: '6167',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/653d91b4ef95e4084733f52c6cde5a79446a3c8da6a7729fdb977d1607648c4e.png',
    id: '6168',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0d0130d2e37155a59a0737c0c366a23c2ad991cc4222ee357dec933167ff710.png',
    id: '6169',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22bb6316321f8e64dc5095f3c6f52f0e04bed80ed267da59758827d28958a458.png',
    id: '6170',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b304bbc4351ccfc38a2866e239584f809631513ca770d09e26231cbc1524bb0.png',
    id: '6171',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'Lights Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0099cdc09f4a875b7c4f941e6098ae0cb89f1af054a47e6bd3c65fef6232465c.png',
    id: '6172',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d3dcc321a8d1249feea9fd575f0664cc3058aeff88cf13dc396837d279fd967.png',
    id: '6173',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbafc5db271169636e5ee3b48dcc8c1e0a7762eda7486263e57f3413163c9fea.png',
    id: '6174',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67dc939b24eea652e6505126cd46a7f365d0c4e7225dfd067658a6fdaf3c3a4e.png',
    id: '6175',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c1cf1a80dd05b35a64ce2fa1dd1e4e12401003cb2762734ebf147087025b63f.png',
    id: '6176',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1067336a30467fe38135b8689f82125813a3fe79d04129a1471719725786df6.png',
    id: '6177',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/341b1818c211ab5ef563a9a0daa8e10e469772567e6854bc58f784ecad6665c4.png',
    id: '6178',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59104c8188abe96dedb3030734d4aef2c7cd8e7e9705c7fcf167dbd32b495feb.png',
    id: '6179',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89286fcfd7a6ac12dbaf2b70cc7ec41ffbf038a9a1cdfa4a9fc3be47cc4048bb.png',
    id: '6180',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e03651ad9c072eabcefc62fe1ff5260c523c1f5a5d735ffa32b7299dd03a0fe.png',
    id: '6181',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b776f882def888c73fdbe96d17c4f882af35eebb298db2a17fe24ded10b3b0b.png',
    id: '6182',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2d92524631470aac3b3d154c634c2e9ac58bfbb98665f93fefeaab37a6483e2.png',
    id: '6183',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84084b74519d2b2b316a7624984c17d343d3b75a3c0ca934e7a6edb954c5d646.png',
    id: '6184',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1a8e0123d4a2005774f5dfead9e824abc498d409509e6c6b7e50b153306f090.png',
    id: '6185',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9640de8fc7d4c5cdba8d295945e5d6866b795dfacdff96c49143955f4079a424.png',
    id: '6186',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19030bcafdc715a3150e81fd9564ca398d529b875f1f09aac743927f3b9255dc.png',
    id: '6187',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5929e5ed2b68d33060682e4007178541509b6dc191dd29b4846f58d0d7539461.png',
    id: '6188',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3771a45dee56db4feb674952905c2a3e752d4d0712b77180497ed7f3d9ca253e.png',
    id: '6189',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/389da0d5ac39e53d5c5e7c4c1d9d14eea1eb892c5e06a17a1ff7e4fc080bb7b1.png',
    id: '6190',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62389fa9ad833e692acb916d8205236b09a7a2e83e0fe92d6251aceb40e71245.png',
    id: '6191',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18b7e301a5b6f5a62d759dddd8ea630251e3c4b8037f27c68bb5bf3a54c54956.png',
    id: '6192',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d15af9731f401c43cc52d5e2a8e05e9f22c073f28bd6d119ae2ed9404022e247.png',
    id: '6193',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b7faae606872a78d0ca43a9de211367aebc7799e2ad8b6af87c6f430474cdc1.png',
    id: '6194',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27468f82bf91f6c9038034ac0d2ab8cac63a2012c0599b8f951f6d7b91e9020a.png',
    id: '6195',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d26ec7dedf2989ec35fdf5c46de0645bff183882bd55676fc480011227545e6.png',
    id: '6196',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6715b01b377ddcbe9453c3c9aa3f41080f838e4d8db45f8d75244285e532a924.png',
    id: '6197',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59ec243e146eb94d116c5d81d6da55162a056970daa054d5b81f86215598d4ab.png',
    id: '6198',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c87e197fda1cca5afe78e4a91b82352d03c69367ff99cf4e8067c7f8fb76964d.png',
    id: '6199',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a43dac6683f20c90b6032d04f8c4720f481e6800876820aadfbb6e0e0c3fd20b.png',
    id: '6200',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c5d4f721cbd72ace8be660344740ca9f986a5e255528731dbf68fad3bdc24f2.png',
    id: '6201',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/019085e3f61b53e609fb8e416c6f41cbb8ecb9cd42895842da78c4c89ac3f6e0.png',
    id: '6202',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/881da326828bffaadf54795d1700a9669715c02a73786ed44243c8a1633226a1.png',
    id: '6203',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97fb00b642fad81a152d96692120c52663deb1f12882d52f9e995227492599b3.png',
    id: '6204',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99a05aac941a4e1e433294b0837d4281bdd44beba848db3fe3b9fe70abf4c263.png',
    id: '6205',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57cc02bc70520e547f54552594f64e674adcc8e405ca2d1e49f027b3175f58ec.png',
    id: '6206',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2717c6e9d48fd28792d9bbf4ea4486ce8cc6c80451102fe7f8692e4b1223ca6a.png',
    id: '6207',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11538d8e5488ba4ffdb6b404d42df0a4afba7867529230df4fd597735c27f6e5.png',
    id: '6208',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/816c8e628789ef49fa1a07f9b2e3169c07738526d0ffc026c339bd329b08be20.png',
    id: '6209',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6bae5fac1ba0907b1eb54ba1b039c7c5d8be02be20b9ec84a7dd7caa8e4bcff.png',
    id: '6210',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c9ad4f9fab8393d86ef1aec25f19eff021e1e0708c0197a09a9b37309eb86a9.png',
    id: '6211',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b1bb8f26b133352863de9b9d7506e965d63754cca2e3ec8d52057912b27e57f.png',
    id: '6212',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f428514e68d67469f17286640012b75ec59e831ab1607f084fd76a15dc130e6d.png',
    id: '6213',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/801f6a9ff95573f827f59f043464cac56424c053ff79f1e7010f168b54d45703.png',
    id: '6214',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9c740a240a84dfd45e0a9db9d6a3edbc92fbb7205a19c9afd982c9decf833ef.png',
    id: '6215',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68d35ae65873bd7a07fcf585f0227bd2f75b9430b48cb1d4062e897a4dae3f40.png',
    id: '6216',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Spinal Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1c3d6fcc69f90cb51d9cc15e84ab542ad1c9390e3011dae66c8fa29c314c505.png',
    id: '6217',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4bbdc19fa85b61854263358ba8053b61b07926fa83459558de55dd0237d2aa71.png',
    id: '6218',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/297d8116b05f0fa9b737415e8af035c04d60f10b40126f1d429aa4de32729823.png',
    id: '6219',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/204b0f9f0dd6a7cdcb189858456846c4d124c344443855acf083be439d013d92.png',
    id: '6220',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/addb7284844d4d3d0abffb8afdc77819acb9575380090f89928a3952de626438.png',
    id: '6221',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/530bc28b86d4af8226f359dfe8159d546534e1d5b48dd4df0c773894cdcc2698.png',
    id: '6222',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8bad9b6e61f94d40166dbbc11d43a0a9c45f690f22bd92d9d24b129582ff416.png',
    id: '6223',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mush Clobberer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/499ec4ce2a00b5a3fef75c2703747b8f0596f6f24fd611b681b672b9baa92fc0.png',
    id: '6224',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd490b0a24ed94ab0cae8b6497de97fcbb38504f6ea5b941f36a5be7b4e506bd.png',
    id: '6225',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc2f5502451b79ec066be281e80a204039beed7eeb7a35b058443332dc29d753.png',
    id: '6226',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa34cb02b39eb04f627353e170c50afc058c839dcc9994564c391276a1057e9a.png',
    id: '6227',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a60c7ddb7f367ba75686c801486f682b0a9850c35c024eb8cba07dc89901b8a.png',
    id: '6228',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8eafb9a99437d413bc5a9c587816e4607e37833b17466d28d4a0ea1b7e1011be.png',
    id: '6229',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33e402d97252ebbf96520fc5b0bf47b389af3af68d5fb9fa324f277ac45dedce.png',
    id: '6230',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6beadf65c60f8034d0095a5544b6b9899f66803965ade04f1619cb9ce53e0274.png',
    id: '6231',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f66f1a5f3b1eb68595006c155fa80d3b3e64ed759b4811655c4b798a1ed216be.png',
    id: '6232',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/490274ecf599f632536262e9b6e6537ad171f166cbb37b6224cab1edbbfd4c5d.png',
    id: '6233',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a33429d1e12bf003b2bbc4e1bb8aa6a48fd1edb36ee19448c77766bfea211f9.png',
    id: '6234',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dce25018d0bedd68eecebd21fc504940ea84377f6793f3c55301ba76c5108d2.png',
    id: '6235',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b684b0bbf0238e9c56c366af90ee2e920c93c3a8c9a391d7f041394eba35a8a.png',
    id: '6236',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8064f7e139408a666f5949e490bb304f767e1b311666f92b92d732a0d455e830.png',
    id: '6237',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32a29a3de24f259a13620f789f97ca0ff7b1e070c4d3788ba02def1b0314a0f1.png',
    id: '6238',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c5d164102dedd9a4787a309134a23cba0b75e85990f2077bc4b90c0de64392f.png',
    id: '6239',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cactivorpal',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/803c7c5f8b80ed053d084a0cd88160c10b4470f6a7265f774987f3676190490f.png',
    id: '6240',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9edbad22f8bc24ccd8d7136c839ee2f401c7b1b6dcfbaf382768121949da1fa9.png',
    id: '6241',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b7f584864c30b0b270eef7d6adb0b80ffdca058fb4d3637155cb4208cd1f11d.png',
    id: '6242',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc4d8b9f20294a9499aa7c9df9dc701aaba46b7595c61741dab326c8562f2c02.png',
    id: '6243',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8ebaba17e165706c75a2ed22e00ab198870255be863d7434aa6bdbb4d7fbc64.png',
    id: '6244',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ada3b0604b3526b7af222c5fa7328f5dbf5055bac52f414368180a27b6f5f70.png',
    id: '6245',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e59271d6490853e8136f1badceb1e7505027507ec7e11fc2fdd201b49bfe606c.png',
    id: '6246',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01d1290c399dfd5a4d1cb98d2c3b5382d74e869d10eecd6d59bfae0d623da016.png',
    id: '6247',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0f5958ec97277e9fe086de2663a07d01ea0c87df3442001bc303f782ad7c9b2.png',
    id: '6248',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/362adf2ca43a0d354d8c13f87876a9499f1e04030f946420963b39c182f5ca8e.png',
    id: '6249',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/476455648b93fcc1fb616499fcb48a2d1e3e224abaddbf3ae6e3a1f18d66aa1a.png',
    id: '6250',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d91e803ea2353fd9813ea10e888ebbefb8d480bc61a17d4d23174b99eff30aa.png',
    id: '6251',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6bab98e1a5f87ef5e6a72d0043ac619dc91d7d8ca66bb30cd109ec417acdc01.png',
    id: '6252',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/145adfd6aaa43a954eaa17a86496a566dce37f0bc6e4beb3d969f6d41462e959.png',
    id: '6253',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35c0be41f768e67a0ecec0e829823ba81290c27f5f534d17e98fa6d22da691e1.png',
    id: '6254',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e539467876963a4033b48d3eb8e43152851c83de4e04aec9d67e2efc02a5b0b1.png',
    id: '6255',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89c355923ff1483ce48835e169f3552b9ac74308e9ef57bdc07fb1b4717869ed.png',
    id: '6256',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4b70f13c4925e1e8c065b565bf30b4a5a33cb44207602347578571259690d6f.png',
    id: '6257',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9eb852cec0ea76473294404a4ec29f67e625c47562c5f92ca9ce18ee0e1c33f1.png',
    id: '6258',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/659c99df79c5acc02aa55d642f8a68dfe2055971aa4407d1c681c930a482114d.png',
    id: '6259',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ad1cf57ef861ce5fb72e82a09390fb61ecb4cbe0e1febc08e8f68968179e957.png',
    id: '6260',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cb432d3212617f77590baa277cbbf2ccb7415ace9f76388ccf2ed443454c29e.png',
    id: '6261',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c65264d9f39e04da2ccaa209c1a7dfd5143a9ebaa2282457c24362c36bab2f39.png',
    id: '6262',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/485cb9a81d124877500b65b9393fd1dcdec6a24d634016c3c525032035fa5d68.png',
    id: '6263',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/601f8eea29ac1e6c5d43b9ef496ba27475d091930c23bd32613c415c218069e8.png',
    id: '6264',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/338077d0c49b9ceb1d36077394b9966052765d6af152d81d7e1db0edb6c5b7ee.png',
    id: '6265',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63cc2b21adc7e939a36812458b25b0e1f6f9eb0c7ff35ecf6f14211b51097550.png',
    id: '6266',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0784d62f764df71a150963cbc2aec89feb0b620a3fac3e1c3434461918b5f1b3.png',
    id: '6267',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c540c931eb68ecdf8dfaa1a1143713df8f4f143bcbff63d00581590c2d5f04a8.png',
    id: '6268',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4cfae66ab56af6b4277a40939e7a16bab8d31309a56f7ec6d078a9f43616f45b.png',
    id: '6269',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d5338536be9ec71c781757a190055ee687d65a8fd9df604cfc0091dbeb913e4.png',
    id: '6270',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9da88b72d612afcf7c2c23a6d48a7a500de79d7d996fbfb0ab8173bacfff68a2.png',
    id: '6271',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d4e84f5df251c71e9e27fcc7fea5b46cd856a8bb507ead3e5d3ab0082f5e458.png',
    id: '6272',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff6d03a7f747688748c4654dab53de331fe204da456b697369b288da92faed30.png',
    id: '6273',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56fb5d5f30030200638c635de61c438e2940710cb226713189cad56a974bb0d3.png',
    id: '6274',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbf31d7825e84904cc0d46da470cbf932db17b82924a9393415de0e3a3527dcd.png',
    id: '6275',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30ddb82c036a9d7d0c59e013034b1b7795890e139bf819cf41a396ac8abf6529.png',
    id: '6276',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82968a9ee6a17f9b08310ece6217a1bbbe72bb23e874cfea95366589aae89004.png',
    id: '6277',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2529983d7ea80c814f280524476911a330c8a289769143c724658a47375653c8.png',
    id: '6278',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6bd5de4984fc8967481b1459a078572fd1873716816f27dcea720eadfbdf891.png',
    id: '6279',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da992735e7410ac59252605ec1d256dbf02180a96d0ff8583aa54a4de935e828.png',
    id: '6280',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b80f755561b3140c828cea004535dbcbcfd507b468736c0df12027daac615578.png',
    id: '6281',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38a726fc56ffbfe9732aeb805ac0127b7ea65dff86e083acaf7f9af759aeecf7.png',
    id: '6282',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ff1a27b3f611e907b9fec1591e208a23e58ace58b22759a74947051702873ff.png',
    id: '6283',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cee25f4d397d0ba6563cdbb1a23931fe978b3b1ef46901d5ddbf59961b2c26ae.png',
    id: '6284',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f885954631a1d67cab8301dc3b07a4deed38ab88b030e55eeaab01d214f44471.png',
    id: '6285',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Batty Spiked',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cd6b12d62fc95e71a010efa365f1974f05e89f7556767b98a0edc927f369fbe.png',
    id: '6286',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c883d50acb2541c3f7b7cc57f254bf9baba3aaa5c79211fc49c082bc8fdefbc7.png',
    id: '6287',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Blade of Great Heights',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/095d2633d87fd01420e9513b29bcf2a896d6d4d88f3b02efb9af5fa9d09c7aa5.png',
    id: '6288',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96b5d3c26169c64830937eb10e5b969966c7635a2955b5a28362095e372f5df8.png',
    id: '6289',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2dfe77e87b2ed1ad5cbf909d3931ae7a408f000eecc4111336e6e3d77204b9f5.png',
    id: '6290',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db828c44d8dc9d89716737346722643f75eb011ba19f8d296be6490fd69d62fe.png',
    id: '6291',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/333140361c78500ed65a421fe2cd4f7ea52e490711dfa3888e11e26d89123a90.png',
    id: '6292',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4020d92eca5b04f98bdefc6c675109fb7d718668c6a7f25bc50b15fb60b77203.png',
    id: '6293',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78ebf891867fc4f88be2136a999d66137598362f7cf838bf81ea73f4475ef5a2.png',
    id: '6294',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/939c3145d1909bcf5cddcc069f1886d0e74b3ef8cf8a94b1d049270a6dc174ce.png',
    id: '6295',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2589e11eedf68ed3b820283e01bc8cccd39c44e3f2b587220764d75a3d06368d.png',
    id: '6296',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0be2adea3b21038238f8a249dae182aab1ee1b96b39de06739bebdad055c80d8.png',
    id: '6297',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cab5c87db22297b8402893f85bed854ae5dbf630d55820fe00ac4ddb73f892b.png',
    id: '6298',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/370531a9706a796e0541c0b490d53d5019bbad223da0dfaba63fa049578a34e0.png',
    id: '6299',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/324e8fa10d25f6213fe531085a87d7bf33bee90dcbf104c64a9d0c3b79cbdfe7.png',
    id: '6300',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd3c375c5b7f75b3c9148036ff6726a09bc787c9be1b1d7df387b6c6cc938b31.png',
    id: '6301',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecae82308348957749d234395384a631e411e8a437d321edb7eb657570b5f590.png',
    id: '6302',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0bceb3e382f37b95f39d2d50792ee7f2a3c9204e176089755b940f947867bd6.png',
    id: '6303',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98c162be07471410ca84df81c2263fd2a6768365128bd2194edb1871910ead81.png',
    id: '6304',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98216bccb321e818db0c25324c8dc8e6dc4db74783973c7ffef5baad0ed3a625.png',
    id: '6305',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15945f335d47619d5dc8a1476962dcbda21dfd92e6296196214c3eaa11f39c2a.png',
    id: '6306',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fcaca9b57464c09d84e90f1b71801c2ed0100a692f7030427c1e8148459f22e.png',
    id: '6307',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/077429b1251a9de68c1f7041183130d00ee0bc2a5517c121d2727b2312ea0a10.png',
    id: '6308',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7590736428c5abd0fe3d782415353dbb53c3f9f7df1186d9e41f6bcbb20fab9f.png',
    id: '6309',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79c1bfa643959043239783d689e02fdb25d90485dfc01d11c3fc504f7594fab4.png',
    id: '6310',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d419dc5d87057e8474470f74ffbaed853c427eb900d57d1a5cd31edfb90501d0.png',
    id: '6311',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3940cf84112ae38b5c8b42c33ecdfc8c768276c78189d5c72880d83e0f89c49.png',
    id: '6312',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/291bf0f9d3c3152280e9fa9f47db6ab079c65790d151416cd84bef606307e3fe.png',
    id: '6313',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b09a3c4ab98c3baa11352bc35a9c24eaa21d969100fcfc09152d305f76a76e8.png',
    id: '6314',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c34b300eb0c7e6a3befc3e1faf66e42832612537d0ce1a3a677ed1efab2ca3f1.png',
    id: '6315',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a64f206bb1e55e146478e9827403861ae07c60f3a7d05e967b6e7fc9ee1919f.png',
    id: '6316',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b3e2897a5b84d1170e14f146f0a3a13668de3f0b3cb51983db65567fd7a8cb9c.png',
    id: '6317',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Claw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/078cbc3e2cd52c1959a7d41152d6d58b83c481456905201b444498b3c4ec1604.png',
    id: '6318',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ede695c96e5a593ba6960afb61c6734eeb34bec713de76a8f473eda8fd0928e6.png',
    id: '6319',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b9f31ce41f5cfefe8723e2717c3c963c61acdb38ed11247b7c2fc314d3728e9.png',
    id: '6320',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97d977b440510eb35fdb8835cdd759ea5fdfe476f63f59adbd938165047c3e3b.png',
    id: '6321',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3afc315bdcefa77116fbd582c6f033ea59031506fba7f5276c2cba960808c94a.png',
    id: '6322',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51b0f0b98712d6e52ad4c5fdaf7a87457ba7b6976e3d89b3b62f6101b6ccd404.png',
    id: '6323',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fec720b942aaa4aa83afac0002a4d0e0ec00708b835a88efa5442963a8af603.png',
    id: '6324',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c823072f8f3b0a5c5609edf48b352373a7bf1554ac56aafc120819b3e7c10e5.png',
    id: '6325',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d667cada15ad5126bd2bce1bde66310697ec2d415634996f19ad638c56cec5ef.png',
    id: '6326',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/265afe0225a797de2a477caa77bf60c31eada1bdb4fa56a328287d0882019a63.png',
    id: '6327',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7db7cbc8250b686a63586425431491366d167596ebea2788abcd08f3b0b9f84.png',
    id: '6328',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a253aacee557869413a86200958d4eda7121664a74a01018aa910a3d166ad0c6.png',
    id: '6329',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7841ee5080d58a98e9aa6a308e7f0b72f0183c4aa2a4974df072974d02a7d573.png',
    id: '6330',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb94cbe12c346ca0b3e24dbb8104034c65cdbbad5eedf6209241707665a07edc.png',
    id: '6331',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b365f0fcf45cb1fadf9f2453ee4b910436626c87b9cca0107f95de942a1122c.png',
    id: '6332',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5380604ccf0ababd74c1407a428cbfacd0cff76a6c55fd614683f5d5c7c9e107.png',
    id: '6333',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee45d7f69e87217f49596850a3ae1373eb1c854e591624bf0e9bfee55148e7cb.png',
    id: '6334',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5145d89d0297a6fa967fcca6d210f299fb4e11dda5fa7dafa47eb13db8469fc9.png',
    id: '6335',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a01378a8d8d05c14ce6805999166f705155d7da3d032c966a8a4cc6d7cec9f2.png',
    id: '6336',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ac36f204b82839af1939cad81651dac2e5e6a82450cc05f7803a49ee06d7da4.png',
    id: '6337',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1fc4c3d555cb3730efe73219aef46b8e7145c7f7e5941f781e833e8e96346f18.png',
    id: '6338',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/337d5e83ca375decff21205bc12fdbf4ee5c8dbcaa9ed5eb3cfc65e16816b2a6.png',
    id: '6339',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/741c7ec0dd25e446bd9a86d16876d687162bb0f5f1cc9bf01d969e3d98518821.png',
    id: '6340',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8188ca48108f6e4dfa0643f427fc49f99ce6cf5635371f2d5c75b37fd321c294.png',
    id: '6341',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1c527f4325fa4c44487cf909e33534dac189c961c6de3fd94261231793af21f.png',
    id: '6342',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a26481305a5e9209056e3bcfdd6ecb5ff1585316da89cae409f3930ad4241120.png',
    id: '6343',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/891f900d6a22b4f5e1fdbbd3103542b912a10f42d9e041bdd523ec408b2c8f26.png',
    id: '6344',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/699a3822bdb06aacfae9fbb01c519744b6f72338d4cdb308168e30fed344d95f.png',
    id: '6345',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f443ea99ada643b1765f8c72f3db7f40420893fbe2d0061e36614c1e7ecfdbd7.png',
    id: '6346',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3523bd25ca4632425f36659f3de3075d7451f9145a158f85dcd61a2c388a4d04.png',
    id: '6347',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea39cc40c5339cd125b7eba088065156c6843873e12aaffe28f321d9a008ea13.png',
    id: '6348',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e299ecb19d111fdae5ba446ba725be48ab88af2cbb7a0a6d401a505549b3352.png',
    id: '6349',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b01c73c96cae02a5f4f93522879ea20008d4b63aab09b7b105fe88a25e672c40.png',
    id: '6350',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17874596d276a6255987d80c5471418c10ad988fb6bd53bd675507b82ed68f1c.png',
    id: '6351',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/201fb8d2298737540f4353041bddaae78fe67a79f69b1e9a3ea16b343d555865.png',
    id: '6352',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c19888196d0a285ecd20024edea7c1064fd05536e643d0426a75b579264cf1d.png',
    id: '6353',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'Shadowed Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a21fb734ca5e10107f01f0050ea06bb30cd336b14b631689c25e6517937113d8.png',
    id: '6354',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2c52a09dce43154d5da93181b6c46108f81850191d44e78fbd0a5106dba1d41.png',
    id: '6355',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/817dc02bb1173d1c22dda921c9fffaa5d1a5cf2fa6f90327be1f33d56ac93e4f.png',
    id: '6356',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83a248a6ec8c47954b5e5096015f5696ce8a46b76b36f72505d705ac7b0f84af.png',
    id: '6357',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3219b37482732bb927f9af0b956f673e58ccb13ab968c759e2003505cc2aeb9.png',
    id: '6358',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14302181115184243b7ad6c10a7826d09794b18c9d4d2319032ef9f59c6c36a6.png',
    id: '6359',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d362d5f1887e58705411943886329fb7b5be98f431e5c63dec686b43f6fae1e1.png',
    id: '6360',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0fbc2e734e1fbf09fb437bdb67137a4f4a14757470fc78a08e9f93a33328923.png',
    id: '6361',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afecb1c8291dbcba32ae080a3aa7b761d4a7eeb03e9695adffc40afe7d8f4ed4.png',
    id: '6362',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef11c45f2d31911300d6aec038ff39f721a7cc5395eb1805b7a9a83bb505b571.png',
    id: '6363',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c95712d22816481c55bf31522c8a32ada2e081818e9d47622dace69ba82846a.png',
    id: '6364',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4597782aaa1aad4bfbac7b38d7481457a3c447eb94933ddf44b22ffda020c45.png',
    id: '6365',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f03f75bdfa6613ba7217c6a68e30847e83abbed0e6aa928a1b0e4868da3620d0.png',
    id: '6366',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/942a0d4892c9d28f9243dc6d90862c110b7101bc12b8267d6aeed526ca3d813f.png',
    id: '6367',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c9d414947c2e6d2ae47432739801ffba65464c3e7b668bb1757fe57252b9544.png',
    id: '6368',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07d9f9d44d73f895da1f5fecd6ed890cbb749c63ae0fdba88f230c4bb296fe3d.png',
    id: '6369',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/428e56de2838103fb33b07d41c3cf8b50f31cda5c30519a0efa73c6bc0a256d7.png',
    id: '6370',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/964f28a4c7e258d0e6969421c2c83d80561d56817a525845ba0dca9b9ee19900.png',
    id: '6371',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de27ba10ed16983a0c6b4776475597443d1bc796be7f65a3805c01497534b0e7.png',
    id: '6372',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21d3f12896362d4c9bd112fd0e83085bedbfdb6032b77110b48190b42a41810f.png',
    id: '6373',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3153dda3af708a10e5f9f6963584b6ae46b974210d43738199f3640e4af19082.png',
    id: '6374',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/415f1c461532243b224fe15112475ff93e724f9f3dd2171e2b6b7017a1f97728.png',
    id: '6375',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f91684946100368fbec067cf243b04f11941411a752e48aed79768dfa51a63d2.png',
    id: '6376',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e71330b04b10796338e2ae148964a070575ad14f058d5dfc05783943171c3a6.png',
    id: '6377',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e20158a4d1de5322f274c5d3c10cd6dc0f1cc49da5c1babec70a06cff536aa7d.png',
    id: '6378',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb4772a8e14f5f75e5dc16e6906c013d24451716a674d8b25c04aae17aa631dc.png',
    id: '6379',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62b48529f27fe6991e530ffd8eee8c9bb4f3993e9eb03b189b9688dc5aa10b61.png',
    id: '6380',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88e975582465ed0a59b9795020ab1c292e67c040f880a934d69cedfddf933341.png',
    id: '6381',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/229ab79f0bfd0e08ae61a759767280d1826afe45ebfee0b73d722c91212b5939.png',
    id: '6382',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd487679de5ec17409fbd706622606677a6c2c0b226952bc59ab8f19a3ea3d20.png',
    id: '6383',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0d0007f92b0a3e72d5eb2566defb33ae96549f32c4e8fbae9550c379db7893c.png',
    id: '6384',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6684429b1706d10a12989496b1e1ade210ebdf639746a247c366e99e2104b0c.png',
    id: '6385',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ae996aa6808aa31b3f7826288861caccdc3b1546344d0447e2ce0488538c24e.png',
    id: '6386',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d87da6b0e52f23970e4fcfb0831975ede13cf75a883fadfcab65bd9b52d785ed.png',
    id: '6387',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d8a25cef825c50ceee64001184c8c344f83d01ceaf8da550319b2d178016f63.png',
    id: '6388',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b575f36740fd42d7611c9aac0aa83f8ab9839eb0bcb70f3f3048e488cbce2b2.png',
    id: '6389',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c347431809c7a81f74c6623f1198fea6a6e04c8ce9bf452c9d6acd2bb1841947.png',
    id: '6390',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04794c0c1609f253fc9628c936d41552551a4f0db0c99bafbc8120dddd99b0ed.png',
    id: '6391',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/506ccf7c3182c8c6b1f9ec9515190410dc154361cef54523c7924d0d9ac07834.png',
    id: '6392',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/645553cb1889dc9b357150c0bc86c0582c63ae678e2b7754ea7e9cd4bea1bd72.png',
    id: '6393',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/707751c3e862699480d49098e7fa8f4b7631fb2b6b01374765517c62e2cc0b7e.png',
    id: '6394',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/252d39306c9a567d40262504986d354b25ed97ee54fd72cc31dbe07819b98ce8.png',
    id: '6395',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6745724b41e7df81497bd4184798f0d9de9f3c59592030ea80c52448c6b7f9bc.png',
    id: '6396',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1bb70fbdf8597506d27f4be4a4a9fb455fb59d200d7b78b666b14833eaf7092f.png',
    id: '6397',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31467c15735986bd0d6c8f67d9e0d5bc838372cc434ef7fb7de81deaadc5fee6.png',
    id: '6398',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c74bd4ec595c914ba022a25799eef3be4f3ffffdc7216a624bf53419a4b4a9a3.png',
    id: '6399',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5973a13c956b796a02d87b939d53282f09a5049a5f3ace60129d2132464b288b.png',
    id: '6400',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d3a53a1d56b8988b038123dd3e0d95d3017cd1126b5efb29f4aa67c4abf6929.png',
    id: '6401',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4881792c21862e0d5c67ab84dfb754842453809b526d6b5c684b1e9342b12e3b.png',
    id: '6402',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ffcc3afbfc82e042d475b4abaf7b55741e9a3131d71ecda434fc44eeee7e180.png',
    id: '6403',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/535ba54fc1aaa7603d9e92ea41d09e20c1306b04f543f3d7e896216f086d6dec.png',
    id: '6404',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fae48d0c5bf385d2f6c74a91d7b5aed05ffc8d98086d58299be3d30091fc9673.png',
    id: '6405',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ce6020424c3bdb3787c74281bad8a370e16d12435f1f9fd2f7c209e97d8f58a.png',
    id: '6406',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1fc5c3b362097d2becbd80ac158b8e18a74100664cb1861ab790e2b72378e209.png',
    id: '6407',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9452276eca92ab852b67e5d122ba3db89c7c209f065a6102c7a1ebcc82e2d813.png',
    id: '6408',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24bf947c0afdd14cac886f57491ccc85559591f02d265ac38163f7a6763931f9.png',
    id: '6409',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10534295f697edfa4aa15f9731c578bf80377b312aa92ff728d2a2f920383466.png',
    id: '6410',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd1862b3a8c9eb1ff7a94b59f9ac8fe7b08d617030eed627063f331babf68fc0.png',
    id: '6411',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05d0628a5dc4b5c031f4fae177e94e848ab7216f27c295550bbd348ee9db7ad2.png',
    id: '6412',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f45fa4abf12bfdd00a3cddddb5be11fbb1217b1181a42f3a1c36a5dea621fbe6.png',
    id: '6413',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dd27e9f7b7d00849df0f611cf6dddf0eb15e39abf78e4d3432718de23a9cb1f.png',
    id: '6414',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea61d55c2090777817e090d2dc2483596f6b89c70ab5a84423838b436c400fcc.png',
    id: '6415',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73f67560e0db37bdf5b9809f148098c89851ecc0b14e195939dbffa3c6d853fa.png',
    id: '6416',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/437fdfe416a1117b88cb45a057c5d595007ecba914a48d53068161b6b41c2b1a.png',
    id: '6417',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3225b5ad766728ba67b317d5be84006a2b9441bccb696e9bc10e4f5aaa63cdb0.png',
    id: '6418',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff019053d6b32b3b92d2ced723b2bd409f01428e04fb199f9fe63465fcc8ea05.png',
    id: '6419',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30dafb6c4dbf7d44efe8d10640cf45ad6d313c3b03e66a5183f009a85e117957.png',
    id: '6420',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab2a666bbf602e01565c6347251f63b21522ce7143d111f45c75061db45be8e8.png',
    id: '6421',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7cf66a58dff088b4ece83212b810edc334f7cbf87f2e047113028dfa438f179.png',
    id: '6422',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb69ebf0d84bfb4acb1876071833f3e2a881dc9aa7fde3a69e63a31f8d54b935.png',
    id: '6423',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38f670db57535abb86a28c695c823f6aa5896f8c46f99a1f82c3c4ae1cc17981.png',
    id: '6424',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b1bb227cbb08b251c39133b472e12e49d25ffbbe5db9dcbac6fedd33f4d6f08.png',
    id: '6425',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08856012cf5a2efc1eed6d05e6fbcfbe7a971ee63b9d9ef29950f66c06d4c122.png',
    id: '6426',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8dbc2e6de9aa7603167bd011c12bcdec76a24340cc648581f2b28f39f6875d7e.png',
    id: '6427',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d8c720843be68f70e77761aeda48df0af77f5a5535cdfe34bb55eb2c5127ca6.png',
    id: '6428',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbecc94cb439bfcaf1487a0c27eaecf0e5cefc71e34abe299f410609a895913f.png',
    id: '6429',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7f592ff948cc8247e6c71cc9ad7d36afd726d9ba8058e6d84381db78a50d370.png',
    id: '6430',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68b274eae1e82f932c8c64207d9a14eeb6784c00f5ed0fdff918300c4fcc6ba3.png',
    id: '6431',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9dce556fa7b94e32c70ec98638beb8c7cbccb40135ba259e7a5d031ce87968f1.png',
    id: '6432',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a631f129c9bb73fc40e379cfba3c0adec171085a59c2c94546987fad3ec67bfd.png',
    id: '6433',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b26283a780c79a8a16783fa8a0ed0759313a727e521031167d7fec0a8adb692.png',
    id: '6434',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7534ce224b2de5793cd1f2e49a02c6473637d0491bf5e8c91ad20981556f731d.png',
    id: '6435',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5cd0b4b0cf78d204ffdb648be6b66e8f85ed9883f831e0c31f941b4656b8a230.png',
    id: '6436',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37b4bc3131e19c7ab842eeef3bc7132018cf860a803602559c5b8d29cd6c98a8.png',
    id: '6437',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cb8ea3d56b1898410d83f34d54e771d9180389379c103951775a42c6b8a79bc.png',
    id: '6438',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f99f9c138c6e9afa12386c786990900bd260b2e7c217571fbb5b31606a280868.png',
    id: '6439',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b58e5c9234abe8b315195d32d0710570fb091249dd311a24a6ec9af4bf2e36f.png',
    id: '6440',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aee76c5d4977c10d74d7d6fc60cef8ddb290beb488d9e4efb398ec34894ac286.png',
    id: '6441',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd47cee3390f58994f5fa14db8c047f701313d276e862626a5bc706b2c9c3582.png',
    id: '6442',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5546aafacf026382cfffadd85acde078652160ddeae9a2db97d06ee89c9118a7.png',
    id: '6443',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/365de2f5b9a083db39e7e9a9901e598b5db31454037581f83bf030db97bfb07d.png',
    id: '6444',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34dff1d1215bacf2423913d8a2338fcba42cee265e2a3f745988345f05ec050f.png',
    id: '6445',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87094f61d720a47661db28ab44f0c5e05e413a87137d467e498e0abb97f756d3.png',
    id: '6446',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dfa4b6d51ffa4803b9908c23289e41853902cb9950493b72df68d2bb7ba8b820.png',
    id: '6447',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36da5f84403ec13d86baec03697545eed4c7d6cc835a9a320451e854b8253c33.png',
    id: '6448',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ffe5a2872710999d77942d9d3ead04cb0fa09437ca45b098d161823407eb6f2.png',
    id: '6449',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4892193e9f57567b3edb52bb68194093c423898774cdeda37b593f4a1b4eae63.png',
    id: '6450',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f86659f1b7f02c7f8eddee51119afd397e427fd59a2e5f3f127677c4fa6f120.png',
    id: '6451',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/685b5625156dfddf327658be123f3c9cce477773793e6759bdbf72c5dbea5652.png',
    id: '6452',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c4951b88bbb2fd9e01a815157d87031f75a3eff0a533dce2984322f6a5568eb.png',
    id: '6453',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d534f61d42eb94cfd1fca595d5456d5ff85980618da3ccfaa195f1984d34a0ab.png',
    id: '6454',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c88b114a5f925c5baa441f9e440729c24f17a6b734d368be59264ddcf32b996.png',
    id: '6455',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c81a973f3da7ec72fe62268a863db39fd44708f849c8db789c397a0c51ae047e.png',
    id: '6456',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Explored Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf986e4430165bdc316d665e7ec04f770ebcec701107546858ac251a835a4d8f.png',
    id: '6457',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skully',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1273664864379ca8d1dfecc8f94f3a9473375522ea11f6bc0855ec01d0f402a8.png',
    id: '6458',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48374014bb1c961ded70123572a62e2a5637cfa0d68c0843fe88746395edfc61.png',
    id: '6459',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a316198769c95678a57b08c0e5a5f24183ce4e3f6b3a0ffbc20a80321cf40f0c.png',
    id: '6460',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2b526ca026203f9e336687e78e2763125dcc88004c9fa02fb7f0347c26ad9ed.png',
    id: '6461',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31f1fcf1bf6c9d85650ec66ef678be4523c2dff8e3547296d9c797a5de2d2541.png',
    id: '6462',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f84fa4983eae5c1f33e3c4b2bc9896b2b1ecda82b51a18ef8f308874bd89d5b.png',
    id: '6463',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Lights Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5134c971c5898d6dbbcf1d2beb8b290006a4e5257fa9c1f909df686a757d2fb7.png',
    id: '6464',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99108779ed4818d7e9bab81d5171e68b099cfbfcc506e87c8fd455c85406e8c3.png',
    id: '6465',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1873f14d6012b389a447c1a8a972b8a29f0e269ab925be64fda322a75839b46a.png',
    id: '6466',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/466eae3b5d6fca8e3d894cb280ad283625949695ff1b01bdf8f13586a0dee9f8.png',
    id: '6467',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f845881bd71cf2eb99a977a9355eb2f04891414db8bf97f22ac988768b7959b.png',
    id: '6468',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9611f5586fb179ab8426c9bb91c5f1fdc001e43b3d9d29fe2641d438eaf3a46.png',
    id: '6469',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9161aa0b2b9b49b4031228678a1bccde2aca34fa6422aa9303873cfb90c7f846.png',
    id: '6470',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab0b151a22ae1941dc6fc797b3f23b51c351093beb9b75ba4bc82c1c2bdb3a16.png',
    id: '6471',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e07ebf3c84c52b009e04ccb2a99c5d414da7de29a3a5b4e26cf0e679c536e80.png',
    id: '6472',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f0c899fbb747776156f1960e9b16a12d189dae636534333339d892c51e2b4dc.png',
    id: '6473',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c89a32db19fe08fdff53dac957e756aa2080701444eae7363e6885f12651b27.png',
    id: '6474',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5c60cd7935375250aaa6993395e1022519b890cd8991bb2cd2917421242f6c7.png',
    id: '6475',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57c860f1e19a08d69e7f7ca957927e3510da1e0dcfd52fab0366a8007cd5f443.png',
    id: '6476',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8ae4e5134da61a7edd10d95a8bd8ae3c480be5c096f591b3902844cb4a3af33.png',
    id: '6477',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b40e798f874cd670eeb2179ec9c6b7ef771c38eda8cd49e620f7ee590dbb8d84.png',
    id: '6478',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3952428348f6f380945c6f6f54fc564004365bd5d5320e6db25c37ed2b4aaa8.png',
    id: '6479',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/739092a07b5fc8ccc9f3a938bd7c475ad1e723578a5f74c792a2df2cdb21100d.png',
    id: '6480',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/859b734d56858c1fb23927b8f4685d99237218bca9a1b16e33490713faf4b890.png',
    id: '6481',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72d02335e6aa28bd2bf6ccb3d8fa5d8dd32cf7ae3db6e271a6642d976a469142.png',
    id: '6482',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/612d3798e0db1b0b41d9e28f2be608a00dd1922ee3641c4d0bc1498b776285be.png',
    id: '6483',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae9ce441be961ea163b642474c966a6940cca4cf6be118fe3fc3f50e1d3befe6.png',
    id: '6484',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1249c68a4dff8993bd44cc2b0c2a6d1e2617bbb63db25917e7117f24ff167596.png',
    id: '6485',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/caff0485aa4a553c1c775a0057c033068d7ce134b80146db5ee36c71500a5ee4.png',
    id: '6486',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a86285afdf3ece3a3134f9f0a26cecc7eed1c447d99a9fdccf12a219773b0fca.png',
    id: '6487',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b201498e479ca491107e28db910ac1dbb4a13d80204bc189a1cb718484eacda.png',
    id: '6488',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6b82d43788dfaabb083609267bc547f1997c9691c0ad55eef998899c6dc4760.png',
    id: '6489',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/905df01115d4b04a5a662b8e7a611e0c61103d3770fa67390384ef298d08f5f7.png',
    id: '6490',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5bc3727989920714243185c01756171e097374744ee58833d1a8489997b8565.png',
    id: '6491',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92e06b12533aac5130b9f0228d0c44f6777d6260e5c23a884e61e5b581d59539.png',
    id: '6492',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98532d9d418be4b2f7342b61556f3f268ed62bbe4529dbdedbc2dff1146c68cc.png',
    id: '6493',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ccaf91e60fcae9baa21b656801286801220567c2e20aa7d765c9506d337877e9.png',
    id: '6494',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54cb4137e6b750867bd7e2fff545da8aedb015356d3a01edc6df0f0674bec7a7.png',
    id: '6495',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67233e56d86002aed370ed42c97a7a9a5109e69ebc627dfcd52bb8839f4123f2.png',
    id: '6496',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20fb150a590dad867fed76f570d7008c66f66c9d196cf675cecf1efa78f85a28.png',
    id: '6497',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b391db76ee413c46701fc4ba474a926462007763338332d92c70c288ce3adfac.png',
    id: '6498',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40b4edac9eb96df74ab11140a91b373dd3b5d7151e99a4ce48070be5d151b208.png',
    id: '6499',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66db50b997483b7230c02121b59b52849dfc2e4d29602baee59e0a794ee08d6c.png',
    id: '6500',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bf671de05fe11451e0d87d1be95fb2349d4d1ce939a4419e8cc807b86e9783f.png',
    id: '6501',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14bcd4c9304f2e056671cd6dec205583e9c582a0ab90ae5f074cc500608f5c37.png',
    id: '6502',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b2c8e138487d174189b65b9f62b233bfa721f270c45c0d2318afabc08bed308.png',
    id: '6503',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1ee7dcc946889c23b1cd239c106ab9d6bd5de43aae19d2b47914c11badf5d6e.png',
    id: '6504',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8a6c204a36859f2b76aca7f07b7425a235025f3a6c2623f28f2e3d190ad3a96.png',
    id: '6505',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3e1a86a5bec9c2c6ea1274612470c749ce907bb8a6757e137994421c882f1d3.png',
    id: '6506',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1fb3afa88f445d47c038967383aa389692d9b2df98277a97c431c665790c516.png',
    id: '6507',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/000460b03d8770ca5e55fc591d1afbcb64f3201c68ccad5c26fe9487c0d1edc3.png',
    id: '6508',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71b486de39a2858b96de36d2637c99a7aa70482d9e3f7bb4c24a2ab25e2d8dfd.png',
    id: '6509',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/364f8a5d8df26aa4be0dbc27e12794cb1303bf487bb014ffc3ac52f7eeec1a81.png',
    id: '6510',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bafbaf94d3b5298ac40c95f12e2c9b5c7c51f9ea778d6a91cf27b9015a45b4f7.png',
    id: '6511',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e25861d7bae7686d6e4a13f584567fdc1bfbac996812b53eaee5b1f6ce33e6e.png',
    id: '6512',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc273dcdb0a9baa39767431c3f4cbb935bf563c8b5c1613995051df0960d0789.png',
    id: '6513',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09f23e9900c2bcd3cfc66fdb87e6e8f25aece698ed5ee7f4bb840b9a98916957.png',
    id: '6514',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94799458107f5a2404b79a922d318af590c21152e9fb9cb40b2450d2a80634b3.png',
    id: '6515',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70768c7faa41e92091b1f43dff4a0dd2a0360735727b6facc3f0d1ab035088ff.png',
    id: '6516',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7336b24cc4929fc4c8d1d356c1a0dd1bc8d6c7b234c1ed32c6ccb06340d95301.png',
    id: '6517',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c4459e5b30e63ff1d96b420311c4fd303989bf30eb4e75f289260e3c6cfc19a.png',
    id: '6518',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c333bdcb82a1297d3a3514fd070236401a5c469e9c37c371dfc28d4b2a47e52f.png',
    id: '6519',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6afa373ce4f9df48823065bbe6e632a7e0fc4c8f4b383a593abc0732d972da81.png',
    id: '6520',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01a9361dbbccce6a609c08a60b0b64f106673c92c9ef2cc18d46e0dd958ed727.png',
    id: '6521',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c88420c7feb5bc6cef1b929f9d51e8ac3259a6307553992ee60f8f389312a1a.png',
    id: '6522',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c76f47237fcbc6384a7bed43015039c177aa6abbaff564eb2c77efe3e25d73d.png',
    id: '6523',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cde9d9083df2d1d975bbf81dc37fa55cad232c60ec9d645c51260b0821a15557.png',
    id: '6524',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f2de0b638bfedadf788f9dc3c5d1a1f6d3ae59575314cc8a0ddf0b5f635138d.png',
    id: '6525',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b90ef5f64ed3c3e38a51e3392d89162f959af9ab6341a00e2d0c180d7091ff3.png',
    id: '6526',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9317b130669f4f89b1de4f370cfec9f1e10c856ae4703c90b0f1b3b1967b07d.png',
    id: '6527',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ed2f62bca783c8f66761260d268c52416710340b439611696465d84740e7ee7.png',
    id: '6528',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96a3da34191b4243b285fb62fc2bb614e6cae2ab4255ea833bd827d2e779a4c9.png',
    id: '6529',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee5a7ee3a00ffe8eae4bf144f598206b36affde4437f149c459e4d2759434241.png',
    id: '6530',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7e1c90145a589aaa15aa77e91039f45a558b9ab0b21107a013ea012e6b2c95d.png',
    id: '6531',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/447e534ba65f8ff686b437cf664b2bac15a7edcb1b8f85fb98a1c84d3367655c.png',
    id: '6532',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4bc17e1e7b25f796e15450f19402f4f34700563a872078f51ca22b98c93fe2e0.png',
    id: '6533',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a90ea64478f39bf5bd13c3c769cb68cadd5b967ad89ae2f416b5ac05bdf5312.png',
    id: '6534',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/766e038f7801bcafac5aa4d6514bec29606a09437adc0f36c8b1103120f5d62f.png',
    id: '6535',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b52b5d496917bec0381e82d08616bb7150f24fa1ac5af3196af2378b66b88f84.png',
    id: '6536',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6c316abfef30d40007891c1112a349b2f713b4b4b9608922e5b1a48d8eb58c3.png',
    id: '6537',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2095022114354d9650bb43bc890043da48827ea4203f5ddbf4ecaf238b9e1170.png',
    id: '6538',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21a97fce2bac52c43d83c15150ac9ac4ca5368f12246c0791d4f6504ec4055d4.png',
    id: '6539',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44ce53ee29ab37d4ff2d978fc6ebb619b115eaae6d59f1bea6ac3af96a87a199.png',
    id: '6540',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d599a7d0958bd98551f5f170d37c4f5765c75d324006827fbb2354e4bb94182a.png',
    id: '6541',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68dee8f4a9db47c78e2e8d84d746f6cb2c62227b2181467ad3432dc43eba3c11.png',
    id: '6542',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43b152b21eca4dd5dfef5b99c15a80f15a405ff431948909e954390470553497.png',
    id: '6543',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa58f0fee76a6fac3ba15b171e6f6ec4480de4225eed15d9d1fc9cdf3a4405e7.png',
    id: '6544',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a5da928abc8901ee72300a6550c1d1ee371c249edf153e8d59796d8b3f4096b.png',
    id: '6545',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ad85f24a06ad4b28082fdf07d1a12bbb1e187b27fed7afd9816bca2817164da.png',
    id: '6546',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9dd22672949ba1276e09fdee36f3a8fade6dcd959f1888ecaae76419c87cd217.png',
    id: '6547',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89fcb7ef3ef33ca7770c559515f096c201c65c729370e605096963176e386add.png',
    id: '6548',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c90884e3a87caaf2e50466bf7f1238b2b6303bb36aba3ccfccb77a302a0461a.png',
    id: '6549',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f109b51525eebd4eafff035a510877cd4231f874d3ddd97a683d8f4b9be7291.png',
    id: '6550',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ede991aca3c6a39851856a7339408c77178785b0c749fdf364bf51442bd2b3d.png',
    id: '6551',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfeb4c4dd4797e7f7b3b5b4b972474f019943a31249f4d01460d53824068d30c.png',
    id: '6552',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af24e40561531808d96efd8f6e6621bc070461c1cb92d0436f3f83d9ad522e32.png',
    id: '6553',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5067b5e7a8502a55452a50f2fbc98f010bce93cdc990884614a63645228caa64.png',
    id: '6554',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1add17c059f4e85f9fca327785f0512432ad4cb86655a9744d9a4f12a4f3f10a.png',
    id: '6555',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd91c6a3aa4df5f231df352f4c7cab35d43d33df72705e5b3fdcaea755c74acc.png',
    id: '6556',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39cae3c6d2f16e1a5a855dad9c25180bf3cd3b77fcf410d289bc252aa181b35c.png',
    id: '6557',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b61654d01a30e7f26d19e425ca8169c6eaf6faccd81af65d7fe0ce5039a47081.png',
    id: '6558',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1765d8765231f454f61f5773c9fe1e0f7f4abbe2eaf46a701c6da23a4d24b61.png',
    id: '6559',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59c0eabbdf6a486b66d9d5265e65a22a11e97faa5b2308d24b767385d8a28e24.png',
    id: '6560',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1732e628a3c73022bf8c37a432152e34e2db072ca31856924c6c8aa568f35b79.png',
    id: '6561',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/268c5d8cbbb165b9b2aa3bc2109bbd6aa6a2a558057306edf2151e31013b2d51.png',
    id: '6562',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b80b767cd1fbb53e446fa81d82e8bb1050797742a81dcffce2ad0ee1714cb68.png',
    id: '6563',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb4a6b9aff2199892aecb1bf670509c0a831e5994ffc98821bea6a7e64118984.png',
    id: '6564',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0189591ca87b87a5ff993211721df69ddb0237088de3c996286a2caa8715438.png',
    id: '6565',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6dcae23615608efa6598a2aaadd47ab0326e73a686c7c552549fa08f09dad09.png',
    id: '6566',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5913edc5e7a301f71727498af5a0c49b07010cd0bd84fc5912fa05b086db3cd.png',
    id: '6567',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00a57e8d40008c67e3ba7eff56a7656023971a19b890ee914e099c49fb586c67.png',
    id: '6568',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44c7618495a8668e5a9deed66f0a2b089c87510e8c8e6813076bd76e5014cc5c.png',
    id: '6569',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88f9bc38c8f9a0d2d5f16056d88ad58701bd32d8c76e22dad2b663fe5d81b8fd.png',
    id: '6570',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0913ed844bd6d2901a87abadcd39e36d8d3d9d24c4a6024c25d1406469bb701a.png',
    id: '6571',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c318aef143f851b38491468c4f3461834c85c8fc1ca1184f309b97a87a2bc81f.png',
    id: '6572',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bd347dd9c01d72c42875f3efa759e6a31b697f07055a6074efd9ce47bc0349d.png',
    id: '6573',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3c47f69f74e3193ddd4febb3d7874390a04acecb79f38ff43cf2a38eddfa9c3.png',
    id: '6574',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5172f9f0cec2226cd0c92f87c4d9f3c66efc00dd494a502e78dcd8d015b165a.png',
    id: '6575',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b8972f929a1a3c0ea2475129a672ff7141abfd02103904312b6f250b07596b6.png',
    id: '6576',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a6f477640aae2f6bb6ff9bfcd41a13044172e3d4695131e4377f20fb70498f5.png',
    id: '6577',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7561d7de8e28e80846bbe30976d4e562880fbdb9f6a1c517c0f5cb0459b42f34.png',
    id: '6578',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88c911736ad913de1d07350dadea3fb28725c234cb5edce127c4da83d3f6c555.png',
    id: '6579',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ee730dafca35bba5f964c380f9a7d47c55dd04d771022ca9a1beea2d5052852.png',
    id: '6580',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffaeafff86c3fa619f3253bdf8cfe4bfa52ed6dc2c08864ed645b565d0ee88f9.png',
    id: '6581',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1838afb2af157efdf2b85b72bccffa5b542ee19c6f98f884cdf97b035f6c8e55.png',
    id: '6582',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d46fbb14269b966a389c25fb5236f92144ec6925d7ce6988feed6e4b354b072.png',
    id: '6583',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c0b47ec8a3a96960d4537d5a3d3cf76ca60b2c8535aa587eab2364a46d78970.png',
    id: '6584',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Sticky Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f5a20ce77c63615dc51d35394b3a7f1c999a5347010dfa122c480c5ff201570.png',
    id: '6585',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e66a6884cf53d484eb6ffead1f1b2aac9e165eba1c4e7134a758aa5e85791f77.png',
    id: '6586',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2ff0b5c10661ee52103fd6762b787e66b787ba5d9e87a59b358c74d4ce6ff25.png',
    id: '6587',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b838bd63d087820949181e8c567e0f07feec43e3b9b0229470c04ed5faf1cb2d.png',
    id: '6588',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59a230ac50396bfe70fc425f6a7a63b8f2fbb580eab4fa99da710f11cec27f20.png',
    id: '6589',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c22d6e2a257efa88231599827b7acd2edc2045c7446b0e3a90af685d7c960fc.png',
    id: '6590',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f68a4a2347f263b3742dc9579031559b5f09dc17ef2e74f9226239093705ad45.png',
    id: '6591',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6635c81c4a06c935c69e82d1288cceb47926fc9b389d777c36f2bd6b04c242ca.png',
    id: '6592',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/375f5bf4ff2af92cda4fa319bae51a4f23a8ab5d1512a0a9c39ae215583b6ab2.png',
    id: '6593',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52ce4c598449070a411a5b03c2fe512eebc2b122fd4d5f834d8273b697cb7d39.png',
    id: '6594',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab0965ea2c2459c1affd0798df6ca50df15d44fe0fca8e48ea571674356b5839.png',
    id: '6595',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ecda06a536bb953dbd4ecebde7084e7b0c40de2ecba8f5b3c379e8fb228d6e4.png',
    id: '6596',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70dcb9ca5aa2136d0092b002ae6e9c0c01dddec5382560bee7410d30afec350a.png',
    id: '6597',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65b9cd21cca97e3d85e1d4bb741acdb88408f16b591fd2d1ef9f781298f45690.png',
    id: '6598',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8ad7edc89018199c9628c29f3991c376656335e66eb2714a9c30e73982f31bf.png',
    id: '6599',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/156884adaddbad8d5b57f388ff5e5f5b57be5bc70d06002bf7b0f309f3c302c9.png',
    id: '6600',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e17d48d6f9dd1b03d6f14a3f1ee608d5bf53eb2dc352dbeec350f10d631d5d3e.png',
    id: '6601',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/110d504917584040063ee3bb0d7538a25f16fc58430a9dd552ef5b987c2b92cc.png',
    id: '6602',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ee8b3da2bf751db800b8adb2e2a0a5aa2313b6c7c45be400cd1f92ea090f89c.png',
    id: '6603',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6925a834f88fbfb6375b31f69f44aabd980dbd5c7edfa45ba0ab4a9b691f96ac.png',
    id: '6604',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60102142257a7cfb76a88bf50ea3d1c21e75c605edcd639bb2b3b378e7f85068.png',
    id: '6605',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4425c5ba0397814c3d4dd9553bc66b5f4ead18e9e7f6315dbbfc8e2034ed4a7.png',
    id: '6606',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d8c21c9b388ad6b6e916245fac6b262a65cbadf2c4137b2aea57d80e9194b96.png',
    id: '6607',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/461c3e4817cde312f303bb8421314d6beb7badaf29b716d4075f78e893acb45d.png',
    id: '6608',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f518f6421cb024ac0d2d17a29f0b229e87290c39fbc92a8bb43835075d14d120.png',
    id: '6609',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/246d56635a753f4703de4c8e5f8322457c6d9ae335a3eebd1f74371b059f1c0b.png',
    id: '6610',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59e56c0144c09f25f92268dabfa9139b0a0fc57f94788137ce7cbb78721343d7.png',
    id: '6611',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ded2ce2d5755e81bdc622443df0bff8d29e3b3403f155898a20c41677382df7.png',
    id: '6612',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fbfc46f3ce0f4b2b6b7da32f0cdfb8b5921310c02057bf08ac32276d87d1b76.png',
    id: '6613',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83a60f756ae4230a79f161ad55e73d61a27bb58176ac817c3033d41f5ec6cf2e.png',
    id: '6614',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff4139ec66558c8b8aac27a851563f5cf4cc12f1474657b4346e2fa6d9028ff8.png',
    id: '6615',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/076315a63d8613eb23b2e76e3e8317f1114e791d2ecbe81fb56bd0a39487f9c6.png',
    id: '6616',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b505bbe570dbbaf89324b411259c282d301379a55cbbba350d5a28151c3a6fd8.png',
    id: '6617',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27357b0a268858bac9aa5b8f33ec4504efa52eaf151793cee6767bb6796b6c67.png',
    id: '6618',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e647823f7195360eedfbf60a69c245e73c51708d3c711ba98a18af936809c746.png',
    id: '6619',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1af6fae225a37eae9fc8a0e845b26849b4ea5f395739307a9df5dc429ddd8e0f.png',
    id: '6620',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f707e513bcb466e29feffdc5b267fc5971810c8b74c5a80d27a99071f3b9cccb.png',
    id: '6621',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bce9ecf316827691a89b1d51f2376a92ddaee60260cc0e49b301be4977ca39d7.png',
    id: '6622',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b85c665b6b9e2b63bb2407c310fb0ebf31a9f1335e708175eaae8ce836cfec05.png',
    id: '6623',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd33016b182e9537079fed03b3dabc94326ce4c26eb77b85ce3f1ee6410c2456.png',
    id: '6624',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cccadd9a9c0e2fbf2bfe261f812bf649ed29200675ecadb75bc6298ad17ec7e5.png',
    id: '6625',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45f0f1bad9b0ef13a981a5c6fa236546d1b7c699cc2821861dad3e220d045cff.png',
    id: '6626',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3722d9a6c6a1d5953ef7fa2eb286db699e826e56c724b7e0229f920adeb950c1.png',
    id: '6627',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/baf73ac8d9ec7203680a89359d3668cea16638d7a71c64af3f16c78da54fa0ab.png',
    id: '6628',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/786dfa9a93c57868ee40b8bd3a25b18694bb12e2781d062d00402c92c3c9c5b3.png',
    id: '6629',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78789947347b19f003fb98e1a9c0021a503e0d1d9d0950a21e39d7a291734bf1.png',
    id: '6630',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fa9695b1393c249ee336153cb140620757fa24f2e58363292259af499eb6e2d.png',
    id: '6631',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4b5f7d0568f21a7b6ca90636ee177ccbd484adc031438dd7fa565808c52e6fe.png',
    id: '6632',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ca92ae9f997e94dc181d058d16f37685ba2c9013399e2efc34d71e417463f04.png',
    id: '6633',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7d94975df813197d4d3db91a995a8cb50f6fdd8b1eccd276282d11d81091fe9.png',
    id: '6634',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98591d2fd53af23a9f3a4c07bd33a08baa2ed8529137593bcc9dba2bdcbab899.png',
    id: '6635',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d2ed8027d126a330bfa6fb07b739e61ef735754ce481dff128de5b16ac09004.png',
    id: '6636',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/769305547a6b2398030f9ce3dd12f09676a396a4391d3135cb4698d911738773.png',
    id: '6637',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fefd5aa8f113825ee6c12198067cca2db51c56a9a041b42fa0f1bda650814b4.png',
    id: '6638',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/409aadbdefe4ae91b78693dd0846a39969ed4ccc9ffca32664fda0badbff5e27.png',
    id: '6639',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17304524336f6e11bfd70142951dac041b46a307db8aa7f446cae43e7745867d.png',
    id: '6640',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/872d7b0fe476b1a9a8c792a8344ff2ac7f2af128f3955729368ea180fb121529.png',
    id: '6641',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a23e26e6de0c189b9381ca4f1ffb6e4450b061fd8ea5da333c98a768aea40d72.png',
    id: '6642',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cb65775e73bb41c5d0b1e71f779c3f8694ec5ac99158199e557281b129cab0f.png',
    id: '6643',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0ef408d4916e425904253c98b04d98fac92b0ecf713467e7badca6a32de796f.png',
    id: '6644',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e51eb6a842860e9c94a06a77cf74b6864e09167388622231a818e94c67475e54.png',
    id: '6645',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6e5b36986b091603ebb081c9a981ed5ec005b3719a869679741a09cb2201be3.png',
    id: '6646',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71df03f3763393f936606c9219d30a7badedd3efccef220eb12692151bc2e847.png',
    id: '6647',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12be615cbf95b65a9ec970f0a9182676877ccad57c52486875fd706059cb8b5c.png',
    id: '6648',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29d112103565fba40caba2f0410f47d743dce5f7990c4fc693e0082477f462d4.png',
    id: '6649',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bda93e445e4ae2c3cf8f84dee17f4c6ed29edc34866503fc5f70453a90c1ad2b.png',
    id: '6650',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29cd65dd7130aa7e61886de8a3a9551f12281199af701658700cc69047dac7d3.png',
    id: '6651',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/413c7a31b455193abddc5d521ffa6a37141f383ce97f5904830dc1686b1c503f.png',
    id: '6652',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bba50c08496c92d91cb5c02b201f9d567a14d02458c6fb3ccdedf50e96f187e.png',
    id: '6653',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b40c2375d81b30500c1290287a3160b69f74597177b6677828a8e9a18ef95792.png',
    id: '6654',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fac98df669c4c0b2c8cd6aac08acab4b5f74a6269e0f07f3bcc9bf2cb6d36e90.png',
    id: '6655',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1faa4ea718ee3fde86654fbd1bc1a014fba8bffbad9ab4b84c58c68d1bcc21c.png',
    id: '6656',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11e4856c440762030f572b89aadc9ced6f6adc4841c4eae14b08ce9d9df16e19.png',
    id: '6657',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e925149e9147fa1bf37a089487001ab11ca631353f1d15359e8eb2afc35b1b3.png',
    id: '6658',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8c4ea0d10ffbe3a7b7a5a9d0a598f2d483a13912baa11fb905bc956fe7c2134.png',
    id: '6659',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8723d0206c5893d573e64b23e429ed4988dcb478d2fd2a9c5799f304e49573e0.png',
    id: '6660',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eed727341d510bc92d1092c3a30e9b7c95854084e7ef1a29fbbf308662259475.png',
    id: '6661',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f19ce765c6b8d2dbbbd35cc09f6435658e1ee22ef21a259c49b43c446435edd3.png',
    id: '6662',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7bbaebc9c5f7be250e03bd77d5f0375f5191bef2d5ad8ebb7abb104865e5114.png',
    id: '6663',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ravens Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52d67f84a5093c247428f84a20620775514c0cb7ef144e89159d265709d7cd52.png',
    id: '6664',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23bafa2338ad3e767d0a8586415002907a65fdb753ed57b5ec7979b3ce3059ce.png',
    id: '6665',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49b30202ff76349e69143bfa0c8168a2ab99d8297757de64fac9b2cddd369b83.png',
    id: '6666',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ad23b07ad057e1b0198d00ab1aec6234a1888fce443f79c49610a9f569e4daf.png',
    id: '6667',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc07c0cbec8912213f9a095c61cde82dea3bf21690a5a2169b847728390bb3f2.png',
    id: '6668',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c7d093a6e9733d2e28098e7409dac0db7209795fce8592bec3351ccd36ff7a7.png',
    id: '6669',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13c846f65d283d02c034b7df1d5b23b71158b7c1774e559f0675e704ad3403ec.png',
    id: '6670',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa2d860846b5384a17c1e00f8ff9e753878840a819fff0c98f7af5c3f9a1fe6d.png',
    id: '6671',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc3524ae4649077e749fadd8e46ac7f700836aa6d15a73e8754e1e49a0437c32.png',
    id: '6672',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c6dff26207723330dba13aafc8b2c2a638309650479d2e52853af865e3190ef.png',
    id: '6673',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9267ea9ea8a0fc66162b09469aa05d760bffc70a5f884fdee34d64b50ceee81b.png',
    id: '6674',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/977fe81f2bfff7f41c6e8d038eed214ec958cde3ed38e7b4d8f4a4dfac846d48.png',
    id: '6675',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b950eb5d79654e741fa7d140365ffb0504c3953c12ec083e91dc91b174e1c441.png',
    id: '6676',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c4d7e799712feb1b0e8a69c2bf8e959dc60c36393574378018612dcefe864ba.png',
    id: '6677',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76aee68138f0392c68ab028b2c763218d7a6b9be3322b3d0a9b9798f46d73cf3.png',
    id: '6678',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/713dae64977c66bae670c2f593b3bc82de1fa0132c002e21efc811a49848b818.png',
    id: '6679',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cea895d759a1a346cf08583836c1e7b0d2750c7042fceded70dd543d75379ae0.png',
    id: '6680',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/916a20a503826f96b7f5ddfab4e792095a55189f1033e176ebb39474de7b0052.png',
    id: '6681',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e51ea076406f258591394024857d3734740eb9a2344c75acba44e9efe562dcbd.png',
    id: '6682',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c887309f60db215277a12c6a10997164ed4633c0abd1bcb51b8be7adc188011.png',
    id: '6683',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d9f69eaf83ecde2614a1f1a8681df85f62ef05f6f8ffb8e8978598d2b934079.png',
    id: '6684',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25805dc2db4ba2feb6f1b7e08342bf7d71a63c957d40e3ed0c69847965e4363c.png',
    id: '6685',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fccd572a69368df165a6481d6d69f5d32bad6cd95a7a56e0a092306609e4b3ff.png',
    id: '6686',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1c72fff21aded945f2e5f83797693e346ff4707bc1173ec8a2292c298448fa5.png',
    id: '6687',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8873c2f73854c25d5eac3988ebd854486b5398d4fa8de5ec926145b202b8def2.png',
    id: '6688',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b08078ee3988bdcceac8785fa1fcd916251378b81c5cfce77f36cb85ad8553c7.png',
    id: '6689',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83da735d7eed715caaf1ed1d7db51cdcbf9c114a96bf13d12705d80815a654ae.png',
    id: '6690',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bubble Blower',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4aa78f3260b5f64f239f03019ad07f14dc933c1469f536d666050132e0ec5de.png',
    id: '6691',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3397000a471e11f55da6e6523d524e0eed3141bd5ad6be98f2e2b3a2ba110d38.png',
    id: '6692',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e7ac2608488464b71c06545b001dfef071605159619d1b21775d715122fcff0.png',
    id: '6693',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d71f5fb1698680a84d1ab979a251afa4cf4f10675bae5f573c2a3bc737416575.png',
    id: '6694',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b5a69f6a04ce801c78084d75dfc4fe160070f2c1b5e7a238453e54ceae2aeb9.png',
    id: '6695',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86a560b5806079a7a4a4e452ccd290d7c30047397ce6fe4626adb38720f4d6c6.png',
    id: '6696',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97f9b49282395e5f18ced54fde337736c53c49f17e4588917c63d39158f7397b.png',
    id: '6697',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ac0e7453f07e48671ee77c27db606c05c17c78ceb82204b68bc2b5c7da5bc53.png',
    id: '6698',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ff189e97b5823f70b6cea1552ceea7406ecdd33cd4cdf5b2b10a00c2030a9be.png',
    id: '6699',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88fda2762b9a6278668863a6be2002b7b098f6b3a3cff03348b7b530da667f99.png',
    id: '6700',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7eb8f40a17d78e2a14db04df517de7fa1c2cb457e5e3650987559aeeb62101d2.png',
    id: '6701',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41f668875411be17080b181f0c2db5d2de890c4dfc79661c5e3a715dbe3c15b1.png',
    id: '6702',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/047fe654eec715dec9bf4f3d85bd5993c78ee2bdac5b533522d02cd8de4f4a2b.png',
    id: '6703',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1798702210f86311130d49ac845628a2b4852d013fc1e2be1734ca07213497a.png',
    id: '6704',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5115bea15b55be959c70255b4a23db914575d4d0681e1201a47bff115af1958.png',
    id: '6705',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35864cffb976dc880b2c12ff0cd04c047d9c3df872b6b74dec84e2b0501ffa79.png',
    id: '6706',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c71fdab06cc4faeeb3cad59a761adf6ac61380b3987351a55b0154d595f8d213.png',
    id: '6707',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b7ac19611aa07b638bd89e57074cc93b90320c89a3861d7fc4577f3ef628cf1.png',
    id: '6708',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9cff6bb195d1986fb7884beba41fac15ff65e7def9ac2c8d261c5b9c35d804a.png',
    id: '6709',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4adfc02cc32be54891ca4fa8cba9bb3a7bd29c178dcbd928ceb222ab6169cf4.png',
    id: '6710',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5dd17790d781e293fc989c23e530624f85d0a9306d6257d2d1b5ec37d02b5c73.png',
    id: '6711',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3752345ed17fc77ee9a6fed4031f1afeaaa5bbe69de47a15b4ee3d5577e0e398.png',
    id: '6712',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a345a787bef6c5f08cc9b2d962b1319684c144a22cad7c4ec79ca5f9a0e0bd80.png',
    id: '6713',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a339477bcb65e3e925d4424e4cae70dfdb6de015baa1ebf8fa1f35fa176a5f3a.png',
    id: '6714',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51b13434da96b1c93245a3fe57968d687029f214e18e978f039254bd12c9d3e4.png',
    id: '6715',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10eabfc99d45330ea7fe136465758fbc16582e8bade949c98045d8d302bafcfd.png',
    id: '6716',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c96600edc3d7f48ca6cb9a9cf177002b054b84a3c4bb88c891b700bfcb6190b0.png',
    id: '6717',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2c51d258bc0a7f3abd067285acef95ec70d685c80d5acff972afe06fd321b24.png',
    id: '6718',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c77c214d8c2411b722a7a4d2f00380af8a4f5ca635d6268d0476015968f686df.png',
    id: '6719',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a74d423fecc0a3184ee30c4e30e6c96a93b71b206b764de11f29011feb279005.png',
    id: '6720',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/157e13a0914f7c6724b9b724403bfca4dcf457a83feebdbdf123fbaad1d8d2ad.png',
    id: '6721',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98a800badf455ed28a0caa30d1567578daa3af6b2ca9ff7801b86c44efe3959b.png',
    id: '6722',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52096131b21a2fcbffb097a0a3091686ab1203a29cd89fcbaa07ae34b42a3f95.png',
    id: '6723',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf6f4b4878d06cfc944e076838c47ece67d05080a3f4ff206ed8b09044ccaf35.png',
    id: '6724',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/163ce0b05e72fcdc6d17c9c25eb186239c6628c371c49b43d9430e593eb20599.png',
    id: '6725',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8176fbc47946f0b14373628792857482b1a3804765440066b194497a481a710c.png',
    id: '6726',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b155288f855c4075e3c567e98fee6eb959f5838a25ea195f65c6546a2889b7e7.png',
    id: '6727',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b44feeff2086acfe0efada89f3403e9b38d4682fc28e3eaee34adb70cdfc36da.png',
    id: '6728',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c4694b71aea9c714b7e8f8f863af022a4b344b76b09dd3e07d2898654a3acbf.png',
    id: '6729',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77840da3b968ab56057bb7bf85eda40987f4f0345e6b69949204533eba1e30e8.png',
    id: '6730',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1ebaa3df8346f279c6e0f0b7daf9651d6e9e6d93814e889dfec405f3b29611a.png',
    id: '6731',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86c1620b9829c4731e667f1757ce2a001e9c5f3f81adf8e51faad1e589d4708a.png',
    id: '6732',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e47ac5f0b19e963780d42ad05dcae241908f410a9a07dd854fe39a4202fe3ee4.png',
    id: '6733',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/645b366c269ed632de58d4bd74ba4dd294b413d617ad2597e2d39d6bf6efe618.png',
    id: '6734',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/129c210d85700594cdd9e426fbc002c489b93e6d6f58f6f54d8b7e5cbd61ae83.png',
    id: '6735',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83fcb6fe1144f5fae5231de46bf7edc8adb6abe7375490560a196a52631cc00c.png',
    id: '6736',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01e41ebd22b0183a8e16c94898b1410a154e5b5f0ec1a165ff5fb8fc0837e1ad.png',
    id: '6737',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9965fdd8c6aacdc4f7773ab1d063dd3ee73c83451df1f2c7e6a5242fb6a04a3.png',
    id: '6738',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db482522d53d1e3196e43578cbb5595a8cd2ad49530d2892b97bfd815d5c0277.png',
    id: '6739',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a1335133b0766c7b7d62abcf5c261894e67263272c813ae42e46216ef393b1b.png',
    id: '6740',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6950df40d923ead372880aca1c0d86dcd507e4e4ee9c360c35c294d792e94696.png',
    id: '6741',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9bd86358193f9366fb3c71ed4573dacfcd9630dfd659c202b5744cac5e7107e.png',
    id: '6742',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6ca681837e4ad2fd95e3abeb8dbf5c56406da47f15c08edb70fe93debc7980c.png',
    id: '6743',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d75e42a8817e470c1689dc3c900392288cf9668e9a9527a2f7e246bea6212a27.png',
    id: '6744',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24a029e937d4614975e06e83f1bd4598d30ed8bb36d924b61e6f2dc99e3fffe8.png',
    id: '6745',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72ab46286a23c749b54c70a47b3a406545dcd23fc0d57404c28e9d1ae70a6347.png',
    id: '6746',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c540e7c5030235aa752116f368dbfae018e6a6256dfc159890f756ed9484e83.png',
    id: '6747',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad14f792f7782e203220163592ea7448349da9963e00da99e622cc35779a0a0f.png',
    id: '6748',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Explored Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1df51ae813e6c09c31fad9c1cbb7d5b1bc42ce5144e349b30d53874cb3fa56f.png',
    id: '6749',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13c82e02bc482180058632d35182a9ef96b05bfedbf6ad90b49d2db1165a1f59.png',
    id: '6750',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8016334340ed2053cd7b638224bfb86280b496bdadc6f97c963ac5a6e3ac6777.png',
    id: '6751',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92dff2736ea795e7fc978c5c3656a55f819b3131c300ebc11966553cab57b522.png',
    id: '6752',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ae57ba96ff224b92e119e17e3129fcac122547654213acc482e96325d90a2d3.png',
    id: '6753',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b32af757c45ef7959a475f0574e6dd8870615949df486e33ea494610d30b6c8.png',
    id: '6754',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cd10604152e7966ad6c016f2d7f4a46e2fcc4a90a88733f418f277a55c7d013.png',
    id: '6755',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d33c276f073631338e408e4ee6ac7d8e0ad65ff5e66f55c9113a3a743f2440e.png',
    id: '6756',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5d9e029bd9be1dfeeb41f451884a9c03371cbd95b28b5790b069e6baf5cb3dc.png',
    id: '6757',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb1af927c670542d7d49e67ac2a46ea22085d3c997ef2f90dce2aff136e36e52.png',
    id: '6758',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c2183903351131b64e9a292eca1dc63d4307c1046c1fd439d9c28e0a0e88798.png',
    id: '6759',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47505e70ec72a2a025e995f6ceb9858c95bdaf3d3b799cd499c3a8663ecbf0f4.png',
    id: '6760',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f702e3d2740d292298602d4c48e72e5a64aec42b82087c3206494f77d5b2d01f.png',
    id: '6761',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9685e05e3729108e1ca7c0c637bb9db3365f2c28543d2e8af2e0fbd55e5ed81a.png',
    id: '6762',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2354b4bcbb100a057c60ca400bc15f600e647cbc74f3b1cb8602feee52575092.png',
    id: '6763',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Weapon',
        value: 'The Claw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a8860dbea0e65491595530b89629a6bfd0856debaa2b1e1c6de2aeaf09bffe1.png',
    id: '6764',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d6a445bb61d3cef36614654a9d466861d8659eea67ed20607d85ba6739fc83b.png',
    id: '6765',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e372f1c0e3464f22521204549f56200e3d7b5c30ac49dfea7c67d40bfea1176d.png',
    id: '6766',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ecb3cacb83f06608687080107e32d906788f40bc2f73c6a5d8bf6a8917ff76e.png',
    id: '6767',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b42e7430744bd67819b465123c11b46ebbbb1fdb53adef55ca1c016852e025c.png',
    id: '6768',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/565b35502122fe4f410f4f317f50ebea360e9831b5ecfa23f834c900f0d1dc7b.png',
    id: '6769',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96dfa5c67512e8ebbb3092da9d483a00bf91a698096760f406a2e1eec8ea57e5.png',
    id: '6770',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14af843023cd473c39f3d85c17e7152ecb2e34bca486bcbed273f619fefba2df.png',
    id: '6771',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7cee5c8294b2b921927e68624be7d9b8f0f5bcf526c74f7b0d4a55554149e11.png',
    id: '6772',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dab26caf3e7df80d7afbb1fcb5a63163acfaed3b4bb31df8fcfbc4432b87539f.png',
    id: '6773',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab197bd032b520ee884ce865ad8a170ead651a9d19d549316570d6f3dd9df9d2.png',
    id: '6774',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c815e5aa8cfba3f894929fe4266d68d93b6fa9ec52d9bf2c9ee2f37a49fce0ae.png',
    id: '6775',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fb76e1785b95ca278b90b25cedf3a060603c42659fb41452fb6a5982691d611.png',
    id: '6776',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3ff34d455750c8b1221d41a115c3eef153ff5fb1a6528cfa175248787b59436.png',
    id: '6777',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da142bd1caadc3712d04a1d64e355747ae69e60759f29579cf5eff051cc30e9e.png',
    id: '6778',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9350b662b7e744a8fa7030c91329f9b5b98dafb7cf22a3ce364a65efd602c6a.png',
    id: '6779',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fad81f064c5ed3e4d45fb8b5f569ecf3b08b5e04c56aa7276b34ad073bb089e.png',
    id: '6780',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f45b654944052d20a90173a2160148769fafa2048d790e499b0a9d6e85065707.png',
    id: '6781',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/543ce83b97ba5f654c04e33ac03341900653e57a08b3aadafe490db8d165be97.png',
    id: '6782',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81446e85448ff077eeb7cc87fde099598cbc402d095e4d4bbcd0e37139d56bfd.png',
    id: '6783',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ed6f07256ec014fa7a964dd96d49a0decff98c9516081a7e0a23be5801ce2e5.png',
    id: '6784',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74f86532037a32d5dc39bd8cf44dd7ad018c0af057b68dedb5019c6dcf89e2c6.png',
    id: '6785',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e04996bdfec7fe63db8b787e5ace87f9192c2994780306ac55d11d5f23c4d8f.png',
    id: '6786',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2c6f445b4c411f7f138515b31f9c669e6ecacdcc6b0ef14ee27a966cf1fcdfc.png',
    id: '6787',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Wise Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5551329928ab3780c053d9fafb64e138c0e3d0185e157cb16b9adfe3e3d4408b.png',
    id: '6788',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a7f4551751cacbeee1f7c07a0e89107dba50d067f8bc9f9c5d8671993ea6b57.png',
    id: '6789',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/287f72cd008a018116e633fe33c6f2e65686cea4feca3ff9f15db9f4e8c91df8.png',
    id: '6790',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6a9bdba637ef8221e1c711634c1712eb8ce0c4d0eb3d8e71d91e8941ed3464a.png',
    id: '6791',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/516ddb588ec1636767fc998b177ab9ae3193f3c43c4b35a36d39666e0202e589.png',
    id: '6792',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc32fec16c8010db4ed4b0b100f36315e510a70f612f13e66bc87cde5c0ed7ab.png',
    id: '6793',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69f45002d41a10432563908e73143262bcde6bf54c73e985ca4d8d2054145a02.png',
    id: '6794',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3a047d27da06799436db0f420fc4ab1e1e4f2389738bcaa7ec09e73be98e326.png',
    id: '6795',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13f1e180b6ae9ddc24cc5899e98708d4167a9e136f99930de8a82d439da2f3ac.png',
    id: '6796',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e628847320282b2a92bc4d6a1a22992a741466e59fc6280d073ef06148b7c99.png',
    id: '6797',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8492522d660c848a95d45ba16dfb197de70a9a8b9ec18a17f82cc9e44a23bcc0.png',
    id: '6798',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9edd062c109a32099812b0b979f8744beca870561416b08a7716f3926a907c87.png',
    id: '6799',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f289a88ecf3bce7a68e66ae3662697bb549b101abdf6fee4e14c9ecf98326064.png',
    id: '6800',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7660739ce9d66ca40b783f004c220748033663f5833e0df7575c1e3c12ead86d.png',
    id: '6801',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0bbb783747ed43f511f2e3211301b107212952c8dfe688f8add0797a9169d585.png',
    id: '6802',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/888ca5bd7b920ea282a2d4e94cc2d13b8380cd59d655350cf01f5bdacb5665ad.png',
    id: '6803',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aecc5557ba6be68fdcd3eb81b88af04708e52d857e36c43c907cc04a3e4b5229.png',
    id: '6804',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f7923fe6686553793ab82b79833a416a4649b2e66708da963ddeefdd950e455.png',
    id: '6805',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4f46a572846e0cd342df344405613af15cc3f4141191264fbcab7043b746aa7.png',
    id: '6806',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ecd18088a95e8a85e90cbdab49e243f2f9b9ccb1dc56a5561bd4193a32c91dd.png',
    id: '6807',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57b759be8b3ccb88ef5ad62cdcc5a05a20703e8ff38c72099aa8309bf7691004.png',
    id: '6808',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a761fb446ae487a187867d991d5f5fe1b7a1b3116f8cd225bcc2b539e1c9e3d1.png',
    id: '6809',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07ad996aaa5fe3fd613e2204fb2171c1a7390f8d5964b68f6d48b884c76a6f95.png',
    id: '6810',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c57788167f4545d09b4b8dc4c17205065a64b172a258966e7e3317075c230a8a.png',
    id: '6811',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0708d98f79bc42c350f831fe457dcc2d3bdf19326c5ce59b980aba1a56e05672.png',
    id: '6812',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d35ad1b7af51408b17bf3e1eaf03b6be48f440ead552da0c1622bc74b830193e.png',
    id: '6813',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2866aee12ae3bc3048fd02c5b8c2b33b26d619b31679f106ac84aa22e6d740e.png',
    id: '6814',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82d90e2de04ebaaa0164ba15f96d475c6740283b05f225297fad247b8a70f338.png',
    id: '6815',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e65460c39bf677b60f0251826891f231edb77e0e76970d4ad493dd84e2ea12d.png',
    id: '6816',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32707bdf3315e47cb1757de9ba95946fc52a1fee2d963b67dc48a94e4aabdc81.png',
    id: '6817',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23f6009e50b32cb50d8a8f11abc6bbe66422c39c0aea5bf489e3f52ad0cf4e41.png',
    id: '6818',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35e2bc3f5cc330e07587b257af43afe67b5f0a0e9cd5d9c9dc2a13f3c1080ba4.png',
    id: '6819',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5030d35f0b751377a4bbcb5bf7b16007b82e80647de184a69f78981d3b80d7d9.png',
    id: '6820',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fdca1bcb19d3af74234f469928e9985220e33afecff8b9072dc21e25fc6784e1.png',
    id: '6821',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7056ef454af669ff9f52c2da77d5cc7798b7f340365811e2452b0783198fc6ff.png',
    id: '6822',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71176c45b514f7ee4aa8eaa54e5d9ea5c34daed2f928db0f0dd7f608de20cfde.png',
    id: '6823',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d1e072f57b108b45d5882093fbf3f3e04a1f546eaed17523e5f291dadc83375.png',
    id: '6824',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a1d12e2e458cedb52add09249fb119ae79b8e601a39f9e351d539d8596f2880.png',
    id: '6825',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6afca1f0a909d2b7e99b70d59bff2fd68ff9158219dec4d9c09459cefd43366d.png',
    id: '6826',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/583742fa470157a37b4e711650a0e56f0cc68b0f46452a8034fcb4bb749c103a.png',
    id: '6827',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac1bb7f7832666a168f808378f97c432986d27020b02923d09c0f46413a84806.png',
    id: '6828',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad5cef286002c8abb93f0ab70ff7e7f5969017e779b4a2900225316117b06ea7.png',
    id: '6829',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/667f81f4c45b02d38a7bb7ac5f107aa9465904749aba1eaa01e1c1016e27b75d.png',
    id: '6830',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92cae619a0407504a8eb050417c3a80707c648c9908a08b0289c798509def415.png',
    id: '6831',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19c2b19d5db0972917010b480f94900be78a7a00b44213c282c97a4d25086dd1.png',
    id: '6832',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f32de0edc26df9251f065a4e370a51586a4173f0bc280cbdb29e98cc5f6c32a.png',
    id: '6833',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3760051a25ce47e96ff8c19b65662e5569f06621e88323c721d965ee78a9d78a.png',
    id: '6834',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/657ac7ecedf827f79a90771fb2f4baec8d43790258d193a759c3b62067a64be1.png',
    id: '6835',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/710a5b6d8f46767bcfb665754f1ad8b5f1cf801709c3c39f4156cd45185142ff.png',
    id: '6836',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90c4d1b16addf0404b5b2a0852d20f13f5e375659ac0bf26733598ca256835e0.png',
    id: '6837',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e986c816077c9a2b3f64917bc6a021da348cf9fc06a50c9f7614af1b462eb27.png',
    id: '6838',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76ee276c36378a200363e25e64120843bd2573647c34c4e12b5f89d836c1d47a.png',
    id: '6839',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2c2b844d009bd9d24c5b70b45f721664230454875eca8d3a34c06024d2c213b.png',
    id: '6840',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skully',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb19ea49c707bcd86da7f0fe2a85ae2eaeda41ee9150c8a337d5d7dc5c44a88b.png',
    id: '6841',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16ae3be5141f154c8aeb75683f090c4179a6ff6568fc08db22441ba52c2adac6.png',
    id: '6842',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13b0cc7a7399e3417371ad6efb62cb551382359fd90d99fad72b4fccc512141a.png',
    id: '6843',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ea83d2f4062b4bf67ab8463c4243af04f8985bbf867f5e74e4eccb5a7e8fc15.png',
    id: '6844',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bcc35203b058223d1586eac2ef0610b5711662476cb02f2e646a8bb55a92e0f.png',
    id: '6845',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/671e48b4bd8a33d4278605538ecc5122595884320b6b1ee396cfbfae9db1e71d.png',
    id: '6846',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34d39d708285c914b96865d9083507335c8cee3910cb5fb60a2ace3fd3023e8e.png',
    id: '6847',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f9889f1c0fe97d2ca0aad77cc246c39bf56aac566b9fa212d6775edaf1bd9c7.png',
    id: '6848',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4506337f081ba335c892e9011b439553e6281d344b0ccfdda68deb043666e08.png',
    id: '6849',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Bubble Blower',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e79f8825bedb0e5fa733d3d6278be7b85447ce76d6b652d2f506e2596c357b93.png',
    id: '6850',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd0fbd1aee25cc47de4191ede480a3a4c4fbdc44b11ae8619aa744ab0d283e8d.png',
    id: '6851',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c65308f0366ef14f2769ebfff85115522f3ed4de800900c7fa9c3a51f5c0b1d.png',
    id: '6852',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df9ab3c0a6762370cf47b09ba526fdc0d7665191ce8b4aa183306ef9a0d3912c.png',
    id: '6853',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93c4af0769f8b0fc03a69e7315e01c4c591fa52933c32b9a4daf6a3c26038ffd.png',
    id: '6854',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1df0d4530a0ca49db16201d3e515341dba57da7948f28d2d90deac43405c38f7.png',
    id: '6855',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3d3d170f91e4c2bcba2827639c23052445d28498f9f074050309027ef49b85d.png',
    id: '6856',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/888682ef2ff47467d258c1617e2138cb544ec9323601786d55fc287ed7ebcd84.png',
    id: '6857',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af35b799b4bba9b7b2669227a86c723203cb95d9ee149c4fb46749c7f5840fa8.png',
    id: '6858',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62994e0050cc7f2ef60fdbac40240559dcf8e83d4b2ff11e14bbb5ec97e2c09a.png',
    id: '6859',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33b2abfef979f026708d6e4975fc9be85c95acb6bc6ed62ebc2759cbdec6374d.png',
    id: '6860',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/debeec8dd3e6aabbf332b4b79e54407ff44b792f01d6477ef53e93e17ad59ee5.png',
    id: '6861',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63066b667574a454432a2441c33d53f55d898b7cc2b28d751753f822e3de5c0a.png',
    id: '6862',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86289164578c9a0b90e990289f00252b8782bb7dda2e6fe8df32286875d24e74.png',
    id: '6863',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9cecb0ce932790c09658f1e00582aa95a903dfea7b41da006e6591f6c547c85.png',
    id: '6864',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30339ff385a80fde0fe82024bf083677440ae811acabef1f4281791c2d31d100.png',
    id: '6865',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8501f8fed0c60a4bd33ead29b57c48034e4c557d62cdb4fbe8531bad50890960.png',
    id: '6866',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c43ba239c583b38c151584a86ac86384a4b1e85eabe3c9a2b6195bf6f4872b41.png',
    id: '6867',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/46236c71faa8fdf8421d9539e0ff58f167f9a8ae91a519e3bdccb035df482c52.png',
    id: '6868',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6749ad1105afa1671a39e477e08a52fc9ece0314ef18b78e138cdb118046bf5.png',
    id: '6869',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9214dc10d4620d1f32b8c9f9ef059cfaf2b8369ad1173291d3ea95e6a21af929.png',
    id: '6870',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37106a3283c1bb43ca9e5391ebac17f7905cf0324ccb797510a499babcc0584d.png',
    id: '6871',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a20b1056a1a3210346fc6ab40255c7e5adb589e46cf95f7273804633ea8e6ffa.png',
    id: '6872',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fea5ac681ad5f77ccfbe0c08a085dea3bc83fd32c0adbe8cfb94f3cb264d1f49.png',
    id: '6873',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/958c66ad64fa9d7a3ace6560cd051e1d5471ee04e432340a37cf3dd3993f6e48.png',
    id: '6874',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a6b6ba8013a9f60a9c73db2ca2dbb1c32d15b6e53fd3f3ad2d0bf93e882596f.png',
    id: '6875',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da421a51e87d63e39bda5c4f04afe6075cd5885a6ef5a90bec49d6a3a08ca850.png',
    id: '6876',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da50e6c0c6fa238492c76291dc4008bacbfee252ecf9b932a9c45307211afa16.png',
    id: '6877',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/276c55e914ed08f6433b6991c4e89e6289a8147ab754a7bae005767ef81d2388.png',
    id: '6878',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19c11969bbacabd702703fea9c607768789b31eb8706014076451881a99fc269.png',
    id: '6879',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c731a397bf21ee790b86b39d4b776b63196c2d7211bf3dab99886fe20db49ccc.png',
    id: '6880',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be0d9b54ab8011965f86c2402e2bfe4571c4d2f7a30ee374da208d4a17b193f0.png',
    id: '6881',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17af41ecdaf7d4f61ce8b4b1166e3acb145c1237b185f9a816bf79541a25fa56.png',
    id: '6882',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32bc1e91b80eecb2f9b9d989e0a3594a920d7017b1eb411c9c081be832d3411f.png',
    id: '6883',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a37d014a04af53c3105784798d6b33696d244ec2d243e969cd7b0ce444a5ad09.png',
    id: '6884',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4c56b19abbc43ad66b7a9b1225e8d1cb270e591a9153dcc249669b1f7c858b4.png',
    id: '6885',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d40ba4da80dd2206c4b9b1b6332fb40886d3ad857a0e249aac43e186c95d7e11.png',
    id: '6886',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Cactivorpal',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02c2164cbd4468b66f3680791ce75f57b12e10c5b431ee93f68c30ca506209e9.png',
    id: '6887',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d3a8df66dc3db8141290911772d65a29752125e65a2d1caa6b5569635b1f9d5.png',
    id: '6888',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee399297ef6eb6b35c4d69dceb0f3de74a89879829d99197c23316977820f1be.png',
    id: '6889',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ec03ae44029ffe2ba6a812299870146c496889cba652206cd93bcf2984b3a55.png',
    id: '6890',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5da7e037c3f38e675dccab5d79db65556512be32922ab1e062da799b56810ce.png',
    id: '6891',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23974b4ec03437794a75db90473ddf44958a2f7d41428723cfb8bc351690c284.png',
    id: '6892',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/539f55df6a57f488fd92a7b93e9f97625e4f55a3a8ede5da7a0948382ff2d714.png',
    id: '6893',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/131ff09ae5951892dad9387e53b53452d1d18ae58cf62181f625a3a3f2069034.png',
    id: '6894',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8dfc50d705453590b9ce1b03e652fd48cfeb85a8b63953457a455a706fb1560.png',
    id: '6895',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9516f5ab8253dc8cfb9d2197d34c06f83cee1d7c48a5fd6e079b3b0eea6ca33.png',
    id: '6896',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25a462b1a2a8a4e3c08da13b76caa1c45b2a2ef7c66327ebaede1ccaf95af09c.png',
    id: '6897',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c2e98b875ac510e50dd3b2c7c855e534921a4d261c2a6cd4bc23e3df90ca45d.png',
    id: '6898',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0cedd6f4a85a4a1ef764bd1e1bfc9634e25ea422b9536401cb98349ed54cfa20.png',
    id: '6899',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f23b68b5caad9472cf8f604b900f965012c502d130a90da9526ec31563c3b46f.png',
    id: '6900',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35b2886437a773c1d8dfd7bd1425f8955f423b9b6cec7bf96d8a2b66897dd279.png',
    id: '6901',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/260f6edce0f84f49a879057d385d5ded32654b7a0adc9f4b6335cc47a3cf24ad.png',
    id: '6902',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e09035f7ff34444498071857ff133593fbb4816039a19758c2102332b2d18040.png',
    id: '6903',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58c14c68f4d7282396c1e82e21d8025b95a63085e337db87b0ef2cd4030673e2.png',
    id: '6904',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab630d1d00bc81d38245622de75f9f7e89ecb7eaf688fea4c79f24c14a768793.png',
    id: '6905',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/497cbd6e58ee47fca6d25675ab0e831fcaf120ce78106f2cb734836537c5bbef.png',
    id: '6906',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/395a844e4ec41e20e19a1319924cc8da2e323804c9243615ee77011a99f0b579.png',
    id: '6907',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e06743cb6421f0854733e07f989922b2f9a21aad40b8cdeb4515a860dbae6d3a.png',
    id: '6908',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22e778977a6ffb9c087d7bab01e906414acce1de2d25de6315911c5b1288b2b1.png',
    id: '6909',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c440f6186ff100222081ffbb99adac2598e7cfcd67c9452f8ae23ca5a57ac95.png',
    id: '6910',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d9f0ecc7680a7b09c0b99b22535de83077ae50279ef8ee45cd028804ed5dcd8.png',
    id: '6911',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71e20c8c4d92da6f5c5b42bae9541cc62c7e01c5f0e68a791fd5c941dd43d4d9.png',
    id: '6912',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81d915c2352ec9bc5326eb1f12657b8aa3ee3dae6df26a6ad254e77fbdc6e7ad.png',
    id: '6913',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c86dc7599a17846e1b6f9113fd90a9b6bc621aab308ee8dffbe0a2727b826c1a.png',
    id: '6914',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11e1e28feeeb3dceca209470812cd399526b06062a5152d3d87282f89bc87302.png',
    id: '6915',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fefd7045432b89709adc813126811b37a3960783057377f4530126a6b4a2380.png',
    id: '6916',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c5c9cf931db32aafaade68739d8d489bb94a1e17ace02b5429178f33f5f8955.png',
    id: '6917',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c01047351b1c92db7a8619cf3006e9f903ef009133645929dee506ede445ac1.png',
    id: '6918',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9b4b25d09937df17dab2d3daca9c0725ddd29e5322ae391bbea8142da9fe217.png',
    id: '6919',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ec81cf5c80a7f31c5b4015239d15a4f77e9506c842c0d3c08860a7b1ace0214.png',
    id: '6920',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a913b3d8a62d6c009ff1c3f38d0fc3ef8c4ef21d05bc2c5e266bae168be6e4cf.png',
    id: '6921',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14c2e3172c8fbf6d89f3899e4c36128a37506b3ea7add662010d2a1acf182a5f.png',
    id: '6922',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/831fb349eec84fe222558c463be75020f5b04ad6ee8c93e83971115166744495.png',
    id: '6923',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12324bfbd454bdcada37185da5369d97b189fb89c7fd0771f690851e5d890efd.png',
    id: '6924',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a770dd2af24684acfca0c7f053e00c1163a09cbc0d2fdbb1f9dfaa7c98449bc.png',
    id: '6925',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/887478bb3e5f38403582e81c0561f51ce2c5e9acddfc8647fc57500dc8916d2d.png',
    id: '6926',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fddfd92f01e81f63a9ef56867ea6ba5b0d60347ff538606d6d933c1486267745.png',
    id: '6927',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e576a75847c764b02c1dea9871147b8ffead00b8bd642bc95d827bf1ef414a09.png',
    id: '6928',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1dc6e3d336b8bc90db62de1c1d0ce3e43808062414f03b49365d06ee466628b.png',
    id: '6929',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd483c5dc2d0fbe72f449287dec2094539e4028c5ce4e8e1955b246223374296.png',
    id: '6930',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Winged Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afa7fb75d8e2c6c64e1e93c281189c8de1290ce323196382a303cea871f82667.png',
    id: '6931',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d17e04c3c9634377376b133539e82605cb8dd12cde15ddda0d7e85aa2282fa7a.png',
    id: '6932',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51390ea67f3d9966d387fb466ce532b3da686378fc5d3da7179ee65027310458.png',
    id: '6933',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47354979ffa36a458031e0087affac8c09b12ec82e2f087536c9599d1eec53d6.png',
    id: '6934',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3b85f0b07691aa44568dba1971b54d8ddba90079bf02fc15a64006a658f2c1f.png',
    id: '6935',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf9ccafda0610d7ee959f1240eea39282347c17acc53be8137208d39a70d134f.png',
    id: '6936',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11afdf1fa25c700b33dce79a20735b3792c0df77cfb0241e13717589fca2074a.png',
    id: '6937',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d8b7af54559540291daed9fb4e18541dff9553f04cf7e41088bc37072babcb7.png',
    id: '6938',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1b0f05375bca68d9721a45b44f305ae2e051215c2cabeec61f2f21c5448b2dd.png',
    id: '6939',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7d54a5319f45d744c370c25dc6b32400a0753d9a9d5d530b3a109fae70100c2.png',
    id: '6940',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a58ac30e13b71f87ff45f588b4d92d8e97b1c60629181d5ff1b9b95ba73f033.png',
    id: '6941',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93642b98c0dec028104c2f01c46cb5317df417decb095975fcc5a7bfe310946d.png',
    id: '6942',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2fd6e46a41f2db1bb40373234e985306af4d7268ba2a13d34aeddbbc6486b71.png',
    id: '6943',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb9cc208f8e435ea4f31d22296053d18e5875bc6bfcae8c9c9b1f0ae5d7355e1.png',
    id: '6944',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d326a5ebd1d1dcbbe12f5702d29dbd3beb7feecc3d42ecf33dadcd62c7159487.png',
    id: '6945',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d8b42640bff6d960c0c59542d57a9b6e6b06f8f25cbc9f5a45679807d4afef1.png',
    id: '6946',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4c5c8f32836271d182507ece41b2f92aa6a3071ebc0b54102bbbfbf9e395a5e.png',
    id: '6947',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3fe8c2a3e4b7554d99cfd4e733ff9a7b1a59bcab8df6c179fe81d7312c3f3177.png',
    id: '6948',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0d745be7364e7e02ee87311eeb57ad678c3c12934b1071f829f6c2e39150122.png',
    id: '6949',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5790ff1ee86c11b2ed50cb42e5671d30d49877cee5129fee931d022489a67ec0.png',
    id: '6950',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1306fdee7cb7e081bf729f5c3d29f661e9900c52e9fb6d819ad641f180c50e54.png',
    id: '6951',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5c01039d9a3867edda52ca5b55cd244be1bd0e4c1c4ba98dc5fbf10c83bd70e.png',
    id: '6952',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21d2883e59ddd045aac4582195cb3a42d85651dd121205ae23c844257879d93e.png',
    id: '6953',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7a3d1a06fba032d68f543c2a4127d325b2b3f2928ee31f4acc72170049f3827.png',
    id: '6954',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2a6568fc3a5656737b4bcea6aacbc858e5d8c215670df2adc0449dc112a2577.png',
    id: '6955',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9150d83c4033708854c4bb03329f43dd62d2d0846d70c770c78c94d2e0250b3b.png',
    id: '6956',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4adbf5bfdace672f0371dacde5ac9ea7a0083c79221a6e266c11087d60a3dd2b.png',
    id: '6957',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe00f56a7b4aec6f7fbc52ce32f585103d2b64252f440b70e172b750bad459b8.png',
    id: '6958',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63cd9e790976d0966b0cd19a8fbe50dafbb1b2526d29cc0a4760d5902bd6ee0a.png',
    id: '6959',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1a107baa3e72252ab8743def2539b6a6722d604a615735ebff03c498bed7715.png',
    id: '6960',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63d830105bcb397a3106e4ea5266c88d311922d645bb342e94cda71d2ca20758.png',
    id: '6961',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c874ebc7d6faebad4088388b032cbba843ac8ea24bdd5b6cee96ed16ea9e6ec1.png',
    id: '6962',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd4236c3ef17e145af4c286ba0ac5b9ef7c17e03513a4d19c272e2eced27e5ce.png',
    id: '6963',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2718bd535d7443c76cb6e4af9b150ed437ac9ea7d4005f8f24a8a1fa065c65ba.png',
    id: '6964',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e4ebdd37ee8f2f71c345f03ed4f9a3cd99185c1279e114082b5910b29319bf2.png',
    id: '6965',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/715542ed660e0622c9e928da0aeb7095885482926fe6a87029e7a6fe6e290359.png',
    id: '6966',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d4f6ccd764cee42f98395db1fef6df12479a4fca8eb0b0f8a46c8b0354c2ae3.png',
    id: '6967',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb461d8c64c5b015a70195a473c17796139a3d0feae5afc05e16c17b3dc5cc6a.png',
    id: '6968',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5dba129d92d5c7547657a6b37faf564c1b9e6bf8e106b418b40b656df2410178.png',
    id: '6969',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a871a1a1ca307d9bb29a0e79ee688f6de34d4c997c850082d1d41c10d860138.png',
    id: '6970',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c949a1ed73d97a672e3e9c98faf5331cc541c5345a8aa4e3f9033a9b18e074f3.png',
    id: '6971',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b254eb2ad0414b61b86933c20948b3da167e5876f5be9a40deb471f8f059e54.png',
    id: '6972',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/009a57b83fab38364a22a02236e1f1ab9a0a1731d3332e9ce284a923aec94f42.png',
    id: '6973',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f113c448d3d4e3e20b98261f402e528dd1772ba7a10d9cfaefcc5d1febee1e2b.png',
    id: '6974',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/410e2707ece20f4ea493254589698adcf11926a1924e49992554fec638dcabe1.png',
    id: '6975',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a713820faeb8898a38b96b48670a40c1d943e4fa4eb4d1ebc909010544e018a5.png',
    id: '6976',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90abf5aac98da6dbd3d1fa10827adfc9bac182ab2cefbb38c8b8301d4dd8c96b.png',
    id: '6977',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25c0dbe112dde3bb0c07d544210b72971080fd0ff12b1c194202c5565fa3bc1b.png',
    id: '6978',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc8cbf0ca6cca1adff95155277c6466dc28235b5e2400da385439634193992e5.png',
    id: '6979',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2eae69309eb4947a79adde5c1681f6daf6ef1c0c92528a4d600fad47b8b3a8e6.png',
    id: '6980',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a27443f50214bd1dcf2c78d65eb428d89c7308098c0c547c9a2fd0581ed5421e.png',
    id: '6981',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89e76c5b917c3eb3b54e2535b10971b2ddf4682c1914145fdcaf97d3d10e62bc.png',
    id: '6982',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fc7ff488a8658fbe6949ef105db1640923841678b99abb3323a1318778935c1.png',
    id: '6983',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a9629ec6fe9c9035c460109e79e9a3c81a754797e6c7a48b49486a4442911f1.png',
    id: '6984',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/039402c579e9fd55a7f31814310fe210641a23c476884fa338cb824f386b4389.png',
    id: '6985',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3017a39d80f27698a6779fb21421adbcf19cfedb9af4a83e66bceb8a147a67f.png',
    id: '6986',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed2c2033f23beb9523e89468d2f6169860d592521030ec22d48d2754093ff9f1.png',
    id: '6987',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51755c1b5346cf43ccb3e3a9e38e20981f2325ad75de8a8b771ab895acac5f7b.png',
    id: '6988',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/228d4b44fb9ad1a60231e3e04418b8058ebed91b53d166d2ec7bdb4f1515d3b3.png',
    id: '6989',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a172d608496491ad5235d2ed3add371a1191faa162455f315a124c44fe3b415a.png',
    id: '6990',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b609b1412ad22ebaf2a96b9dcbdf77416c3a89153a14b2776b634a5c0fbc20c.png',
    id: '6991',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7e251817846b135f2d4afeecb5189de5f58a0a3b83efa79641dc83baa2d0426.png',
    id: '6992',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f45051e9153e150197c8d6eb20308c52069be151c57903105baf63eb0398f552.png',
    id: '6993',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3fc1edf430062c38658a18508e32fd626ee5253e8a75d9de1777d37ecdd9ac4.png',
    id: '6994',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/077a242cf727ed1c04e96b8f92f6e81fbf4f4d990486d39e1a1332883cca9396.png',
    id: '6995',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85b2435fa143bbb414a37fb6ac05b4ad1b0a50d8f0dfead5b50e2c0a84b21f79.png',
    id: '6996',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bfbb57dc64d6f7bf05ed2ca205200ecb647f396a72cad6c59a21cdbf5636d8ed.png',
    id: '6997',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4ec8aace21681ccaf4b3d19b2855fe19a5ea79298d4fd9a2da079f9c9c59b1b.png',
    id: '6998',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/237361dc3f25368aed6076cb7fb5f0f6834717674e02f944991c47945a9fee3d.png',
    id: '6999',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53f132da780250dd4d686ca60570c5b41dd2de4d3c224c83b95131031cda2457.png',
    id: '7000',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c76792b1fd966cf05c359de6258fb860d6a9c27f81816f1ef5732b2e04679bc.png',
    id: '7001',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03f149d7317066efe0d1ab2cb73f0aa4f155bee36bffab4d439968dd7be32195.png',
    id: '7002',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ea4954d6406371b39d413033f85266a6f373a9cb4272fe451862aa039778294.png',
    id: '7003',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7388dc6292b2970b80dadba43dd4bac90787def6d61a02b99cbf03c1530945a.png',
    id: '7004',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7a748be403fd19ae2814860e662b4abad9c014669150d53568c571364d7184f.png',
    id: '7005',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66febb283a269ab2c42cc0ddc678ba5ed62cc72815b6ead176546dc9b0d2074a.png',
    id: '7006',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4d5606b7c4bb3ee7c4edb519abfec9a0232ca39523f5dfa1ea7e948ccd30b34.png',
    id: '7007',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15dc77897012385efcdad23563095c216bc49fbc6dbb75b810c9dff232ff2962.png',
    id: '7008',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/247c2bdd36e4b4b932cff0a9f33acfb7ad613ca2880d1e234a8f1fe73956e6ea.png',
    id: '7009',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab365a44225cb0e45fb27ab88902dca30846161a4e5e65d7a5eff1c8950ab261.png',
    id: '7010',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aafccac471da98f77cc493ad0ba24dac1d53fd444847b6939c1b3a895e876097.png',
    id: '7011',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fce9d5c0144ec2a44e9314ad765ece8457cf6d21c541522c947ada60c5539946.png',
    id: '7012',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57ae9aea2f8535cd51764284cafaaadf3b0e5125839e6fc29690b7f41d1fd73f.png',
    id: '7013',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5fc27fca4765d0a9c8e5ef50b815fc407651e4b2e5db047871f47be67751033.png',
    id: '7014',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a46cfd4a46f2261e1913534ba48541c9694b449efe84db28b56ad46366611cd.png',
    id: '7015',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/877f3537bf267adf28ffd26891035824aa91fac7e18be2c447c7a439801bc14f.png',
    id: '7016',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98ff066849faea062be0275de6aa3f3f946befac950ee1dde17aca3b002508a0.png',
    id: '7017',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff33cf895267478bb0e99bd7d68ed9687a44a6ad0dda1fbf9a7a599ff46d9dc8.png',
    id: '7018',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30c8550d44d9ae1466925ee99578455676c0be6ef92e647fe4aa950379f79ed4.png',
    id: '7019',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18476b66483ae4a83161d90aff7b72b6d9973a85f062408ad2b5bad5fbb5fc54.png',
    id: '7020',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ae05bb11baf11355ae03f759493a8b0d0a0f1b7cc05572bf2cf9c3b8d45760d.png',
    id: '7021',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df30e06a826f1628e6d9097979b9b23a938c07eea35b8ae128633d5f6e8ba4c5.png',
    id: '7022',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28fb40bb640595955a3d02dfb103aacbb446eab67f6a1478e002dc9ec958dc9f.png',
    id: '7023',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07ce4de69348818e03b4b8ff6a6e2175d41341601710ce23b899b45cfca267bc.png',
    id: '7024',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f49eb8607da59b1c9a8d65824f1be752ee53b4a80440cff49e7d76e1717c584.png',
    id: '7025',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/311bd853f3548511d7f78a76db1f5e839096929b977976ea6b3d7545b4827bbe.png',
    id: '7026',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2853da7f45bb309b3810a0f4e17115b765cc9affcaca0438577f33931b7784b3.png',
    id: '7027',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd4c626a414df043025bead47bc1167afc307d7b731cdb905d33600dbcac3c8c.png',
    id: '7028',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae785aa839acdf850d901b27d11f1ce5543b9424bf5c78ec219cde2a6c1ef583.png',
    id: '7029',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/401acfbb66de8838da1fe9ada96eebb7cb6a80c047996e1e0fab88f094b8762e.png',
    id: '7030',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/396f34bad8b3da3cea2afc500a96693c3e85ea27f59e73f888519e74c91f3c7e.png',
    id: '7031',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9b95a2f909f37650fed917b648db324e3249728f233c521fcddd1a4d4d21c96.png',
    id: '7032',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dc9e3792e9227e1fadc8a9f3bcc9148f5cdfd68e1aca96182b8f06eadfb1adc.png',
    id: '7033',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6dbf86676221a694b3df7fdfc0a01d818aac6c4786b690a39bb183420b5caaa.png',
    id: '7034',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c77ad3f7040bd2769a4717356235d03f8e9f73fb557a4c13e9bf436edc57dee8.png',
    id: '7035',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d99366d4f5957b63a0f700deef178bab5c972edff8c84027143d7d8e3b2b5c7f.png',
    id: '7036',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9293e5c086c1c0ee9ed9f360c2ed209ca5313e6870573a7fe8da2d0fb047051c.png',
    id: '7037',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05fffd3311e92cf619db73fba03aba114917cbe010e59591def523efc11d4085.png',
    id: '7038',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56a14cdaf2e1b17cd0f9342a31b58a3df6db127acc1787a319db1d5d5903fcfe.png',
    id: '7039',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfb52f5f5ad876d1597c005441f691fe11ba4e8cfd1e9e0d58c1bfd4fe43b28c.png',
    id: '7040',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/80995b61929bdd77de4333eafd21b6df5fbb3eac68beabf0d647a5682f27018d.png',
    id: '7041',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ae9c342ba1284998fe75b2f61146c770740342498498942415876201cbb21af.png',
    id: '7042',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fffbeea31a3a59b001e0993e6286b30ca772356e27feec858ce0bdb2d3188e33.png',
    id: '7043',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f5313c08d35224fb0006f2bceb7d6e80d4aa54dbea4a52726527c76e382b8c1.png',
    id: '7044',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/faadb3532fc157adcc2bddc9584df7ef370fce8f496c4a66c0c8f134a4405118.png',
    id: '7045',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ec8c5a47fbd6d0fb461cd5ac5e302fc1efb479e5a887c109b319b13cfdd00aa.png',
    id: '7046',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06182fe9a890fcd5038136798191974bec82296b12f70193b92b69c90492f161.png',
    id: '7047',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a95f83f4aa5d3e755cfe8789fda349423d0ada2bfe087d073598c1b2803082a5.png',
    id: '7048',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b2c8bea62cdb410f0592ee7aaf3a21a6692325a3e4c8693bcb8c23b707805f3.png',
    id: '7049',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db65e57dcfb0f76f347921f37ff89816bca9a23e174f99644e992d8eb1718ed1.png',
    id: '7050',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/724776e549ed59ddac3eb5a3838c605fc6cb53cbde8922d020bd9b5a19ef33af.png',
    id: '7051',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/796158c8429b3db8688341f0c6dc0bdc81de1001cffaa038303538f2f1c61e62.png',
    id: '7052',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f17eb4776e41654c03e62236bfe0bff6ae1f4a5e5831ebe381ce85f01507217.png',
    id: '7053',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4cdb4472e6b3536c9a9541ee117dbe7f8b29d7f2af1507fb47025373ec5de135.png',
    id: '7054',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3842b4f53797bd7a8bdd8fd381ea0236dec71bf256eeb53afbff86c1092eb755.png',
    id: '7055',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e04092acf6de6d7b98f9f554ae43c390fe089dda9e5441712e6bb5358ee2db49.png',
    id: '7056',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebd6161af4aa9d256e33a49dd8d10ecd43ff76d05a468bb1b82c0c6e1c71caa9.png',
    id: '7057',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea888e7b01e8d557cfe3b06c7febd41885959c9b239ce08e5962af3da4157e6a.png',
    id: '7058',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2d961d493657cacd738d34788485c007db324df4291d16ed2639f873293758b.png',
    id: '7059',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb9a660e8a6ae44765263debc99fd3b46915291eaefcd4ce81fe12c42783c20d.png',
    id: '7060',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fee4730b76630304e1ee5563665914f1ede15194b4b51b9610204ce6fcd9da57.png',
    id: '7061',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be24c033949816d38705cf4fe3a5e2454591b5e53c7c24e125c61d35eac1bbfe.png',
    id: '7062',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c7daeaf6248e21ad44e2212672f566e0f337ddf7e415c45721b6b76dbc2eb9c.png',
    id: '7063',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7350f447c556b2effa7521b39a936d5bbce7cb806cc0b44e3b77dfb7ed318b78.png',
    id: '7064',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2309342522cb7d7d1c53fda50c943a9068d648793b411b2474f29789ce8cd816.png',
    id: '7065',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8daf849673e15d425dea8d2ef1bd2edbe25ac6988b098291e60693114ee4df1.png',
    id: '7066',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42750f81f6a568c37926534f18febaccb4038e750196b758fe9fdd7e9c8cb5be.png',
    id: '7067',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41acbcfc886bf322652ccef327af32566097dd86679b00cb8f44e5b112a086f6.png',
    id: '7068',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21caa31105236f63bab7381b69a78a8cb56af7f1dfdf2a4b707ef16657b4c955.png',
    id: '7069',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ec7950ad905beeea16f8b4081d165922ad4f584b8270bec66ec64736063a15f.png',
    id: '7070',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d78e2464b5ab0bb05fe4b648f07c9a15ec3aa8dd61679eefa9da72513a3f58e7.png',
    id: '7071',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e95cb3076893056372ff02c4888bffdceb29320d1acb94a611e2e9cd4c3e3f3.png',
    id: '7072',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2d5cd451ed12ab9676b31597ccca968cb5cbab5e5f5b330e22a6998c65f9f67.png',
    id: '7073',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a92f5b6831799fae00308ae52e692fcc5f7a22c3c5a13f28934a1aa7c7a1b8ae.png',
    id: '7074',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/492c7e426cac3e3daea38c8c9ff9a7baedaadba0b7424c28f5cdc253b91aabd8.png',
    id: '7075',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06e60faf3a110a66953ecfbac49b07ea52b2a9277c6f01421cf6ae8f6f7250ef.png',
    id: '7076',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f91542dca0d2da3c56563a8ec50b2a4d361ffa99827a6aa2338f1d7ceee6126.png',
    id: '7077',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42b8cf22f73899a9e030a73b6ed85b0b216d3033469c69df381aead18530effe.png',
    id: '7078',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/015e31198a37aecb1dec21cea8c44fdb75b223203643c5b42bd72cec82f21ff4.png',
    id: '7079',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89272584422d4ae70cc5f4b07af6458bafcc43e43d5b07486e631c3f2608756f.png',
    id: '7080',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1f8bac46ccbdc4b95491ead835d9dcdd95a2f3095287adfec4aaa6cf3b178f9.png',
    id: '7081',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0f85db45fb857edb31602051f82db13fdd36ab50a3739b9a7e71418d2b1ec8e.png',
    id: '7082',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b613f1ee77753376fe115c870b6cf77d322350733d789ec401858532798adcb0.png',
    id: '7083',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cff8713a73ab9666bcb63b7824084c317381c72fffe1b24989a996f2e7c87fa.png',
    id: '7084',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbbf74e6699ebe42c69a7833b0b89b3f8396032b79c0da7a62557e691e22e232.png',
    id: '7085',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75f74ee6f93892e0500d9f04efbdb70290b376ca7e61f4d2811881e87e0cf12a.png',
    id: '7086',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01edbb50cc0a841ac7b6fdd6a51d137e09f78f68ad5988643516eabed2bd05d3.png',
    id: '7087',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af647b3b6253d46d46b06959181c3b4af876cacc7e4f9d1f3f9aad33a9b89abd.png',
    id: '7088',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9da2ca75e87feb7a205b624024062a7af6e89d57794da3788c21895a095a1a99.png',
    id: '7089',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f5eabad2e1fab8b57711e42c1d8a2e5a0c88d8db67aeb45f217e90fdcfa55b7.png',
    id: '7090',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0602cea4896e685807cc7fe25bfb875131b3d3722333e953cbac58cbdbbf779.png',
    id: '7091',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67be2f82e672527ed071522a0f490ee22c804e48dd15d4cddfc431dfa7a7b999.png',
    id: '7092',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39f80bedc14d56e1ed5c7f9ea1f00c6fcde24796f93a4dd70f9442d39427610a.png',
    id: '7093',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68cc36eccb4729616f560915297a9fa46fb1dca3156b127c1f9a53770ec8ca22.png',
    id: '7094',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20d9a20b478e1027bb49a1cca3c0a1e5aa857a2fdbd372c0f421ba64c94166fc.png',
    id: '7095',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad4e62c96ace4033f4f97491787f294e4955757ce727395665e293b2490281fb.png',
    id: '7096',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c6128d95f7b2958d9eae637246ae977cb115046ee6c52dac9341efc99aa2dd7.png',
    id: '7097',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/979d63744237463bc72127a26e6d71c29041b74214a59d4d5a8d151f696a9f72.png',
    id: '7098',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a055f1723d327cf4f74f55ed3c2ae58e545ac614e48035d59cec4108c25f2a6.png',
    id: '7099',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fa9e67ba03b9cfe90076ddb1104ad2c7f803a86fd745a0bd7ed20257d4f1aa1.png',
    id: '7100',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cfeab802d33a074d4401d6f3f0708afb3a658b5667100f00cf28f80a94aa017.png',
    id: '7101',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fd07f127af058079493414bdbbfb162229af44842b3a3c2f787c8f26a837cbc.png',
    id: '7102',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Shadowed Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26d5b87ae67e2ffb140c4a6e42b5e1fc201777ee4315a1d2567ab22a034c89f4.png',
    id: '7103',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4462f74a884c657705e28970ff84df91d4c5c1e5eca87f1c416e1062ea916f7a.png',
    id: '7104',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/711d52144d34f498c8cd91bcf028ecea8c2885448822233b751541f1575341d8.png',
    id: '7105',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1312b6af07abb74e88c49108edddfa3fec08e5fe0ee3ed9972461f665a0e9095.png',
    id: '7106',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abe574d5062213d232897b45ef6ed8ef46d9ecbb928b569c04cd1a579d583c8f.png',
    id: '7107',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/990c425f734612908c4447484bbc30c4661c2e4368133e050144953b4a7d4c79.png',
    id: '7108',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7fb31efef8afc714f7f1f4cd00c236bbc9d41c78d3ca018c2377bcfea9c58c1.png',
    id: '7109',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a709f2ba2b70d0c034103b8d6fefaa14d42ea8ce1d58aefe83e324eeeaad7336.png',
    id: '7110',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/471fb6c638461415c8bd51351eadc563872d37b484df543d66eea828e2fdb61c.png',
    id: '7111',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44bf03a2f79076c28eafcc35a8e9b3a1deddd28adfba13d70226a0104a65b087.png',
    id: '7112',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d94a481e1756ef530534d82cf83e64f43383d8e67b21cfbda14bf440d63cd07.png',
    id: '7113',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5d997c7b95cedf69402e530477dc23bc07a791709fac5f9e0ac92fb839a670e.png',
    id: '7114',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b937578fe74201b9bcbcf57c911dba33a8e3f8937714da678aa15e50fb70ac02.png',
    id: '7115',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f316426af4709c73108025984ebda64ed53f2c804cb34643ccfebf3d0f0f20b2.png',
    id: '7116',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c783ed96d8b46cbe40f475e8f129a5291e9c7f9250d1ceb1400b26a26ac3a639.png',
    id: '7117',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18d5b0f91dafb016cfc11668197c09c45ceb02dd4575021c7bbdede7e84f9ea4.png',
    id: '7118',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a8408d486252749507e2a56735250ba76dc120da8ba8783f1d93c2286769871.png',
    id: '7119',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d10342f2377657a0781b61d19c39caee259769b838599d275341f978ea4d4cbf.png',
    id: '7120',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5921e4dd518625bf0410845f0fb0aac278f51359279d9ba4a4c89b08d8d0fe8f.png',
    id: '7121',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b269f57560547c0c51eba1413cdc3426521e340252f8c403c32a9f48e5ba4e8f.png',
    id: '7122',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a8a456404659af52038169c77120b90303738b9f3c3ea8f42ae71423a60eb23.png',
    id: '7123',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3234ac2714a30e4973b9f1cb30b227212deb4c059e8df5ada4b3f208848e97c.png',
    id: '7124',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5e13773e89ece89f7fcfafd39a5c501bc1981fe8a9ffab60a67af9f359d8b6c.png',
    id: '7125',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4af033ce4967fff1f244ee5206472aa682414455f02160789aa2c6a45f3c3521.png',
    id: '7126',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/100ee471e11637af0682fd10fafd3ff36daa8638c7ff8c5e604a55373479e84b.png',
    id: '7127',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75b10df0ee7f56d53771d49473b96532434e1292e6579497739c118f7771e487.png',
    id: '7128',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dac48c23dec82c97c3357f6e3aeccd0e494d44940e9920a7c3b5bcf89ad15e85.png',
    id: '7129',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79ad058a11da94acea5757a274e24c2c4a329ec783b8c0eac3ef9b0ef0399e9f.png',
    id: '7130',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Spinal Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abd1e992d3853b6a205c06e9840a23c36dfa200c592c036671d85e8c810bce83.png',
    id: '7131',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b8e45d52e8185ef731ff975efd5f008c8eb78e9e6b56346c5fd3d57d86e44f9.png',
    id: '7132',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0cb3b827e8a4d284688c57f5008f2546082b615e66bb036a18693394e83b1a4.png',
    id: '7133',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/474d19c6219298c3cd00a433baf6157359328c6b7e6ee6fa88105875001bef95.png',
    id: '7134',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9f82429b9b403d559dede4047d1f507ecb956998f782fbf415a466ae5eba6d1.png',
    id: '7135',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cee5d0ecfb691a2ad38c217ab09baa82fd9f940e75fb817f63570b5f810002e2.png',
    id: '7136',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f163d4fec3a924df325760d080e9ee49c3862cb9c864663580e0734ab1cd4165.png',
    id: '7137',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Weapon',
        value: 'Underworld Spine',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af81b990e5a7d99f9f94784cefade69f79fcad38bc98c531cb00028255bcf3eb.png',
    id: '7138',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/705c5c52a863e2da4e48e131a4445e05982ddfbed8342d51dcf69d64374d0538.png',
    id: '7139',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/078ab6dfca52b753b140e9a8f92cb07cd2df91417f845c765c3ef6483a26d572.png',
    id: '7140',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b1078f1f53ec64bdff265510cb420da9a6eb324404187747b71b38695491aa6.png',
    id: '7141',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0453c7a5ab0fba8c36552219cf4dda8033094f4992540e9005e41058147ef09.png',
    id: '7142',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e0455c2ec9aff4b695fc07e7c1149f0dde72e2b147d12f000e3b55cfcfc7a3a.png',
    id: '7143',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e851774c2c1768e9b5730baaad1d747742d1ef162ffa3033220b32e9a6adcee.png',
    id: '7144',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6699f87644b9da77a771713468056454d8570cdf37fb987977ecf857aa8f648a.png',
    id: '7145',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf174cfd31e8411cab363a2c968c730c369fdb8cf7764de6f895b5b457a13fca.png',
    id: '7146',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce6627d7f6de6d4e7e2b0ed7c78b5d2b7eaf7b145921ac51fda848d393e63b8b.png',
    id: '7147',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4ef3222cf27548f1ad5e24b2efe4a4e39c9b6606370ef91ca22f6b555b82b5a.png',
    id: '7148',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c568df87247b93165923542c5cb36a1983ee43eede796a489646539182d06c5f.png',
    id: '7149',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/094ae95d13a656ee96bf99c3e2dd62759320eb3e9a37a4dadef93da0959d01a6.png',
    id: '7150',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e90fe1058cce4910fb7b19bca514a71fa0f6d7d18edec29f2cc2a032f8eb1561.png',
    id: '7151',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17e3cbaf087ccbeaf6997dd1a289b0aa439564abe0f05f74c769b04f59e73156.png',
    id: '7152',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7390649b9c8723234a0be8176009263176c2e49028f901969ff32b6bc06b1c0d.png',
    id: '7153',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b6a58c04db0a91192abac81d5d4ff92f3efbb625f1ae47ce635e07574926972.png',
    id: '7154',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f015789f2caaa8f1fb9f0dd028a852273d65b253d9e3ead73cace7386fdc364c.png',
    id: '7155',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed0b70f39a76b7986110e279f4bd75c083408e1de84289bb2286327dd42aef92.png',
    id: '7156',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf1073ff6f6c3f62e2d0469b071e28e129b54b2c0d7d69240a57121bbd45adfa.png',
    id: '7157',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b388f1f58c8ccde4a0ff8fecab836461d63a25f2f066981c8d1c56420cbefa6d.png',
    id: '7158',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8532c563e22264cdcebe527bcac4e8827d7ac8d9332592e8a8c98ca883447e0b.png',
    id: '7159',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe7ff9f78e8181bccebf340eaf0979ac977f4b81f98814ae3311cda4e9b88720.png',
    id: '7160',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fce2a1b02b80f011a7a0a2484fe9d9a9980f5fb2984798b9db376b8735c160c8.png',
    id: '7161',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57a016e0496ac39c6425ebdaaa2b7f36d2d732365f73bf60cbaed5de7d6fa475.png',
    id: '7162',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84917b5ba98a906616627b99aa57f6fc0a40b5d18ca125d2b52817642e17e3ee.png',
    id: '7163',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb5cf583f323ab98a5736f3b5e7385e6548d603c8f2d242ae6fd98b4c3440042.png',
    id: '7164',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ba5d5932e79904a02f8ee957f3d1ce481402ab14b9998bae7598d907f1e2708.png',
    id: '7165',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/057b246b3247c8bb4ec506002eec942c62b36d878e6392203237e65941607a04.png',
    id: '7166',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ff3ea203c861ec108863ada5e561edffdc92ab6db0793f70c823c8b46a184b7.png',
    id: '7167',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0de668b24daa8c72c719bd360d3029dab9a152bfdd62b81a56d25ad8ffdf775e.png',
    id: '7168',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4ca9bf7844197d6b04bfbb2693e94d7cabeb72dc04df9df20dd5802c6233eca.png',
    id: '7169',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff9f7b0b5ec4013bb578c6f626dd997ea34cc693d8a36bac2ba41126b572ebc6.png',
    id: '7170',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8e1a9dba75f66432a1369e3e60180229f276e58ad422e5f6729224b333f39c5.png',
    id: '7171',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5620c369016303057d2b230bd58f4e2e6ff1970ea4e7c495c4d572e27997db8f.png',
    id: '7172',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23a6127520fd33236fcfab2f1959ff8b5208431cb45070de8975a3d0a7219b4d.png',
    id: '7173',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f542a51f542c65454d60506dd376f967df2cfbe1ddd9942f84b56113d33cbf95.png',
    id: '7174',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6453b1cbb386d33fc3c1a8f6ae268baee4d99f82f24a6f961d1fe042fc95f73a.png',
    id: '7175',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27d0d4efecac84240495b863a2bb3ced2eec6507707e58096fa8149e14455463.png',
    id: '7176',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e29c27434af5b69b7cb9a21432c0bd2173d53a20a00ba4639a3ded3f2db2c871.png',
    id: '7177',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe30bb666e312261bb0ab4483c94734aa9e1bdd51ddf940dc23b777a037f6fec.png',
    id: '7178',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/268a124f63936fd4c701468cc8f3fc5e2d567346eacd36527ae6904dcfaf682d.png',
    id: '7179',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4669c3dfbf3baf640c479678e850d7909af60deb3d13e19ebb3d6bbc631ad9a2.png',
    id: '7180',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ef15b1f80984f6dd65e1de9bcd0ed934fc662c4a3220cf07f070c1e9f62f5ca.png',
    id: '7181',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/165c204bf70892e7abe5ecc36f29861f6d3936ed9264be2f4686fb2ad24979ed.png',
    id: '7182',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f40758c0f3ed97fad4f2a5171e223271dc6699cc534bdd69d101710190c7460c.png',
    id: '7183',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c1d61869115104fa20bf59890114507373c9514406a75ea83d25b43e7c5e2ce.png',
    id: '7184',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d8c0cccc946e21c3fcd6e1e5cbfddc88425d07ceb3777c39063d635c4daabfb.png',
    id: '7185',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbddb74b3b94d1530cb4325d4975518bef9b8cb1864cb733ad4bf3a787b12917.png',
    id: '7186',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6219967c118348a5d5cb06a15e14d8114e66ced8624c4f5176fbc4c43c3aeb4e.png',
    id: '7187',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82324271d191303a144863677464ac983ead108553b4394e5c992c90f847aa65.png',
    id: '7188',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/874494eb4e52ef08f37dd5e95b02f4c6b85104851b510c9b674fe5d711b40852.png',
    id: '7189',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c1c443e6fb2caa2a60371ff5c80e9f9594a02e0ca11e052ca9ae731d1833d1f.png',
    id: '7190',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f1162525feb66161832755e70a9faac98f68191b1d40229d22ce070e1a2fd02.png',
    id: '7191',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dfebc484afea0cd84ff5e77bd0e9d85206d8047adb34c903d9899d358711d8b6.png',
    id: '7192',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7c38b7d7211e9ddcd9e2378a915410f6c6b1e89640104451e5e959f9e4baf13.png',
    id: '7193',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56c72cb26c100fd75831bffad86c9d08d8eb1192c1710b0ad5ed8e8316e415c3.png',
    id: '7194',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61603980df6d0b426537a8864578f6837f2020d158ff4bc3120ec1102da328c2.png',
    id: '7195',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fca829e417efe9499dafedbd41f922f5cf3a54e82b16019071baf6411542524b.png',
    id: '7196',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Weapon',
        value: 'Sticky Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f415d4008058419951811da8bc7ddd498e473f9e3c411d2831d22425d27f2849.png',
    id: '7197',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91a54d4a33861069bc2107477fde666101c7d5526dc9f963292ac60b559f5857.png',
    id: '7198',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52781ab3c1dcef637350e76b66b3dcca204fe52587d7fefbfc319c61124ce93b.png',
    id: '7199',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd3c5d06f77464406db7c7bd8526e05cee6330c3ba36e45d2d3d21cbef5a83e7.png',
    id: '7200',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2220ef5dcfd243482f6ced076202eca84e4fd415b473998e14bfc7e2e52ca00c.png',
    id: '7201',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/539924fbea84b4faf09b10ee790af1ee969a959340bd77bd290322a25ff5ed59.png',
    id: '7202',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aea3e9b898ab27b72bab919bfbcf186a0695fb7a642e133aa814e6fc4b5b2001.png',
    id: '7203',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d998c1a227dc04a04d5a2f8599232af3c865f1ff365a4dfb613adffbc0d00fcf.png',
    id: '7204',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d71d87f6bc8479d7792db9952a8fefbf8e98fe76f16a50dbba46826e3d8eb0e3.png',
    id: '7205',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/132ccb5b976f86f7e87b77180aac251a5dd1c46ef829b0993d66c2aa7c990e44.png',
    id: '7206',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9c0463407a1fb5038cbe2a6c5b6df0e193a2ff46a3899c972ca7cfbd72de543.png',
    id: '7207',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/627b4e199dcfd7c276e88d9c47fea158cd455b329131e82c5c16d3ff4360990c.png',
    id: '7208',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Monster Ball',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b4c59892cb26c1a9ee510f4a1080e40c8d34813d158b58d67cd77f344d83212.png',
    id: '7209',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca2f62d3cae6a3a535efb34d8607bf61db9734bbc29bf0e7d2c1d93260f1bfcc.png',
    id: '7210',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60ba3142c12b35ae105d9857fae1e1fd846253b7a23cc0c8582c8a042a9e6db2.png',
    id: '7211',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/faddc69e9f389e59fec0c682fae1e4c5d59c7c7a7c0caa401657d2b9e7359311.png',
    id: '7212',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9179738acf1489b8f1e66360a69853e89547998b5009b0e95aa7ca17221daba.png',
    id: '7213',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/424a7f05f25341da1864111b0bec7590e1ead2478ac08606c689c97944165c2b.png',
    id: '7214',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23c79b72d147c285802f587dd9466cba40ee39248108f2b21ad49be7bd80b462.png',
    id: '7215',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ad5afb7871886cfd7eeb0b4addf3d964ecc00a486de8658824f126ff6e88896.png',
    id: '7216',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1ac7c8138fb5e422e278b6988ec52ba9600204b810b5f7be51eb2a916a6aafc.png',
    id: '7217',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2311315827b078a2e5979f520601dfaf4133330fd35065630ccdc1e312a04681.png',
    id: '7218',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38a7cbfc7b3329b0df5bea16335723c90f90c1cf317932e1147ecd2f645c91be.png',
    id: '7219',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3bd9c222599a8ff45a76dce39466b804c276dd83a9e45d0d5c1ac9f46fc3b82e.png',
    id: '7220',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15a4b36f62050b91e80e2dbec176ddf28148753ad9224307cb4df1fc1d8aae14.png',
    id: '7221',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b3ad1aafabae8e1fa5f48572a0739425b6304ec96dd4db1fa9e2a62b3b1fc9c2.png',
    id: '7222',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3302c89eb9f439be6ddcd59bfb874c6a8acdfe663c51efb9b32d372298a99e96.png',
    id: '7223',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03a97af12f011f247130d9aa3beb76c9cd8488860dbc76eb23117a670027c3ae.png',
    id: '7224',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f3fbe9994e653fbe9210e77f4daadeff15229e1f53c21686d115d87ad725883.png',
    id: '7225',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/174c6605204e849196d15cd4cfc56c3eb9cd1a5fb7397c71fd1296873ba0109f.png',
    id: '7226',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dcc0bb56aa0370680fe2b25cdc06d4bca8415fad063fb097ab5bed98cb4dd5da.png',
    id: '7227',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14239ad919ef575c7d611aba6c114e8f797871ba12cb4b055c349cb47a82f253.png',
    id: '7228',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f01edb799e79fa0ea7e352d6565acb78d04d7530226a2b3b3d5675605c76ca8c.png',
    id: '7229',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c581e8a19285d7988fae21e5ed5d684b59bd614a7c8214e0f51ac0aa87cad581.png',
    id: '7230',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a86cf882177ec879b083239085c1c1073370426dc33dd96c1564e5d7f7bf105.png',
    id: '7231',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85423d21e7d6f7a69d0b37ddc4bee80411ac62b1a2e0f0e36bb79471290aa3e3.png',
    id: '7232',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db53bdaec980f6ecc665e4a267aa63778acb3f6510235e77fe446dd12810c909.png',
    id: '7233',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41289f41f4d152169232501f35af793cdae4de799bf16ca48cc22eb09ef52a7e.png',
    id: '7234',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a19b79341981e77ec7730fddf6dae78e657c7eef507bc9a6ad57d7e251027ab8.png',
    id: '7235',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c37ab6bcc3ee7f9ed00788c060ef54bd2b0efcca9711dc11f16e59775d01503.png',
    id: '7236',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ec8e6bba96cf41c60b0f76e4dada5c53dedd683b244a74b34b1ffdff80d8e84.png',
    id: '7237',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e506d913a02666abeff9590a411fe8e81ec9f0971b8b7c04998961c82a670ca0.png',
    id: '7238',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cbb667e200c7f6e8acf4f7c0d968bd98282570c0d10aba5570c30b74047db77.png',
    id: '7239',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58de712a5dd277308ba3efb8cad456f8227b95e8c5eb7faff00402dc11f98246.png',
    id: '7240',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d113f7b771c7c5b29897d27975ba5dbbafb7e69004110cf35ff2430b301f99c.png',
    id: '7241',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59c2b4d923e7f96a138d34da444097be76dc1ada22f8fb2e3e0b2a9dfde85a53.png',
    id: '7242',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49de947b7df879edfe35effa9cbd09a2a92f3d01ad1f503af140d5be7914d12c.png',
    id: '7243',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f858ddabe171a817aaf3970d8e8b9447611a804949628ab7958af63a146bc6f3.png',
    id: '7244',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f8f0a9a1f244994e3ae24f5690b42282cdc4aae49e81eb93e1896c61e496c6f.png',
    id: '7245',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c82b44cf581b8a546acadac861acb59d785b34c69bb2680a74a7bec2fd22afc2.png',
    id: '7246',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7be5c0f3440c1f020f302f55d0afe4074edb12f58bf6b747934f6264af785e00.png',
    id: '7247',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sticky Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e762acf02d0a7ac9c7c34e0ab92875ed0da742f5f1aa107e34a06d2f1023c20.png',
    id: '7248',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/285d8af83323137ade149bc14e975a4243bdb9bd883d2876ce902c281144af56.png',
    id: '7249',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fac50b8dc2cd829c177e42de3c0b2b449e6489669d1b0653669cc5b05cc89d4a.png',
    id: '7250',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f4b231568e554579b2b22153946b52a662eed0f0a7a28a3277364d284e81e48.png',
    id: '7251',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f96d450b9ffbeff50de439217ebf4fe68125dbdbe72c4079f07ca9a5b83aac09.png',
    id: '7252',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1be726178ac5f904211a373b48500e32a0c854783b05264049c99db7772b29cb.png',
    id: '7253',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47e56ba28861b0b2754f11b1abfd6a1db4c2e8411559373931a752b7931c325d.png',
    id: '7254',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e23803114597c8022616cca8d00035b0125a7e01eb378d4dbc47cf3f8657675.png',
    id: '7255',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6cfc1e6f77d5c617de16629fd082a0dcc59f901c0588d306b939a0549e79870.png',
    id: '7256',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a77a75566997518635e68b08c1c2a352672d1ae23d60b3e8ae46615585fb8b0a.png',
    id: '7257',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5d2307ddafc825955efefe30ddb5740641bbf8b4856b7a1543fdfadc43a259f.png',
    id: '7258',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a9dea3157b488d7f52e82eda3c4e09338d12a27db2a401fcf6cb63a016e7903b.png',
    id: '7259',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07e4dacc61a63981a1418ca46275061f67395867eabe4f211307b85ff6d5a016.png',
    id: '7260',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83dd954cccde511aae677f807a191051628193d3f49089e3e48460917b0ac907.png',
    id: '7261',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9969f944cf6941c3e7b2da1712c1bcd0c9fcf727c02a7f19cf97c633f499c201.png',
    id: '7262',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/226f68cf177003e5a9dd73565d90bc2938d48f0ee880ebc0546a66c65861b8fe.png',
    id: '7263',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4c9128bb8ef06df2dcd8e8d01883ea88f884472c5e699fabbb616a067265835.png',
    id: '7264',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a8219929c66045d720720b0ad386834795726f8e90d08de969cd06e20e4bc74.png',
    id: '7265',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ac3b0f33bf73e073e55132621f86c341504ba36153206e3a0b8c7a3733b47df.png',
    id: '7266',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30ecd2e08ba30e69b40a967402e5142560b9047661ccc235289a4bbf5a5775fb.png',
    id: '7267',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b586f4355d5f9dae2cedc58582f24eee2b28df14f0fbf17b86fa9f0bb95a98b1.png',
    id: '7268',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf31581422600ac9918cd93e8528482770f4217812135399eb952aa11e0adce1.png',
    id: '7269',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b87eaf40e7a39b75476664f432b9be284bc5db7300caa1b28819bf1fb89dcea.png',
    id: '7270',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f5e4733d673978f3868733c487085535f3b0b0913f26e28105ad31fbeab17b2.png',
    id: '7271',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d4c882e5ae8ee319d218c234ef0e7af21e0738617b89e704770aa022da83455.png',
    id: '7272',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/935588e597f3959cf098068f596b435f434b81c0b547478e3bf82011789d4342.png',
    id: '7273',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40eb17f60045cb9e4c0d1319736c7a0ccc74e49d10db1c10ce0969b0e5518d8d.png',
    id: '7274',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/773317b185822d153979a32ea65b879c2179f051fb2c4d1ff8c3fc0e4886b407.png',
    id: '7275',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55300fe0c610d5dd9e92890a3ff0b58969639dc845068f62481585de260be890.png',
    id: '7276',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ff378b929ead16474f20268fe17cdb9fe11c1ada227156c6c94f656651ba8a5.png',
    id: '7277',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07122aa628b6c5888f6b1ff4c35f15d25120ab0bb03d8df3827d4da8491bae7d.png',
    id: '7278',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ec8ccc0a390b2312351078fc8abc05134900a1bcd8d384ed1f1416315479cf4.png',
    id: '7279',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89f20ea1ae333fceb0bc30e5aaca4b95466464794b7d18764ceca9628c74f225.png',
    id: '7280',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/165b6e4a6150fddee93c0086d08b3faed15f02691eb3154b2a54675a7c0031fe.png',
    id: '7281',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d3d3044b12bc1dc805693c7292bba29ab80c5ec4e16b084b47104132f0c8dfd.png',
    id: '7282',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8eba61274ac544a7b711a47275624c1dbc4b0d565eb1952d7af5b33bc8481c1d.png',
    id: '7283',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f41fd3ef3bb6868fb04d39dfcaa87af06cf4d22afbe5b3eed7c6771d2fa15b8.png',
    id: '7284',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f059c2690de50ffbee5616df17a0599966a8abf456b0e67e90efbb5e527975d.png',
    id: '7285',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e1ca760f7862b1e14eb0b29e809541aac3f45c0224e3a32841f07a62d52e601.png',
    id: '7286',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87f817931f47827dff42ae6a45a26591b24a988f6a3d2c53289af77282b7d27d.png',
    id: '7287',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27e864342e1533f77a480d09c8c4fd81bfca50034164aa498820d5ffddd5c818.png',
    id: '7288',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e64969ceb8be4a57a4184ee4a68fdd22e6404b35f9f285714c9064f6ffe7a957.png',
    id: '7289',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/682728c184e63a874e5f024e7632db363fa592d1fb869b26fcf06701bd5eba4c.png',
    id: '7290',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e84141c6ae917f84c8d6e5d6bf71362baad6ce83077eb78ce29a710dc7240ad.png',
    id: '7291',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0937e3b83377724e93ee8f44157d4eeb22c8e3181ccc25c576ebaa51a8816ac0.png',
    id: '7292',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3bf7377f50e25316177db2fe4cf9221e78ad9f9cf85d4d2b72d2af61c4c89810.png',
    id: '7293',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5094bdae792035a3048780671c1e2e1b71117a3cb70d8fa9ef9c1177d9e11428.png',
    id: '7294',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8780d4453bbd8989688e4ef0a9651bb1e3affe91c7d8b9a24730738c2e2ab67.png',
    id: '7295',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f78651b9a0edceed527858c2fcb8a64f0e75341abef1d2fdde8a1103dee4468.png',
    id: '7296',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63baa1411ecb9f36c490c33d698ba09d6d938aae921bb0d4e37879d950fb45a9.png',
    id: '7297',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/970d598c274f04a5c21a3a67d8fd12dcc0138cd9ca10ba97476769709bc7e090.png',
    id: '7298',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72353ba28fcec73e1de0c4e82923f6de2d2ceb4d1bbca605924cd2eced2e1eae.png',
    id: '7299',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bedc7c5cc35bb0f2da000a7289007d0e1326a467fc54d39ba9474c5b16672846.png',
    id: '7300',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/090f8ada931df8b3e6c72166b8987e184868df92e5eb3d390207a950beb0ad20.png',
    id: '7301',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd9ebb5cecde3fb7276bade3f57075453dd4c54793ee0654d169ca76afc93e01.png',
    id: '7302',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8351b8fa989ecf66efc1bf16952339d3a87066325a7d6d1219cb6e4d1d0e0bc.png',
    id: '7303',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75eb40274a4de05f5d46981d787baafa3d064e0282e68c2c54fff0bd5576d0c5.png',
    id: '7304',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f607ba1717c59126d7c8d4d864adac86c4ac6605347cf3d88439938f21680f50.png',
    id: '7305',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00f96fb213eec014d342cd31f14a9484cf7db206940b695ed7b8eaa60a8bfc82.png',
    id: '7306',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c355ecc6e12b40366732a05d3ffc79a26b35fbd949dfba0715b67d57a504306.png',
    id: '7307',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77c6de83b5448cf4eefaaa6f075902ec8ea5866c6cd7667d1128b4cfdfb95af8.png',
    id: '7308',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/362ae7e50367c135382176cc565c8edf7fce80181aead5a909db39d06f2235d0.png',
    id: '7309',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f797c2a7f6be1f7b83f0e3ec38cb6a933096dcebc2285ce744d9ae0ac03ff2aa.png',
    id: '7310',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb7ef14792931cefaeb04aa14e458202fb4a25922a0b2472219d11de752348b1.png',
    id: '7311',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f996b2f6c6a695bec47dfa698f43f17ac59a48b8973121cf5a8e73f347072fa9.png',
    id: '7312',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff37ab27b03dba174dbac1d3aea7c3d53be886bc6d3507227e0f7695081841df.png',
    id: '7313',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f82b0fc3d4793b7ebccd61553d76a9eb2c92b42c42b2a61b93bb3a86b81a5165.png',
    id: '7314',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c641c7833f2f783843507637749887b307c50d08c609d2447f13bdfad551dc24.png',
    id: '7315',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/152881d562f126e08eefce66ac9688ac77fed9c15ffe19a2fb443b4429a790e1.png',
    id: '7316',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2b7339cebf87da84327029b2724da1e994f79889fb884bb92d32b53c2cab105.png',
    id: '7317',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/151c7a2992f13ef6518e49c4458e1c3e9b0077aa72d39950c913d731a351c032.png',
    id: '7318',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3288bd5a1775ee454b57d2670e72bd2a6b8f6f2bffdea53f50e2895346ce1360.png',
    id: '7319',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/989f86b89bfb860940eeb154449ee749976a2d3cb1f4207997ddf64c0e205833.png',
    id: '7320',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec78dd9708db5feace276023c5b5437d3868fac13d52a382eac67e4d7f073429.png',
    id: '7321',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/46342b2680f6ea75665a06cc97442b7ca3486134204d03a80b6b088187c824ae.png',
    id: '7322',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e03797794aeabfe071426548191bd0af69d84df9cb3b214a2ef7fdef5d9ff99e.png',
    id: '7323',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7be347e47be0936d95dc4d2e809c71abc2422ebadf415809a21b28049425b9ea.png',
    id: '7324',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2849defa7a88fb0f19325466bfeea864d8abf0383984a501d726aefb87440118.png',
    id: '7325',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0611d627b5c586e943d780ab3afd7bb1b285bb3a1d53f9f963bc4e939d239e43.png',
    id: '7326',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70fb949b632b418fb36efcb722233b2add472eb99a7e80987934221e9362331a.png',
    id: '7327',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f4d483e70dccd0d5ed514f65ccd72a9f9d6d51dd3cac8d8f4ffa001c38130b0.png',
    id: '7328',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Weapon',
        value: 'Gaze of Mysterius',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38277f811803617bd4f86c848a54fa885eb3cc68a283688dc1863bc976122b2f.png',
    id: '7329',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0c71040d5def993227eb03d35501b13251c4845826acaae42ed9b2a01e05146.png',
    id: '7330',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58a5c7333b6aea09e8b38729ca471945f6abcdbf821574f5d115f7aa80c6b655.png',
    id: '7331',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2b036245b4e8ed4d19c31ee406190ed9b4f0f3f04621b91b20ef9f92e66d022.png',
    id: '7332',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2a7808c6f8cc48f147eddc0f73be284495f2b2e94e47979cd694a2313dbbb48.png',
    id: '7333',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69bc979af55540e940ccf931a48a1e3fe4410a9a9b88d4dd70f8156e6d8073dc.png',
    id: '7334',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89cf3b99dc1f28cae6fbf2d72e5ad1b9b8fb35b9e2cd05a2db3b7de6fc287402.png',
    id: '7335',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5a8e27c60a7b9f8e4bb1c527985a0c22b13bebf8bbb9c80613e1bb28b36d4c8.png',
    id: '7336',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85debf2f1418b96d7085bef57ab594a516ba47fa15700c96ea1eb971b6ccbb58.png',
    id: '7337',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc5c8e3466d5d85aa83bd28b764c892da9c0d1d31b3523b951c11b0016d09987.png',
    id: '7338',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42a39c53e331c4025e5266a33f5a82f514b74e273e44c47a6dde3fa8ce814016.png',
    id: '7339',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04ae03aac0a9a0beabd3c731495eb49aa1650baac0b5619284fd7c428cc23d17.png',
    id: '7340',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c044b59ade2ba9686e99a902cbcac698c68609735d242e774c8dd3d59a6e69a6.png',
    id: '7341',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/758881d5dacd7213b0dce2a1edc9e3b231e65aaf3e01947e74ea1cdcee1da99b.png',
    id: '7342',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba14c0cbe30cd9439264669f66d7b41af2e8563419ced60309bdc8b535ae327d.png',
    id: '7343',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffbf1ea63d55345cd2f275abe2f3839530226a7827a91c72a9ae6e1e1e131050.png',
    id: '7344',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/004ea23e1e9d625515f6e4ef12c31690dc82bdbccee37f61e71e127a88e5acc0.png',
    id: '7345',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df1382ccf05fb01fbe43477095d1e2be754689dda61b16d24de2a217af1cbde2.png',
    id: '7346',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e73561984fb837bd4133d64be0179b787578d8f584933313470d3244fcce1bc.png',
    id: '7347',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f1e8a0bae7d33db7f099796a1ab21dd0db6d33c467535d08a77e45422a75acd.png',
    id: '7348',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0bab50cf7113e9b7e658fcfcda06e390c693a7f2ac7d56d56914e2ed44190cd.png',
    id: '7349',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/466281485410aa1bfc92cfad7777f42a9be86fadf2a3860271fbb44ff76d6639.png',
    id: '7350',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/842fff83263831e05aa8515e134b9ee458fadb0bf9304df909f4b1ec159de306.png',
    id: '7351',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7ec8527833acf2b01dfd6d39a9dc26c5b45a5335290110107fdf7bff4e5f605.png',
    id: '7352',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98020b0e824ae79dc4181968f443ab65db4f168e6c60bf8665d2bcb00f915106.png',
    id: '7353',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/efd9de6be802cd28949459440f64d4d586e7ee12b73143aa6d15d620a498c843.png',
    id: '7354',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7edf6bd444630406321e4aebb512dc115897a38bf491f9f6c6aa77be746ddcb.png',
    id: '7355',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17542816dd2e800f93e8b7968e3c4c230800b88800b0c589cffd07dbdcd81267.png',
    id: '7356',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6527065601f3b1e44607b6a73f219660d9e8624cd99d6c7f631398fc237cba04.png',
    id: '7357',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d23644de25bdb5b7f480e6d8ccf8fe9c5a7b8912dfa4d44398f2da22ac5d9ebb.png',
    id: '7358',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/302fb93fafcdf6c15d611efe50169a6631cb64afb637e990c7794ac1b23794aa.png',
    id: '7359',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e21c6eec59b816418e46c6309c42cffebde5ccfd757b0fdf9ca2375ab9e3cba5.png',
    id: '7360',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0efae66d7665f19012180329e40d06ebb052832665464f1262d0698918106de.png',
    id: '7361',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef8dedaabe0e88b8623e314032ba6d8e03e832c113ca7ace912cb3636d88f800.png',
    id: '7362',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b9bc65841022d3d8915a8b24d36980048358be8e2627fa634a6cb6ce41ab036.png',
    id: '7363',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/603576f703c4852b9b56cbd0cee78d7d58819747f43941320a7e51ebabb32321.png',
    id: '7364',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc557ab5cd6e8ec113679f9cd33ede8c38be76bd7cf51f2c7f8b7b352c05e167.png',
    id: '7365',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d848c832b0b2abf3c0e07623a5de08eb078ca7d20b52b2a5264a6b498fc0d94.png',
    id: '7366',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5ac72076722609cdd0a134622176c442f1c30e006accdc1e6eb26ed36eb81f8.png',
    id: '7367',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a67b1fdbaf67cfe887714cadd6f7e6e3775753dbbd1765d5c84a3822e55075d.png',
    id: '7368',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/695545596bd0cd00da9da70bbefd29577b14fdb207dd1dfdd715c7159b9e2da4.png',
    id: '7369',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81d9ef954b5594a11cdce9b1b32c599e37e7e9a1277625978c8d285d3f78dc06.png',
    id: '7370',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d0a0f71202e44a2cd337ebb4a94de290b1c184d9134eb0b4f5153327db937dd.png',
    id: '7371',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b94085f75040f55720603e04b697c0ca1a6005e00ade0a43398cb6d0ed5789ad.png',
    id: '7372',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0dc3e2aafc72627158d28d0b7840a1adec075e2b5fada4a476040c998647b8b.png',
    id: '7373',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/041aeb24fe57f0408a3d8daee438a5081f14531660e3e29362f349e265c903f0.png',
    id: '7374',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f2f47d06990cc6813e768b2105e0d8644c0938f536d7617e1aaa4f834d59490.png',
    id: '7375',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a8a472f0666829308644959e1439bda3cf5138a6ef1b6081f11e95db2e49a8a.png',
    id: '7376',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f888c33681c120d1242aed9a2bf1c4ba6b0dac01609ee26a1e9f351ae355d1be.png',
    id: '7377',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Monster Ball',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77f40467e9dd93e698946171d7b5c5c4246a89751bd6bcc84871573d2c819286.png',
    id: '7378',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5228ea31001f23be91476489f9f14f442551aa252bc87f47c3d8eb84827e9d2f.png',
    id: '7379',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Explored Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/847c2ed196e48a9d38c924831933f1cf703ec314f015b6a8be91920dff2ae343.png',
    id: '7380',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4348ecb9add4ef991be15f7ca5539c214bfce6526660c0f1cd8a6cd38e70135c.png',
    id: '7381',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca5bb84078004c02a43597bab12f38a1f9955b0973e3360fc19b1fa084f339bb.png',
    id: '7382',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2078d0af8b9a19c68192bf6117261dde9e304622f66c72e349de4bebe1abb62b.png',
    id: '7383',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5fb3fbf75eeb393a8bee7e07f66dd27a0c838150938d9e8613a414c995a82ed9.png',
    id: '7384',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/101dafc0a648c97d7d273895ea88a388ec05ab72543cec5cfd95db25fea66a14.png',
    id: '7385',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/731ac8fe5d5b6d49f52ec1ef381a7dfc4914bcd7eeb50dcd2964369237c4a3e6.png',
    id: '7386',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c25bea4c0ae8d1bb7d2698662055157dff1debd1718af1a1e265b7c31761e05e.png',
    id: '7387',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57d7a701597ec03aade7127959615b73cddabc3cbc1640fe02f1fea76b5618a6.png',
    id: '7388',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/21ec46c666d5b5e4dd0a4a1382c1e0f3414f438b3a1015c0ac0628a0f2fc3f78.png',
    id: '7389',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd3fbed4cd4ad864198b86f873eb778b3b10d46b968e19445179138b1827d73e.png',
    id: '7390',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6adbe21ac000d543607b53d174d308738fbfa6c57cb49d0405bd6d77d0ac03f.png',
    id: '7391',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23734139841a8c4fe88ac0272c60347a68f99230342eab3d148e544cec36860b.png',
    id: '7392',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18a9857e751c0ed275a7a816707991871ad5be99a81163dc3c5d26df7d29b0ab.png',
    id: '7393',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12da4a71c1b40e33f6ca0c8c9c3775615cfc503f3720a61603e55fff0cd2c2f8.png',
    id: '7394',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b94704cf9a8392aa7c3d6fcece568d1ba9d671590dacb8186e45b3f4c99c56d.png',
    id: '7395',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Adventurer Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4674ad3dc04bd4d5bece88a6e3925caf8740104ab90c9e258f4b88a5be2d77ae.png',
    id: '7396',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d3bb789e71bd7ed0864c59e5f7f128a620b6cb90df49f374a717667b72866fd.png',
    id: '7397',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/492f7cf0b2c9bf67c59e18ad91120f4d11fad45fb69e05d9535a343fcb9cf270.png',
    id: '7398',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d057d6c3d63c9d671d4de4307bb05318085c6c7b9425336d66b3dc784caea5f.png',
    id: '7399',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2af74ca9b1e1d7e19c4047e0c42277df896de2ad35179fc2213c215cfb41991.png',
    id: '7400',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa76af67535333912038a9be59dd32ef9df5d89c4829fea79941fe623659de09.png',
    id: '7401',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/082d2ef3e394855ed2a47ae2428ee30f35c45c6afcca6b7761224d536b54afb6.png',
    id: '7402',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72fb9ccc3907e603fb5d065b193939822047b7383f0f657afecf5c9b656d4831.png',
    id: '7403',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d7b958efa5c1b44f8771934dbdf00e87a055e9cd14e0b727891ccf3661a78a2.png',
    id: '7404',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/880a4ecc3e3c89d1e92ff927181b4a97f42c3b0f2f870e77718a75454660019b.png',
    id: '7405',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6dcba56d022595fd20746a7071bbf3278f5611ca0ed693d42ff1ec15ab312647.png',
    id: '7406',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d6e5c04b81eed435151b2390d291c9327b05073714e2722e954b248d0f608ea.png',
    id: '7407',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb92b8bb683ab04b496183a49986bc00091fe0dd59dfb561cd9a786b1bdcedd1.png',
    id: '7408',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0a2d3c4d654988246288dbea7d0da008b5860f51c5758af97ae5e51c6bca950.png',
    id: '7409',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d35be03f7c44b8e0bd285a035e3abea74337e040e24636bdd8ff1f232052ac04.png',
    id: '7410',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3592bd087842e16cc94cb322954e3b01205fd0af7cd87e39b6844c80c7305e9.png',
    id: '7411',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffddb6bf8a556f12ef5f3e1f54f335045e5f95307d4e9ba23de5aae25d961187.png',
    id: '7412',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5909f5546da19cdffa5cc61b8ccde2b91f62c91c5debb198e071e0473f13aa6f.png',
    id: '7413',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/962fbd8f501c3230e918e505331172d544bb01a975247e513fc0dc73b61390b3.png',
    id: '7414',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0bd59d08745c7f5a404fedac5b4cf2d2b9e5ecd36fb4d79de28bd1e950cb88d.png',
    id: '7415',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e4140c5e4e789aaee1b22ec97cbf9deae03860234d9d469063adc17c9fd1ec7.png',
    id: '7416',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8651388eeb488f556386c51ab450cad7d0adaa5e1e9577b76d30d60f85c2416a.png',
    id: '7417',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/822f911d1bf0667027930b3d90c895698063d9799f08632902ea245d8994c2e3.png',
    id: '7418',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af2bd36599fa0c45978349d31d7f82fd4057bf9cf9933c7301f0c38167a08f1b.png',
    id: '7419',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/053b4923d7d787d290f10439d6055776cbafe09caa8ec671abbdb708b42787e2.png',
    id: '7420',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/382ec60d97e7251ea2121d28aaa84f36b7a688c1c6fcdcb6d01cbdabaabe14f9.png',
    id: '7421',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bbb4ead460010166f3180c3da1acd05ff59306a151936a9b58b67ab98cca951.png',
    id: '7422',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a4e1a7d0710158bb2f798e7ef1266caaeda86e16d0f3adc0644fa8a1c50f5b3.png',
    id: '7423',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b71ba23c55611af25e5dbbe2e65acdb8b1f8db3d84aca6a72eaf4fb99a9c0ed2.png',
    id: '7424',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20c0fd4a04faced5b1c09513e0b9f9b942de11c9a4fe3b4d7b7f766857bc4d1e.png',
    id: '7425',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e120061031d8088052fbbfbd5f650da6f8f3e71bd84c6f78886467cbb8a5eb7d.png',
    id: '7426',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4428dad40bdc81242d224fb85c65098bb45f6de57f8beec6e9685c583044192d.png',
    id: '7427',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8ad0a32f0e21b29d727483ba925340e44c6afced6d8332467ea735e55e3df73.png',
    id: '7428',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b0548091f77b2420fdf1a388cf8d621c5707b3845a2ce68c58778d7debd3a6e.png',
    id: '7429',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3787bd591b268de0186c1f54c6bd413c23ac6c6c4582eef1f368f37461246e9b.png',
    id: '7430',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d70e94407a9edb9a021c31cdfbe0022d33ac5672ddf66e692928500633bc8eb.png',
    id: '7431',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/adeb3d821d2895ce3a9ced76b0004117a6fa8cbc3001b8d4b7070aa30a2c7987.png',
    id: '7432',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f296e636d619f629ac99da7e68f8c63347195457d259f9dee5a74eb3cc0335e.png',
    id: '7433',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c4088b0d6e35f07b127b03f5ee30fc3381c980b25c2f48432217d08514f5618.png',
    id: '7434',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0b73e51390cad2b76264da5c94a9e2ec1e79fbb3c95ed6733287b1ebfe70278.png',
    id: '7435',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7330c2bce4fabb65e4bf344c4dc7158cb9bb98fc0fec8d6da62c9a945f00121.png',
    id: '7436',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58edc515b30ea5d35be159efd86cf36b9b137b0789c75a36a62ab58b8dce0ab2.png',
    id: '7437',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07583b3fba8f4f9b561f4c15b14b1a2e68b6a02206ee93eae149c86685df8496.png',
    id: '7438',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b17bcce9f5e5f72df5cc4fb49caba5f967ae2e38c95dac1754bba1313a3dc736.png',
    id: '7439',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6759c65aa7d25c3ea2748b347c6f091b510eda5b0316f3bd5b22cdd20232a5ae.png',
    id: '7440',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17c9e0b16e98f2863fe1cc194ee80a6d1804aeaa0494d5cd38c2e899bb62b554.png',
    id: '7441',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfdcffdf3d2da39f875f75d540136c5a05969f1bc2afc64eee2fb9a7c0232016.png',
    id: '7442',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34f22d7a4a5ced888f222568176fe7087c22a7eafd97c7f71847275da01de00b.png',
    id: '7443',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ba54862276ef659afdb944215dea4bc032d954c418c8ae931d58ac44f2cc126.png',
    id: '7444',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31342f501c018709aaa163cb94ffc066035fde883fc7ecc290235bee60b3d155.png',
    id: '7445',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b9b2251849905ed7eaa1598b1e3bf3a704459dd3a3b1966947760c949bbcbe0.png',
    id: '7446',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cdf271052d5b2069b4d10cf8ab581cab3a4b4af59d3fbec3354457afa1cc33a.png',
    id: '7447',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b77562bd6f460bea0c1a160d8556f12ac27f904bc8b74c574381d333aa2ddd6e.png',
    id: '7448',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3d5891a656d9e968f7d518af997ec9c7ebed92874034a8b34e7b8f5a69ec94b.png',
    id: '7449',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1c86f4c6a2001c699ec6b5cc7def08e7165cddd4033bab097a2822dfef7162d.png',
    id: '7450',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/61d70d579d5757fe8e7aa778d15039c4e16b7e1de050f7ea60eaf71435f840e2.png',
    id: '7451',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84614930c1e47006c04a565d13718c55b2fb142697283d3abfe9ef1f27030a09.png',
    id: '7452',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0375843f411bce27b6b9d2a64e2dcb8a62f29b89c868499a4b971bb5fa65e07a.png',
    id: '7453',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7d310b69bd2390aa18938b560d3eb3e8ada6ab7aef0f8f65b72d99b018e8e97.png',
    id: '7454',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a90ef35187364228fb6d488d04878c32671daa68ee981566266fc803c0708f11.png',
    id: '7455',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38b1fe6006b16d4b4cc754903562761ba9b150c025ecfd5d48c495ea1c5ebd5d.png',
    id: '7456',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a8cc135d3e3957e77b6c17bc6deeaf97ade3d8fd3a26bde33c2fafbfd840cda.png',
    id: '7457',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4f2ac9bda3571eeceb3b6178d6440eb82debe94a930dc511c752dea3618b556.png',
    id: '7458',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5bb55ef4cf4f0c544a3fda18eef441d0ddbe6a5f1ef723974bd728d76c1578f9.png',
    id: '7459',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99ebbd7ccf504d99d5b92aec5a46a53aedeba9245e89cb3cbddfff278e9021f7.png',
    id: '7460',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ee9b50c77d6ef3a4f63c4bc0c3759ca35b70b6cf430e69afea9353569b12df1.png',
    id: '7461',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c20331be45df1c220a6836c053b4ce27b86e1b51ae0d82b6ced401a4fe33655f.png',
    id: '7462',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92e06c7bef67666f7d96eeba573560b660aba3713fa5d8dff3b7dbbf583b1d83.png',
    id: '7463',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77f25f14675d1b4e56babd4073082de469018221d5eb632e281165598fa98997.png',
    id: '7464',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bb6a6e2e0cd81f1d18dfef01dec4d778f7b03d31f08779d47015785c6ddbe97.png',
    id: '7465',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1535f4d3e3536d57de25bf7b78abef895c7476a1849a5167b2c5ef6bf3423b9d.png',
    id: '7466',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5d533012acb5750dd20cfc9b3e764e0aa8d21ab8001e3cfd3e0f47decee9782.png',
    id: '7467',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14e8ab2aaa59441689829d012ef900dd2b17a902525331b13da953b2e6b8e586.png',
    id: '7468',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffe3bbd2e8a33287089a66c170a011389d8551d7ac9fad8ccfdafc1f5e672df8.png',
    id: '7469',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67d66b746a85b5a9ab666dc80b16ac65e62fc49cf865bcce0967b3ce4180dcf4.png',
    id: '7470',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/801bf5122447424f8118850273786d95775b8dee3ad3eabe39e83cd98303095e.png',
    id: '7471',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f98032b8c084d43f97e8c16ce282738fbce26c0ee23fbcf85305086a2940a28.png',
    id: '7472',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bbd407b8f09f714513d83b5d9859b01e0424d44b78f9f0528ca822c015cdbd2.png',
    id: '7473',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7fbe6ca1450ac6456e76faebea339b581c7ca6b6158386131c7f235dc067508.png',
    id: '7474',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e56f694f6dabd143cf2f009ff15b8f3113ad9c0a0f7f96eed158c38cc087370.png',
    id: '7475',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d5396a823164ece761cf46672cb03379d9475490e6e195e5707bd788cf80fc7.png',
    id: '7476',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/872a283b62e5b80407ef0e93cc9a635d7ddb647ce595adb0960a2efb411d8652.png',
    id: '7477',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63847b78fe6fd0812a99ab17971a8ca688464b036efdd2915758407f0a7037da.png',
    id: '7478',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/993f62981e06873502f6be0591e4b1eb5767145fecf555394d11f9e63a3dc934.png',
    id: '7479',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/721d1876f3f7e19f2de777dba394a1fdac6c38f4b486c9e0289096688928cf2a.png',
    id: '7480',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9dd3c083fbb8add6cc533378e24b99ac2c068b8bf3fcb3e2e3392880751635fd.png',
    id: '7481',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3819f9e18d7bd7c7f79a6047fe18c42fbbfba0e8ac70f9916875054fd7f40492.png',
    id: '7482',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e3334a2a5fa5d20c2e3a52fbc6345901160836d37443ced79bece23eb9f904b.png',
    id: '7483',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1339255df9e2f3b077fe306b126f58aa6f07ed518208168b884768ed41f1ccf.png',
    id: '7484',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74613ce5c1cd112575a2d87d9f08c0564a348ec769c0af5ac6d3ed4f72892b3a.png',
    id: '7485',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a59b157705f4a0e2c9ae0da78e3e5c13005950b07243c6413e7471f20d73b90e.png',
    id: '7486',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9392bf62a583f7794cc414100ba42118dccd13e850d5f495fbb01dade88601d9.png',
    id: '7487',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41d3ebd09df690d00cd0c69ae6e34a4e3f8feaf4454b5fa5fea7168df4400995.png',
    id: '7488',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b2a17f7bce05a39b298a2d0196169f06829a6f0fbaee2d91e0301a776ff2d7d.png',
    id: '7489',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4482413c7ad43aa8586f4a1640a3ab115c82091e3af66791a320d724ce9ce217.png',
    id: '7490',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc2c2689d64a291c52e9e69cd4386935b64dc7523b74184867914ce948600412.png',
    id: '7491',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/333e40afa05e25d22bbf97bcb5681e0c7a3d8e6c81b1f7754d25a3ab929424b0.png',
    id: '7492',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ff739036b85ed60e80402709d49dc47c9533ea10d33957bc58558c77c5a8a3c.png',
    id: '7493',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ed9f7f399d373fbde15f44b33e1908363758b8a0c0956d938082f670352f08b.png',
    id: '7494',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e07f5cc71a00ff9948fb6969762e58c5b7a41dc76b16ababa5b8a4a332921872.png',
    id: '7495',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe2e662b49258aae4e40fd0d42a0173537bf125383015ea44ac1727acbf99c8e.png',
    id: '7496',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c78003a3b44083f27c65290e40ed0e407ad453fa2a633cfb81a8a3cc7373fed0.png',
    id: '7497',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d57da7a77e5fc793a1fc3c897322ee51faebed1e9af7690e63f1408ec34bd530.png',
    id: '7498',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Weapon',
        value: 'The Tenderizer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca2bb35fbf1e8a3ff6c51844d9da6a90e34494232c30e0f266e3cf31774d45fb.png',
    id: '7499',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b0d40091826898752df008f0fd26cd335b0ac1676fdb5eae00d5433c773063b.png',
    id: '7500',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/892dd9864e298c794b41e3ad721daf9c548ab5f763ee7e163b58bb3891fe4480.png',
    id: '7501',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2bdda5c4865bcdc2830b46548d8399e5b59e8f0f30a7655a799a684475e311e.png',
    id: '7502',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f1228c8c4ca0cff71942c4ec814a2ffe0594cd9de4254e7f9c10ddc8c570ca9.png',
    id: '7503',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ae2766c74d0dcb122e718c093e926fcfeeb029536f5ff974ada8c8c6c238a4a.png',
    id: '7504',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6dd1f3caef12e9deac25085fdbb14e2bd193bc34805d2f7e19844233b03d6c01.png',
    id: '7505',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9df82d7c9f6c8815fbbed5ac3e11fc4cbce67876bf199b9f6985cb467d799e8.png',
    id: '7506',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23e85dd15b4501f988a19fcbec86c80f0afaa831fff2dee19a431ed28a30c04e.png',
    id: '7507',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e886705b60915adc3d50c791cf3c65cd27631267270b52f502f498815fd4af38.png',
    id: '7508',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc6e86ca5fc8fc1be71e929b6b0a6cd8625852e54f0f3e5f16916e368fad4a56.png',
    id: '7509',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f030f706b9be8ca2226e4909d20164629c0b1720b866815bbc8a6077366c1d6.png',
    id: '7510',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bed7ec70481c87fb05fc7d6f785c6c429f9496276f050a19c0d4fded9e8aeeb7.png',
    id: '7511',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6eb59320e23bfb643f32904776caced6e9352d77acf01792d91cf05cada407a3.png',
    id: '7512',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0f0b92594c7acda6dcb80e0939791b13b5623b8d7361eaaf5192d8a9f9a7328.png',
    id: '7513',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad99c4ab5240fb6ceb2551976a4872d8eb7974c05ecaf89f2b381b0f40cc11f4.png',
    id: '7514',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c64043eb491c28f6ab403415a2110d6f0c16ff810102759450e73ddd272c297.png',
    id: '7515',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f11d0ad06ce3a4295cf3bcf89236c523eeff170a0d5365242a79f6fd110434bf.png',
    id: '7516',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b676826f1b0f55f62e60a7c422ec9ae27a5ba24b9198d45edfb35e470892e31.png',
    id: '7517',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d201d7cbe38f16afa14964e6609299bf0a0f6b45859788b048f5c41e11e8740.png',
    id: '7518',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7d37b0b54c458d5a258bb952a6ab1f27e03691cc70f0982f7d73442917c4609.png',
    id: '7519',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6525dd2228656d79cae7059b813df8007b2135b84b6c698b23b08c8bcd82bfc.png',
    id: '7520',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2537002097f42b80a77b654f9e41a279105da6a576aab41f5fc7aa6575e8b3d9.png',
    id: '7521',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3346c0ccb83d98582b14d16beea0ce21e0d48bf0dac71927c01567aa078e91d.png',
    id: '7522',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e71ec12404e453433cf2b1da948ba29025b5b5531a786461da9cacf638025550.png',
    id: '7523',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8b82ca36742e667fa746ea697a5660136a7c90f17ca9dbf8a5ed57b0b1b4627.png',
    id: '7524',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5cbdbb7eb31bd5b9053a5677dcbf131694ebf26f3f3f9bd8571338f3ce517041.png',
    id: '7525',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66b2b97bb1a8f8220b547a48688cfe18450aa48f39c8b56707892014020769e0.png',
    id: '7526',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e86a570cc067a90247377ae6756756c9ca486bb50b76f06c3635b3c2994db7f1.png',
    id: '7527',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0298f17b1363de2a42e9b7f978344788f58d71f7dbf077c34e5b3da470d31da3.png',
    id: '7528',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbe5381bac45e39e5d7739d63318ea6092708cd6930aae235945b3fc0aaf3af9.png',
    id: '7529',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9522468b7b4cb8882813e948a82b7d541557a2f017fa53a3a5f11199d73e6b1f.png',
    id: '7530',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crystal Stabber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89c034d8de809c25533f6e6634d3ba8df9f95ea99c1a0cef85fdee75b22d82ca.png',
    id: '7531',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e1dc2f7d1af6895ce311c4f22125056ea613f88ac4a3cd8e924c8a023e314be.png',
    id: '7532',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5543b8e558a423a3a74ffee1048a185f2b6379724b77aa57c66842bd58bb51e.png',
    id: '7533',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26b48bc46d45abb65cebcf365ac189c59ac75e9d1814c20e5e8aef6d098d6434.png',
    id: '7534',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fab0bbda059f785428cd80dac97a853e632fc41ad9e75ec64f7aff183d1fefa6.png',
    id: '7535',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b4c139b45d52a46597f7a6e1583f86cd57e215d0105db44e9c1d9d01d6edfbe.png',
    id: '7536',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bfe86f07abc0fee67b3676085d0692cf2d8b84612ee019c266db9587d754e70c.png',
    id: '7537',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c7009e7e5b4236442f4ff4f2da84282c4c7a2759a3a36c705bb3eb87ee165b5.png',
    id: '7538',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/926400af7ce51d7b1c2bab87e7cbc65f9d53753d32282cb2bd8d34c097355d7f.png',
    id: '7539',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00a26bcb495928655bf321883e21e9e96f157e1fac16461fb79799a6c881eb4f.png',
    id: '7540',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5df78e655b25fa051699635d4faa004ab541d28d70325a162b75adcd5f179d0.png',
    id: '7541',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f67ab843c2d417d47611386b81e555fb8c0a6f88390f9cea1ba1f4e4a2130c38.png',
    id: '7542',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a70c79c65790e448958c734c490268ef9833b73fbb9c5fd1ebd386ed254fc3ba.png',
    id: '7543',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43fb1ff898dadf7badbed9b05dff361fbd5c344f3c600c1e1ea315a7abbf759c.png',
    id: '7544',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crystal Stabber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1cbf150d2380195010f1bfb197f1fc42eeab1590928d55cab7cb4ba26b447e5e.png',
    id: '7545',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3f9dd0d975b19644c88df792e8d8471e06cb9a48a1c94af0529a2e4b5a9cd8e.png',
    id: '7546',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2970e97edd153efde8671aefa8cf59ad61f30c18a646c34f1301d812ded70fc.png',
    id: '7547',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52018a72350a58f7108edcf8f23e9c246cd8ff3ac134cf97443a99c56a4a4959.png',
    id: '7548',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b7b128149b75475d57a7eb9ac8f7cb3bbef3b85985a755757dcd3c9a2388059.png',
    id: '7549',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33cdd8672d11e27ca66c4f298d8b4eb011d0cbac040c693aa52f474fc2a7cc0e.png',
    id: '7550',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4e3e74443f1fa5aab0ae999c085e95dbddc0006a8d13576cca21219cd81bc22.png',
    id: '7551',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/393545196e1ae0dd7551bc0d610fe8e73c0c9a9e256cf11d0a3fd93c2eaf6bba.png',
    id: '7552',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/528cf5d23e6fbd2b7067ad6781ff614ff3407d46b5b138ad28ff2535dda1bfb5.png',
    id: '7553',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a05064480445543321c1d7a3c0502009f00c63ff83a72538399304b88c4412b5.png',
    id: '7554',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/423217cba3b01e5031a114811fbec3b53396f2698b33eb53f7fdd422419d4c64.png',
    id: '7555',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07b96a03006a605d2f6d4a2593a157b68196c88c52fc692ccd94fbc796b77d70.png',
    id: '7556',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01d74a021deda2996b2b54c8855d41e56bd9bb7f649202f59d40c245fdbefe5e.png',
    id: '7557',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b3d9be4d523676bc99bd56b74d408e0cbf438b06c81b760ec62f730b556a906.png',
    id: '7558',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dc223a4ab077bf34230ce810f49e02747a9316f50e6c508d971c3e5a4e8c201.png',
    id: '7559',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f228b9d861a38a4c235b9f220ab8d92719a60c49f139f2680db8dd1442f5a78f.png',
    id: '7560',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/568dce4adfce69eaf4c5e4ba8c1022626103a96d9ca5480eceae0b234a6de463.png',
    id: '7561',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83e3f83d428198e4fad001c92eefdfaa7c6ef8deea5713fe2417e2f504e7fcb6.png',
    id: '7562',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81819ca7435ef9a356324fd671fb598c3bd3b7b5818108a0c143a0ce41239018.png',
    id: '7563',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48d5b06c343c1de226a0a04c6cb45e32790fe63c15897075050136c968fc84ad.png',
    id: '7564',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/503c51194be40198bcf360091741b199641a27afe2a963b81f1d130c137bba15.png',
    id: '7565',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddcfa610f2466e8ec4227670187c31ab5c9381c566368fcf9cb82eb9ccb80854.png',
    id: '7566',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4926db6e7a6afccfbd0ea44c7335c6d288fa1c26159d2781d2def5a470698655.png',
    id: '7567',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a1d44f4149c9e8fee32b21b5af5914fae02926ca9eea855ba8bc2fd7071a4fa.png',
    id: '7568',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8f6f36e9d45cd375491e7aa8fef94d7cde7fbf968fd8dbf99dd4e1ecd8fd8b8.png',
    id: '7569',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0aa073d39ab04b5650f370594119c79e80aaa68528a71ee5f0b72b9396e4748c.png',
    id: '7570',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4af9f481d9a77e00fb4c2d96df3730267197b47c352a95a6b3f4abb9f2f8d54f.png',
    id: '7571',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a602df0b7745d054a2565dadc2f4f1ab1128b0f19e0c8c34c690e0edeac3ba1.png',
    id: '7572',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da8140874d2e161230e5b680fd869ee4c8efb4a9de994354f3aa09131d29e186.png',
    id: '7573',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fdddb82cee5a4102e0ee1b13a75a2cce48e1997e67ac4565554ff1a8fc91d5f.png',
    id: '7574',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c512fbe7e291174915222af0ce55b91ed1cb4897e6d5f6d0837333cbaea2b6e7.png',
    id: '7575',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a46c66691bc624cc6bfff6bf29c10f7bf1e69bc79ad7d8000814f3dbf6a2884.png',
    id: '7576',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9084fce4da6556c03cad965a3f16eb125f64b3c069832bb417a1f32a6688200.png',
    id: '7577',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e027f190be45a837d1a51f135959ebd24d0d9cfa7b587fb8e864053ee0b4d37c.png',
    id: '7578',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb88d2b78c70cf8155476734287a12adc3651e45a81804abae078a4b47c2fc3b.png',
    id: '7579',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cf3c2be6f491db747f2737ba93d891dc134b18b4223b6061e774ef6265a30b1.png',
    id: '7580',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/496acc6f22be8197529b8de7f21d8f79982944b823026e28b9ab184bed51cfe7.png',
    id: '7581',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3533c7b601e15d07da52171e4fc56ae5c6f1ff7a82998f0a7d710e024e2e289b.png',
    id: '7582',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cebc4f1da8a00b8632053e864176ff1de10ed9a1f90cf30b7d6eb22fa6a99813.png',
    id: '7583',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d353e96e28691ed485cd9c538c87aa26f8afd0f7a99c1df696906db82bc425f.png',
    id: '7584',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4429c3fba1358f794d1ce3073be169ac204a8609506972e28bf364c197806f0.png',
    id: '7585',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9213d5a8e7b57e6d1a150bd1b2983798a53eb5fb22243ed4a49c9a729315b194.png',
    id: '7586',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf5a9bd8814ae6b1b92761049404200f46a0811b8444570579771f5453dcb68e.png',
    id: '7587',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15f016aff4604e0a4153598d6b2b28a32483d618f058dace915958b43501dd55.png',
    id: '7588',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1848e04e6ee7b1fbf65e638e2da5491c335367bf508165bc1c1d2566c63a58e5.png',
    id: '7589',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9716dc85fceda5a65ac86ab18a499601e755b41c080b2a5df3f2620838c97f9.png',
    id: '7590',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b83d4d766c3add0d4eb92f88643fcee528ca416397757859f3e3cb600085ece.png',
    id: '7591',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11e36d34ffcce571b57ab4654a89fd46fda4a2f57ef38006cbe30119c0f26061.png',
    id: '7592',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/286f98c2aacff4689583f71e81a39a12bbed92e260f89f0d181402e2705fa307.png',
    id: '7593',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e59308debae9480c889e70db8788e244d873487a84386235e32a6c59b363e66d.png',
    id: '7594',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Winged Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9429565d657bc28d58d5caa432810fc9e2e803fd51eff7327b8c49cfceee4e3e.png',
    id: '7595',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa4f27cad393f6231c1bc46467b07d71f31e4558e07cb374f06a1aae59794566.png',
    id: '7596',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5bde97690b4021092f327f7bb2129b7cbdc4bb669ad82a498efd9dcaa1c0ee05.png',
    id: '7597',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/378f41d01d5c1cc9e4e07bfe3139bb4665d51593b29311ddcade04360cc5c12b.png',
    id: '7598',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7036657ebc362e9f432e2a6174bbbf78a1a1eb1347ceb82d0035484d935c61f.png',
    id: '7599',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc084c56156b19a76c16c9d316a58d01542f9e23c406a1bbacaffa3a5916b298.png',
    id: '7600',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15e32e911fab7e23415545cf9fb7a4b49f8d60d02b819345347e71e8a2bc66a3.png',
    id: '7601',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a1b69bf7a6a20ef5150d05197e21c4590a9f9c1b5428a3311ef88aefd66f0c0.png',
    id: '7602',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bc3ee0df3195e1045279f48bfcca9b5280325be47ae58f79d0f9cddc1012d1e.png',
    id: '7603',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93e5535d608fc188d23226e2130f256c2e55cc6182713d5f667dc3e44d6ac7c5.png',
    id: '7604',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88eb0431424fc4ed90e8312c04f75bc5e4fe5451b1d796b344cc9daac8864b59.png',
    id: '7605',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e24e3c097d874704a78040f3fef8421ef8aceeac485d1053bb4b51fb86d8f357.png',
    id: '7606',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd3de3a1e7ea167cf9ed052b46fd68aa8004504a059b15b3559d1f32d10f5691.png',
    id: '7607',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5fbf5398ce4c0b1f4cb9e9f737f7035f1118fc4657dceed4f72a611df52e5e61.png',
    id: '7608',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8678492af2fc486af1eee1006704db63f4dbf62a98187b5ccbb073128240443d.png',
    id: '7609',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94f2e6a821cf70086dc84e647349cea2c7a9a67cbffcfa033c9d7d0f9313a365.png',
    id: '7610',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7d5312b9660721d6d06523a165f5a1d267e95b64313d3da2ae948067f03d9c0.png',
    id: '7611',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8254f5c42c74c76a112ae6a1590e9a2807ba8dc76ff51a243b9a257840ad8779.png',
    id: '7612',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf4efa245fad33cfcbbc92f64ea1f0315b521b1ec8de975c3c499aa04f757848.png',
    id: '7613',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f30a5e76633af9a055f057f7949ab84ba509bac478cd1b2baf24355d5517eb0.png',
    id: '7614',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea4d754ec9e43ceaaffb0adb3bc44fb0ad928a207dd71e962646f8062ee3f86b.png',
    id: '7615',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d437164fa15e1cf0f683b1bdad8624e0d91a20e2830cb270aebf8b6dd95fc06c.png',
    id: '7616',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/449c000d9991e66d2d48e21bcd90ad077df60c35422c9ac2ab6edfd4e4d764a5.png',
    id: '7617',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47924c923415479a1de1010bd2b18d3caa3636897ef087491d3b01a0b9b9a040.png',
    id: '7618',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e139345b78a51bc769ae37dae0254aaaa0bc0240841b9561e94415b0e72dd70.png',
    id: '7619',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a55b66b51485d5520ecf89ba12d6bd9af002aca540bfb4cbae4bc64b49518ac8.png',
    id: '7620',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2149630c2f84b4f6abadfd5ecd770bf0fd48194cd7c6a3548c8af1d10a83901.png',
    id: '7621',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bc551f4d50388c1cfd99391c86d6dba87875b5f693a400e2695a457ed681974.png',
    id: '7622',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a06dfc7fd1eb295969db9f1623706574abd1e9632d62b4d3a3d1c3bd3161fa61.png',
    id: '7623',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ce2c94832c4d4afd65e2dd3d670b60881cdbb722ca25de91771c3c0753218d8.png',
    id: '7624',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9dbba3b25c2c19232de33830592e8920bbfd86fb6c056bfce58df71c3d1d6782.png',
    id: '7625',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7473bf958947aa208989a9c8e158c8e15e9723385d4743beebd77d22e8dd6f1d.png',
    id: '7626',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6337e391e6a365f28f543c450a1f7fc8453e0e7e19d75eaa442ffda9569f1e1a.png',
    id: '7627',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f560d143aa90661be7645adaabe3da5bd44a201303d60011ff46138e4091e74a.png',
    id: '7628',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c840a55b4f6ab5bf5a56d4a10284bbe3b65691cdb4d31e3c95ab30caccda02ee.png',
    id: '7629',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4879644ec17847b001254337a9911a1bb8a129cd91e72f48e1c80a4c6df1ecc3.png',
    id: '7630',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8038e9066eb811c463923569ca47849cbac024cf7d156c8fc803ae91e8701f8.png',
    id: '7631',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f11a676a54557a4c78e10dccad0e958df7624d6f3252ca528686815a179a9c5e.png',
    id: '7632',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6052de9e2ebdf0dfd099f16bb5b4b1e7400196af188f6a6d422c4452907d6fb1.png',
    id: '7633',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23a1391063eb363570d32c3dba347884a1b1838d4185c8832ffe8b2156ae2806.png',
    id: '7634',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a9f05a8b021c2da4b6977e53658de8ebebdadeb99bc1aed729c801fb321be54.png',
    id: '7635',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ceb78700bfb477f1f2fafa7606f9a65c18077cfe3818a800531055fd9df7d50f.png',
    id: '7636',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecf16104133fddc176338c89f5ab151878a3c2e7cb605088eb241c06124e1bac.png',
    id: '7637',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd28554a1769501125d587598cfeb343b3c7bacfc2069378b32db5e04931962d.png',
    id: '7638',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f454034512832b103d42f8716c35f27f6d53778de0d1be42e65df0ee95705655.png',
    id: '7639',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7461e2ab9f290266eb7d76b27bb9150eca03d85b64541706845b68991b7addfb.png',
    id: '7640',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6be2bb21df19af4e1acec2664b937c4e098a9a6b5b808d455877b94d7c46dc5a.png',
    id: '7641',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94cbb921d8961df204d320d91e29f280d0a6b25a9b946a8d333384ff7bd7e460.png',
    id: '7642',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96bce90d81b927ca9380705b5f4aecfecb6c70527cbd10b52a898bbfc069c0dd.png',
    id: '7643',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f80a363fd94bd6abebcb473e8c97af91a32825fe27bc3a4592a1df172af8ed9d.png',
    id: '7644',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd7ccedbf6e6be36c1a5d2b306610a9c6f34659500a761791a9c6ac3e551c787.png',
    id: '7645',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98a1b23305eb16fd2c21936bf08a1e08783cd2fea3faff735b39aab414a094f2.png',
    id: '7646',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cc06ddaae295ba619a822ff5fdd0c867ee9526ff8b5c7372101d43508adaae5.png',
    id: '7647',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e573d2b7212dd2b246f7fe86a18fbcf96583e3c0055e98cc837cd6cb582286e4.png',
    id: '7648',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9002397a4ee59ddb6efeb0fe0df3ab7f1e7eff60b284ce0590b0ad09e2212ca9.png',
    id: '7649',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc839b1cd0b2e3d548f75125e6380a4720828108d32081d37f3692c4dd2f3bbd.png',
    id: '7650',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54f494e7378cba023f6ea279ecabf76e3bb0ed80b44127358d2ebcfeca93b2e3.png',
    id: '7651',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48b83fd09bd68811dabfe19719ee8ade6849cc33f3a67d8ba91178ad8f136ff6.png',
    id: '7652',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e175a44f60b6a2c5e6a82e1fa31494dd0a9ac32a7a4565db2cfc7058c19d9e0.png',
    id: '7653',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91626eac1cb2f36724c3611b54c072f49af49691ef9e94e0bbf957b8a11cca91.png',
    id: '7654',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/357a655cf715daa7bafa556ad5526b2032cbbde9da0e131060206b1b99c49404.png',
    id: '7655',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fc72c9c469e9d23f349b0b81e044528afe362f7495888a1177d42495ca09a56.png',
    id: '7656',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/208ef3f1c2dcde46fea6f7c2c7f0bf89c95cc94e461f45d65a1addfc149f49c8.png',
    id: '7657',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62decaee7e6112dc9b526aac6fdd6cf8352282005c986f5d0748a33fb107548a.png',
    id: '7658',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a54d2b83efa63b3cd1ad55fc629f9577d97bca163b00600eddf486615118ae84.png',
    id: '7659',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b422aff250814d27c91472263127cd762b23a1e69152058d89fc9557abf4a080.png',
    id: '7660',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6675328079f2a4764cde03ed68f6d3b73da32ed9cd0768687fec0d3a22c53b7.png',
    id: '7661',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f884924f23fe13b421cc36e36909bb3f5b02c07c8ec5bd7e0350a0c1e8f04ce2.png',
    id: '7662',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f4fb22fbadfc830c992f0d0d7fb004829aefebad6f7501df9c2b021fb384046.png',
    id: '7663',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc5170090cb0c7654636da4dab740d59c6f01ceec49cb708cdd69784762818f4.png',
    id: '7664',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af5f3796a4b9e6278977b41bb6b4a7e22487ef7851ace3382dc678c976c35a34.png',
    id: '7665',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/405d11628f2649e7d6a574e79c781b1562b1079733b9bb416a40e0583b05f8c5.png',
    id: '7666',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fda4947fea5f954e0ac1f118087d0a1403ac402e5131916b10ef657eac5a3f3.png',
    id: '7667',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d34aa1bc62a1c6b34389c78218846da6b3b49ede8b664b0b6f319283af668a7c.png',
    id: '7668',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18a6367d81a61f0accb932990252c3106be826d889580e741b9cb87525c8762f.png',
    id: '7669',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f773b9a0716191832f3095046d23cadafbfb7fb9f34dda5a6329e06fcba077ec.png',
    id: '7670',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee05aaa643358d1c1ccf9afadd7d1efd2d5dbd03bf46f05335bbb809cf6da53d.png',
    id: '7671',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7c46c4b40e258d4bcb06e4e7fb1561165830c209359ddbe3da1131f79eca932.png',
    id: '7672',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08fc4396d884da4cff5e977716b3318675f31d43b57727bcc7085251e5ca456c.png',
    id: '7673',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40c6718db254d122cbaf3af438883a53dd70037430aa62141e6d3cb39c562030.png',
    id: '7674',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f43d6611f84740380142cd8061a701ab7f89a2ea91e350e2061cf3ae754dbf5e.png',
    id: '7675',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b08b3bd25a8f2c7c7b2fbf49e8c72c2e6665f08f5ae71d008a3a787791cd5b19.png',
    id: '7676',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29cb7e942c7c035fd3037de1da437869b376447c3580953ef04e82b8e4e86b15.png',
    id: '7677',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/394128ff6cb78f44e20f95659dbc25fd89d87cf52d6a7efa4d58c07311b481df.png',
    id: '7678',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b57f35b3ff476b3eebc271d4d4324a8670395ad683a0dcab18a6c0a913177823.png',
    id: '7679',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58fdcf0cc6b9d5191dce630e9f8a2b9a01dd814be6142fc7dd19056d12528a49.png',
    id: '7680',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8ec3678939449733aa6cc3fb5ab96b4e1e026d169b982e880d0709dbe75e9ef.png',
    id: '7681',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ccf33d5474eb52957ffc45bf90271fc54a74d804e3acafe15ff5d406291382b6.png',
    id: '7682',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9338c79de143758604a7f725d4fbaf259b740a854727d020833ab51f8b2b6b0d.png',
    id: '7683',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/995585e77855d75425da38efd9acf06dda5d8e30ef2b06c531df4214cb03d93c.png',
    id: '7684',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eed3e551544b768ce953735d6afebf3e44d7c67c562d88408f63e4e27cfe0fdf.png',
    id: '7685',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7205fb35b08fdd28a95afed730ffce97023634599db085e96745aa158fdde280.png',
    id: '7686',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b6d77ec1530ea9f935cd1b423643d3484d21c6f689bdffd3ced957fb65b61e4.png',
    id: '7687',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a42d29999ab24893c494a8eabff0c991a1541681070abeee2df29dbc6ba1d294.png',
    id: '7688',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0c1202cd21198c4557d9aeb359d00fa7a05b98fe949dc014479847a6902e088.png',
    id: '7689',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/673d0bf02661e66c678ab3abd7c35d3ce34e8049ef28c1709e82e84afa5bb151.png',
    id: '7690',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e36f0814ad82ff28582621fb79e445f2b73df18cf685dcbd22c829217d5fd66d.png',
    id: '7691',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83c92db35d1090b343cb8b367f8cda43d98b53c1fefbba26b95f66ea28851b0e.png',
    id: '7692',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf6fe3364e9a41c49559a315d2164c431c43ce3d71ae626aedc0a75488e9974d.png',
    id: '7693',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0683efcbb92e22cf9aca7b7a310bd47ad3337aa2827fba21632dfb1cb12c74a2.png',
    id: '7694',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83bbe6e58aa6eb4e1d0f6545725d9b5473552052b629f5db1c8b2b0abe77eb81.png',
    id: '7695',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcaf329d4a5d081128de0cd36071f7fd66548c2522e6b7a8555d31f83d20edcb.png',
    id: '7696',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/661ea14a0758685b1c281b0692a17b92230fc0655d13f167e7366994168eb41b.png',
    id: '7697',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e8552f1ea41001b7a57935000d91d0b11263a37d84e7272fa627622970c12e6.png',
    id: '7698',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73353eab2b64ba4f40e34bca0ac34ba198dfe1c72cbb75acca1de93c9cff3608.png',
    id: '7699',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad402dc6a3f13d37b73f32530c6e03b3400797f9533fce5c4ff68c1f2fae2551.png',
    id: '7700',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf446b9e6dfc509df6afffef20200b13b587371d1a6fb87ff9e2646f3ffc68ca.png',
    id: '7701',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6bb1df8f263a1206097a48c20432dc52066816e01580bc17682c107de8cd3d3.png',
    id: '7702',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef21e9b1a2d3a2ae33a3d4d3c6e13764cc77f389c180c743ee259f49ef489b1f.png',
    id: '7703',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d2f433665cebd95af1e1f8d808a76ef4dce1e9242cd5bb8ad7cf76abcfb7f2c.png',
    id: '7704',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be805068637cbe709eb9752325341562ac5bb21933d7007e4960331937d0e393.png',
    id: '7705',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d536301f652fdd8bd4406ad634aa981e7a623dd7228fc1c076e79bda4c8a673.png',
    id: '7706',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed58667265db1d30270dfa0b2bf2cfb07f143add75239c184be7179a3498f06c.png',
    id: '7707',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98a7042e84387d31b590315bb53dcef41d08b6074de05aea1f59bac496dc265e.png',
    id: '7708',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d50a2aed6a0252d650831e8f3190aa1cf7b22ca054d7d2394ae2b6ec508679b.png',
    id: '7709',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/791c16ddb1fe78d87562d6d09566ad15ed093fbc8b840dce474e920bb98de9f4.png',
    id: '7710',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/46893b00f711c296a42dc1dbf1e92101d055304230a8a84cc49ad52a6d6a440c.png',
    id: '7711',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/949cc86a803311b94e04c72aafa09096762dde90d3fc1fefbe78c4d3e3a61a50.png',
    id: '7712',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5feb508495053b565487fcf95c994d3be7bb8146cea72e4934005b5a307a6c68.png',
    id: '7713',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3df82c9e5d6370f95a2118566e258994a2fbbbb9654f149094b216d856255e71.png',
    id: '7714',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cafc852781ca107905e57e3d8189cbda99d16dddfa5245c47c5a2b9793d1c19f.png',
    id: '7715',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96b163a0d641cff10dba74aed34697d152055db026cde658593421689550608e.png',
    id: '7716',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83671b07b4423b25b0cb1eae4a03f30e615c6cd17bec179ea0b197c8937b8a3d.png',
    id: '7717',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f971bfa273521252080e3c573dbb8c1d212550fc4789e2f4ff242b0bb9495e9f.png',
    id: '7718',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/774117fb325e150312c4768cf3e9411d89bd44edb5f0b9f46fa55584aba2d86c.png',
    id: '7719',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf57c54d18b0f20a91f53d3b7a80a4b90ab019e2aa85f1b74550e989e4039285.png',
    id: '7720',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d2a658a11d1a72e8b20e1da8822934fc1c9fc4d466fab11982630f584b0bc8a.png',
    id: '7721',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/923be23f38f860822be6443ea1ea9197a79286ca794c9b32fedfeeb20c7c976a.png',
    id: '7722',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1e554142a05aadb58eb8f4c59956007d329f03305b534eba8601aba361013be.png',
    id: '7723',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/791222c977deca3bf0ede8e40cf8757a0743a09fcd6f784fd276fdc7d0be055d.png',
    id: '7724',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0dd77c77f0fc8b90a718dc285d6f3cf53867981b4d798c5c4b8c4c85452bb5a6.png',
    id: '7725',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3649e534d025add4fa2689b9361dddd715be947b59454f54df04b07099de0078.png',
    id: '7726',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3fee629ec19f241dcc87a301a653628d7fa6f222202d40a746fc918fbdb07e8c.png',
    id: '7727',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f476d6be9282f6a9409ca25b357870e57da60859d02f8818632b9a160c54167.png',
    id: '7728',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7b3eb514c09e1cce8674b6165b4d431442ad2df44e33b32e2b9ae4fd48c5e95.png',
    id: '7729',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32692cb0861f37c234e495d2196e066799761f8eb901a398f033778ddc3037ee.png',
    id: '7730',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/223b79948ae0c32f9a6bbe94cfc3e903ca93b3ac18a78faa1dde451b7fafaf05.png',
    id: '7731',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a51429d0787adbcf9ff7164b3d51d6db973b63230f7b0787e5d0d4146c65dc09.png',
    id: '7732',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/062331dd42effd37c9269900875b6c7a0335085bdf745d29ae498f9ff58970e5.png',
    id: '7733',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3793465e052125164a02e43df5b55464edeb7d39b855d2dc28cbc298e5cb65a.png',
    id: '7734',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee8c92270ad58cf2dc9ef5c3931388539341bf42a121ba1ed9b9f369f0182d63.png',
    id: '7735',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3373f5537ee90cab706ff1a44b15080b1915a16ffc4cd942411a529aa1088674.png',
    id: '7736',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Tenderizer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d42f0ae40862aa29b5f94f035153bd1e536b321f4e0434f26f0cd8f7d3424a2.png',
    id: '7737',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88ee683caeb3c169f138af4c1187b1d9410a0cbea330cf045088d421fbfe7a7f.png',
    id: '7738',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/073ff2cf4e1df5c14f1d85e58d95c37e13e8a062ce8356ca4787d8d5e8e4c01b.png',
    id: '7739',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de1032c6d963823f6b012335cce29679eec03ebb5d030d2bd66ff0436eb503df.png',
    id: '7740',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd08f39f82d40fc5914ff37f6e1c5146b9986ecf7ac2b144c9cf11b00a458d5a.png',
    id: '7741',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cf3ff5792943bbca182177093107ed8ae2e4a6ecfbb27abd299838f8ed3d937.png',
    id: '7742',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad61bce469acf31ede010629566566dc7a9f7a0c7234302b398a749e37846826.png',
    id: '7743',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ae48ffb62661f215c2cdce80bc4f0510156c817e7ece31536eeb391cfa07df6.png',
    id: '7744',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc73afcbc5672168bf8d0a42f36515e1d4346f43c9106fad3460f78a8f45f866.png',
    id: '7745',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be1a0c178979ada769b23f479c49c37ca6a24a8680e80e3dc751fadf28241445.png',
    id: '7746',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af6e31488ceaea0f9fe0ee3ebda63a8b5b21bef16f72ebb1018d0cbf3ba12653.png',
    id: '7747',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a34b479dabd4249dcffcdae07added63199704fb72393d7f7cce080532eaf3de.png',
    id: '7748',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac243994445932109df993dab23d5bfe63206e347664d46875dfad2989904dc5.png',
    id: '7749',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a46e619db0ce6a0bf625e42852c69833b4c3a80860d54ca4c1d36bbd8c778ea.png',
    id: '7750',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b58db06677bb7ea7e60affee5cd0d4a45799cc40d7955afab772190d335720ed.png',
    id: '7751',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ee36ea20c61546812d1db2e0a02e71de526ca95bb38fd4bfba4e79f92fb00f3.png',
    id: '7752',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e71832abfc4290f1525783f698f223f74d8fe21fbfb641db210798648f8f87e.png',
    id: '7753',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'The Tenderizer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01529d3af8cc62faf19c89f4b34cbd5bf7096af1df39ea85c29d0a3f777d7c09.png',
    id: '7754',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/010b3e5d09d11ddc22e00e3a75ec591b1294e4fdc7462b322eab90c9a047275d.png',
    id: '7755',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4718cf67946c30af1c4183d2c083d557322fc863eeeed466640359c66c8fa5a9.png',
    id: '7756',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0de069882619fd73a97a77dbf0e4c337ff548654ba40b4e2aa1736bcd23b523d.png',
    id: '7757',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3822d84d88671c396808e031a0843b3d53778ea3d1d620003d28878faf3a595a.png',
    id: '7758',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52ac326d207fe3fd2dabf57b2385def691ef316c65226a2335ef25612fab04b8.png',
    id: '7759',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a1442b478ca2ba245a78b78462104dec91ca31814568f5f94d815f946c2eaaf.png',
    id: '7760',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f810bc71ad833f5a3aedb0e6f6c4503ec31fb7926a6ab1e23eb9be26231b82e3.png',
    id: '7761',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d45a78146e112794c0369d2300fd57edc953695f3c9b706900883473c91d83ab.png',
    id: '7762',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1cc29d0ea708aa297c6c4f97d020bae718aadbfb938ccd87fa2bf024ee423ac7.png',
    id: '7763',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0344cf1445450f8d83d35e5e860928f45f4310405855d56fee8c206e231d194.png',
    id: '7764',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae3bdd598dcf80c35360d34dc923f6bdafacbc71d3f9b60841a1f5f89df505a5.png',
    id: '7765',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eafd8406620b89cabdb0300ee91bf89d6e540c6064e4b5e1cf7fc48c9d02f9cd.png',
    id: '7766',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b654ad8061935b343aa4234adfab4e529ad6a75e58efcf4cb46a3bbeb423e12.png',
    id: '7767',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a4228e446c9ef5be2576417f8d4457906a874a408199d2433faa327bc4e4e84.png',
    id: '7768',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'The Claw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6053facfd78b6d65d08297d6c760f8fb40f9692d172545bceb1224166e7f4f9f.png',
    id: '7769',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ed5db312f28b9c3671dd2117f2f3c853258fda6b6c5b170e5c1175be699e43b.png',
    id: '7770',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01e4c4a605727104b4ebc616f69326762bbed07c3b0742b1ef94b5564a91bd68.png',
    id: '7771',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95e37f8bc6b842ec538b578bacfd18cc508dd27d2d5bf41dcd80571e6c10a7d2.png',
    id: '7772',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b732844a3618b0b5dfbbd5c2ef18b9ac8354ed9ded25e49bf7de6fe9035a0c57.png',
    id: '7773',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6e1c6b6feb0a866f5f3d14d3c21c88b8452085c3fb2d6ebb701bf1c9a6a72a1.png',
    id: '7774',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c66ee3366b4aa6a89c634a0d36bad67f1aa399224b93c2fa23cc8412310048e.png',
    id: '7775',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ae4220925fc3ac4e4ef27176e74493e7de64bb077d800f06a05395fe54c89dd.png',
    id: '7776',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc27e53dfaeba0a01721fe5e62104be64158415cf905fdcd71c661521e1f1cb0.png',
    id: '7777',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb6726e7f1b6903159ab33d0b5cc0010fab9855b7bc12d11e0657ccd832f3867.png',
    id: '7778',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de8b9a7145244332164d70fe7d772d1b817fe56403ae9c9f896d7df96f9874c1.png',
    id: '7779',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b5d377c716d4a2a893c2bd43752c12d06220c8b799c3825b4b646c019723d70.png',
    id: '7780',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24688f51f51b3d6678e4a495ab173bb6cbad1547fadce4ab82eeeecb8b3dedec.png',
    id: '7781',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2c8d3b97ab16ee3f5126cd4cadf3b9ae4a408570c2e25d34dfd9c6dce508527.png',
    id: '7782',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17aee034c6b4ab85495a1c7db739d948f06a37680042ff5a36e27b15c8e15e3d.png',
    id: '7783',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89c0ae00a8e8f9c25e99ac2d0308bb8e9c9eb0d18f24d58a83663f138564bcd8.png',
    id: '7784',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Twistree Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e837b51832c7377efdff58142ae13b24698d0862410305e050c9e6c58183cb53.png',
    id: '7785',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83680e98089d6b6cbf4d8c0edc708761064699b8a9021d61fdb075cc64191b0c.png',
    id: '7786',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e5929c3d45f24a222505523e832c373b49ee78326eb7490f43a8ea9a27ffaed.png',
    id: '7787',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03f58d56450af964df3179c6138e29efaafadbc21dd66101c900186c54f8e0ea.png',
    id: '7788',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5d1ceb7ce5d3419176c9f3851592f86720683a71c11a03f1c499b30e23cfec2.png',
    id: '7789',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1792ba9e9a8e4bd946d56c2db214fe07ed51a717b48e0116d651b1693ea8c04.png',
    id: '7790',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5984ff21c42d91cbf5fe5ed4c7c161802aca8cbed1f1c826c71c94aa6064b4b4.png',
    id: '7791',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1732aa08ee1d0a28052eab799ad395c75909b218795d0ef9108563822ae32961.png',
    id: '7792',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa8dcd6a1c138d31e38d3c33a63fa37772cb0a3d6c60facba6cc02c0d1528879.png',
    id: '7793',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebf310e0ce3a290fd781ac5fecdbf583f7256db0a1ff1c724787e01812222690.png',
    id: '7794',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73724339548c983b156a03b8af7425f13d3744a048330a67e8ace23fa0f4ba6f.png',
    id: '7795',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0287edefc845dca0c28ef3c91c68c00c03cfde2dc040b7fd0638ef88478b3081.png',
    id: '7796',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07a5f6befd3cc06fc1760cb851cbaf0f71e04ce0b706f36d3e3562ead1ef2c59.png',
    id: '7797',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0d36430bc9c4452a7f09f3a7d980da761ce8a55afed2a497f4f74209f4d6f9d.png',
    id: '7798',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65259dde62b2e1545b7fc6e22b7c7b153c806bbeff604659cb89263732b08470.png',
    id: '7799',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/349de7d700c21494cccffc62edbc6a4a1df6c8372bbc9e996817dca74eebc3c2.png',
    id: '7800',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/006ceb68170a04ff1772be2aa87a9c8635e7c4000031fb4469970a1983d028b5.png',
    id: '7801',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/827c4affecba4d993c7fe3a3460d8dda1da6d10515b6c0ebe4854d4a66116337.png',
    id: '7802',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/065773b3c56bb6d4c6e6735f78251f8359834a36718d7a7acc6b1671ce16518d.png',
    id: '7803',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ecf1844ac3bb617f132bd9aae8def000b9127ed2a73d8972e0569ee657c3df4.png',
    id: '7804',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4258cf28335e1754046571a494eab96c5d58e78020568ea2e6d017f6b110128.png',
    id: '7805',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/788e47d953fff7e3704bced77660a5b11d961c4eff4a382a777e34c8bf28b838.png',
    id: '7806',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c2db8e046568b54aed01cef1100bf2d252f4400248d8ada93d04da24c60404b.png',
    id: '7807',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9d66a3dfb2653d05e48484e4ebecdec98f419e9e98afe2eb27604ba5e63f299.png',
    id: '7808',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13f9cf1a361fdc4d1dddf57ccc198ea21f8916efd393eb42662a913a47b053c3.png',
    id: '7809',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ddb0f3aba6c339ca8a4da313e6bde53107237e810f98a10452abd41a0adbe60.png',
    id: '7810',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Levitating Lava',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff84734fd7329374433ce2f9717272236555b210143b4bfa76182a7dffb0eb26.png',
    id: '7811',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56cbcf0cf24daf7f6a6aa9e13ebb576213b7fdc19673d345f834b0d58ceb3a26.png',
    id: '7812',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a65ac67f76fdb9a44bad300b7c6c39f839975cd8c40d4ab2b99816405e74a950.png',
    id: '7813',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfa64c400f3fb313a16cda7abc65a905a5c77a508c388f9e524efda2fef81967.png',
    id: '7814',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2486178db60ae7d72dca62d3b56aaa57521b5ab96d948ed4d33f92a1f1d5cecc.png',
    id: '7815',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0708f8afd34d6ca9fe4b2030773051ca0f87d40434bac758d577332e3723cca4.png',
    id: '7816',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51b38f3e36cbe948231db798aca3262664b7fa1a0d9eeed5bc724293e11ecdb5.png',
    id: '7817',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81ca5b8ca87b2a82ac144e8e4d2220cbd119df932b88c78b6863d9e2b3affb75.png',
    id: '7818',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44c685a92918521351337ffbdd9335d4d94c7a02117e2f9e13d4c136df2e9112.png',
    id: '7819',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3330314782d195f231a5433f24aeaa6a7b934250c952c837d4aedca0594e292.png',
    id: '7820',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dfac2ed9222b6257acff885dbe82a976f8a05c875e945bb247495924c3c922c0.png',
    id: '7821',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4004f72710a737076164a284166e065070fb8486541b175238a6ac6bb0b61b7d.png',
    id: '7822',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ed5ad7509f07d8e989bd6e84cd21912450f5d2ca09f985e59107961ae5605c4.png',
    id: '7823',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea14a85ed904a2ae2f23bb5d69b66359ddf57bf23120da9871031d20cf6fa8a4.png',
    id: '7824',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93b36848473f9b595a1b40ddadda9858e10d67e92dc3a4baceb293da16e87503.png',
    id: '7825',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ae089190dc27c4e839312f216774f91b314ab6411cc8b6ba20de5986fd23d9b.png',
    id: '7826',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0f90428edc0d7e69c3935547d29c822a1df4df792c4c699596b27cee74ec965.png',
    id: '7827',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24659e7f2de7c2442a7fcfdc96cb766fe0d33c932e71f237a52ff19076a8d86f.png',
    id: '7828',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04bc3850c180db06668c52357d7185b378a1e090ab04287bfbc27e4c51d836ee.png',
    id: '7829',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/843ab816c7af55c00f82e9ab7b53ac4e94c66c94228ef2c1f970cc0c52ab9258.png',
    id: '7830',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c81ba000a2b87cf1b9c801c4f2c853e5c2ca8dfd1d0453f51b43e2d5fe727172.png',
    id: '7831',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a87ff862f59a7aa6ee3277776a00a1ad09b6c6de98a2a0f15b5e131bfe5d3662.png',
    id: '7832',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab2c6a34bacbcda40b403c34cb54684f3880cacdbd394202acd7c38e8bd8ea2d.png',
    id: '7833',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dba110e03e7dc0018765dbb5f621f862837648f1bd84ab4b9598aba7c58a3481.png',
    id: '7834',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbbd8d1ea4cf6034947b1cdd5625d415366f8082c2181c0e0bdb3bd3146eea34.png',
    id: '7835',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e2efc8ae65e5edbd2dc57cbdff3a6011872ff45489aad53f118b340b6603725.png',
    id: '7836',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee10e955a39de3e955d4b217702be37d69d0978e5f3d248d2fd0359ee310b4e9.png',
    id: '7837',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a40c9fc700a3abf1147dd8a9f0b9f5126a85b9c0a74c6e2e4d82c56a5c2d9de4.png',
    id: '7838',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dcb476121accc666b2b29c371d028060adfcd88ad0b4e54837c170b0e6d25d13.png',
    id: '7839',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5892afd8b08e96bbbb459a8499c1ebd2ccd957f252eff34b5bce8bb0944d4c76.png',
    id: '7840',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5bcf909ad7ab7d513f317762d5b72f317052ee8f8bc43246d87ffdac7aaebe9.png',
    id: '7841',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f56390ef0ed08ade4c603bc1762007feaad6738b4db70211c577e21c1af672b.png',
    id: '7842',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5660e76e89460e146154ba3184e775748d3f93565653afd9710c69d623d3ca2.png',
    id: '7843',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b60e9a78295bd20ead1c9b3bc38a4723c4b73510c98a2499852089b5e8c0ea3.png',
    id: '7844',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e872b758c26b3c71a3180104e50184705c5df599fbb6569e5f85ba4ab11a641.png',
    id: '7845',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24f2568d4212cba8c108912f3c60700f869531a31836f8f1028eac004975f5f7.png',
    id: '7846',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/810550df62c2c6fa71ad77b963153a5b28c7347838652424d2e3f01019697e3c.png',
    id: '7847',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ea6e233931c92af066fb58bc0137166c399a407e7ccd69b1b4b090334e0e7d2.png',
    id: '7848',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a90f06ed572992d9dab5a9498ac7df673a14addbd2e89cebcf293187c9139c4.png',
    id: '7849',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d05601850bebd0ecf79b44c0e12e8f9a830c63eb2c7b40d42b701fbb916f0b8c.png',
    id: '7850',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eeb07c28d29fceebf78525c16828cfb4f7d616c681af8d19d97519eeb42fc149.png',
    id: '7851',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc843a2d1a591e9952abfd521dcbd4668d56feaa91a3239709ba335c840c1e61.png',
    id: '7852',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d05a550db640ce99e07320dab39c08db97b9bc292db930af797ef00f4f8288ad.png',
    id: '7853',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7896d8a18d8a4be64c698a63ff1130ce6af186628e96ced02136817ff814cd8c.png',
    id: '7854',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e448ed155ff9090e784194c2084b35a333b402e112f3758d8f54e41986f3530.png',
    id: '7855',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3b4c824a8800d158fb1f621644ef7482f64dea8d9663ee327d0c6629b36025a.png',
    id: '7856',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbbb5be3acf83e4e926d71cb437c6edfb500efabad3d9e05615ddfd52651bad5.png',
    id: '7857',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f63415e82993dc0ee6830eef196cd78e55c46bab58e9e4d58912b60a8e1aa3b.png',
    id: '7858',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66503967f1e58abdd40bac07acda32a9904b270199817840cee56fb487785c15.png',
    id: '7859',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6364731f5d50dfdfa8bf569dc5b83936fc644159552ac7c76e659f7dad37646c.png',
    id: '7860',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6c63426b95ffcf46a11af2c0721445633c7c93677c118938cd8b6bf910e1d7b.png',
    id: '7861',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe9de2dad74876540d81d0e1fd4aecebbdf106983e92e274cc5818c980093d74.png',
    id: '7862',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ecc07db9bf88a24efa5f865080c6f374a191d12ab6eaf86b3eba5df05f3d795.png',
    id: '7863',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Explored Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ad869cc1237014240a0895efed0e536b3f6f61a97a7058878910e0ccb7a7434.png',
    id: '7864',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0672d7e261f722a69a54dc520e4473cf9b2672db0fbfebf0c0a644f73ec16c5d.png',
    id: '7865',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd69f75929ef96d150e0a55efd753f57dcb08d893690dce8a02f02d083a4ce2e.png',
    id: '7866',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cca313c5d697adfa3b992f1b91b5eda9c1cb1241e13815cb7b13b7a0dc4da15c.png',
    id: '7867',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b74fedc0587d8a335fa3558d3d46fc9e26eec2682ee5a722eb5bd8c7ad4c5a1.png',
    id: '7868',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e72a183d2a0117e01d14239e4035bf492cabf1ca813e54e277a6274b30c3aa9c.png',
    id: '7869',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b3071584582f47f0393bb23ca27ac927020c8433e63fa55d767a03a8a35dd69.png',
    id: '7870',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cc54b0b95704ca29ff4cd9b5f8e9fdfeca36e2f9afb229bb0b6e3fd423b44fa.png',
    id: '7871',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54b37631b1b5a36124098243016175590ca9faf18798669d8374d38246597fb6.png',
    id: '7872',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92634ddb6129b60b8e675078337514e7052826b3ccb110ec5e1d9e527f161449.png',
    id: '7873',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed94fb4082499612ab6a0f7854817aa4d6a4b3f903f0cfd4dfbc6dda6b1d6e74.png',
    id: '7874',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Batty Spiked',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31aef6f001edd4e95813ce341bd1bf94c0e44efad7a02bc9ec0f291cdd7267a8.png',
    id: '7875',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99dce7ba8f0cb9b1c61931ea9f0b48db5364cdc0b1c4d63a2c683d3f46b648b5.png',
    id: '7876',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a48a0d53ee1dfdc0be92a9650ecbb9e67aeaabd90442f0d3ab5fdd38194ee1f3.png',
    id: '7877',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a465dbdf111ff72df4addc98944bee9f8ecae4a8c010e995c6b9920b8e5fea98.png',
    id: '7878',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66c65186d96d0d4843b63de818cc7a2b2b2333c9d0a672504cb2d3d6845fd73a.png',
    id: '7879',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4b6da5021717be767161007811c58e6307ab088c1a9455ef9432c1a50618b63.png',
    id: '7880',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/299c4411d8020b605c03b389aed258d88ef0345d5e2a2a1b33ace0e7b3669484.png',
    id: '7881',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1e52b1e9cc71db765e9e8ccaf83979819c3eb966d423c14e970f93e210dd1f2.png',
    id: '7882',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b00de8a93a54573bada9fc25016ecdb8f508901bebbd05d62d6cf290d5ec5ce.png',
    id: '7883',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/966af796a3afb5ad108633338a4ce6adfd50e452e062ba083718992b3bae894c.png',
    id: '7884',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de56da15aeb13add895051def33cf6f3c0103fb28cbd881cef2407b6674190b8.png',
    id: '7885',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fe9973818f1c5efd4199ee92b8710b49455c876526711881bc243f1d5fc82573.png',
    id: '7886',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a351e6c9404a1e84f15505ac6edc931354f52ae26e358e3a75a5406e55c0c0f.png',
    id: '7887',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd2f99338e56c06a7d7be2c9da52054e3bcf934237763d53bac27e479ea535d2.png',
    id: '7888',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f6b33e1e77a9180e3a40cc138fc872437ad568b0941027b076fcdb671b6163c.png',
    id: '7889',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/432aa98ce7faf6dfb7d920cb68f52bf147f426c2bac37ab3fae62255e9f2fbcb.png',
    id: '7890',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/350a8031cf302034267e600dc91d48a4360b796d225ddc694c28787a7e909306.png',
    id: '7891',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39c8898fef3863b594e69a8798a20c0688370ddc19a74ad801fe4c5e525872cb.png',
    id: '7892',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2bf6953a4c27ac555034ab48981ea142f78fd4ed16fe97c29546e477ac938aa.png',
    id: '7893',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb5e70f327db692451f16f6a80123d25db14083055f6b6200a7bc931f07ddbbb.png',
    id: '7894',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ce0513030d2218f2bb93a20c1aa968d9b1d7b35a58c3f19f4b43906cc871cfe.png',
    id: '7895',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/196249ceb144f9fb68aa2865877af8d7f2208239ff5d3616557234116a88afb3.png',
    id: '7896',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7d91c1ba3505c0b43c11f0c035299089ff662483f46a6bdebb782dee9d02f02.png',
    id: '7897',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c91f423c356e4a56d9aa47a7473ba133766d324196067436b3e7938159e9b933.png',
    id: '7898',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81fdd87bf39720926d1184d76fa4a29adbbdf645ed2177ff994d9337ee202e7a.png',
    id: '7899',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12b0452cc516f65df19bfd2c774873a246f8c49bdde7accd42e7fe0047caceec.png',
    id: '7900',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7d1125abee4f765a5df2c08f5659688b86c7370e8ab10444473522cd500dc24.png',
    id: '7901',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f309cb91079f81426eef1684634f510c10654047039329cd2b61ea1b551e342c.png',
    id: '7902',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0251ff051dd564e1dbb1cc239179a724e1f94c4792579eee5ca19ff106781c01.png',
    id: '7903',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7f3fb930673df3980fcabeb4bce74fcc03e99ff9cd2c6a23579d1f1246d2056.png',
    id: '7904',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6775b131e003ec4733811ac0ff717ff4941b5679d03e0a876d5cba23bc114c6.png',
    id: '7905',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01675725d7f813582f73a2255c814df24c93f9c0b96bab59b153af80dddcb459.png',
    id: '7906',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/234b2187e9cc7fa24e95dcbc244e82265a5ccd59393a6cd1100587229b4ac3fc.png',
    id: '7907',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fc552f48f72e7fd7a958843cc4f67a21d3b29f57ed6699275e03644119d5c48.png',
    id: '7908',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a0395b2d6df4c3c457222e5b9f5610296d94ca93228728385d2f1e2db54f0e0.png',
    id: '7909',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e6be9ad9790a3c23837d33b7a1aee22906ad6bfe4e66b401531bc4c710cf5bb.png',
    id: '7910',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2e65788ed57751d7c8c198d717d84e55bef2aedb825f0539139cda2190c5f8c.png',
    id: '7911',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca21f06c0821d5b9dc69a0ba4042c8e5547a50c2014b5d93b568c9fed6c6d21c.png',
    id: '7912',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d64768a251e716c6a2b4de135ebfd99f22745490877df3f3eceb44081b44eba.png',
    id: '7913',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60194ace1182459b87ff79ead4bfdbacad891b70032f7d98ced1e075cc82bf78.png',
    id: '7914',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee20ceddf1d4eedb83de57de6471565825fa0de982a2638d396185243902ea86.png',
    id: '7915',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eaf4724587f6a31f89b4c6a6a602ae6fff59c25edc8226851249084ab8242816.png',
    id: '7916',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d7695669e69c05ba06bd0b4464ee62d968b5d0e3817730bd1b250aec1cfff8e.png',
    id: '7917',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cb3bd4b54956fed5024389ec7a68fefac501bb0768f9bd2a8393115d8f4ea9c.png',
    id: '7918',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/541dc4ac564bc7022275dba20c25d4d3de68ea930783f6f0e4b062efb5a7d90a.png',
    id: '7919',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67a3878ab8a946a0f6d3dde09b2ccf0d76923107719f76a2a990f3a3885dc5ff.png',
    id: '7920',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab711691c8e9b7db8c2569b70559581b8e97af1ee43f7e7e8702b907ef894adb.png',
    id: '7921',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6443637dfa483aabc4ad0d03f72810560daf39fb2fa1c6138dfebc617a0585b4.png',
    id: '7922',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a285ccda1857b5b437372a03e5ee22403664249fb10fb4c697a0e37c77bcb3ad.png',
    id: '7923',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf7f05ca96f0261b82a78e62685fba639bb8116b0a2c5c418c103bf238f6416e.png',
    id: '7924',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1302aa36a28cee6c436ee217d1200c54f783cc0c07feaee6b57864287fe4e02.png',
    id: '7925',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af5ad1cd226aa9605f7c3842feba9c03baf11f782b509a62bc9c0d32b790afa4.png',
    id: '7926',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/849062cefc82f6659280a73252ed4451be47c8299a439545de68ded8824248c9.png',
    id: '7927',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/614a12fb07c9b844caffe683ddd391f6fac3d4e4a010bf3305d8257df6dac036.png',
    id: '7928',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/441226217ce841470feee73b8abb1fd40dd8bd9bca8ed9a86bf30e751d1fa868.png',
    id: '7929',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/978512ea5109829fb1d179aae8b8baeb3d39df769f6a86178cce3af1127f4857.png',
    id: '7930',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/773e13933d2ef192d997ab205311f97c57820e4581fe2296a40d8227551e4643.png',
    id: '7931',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ab4fe45faf1c37b037798061977b206dde4b1937d64047798591c546e1162cb.png',
    id: '7932',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/005c9aff7e2b47df71b16a6f039e1a3100973d74fe8ec90ac5b69a3a5c179959.png',
    id: '7933',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38ad466badb58c1ca338d98b7b864e5e24d006b02b5c1ab7702031665da44131.png',
    id: '7934',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2494b798ee38fdac659a915baa09d19ef80917a7d35e9937363a40ed615e1ce.png',
    id: '7935',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7b7ea337017a518db76d590a503e2fad5f6b79a07e8e123331217f550f98ace.png',
    id: '7936',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd5d4cc416cf3bde74d7a33cc465ad31870228df90e6b3c78052e1223c42c304.png',
    id: '7937',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ce30c082dfeb23719a8a9cce30531edf8459c4193c13a2fcad822e60af91226.png',
    id: '7938',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8052098a243f8bffe0e31d6a5fbc85740631c85a234bddd99cabb4c81ea6c6bd.png',
    id: '7939',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d116d880f879527c204880e35bfca1d64c3d7fa75f3f35721054f8d922787b8.png',
    id: '7940',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d074a56c5bdf97f761e5640ba4b33dde96b723f3bde9fccc5dec7f5a7e24a751.png',
    id: '7941',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27d77c5c7ffe16c5aabc5415486022a663ab6368981b8da9aa0e2b311fea8377.png',
    id: '7942',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8594bb9833d9ad5cfcf05f7a2a6f3d75f65419cb81b08c7319efcd91695e3760.png',
    id: '7943',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/981a1f2881c5f0a43578fdeb91272ab5b29d1a32c752d86d048969e4ab6e62d3.png',
    id: '7944',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac032f60afa38671dec9412d5404114f0034fb0c0eb7cfc9fe03baae08a40045.png',
    id: '7945',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc59e63bdb3d308703e22c47ecf26851efe78e276bd5ddbcb82b0a84e9657bf4.png',
    id: '7946',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d36f07978d523b646742114beef6f6f33b183ee97076978691fc64c48feab547.png',
    id: '7947',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/935facb91fceb28bc12b92a4211b908e41fb584e3648ee57a07c9896944410d9.png',
    id: '7948',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dcf1537367e2e84be750bcc46c26434b82649d8a2a0eda04c6f4bf98fe39df1e.png',
    id: '7949',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77826d3c835ff9db9a492895ff89a8821de756411d9215e9cb83da954e504fde.png',
    id: '7950',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87b92203942eac0e174a8001004ea43364eaf62a4e5034b9ea61482697c73085.png',
    id: '7951',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7159ea5af05261a7808f34a631efcad949a2eaa7eea76d297572e965b2dd9675.png',
    id: '7952',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/107459a0909d72ca07584242ceb20642de7cd492e88dcdf7cbe79753ea36894f.png',
    id: '7953',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbd79b7a32d287cca56e0926eae577682fb6b93e8d6df81d5263ddba27b42707.png',
    id: '7954',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59b5b8873af9e1152fb47077940c0affe1372ff35225dbf2fd76bf85195bdc81.png',
    id: '7955',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8da936db68fc72567f43f8cc0f677299f572cdf435d37a8faa69f78a159f385f.png',
    id: '7956',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11b2a7e53264b587ff6b4dc0a9b5733c12a83a2891df1d46931c8d9c7ff606d4.png',
    id: '7957',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdd7b66fb8361fdf273752b213d713062aa21193527d444e5c4b2c7ba8ea991f.png',
    id: '7958',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41b36fbc5dd772588dce7048d9c0db00df4664d726a4437f8bc572a30034555e.png',
    id: '7959',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Underworld Spine',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fe0514f57f79b08d37ffa62cb95244a93f5638feca838164c75510902104d81.png',
    id: '7960',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4464a675c7e16bb627f75da86ea526b86efe075b576d1c60e6a73ca505f25ff6.png',
    id: '7961',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed91609833fa808163d975cd22c8731422cae4b06d48c5fad3f524eb9bec549b.png',
    id: '7962',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff49edbea0b3c9564868e8b43ceca5820ce0581f73a3fbaa3fd87cc245b22189.png',
    id: '7963',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3f0a18d39626f5a0b971cb7a7bb98ae06b4e294dcc66abd99d5a1b360735a42.png',
    id: '7964',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08227a88091a963e31ddd9bcfe12198e9554153e9d139e8dbb3dcfdfbdffb846.png',
    id: '7965',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d71b1a2e4027382a24f6168a59fd6b53e91c93d756d5f4143114d3b4ea100dc.png',
    id: '7966',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/145fd13864a9d069c69c0d09fd8735f561167a2fcbc5a115579aa8b04b66ca8c.png',
    id: '7967',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0480f8eb12d679aa2212581ba8fd7acb4bcc9907f56713e7eea1fcf7a9fef6ac.png',
    id: '7968',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6dd632a56ba61ccfb91277ce1c89446057692da512660a4aafb02e98ef5d73c.png',
    id: '7969',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b2c00c971c63f88b5eb8da8687fcdce8e53d6924060191a21b221b88741b728.png',
    id: '7970',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec155c5984f6e1fe7e3a00a456d0a0f50b0ad1ba63a1d25aef966b576952a9be.png',
    id: '7971',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3bb92077833a2d325ba58db51677b9c1602662b314f2a8de9aa70e5476173aad.png',
    id: '7972',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a492999d41ae36e8c0f708cd59bd0176e5f4b181b53a9d754edc59ba1856bef1.png',
    id: '7973',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ad98599fdf345abf8d9c66b933e2b5433f01a135abec26a14f72b30ff9b44a2.png',
    id: '7974',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/815d1f74d300acdd38ba68009563242e6d0c3e264c4c8bfc706ec9d1a82b94d4.png',
    id: '7975',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c2c3a583d0562dfc2fa05ac1b18e21d0aa4ecae3cf4d903a55557daf75d1f0c.png',
    id: '7976',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/430a5217e9be869819b0269d74a54cc85312335e4b004a27f6759cf0abd4a109.png',
    id: '7977',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16028a4d1d0803e74f1cd7d3ab8a7c7823bdc8e2aa9cba09582e772be5f7435c.png',
    id: '7978',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afdf0ad9e1a9883fd7af119a8bdcba98fd98bc804ddddbba616e3fae9b782c58.png',
    id: '7979',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9236412c7d5c52382a4f290917724c77ef5463ef51771a21d5c2dfa1d3b36d2a.png',
    id: '7980',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/016a736530f04d3218d4672e900e919df1044e42ad1792aa7fe89d96684507ee.png',
    id: '7981',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c1bbaee7cdaa8ee46bbc178fb25fe40abe345585bba57ef84c6941cdf44ffa7.png',
    id: '7982',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/563dcf6b9232cc7da125e562f26c94d7a9ae2fce038a2d4d5ab21bd13bd30f2d.png',
    id: '7983',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98431dcb7026e66a96ac4abe39606b17c5609407f95ef4080b2050c59f932efd.png',
    id: '7984',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9cbb0697b4243384ab3a933085b863f7b6e42ca85bdc29111636ecc2d77d8ce.png',
    id: '7985',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04e8823c3fdb050e3bdf8cad2e1e5a079fa15508c774e9da7d2a02689c041edc.png',
    id: '7986',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afd661a57d58a9d1a94b2b6b5d0f5b4408c760615e1006081b985ad2fae5afbf.png',
    id: '7987',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cbf64d08f31961057f5de5769fc99e897fdb4fa02602b83da21913600dfcc1b3.png',
    id: '7988',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d1a6b6af798107dd4e1c81b463a7f0c005a5bc8d6706239b64235be7b70097b.png',
    id: '7989',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/596399979db9fb409f19c9bc6d8b4ecc1d5a3251b3ec533454bdca7ffe78a932.png',
    id: '7990',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0b94f38e569b9e4792c92028438c257594ecd7e7fae5cade306b91637603d16.png',
    id: '7991',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7a78536627f8cbece0a1d0a913187c0946b2b91aa0c54f2cf0be0ded8309e66.png',
    id: '7992',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b028871c8820f7574710d0d2082a3114fac8c0ed43e9db973b6a5fb95f812c19.png',
    id: '7993',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3f850214b5837ecd00e79c5f0e60d002d1c5306e1f635399ae21b5c466ad5ac.png',
    id: '7994',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24513a429a7e1d5c9b579b2524a469f727a9a0587ce1818484b1757d881f29a5.png',
    id: '7995',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60bb259fa8e72c1d994fcaedb79bdaa61728346372dbf7a2fcc9c514d8da34a4.png',
    id: '7996',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/483b80aaa27a04dd2754cd81dcf8d3d1ad95c95f2265e1a6ff021bd188755e98.png',
    id: '7997',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c59158fc356023292d7bc3e4d0cf4256d794abaecae7e74d12509514b73ea89.png',
    id: '7998',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4bc88097336f6f13695755a82e77210e5c60ffdca32874840adace262a879a88.png',
    id: '7999',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d87bc02fc2934c8f1b1847163bf75cfb77ccd6e6936f3f74c259b40eea2b8ff6.png',
    id: '8000',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f448a27a16bc65cc2061d1c31e3074f1a2f157731d236cec9ad9aff4dd0fd85.png',
    id: '8001',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1cb54458c55e708f5db82103a4fd956876ca03cd82845e87081572fdae709f5.png',
    id: '8002',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbd847eae92f38b40244a0ef83148df07252364590acc50729844a7ce08c38c5.png',
    id: '8003',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d873e85f6c845e689d1e062eaf231a0e030847737baac6d2cc58c5ccd5e144bb.png',
    id: '8004',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55fcd53bea30e4f1764fa19d65d4986a7c9e370be487cd6d31b7b40ad930c13c.png',
    id: '8005',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cfc3bd5d2f9f3c10f885c5589afc43d70c553f4055af1eae351618c5c8fa256.png',
    id: '8006',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf0d722b1320d51fb8a55f35579544306d3ef924c4cddc1aa4c1e84d82cf9721.png',
    id: '8007',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f3fc2202c9cf73e5f12f51dbf4b1a418ded0ed1e1dea0ed2cda94b9a61f9367.png',
    id: '8008',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0557c8883af717c617d0bc5ab45721fb59aea0fb83b476bc8d46cc8f384a0b3.png',
    id: '8009',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c65f5d53e277b5a3a50f7c88ab2cde1fff9929995d0300b3a1ed7ee6dbf66c45.png',
    id: '8010',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6737e8cb4db2e05ba4b91bbd5bf4aba19bd8407afa8ede3a648582544ec2e2f.png',
    id: '8011',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f84e7b0bbc825a6431f7a5ce006e8a5f4f9dda2ea84024a98cbdb32ee624db0.png',
    id: '8012',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Undulate Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07a6937cdcee64f0b86e9d8d63a7183b7b1e82b513363be2747c017295722dce.png',
    id: '8013',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01c0c6216117a0da3f2858569179c8af144a0b462f8dd7c765b6617086a41e41.png',
    id: '8014',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/460e2a5f5b99832a4e6ecf559c57c5e415924470e1fc89eed750b7666060e254.png',
    id: '8015',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83c2a050aaa63f78682303d7b85b78ca6d1eced76b765666028231af5f1cf418.png',
    id: '8016',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89cbceed07881781cee9b9ee57f6b0904932866619826c8d76d019e6c1f0645e.png',
    id: '8017',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f33e208a9c79f1562f494456ebfcbcc204e34c8263d1fc29301966b3d782405.png',
    id: '8018',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f851080c879f6bac062ee5e8f240deb6edbe893af91304ec73bb22c5b670950b.png',
    id: '8019',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6cfadc6fa814a6f94bb56dc5a91812f49ea0cc2c3e78a4091af240c653aa6115.png',
    id: '8020',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b88f504490ba8a6c0e07a70583a05a9930dc7f138ed832a30e2fa4602be7a68.png',
    id: '8021',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93d6874286a5c87a4e8b9602a73563f7e6f8eface7f68f41ae709d3e232babec.png',
    id: '8022',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/041241fb8a3263ff6432f4a7e7d9bce53a604901f473617ab2e13ddca0b15606.png',
    id: '8023',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a979414c48b23bd77e51b566c23037c13cb6f216f2c9f2968061cd0eab3d871.png',
    id: '8024',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ffb575ad484cb487a2ae7cf7dd17468633759217829d99e24a440255261cef7.png',
    id: '8025',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30aa82226880bd8efdf77634302b0f16a0aa5126bdc19680be98de2e1a6f83ed.png',
    id: '8026',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2831ed53fa17382d3d928435aa1482290bd5cef4b2d75377ca5b00780585cff2.png',
    id: '8027',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ad5791c9ea4af9a729a5bb0bcbebf71d17bf6f9e2a014b2d2b39721ef25747e.png',
    id: '8028',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Crystal Stabber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df7d66e1cf69088f0b0b4d3037826de3d61b3a6dfc8985e800fd526239d27bec.png',
    id: '8029',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f319dd4c3e57e788825a556b816187b400fadc5828d5cea8bc78b68cf3df890c.png',
    id: '8030',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ef92f6df566c723f4b198453ff66ff50e3e2c7ed985a0acf6e76b79745736d9.png',
    id: '8031',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49cdb33fa266d9e0bf1834969d81414f12be08deb6e6b46df7c7b7dcefdb38d6.png',
    id: '8032',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/daabc45c7b5131f4ee827438c516103c2545cb0f98e2cb7df61eb9e94df85bbb.png',
    id: '8033',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8dd1e32955be769d8e7e2481f4b5a4844441925a80eed025689e7e58176c1c57.png',
    id: '8034',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b6fad82e281beba0a409a1523066b59060cdf5cc1a6063de53573c89563a087.png',
    id: '8035',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b5ef20c787a863048e9244eef22431de30724cda932c70e53a709c6ffda4918.png',
    id: '8036',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd424074629b7f2b56491118c5ed63717b0a13d38863cc94368d9ebcca506c1a.png',
    id: '8037',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/874454508db721aaaefa93cd892e4f8245235a1c7202aafc125dc9e6184dd462.png',
    id: '8038',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0ba6004996c6bed72467c3f1350808c65520e8c1c1aa6db7db704d6407e6868.png',
    id: '8039',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e410fea6a32276fd00c10bc0584d72f213a5a945f3eded0d6c2c19447b3965f.png',
    id: '8040',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d75f231de06bd058a3f7381a2d304fe6470277a334b6a65b9475ad1bf342284.png',
    id: '8041',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d3a3051afbf4f9689c932ca558dcbe9754825bc66d2f01fe8e100e3535b4a9e.png',
    id: '8042',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aef4d833549e01f8a5abf0eeb3c1c622406cc03efd9958ddd0f3e9c9c92c8ea7.png',
    id: '8043',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67ac2ce58d48ac712860f2ab02869ec82ad3044320915dc3d7a77f96e9565148.png',
    id: '8044',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df674f3b85c22ca4ff4f53f1e1fc5231fc305b008b40640d1f21f1d3e6aa0ae3.png',
    id: '8045',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecabafbef3b57aeb7039782eb24cdcfdfc00d5cb0c3a06580d5c4a257ec3fb95.png',
    id: '8046',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0cb5b75557536f4a45d0ddf74a3dbad2af612e7c4c2afe887d4aa02a07ad04d.png',
    id: '8047',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56f2b37a30f3c97e76740a6708330967437800170282ceef82cd7697909ca595.png',
    id: '8048',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5e8cc6c9b59a3c6b92b8ab36fb25e87c85504651b90980f632eab14e2ceae7f.png',
    id: '8049',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d20961398c1c0ad07da96fe9f5a2b8ea347ee1afbf16aa20afdfe8afe71a0ca6.png',
    id: '8050',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/465cd8739bb6a71315cf53afcee5796c4639a87eda3ae09cddf1a93888fcf4d5.png',
    id: '8051',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a86d191407f135b8839611b101be4ad7f790f44bc5826a718b48853aaaac8c03.png',
    id: '8052',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63ab2ab35e75f8d2d4cc4d927ca468a2011717394023cd174d1eba63aa8a2910.png',
    id: '8053',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b20c317ca429805f27df769252e852ac5ee6c42520a91bb6f0c77ff20af1fe91.png',
    id: '8054',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2935f8c1452de03639f413d2ebef68153a2add06b3ece71b818108aa65e8b8b9.png',
    id: '8055',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bbf58a41e2bf0a72f0588d82671bfcbd45a4bb206d98f20e87eba6abad28cce3.png',
    id: '8056',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0070ffb34a678a804fe7affd4b5e2fac8e3623c6638ee390358128e12a125df5.png',
    id: '8057',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/440d0cb2f0b58a952eafc1fb40645f939cb9e7bd0631095f2d2ea403a9a58f66.png',
    id: '8058',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc6cf83548fbbc1d0907f20b73549772437aec465e18d4635a30e3db56812ae3.png',
    id: '8059',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/adba662c28c51a3b84fcb7556061bcb1a5850667e8b144d5c35a06d88a47c0ba.png',
    id: '8060',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b63635cc6ca4e8444510a6fe04f9705142582112c1cdcaf8ac3da8d0cd75ea21.png',
    id: '8061',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0eb12725aa37bf13bd648a44a3d4ba89f4ca92141931f1c4813eff8f88f24e5d.png',
    id: '8062',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f11b0611d8a92017e973bcfbae342128890d897bed1602087c2b5e1dcd8a3590.png',
    id: '8063',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2c041b3715932a9294568840386c7fe55236d33f4573bfbc7f8b12a18b99f06.png',
    id: '8064',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9befea4c03668e921958d3b379c10bd8cac4d63485117db871ddcff368b6ccd.png',
    id: '8065',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e912f6dc8378559a874737b686eec30f6c971550cd8f5616f2fdb1eaa3a5b59.png',
    id: '8066',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc9682df059e3291ae450c426ab92bc1cf909b586d6bb0c4a8eca842832fe860.png',
    id: '8067',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a37b8ce6b045905c2dfd78cc37e6c76e559c16ebf26654f18743d78c6655ccd.png',
    id: '8068',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13c6c2c51d3f4a8e27f294e708b2899f1d78e042541f4f38c2968fe7b7f7c830.png',
    id: '8069',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3adfcb5aa65186e3896e2439d970a930b51df9a727fe543ea782c8855b9bc234.png',
    id: '8070',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd167f0a2137c258332c0c37e0d57bae61bff23da237e60f244940e719d6ab05.png',
    id: '8071',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72feb1d958d35b7e550ca2e2fd9d0f2c72d18b92e29d5c613c5eef283dcec798.png',
    id: '8072',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90a6029274767eb1e5e7557c9d73ba44152fd868c499ed82925994adcf8d2c1f.png',
    id: '8073',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06b5b4c8bf78573eebee92986a724133b6b36e0c11b60396f2ed2bea60aff4c4.png',
    id: '8074',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b03dd76689d93ed0d603d6e75637608543955f826d551ab9788cd8e1e837fd2.png',
    id: '8075',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bae192c4497959748a72e551d060f2cd21fd4efb30ae92b350b55af89904435d.png',
    id: '8076',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93e1a1339aea4e72f4f3e0e13d66b54ddae9ea9877bfcd5c589039b62f3b6129.png',
    id: '8077',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e21df93afa9a9cf999117e3466e0270261bce6eee5bedce78d5bbd4adf71a1f.png',
    id: '8078',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/128bcc0dcb1c9ff72284f9580110453803487714df2bb5f1de2f759e17419b02.png',
    id: '8079',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37d68874c1e3c0bb9a7dc0bbc00d4ee88d6a92da87e6d751272c02baedf665d1.png',
    id: '8080',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08b5e733fb29ec6210dbfcba0462d988c2c4c3fa8842d03419f900c316950d57.png',
    id: '8081',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1cc512b2092c2eed9a2df05b7c611704d00a0e51f66526be067e4df037273c68.png',
    id: '8082',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6efb374c6c55203c2c7bf0dc2757928c633fb61a8fc15891937587f2d306d216.png',
    id: '8083',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ece86a40093c096c86d5278fd6fbf32ec7e19c7e6249c7ad24134e1bb5fa2d9f.png',
    id: '8084',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b97078936ec51bcd791329afd61da372f1b274c7be46d6e846c56929b9713255.png',
    id: '8085',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3004f177378626e6a0808c22ab22ef3f467726147a3c398fe1cad6983c5aa030.png',
    id: '8086',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60ba1fb6c43121f3b32610009d64e0d9ef052fad769ba6dddd4350dadf39da66.png',
    id: '8087',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
      {
        trait_type: 'Weapon',
        value: 'Grazing Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e70d2ef5665762f4aebe80b0f7ca3c64097102c802bdefd5cdfef4e4b1660a39.png',
    id: '8088',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f4e714fb90dbf85a45200d5086ddb81068b5751c9fb1cb57dc74d40d19bb47c.png',
    id: '8089',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1506b002f57dd2f5a75bd39c3c57050e51aa08a7487ec17c92f21039693b65e.png',
    id: '8090',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16772523dca6905d3ab22bf222d44515d67d51dc405dc2a1697872b7b5ea09ca.png',
    id: '8091',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08b2e4e85dfedcba067d3e76fcd5962c9fd7bfcd0e288bdca1929d8283c7bcd4.png',
    id: '8092',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8e7a1d09fda5fe317d4341e421fe32043623a92d4d7ed88f5e150b7675aac09.png',
    id: '8093',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/292c1e6742d830364fad84ba28fb485293a07c785c0a15ada74551d09a5c7096.png',
    id: '8094',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54cbedf0eb6e62d4bb878b06e2901d3f5932820e6aff10a144794f20993184d2.png',
    id: '8095',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d96f19d7c4f442db9106b47e0994c7bfca912db3019907a4bbe3fbe13fce7da5.png',
    id: '8096',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e29bad1e4cb37132ede8d71bf8e5ad27e5856a2b513fd44f30016b73937f3ab.png',
    id: '8097',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81926a05f74adadf53bcaf793b0da6d3505f58c466ec56c2a9ad1e5cad516599.png',
    id: '8098',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/729712ef9a67f003de6e22b9387743ff4fec249d59bef52b7bcabbf277b9a99f.png',
    id: '8099',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/684ad08d79c89cf51d8eaf516ae4377c738e56f2d90da1f93d5d6377703247fc.png',
    id: '8100',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/014c22390af448603abc84763a9dd59615136cb10019ddfabdc4d565407e3957.png',
    id: '8101',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93f2aba5aa8914ee30633bc69c4650fd95dfa246d286fe60efc5eeb06e6daf5d.png',
    id: '8102',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2be10a37301fafb09bf9207aca7031d99f12da4e6297dfde4da127ba5943b8eb.png',
    id: '8103',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51ea9c7d6cf5a39cda6bc0c3d677d37dc0af7793e3d77afe555469f07866655e.png',
    id: '8104',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/943b2cc1a2432b86bcaa2ae0c4d55bb2552fd63176e2c6b4df2e1b84ea355ac2.png',
    id: '8105',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97183eea52d2b1c6a9e93aec19024ed08e1bd4370aa3cc01c5997aac06d7ea4f.png',
    id: '8106',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e5fde8a9dfd7599c683d8f00996dd459e6390954af8283545deeb3126afa81f.png',
    id: '8107',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/487d129f8d61c6f0cd1192b3e53ded5d7259ec2b8cbe2426494d41cdde0f7bde.png',
    id: '8108',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14ee96f6183481ae748783e3e6592252c3e97cd0e332c72eb49156c160198fc2.png',
    id: '8109',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b177bb451a1e2a514d92b34bd47784299019abd43a97887efb642220335a233f.png',
    id: '8110',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Wise Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/457725336e2db61ebea28c33301b0aee4d8e91aa19520a9191a6c6f1b17675fa.png',
    id: '8111',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdae1371cb20f71f735359c01f563e2515ae507081884c654b50da7ced366a0f.png',
    id: '8112',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f995d3e5c1593aab531b4f1fb0614a6e66a8a62d4089eb68fac00568c3895f6e.png',
    id: '8113',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a85b8c8f65ac02125c31e759f0d99c481ba3f12acfb5feff103b551d68601d8.png',
    id: '8114',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06b3af0c69024e5a0ab6d6587f2455e9a78db146fbd45f7957ffc540c78141b1.png',
    id: '8115',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/062c57cc86ad3534355b9b913424b43138ba1245233e7aceecd98af5f3f23504.png',
    id: '8116',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7298220be01fac692d8c53e72e2deab7d5952c49c6f6bfd82de007b84f8ba323.png',
    id: '8117',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11cbef0c6d929c0aa77a387ce5bd5ae2950ed81447601b994671e987ff45976c.png',
    id: '8118',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f2f87a7d10f06e2e852ec997aeaae80de6ead5cc4abb7763e6be5540b27027b.png',
    id: '8119',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gnaw Saw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07aafe39e76872168802f4a3cf4674a3e09b6f27c7a866347f743db88a9a9412.png',
    id: '8120',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef261410668730b1d364a9aaecc7e43a38fa1af295b667dc1aabaa6bd40fa411.png',
    id: '8121',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ffc912f46487e0470193f904206e65ee785db46b5d954995eca8d3e3806541d3.png',
    id: '8122',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82475df062063e17ed6bb027519d348091ba8f4117a49c44b55ca1468ae5ea68.png',
    id: '8123',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/368d3ae8ee33c4d3cc749b501637fb627f2b62ffbf0e20d7f5f894bf321f12d0.png',
    id: '8124',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b45169eafdcd7f7744c3694304db557459c131c9f2b99364b51d9699376dfe08.png',
    id: '8125',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74c7d1ee3fdc9da3e6bae267dcd19a80c1164ba9e46962c5693f5b80b25702c6.png',
    id: '8126',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/335b2673012a5131ee685a56e4f43ff7a64419e12461e73363cc6de6b9ed3933.png',
    id: '8127',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ab44860f43b61829026ebbc0924615cb69c14617bacf620a7df993732b0547dc.png',
    id: '8128',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/163a20c1cb99f095946746051384418b7124699a30326580f84ebede31a3dc9e.png',
    id: '8129',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7deb77ec27b50c087fff76c80026c231cae3fe8e9053b4971e57b6b58ca6b4f7.png',
    id: '8130',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdf40c6d5568e48319e75724f1c76e212c80fd0a22c886ec0e3794aafaba3271.png',
    id: '8131',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38cf1bee72fe2420c50e71a00abb1998be157e87ee1fb3ed999e661b2754a4dc.png',
    id: '8132',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf843a07b8f697decbcd3f3858bade1875c78ad5c46d7686ecc4af63e7ca1f6b.png',
    id: '8133',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/998058bdbe0342679bc09465f1741abb9adcc22bf09e21ad68c9ecab25e5cf74.png',
    id: '8134',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5961b0abcba310f0ed437964fa13fff66084ee160ad8fa043b1c36b918f47c84.png',
    id: '8135',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c88d9ac8b8d57d18c202762e8fb9994a3f4c7fa4c39d0ca14a5544914e85dca.png',
    id: '8136',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f917a554e9199c3e411303dc79e15df47102114d6f74da10ce3069f63a3adb27.png',
    id: '8137',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'The Watchers Eye',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ca7d1a0a8c565f04b6cf0ea05a8752f88a371a917ca05ffca4018cbe4059c98.png',
    id: '8138',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91487f37f8489635758ef6a47a1edc31c93c5f60bbb4b44dff4cb22b439c3108.png',
    id: '8139',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66fdced0b7a41ddd83163caaeb428cdda383d21e0dd40f96b3801ac45382ed5b.png',
    id: '8140',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23f04cc7d7a71bdfab35d62c5f291a3296837eb2f0109b53704a21560305bde7.png',
    id: '8141',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62ef9b5245bb50844762e40a7355963f8c4747ae225338b4db0b6a1cca1b6060.png',
    id: '8142',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb350866fd454d58ce752c97dce047b47db17a36f7cedbc09a4d29fd5252383f.png',
    id: '8143',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/411f4b1b58d2226ce99cb43160520a260d02cabbb62bba2754fc6e39c2751c22.png',
    id: '8144',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8139609a3e3154a427301abc14971d97805cc2d2223c17e78f7bae97d19df05a.png',
    id: '8145',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4caf012a917b50eecc160f093b0759ba953494c641d66fcf61db1c70ce6ccfb0.png',
    id: '8146',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b37a35b17c9ac419760d112355254bb7a51b7189853f74b1edfb2cc3e8644e4.png',
    id: '8147',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d41bb2e01b4d4478e457cf0a5cfd2d49730c8b7ef59bba600163fae0d18ceab.png',
    id: '8148',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3372eca8bad628ac69e191b407c8dea999aef85bb56dbdccb1b5b3135e693d42.png',
    id: '8149',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6e062da34d2edf9405c59702521b09289e14dc1ab59b071b3ca42a4f6cbb96e.png',
    id: '8150',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86d37c3edc9e1786db8b0c92361026c3ae17041f2a26fd089a620e3cbaf8ed9a.png',
    id: '8151',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09643548910b0c829ac051043456d615e5e5834af942341857bc900d1ad33682.png',
    id: '8152',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e98b59930959c49a85e2b81b0df1a881c3b638f59c5d085072973551a948b523.png',
    id: '8153',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3b7cc748318425ca6e455061dd7f22d4373f6081b78f803cd4d7f3b042dfcfa.png',
    id: '8154',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55a889092725aa62371572422cabb1345eec229e7c19fddc13b8bbf0d3b6ee1b.png',
    id: '8155',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0bb6cf905eca4b21fe766e714c5bce6e563d3fb3765be9087951fe140e00ae8.png',
    id: '8156',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a294e2245ed887a9bc21d458330ffb6dede38cbd3a7be91a05bb85237e8daf9.png',
    id: '8157',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60a86fc41ed9a3ff8efdf4f1ac9bafa1e2f6729d6ca86e54faec4a4f607e6149.png',
    id: '8158',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1fc83090355533c62bf506dae9cf2bd6d63ecd961202d2cc98d117ce177cd908.png',
    id: '8159',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d878a2d41a837d91b8205f0d080076d443a554a47532ccc1c4c8e09107d8887.png',
    id: '8160',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce426b871e7424d087efa909bb5f50153ff7f2a7ddc07d9c7a5cf09b6861b78e.png',
    id: '8161',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/579aa69cb913e002e2e7cf459f371138e2394a3fe78b24f593681ed8eb62817a.png',
    id: '8162',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a8ec20fa19a4cdf45f90b1fd7508f08d2264c253bec4e21cfd1aebf712a9c7c.png',
    id: '8163',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ccd4e9b045bfe2013c3739c8785443c6f116c51c3189c06b38b4a03dcc062cc2.png',
    id: '8164',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5befaed34cfe1308ca5ca1022eb5d8135070d014ca9c4ccd402ca14aef5c65d.png',
    id: '8165',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d857fe1e3e3ac2717c84d328c99be28390132f3efc178066c034602519308a6.png',
    id: '8166',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5e2d24f860de5a6344c9e0df8e2888d4ca4954beb44d3d867d4b405c7af8ba1.png',
    id: '8167',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56d70d3cf856932db1256ec6acffb931cbdd0097c30952c47dedf1a288b2241f.png',
    id: '8168',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/112ca3b35c6f6e0744b55f538a02c6d097e8df618c0efc1dd984755c5901235c.png',
    id: '8169',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28e62e19e78c6cf00d5a83843d135f5d57685d786c889a404b876d31c8a073df.png',
    id: '8170',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddb39fed7f0eb0dc3b744ddac4b3da9c3ea1d3616334c00bf431ca07f151500e.png',
    id: '8171',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd48504e4f7b2dbd89a36637ca537a0f78dfe5df937e3625c59a16908dcc3bc0.png',
    id: '8172',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83a174df631c78b480a5d4bd23b35e9c40744f906dc250af93c815420d520f8a.png',
    id: '8173',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Batty Spiked',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0c30d57d872081d134cc5049fcbc763bdfc4795681d9710f12a0e14e45e9fab.png',
    id: '8174',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f8e4a2fee390cd58a50e63e660c1681f545b59c326fb7b89efccd16ca964a4d.png',
    id: '8175',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d91e6212aaa172c181487a8ee25d2da52118c24a3b1959cec11b5384b259d734.png',
    id: '8176',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e71213a85471d6be94737b737389fbbef0ed8b0c945da85342ac283d76b5d14b.png',
    id: '8177',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/adc6148161b6fbd6b037da5cfa2a0ecc5e11b5f88336aa7d2632f929a4d609df.png',
    id: '8178',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/882821ba1193acb643f4d0b9dfa89af61e57f7ca0b0a307fc2dc4468a70e4681.png',
    id: '8179',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ba969d4453c8f313b6722d8afb67a3e20130b411205f47180b011c3d89ff718.png',
    id: '8180',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/beb64f3972697a611d448baa6f7502e2f8ea6984c9cdd6ed5fa31ddf1f5dde81.png',
    id: '8181',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93ee948967a3376a43e557c0b86fffb0cf51d5e16bf4cf7671ebd97923377af1.png',
    id: '8182',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d1e6ea80ab4dbc8d8b7f52043033f97860b07e3b1c6992407a219fc3ad0b38b.png',
    id: '8183',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6c6c50aa688e903015f600016b9fabc40bd1d65a5f519e474c726808cb6ab96.png',
    id: '8184',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a84678ff5bc6537ed6bbae4ef7b2a3355bf68dfcb0274a43f68c05d22fe93af.png',
    id: '8185',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca67b3fbe323ab12a7d9920ebe137ab35394ced7a173f354671d9c694aa1b98e.png',
    id: '8186',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b38fce646a967f39ce1aca559d9ca19b45288960e00f7adf55008920d03e844.png',
    id: '8187',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73083d20f5b762bece67dff861e8e79383b1baf0f60df6d27864b88092af6bb9.png',
    id: '8188',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da41578711e04d2f5f83eb3dcc52d9ca747b54df733a14de748b4f9b1b7092b2.png',
    id: '8189',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff7c5933a56d08654544272a5881703831792510e5c01627a8ce53bc696b4717.png',
    id: '8190',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/103839b2e6da518c7cb77b96a4d646d24c4f9b9bdb3e5765724bf1080b77d8c7.png',
    id: '8191',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f8e3d3f59556e50812d4c990edc7b2ddd05df22b3fa93ce58086c9771c66170.png',
    id: '8192',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51caa20caeabd5ab21febd643d629507b50558c5e988527e1797afef30449c37.png',
    id: '8193',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10ec8cacc1bc25af15de637f05561f9c18b3ba81514bb10a8d039f63f8f8e70d.png',
    id: '8194',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa899b0caf2792d57d1bca5b260484c80e22195dee4749f7abf187a378b3d005.png',
    id: '8195',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a92807e0dd14f0a2364e4c7741e88957093ac593e726e5143e897afe32ba1c43.png',
    id: '8196',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28503d7705fbea2de6e1ea922ea21a2a3b777057825ef9b5c4690a36502f42dc.png',
    id: '8197',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55a7c7475a01a8392a0029bd3e5806adb59452fbca254a0059a7c2905f8eae59.png',
    id: '8198',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3e134d519ea2c2d91b3dfc9e025d450ca97bffa8ce7482eb0794f7f050973cf.png',
    id: '8199',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2684cbd54e737fabd86ad48396516b800518b48b99384b768dcb9a03d77cb1e5.png',
    id: '8200',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c099b0aa1f029907d5a068079ccf05d15e0a6d03528b596294ac55869762fe5a.png',
    id: '8201',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8cfcbab5ddde7a452343db75985b40d2ebe240702146e0ca7d39479066165614.png',
    id: '8202',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/889ba0f64ece9db6a85b82cb99afbac7c46df9165f1618617c8a630086bd2314.png',
    id: '8203',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Weapon',
        value: 'Winged Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7c23623e834f6b1336492fd097b4369db68140126530c7e690c8714d9a66ea8.png',
    id: '8204',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5326f5365f27344e97adebd8bda04564367ba4a691eda29cb2be10e7778b0c90.png',
    id: '8205',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76f86554e56995324ccecf5894d5da17b0c2705c655ccc2725d4b4770c82259f.png',
    id: '8206',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6917b5999b373eb11269124d8f84b0164a24d8335ec4e77996d00e536f8b76ea.png',
    id: '8207',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1af66c8127cb50eb2c73d396d4ad769735f46abe3fcf5a8042a35aaac2982070.png',
    id: '8208',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5ec0c004e8cc345ccebb5f60ad446e57f3cc847a1250f0ef2905942d41e033d.png',
    id: '8209',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d289a9e08cf335b9b8985fb659e7a54a68b5b84c9fd5d9ea900b1f80f5e4f500.png',
    id: '8210',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53d84ca1077c59562c3998516f619f4e355a1101f45126deba758ab4c5223ff5.png',
    id: '8211',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54b175665106f6eb417e3902cd63f0cede5ffa53b71f4cedd1f11c118310533a.png',
    id: '8212',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d68745891806b425ba55d78c2c81d70a782eaa77b2d7b64bf6271c7a28796b2.png',
    id: '8213',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfc932f9b75575441fa7c1880a2082e0f568bd3252e6c79af4975a716129e542.png',
    id: '8214',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0c9d98d3e6da65fc715fbf85485149ed1728c0f3ff87045e8e2beb7e998301b.png',
    id: '8215',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a068996ce9e5b002a31e00ea14396d5e16e7ad7f2e67a09f30ffde0414dc050.png',
    id: '8216',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/efcbf496dbeba895bb1cf2c30a11702e62b0445b7ba5369532e9c496134b21d4.png',
    id: '8217',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95efa40b744e511e0c66a7587b08344dce356e760d2e211fc795f7e14f6c09e8.png',
    id: '8218',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79563fc18a2c22c8be120dfe1b2ec31d6053d31c2d9ab56c2ad51c5b843e698b.png',
    id: '8219',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fda08e7bc65467899dd131cf1752199ac1836df2ca15f35865feb801937d786.png',
    id: '8220',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/425c8f3e7f8f25688b1bf3eb718da232abffce42fe5e6a76c36e158daa174a3e.png',
    id: '8221',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25ca4bede28a26a710219a1b4609631b35dbd18b46f21a887af8a9fd4eff9a50.png',
    id: '8222',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4ff0362bc6b3dad096f983ec000b53fb2a57f3e35dbc7751706d824b3a3c0a7.png',
    id: '8223',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b6a7dcff69fe0eae28c4d112a79150d12c98ccfe30f1dadac0e01096a412b08.png',
    id: '8224',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa69572313671f4c23cea790796c4440537c32f8725bbfa22d27ebbd4b1927d4.png',
    id: '8225',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Bubble Blower',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf82bf7474941a63a84448aed542b044ca885eaf2fe78e93ff749b43a750bc1a.png',
    id: '8226',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/612e7610f0b5f335789d8739ea6eaf41c5d0310eb7bfb76d5d11b81265c3dced.png',
    id: '8227',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5811559540f75bd2c0bd99e9944aaa170e40ed237700d70e6a6deec1b0a9af6.png',
    id: '8228',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37ac60086349f5eb735f27a3c1730b1e23b8fdf3322a541a0a533ff2c41a7741.png',
    id: '8229',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/024253f655a5a0fbd190aa3da25cc02810d31d0283c9339c2253938b44258b63.png',
    id: '8230',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd21d8c744a1683f6d482dd132d4e6abeb7f6c4b044dd312249c39625bcc2d57.png',
    id: '8231',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c547a87233d5db9051c884d568ad44ccf812ceae89dd2537ee636e3fab6d256.png',
    id: '8232',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5ae4f793a0c5731fc6161b0be897b1bd9697f9e99cb0f90cf51dfed1c2af627.png',
    id: '8233',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Alinta',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ac625ba72b399735e76f6687ca79d4335ac925e49f7003322048516234063d9.png',
    id: '8234',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7148dc3a0d9f3d39fd0a2e3a595900466eb10f603c9559d4cb65fd5763de834.png',
    id: '8235',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1dce0c57ecd4f81e2ea0d010278688578642c3f4cd1d59c8c2d4d9ffdd13e1d0.png',
    id: '8236',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48feda07cc56817811b7d4077b19d6d7435c825a19fc54a218200749a4f97c8c.png',
    id: '8237',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7d029b567a47b7aa35ac226c29afeeaa78219869da2f6f8ff0462a26fc92f6e.png',
    id: '8238',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f425776eb8e5d38afaf979589ce83efc42d2491eb31548637ac4c003f0bf983a.png',
    id: '8239',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d76c19760eab30f4c3d165c409ac31f4486e42214622c41167b90693a9699a04.png',
    id: '8240',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ae6d9d9509ff2f54c14360c9ab81daf78baa78af703c02af993ff47bd8fb962.png',
    id: '8241',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b9ee72f28feeddb0ecee01905eed7d986e955e77ce66d11fb2785134ec44d3d.png',
    id: '8242',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7f968945600a191e072905b5fd610eb1bfbba26f87f8aeb8a05ab00fdb6f187.png',
    id: '8243',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3b7e02640acb0cae2e9b83022e96fdc9e26c6c102ccd2ea2b41fe130fd59349f.png',
    id: '8244',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67b475163f4ea0d00b50ddf3e280016407439d40bbb3192e332f54e8cf02e8fd.png',
    id: '8245',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8b9522ae69236cb041da0586f590cd9dc8c2deee746f4e18d255820ec7274be.png',
    id: '8246',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac565fbc47ebbcde36a06b3dd2ec7970b680071556fd9b2ebdf5fb30ef69271a.png',
    id: '8247',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8480d11c532e6cea36acca10f438800423a151198364b4d71aab4c80b2154514.png',
    id: '8248',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14a2899d6da1c32907e3eaf4fb737d387ee4ca5d0592d412b429d793863c146a.png',
    id: '8249',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9fe0d2efcaf6a53d2f57f6b4b96a4d7adaf3492cc3b601001f2f6a2b43cfd38.png',
    id: '8250',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f410a7c768fd96b0a7798980279fb49bed1435936013c8bffc5cea949c6201a8.png',
    id: '8251',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3c35c546cf72f553d23dd8b3d13aaa92542b8d396b8036504dc75dd00dbfc06.png',
    id: '8252',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cd4af163be9a98529735ae5f0967265824ffbf15feeac3a3761f8935530e2d9.png',
    id: '8253',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c5d16e00e6f14ce3d4ced70fb0fd6003578cb47af7defb5da1408fb5b568e86.png',
    id: '8254',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0e6b055f3701e86425342a919790405cdd2f3e44c7bf91b0a77ddba38b0f515.png',
    id: '8255',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51deadde550bc0b3677fd80600a193baea8ad45059eaa12da870999e84ff3ee5.png',
    id: '8256',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22cc388e3dd345cb66c2c0591471706e1011716cf8a4faa8b040708e3cedc940.png',
    id: '8257',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7fc03d9585f233c611ae4a9f26af666e7accf32d6e6c9ac9f04a494ad0118b1d.png',
    id: '8258',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c1653e01c961b98566f5a8f2b5a585dc5bb43d8e6cfcbaa398138e0f0cecb90e.png',
    id: '8259',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5e4509a43fc986e14910a1d0c49d427bca361c4e0503a9d732a80df6ac63f75.png',
    id: '8260',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0b4323d16b2b0a017f920bc736369d288f31fce6f194fa9289562a8be4e8f62.png',
    id: '8261',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4cd9733a3aefd7dfb7e5b52958466695de837a4ed514398faa8d2809249ad36.png',
    id: '8262',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf489e8c9111ccf21756536c8e21ffa0d2037283410c35cef1b4f42e630f8686.png',
    id: '8263',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2a450f5aa4ab860d287c4b6eab0df401c303d38fd205f79a88302393e2735ce.png',
    id: '8264',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ced05ae7f18da2a1e93a3ef005b28d8a8b7d1e30c449b55b16829155d737bbd.png',
    id: '8265',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f4a98658c67cac52a9b6c74d0c4856bf4102ee4ef2361f1f82a78df1d559e0f.png',
    id: '8266',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7958e812c74477350cf7894ca57d87e5f6ec145af603cf6ee32994b134bc39a2.png',
    id: '8267',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12936f741e9d73e10f90a6d5a0be782306613719b640f500b51c2c72ea17b679.png',
    id: '8268',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea6564e2cc6568bfab90f50786fc463ca5301aa370f6da5526ee48a1017efc11.png',
    id: '8269',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/198b1a47b55b1aea3ee3a5af41288099df778488def20d472a42f9c9c080e11f.png',
    id: '8270',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a13ec1178bfa967995a421456e5858b29711d58c0732a02b05055cf1eebce0c.png',
    id: '8271',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a317dbe8f6f8df62c7bbfc7d31f354abb158feab286747794047f4fb05f7771e.png',
    id: '8272',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e87834a9b5471447a66e86fb01741cff28496e09e6963ed5f70aef334a999c0.png',
    id: '8273',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b5c041bc63752e01b9f58060acb1af6a8819aed08855a3a184f998b62abebb3.png',
    id: '8274',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5e6b625d411697d35c680490d5b68c193541701b1540a2b6c2f185f080c45fc5.png',
    id: '8275',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/459a93240ae9f2f18fcd70f3cafbfc38e7627514334d844136d0aac7697a9a1c.png',
    id: '8276',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29701728dcb733086e0b8f3529ab6ab48eaae47bc56ddaa3bd4f1bfde55fe92c.png',
    id: '8277',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4833baa2a6bc19ec0b04a6904936fa30311cb481797a1e3964d8ffa69a142ff.png',
    id: '8278',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8edb8fb6972229204e6eb7b1a65bf7e7a25c38b941a4daf1517fdd8166958f40.png',
    id: '8279',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66a6afe0b6878b9284fab9efa01f1719309d0eb425cd6ebf6b5628c5f9e58e82.png',
    id: '8280',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/410c63d0fbcf5558852110239d3fced7be57e7ad0708eda66e1c771ab9b8b56f.png',
    id: '8281',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5214a582c848a7b4eb3dce751dad4b096cf767d34b71646f046606b41f487ee6.png',
    id: '8282',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fce618ef53d9654b9f782096caaca8393925023150abc58669965b8fbdc76191.png',
    id: '8283',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d86e9b3fd8c5ee77cab235ff309d2ba381971bc6b7b7d058a9045962e4f5c9f.png',
    id: '8284',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44febcdcb1ad56c01124eee97a4d33b02a4af1fdf75f54eb8cc52260dbf5aed3.png',
    id: '8285',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/441d7067fdaf2cd8bebe686610cf44009fec8f5a6acff1a98eab398c7fd75145.png',
    id: '8286',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/838af29eee9f4639829b9c37b747dafe1220a61e76bcd903cea746d7f056ac68.png',
    id: '8287',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa5b608794f2ae8fea564eaac92d2778e41d6148b4f7c9c075a9320af0927e3a.png',
    id: '8288',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c19d7ccca041c4f5c08245beda2fdcc923a4e2082f5f948bda0f313c9da2856.png',
    id: '8289',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5171cfe7afcb8e39c266159c5be4bdbaa038fc1bb349485b002f8c560b415f74.png',
    id: '8290',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef731c9af76fd249f854a4943615060c8c4ea21ce04d1a8f97c376ceb0c0fb03.png',
    id: '8291',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd7010890a60e43e447b98876a2443bd746e58e5b375f735c61416b5f450f999.png',
    id: '8292',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Levitating Lava',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15b94d0efdeeeaab74874ec9026dfed50d5249b7342b4f0fefc052a7d1db2e6f.png',
    id: '8293',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f48da82947a1031f266dcd4438078f6db473c113e0799aa37d05fdfe37a37d2c.png',
    id: '8294',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3634a3fff4e90b91fda5b4d0d11b3f717dfa29fe6f251265c1c24be3a0c27fa.png',
    id: '8295',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f85cd848acc0be4d6d5f30722d8f41f3602716feaadd6f8529e4845bc1c2d9f5.png',
    id: '8296',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9513e3ecc516658d866a025cd16ffe3bb653218a784cb464661a0b7db927bbe1.png',
    id: '8297',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b5f486fb94770d3e42276d37869bb8ddec0b3fb10346f3eea51bc4f76066a5d.png',
    id: '8298',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddc2c32d3c89194b2d5583df7e853950b3b2c476abf4c4886de8134fbdb91457.png',
    id: '8299',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca7feab8f7c3b6dde58d32a65374f41cceb879e35f9afe18827c75d31a622346.png',
    id: '8300',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc19b5b959d5b194c34e474c73d39ac9670c45956f985e1336c5350edebcd461.png',
    id: '8301',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c26033055e347718cb37a635fcbe702754cb6c91f2e8b39c1e73e1401b21f88d.png',
    id: '8302',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc4907a02dd458733f5f2784c01eed053367376bd009068527425482dc7d11d8.png',
    id: '8303',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/801a171331e0c4e5c55fc7be7db8ccd3643367ee1889c2e5e1b34cbbec433914.png',
    id: '8304',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b945e789841fbf987aaa214e5186a797be4ec7fc47d5347b5adb2ebf611d4a3c.png',
    id: '8305',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f67a93d82a820e47b9b3d08d3085599fd6f6ffb8a04ad064d2d3cfdab636b1b.png',
    id: '8306',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a01c33f53e7a9a5f20f8a12d3d3dd5beb19f85c8973b02006824b26cb38e00e.png',
    id: '8307',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70d5ff810a1d004338a5512518ca44e5b8132ee995a0d591a1e71debebd59a43.png',
    id: '8308',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ba8752ea52e3111abb6db2aef274d6816499e784e59dec3d77e1de1c1fbd0bb.png',
    id: '8309',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0a8cc099590673eef4572c80707f3eff8baf3fe43fb70d6acc97e028b600858.png',
    id: '8310',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d502eeb235365f701ade8f290e149b14698a4767ad415e5ac1a6e007d595134f.png',
    id: '8311',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b178f34005bf12e5cbb23d734b39f1a958fbff7a18c0f4a6d0fbd531fa370fb3.png',
    id: '8312',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32ac7f8ab0310f42b2a96102bf3cc3e5b2a217d92d8f97cf64df1c518522569c.png',
    id: '8313',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f77bf08f067a95904800d92b30afc0369dcb3034497c4e327438b695a9101a18.png',
    id: '8314',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0e5c78a79c4713c3bc94ff67504bfd8f018f9d86b17fc4187fd514a2048ddb5.png',
    id: '8315',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/314d573602c062625b660604966cf5827df835782cdff99463e79162147becc7.png',
    id: '8316',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3babf8aab6b410d616cc50e347379cea301660680450e3bb7f08507e5152b78.png',
    id: '8317',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/594b06e2f48aacc48cbbec495039350eba5709e9640cf70916738f6ae4179bab.png',
    id: '8318',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3906c9c883a219cc42dbb04b1bc9973af7b37e9d22b5b9177e9f0ef51f6aa16f.png',
    id: '8319',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f08e69fb4808902da143f8bc84a621af6474b3c85cd1e1f18b9f582abdcbc3aa.png',
    id: '8320',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/382dcef8fe1cd5da5aa4288e7c8ed2bf101736251f731568919e223a29bf04fa.png',
    id: '8321',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9199e0adfa74d5521121a0c284471574f706b097ed688875a6920538f8ed3d00.png',
    id: '8322',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddb8ec3bbc56970eac275c977fb4d915fc7231b06ae6b6b7689cc266bbb26e01.png',
    id: '8323',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ac093642396fc3c7a817244f3fda5a4fc6eb60987c5d4d3cc3f5a5ab637242e.png',
    id: '8324',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8df6a0ea0623c180eb2da5065806e48e101186d7d0272ebc3b223bdbbb84fb2.png',
    id: '8325',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f7586627f5b4310d0e71d70eac1f4b4944a443147f9c110894b4039afc9b99e.png',
    id: '8326',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/46f2cfbb46563b7abe5d97080d6cb80317864bbd9928d82cdc7cada0d1d3ee13.png',
    id: '8327',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee51fb33c0eb9143f180cda320560b610bcaef3f717d01c2a07f42c9cf598f44.png',
    id: '8328',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Alinta',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a60260be5c5aa0fc93cd7017c5ec1d813e5e3065f53ce7500c5a41e878c37d9c.png',
    id: '8329',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be32b0ade704a24bd9795eecc7f6be6ba3bc3f01f532e46562b2bfa63e69018b.png',
    id: '8330',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/edcd6f1c5505f887c344f51a5d03b24d28a4b230d2e22e600e0ecdbb93370f6f.png',
    id: '8331',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0650ef02342968bba4c1060d28bdaa9b3d3ca49a9952a2f44f4a8cd7a2011035.png',
    id: '8332',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8804f1b4975535461ec8c5fe1e3a28c97aa303b49b3dff557a13886685cffeb.png',
    id: '8333',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2aee6acee199a0535e8bb5aceb963fa3f3ee7abc953e9d2473b6ca1cc5c6d965.png',
    id: '8334',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Weapon',
        value: 'Adventurer Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a52fc1412ea2de3a95bea7cab73751da1fb327468a61fd8e34d1afe603f68c8.png',
    id: '8335',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a1135368c04de75e7f46be41a82ac504a80ec741bf42adcc27a8833d781f62f.png',
    id: '8336',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6f4e38d9ec1f2e138ff3f12c6949c91a0fec9bed92f55a3153751bc3b8eb72c.png',
    id: '8337',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e71c03506c547d3413f8fd2502657cc0678b3747860ea28e2c67d95482e18563.png',
    id: '8338',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43dbed250cfa0943a4aa5ae321670edbb9d55d95095926f4bfcf67b2e106b395.png',
    id: '8339',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/540a7e0ab7eecae8274ccaf59893f118ead7c915e88891cb8a2842ad2568f6af.png',
    id: '8340',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e06b255fe50eea6c4e088d8e5cf8ed4fe813cd5810a393582f159c522e872ecf.png',
    id: '8341',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c794327e56d901007eb161278fda2e37e814b0e907667e949cadde2e2c44d8bc.png',
    id: '8342',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14c16e396448532d7a6f05be763af369e3a46c9f7706e2ccd5e818d74f1e790d.png',
    id: '8343',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a61437963e582690538fd36906e763c462fafa14357493d86dd02a6618cde985.png',
    id: '8344',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c69f61fd2f9946d18a81ed9c42946ef3c42dee141c3d438aafa6e64151d23223.png',
    id: '8345',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5590f78ad8d6f53552a64ab171a9599e6c6859fa27930e84402c1fab1966f3b.png',
    id: '8346',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf2681af66e989079095ae306ea7474060343df9faaf196979fc92b8b497aa96.png',
    id: '8347',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d81466faf475a0aa482224f54cacb7f6d055f2852c22abff914bfd451dc1c44e.png',
    id: '8348',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce1f666d3e74cf6e24485201502c9679b6bbe156718b794868e08e1999d2cae9.png',
    id: '8349',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da4e4c151eee36c63c9cf9bcea371898b8b58cc40c0013e117b51dc4d5fba9b0.png',
    id: '8350',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a299c36572e756da590c545937ee310ffa0ea5a3407f864442784152b103fa2.png',
    id: '8351',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/411453d02551d1b6e3a852398ff18012862bfd226f38ceb1b113f8ea53c86830.png',
    id: '8352',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13194a09619345013ee62c8573510f3f27ae8018ddd7adad91ef5eae567d293b.png',
    id: '8353',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5be9bbf6e4dc04a060b5a7dfe680a9afea129ee975af433b8847d33a6b702f6.png',
    id: '8354',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8893accad2efc6ca444974870d0130174b4679bda592ec40e6521df1db36b6e9.png',
    id: '8355',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e9f4a8657b4cfc7666c99816593e26a6fa34443f720283a357b299e3b657a5e.png',
    id: '8356',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f7f466e7995402adcf5dbcb8347c4f3d03451683f8c8e80f17b4b19a72dcbec.png',
    id: '8357',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0e5b7eb77a9a3c7dcf88c22ff257545eb75d9b23ee592bc26af238fdabe7f10.png',
    id: '8358',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15c8ed800e87ab8a1728f8d94a218f3f3de584f0fe0c4f06b77a8013280ebc87.png',
    id: '8359',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cc7e569f960eeba74db67b0972e3bd86f3fc9b970b5c90af12685f001202b3b.png',
    id: '8360',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Battle Axe of Loathing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3201c5366e4fb6b7c295ba80fea4277942c635deafdf8269386f136007ca7aab.png',
    id: '8361',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/da3f9bb19eacdbdc3034be244b8bf3e12ba84a8703bb160e2546898dd401bdea.png',
    id: '8362',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d77dc97be3d5aa28c16170075cbaebae3ad93c4a7028ce2c207b9e724dd60038.png',
    id: '8363',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d98c490fd892a1183ff4dce53860cf1a6b15d5ec0fb3e16a605eb5c4651ba2a5.png',
    id: '8364',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b551e98ce978e66610caf941a9db4b9d2363791648103cfe0a824ccde7d97e51.png',
    id: '8365',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/468efb0cb22e69f562c03ea918ba0c034eaa35618c16e23b097c776618d39b4f.png',
    id: '8366',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/919021544e23d7f9882ecaa02d5e38d066594ad2390cb9d24fa9a62e3e8f387a.png',
    id: '8367',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4b13703af68eca59e1a8ea864024b0cc62151addfa5ff05d59a93e763f06fdd.png',
    id: '8368',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b908ed8b317b3548519936a7d727d8854d96cb404235110ee2c5ffba01b5f46.png',
    id: '8369',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/923c255a2ae9eb659c81877e882c74d40bc334af262ee077f56df065d6e2fbff.png',
    id: '8370',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fab25cdc2ae1e295f7e4a475ba9fd744bfd6c04c0f434c2eb3e3e1ea827847ee.png',
    id: '8371',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a014cbdc5ec83a39ee5964b0820d23f92be6afaa0f91d897cb39e93ace578d1b.png',
    id: '8372',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5db6cd3b9c2858e12b8a2fd1fbea617b3f2b99d1abc6d26ef43833c6d154d30.png',
    id: '8373',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d2827722cb2209c01040ef9d39d3a2a81619f293345aee3075361a5284084e6.png',
    id: '8374',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/524c2341439113340da6c442eb55f4294bf5d3f73f2807de564b2fd8fa7a69e6.png',
    id: '8375',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/adfb1c19bd05f7aa150577c5ed944bee6d92a08028a676a6e8a13abf874417c2.png',
    id: '8376',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc7837ac7d583b6594e0871fe684929a500a5ce0d4cde26a04085821f4093149.png',
    id: '8377',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12c4c7c87e00274e033273f9e98e79b87fa89a3c87b4bd4c777256c4182dc5d9.png',
    id: '8378',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Grazing Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ce46e0b6799ed5bdb27191d5f2b3dca493c13edec04123f17bca53d77fb4ba4.png',
    id: '8379',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23c6c4abf935fdb43391d441baeab090b79dffd2be27e1b0a1401badab2ee004.png',
    id: '8380',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77ac6c2bb2c4210a7efbee9839d99d931ea567417d1d78f70919e140aa720b34.png',
    id: '8381',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e2c2364f12aefbaed6e77838226e2e5a012b4ffa7117f48a060e5170d9bd7fe.png',
    id: '8382',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e608c101133b6aa5c635f4e4fb6eceb363e687db71bde0dc25e8f1f339068bb.png',
    id: '8383',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92eb7c59cd1e687de450e6e72e1650ee7b57ef2a8ea8da46d2ab5dd5e42375e0.png',
    id: '8384',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0389237d05d78d233d253631f1ed240be8f052d6819603f971bb772bfe8dcfc7.png',
    id: '8385',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95a2b3ed92cd809665579ac64a6eee900f35998f7acb3ca89e695e17af6137e6.png',
    id: '8386',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d37b6fa6903b075a7b2c7da5f144382de16959b55c557a2c054bd55f9aa02d3.png',
    id: '8387',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a64ba64a11d138bb72e3c001592148ff3895d7fa7e38f8328f06099533101eb7.png',
    id: '8388',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0b4974dd3d1042c35758cf21b5c1107e39e252c8057e8c037050480581fcc9a.png',
    id: '8389',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abf7c5e4c64c07e162269ca806292ed813e286e64bfe29357f30a962bfd48f75.png',
    id: '8390',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48d6b7b98141599f201f60f95005919648e685402de7bf40f8f5fafe7ff49048.png',
    id: '8391',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c528dea431e2b34aa66274ab51799199a5f24a5d5b088281c38a9176e25306aa.png',
    id: '8392',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/346bfd2b31c4a25f296690512a0b527f8151377504669d7de9493f7c87023122.png',
    id: '8393',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27aa55d48524061ea1b04d878a827ea6bacf3b04b73973a73535c32213cb485f.png',
    id: '8394',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa563b69dacbbcd13587630954ddd8b9454408cbcd83b371aee9d6a516f7754a.png',
    id: '8395',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6986c8f12a81c94465b3bf2956c524d7d41dc2c1a1c8a7d458ec6b95a979969a.png',
    id: '8396',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cbc7396db7790035f6fed98562b27a9822714883e6aeb8a2892710fb3c320f2.png',
    id: '8397',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2da107f571fe1f9521acbfb3ab22253f90d5b2d44933700a75f8e327d0b5d376.png',
    id: '8398',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3f8d6664e0d315ae4a1b0d2c6f703a2281899f57d1cfc4c71e20ad8a562eac47.png',
    id: '8399',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74101945b10f90531a93dcdde67c0f6924d4360e7eb4035b79a658158abfa6c4.png',
    id: '8400',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5cad42040890bafc23eb19c6ee0f751cd60e5cbc1fc7ceb74b1ae331a2cc571e.png',
    id: '8401',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aaa66718ca3acbdaa324e56c751c318771cde4f5034af723f4a3b107e69f4642.png',
    id: '8402',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd89523125fcf321994eaa98b9eb21b399af72ba745e2df9e3912e5c4036280c.png',
    id: '8403',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c82f426cf08276b7ac97962468c5ee045ded49da1079f219bc0d80ad80c7e32d.png',
    id: '8404',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1361bd47dfc246904288afcc9ee7e155c1d543aecc585d7c2c55ba931cf820e.png',
    id: '8405',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64e64b9de0e34197de7723907315ba105614903a10042667ca027cb6dac79ef5.png',
    id: '8406',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/812a660d37a0bb21885ce25e16e2f5ba51c870f26a85803c094a88758b2f01bb.png',
    id: '8407',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Undulate Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ac36d2127f5ff2460d0b6c04eeca6175730211ad2979654ab0c7e46b0137876.png',
    id: '8408',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63e45b79d3ba97cd15c3bd7dab0fdf17faad3ecf839d1c758426ff102c14dffa.png',
    id: '8409',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d3d3d156d8dfe9910fa24efd06c049b1e136fbd010e7c0514a57bc5dbcd7abb.png',
    id: '8410',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1373c7648c66a5fb9655011fc0f90f416313a944ed9a461aa5a0ca3491fc1626.png',
    id: '8411',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b0713d75e2bc06d28a1ae1861483101680300569e10f0c57048cdc50e1e6405.png',
    id: '8412',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa61f4788bf54ba3fb1854dfb4cd84497420513cd3023bb092b13bb33b4bc6df.png',
    id: '8413',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/474c05cb217715cf5ede35c6e7d56d4d76f9254386b576be015c22fadfa48464.png',
    id: '8414',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4ce25355941d419707d87a1ec179e9d8f4881eec2b6917c641e960709bb18bc.png',
    id: '8415',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8568edb8f778c31a1aa33599688eb664d9a2a098e9e600c7a1c4671b25541c2.png',
    id: '8416',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/869d84d919ff47fe8d7fc9e3d52318565d75070eac6f5127fd0edc5645765efd.png',
    id: '8417',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b85b5374ef642b8ab6df9b484a9d54890e28c0162b9b5834beeaca2336b807d.png',
    id: '8418',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c4d3de019a46ded593ddb0f2eb917215c07cf5a171fc7e3d7c66da2b451383d.png',
    id: '8419',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2da6980570403de03cb6819aaab4e9d31de02b210ae939ba30f3473700de470.png',
    id: '8420',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94817c69e911158c7f37147a68729e1b85dedc703c5641dfa8ee14857bca1f63.png',
    id: '8421',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/526e0b4b35982677a2024fa796ce2bd1f2449387d2514ced24160bf7139847ff.png',
    id: '8422',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f19ab4e4912efe79aa0e82dc8657aa5d994139fc5c9a0ea7cc872ac1ce7f7002.png',
    id: '8423',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/675ebdce2350f473be1d9995d1bd316a41af68d9a3cbda174909068f61fd4b0e.png',
    id: '8424',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b3ebcfdf5c3c783b99446df22019a01c851a054fa3b2a06dfcb8a307c83db8d5.png',
    id: '8425',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65a3d1b3f4589e59547d56c87e3d17c5dfd8cb74b863062a8b0017a1d5e48bd5.png',
    id: '8426',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77188297ee155ff6e282c98138f54685b1efdb13192d2b0025d593c522915b44.png',
    id: '8427',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d822249024a062c81a0cc20f54ef1da25d6d9b9ced48cddd059911efdb75d29d.png',
    id: '8428',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/400a4d84b435c3215441b2e4cfdaa359659658f0ed15c241e4de85e33eda5a0a.png',
    id: '8429',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f8b72629e0c2f6dcdfca5ca9a83c7688c1331ed5a4008144cebb95b909dbba0.png',
    id: '8430',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d391dc5eb8828a67f33ad81aef7f27d7f4971636181993954b8ead684f15c458.png',
    id: '8431',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96f23bebdc5e04fd806703b082ef9e4e27ae04788b958123ef3b14386a2f490e.png',
    id: '8432',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b652e41854ced086c9c649d93c7e25c1c36311f9f6eb92e0a63b133b61dc25a8.png',
    id: '8433',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d355a3c82b95a9f22ca4434feaa706d546bf8e786738dfddef3655f9d4a3ba85.png',
    id: '8434',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ae7c8750f642a43b9e1692b4a4adb5fd3b346e5f458f04bf4c04fd4effb29c8.png',
    id: '8435',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99e11f5c5e6ded6fe0780c5710f3dda71b2a23891f2f712a275db2d3c453b4d9.png',
    id: '8436',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4deb476a2dd84f53b52fbc91c8852d60fb91fdd056426bcdbaf01556764225ae.png',
    id: '8437',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/136917179526bea36ebb082d1271a89dc1255802d10c31ab635cace0522c4071.png',
    id: '8438',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8846ee051d1a0db3537383f869558fd5255a9968c8946cc93f8d5a11a52f9565.png',
    id: '8439',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b372f2642ad4a89d717bfd92d2c687046906c286cf8ef30f0773ff49342bcb5d.png',
    id: '8440',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ad9719685c0a2e2e473eb711cd5591aa4f2994064abed8785bdec7312a3f4d4.png',
    id: '8441',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f523051a832274b047d477f714360b94cfa103af7ea0a8c97e8b3ccf0a94fead.png',
    id: '8442',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0b35166f4c9925b1e1493a64f14a26c8906353ab965066cb42c5ca3797f6b7f.png',
    id: '8443',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49e9adfa836b781c82c1db7b1e1c61103e8c9ede3beb26f32f8eccef062cf0d6.png',
    id: '8444',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67fd1d26ac094582e495c39581775ae4ffacd406f2e6d7e37f1af64c54f6b726.png',
    id: '8445',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/911311bd07e19a43a85803f90ac551c959e7d1eada8ec4928bec886eed6c541f.png',
    id: '8446',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0bb26201eac50b5ecfa0d2d76ed36252666ae28f09f3a0564e633b7732321aa6.png',
    id: '8447',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cad4ec73a8469c199d71e5c9672345eab4a5d2649d3ef7cd326bc052b694fad.png',
    id: '8448',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a01477c66348d03050e2e5c0ef5968303e498c10d300babde4203c8ffba62526.png',
    id: '8449',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af8ccf30e009cd38a6cfb4b8c0e9e75d3f970ff567f7ded0ae4babaec212377b.png',
    id: '8450',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c7c81938effb9a5d46ef31c9f503391d1e9ef001be515f068ad4da289eab4f3.png',
    id: '8451',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83b6143f1ce32ee25b4750d4c6b668d06b65f0c4335c642d76f2b9bfa292f04a.png',
    id: '8452',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Bone Hook',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f86941ee711d15d84587c7d0012e7cbceeea78c98952788154ccdaa20b5ca28e.png',
    id: '8453',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0163139c38b9c2e796a36be10ec104fd72bd132c56ef37d106b64d133b476dc7.png',
    id: '8454',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Weapon',
        value: 'Mush Clobberer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a91361d3596d2c00a5ecb8e59f46930098f0f5743f893a6aee7a78b833d4a1be.png',
    id: '8455',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d0528fd1cc74d36636d2589b0acfaf796a50e2e18a3e0c43839fc17ca3d8426.png',
    id: '8456',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bb59d90ea97f0f5ed010c70ba5b0d78bc19447ac4362b40f46884946d4291a4.png',
    id: '8457',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/721260a25ff4c8cd8989ec67e1364719512b8dc0ff4e31af10d44f0094d0acc5.png',
    id: '8458',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9be470990b4b1cd4a0a5ae271df3552fb7a925f2095eb4192ca89e4fe5067f41.png',
    id: '8459',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3136126946e3e471f19c872e03f5fad5de78bfa71e279422d07339fbbe8834d.png',
    id: '8460',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/419b3b2a141285dc1f652128caea1e4109b629757a14560a5d64fb147370bf47.png',
    id: '8461',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19572be7f2c25c4ab4ce696bae0f106bbcc92e739efeb4c94b428f62cc192402.png',
    id: '8462',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f217732e20de8818138d63eb79690cbae38168a9515c7b139702f8b2ce458df0.png',
    id: '8463',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac5763011bf889455d4b5a43ca9555053d64aed01b36920f3e9d69ec7c7f9dfe.png',
    id: '8464',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a834f0815aef71589f29e22e1984dabebc63904d639ce75dbc64539cfb395691.png',
    id: '8465',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/edaa0c8cb31efccffe7aa91fa053a14e544b7affca815d51086a9b2c5a5079b0.png',
    id: '8466',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b57c2b678489a7ae7174c723f539210bfde006fa9d40c6eb10748b644b243a94.png',
    id: '8467',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc2f2a7daa4b1896784adf83fb5356e99106d13d9835d1e7842fcff10164c5a1.png',
    id: '8468',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/942413af75e0eba4e7a95f167f315c5c8a01c23d36556aa69bbf15180e9a98fe.png',
    id: '8469',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b567d05f406136c530e0c95c678bf76962187704878f034c4bec6a07dce2486.png',
    id: '8470',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c997b154526940a7ea64b1b00761efc32ce09ef72fbe3a4b5d1c103cb1d0598.png',
    id: '8471',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a925d941a9958680b8ee6e772e36cd2d77c4ea45bcead9f1fcb2892423cb6f11.png',
    id: '8472',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0960b75652243e81eb23b9ba57d16ef7056bcf400acb07cc1d83461d23c8b150.png',
    id: '8473',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84ca763bd1a77f5adfe0b5c663f6902d2ec03d22d72e0ef479bd32d699be4895.png',
    id: '8474',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bcaa0fb483d422145407487cc1f659b3bcebdedd7f764a36c67266e8b8be0aa7.png',
    id: '8475',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4fd059e328f4e4a0c9fcd65ddeea155bdb4af24092b9d104c408d0e9f216942.png',
    id: '8476',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1052a1a220a88bf619ab89693aedc455997069306bc6ab7ff6f7b6707e572477.png',
    id: '8477',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a4f2374bac1f6668a66c812f5203f7770b08df45938b9fa75afc179fb3d582b.png',
    id: '8478',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abe5011ddd97932c441d9869a35505fffbb71ed750323fadecdce1b7b329d108.png',
    id: '8479',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8201307d3f4903f53ed8a8fac353b28015f0bb5f7d84e6aa2079ee72d634b6df.png',
    id: '8480',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83d255c19f652f09ec42dd7bdf6ca02e544b7a16390d5a1345ea50a6b6d44eb7.png',
    id: '8481',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12e5f96a3df6920aab81b09b06a79037feeb85f42e1639a9ec96dcac069227d9.png',
    id: '8482',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac63ff8b814bc942a2b27dcc83909fbba824395ce868e666d28d15938e45add6.png',
    id: '8483',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Batter Up',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a96e8ca548dae421110894e5a11fbf5c064ba0aa277b1a32c8f03fe37fdef64.png',
    id: '8484',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/688dfd30996b50e0b2fe809bd5796f44b0abdb6c595354089fa78c5e468b5ed6.png',
    id: '8485',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53039fda25462c31c8c3fe417f83bf2f68d2756e6ddf0ae98b0f0c1e7eba4c48.png',
    id: '8486',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1983783c249a7bce45a3ac7ed2ff917ea79d8359d51f22c0f95e9064ede5a52a.png',
    id: '8487',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d34e71209846628e5a621e1adbdd7d56739dd2740398007b1e04e983388796b.png',
    id: '8488',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/85b1e52a26fa7dac9807f731c75685414561eda14f64f4487bb206414a032c04.png',
    id: '8489',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2bc1a9ddc5eecc2fd0fe5b87802e35c2109422f3b0a970b899b9e88f7b3ffab.png',
    id: '8490',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c8053e0315e8d68c3356116a51ee9f5283808e1ac5cd86c0dbb96c78e212644.png',
    id: '8491',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ed623eb9d6b2332a336516a4084fd469abc2620c097dcf851a84486409977a1.png',
    id: '8492',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef4eaf19a25c7b9ef56de8e1ee47527d26d7ac8824948b43f14888461e4c3105.png',
    id: '8493',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c31681fb2520c0e9a6e1894820969d360aa57163caed7d50e2b75c959a4cc96.png',
    id: '8494',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5beddf29fac7976ee4c866c07b7e0ecb3f68e1758e5e48c607b30491a3087864.png',
    id: '8495',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Scythe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27a10ca8e72061e73aa997fb01ec1c5b38383e56913455e66b7f08cbd8762a1c.png',
    id: '8496',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c73cb5ce633658dcc670dad2b1bcb68849cfa015c784b8cc68e3fa37436e949.png',
    id: '8497',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6836be6125d7cf01da3b64b10cdc9f9c3045651be5e74a81d128a86ea98e301e.png',
    id: '8498',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/120fa39569d4955854c79c0ddb76e444c32e30d7a01673453c3a602fcd0b44b9.png',
    id: '8499',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0acc9cfa123158863012800aa5cea4be1c33d8586d3678f0c33d4499a6a4b05f.png',
    id: '8500',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4a68e603aa48ae1d77bb69fb6042c54b20cff30aa8c8d1ef7d59fe43da41a17d.png',
    id: '8501',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5eeb74913077c421dbd5501995e26dab5c0bbec27bf3e363237dba0f6bef1201.png',
    id: '8502',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4df5eb30631d1da648a4c4cb803ac4347ca3b48ab1ec7f43050f020c08eb6a0d.png',
    id: '8503',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/271657fe7dced9753945cc8fd4d46c6165b7ea13b0e89635adb3423132d7cd37.png',
    id: '8504',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32b902b4e569236177e925a9e58e43e809c8bb3156c70c99612ff3b688fb5b73.png',
    id: '8505',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cdde34cf4bd9da8b491076e3cce5342ca6f48ef4e70a0d239860b206ac4ee5b.png',
    id: '8506',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91e1b2b62ccf3fadbab18f0a7dd68a95fc5840dc6f7ae00fbc90c5f8514c3e9f.png',
    id: '8507',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4b86fcde54b05dd6d034c0483eb01edb01622fec0ebea5dd9b6845527a28741.png',
    id: '8508',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7513a278611468cc36ebeac90bf715da637c40368bb3a073336b315ce03e736.png',
    id: '8509',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/69ec66dd87b5af9e005329d76bcef7a96fdd8bd72e86a69e401b90515e08e9c4.png',
    id: '8510',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b20d7eea139267ac20b78a284f0f7eb9091e45354f640b1c8b2fe0f7cd472ae.png',
    id: '8511',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/caf7150277edab8292dab25bac94df33b704548492f35cd48c215964c716cbd5.png',
    id: '8512',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1dca62e1dbff7af4c2d4ad7335d22478ba58b28c635b85eb4022b3554c6f21d.png',
    id: '8513',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/272eef667ab8e12717552e8f4b745ece653f74b7f547360a014ad4ddb0055de4.png',
    id: '8514',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88388d4a0032f96c88268b1aacd85d6698239d559e8272df36cb0d46ab2c8ecc.png',
    id: '8515',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/602754ec662e288ee8418ea24db8f18fbcc321d325a8f185d2936b072c1e987f.png',
    id: '8516',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Adventurer Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5279ea49d23ab88c8da61b17d103b5bcaa3e5a73dda28620441c171f99b86dd1.png',
    id: '8517',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca259c1257c9304f7fb548517d6a3b03195cac93e5a4abc6e3c68ed871c744d4.png',
    id: '8518',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b953c3f925bdd6fa838d71fc6de3b6adb36a2f319e684bd08e955745435b7bee.png',
    id: '8519',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c64362ff7b6006add5e2b3bffd17db6c9eda6a496942a0449903ec3f32ea0ef.png',
    id: '8520',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d6a81bf8fc25b309ab0da5545ee40e4e7e67745fee948b41a1c43795f0c9df1.png',
    id: '8521',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1117a055c78a2ade6f37ca35f53a4d0de4bbf4bbaedc767ce1a7a083ad10abd.png',
    id: '8522',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68219e9ba75da3fd8f74547f070fdee7009d39c624f406995da54e93e4c5bef4.png',
    id: '8523',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/929b67513f171104306a56753b84dec17ab64ee04dcc41cf128fb0ed7fea5b65.png',
    id: '8524',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df9ba5bf940369bed6984edf33021bfebe3ff7876946c7c92d270e79206c1f13.png',
    id: '8525',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30b03ff8720609b4125c5eb8c2eb0569b3b0d144400f273781b80dd409d6cd05.png',
    id: '8526',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4bd77691e702c9edba841197be354c27204cc2ff540078ec1de9e6794712cd7.png',
    id: '8527',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afbb8e4d78ea891139a6d2c503e1cf12667c25599c187a7265764952f2bf6ae8.png',
    id: '8528',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c5b9d1f70b34691d125324623e78f165659d4395c70a803877de58c2788a8bd.png',
    id: '8529',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aae6f4e5ee15d9d77a3d4f0a025da254fe290a6c17fe6da5bd4078f52ebee1c2.png',
    id: '8530',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a5283d095935ef3148e7a52c9046dae6ebd0cc0f15d0e237a0b2c5d26ff149b.png',
    id: '8531',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a716813ba57deb80a0692bb8b1e222fdb848bc84d9f76ae585c97bb3287a697d.png',
    id: '8532',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7e7a4c8c92e550175f2130b11d17cc6dccce568c737f5e859a2801075e15fd0.png',
    id: '8533',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ae74df148fdb0aec4e5b51916784d5d2f6a1ee8cd08a7f5cf7efdf37af7f261.png',
    id: '8534',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c2cb68b325a128aec08e06a8c424e5e32c455a6e3207d14495691f316d21b12.png',
    id: '8535',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6903a288066371862835e0ad77ad05e472be295d971d24a7598b9218beb8576e.png',
    id: '8536',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d6779de29c28466b570aaf6c08f8faa19310eb16e5dec545af5b61051ead3c4.png',
    id: '8537',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3edf6cea983856a488a2b37218cff5ba96ecc0a5a2c8a5ef5ef39e1f9818f036.png',
    id: '8538',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a51d3c2f777ca73c6c16da05e12647c07f0e2bbc00ef63ab0aae5e8c99010f7e.png',
    id: '8539',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e09360815d15cb496fe548e36a8a5e05f02c81c478f925fcd10c793f73e936f.png',
    id: '8540',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2906e1540762f013a07df977ebae4641ee7ccde0c033dc955c099466ad6ea91f.png',
    id: '8541',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45d8ad7da0c472b526414b18c6979cb9bfdd95501057ed5c105140538365d3fe.png',
    id: '8542',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c99df81101524781e7cae4e2129e3c657ad0eb9e5bb70858538c19d9500750dc.png',
    id: '8543',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5feaaf0ddc60ffa91dc603434058dac4ce013de2fe0da5210e3bef0f99fb8433.png',
    id: '8544',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee58062ba3423887a8832e5db749aa798eb1fec3a760f53f931d02735d06f43e.png',
    id: '8545',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62022a963cdc52cf0d55ec116543bf67d8fb1878ce4c6c52f9503b35463a8bbf.png',
    id: '8546',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/634c12f9113d33db9ba60e78d6abbe66c2f5f178fa552313273245f6996baf11.png',
    id: '8547',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04d1667cd5b6f032d520fd0afe547d54b59a4d3615ced42d044280c7b10dbde4.png',
    id: '8548',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea0ffacb50845f6f97911641617e5b8aec22eaa0a9983f1b2a40c970a890871d.png',
    id: '8549',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce010239c0d03ed8669f6d940163842ecdff331dba4069086259b190fcf4916d.png',
    id: '8550',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4d05b04032197059a233efca4a11466e48af0a7ba6fe7f51a87b2544176b8428.png',
    id: '8551',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e83e682cabe9e85684bf0f326dd4e0280465b2595c03d0baafad25c8dc8a95c3.png',
    id: '8552',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94541c056940d36d76ddf0d3f9777c73a616868337a82b68669588910ea6e620.png',
    id: '8553',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6d1e23f5af316414cee7e750eadd9b1de130c0f1127fcc9c29fecbbf029bad0.png',
    id: '8554',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a970e046247b67c3fe40105aecf69817e11ddf19d90934cced021c828dfb97f.png',
    id: '8555',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df1503b0698770db2d85407833b2d19305ef143f9757c00aeb4f0bd915639854.png',
    id: '8556',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd03e38122a93f3a86d281c127d34a535052dfe9f7884f87052b7b1d82c73438.png',
    id: '8557',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cae0992c857756ebe355b734347ac89669354aebaedf47c0b6c33c4ba59abe8.png',
    id: '8558',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f97c523c7525f61c01ba1594c639f9cc5a6c710193755318d4d756e7519dbdb2.png',
    id: '8559',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12015e4f53eb66301170a3a3bc46808263d07fdb420a00ddc0cd6f5eec2265a5.png',
    id: '8560',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f64f86d3441af4797ecab1e8a4a974344ffeb4286b018285efbfbe7086f1d4df.png',
    id: '8561',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84a659283f57355b94a2cae55c1f296e7c9eddc56f36815d3ac6cae19f1465b1.png',
    id: '8562',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd0bdaf9ccf8ce4a931381e3c5e0211f99251dc3488a8e52665e389d62d43a17.png',
    id: '8563',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e28cf1cb478d9c7c81ee517ed1d2b6600e0507d92d18bd989b9b9132f7e3f9a.png',
    id: '8564',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0305fce2377fcd6d98a70438958975cbf417c0740c3a36ac1b5c56508165270.png',
    id: '8565',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c926095f8eead0b04ecdc8bdfa237cd97212cc77c3c650d500727ecff933edd3.png',
    id: '8566',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c702edfc17bf509f6973833aa05bdf144d90c5aa4dabbdba811945e0d06292b.png',
    id: '8567',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9913d7bf1f15a143bfb9fae07b67473183d682a19dc8375942ca8502421067c0.png',
    id: '8568',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/598645486e0b18ed13d3575933bdfbc318283484067282e78cf6be6a3df58450.png',
    id: '8569',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/66133dd20818b85251376955c26d0d5af3158a18ef6245edb55b52f507ccd3a3.png',
    id: '8570',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f15445878e5adc5716ad57e70b29658bdbbe8d94dcea53cda04e2838c1faddce.png',
    id: '8571',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b201bc7a89fd5d3dbe07ad83e28835925eb30b4021ab3100a84208882151e9f.png',
    id: '8572',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98f83c44a6c5849754e6a35fc57e76c9fd6b5f27e8b6b7a21c181989380aaa9e.png',
    id: '8573',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aed41631fc4e0ff7cf8043b28549ac350e015c0e4ede0f5fec0de04f80ea79b6.png',
    id: '8574',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2578e09e7506500b46ccc483beb25c79ecef6c58e7a5b7b9f1edee369f30fc54.png',
    id: '8575',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ddfecad2f34fde5b24eb0f123389435562b615c316b048ffc6076e23e859354.png',
    id: '8576',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d743d02befd82c17898b146f9bd753398f03bd22cd448000f07cc4caf4289c1.png',
    id: '8577',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d21f0a180ea86a8076d61a62829162ea9edd2f14c98c64f81934f92f1691ca6b.png',
    id: '8578',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf1fee4428183aa68b60f7db8768d80a596f517dc0e9f050a282dbf06e034f51.png',
    id: '8579',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75bda46d43c7bf5b2d16b9cbd673ed5bd654f77c325326b0311501bacddeb396.png',
    id: '8580',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Wood Ear Chopper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8abceb7eb4babc82858337f437ba0e1adfbb694d7664d0ac0e173854b417b7ba.png',
    id: '8581',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a66d7b247d4e3051e3fc3025f62b05317772bccc0b8b2821824722cdfd220ea3.png',
    id: '8582',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c72642d4271d1be2228077772a6e6d28f192df09111b6b8acb3227864b156ff.png',
    id: '8583',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62323042631c0042d950e0a6c6c6bb3914365276dceecc46b473830559abf0d7.png',
    id: '8584',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c45d97d75961a1b01de5a94f9104785736fbe835a4ab253aeece5f47f9fa3313.png',
    id: '8585',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/085ca24d23eb432c5423f30780a94914d33d8756da77ae18b7043b68fd2588b1.png',
    id: '8586',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebaf6cff70e392be4ca4ac98001f6dc552ff78e782ab93d4991cd190e8c7d3bc.png',
    id: '8587',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/204fef3a44f38f13c903d6b1b4bd9fd006e5a23f5e70ebb445110ca3d2fbeeaf.png',
    id: '8588',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34991bce86b011dd17aec0171be32bb50a645093befefd01e081c77b522661b7.png',
    id: '8589',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/817bb69a40b4ce8c27793b11e4f08fdef4f622f757b80a6183ca6a947a217025.png',
    id: '8590',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ba2031f5855e0a8bd6c86f3da224c437cf7690b5a5aa13b7bcc48573463cc90.png',
    id: '8591',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35a78348f26346233bc2c8d2b1ffa0ca66b2eb186306ed1ff0d91e91e5e4a835.png',
    id: '8592',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43a4378d62714048bcb3a999e294fe01ebb84dfa356c2f6b07a703cb179550c8.png',
    id: '8593',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/922b34f4ca9d2325529d19ba3728da73b1819175e258538df18d32d8e42d46e9.png',
    id: '8594',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/245f76c3c5f5fcff97b0e111c0d7bcfe179f4ec05c4783d191829336e1262714.png',
    id: '8595',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fafaedd3e154d10284df6f71ae12ef54331d8b9e7aac4ce212bceb88b093cca.png',
    id: '8596',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47c55d3e19fac412f85d8e0fdc0670893d058e6ad5cd867e3dffa0dd87c2f989.png',
    id: '8597',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9df0df8b924a2651071f89494517100b4a72d8ff556e030aed560799a2448f8a.png',
    id: '8598',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ddba90ecb6053046b3476e477406c4ce72eec6e7a78fc9c9ef0eca9719e86cd.png',
    id: '8599',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d246576d64bb41faba3daae6b2e7849be77506536886bd785a7be972f4748d1.png',
    id: '8600',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fc989914347f78fd8626a8700cd56914998d9d754c1e1132cf892c04c0179a9.png',
    id: '8601',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53df5ecbd11cca2922ddb9ff3a6f051696a7752644fd94b396b500c3f0c0c652.png',
    id: '8602',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdb2e8c1aca373558c4777cccd14747f2f11c23ec344475d8410faab85a95227.png',
    id: '8603',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/368daecd1e40bff3ab60229d60d7d3a23d8bf43f83cd761211fcc75b03854546.png',
    id: '8604',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c37181bddf7c0937299fa5ea5c82cf516f88026b866e69e4503176a8a1382d4d.png',
    id: '8605',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19ced2839f9b354095ea3d460cd98ead81edc907cfed679119d217b9999e7270.png',
    id: '8606',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6382bb075aa0e52412733e816446ff19af4e95319582509ce49c9f2b53130981.png',
    id: '8607',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52a4c2da5a137bab7361b84c30641703c84286b9bec748374d7361dcf0566d52.png',
    id: '8608',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e30ed43e12c22e603c0e984b57e6e90acf89211d6112fa6abe92a77b7649704.png',
    id: '8609',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3c919b1d797d57a3d9fb399c53377e7537b65385c840d1123e70d1f005d0b33.png',
    id: '8610',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Cactivorpal',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d90f517f7d19c07543855d97f1320ec0632955b83dfb3ad48ca3d7a4e065ebee.png',
    id: '8611',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf9d0b0ac34d851252c9e9607606e33cc36d100cd82b27c804462e41fdc8ff3a.png',
    id: '8612',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bc4824c07121109a3684e649ca434cd09dc70c6e3470b73b7834e913542ca8c.png',
    id: '8613',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6f030baf5b19aa3044e83bee6361c11fb6c5ab56c7a917db25de1b7d303bb82.png',
    id: '8614',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c0fc0d836d3160b1bda6941d9149f6d8e7ae34bef10b669dc9181bf8284857a.png',
    id: '8615',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d74e4d3da7ab62bb96dab9dae0956e1462c04f96c52328f939afe78de22b8fa.png',
    id: '8616',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4dcbfb31181029e5561505436d7a3618752069924aee85b73bb466f21eeaafd.png',
    id: '8617',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Explored Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/450f8fb45eb6a1513d0c7048efaf2c5d1e20768bbcc0c6696e4d279e398538ad.png',
    id: '8618',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/425b406c6054b3e18d4707ec52b4d4ede1329f576419dc34894c593c3740ec16.png',
    id: '8619',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07b25c68b4bc7a35e9c4afb0b17eda98baf376df430cb21fcf2643d0407c00f9.png',
    id: '8620',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/13d5eb953a5e0a6d597d0b7c911fda9f689f91aa03f708dda24c7ffd7fd26589.png',
    id: '8621',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/283c87359a4eae300cc1603e54db39b367803b50b6e8230e26943c5201c17568.png',
    id: '8622',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd5b13256f4d35bcb796699e001af98a1fdde33f07a8f994bc5974562a9da350.png',
    id: '8623',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77e5fdfc9bb9896aae5d552fda9a2b1347a6880fe05d8136f5059e9347024bd0.png',
    id: '8624',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f0fa10cda8c84b1a489372689199f02a7c9935b36b0702d69fb6efd1b10cb6f.png',
    id: '8625',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3dee4ae559d71926c41841225dd31431c1a31da57c0ae3d677e08628a93aae93.png',
    id: '8626',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be74977af371119dc5e216638536e52899a9cf70323ca6755e9d69ae17ca842a.png',
    id: '8627',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acbd6759f024dad287dd378e04e136fc84b508ba486d08357bcc043257c8d62a.png',
    id: '8628',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5de173bd5f6657e26e048657a8a5e837bc97ec0cf091ab168a25156d6056c3c8.png',
    id: '8629',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17d640f74016837eeee439c593ac5336e0ae7f30c28ae30e760e2ce37101f32c.png',
    id: '8630',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e3f4b6b74cfef1dfaa75efcc78104c9c70967bb5377b66a614693137e9ad675.png',
    id: '8631',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/222a73d80a80781fa33c75f8ed5b5b162fceef49f2cdc8fe1de305d22218b0d7.png',
    id: '8632',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64f47b5bc67d5b01ef9d447080bb7b216eeb242f469bdc392f626545bc5810bf.png',
    id: '8633',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acc12e6cce41faeb341063dd4f9058bba570ca1a27a6d62a7a344c60cea7fd3e.png',
    id: '8634',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8811ff3708ecea97eb2b3716d947da9311dc27093cc73f74198bef1dc52f6f72.png',
    id: '8635',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ec46a351891b863f1ce36507bf33e929624afa6527311d53f3dc3b07887b6dc.png',
    id: '8636',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a73f51b7b5725b05b90e1014374f701fb4e46dec5c5464bd889715d24dd8a0f.png',
    id: '8637',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dbef934adf064736cdc5e06b32eae256e8c22c40a935aaf376738ffd3cef33c.png',
    id: '8638',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42aefc38028134abde700017f3165db766d246bda349d2cd4049eed12f0feb81.png',
    id: '8639',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/024430eeb52076d9b094e9743c6a00b4da678eb2f0d496c9e1e133fa9e2a7c0f.png',
    id: '8640',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d23cc6e39ffe08dabee3cf8f9de15c17efc16b937ee7746658915cac99198c0.png',
    id: '8641',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09cef72a531e21dab5086cf04830677497c28e479d025d19e6f9c234e29d0760.png',
    id: '8642',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a099c40ab186dbf385e2948329405bbaf89e7d29ac3aef22431ab4065f799d8.png',
    id: '8643',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2990b4727b30ecdcb248b716edea3ac7f3af90f303f2ddc62b71da88f7a7fa8c.png',
    id: '8644',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Alinta',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5656fa8c7ebaf964ac0647bac8b3bcbb7b79c65bde10488aea261b11e65fda71.png',
    id: '8645',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02d7749d479799a3198254393c1bbe0b3c178a6fd699b4906f6abe865ec026d0.png',
    id: '8646',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b6d5ec5e34975e63aa601805fb06f49f651a5fe6e68b3c92fae2c4fd1ff3a78.png',
    id: '8647',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d2b258360a1e6de110558272fcba61447adac56991e0683d7dcb926d6c3346a.png',
    id: '8648',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49bb27619932375c9e6f9ac60654cfdd8434a9cc914211dad035703ba9fbd733.png',
    id: '8649',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e09f7ee50bd95859a0ae76f400c749a9a68b0e843075d7134889840b6eb444e.png',
    id: '8650',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fee5fd450d176570510223ce4691457664b93260c23923c651bb57621046b3ac.png',
    id: '8651',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96766cc848270344b1ecb162630d1f9155831aae7e611e5709f0350adc288170.png',
    id: '8652',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/504d792f94c55fafdd0c3ad308f3b61c124044056f6353b5f5360ad4789bf17b.png',
    id: '8653',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d92aa5ca2fa3aa58d9280390edb02d98b0c213604e7f8cea188ff8765ef1256.png',
    id: '8654',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/867364c5dc9d033876be41e984adccbf5303ef52a4b7e42c7cef92cbd3b49a92.png',
    id: '8655',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/adfd37947bbf6bd14eb351fa761af86687077a62ca8d698c33fbb369bd7e9d8a.png',
    id: '8656',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ded4a46da25fb58dfbd5fdaad490f7a0e05f4151ea48b4ed12998affcadd8ae9.png',
    id: '8657',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Fuschiacron',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2967e1f27326a752ed27fa31b0b448711b942c8cd7f3495cdbca2dfee6fa7156.png',
    id: '8658',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/461b590121df9ed5a196cecb160e08ca8ecb693fcac9c72c2a808e05c43abb73.png',
    id: '8659',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c1c78cb377988e5802a3ebf91d18ee508a098d7b7711fd4ce08bfbfac5cd0c6.png',
    id: '8660',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b014b78a91061a789dec1edb4eb01403c394c0b8fadbca388e05f48f94dece8d.png',
    id: '8661',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/899d16ad00a134e803f15e2bb752e5c7cea5a150be4383003b23c44fee062b28.png',
    id: '8662',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Alinta',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1c9e1f6e701868a621e08ae81b814654193b2d17a9efaf080da7e4b64a56965.png',
    id: '8663',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d00bebbf37fdcee3769886b172efc4cea8b62e56796718dc86ec4036f3822aa.png',
    id: '8664',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8609652faca95cc98208af453f4d0bb3f477f6b619c2581d0fcd92e96bc94c29.png',
    id: '8665',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1d4e865b01bda130706d0a68487ce10afea5334357b8f4d5985dec78868ed5b.png',
    id: '8666',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17b9b20fdc661cd5d9d5208158e47f242ef215f510f000fe9d633822542ccc0a.png',
    id: '8667',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a0115410bf3c9e049699418c4bb4f00ca1bed64f9b47750e478b335baea0cfd.png',
    id: '8668',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f832d9abeb1bcb9a634ad2ea14ee7f27bd609d4749f1929dccb96c65e6168677.png',
    id: '8669',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bb815b1da2a737a9532b08ffa70f84c4e3dea61e437a4fe6617df01143d3e5e.png',
    id: '8670',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18a1cb3b69b0ccf2c2970b073063371ce4422b11b273c56ae040b441e6ba3c56.png',
    id: '8671',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd29a20ae7e7700666a1d6fa2a76464f8f3758a4d9673337d6c1c9a7117c0777.png',
    id: '8672',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99fcb2a41405857af22529bed4ac90a1c72764d8d9d521c39d3ec968ad484504.png',
    id: '8673',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f172c7dfa46176ad7e9ade7be30e56c4c6eb70462bf63ba33888ba82cf3dda34.png',
    id: '8674',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b9f23fdc2d985682ca3e904fa0e5f873d6ca60b8ddb14f518b4a750f063c0a0.png',
    id: '8675',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/793062b5d1525065e59000514fffe87d31b724970e83c02ca8c8b877d03b9dbb.png',
    id: '8676',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/89073f7f2a45320ed2ab15d2928b76cdb8a5ea8698e8b18f77f5ffc4cef2ebc3.png',
    id: '8677',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4df20432be9ff9f4cfe5ca5864539fba2af333e5b723d5cbbdf0dda1b2c7220d.png',
    id: '8678',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d707a252af9abe5b720b4a810a7866c43ae79eeccf2b9a6fa097f12be9b87ec.png',
    id: '8679',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Twistree Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/708136e81fab9963e782d60ba9a83e295d00ba2c1e6ed4b4f5847f05461d9a52.png',
    id: '8680',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3cd43b301bb9419c66bd6f1d816cb48b8230856b79db371e23e1eb925ceb15e.png',
    id: '8681',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/984ee0e12dae331e518acb11507626371778c6194b27913fa4b31e69ad8628d9.png',
    id: '8682',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c66088f21c2cdf3d1b8f406a254f292197e8e6d1e893eef940eb41677f989ec9.png',
    id: '8683',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e103b0208b0129b022793586f9256da607e0d03abacce2e36bf5a489d8a691d0.png',
    id: '8684',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07d675bbe77a16b0cd1bcc94febe70c430e4adffdda9a792594aed5017fc4b29.png',
    id: '8685',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/184398a45b160eaa97df8be8ca4d670301348609a61100996e4d2c1798f03f55.png',
    id: '8686',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7797a9c28c2769c68b50dfebeb2041a5beb20b5709e0c5e3ed9fbe6d5cfcaf1.png',
    id: '8687',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6e177813941aa416bac9fd2069ac1b6c7d5d424f82ac7a1c6003077155f5d33.png',
    id: '8688',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95d5fa7069338ca733fd27a6c6717d1e99ce9a959f79783b7441510027265128.png',
    id: '8689',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cb57b1ecb7272baa8db75ffd8900ecffc41f3ea34c82325ef9148eea88013062.png',
    id: '8690',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/005e45c7dd8927adc3683f3fde403831542241ba7a2e8935f99c947d41a15568.png',
    id: '8691',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/948ed50b77a5def83ccd7e741ef88bd5c7f52a862cf685453d53d7fd8d5cc9d7.png',
    id: '8692',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c276fd90ff36f7b3a38bab2da036d235269659c13bae109779795862ce9af462.png',
    id: '8693',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74e1cfed779fd004c81ea659ac6299f457076aa5a465c91afb999a068a493744.png',
    id: '8694',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b67e25526e0489b7a1e3dd62097d879205bb1a13d1913ec16ad92a59be6a018.png',
    id: '8695',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/587301e761e0e677817c234ae388c6885551487087a5ec9510037d2da8dbe582.png',
    id: '8696',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92d06bd2578f7890b01bef5197976a9561249daf1641b1623297f153b5dabb80.png',
    id: '8697',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9cbd5ef6f3bd37d226dc943f238694b3154b00b287d2d44f8b19e4cc858b1b8.png',
    id: '8698',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5010ad5bd73d268ee778a97c2270d4a732d62c007acf6fc404ef569438a3d648.png',
    id: '8699',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e22f2f55cf744da2c3f56cdf205a58ab5c35cab27b45e295f8dd82f1ea0cb4aa.png',
    id: '8700',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0a0d939fb209222f0d3442375a7f7c03e400a4f7abaf46393f351c3be21ca7c.png',
    id: '8701',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb995d669cb65ab133255d304e241721b5ae1aca8444d521a09861ee9f16231f.png',
    id: '8702',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1aaffd470d9d90ce012e6f7d0f1fd2071062fc01b2293cd0c48827511170a7bb.png',
    id: '8703',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11ee6cc73fc86cf6c52ea29b7660837dfc8a38f0fa6e58c0687340e132f81fd8.png',
    id: '8704',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/393707456c52ca13ebb4087320d81a0d30459e8605a2f6ce6511c6c9b6205604.png',
    id: '8705',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6f8f0a74b60b7af95abd83900a867ed7031de30b697b916e8bf86e89c43f853.png',
    id: '8706',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/50a3301b29da7df8c72903c75b03079dc9311c28de560c8c302fb0787e0449b9.png',
    id: '8707',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e4f4939b56760cd2515fa968bd76aa5999cde44fbc69a3351e7a46d98cf6d87.png',
    id: '8708',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/693038925912554fac85f3fea5d1870c2ee0358bc3cee52708476226565d3c2c.png',
    id: '8709',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05e868e299c0c8361b407ec463b95b19e4e7763530cec0230bc284936964c9f4.png',
    id: '8710',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e22b2d12e5ecbf4898f9a3fff866cb75f702570e204774b787a299e7017909a.png',
    id: '8711',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecf108e1ddfd6521307dd8b07f5efc918f060af94de8bb96133f3513987feffe.png',
    id: '8712',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2cb9eaf5bb1043545b4d2dbc7ab9575d34031e948dc94546f186bf512d6ff38a.png',
    id: '8713',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f18a68dd3ce3d05a5d7daf03f9ed196c0d77ac9dc07f4db8b4699e67216eada.png',
    id: '8714',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55152291ccb746dd26ed8f45747af794964468967a2895ce26ba3d43b1ad10d4.png',
    id: '8715',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0004fd5eb40f724e3310fa270ced126435701a7e5b09469bd6beb5eff2ed23e.png',
    id: '8716',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7aacf5d838d0961abe1d9ada7a4efbdfa6fb22c54e31b7645b58317c0f8b9410.png',
    id: '8717',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a59fcb2bb00b33c2d8d508238b6ad265790aa5985500ff75e75862028680fc81.png',
    id: '8718',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b9a1c086934f8607c2cbbe943d2f1dc30fe4c9f3b3ff8255a8d2c82890a67ed.png',
    id: '8719',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d299fcf9af6180c2b5a292a7cd0c02a6245f499e2ad08834023d63e1c0a4b43f.png',
    id: '8720',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f70067347d64dc6c4274570a9a94fda58558e3b0bc228d8b5cbbea1a32a1c6f5.png',
    id: '8721',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00beea9392576cd4df357eaf06962e9488b4046d7dbd5bef53cba469b5a9334f.png',
    id: '8722',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Weapon',
        value: 'Blade of Great Heights',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec27fddc57396d75706b06968fd09017ccc911659c8bb5c68f7a36a61068499d.png',
    id: '8723',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70ba8fac3c39d095a1de437fe16e2f0e515ae5579fec86514fed08cbdc40c2f3.png',
    id: '8724',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b83d4ed4bd3b586fecf9a4fac99f0e5f23816be49b8384c7a5ec22d4c6ccaedc.png',
    id: '8725',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97cd5c68f8b141f8d325e3257bf7ef4f8c16583eb236b0e9fe164fe1b45fe7ad.png',
    id: '8726',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/727db85f60ef1f9568962ba6188945d4c63098bdb5de2ed7bdb422afdfad8ce1.png',
    id: '8727',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7adf1e8759af8a0ac45c3660d133c4dc5904ce84a9f613f520a7c38889f05759.png',
    id: '8728',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/664c1ab376604ecdd7f790cebe4de9446b8c155b6224ed4a9e38e9cd1a71685f.png',
    id: '8729',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0abd1f0cf6a62ef33c593a7e997aeef90af814a2775034707097c14a1c5cf5e1.png',
    id: '8730',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d630f1d6fa677b424cbbe2efe211cc8dea0fdb88dec4e90511a60c6895d24740.png',
    id: '8731',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a619f498e96d95429d894168a9ec188537d4ada6901c3aef7deae22f7e04fa10.png',
    id: '8732',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f0b3292a7922cf80915835ddce5ce013391af5a054a479e49fbd03c150c4fd0.png',
    id: '8733',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/468657a50f906cc92cd24d0f92866f122eadc827cfb59cd3a7a44ea6fd52bebc.png',
    id: '8734',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cdfbb00f293e07299043eb5f5fcadb750dcea5e2eefc04474188aff0dfd1f7d0.png',
    id: '8735',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a38a1f6b469d16d7745b2958809f3df9baef6e09764aa999ba07717a1d1474f5.png',
    id: '8736',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8acd079c2862c25a4e521216467fec3c091ed114f836a65a785a3cf9b26e976.png',
    id: '8737',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fd1b54ac92aac2554effa209a6751a7bd6dbcc461ec37e00bdfea1eaeb69f4a.png',
    id: '8738',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c43099dd576975cb7283c826ce36bc1dc21786b2e3a0129c4bd5cfd7d37c1fa2.png',
    id: '8739',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06e3d0368675fb54916ceb7a122637a989e7a9c49de562e00a03b79a83928ce7.png',
    id: '8740',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9dcce14becf4038a006332e2c455171d85900bace8aaf8c9d45d69c430c98c5d.png',
    id: '8741',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cactivorpal',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48a49b57fe0354b706979df3c7b21350b5c80191d2315cfab1706003046a937e.png',
    id: '8742',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0da5e618715c6d1b492784a8f0237ddb21fc56d80634a9902ec82cadd960c58c.png',
    id: '8743',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/256189f2205c7f5447531eb10a40ba228eda7b8d1ddd7392eb77966a2eb97de0.png',
    id: '8744',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2c254ad0dd3b2b909f31d6f3ac0b057cf60d6cd431694ea4bca31508e0224a4.png',
    id: '8745',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4d36bdc0fcad1449cd8eeb711ba479e607510c6d840f2be228db5a2c99016ca.png',
    id: '8746',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/733ea7f638e44501071a2f81447a3aa8949a01b86e3385a0c8dff54a73f24925.png',
    id: '8747',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8d6044b85eafbee3567f97ba4c2ec734e85b09ad0e1333b4e854ae2443a861ab.png',
    id: '8748',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/126199d5db63ab4c6b4e65ec84826c2fbcb592515788a24d2f725967f26d74a8.png',
    id: '8749',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/690a9d348c600f71e836d84f038e2987e87f99ce842163de097cfb87140c8591.png',
    id: '8750',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e059265f42723b3f9f031a3f2459381a19141bba750d9ac2a13454a50c3aac78.png',
    id: '8751',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2acaf2e6f7cf424054c61c84f73c55b011c5ad6d6916c490ae3052962e1df4ac.png',
    id: '8752',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e6a53b40db6ff88dab9244dd540808eaa2c4a3b90f3a917aa99ffecc2eff2e8.png',
    id: '8753',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Bubble Blower',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a80b253cb1c201dc0149d87d87698b6ba80c3e9fca502956eb0b8075dc04502b.png',
    id: '8754',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0515f121a4aba222aef9d8e3c2c5516a3643761daa15bda39b1572ea6625ca7f.png',
    id: '8755',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d9cd59815dfbfea17a1c8a55dfb8585d938e4d852743d0a45c52ddd620dd237.png',
    id: '8756',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23417dd4b2414dc34f683348d193a13d9543190dac9fe9ebb20c9ef00a1f160e.png',
    id: '8757',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2488a948f6e90c0f0f04eda784c2ef7b7366bd80a88ff09af812110febac8707.png',
    id: '8758',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b894aa4bce91db8d6502259cd989c0656358c48e298f82a88ccb075ac2d077af.png',
    id: '8759',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fc57e194dea0455c4466fb2b4980d068cf5028a00615594c6d15f593b7c0bdf.png',
    id: '8760',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f54617cbf2e22843bc6ff834391744c8e7958449fac908c8d88b614171f6822.png',
    id: '8761',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8de32700e724ff0c8331dc29eb6f4649437f38b57aa91a5afb6fcbef29fab23.png',
    id: '8762',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/375187264416eb36c5ddb14497be38223b32e78d164ee857ad50e8808f202f49.png',
    id: '8763',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78262ecf0effe2c34a979304938043d6f10b691b5cf0370debd26ef0593f4982.png',
    id: '8764',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wise Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74029a5687dc87aa97ff9244022eee42e0ed7399355ad3452a67199b54872616.png',
    id: '8765',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8468335145b0c66c8cefee576cdaca61128ca7d501932c9a79bc83f7589d7e3c.png',
    id: '8766',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23817d190d53687cea8a1c100091c6aec5222f4915363e895f616b0cb31bad8f.png',
    id: '8767',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c85a607e76c4c7f11542b29210087a3abbd76129e8c034ed69cadc43ec13a62.png',
    id: '8768',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/167da2bca5fa403e47c5776730c47139ccf4925ef02c08fcd8914618d5787267.png',
    id: '8769',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc1942802f72fa07b48a339611c8670871ce4d8f5fc098af97fee515000244f8.png',
    id: '8770',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4031b69ef5067bba106331ad866e55f2943c60074e14fe51085199004392f495.png',
    id: '8771',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f2910b47c31ed0a9fcb6c93aff133af0896a80d273315536030de01e03a37d1d.png',
    id: '8772',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9198494aae4a54fb35a3488f203704c0afa7709007b54825774267dfc2e98ee2.png',
    id: '8773',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b7116b543f4eee27d819f996bed94b0067a4ac317fe37c5c63dd4cc9f3a6e62.png',
    id: '8774',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ca360261f20bb62e596c013ab8f48d5d8796486ceecc4efcec711db2117d903.png',
    id: '8775',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/617d44b0b08db4f35e790f8cd43a281addf0b071434156705e0b8c3a9ea360c5.png',
    id: '8776',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfadaca3b09a1f4a21d8c4f87f58dcff5496a284008217b59ec3adb6cf598979.png',
    id: '8777',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd56bdc7e79544ad2b934735b9b083f953070550e393341362f362d08a876742.png',
    id: '8778',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de0c14ebc38df46756cd5d4317a72bbbcfe94bab134807d4935ec4d884b55bd3.png',
    id: '8779',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5d52bc5b99d934c9f63c6a9b575ddffd862d956a80b73b78821d1df9258da3a.png',
    id: '8780',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14e7bb68c4fd820a82edf4cfd9a20453e55282a46dedb4a55e6c58dc40b548b1.png',
    id: '8781',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1a21df93b988510140824ecebc9ac4a6dc252147bd926a729121aa8cb7119331.png',
    id: '8782',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93fcd58116278f864487bb176d894999bb122dea0bb7bd4a26cce4f2a8b9d5fe.png',
    id: '8783',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/082d81fa4a3b43833c7c58474ba921d97e93d20eb49d05f5f66f2bc6d0df5f35.png',
    id: '8784',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81daa3b11998a650adef6c36cd1717adaef192bad32c92679239a28571cb0f55.png',
    id: '8785',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/72aff201438f7c248896024479ffd8ba3ce8e918ad21639fc42c89fc8674b2c8.png',
    id: '8786',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35f58dcee0e65a1ecd070d1ea275f6ed4a95d4c1ba120b779c8ec0f6b8ecc802.png',
    id: '8787',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/999aeb4d2b3ea30965d428ace308eba8277fd033cd4c77794b877189ee364c37.png',
    id: '8788',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ed04151bf660e1a1481f1b7443e1d7bd6d2cf08535983c9de2b6b80e7e00f4c.png',
    id: '8789',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b26a814c2652c0f050cf47f9931a5a0ba54afdf3bb198a93d96b6d12ffcc476.png',
    id: '8790',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/162a89e0ccdbe42d63464e1700251fe77c1b58a82bb08bca99849655b2007f0b.png',
    id: '8791',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/635859081bdb6b8932389b57825812494290b672f4ddbffa5c370582100ab450.png',
    id: '8792',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d0027f4136449a2b8040db0994f4afe76689ab0cad1e58bf2e62fa568af0760.png',
    id: '8793',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/156bc87a26c96556895f8f4e436176ac9029ae0caab5816f603751e3abe57404.png',
    id: '8794',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31da0df58d7eced15c530c9610c39ed8ada2679f59f2098b004f6a9c77c00698.png',
    id: '8795',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ba1901cb1e1478357937bffaacba18630ad79946dd0b6e80dda7ed187ac8088.png',
    id: '8796',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b6e5dbd063b304b921e6c75d7dbafbf9b0b1b1a33640029d30e9df5a94b427a.png',
    id: '8797',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de7457217394f05833dcc773279dc4647d0896be011d9f4214b073962618f5ed.png',
    id: '8798',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99ef4b3a5657ef98e80b1da4720a0d399f0734a95239838dc6a1fe68be409595.png',
    id: '8799',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af7d2734de5827b6825b4f07daa583e9653c90f516c3bdcbb16ac68e08f3f0de.png',
    id: '8800',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a27bd7b7f005c45d276377d3ccff589cb1b087997994f536d511cc0a7dbee84.png',
    id: '8801',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/610c218803e674e03713f71ef98456f9b8a5c979cc3b9e9e0619c1b168c7c695.png',
    id: '8802',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05c2345ea39ea31443c841d2bfff6e499413f245538af435529d99779725a499.png',
    id: '8803',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9dc8d6ffdb5c67d777608ce2943676bfe0bb13aa203512f7629da55c1a0a11e9.png',
    id: '8804',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f111c36d0061c64b8a161c423de67c626f2e37e00c73a2a41b6ba2c9298887a8.png',
    id: '8805',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d67440e70b0c3960e97d55b265ac382dbbd2c0747d81fda18ac8a6d231d460cf.png',
    id: '8806',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63c8b4d6090149a732d69137ab5471d20cf2f48ce821657a89553fe411bea9d3.png',
    id: '8807',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07ae97be6ad42eb6b6dca1d23db75a25be519ab5887d1887cf1edfdb64bdb77c.png',
    id: '8808',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b228812e5ce134d40c32bed56ab7f8082a20b816cfdf4b940a8ce25a9e3031b5.png',
    id: '8809',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca97e5a77a1001f0176cd81a5ae0184a5339f63fef8e724e10e05efbd5da29a1.png',
    id: '8810',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fb94b55d15db20e33a2e00f2d4710e47994553878b9840605559dd2b9a3df0d9.png',
    id: '8811',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8feb2cebabf1e610b438b1bc78422b7bce3002e30e78cdaa35941c72d74659b5.png',
    id: '8812',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28a3f0647344f7a4dba8d35dc61910e8e3e2a86b696d14d294300b3389f4eca0.png',
    id: '8813',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5729b775729fef93ac5c7be75aae2d4166c6c896f2b2793890e92423ffe4ec8.png',
    id: '8814',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03807686305a6828351f36b8d197ec9115cea8052c637ada04cf8b5d72bc8f42.png',
    id: '8815',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d492b7b4f577599f4c51aedfdffa4dc2d78a5051d1f48af5f1b6c78eaccfa95d.png',
    id: '8816',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/811b86a5f1fc7d63b5dbf22e6de933de6d338caf6a1e759921503d32622fbbaa.png',
    id: '8817',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b8ac5e328a4f4ae2d6cec9ea3ee2d4e6fa20312c6aba20fed967dd68fc6cdc1.png',
    id: '8818',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bee1d9b0f78305ddb446fb4c0c4a5ecdeccba53f25b46142d03e9ea8622f9c9b.png',
    id: '8819',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/250299066ae8587a37acf8a5c75fdd464c64a752657b8b700dbe1ce69a5e88d6.png',
    id: '8820',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1282b365b7ddcea8928f1bf245d1af5a5837ffb06c6ffdee5e1aaa70f6bbc1a2.png',
    id: '8821',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cdb8c4e2d231d3a27d2c93d305d0aa90258882d484160b497f6c05abf608b059.png',
    id: '8822',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4777b4c2835224ef8734ce441ab4f439a791e7416271b52784661d125faade4.png',
    id: '8823',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a5436a39111e0e1bc5371daeb216ac5e441fb6c0096343d3c74f1ea3d563dd7.png',
    id: '8824',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f4a5f41833ebdfee11442a80283c310e74063f137dcaa36473a96bec18fabde.png',
    id: '8825',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d67f655d15e60327bb8ed0c0eaaf844fe7911b7c3a589ae020a5ab4ac4a2395.png',
    id: '8826',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a42eb5e77ee4a54976ff479fb79d8ee2d18ea13bf87b65041eefb33a6d0f3a6.png',
    id: '8827',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36f8af0d6fd7685fa1e42182f9ee8b615d07c662f5fd06068c82601300c40677.png',
    id: '8828',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc8ee9a199932eb27048af044ff4399376f7c224f9a88bade96179ec8d76e7eb.png',
    id: '8829',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e248791c9f19de68d982c07808da429c49dff2be7022109e44c1b528ea135cd3.png',
    id: '8830',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e4e162c48e83f38b8cc8ec052f9e4b479e63849583f8f5eed91b6077d505d44.png',
    id: '8831',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86b2d7724fb13590474d93519ca107a06078bb1b11c9eb9c60be1f854b75d6b1.png',
    id: '8832',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c596950e59b81f7105f81d5f3ab0f79c731b60f3c7858b5869f6c424b7f595b.png',
    id: '8833',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70dba27a18af50341aa00a9f48129b6e0b216b06c8dc1b82bd2df1394bcf9194.png',
    id: '8834',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b3e1b7d1e3294cb67d7e16eca6c5fa1084c68edf23d4ed11630ca3983f99587.png',
    id: '8835',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/50ac63db8fc10ca4f94c341e6f7d996314f38c00a8e8a08c503ab0e0316ea236.png',
    id: '8836',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d73d8bfdfad3511c44954e8d99d790d385d5ceea0451d743e67211d5ea726968.png',
    id: '8837',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d80d2bfea10ae80c45e4708df26d06e871c66546ecdfe247e07256240f41c53f.png',
    id: '8838',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Wood Ear Chopper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60c79eda43f4131d7edaed345d36e9a0aa173edc52d0bd0ffb647b974288d633.png',
    id: '8839',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d36f65a8c1595ebf016de1c2e8d6a8eaf8ab224e9cda5b8a7b01acfd3269a759.png',
    id: '8840',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c0d9968b1eea2df27f89096e15c5f21931e556a8e3d48860b945c38c3f5614f.png',
    id: '8841',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78259beb8d87493c3a39226f4fb30a92e523ca884d5336cf5f19bd60fd530cd0.png',
    id: '8842',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4141029f4f44f33e5bed23541c25ae37927d7d5a844d88782ef96cd5cbdd478d.png',
    id: '8843',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5bd5091b86f22a59e26eb74b43f59c7ece8b94610b92de668d20077820eab02.png',
    id: '8844',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b10bcd24cebfd1483a56682b488fe2799c3ed4e324dd4086267f9fb21fd9ade9.png',
    id: '8845',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1aa191e6a7542b7b5e030797499dd2f4baf371faf0f499ab4417eb4f19dd7cd6.png',
    id: '8846',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0aae9ba7f70c55daa806c21e0e6143d94a7e291aae9e035a165d89fcc0f952da.png',
    id: '8847',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b674e4b101e66fb9b73ad0e8bfd938b19da3381dc3f1df1444665ae65911e93a.png',
    id: '8848',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1566ab8af2e49be7135c00193edca262ecda605bc16e468551146c1aa8c8f5c.png',
    id: '8849',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2db85904ba88a4feab353b0fc458f1d3757902678ae05b50d6c092f2da89eef5.png',
    id: '8850',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c387af28ba07835746a6beba1bdc3bdcd10c3f8c97b3c54ed5b487986f692d31.png',
    id: '8851',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4e90a2ae414d1c18d3dd89375e7c607b497808f2a58ffd626e499ddc224beca.png',
    id: '8852',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6ef6779d1277c8fdb8a2d9631c37a74fbb84661a51e9679146138cf3899569e.png',
    id: '8853',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08963563420c6db2191a97f516b9018e388f56e1ceea9ff41293de73accaf265.png',
    id: '8854',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/facaaa22bd5fdad34459e891f4bf3494462af82ede910deafe964587f13ad433.png',
    id: '8855',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95b82894741b3a141181228e06cc669b7a0ac1a587ebc63251a9f2f4f6d9a46e.png',
    id: '8856',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2fa4a21c447d0524c1cb1bdff277118ce078c4ac1df655efff6f0c76cebde9e2.png',
    id: '8857',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4cb613fe2413ec94b0ff4075234020397706cc7041529f5ad4e7f21b250780b.png',
    id: '8858',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec363c35f3744316158ef09b33735d7f124dcd5e02dc860b1aebe141754f6d87.png',
    id: '8859',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/93bdee3b46d388466fb085ab13a46a433200e000c9a443c4df4ddb884ac96a2c.png',
    id: '8860',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d70f4b1fa1c9f9a71e2ee32f42e9a81ea54e8025e409d9a36732ee3501897e02.png',
    id: '8861',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67f614668b353202908ac2775502130b861ff077a6b29827deeb68f6cd9e43be.png',
    id: '8862',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1cd16822846bb0ccb4fb2ffff37efd5509b009ea9ad5f342c92dad209062e921.png',
    id: '8863',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2243c4d038479deaefb4db586039a3d358534545f482722389ebbd899957093.png',
    id: '8864',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df5227d2b89af3f63da61a7952131e48ee9c1f6fc0df51c7a8fc1d6384767edb.png',
    id: '8865',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1bee133eeaefc6b0c4da938bb986f66e5b4d0a6eb601ec78fc3430bcd1db48b4.png',
    id: '8866',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/507e84a51ba3df2d8b70c863d84b0d6175527f0d295c3054874014f4eb44e969.png',
    id: '8867',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a6e718e78e8efd1e31c96beb370aa434189172c36ff3171d219e05ff7b15d957.png',
    id: '8868',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08455431ca8734f5b5ba7408226282104410c723e34bfd1c6a3c9502c9da4ce4.png',
    id: '8869',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48be1d6815fba828bea5572c99a6916a45de24b38c5e68be35495869e6ce0a12.png',
    id: '8870',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/363d1d818084de728fbe3e9189dc39ec200f9b29475b1a4603dc89d0e1a299e8.png',
    id: '8871',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8dba19dd044db7550810b40636283226b81a511b33fe12117b5527284def85c.png',
    id: '8872',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a50f3f7e2d5183024de8928d91c730bd78d68f82c0ddd012144212e6b7c6687.png',
    id: '8873',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60de7e2fd3d736b1d97e8d4a3d37c5d6090cca1ed5a3609c24a7e6a23355f846.png',
    id: '8874',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67d3f76e6a9050c358912287edb0f8ed85efa299f2e331e61efc1dc8efec59ae.png',
    id: '8875',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6917b45fe4f6a19d198cdee082fe53464cbbd9ea0dc7b0863ade6c4442704e1e.png',
    id: '8876',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/055a7e8fc1c388cd824aa8fee34eb8ca01235a6803e002e2bc3904a1a54cab83.png',
    id: '8877',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f294f8023271edfbfba8edd70f04be7504b0caee0ef7061bf2e757e4c8d09f7.png',
    id: '8878',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Cactivorpal',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/963d4bf657f3b7392bc009ec2ab52960c7f91007d3624fd9284ce44845abbe52.png',
    id: '8879',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d46f171f834d2ef071d1b1e82800af07b518a9c9741ff960ba2cdb7735f17763.png',
    id: '8880',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5abc1907635c200d1fd416ae86f97e9453f880848d4ce5246020421bb8f93a69.png',
    id: '8881',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecc6cc95b498417e9275c7663f6561d053d5385c12ffd4f2ae17a789c63afda3.png',
    id: '8882',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/44e52ba497894f3227488f7be817234431ba4208c00f5eae193e42c77cd580c3.png',
    id: '8883',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6012c0c1ddb3a20ee7b6c0dd507556af746bf2bda243f477a5335501f9daa6f.png',
    id: '8884',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2467cbd2cea29c3affb4c49708b36ce52298f38a1a4c7f556cda54ff5433c3f.png',
    id: '8885',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0695eced9aabada19a0b3f2d0ef193db5954d3fde856bc9535c904049b488c78.png',
    id: '8886',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59e1ea1255dc4fc866a1d6693be3a48c6a8d2e2a901d527ab178725a1f944b6a.png',
    id: '8887',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/803830da3afaa27e04b711b9ab2c82dc908741659fb022bfbb0670f89d9a9f6c.png',
    id: '8888',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47d9e7540ae8d1c97e47c47e56b4a97227a0e5564a6b579b0e6f2aa3d8c31947.png',
    id: '8889',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af9663d7ee8306f0e3c54c4111a2ddb2785635c86f137c2554785d9a36164be8.png',
    id: '8890',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c133d28cf39a8929cc716cf6547bc3e91b1d93e16c224aa6921aee8f46ec509.png',
    id: '8891',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e68ac0f40f2373441f42b4cfee5f7d9f9065525230e2ddaa8b3a2de310591af.png',
    id: '8892',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b0a5745bbd27f1c6747462272564430a2cff839d0341344ac6ad25297f0fbd98.png',
    id: '8893',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8871597a95dceaaaac0cbfcd0fb82e40227a23c83312bda512691a2991dfca16.png',
    id: '8894',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e464ad935248353a1b866cb5dcd040945d9b3f4914eab6ece1b5d9e672325f2d.png',
    id: '8895',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/553c4e68eaec0e4d96074b5e79f943bd5fde0cd5c09731cfd23aa72f71c8e0d9.png',
    id: '8896',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2966b60e08609806e5759d9e4af500c1e7d4f10f6747bf200eac0be54aae517.png',
    id: '8897',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/068659180d77b601ed1cb02472039428a0d9aa3a19fb76dde2c03498dc40320d.png',
    id: '8898',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc14f1b64db97c11d24102384b755093a81e9624feb13cec03e3d7583f7d7182.png',
    id: '8899',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45648c0f9f7acb9dfb0ba1a76856db3aacba342ab98707d94b8705326f00ad26.png',
    id: '8900',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/993e28eb0c043cd61ad2b06910b826ecbafb4bd2ba9acbb2568fe69ea19b2d0a.png',
    id: '8901',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4577461baa805a940b5ad0f29539aa1aba0df71f71bf583867b8ce05c059000.png',
    id: '8902',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0fb81b210d261fd16bb03f0b7e076c799973b5dfc79093a350617e0175a2b797.png',
    id: '8903',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1984085a6ff4cc861550689ab8a45ea54f87cda1b9bbaaa2358c3abc0e20417d.png',
    id: '8904',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f016a4a9ae8094f6ca78f5b8952941e4f6982f0f257aa9dd4b0a07e1866b522.png',
    id: '8905',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b32120783eb139a790fc8f60f813657e56f448ac6b4ab997972858d6b225d51d.png',
    id: '8906',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1c570ff8f94bcad8ca9a497a7fe3ee36a76db9041b040ae81b8b6a0b0fa02e5.png',
    id: '8907',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/765c168da634adfb55812e58d88341d83786f644f1b1b304d410457f7a24c540.png',
    id: '8908',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b07905f671d305aa438985fef85d23c49cca245f806180d997a7b32670858089.png',
    id: '8909',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e432a1547522cca81879395515a1ffadbfbf92dbe8a96869c4a7a49356486508.png',
    id: '8910',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45e31d7cc4b57787ab6436d22005a8783d961573df7cdb0ed3ab7fa738b00644.png',
    id: '8911',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0b9748f05bab71003e26bd65cdf0acba725b4b1aa87a95d9a651e7a67514ba8.png',
    id: '8912',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8f3d47fe75b88d71ace01ef4ba30a57713b1e2caf53ef35a4824d76aab67f57.png',
    id: '8913',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2054452cdb22a2ada759db8a612c5fb32e3dc07854488efc20d969b2e6e047f9.png',
    id: '8914',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1e32090511f8e3fe4b5587b10758bc199e0af6e867ca5e6cc5a64b5d68cbc34b.png',
    id: '8915',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f40cadf63491422c354396399707e289135eb697e0537e40d70616b97f45b31.png',
    id: '8916',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d03f58d22bdec327f4d212f83fdbf839fac7cb486524af24a4ab6bf81fc9aed1.png',
    id: '8917',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a95eb419c42ea0d868dcaa6fb66ca23c6c66f6c9e4058b3c2178f249d5791ce.png',
    id: '8918',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3dea608fa502b1cb0f849cc879b642d7fc754187ccc0c19b665ee7fd85547f7f.png',
    id: '8919',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09d1d01f5744b43ed81db7994e9c2a92ce5fbd3187beb16b4ab6e025a025c23a.png',
    id: '8920',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/085a66b2ee181a009af755bfc696ccc0bd6ba6cb9175f1c4498e7e3b77c0ed75.png',
    id: '8921',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f095dccd947316bbd82f0fe793d5ea104478aa7d1cd806c93893d5679bf027b.png',
    id: '8922',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f5b13e9a8e1ebdc90b0c74536a793f4004d9b516b3c4b3bdfaf8ef5455e7505.png',
    id: '8923',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/60761aebe71d757aa2cbf75ad3ffe1de5439b26b58ddad194d2c41d715aaa4bd.png',
    id: '8924',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86c2da81cb0a09193a8c57bd6f7e070fe7bd64a4186d3f08679339d8989d50a0.png',
    id: '8925',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ce6b8f725792f5c93130333aeb4d5ddd5eea278a10007e6c18d4f8d281fcb47.png',
    id: '8926',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6dd130c6a10dd8802ac1f73d0565f040bfb3167b090aaf36aa4f1983bd3b9a8.png',
    id: '8927',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27c23b8b7565b34c3377d56a5e5200911633609b804ccf50e4709153a23b851c.png',
    id: '8928',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5aba2abcd8dd4f5469d295cc263ddf640cf9cefb890e2db289a2a1881f6fb8b6.png',
    id: '8929',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Scythe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6de88339b8bdae98061fd4afbd1f7a821774fbca90c5f58ed76a26a415b7f33.png',
    id: '8930',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ecc52ca115197319a2b3f6100ad7a867ebdc7fa5617ba51899dec8bb3bc1045.png',
    id: '8931',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48b02848a0bdf157660535b592d53ab599b8ea66b4dcdca16ce4ccbdc5eab153.png',
    id: '8932',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0352e02f16991c62a6b7c4cf7e1e76919aa5e7243d9108e2490aab60e45f6a88.png',
    id: '8933',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1598f94b5768f43ab3b257b077d018057f2dd4b839ba1a924c4b379d15885d70.png',
    id: '8934',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7201b2f370402ccc835ae6262cc07bc493ded112a119cf8a053006ddf3c7571.png',
    id: '8935',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7b4a0d41f31ce0f4164346a425a4d8269b20192aa2e30bfce9ba72ebb5897d3.png',
    id: '8936',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b12ae833d4173cf8d614e6225a5b4a3774365356de8f6f145d1595c8ea48ff42.png',
    id: '8937',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7111073640aa45b97aa514f2ed582cdd0b42a8003f818e34fa336ae5819f490e.png',
    id: '8938',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0234a83cf6fc6f1b86bbbd92816b7cd5f71a7798bf7511563f23c94175d4eae6.png',
    id: '8939',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3642c928e8283ce8edd5adbf1f02b5aed14aed38dfa5fdef805c0a24c0c26b01.png',
    id: '8940',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7157a3ef313d9bca2f7defc7ddcac70794a27a571bec7bbfbbaedf5881f92fc1.png',
    id: '8941',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01bc230be26e77f99ccb37a141929890eff894ad676d80486dfc5e8cf6c524b2.png',
    id: '8942',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bac91a832a4a7e45b76981fcf61f245d185694979238aadd0433601ab2566bde.png',
    id: '8943',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aea7f7db883c1a639d383452cc6be610130dd18cbdb8a729b046b62bd792260c.png',
    id: '8944',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ebb2a3476fe1e8f472df9656094795df02f833112f36a71dac2e789a44e6c059.png',
    id: '8945',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00d885114e7ff1eb1a1da0c0e3eb5749c2fa07726bb4ee832bb59e132395ab26.png',
    id: '8946',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f011c64384b7d9089305b0e111fe8c5e13d0c463544d07c50c806f4c9b9d1134.png',
    id: '8947',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2a5b334870ce589b97b6bc6bb13a20dfbdc1c0f609731d385000090bbe9e3b3.png',
    id: '8948',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de60b2d74aa4ef955a048e8fdd1a1a9ac004275ae7bf235a45c292dcc642b796.png',
    id: '8949',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90f53f048374e4bc02a7b98ff1a350a75dc08470c7dc2c3d39849c172ce21574.png',
    id: '8950',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/165d7a1735d42e5e93bf9bee2838174c6d0d2c8cbdee97036d2c8976b0f9d492.png',
    id: '8951',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0d16df48be9e4ce290da6916d42f514bdcd66e019b99bfd6b4175ed14f74a63.png',
    id: '8952',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Synthesis Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4e213f186786f93251fd5f3754959ec90e1820c39af7f7f24de486c7151a104.png',
    id: '8953',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/690f775f7c366133d078a260690bd9ba6c70eef40aa429fee12b58ce803e8145.png',
    id: '8954',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/057c53a6719b91ae83673ec085577e261d42061a946c1caad08334f79c95f936.png',
    id: '8955',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Five Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a782be6fd758522bc3c208044ed23f10c539585aabb76e8f5a561daca874491.png',
    id: '8956',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58a57a2de81d34a6ea7b1e656b969bea41c9960154fbf2b49495d07be0af3a88.png',
    id: '8957',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2211f8b379a590cf9162bca0f7db096b4c0754bfe493de5ce145c6c5cdbb352c.png',
    id: '8958',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8e4a0f1d4c4c9dee9a244110faba899d7c76323a29a12dbdee976cc3adfc1d3.png',
    id: '8959',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d84bce7df3432f48d129f48eb8b9142378d5bd43ef83dcd5e6fd7ee53aa2b2bd.png',
    id: '8960',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a7e57ba8f661d17a2f3879f3432a051f4cd4e14e6051e406bff2faf228ce6533.png',
    id: '8961',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d11d1f5229af8c914f712fc8e9f5b21e59bf12c278d21c87081b54cfdaaa8ef7.png',
    id: '8962',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8aa710ecc5d083afbee9ab97a0eca490cac745ec20e48407b1b5ff68d493b923.png',
    id: '8963',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4127dae1aba8f6f7863a67cc425114a669421465f6ed8a345136858176a2e8c.png',
    id: '8964',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fa40d84872772e97bb9bb710eeeb7d61339c4f50cda0a1636c357f9f86f6c90.png',
    id: '8965',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1499fe4833131e65823982a92909193ba560428314a15ab871ccb6048628bfe3.png',
    id: '8966',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ae0050abade383945c8c61f4bc04acabc4815fd16cbbf1d5d75b25a47c3ecc6.png',
    id: '8967',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cf09be2b45355d7e0494e49e228e906100845d0112e43c1f2fc8c33794a94bf.png',
    id: '8968',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0724c5e6a96d62c6d76cf1733ceea964616ee144a3fd5f8b3f04ed975bf1320d.png',
    id: '8969',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/151b7220da6aca680db29dbc59b4e892d80a45e40216aae9689c1e40c9502b23.png',
    id: '8970',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4242a898e84b6cec4e26aed0f64baf783df9f92400be3033154e885c248d7ca.png',
    id: '8971',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed3e0626341496a89859629caee4d96282fdd94a16c57dbc691f45a245703b5c.png',
    id: '8972',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48de8a021c25d4b7408bc13eb02e3c1c5e5b4ff2b8d62bd13cce5dc0dc8c2462.png',
    id: '8973',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfb1d168e467beab1c23cf568d98181ca1adcd15b8a487d911ea48883394b938.png',
    id: '8974',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39545eec65981d6457aebeb18cea980e4d46d0fb4313453961f7f98cb54c1c81.png',
    id: '8975',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c097b572a11b1d2c9931f6cf3dff107b41a582a58ce3153d6fce86e7b6c61c95.png',
    id: '8976',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6fcdcdc8b0a19e69e6d924a6dc5b3e0dd76c4f8e1797c1f4530dfa5188f3d86b.png',
    id: '8977',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1dc743ccf15b295bf159a7665f65849a503bf1e8a3d904fb08d2fa0d17214e3.png',
    id: '8978',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47f233924b4c0fb4704a27ba88e4719de20b4c68ec5c151e656880f5c7d3acc2.png',
    id: '8979',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af4e30d54df8153bc960ddca0617b15f9d6818aab0724b6eb8153f4704c0da41.png',
    id: '8980',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1f9ca27947acf2f49db97e0d6e0090b7d30efdbb237a1f377fe0ed659cfec2ba.png',
    id: '8981',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/566947dfef57b8aa4725fcf85db09797effc75df4fc15a714cf11308cbc37e9e.png',
    id: '8982',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08cbd52bb6bb9e7acbf95c9b14a5c0eff9533516f141cc8d14e9d54739dd556b.png',
    id: '8983',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d0975ee6a21b7612a071311855c6bed2afc29fbd637f9ef819c2990e2601573.png',
    id: '8984',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/028c1cce362bd7e006c1d6bc0c095ea1771c21c95ac629dac4c433e207cf423d.png',
    id: '8985',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9796f82658d2ddf2b7303ad24ecc518c1dcbe7859f25c78fad2e497f2f59926d.png',
    id: '8986',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/329a9b2b9815536e88389217a7894339b9b4315ee792eca0de707028df9a1ec8.png',
    id: '8987',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c0d237803c68917f4e4dd7f524297c4ac1057a8dfba44a0325b8e50e6210574.png',
    id: '8988',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/645d0d7f40cf8036d0e3ad03ff986d213186981993426f03cb54656fbe52dcf5.png',
    id: '8989',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c553b8b9f33112a09edb2436d123bb6807d95a457cc16516638a1adfe03ef13.png',
    id: '8990',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f0671d6203fbfabc13b628f5e992bfd34e8623103ebb9893dd9a83cebc60bd5.png',
    id: '8991',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c592e903757fa6f8700758c75b7e40c22d16a13d6b3af3d5945a100db1a4109d.png',
    id: '8992',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ac1535cecba0a9e3d6b75b14cc3ebd9a36da2e16c57601309a429883b3fc17b.png',
    id: '8993',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eea1bdf2f0ed6c9df71f8ee7ca5e2904bce3fd6cd3fa96ddfb5d3a46ac6bd08c.png',
    id: '8994',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5b555b99b386e5ecdfd91078999a59b25ba99b9eaaeccd05913456f40cc7847.png',
    id: '8995',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38c4b1d8773b4616cda9994db0023b185fd1859b38d4a786cc451b202ff2278e.png',
    id: '8996',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/17f6da53681cd2364fcc9fab65dd31fb90ffb3e52db17c64fe11cbb296b142ab.png',
    id: '8997',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b78c9b0f4af25245311a0c6eae7aeb6fa0269ebac590d0b9d1f5b04e72dd6c0.png',
    id: '8998',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4efc91700291c50bfd4512b77e8a41dea2a160fe4ca847e004d3b6018c1b250.png',
    id: '8999',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c1f31ac44be75c48c447e6cf4edffb1a178e0c97612951f8a233e0306f955c0.png',
    id: '9000',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df56bb896800f60c748842472e8cc10ec124a695dbb1e1eeabde42c546809c8d.png',
    id: '9001',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e82ddb1f941c1b757ca44f5233b956e159d1a6be9c92315d3e2f71f26a22c8a1.png',
    id: '9002',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3678638eae3a6404230673329db9f99fa819ed1865885d152af7286c9dfd2efc.png',
    id: '9003',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/240b79eeac1fb30dfe53d99178d1ba31b206ae65e95a2942bb4a6d98e3cc6ee0.png',
    id: '9004',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c427379d49c7bfe234d50cbcacfc90a0e413c51cf0a374926c859660be1d21f0.png',
    id: '9005',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4048a9a09069fd6b68440dbb47bfdd5b52379cec9b87334d3750e813d38e8bc.png',
    id: '9006',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7090e59e36ca0efdb183da9f10a38ae36c691b39284516411366bed076d7abc.png',
    id: '9007',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e36a0a4240b8ed2086a3e6746e9d16c34d9bd96bcd94f4fccfd40ccc266f3f1.png',
    id: '9008',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ccec9d68d0feb6060bad3fd30425f20ba6cba7c336802dfde9995ca26d6ed31.png',
    id: '9009',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9181907186b66ccb50ab44062f8f6be569b632af0d45ed8cb57ed7f062f5c6b2.png',
    id: '9010',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0710fbeef35f7564ccc7449539aa3acf1ff8dcb8608a98e63bd0bd713e7cd0d.png',
    id: '9011',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/788fdab26ac168c605f91d9c01e86d84f37ca0a16a6a60ec3e58cfe54354eb14.png',
    id: '9012',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/454da7e129fa549f2b1864854513e46cf297304103a5972c4c47e46b5489346b.png',
    id: '9013',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad02fb98bbb09c329f3fb58e07cdeeb91921d81587578b68bb6db42e657c037d.png',
    id: '9014',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5c2a3e94f83cb779d53f9a833a82bd30280ad538adc85f089b1bf068e7a99ea.png',
    id: '9015',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a35bbf54586f4c6548f9307f1f1e3692d6bf8ec7b2995eb55d3f0c41a01c4b78.png',
    id: '9016',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99fec950dc8f36c0376ffbecfe7a5265f5b2d7a44bb4ec855d250da5bab9e1c2.png',
    id: '9017',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f1fa4bbe18f8e66894015de369135bae9965a4cd25678167b926ce8f04b4bbb.png',
    id: '9018',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d184d25cb217e76c333e1793217cc85e0f2c3b51904f51f7c56dd49e39ae3da1.png',
    id: '9019',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9bcf203425cfa95aaa25a580b48129f41ffc8d402bdf9337262943b57c3f0632.png',
    id: '9020',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7da5adfbbd45579bf46aeae0f48c0dd121214822d2c1dcea375db5d66fb115e0.png',
    id: '9021',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6af7b5f5233e976d17547c40e6fe632d4da8602213885db44749dc493e4b9dda.png',
    id: '9022',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d65971b67759b7b771f382a9c3322dcf26b8df56208b6474e7da4eacdf3b279f.png',
    id: '9023',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2313f23362e65b22fed5c12bb98491834de8590b5ee3cd2ac070a2ed85512468.png',
    id: '9024',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/221bff145942f714190bc7dcd9757cf44393d1d00b71d10fc8b6c97de06f501a.png',
    id: '9025',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23bb4be0304a7cdca619fcb53bb4e084a773481f6dc65bd4c308c1812968aa9d.png',
    id: '9026',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f704bc1a12541e8f7999eb6abe8fbd8061290317b4f52595392b485cf3d2b2f.png',
    id: '9027',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecb805990af0ab5ca0695f39b6594506eed06f407de4207a02d9fdd4d7d3ce43.png',
    id: '9028',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b802fe4b4e8caa753c80afbac465f2b0b0ed842e94a97652fdbf16b08cac2ed.png',
    id: '9029',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/56be993158800ffb35a131b2a5a2018c9552bc6c0331e3f1534e0025c895fe18.png',
    id: '9030',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Monster Ball',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cde14378c7507abb74e48d77a294be354da8bbb8a40e52a5fbac350b19016a1d.png',
    id: '9031',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a142fc45c553e5c54d65f10403e27c23362c624e571921e620f515bd2b97312.png',
    id: '9032',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94a69a3444fa5b76d6d1f6e156aced09102f74b77065c7e53724c804d6ab4869.png',
    id: '9033',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5cd2975a4088b5c4c6496b3614c43be6cd7bdd20f36ebbd550a41399b9d31cfc.png',
    id: '9034',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac093629951af4081d601e01dcb7a05d5515ea73c987a15ced5e206c55c4ebae.png',
    id: '9035',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18a5ec848dc5e6049d32da817ac9df44780f8094e9bf431ac3ab56382b040e7c.png',
    id: '9036',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc77dfce3d3922b3945bd4c78c36ac8e95dbe0b8601782e3b9d9cc56e8436512.png',
    id: '9037',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49732a33ef7bad987d5de1f0afd6e5821aaf925ae36611434d83c99df4e0eaa8.png',
    id: '9038',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d47b0e282a243502ca5cdacabd7e34e6db6aa7a7d807b7ab637f00fcbcadd367.png',
    id: '9039',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14dc02165e19445cbeec861e86d2e8613b28e29f3e565952e91bfaa929fad8ca.png',
    id: '9040',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7c3c64a37db6f2d26c0a2c7367d58afbe3c4bc23630209c5c213510eb3ad14d.png',
    id: '9041',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2aad8a59ad1c9fb5314c8320d06e48f42e3eb0de1977a10ffc55a93002e7b0a1.png',
    id: '9042',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/128ec8a1aa0dc9163e9fe254a74595108dff906a33c5d7e95048dbe80383f29b.png',
    id: '9043',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b4549d9cf0e11b95d0d0a41bea24e0dcbb6e1b56dd7681e103a0634db942e828.png',
    id: '9044',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74b644ae098fbaa883fd011798acca5fd69b369cb3711924effaf0e411145bc8.png',
    id: '9045',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sticky Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d390feae05cd24da4d6f87a9b53121059d6ae42b72f45594319054747d99f8f9.png',
    id: '9046',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9ca78b88b2077db92bbeca443ca84fc21c5cfcb270a4e02ed3c05b692728839.png',
    id: '9047',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'The Claw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4bb7e80ada826453899c42e966438598f3db2d259716aa42d175ad60f298b59.png',
    id: '9048',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31eb9d008af6a4b221c372f2cae17c40cacfd59ccf3e470fba0c93d316120341.png',
    id: '9049',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b502e0955d9be267d2f7eb32fc809d3fa0dd6da2d0aa63ef7e447dd520d59b9d.png',
    id: '9050',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a591440f62ed54e828cdee7f49b4f05e56a1cf530529662f17cebd44e0f764d9.png',
    id: '9051',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/299f116e8803baa313c62069ea62dd65e0453e5c6a9eafa363b44820ee260d1c.png',
    id: '9052',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4eb3b38b8e396a6b37c3f2f5616bd8b01575d9708e4fe7183e4a4bc662681030.png',
    id: '9053',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2363e01cf71bb3a868cac12beeab655e6b22c37ef59d4bac493979550d370972.png',
    id: '9054',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b303ce7edbed8a93a976a36e1b54364cd1ae05e948f1ad61d673f7cbe1ec868d.png',
    id: '9055',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c91c3b7452545a2d8caefa12cdfe1beece540ab752ba4a05940de8be8d6b0d6.png',
    id: '9056',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6fc49a515ea5ba284c926ce7fac47125afeb19a3e3e84971a0b8cc260e8c9974.png',
    id: '9057',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c14da84f01c6d48a3ee591e4066885e353dd8727700939a4b4b83adbb761dbb.png',
    id: '9058',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac37cf35cbbbce2329b4413ca49485f966918c687a04c736cff20b4a30eb2eb0.png',
    id: '9059',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05b12651522536a73ad2911d5e80f93d3fbac6c36f5d44517bb8c2c17134fd89.png',
    id: '9060',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbc5b94efbc9f24143825765fb32bb22817dc7a99d4d5c467c3ab8558f7fe3ea.png',
    id: '9061',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e6683ee51186d24ab894aa915c950ce30944cfe77ad8551260165777d304ac60.png',
    id: '9062',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c00a4ad42c3e6cde58b02f5a358f2b0aee3b89e40f3b991ef0017d1e132ba82.png',
    id: '9063',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/734dc733aef4d04b38f3ca8b8dcfc81c9c28ecbd0658e7f25bcd34504614f7a6.png',
    id: '9064',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18f0a5cff730d92f69ba5beca78432c607b0dde79fb3b0f7a18076642ea9fc7f.png',
    id: '9065',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77da9bc22be070b2d74462ab721d6b89c73a98e2d738acb82735adf545bfc728.png',
    id: '9066',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10293d84a7560729a16dfd3e7dd3cf6202657e9da2ff61d80d83df3415c281f5.png',
    id: '9067',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1d6b286fffdaeedd985ab940b882d20df359ec4d5d3442efe554da236c8c114.png',
    id: '9068',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d163e414f1d8ec3e5a8d54784a80146c7b42a6b9f15a5b11c52ac9510fdb0ea2.png',
    id: '9069',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e5130429631bbf4322b60c820bb5bdd291e9cfb5f8ef5c55fb4ce9c55592c5ed.png',
    id: '9070',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Guardians Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12745217d9fda4d21881108ae084efa0b622c65817e8b69737164d94c729118c.png',
    id: '9071',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a296cb77d5a9a4a0fe1a796611f879d7f63e8c3cf5fdef05c81b51289417227a.png',
    id: '9072',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6defe41d93a8814c48f86991ceeed91c49614ba6025013ead0a52d7fd427b631.png',
    id: '9073',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8c97420cad886b7a3569be0a815fc57023921a106c51f2b59cd6ffd280697180.png',
    id: '9074',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8adfee1ca462844a43742831b733c8c7805b77ea2fbe76826aa9297fd9388b6b.png',
    id: '9075',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f3e31a198fcf745f71082fbd9e2a17d2304b622b2411d43d1b01dc86119ac8e.png',
    id: '9076',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5656f576681052045eefe3e6bfe59d94a80381384774f5ab6e2343905c4c582.png',
    id: '9077',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cd9f1650298e4a458bd48af7d1191c15a9f37df09edd0c66f6500eb130163a8.png',
    id: '9078',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a4d681f2c9ca3ad99a86136f5c436d95a7ba1ba9d7743c870c4376799434af7.png',
    id: '9079',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a15c48ce522300f5467746ba08b572199fafe7767ee3003533676752a60d914.png',
    id: '9080',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Sticky Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/361d0a17c2d68673ef1a36e305045fc2c05ac2fd85cc83875f1e17db822469a6.png',
    id: '9081',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc86df81907830fd39ccb956e814b51b6efdebc0755291d2b7c964af26a3e9ad.png',
    id: '9082',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e22543b346ee1ab7013782719f2224149fb7192d9b9f5930a097070fd93a538.png',
    id: '9083',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d08e944324a497874e867ef9cbc63be5960df1bb07226710a615fcddf4ee1d96.png',
    id: '9084',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a86749213e5d875d9c265b9357450ed74be238c481436ca2bf1037f57ed63f8f.png',
    id: '9085',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Cactivorpal',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2282d06ad9f8f800256153de6ca42ced63d8cb3a52d567c62cc84194779300c8.png',
    id: '9086',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4e67031c3eb9cfd5f9f7a9f4d80d6753cc60c394d4f4e92c60f25cebdbf7b3ed.png',
    id: '9087',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dec30605d4bb4a92cab11457097a8720edbb9b97bdd1d5b4d78c157a864182e4.png',
    id: '9088',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/598dac511f4be53bf4b470fff15d8210b8e959cb97bb8fcbe1ff27ae9d56d4c9.png',
    id: '9089',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b91f89015b2a47e7ef29c42017fb7e89021cb08b3432ae9f53fd86d882eca13.png',
    id: '9090',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81a8a0ce4bd09fef5713ca8c7e86e8f006f5d37f632b7302f1c746a039c1b498.png',
    id: '9091',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0c2e2a7949af5179c0edc966aa1f5dc4e5fc60b7dba16b1f6dfdacc243bf750.png',
    id: '9092',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b612ab1209ef3824378348610970f89862453547e08d5d133d75bb1c6fb1f25.png',
    id: '9093',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbecf37f87da450d06ad50ce3ade76dba6a1ce3f617fb977c4875d639d68ec0f.png',
    id: '9094',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9468627a338b0800f5542accebaff22cf31e54356435de35c1a6d0e0d0ebd3d.png',
    id: '9095',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5734c94564ef9c279a72f40e575163432e221a4ae1eaa73ff033df3661830de.png',
    id: '9096',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7398c4cbe2c41b0b258038841f84ebebe9b16054530efcc98087fbc2f52975b.png',
    id: '9097',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff40dbf3b9c87623c7dd5a16e71da294222c0bc10855221f48844da7b0cf3e6c.png',
    id: '9098',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd28f1bf1adb4986b89117676712103ce936816d7479535b1f3434fb814d3994.png',
    id: '9099',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d05912930403d17c07b7337c3f6f91b51fd4820e72218dbfefc980d69cc821aa.png',
    id: '9100',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc6858bcfb7cea2480bf438d8c24c6d89aab3cca417dcb41e7a07a0d27d31908.png',
    id: '9101',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c063e2c63d84c8d31a34792e7dafb25a5bd977f86b7a6ac94307d63becfd2fd.png',
    id: '9102',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/748360ca3c309e1710b5c29c461c6bc00219bfd2a8025ea2fdd23bdedd102cf3.png',
    id: '9103',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/618644358a8e8f0bddabf26bad18afcfc67cf8fa4ff6e877f7195eb59ab63da6.png',
    id: '9104',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/157f6c0460a4224ad0c2ae9fdf9ad7d5d1813132b75809a77173ccf6968d4924.png',
    id: '9105',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28d9746859cd1cb139478a63269a6dcb32e96a16c50ef7314f9949479b61e06f.png',
    id: '9106',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Swordfish',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aee7be74bb845eb317f31991008fe4e9c7bfbf6e1a2ee9dd9060d76d20547353.png',
    id: '9107',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e2eed7beb9d49f75df6d2059183a8391a87bdbb7f7757a2b8304612195483cd.png',
    id: '9108',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8e2ffd6e2830af141bd056f24ddfe037d1bc8e81c7444d29389eb61eef8ebf7.png',
    id: '9109',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c990510993dafda58ecde5ae14659528fafd44d19596d5b26e045f66ee79d6a1.png',
    id: '9110',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Scythe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8713b643e30867e655ee6a62616a4fb693d86ce3e2d365242452700f0c6a0bd5.png',
    id: '9111',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fdd7a46ee7c6ea03ebd1509042c0114823f3ba367aa09b2901c55f63adafa85d.png',
    id: '9112',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a9dad6d361236a5d0d4000b06f5f3343d76fc5cef1866decb715bd2f0cdddf1a.png',
    id: '9113',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2203554b09b2929b76c0a18a146c00d866bbe1e2c4ce8062b283b1172ea8bf40.png',
    id: '9114',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8074f0c16262d3b9821b1bf93f8e2b199d6c948607c7bc56506ec68b193881e6.png',
    id: '9115',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/175a57686ecbdf098c85b573a58d8bdedc65b6049d287057515f71c9dd163be0.png',
    id: '9116',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10c46e29a89a7d0e71e1a79665586d9fab587dcfa56ba90194ef4831940f67e6.png',
    id: '9117',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48ce2d44f86b212e3c77de1dd3a01561a58761853f799a07b0f97a85ce113714.png',
    id: '9118',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e47cdc60c1381e24b1c0f936e162de4948b75a5c744eca51245d8b97b870536c.png',
    id: '9119',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bcbb62dacd229e3599949db865287ad000196b423d085045de45db7fdb100b3.png',
    id: '9120',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bfc2c87ca1b3023a2f0fc54664487bb26ceb9e6114fd2c216344da9916e46b7.png',
    id: '9121',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c563cbbc37be572959ce3f225cc5b7e00256649f16c8333d5082e9b384c1c11f.png',
    id: '9122',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d32515c3e4fa4aba530d47ba0772adf41aeb3cc7ca95982e3a93396345152cfa.png',
    id: '9123',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcc8030a4f56828c40c73c0d6cd2c2d2b80a9b9b62a45a2253064c652f31662f.png',
    id: '9124',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15f2dcf61d645a021ff02c7ad74e2c3123e43a8f3fc149a793c984b17cc3fa8b.png',
    id: '9125',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d5f5007370da424b47cb778a5683f570c61db933c331e2182b58d2fd03af378e.png',
    id: '9126',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48d423b821106d3438c04f357afea9412c344c9ec1d8fc4ac21511b13fe644d3.png',
    id: '9127',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eabc81515efb4e0276d2ee083a387852f9212bee7c75b4a4f1ff7ca24748a59f.png',
    id: '9128',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dc94c4b06b3aaeca050c074891033904036856cbf631fa8a60c4e6b3d8ad3947.png',
    id: '9129',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0bfb9ec28055b654e6ecee3387473db5636145b31ad473ef2f53466ecb71739.png',
    id: '9130',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5dbfb0e32b29548e9a8197dc21750c501eeca7b93984e2a2cdeee243b90387d5.png',
    id: '9131',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24cca6e553bf3af472389fe33b5f7bb0daeb408a71e6da568a545ed17f73c321.png',
    id: '9132',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8a71b7049190e0935aa4e9b5bda13989e1d970b31b38255cc439f5accd338140.png',
    id: '9133',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dbeb18e0b8afd37d1d181bcf573c18ff25287ede452ca61b915a2eb8f86793e3.png',
    id: '9134',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3e6b9c9f4199e4d23464e0811978e74608d5ae081774eb1d4cf892eada00039.png',
    id: '9135',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dc80f63f0a4c089ec61bb026b715159990e0660ebf5595150b728aef1afde3f.png',
    id: '9136',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Weapon',
        value: 'Bone Hook',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/931d13b42c580344e3285ea8177a0000195987d803524d4187bb3908c38d7a73.png',
    id: '9137',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Fickle Sickle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36155e1432759b56466d1f6377c67882d6cc78e98e433809bf5a52bf92a3ef43.png',
    id: '9138',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/714a4eb115f841bcdaddb4988d03760fda14828beb8cd225b1f1aab03573aa1c.png',
    id: '9139',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0c7b1ff29339b9e981afcd90f1228cbc6e6b58c515c5ec5af11f969dd41a777.png',
    id: '9140',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b03adfc6905e48eacdacf36ec71c8a7f70b69019172e73ad2c8c819a4471f854.png',
    id: '9141',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/838984358982f00c15e80cfe4b174d808ea3a33e6bd787296533108233da3b8f.png',
    id: '9142',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a3f2fb36d36eb67288332f725df36f5406ab39fba6cca3719c157d95492f592.png',
    id: '9143',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dcc3587cf04162e137b4107ff545237c0cc677a8d2a2c1c23407c4a23595b8b9.png',
    id: '9144',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81b7ede525dc388be5397226c2dbf6c22e1047f89f0be9ff1438ffec630a6c40.png',
    id: '9145',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2f2fb8dd513f348149bcb9a8fd29d38a288c63865cedec30c54645b12dfc2b4.png',
    id: '9146',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6e2f9bd2a3f4c46c469931b4edf64be10dc0da2469418cd061da2991c809142c.png',
    id: '9147',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de36301dad496c155c7efb07a8eca5359764cd579cf3b32f926b2a854f0fdfb6.png',
    id: '9148',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3b1fbf768017845da048069f15ccbf0a5c878f8b176e1d3dabcc9630e38f729.png',
    id: '9149',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/894b438b0dc48a76ec364189aef912a8217fd232fd7fb486a048091761453a9c.png',
    id: '9150',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b20d541f88eab6eb6c1cecd5159ed91a01c4a14e48805b8f2c9f20be25e056be.png',
    id: '9151',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c5067c873bac7a53474005e9364b6b60cd53d6cf89b14c6c445f906d9769933c.png',
    id: '9152',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a9c5d88efc4181783ce383681464f5c8cc76c67a201a3cb656016b147d889518.png',
    id: '9153',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gnaw Saw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a19c6e873bfbc9ea743f0b75bf2affb5306b0ac6fc134ae607437cfb16d3c997.png',
    id: '9154',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Barnacle Buddy',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/248bcd8681b387d590dff7f85563b8aa51f8d0e6910b332ebbd2472f07ace7ff.png',
    id: '9155',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ab64576a62823554477c358aed1cb51168bc7502722c8ed6b94eedb3bd19840.png',
    id: '9156',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/de41848bfb38b13fc8b17a1a17191f05169fc762e8bd9bd22c13989b7f18dddc.png',
    id: '9157',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c37e31fda4277002273465e6e972c3cffb11b54bfe31046057acecdb9c1f15f.png',
    id: '9158',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c3592c77f9856873301b7388d4fa6fa1271c199525533bec90f9908bfc43a12.png',
    id: '9159',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d09701a76981a9b63d4b7c18587039ec47026490a9cba062890f3328f27ea815.png',
    id: '9160',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a6297f45e56d078accde398b035b0dad5ab4c6c8f84cb9f7841ede598d55503.png',
    id: '9161',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dda0248f0ef89bcfdadacfac969a49a3e01bf461329faedf7561e8290ad4bbc7.png',
    id: '9162',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47c1af6c20136c345d0ac922faf2032502790bf2c17de1fd2ac68221c195fb1d.png',
    id: '9163',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42bfb8a5a0ea03b1171ff0b070a8f9e29b7da348a5034578a5478a179220a7c5.png',
    id: '9164',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d22ca3ce7667b40e76bfbf90e7dff31fa371b942d61801880d209a73349085ac.png',
    id: '9165',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/538e2dc4d19304835d2325f62fe2fe1c9247a03ee75dd1fdc9d7695dc458094f.png',
    id: '9166',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0f5f2b790a7149fc3525fbb970673901511fd29eb694e503b5b0963a01023a65.png',
    id: '9167',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f6b8f5a5490412bf9fdb0387ddfe2c5d69c4c750f0913295ee5541b6437f4045.png',
    id: '9168',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/542ec7c3bd18d4f474705bf242bb36b79ca877f3d3920a0e913ba344a6ac518d.png',
    id: '9169',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51a3575c54c4b5b961cd04a0ca523476ceaf914a2a2ce532971291a21412a735.png',
    id: '9170',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33305207195d2185707b53c34be2f4e755d3c3489f4e7d4e1c8a1d2edf443901.png',
    id: '9171',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Buttoned Up',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8fcddebe1eb868b1ad7996341ff13bc578cc181326f71b196e98cb976e38e6d.png',
    id: '9172',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c02e50c1749336e001425912e375630b60cc952356bfdae50a3a1399272c9f9e.png',
    id: '9173',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6795290e6ccee825abb12e2b2680db50a947b6162f40c2c779db770ec1b4568b.png',
    id: '9174',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed0cc06ee0b6769e5b48ef7d86c1cb2df0c4719dd6208dfab81ba5cc19e6a3f3.png',
    id: '9175',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06c4d5bd555a9fcac59469609b56cae3c1ba1e0cbf32f28822a0f1661e91aa77.png',
    id: '9176',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4499f5cea58c958f934c4aa0452b8101438b9e447747fd827e4ac725d62884f.png',
    id: '9177',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sticky Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba38a2111ac95378b6620ae948bac806f1b02bdf676411e94bb649a09650a3fa.png',
    id: '9178',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e263711b88572b3c918fb07b4497f0433c6b1dddf8d744c77a3ce687f0d4a660.png',
    id: '9179',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eba0716884805b44944c02ebca771f5ce6bceb2184a03ff016c75f3882b49972.png',
    id: '9180',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e92a37097a645254e28c85375df267997f691d38160de09ce3c4bf291c7c17a.png',
    id: '9181',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e22ca5ee9c0ab06320ad8a7bb5c2e587ca25b1c01e7385cbfd8266e8678087f.png',
    id: '9182',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b1b283a8efbbe0e2a24c31a5df0b9f503f9b268d1532db480f88df63badc9be1.png',
    id: '9183',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyes On You',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c86da0681af4e9ed518aeb80fb24aca8410fcfba566ac52decef1269458327a5.png',
    id: '9184',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Coral Clubber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d464753345378ccabcbe0ebef7084ff4907e85252842434eb1c74e0cfe1d1cbd.png',
    id: '9185',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c136f4b04d41ba32d801fe7bd7fbd3d7ef4448d2aec6899a02d48e77284a8c1.png',
    id: '9186',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Sticky Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d62c6393e25aebb5a443a81ae189eeadabe8b8bea4b3dfa2db251f130029c96.png',
    id: '9187',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f31d41b5671e511871779fea7a5f6333933e088037de00f2a045f5a44b5d77bd.png',
    id: '9188',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/960252b6bb7ba2a3cca545a0a3a75780586f86d9ef166a51e35274ec1db89f1c.png',
    id: '9189',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2f5b1649792770887f451cec7df0b463e39b87e24c38417e582b153a7995da78.png',
    id: '9190',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ec206008d8f2a393913fcd2df0eaf2562a4b607df5e993738b4e2f802b80790.png',
    id: '9191',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd0e39094da1521618cb3d9c068a3b1b864358a125cd613f87ec0941beebe2a9.png',
    id: '9192',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7617142542975d2a754a9ef30feb542d2a04a08a537d82f420d13ee9af27218.png',
    id: '9193',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/401cf2a435960be7d3cccfe9d568e7582bdca2f5838867d602f05556b0f484a6.png',
    id: '9194',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14d31ced75fb9409633f9ebda53502982cb9c550d16a4859f023ae632e914fec.png',
    id: '9195',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00b73c14fa6115e7279e6ab6e3b78fecac79da95fddba07bdd3cd9345ac6d560.png',
    id: '9196',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16c730b592b32d0ae61946ff947391a01c138279d73c5fa84415dd21c6b2273d.png',
    id: '9197',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6f287a86e575741e335bd84d48e96b6493da677452e87c84cfc0187eb7c73db.png',
    id: '9198',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/210b4362c1d496c5d1e711e0e24f8cbc74032c4fc2248abf9c75adc617084317.png',
    id: '9199',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c23c19e77c6d6ab16d1af0e0539f7f5a3e61c0b6fc4c3ca38f26e90360bded6.png',
    id: '9200',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d16ca42b9b578602728c972e3b022f627cded7d276b53fc735d88ee4ead44989.png',
    id: '9201',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/176313ae9443ccfe37ab8a001af3b21448bc6910e6258712431e82ed6d83f0d9.png',
    id: '9202',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59c0ecf47a79f0a6879090e9b2c62eb937c618d82770b5fc6106c1d1e660d903.png',
    id: '9203',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2807d1bba986f64a11596fccbcfe2155a537ceed2cc4efb24f4d4d1e8f17749.png',
    id: '9204',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04fe10c532cdd3876586d2b158c8ebefbb287ee24f833983aebbac55095b50d9.png',
    id: '9205',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a23523f72450d2e7e8b2b2ad00ee56ec687d06e4fa74ee883200f7976e85cbee.png',
    id: '9206',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22f3f8370770fc481a19003adaccade4b8e5f8533430c16ef324be5c4ae964cc.png',
    id: '9207',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3872abcce5b309e1a2d23d55f6c3337a0801763e4f22875390aa46fa0c51d4d9.png',
    id: '9208',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c62ff87cfd35218354b37bc026662562440a1f1d6b80d861897a9261b7be17f7.png',
    id: '9209',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5799f43087e51cbfa7b9d7bd5b108411df9dec3400495d9823d077564449676b.png',
    id: '9210',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b365f4bb425c4b8b142b2c6cf13dd230e63c345619d42dd601572cfb7f32600c.png',
    id: '9211',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2c9fb6afaa1871c81b7545b57795e688a2aedd2dadbcb2b4030db5b976583f4.png',
    id: '9212',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd6f868d8a0ed617f24ef1e55ee9d20368082f2d5308b7e25689ec3bf16a4324.png',
    id: '9213',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0aba300910d139e9a9146b5d3686d2450712e88868dd6cd764319e6c1117f654.png',
    id: '9214',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ab44ef985265f1bc4e28d5bd893ee5770465209a6c7909bce662caaec3ff999.png',
    id: '9215',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8afc39c728afe1941077f046040f278ac8798a1eef214d81ea9ccf46b400d57.png',
    id: '9216',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0adf7d62ae385963247e60eab6679a126b151647e7b242a126f82a8df516c8f7.png',
    id: '9217',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96e060e127c38aeb917d9c37b061e17dd2e8b91ee316130edad6f214c8192667.png',
    id: '9218',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/250353bb72ce860ee56cb6da08c8256d2aac46f8530437a2d3cf9d8e88fe85bd.png',
    id: '9219',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7ad2c4ae6def999bdc9d5944f06e125bece8c3eb4d0c876e63dfaa5bf65d661.png',
    id: '9220',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afd46de0ebceb6c8b879d053c942ca5ff1e3ebbe0a65e0695f729329d0725c79.png',
    id: '9221',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1df0f789ae9b72d7513fac7959b26654b65b78a95d8a9fb854004c2de0a3ff3.png',
    id: '9222',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cec5a037a75e364e45f80578198d43a30c60f85e7bec51bcce04db7392dad08d.png',
    id: '9223',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/83c4964f654c25b549effb9217ad580f76514410fe11f093bd03c34d71b14e68.png',
    id: '9224',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a166ca8369e9e3cab70d08d9afda1bbe23deb8aa8fe12429d29692bcd789001.png',
    id: '9225',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9b68d8b5585ab3848991a035c9b55fb718fdf60ab5343cc928ec7f01d3b4531.png',
    id: '9226',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be43e1ad9ef721535b67ec6572ceb051dc7424992f278a51e7bf85237e8fec67.png',
    id: '9227',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e574e775aa737f5c6eb9795602e46992276255bed9e84cf6c0c1998094a5b8df.png',
    id: '9228',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/429d0b5b17979609e4c71190569a5fa3b576c4a0c5ec5e7894853c240655ba2e.png',
    id: '9229',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/878cfad91e86869a33404376eafdc6ea290b39462fe346a07a66a604cf97db9e.png',
    id: '9230',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f56558c5cbc9041e78343c4e0229a0838ac437ded1fb700ebaf93a0fe4a0370e.png',
    id: '9231',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3a48dcb4828c80fbf905147e3644c78dfdb1cd53aa2235806868a9f49889a255.png',
    id: '9232',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36b56a007f754d5d9692fccdfe4d884a27852c0f0b4f786e970e9130c7ce64a3.png',
    id: '9233',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f9f702e20a351b8b91daaa7b21ff2a6e8584b495e6566bdbf4100331e3f127e.png',
    id: '9234',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5651e5c6995eae9469a967755e005ea6fb1317f3b456e90889c6ca93a5af5753.png',
    id: '9235',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e0dc84395db385bac53335cb744b9b8cc24034b6c4acdd6596aacb3dc311fd4a.png',
    id: '9236',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Weapon',
        value: 'The Tenderizer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15d50f6ea71514a3d30c19c3d04ebee027564d10c13b2c5859649b7f33f2d4dc.png',
    id: '9237',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eec5092d291e7db3eb359b2b8d7456db59190556ffbde546e452be043e9955b3.png',
    id: '9238',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crystal Stabber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/82349e2e38e39ea64de720e96258e3079d0158108303f422f732a3d5936089af.png',
    id: '9239',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22ab25b2bbe8905dbeb96a422f66d6ca46105aeffb43631f05a22a6d9ef8dc75.png',
    id: '9240',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee653176294768c738122b913d07a9aeb753c9617ab4b408f30e1f0254e9fd75.png',
    id: '9241',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1ed15087c9a8e35b958448bc0b6030e0a5c7f54eeffd8cbdd909c6cf1439f623.png',
    id: '9242',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/795a699f003136b7b3df4297a693918208a0eb48a4c044819f0caa4781ebb378.png',
    id: '9243',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8890ec9b5e46198e14aae836f8c45ff048c5ad700f61ca806f9c83392272faef.png',
    id: '9244',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a129684029603838cdf947996706e13a6c17970e1759d8895c8a1cb3cbf62bcd.png',
    id: '9245',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e0a01da0ec4467e0cfa280b4aa9b2997ffb4610e9e51e8b9324c54ed34a3890.png',
    id: '9246',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5bfd610224573bfa72777f857063e3c963ea8c19445b2e2a2015aaffd235db31.png',
    id: '9247',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a00209c0d75b63ec6a9056a0dff8c02def6e1017f7a0b9fcdb8175e4b5d15412.png',
    id: '9248',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3828b971d013e3505a581ff4a20323357d3f2a18dab07c8abd83229003685a10.png',
    id: '9249',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3e1ffd91c5990ebc50265f70e0dadac8161d52e1b307da33380833ed330e6b2.png',
    id: '9250',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6268fffc1c60f2dadf3974e4722e047d31f444a0dac9c41add75f7144b500a18.png',
    id: '9251',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddff18377fd7a1bcf88b1df9d0d76842ab6dea41d9019a4f0cc9f222c8eb8e3d.png',
    id: '9252',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c31937f3f66db65fa8d54409ce496ab20e8a302adc1a0f577f438e6905442449.png',
    id: '9253',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e122826361f3a5fbbd2693840fc276545b114bc6774bf69ccccb0d7b40f7625.png',
    id: '9254',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4f14a8a66d9aae997a03667d52535b7143c8ca74ad8920640a1f0bfc031f45ca.png',
    id: '9255',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29176aa0a6515e7d3547460c511e370c8d59e150a5588da9ea5a9c52d757a553.png',
    id: '9256',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/038c8b10828a0074382137b0c0074771bd713355d89cce25a4226cb98088dcc4.png',
    id: '9257',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c18d17f9292c8355032ed85abd37e588a7c4c4fa4351c83bcfbf5e3c5c2da5c6.png',
    id: '9258',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51ea5bf741141c9990f6e89234eac06cdd5d0ce7d03fb752edcdd2ef401ae380.png',
    id: '9259',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bb48043f6d52d6cce8abb27d461b00aa56ef09e6cfe0aa44754a80a9772b320.png',
    id: '9260',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8eb5ce4126bfb32de49eaddaf95e2af8b0d22e2b0860ebe98254fb60043b6fe1.png',
    id: '9261',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30438f1913a46fbfe0a0ea70e6be1e7ed19d933216ea24fda61c125ab4cf2d67.png',
    id: '9262',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/25f52f656f25597a474716c981a0343b403e44caa16b97cc9e9632d4523a4f37.png',
    id: '9263',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b486520cb82b63fd1476bcf557e806392fb23b4a8962480fc7fce0a3fa90f98.png',
    id: '9264',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c300e83424ed76a80e120a257cae8df6c8163e9892ee73d7bd7539a23b8b8f4.png',
    id: '9265',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/003d5f46852993b9963c4bb11b1f8a4577a63343c563263afd15b1ca6184a962.png',
    id: '9266',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c42b435735ab24d00d53b74922df8d3a05e158ec3ea2f30dbae39d228d0e53fb.png',
    id: '9267',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b904c05728f522020f52de77796fff69f499b87653416619dbeffea690e4c3e.png',
    id: '9268',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0d7242ba7f8883b972a4df6c2990fc4dddc4c7df313662710a60e923fcfdbd1.png',
    id: '9269',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11a23ae7591eb898d2280632216db66b4b29b68a0d29666c7e104ce53460bdb3.png',
    id: '9270',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2be4777bf0cf33851c904674c99087fbebc6681c3d91fa62eeb75bc0ae46d87.png',
    id: '9271',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9201577f13ce6538752bd150aedbc4bd0a1d9431163011b47fbe395738969fd.png',
    id: '9272',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9041d545ee47576071846ee55d701baf4939f27635bbdb8d792f32bbbea10c06.png',
    id: '9273',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0a1f0bc72da9449d849893bfa21420d1f84a1f5b1e101858a87d5ab7662d06a0.png',
    id: '9274',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a00d62d224cb7baa8bd169df8633ea0dc2c7c5b78a07b36264ae85fadeacb743.png',
    id: '9275',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Weapon',
        value: 'Gunk Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d502b37a716f94222f7c2e8e11e457ef6a5a367c92ddb774e29955d6bcd8a40e.png',
    id: '9276',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a682d7845875926b32b27d7f3336b9f6620d29f7cddbf0fa18c66c454a36d4d9.png',
    id: '9277',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a8f2cf5c07ee527bb225f50d6d876e2bdf0bd16223eef3d938be7276e09fbd0.png',
    id: '9278',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3de47630ac62a3e4e1c61830d612299759ed12b7aca9141fa5b5d52edec4f51c.png',
    id: '9279',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/250190bb3a0a502261670f4994a0ca6b4cb09d60a9102cd2371c3b36198eded1.png',
    id: '9280',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aac9e1791e548e09e49891b3f0b947f1dcdb1eb1e098fe486d6beae60d502521.png',
    id: '9281',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6197b09e011d5a4e09fbb4987eaf49346c93ad87f28ef772ede36c4ec076639f.png',
    id: '9282',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/54f64435297d32684307eee0fcbcff19b4f69bc4922dc3994c17e26dc2e25a01.png',
    id: '9283',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f27350681b1b80d4fe1134fd250373a03e54184dc85144e08db01d015952ae2.png',
    id: '9284',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4838d4a473489be2792cbbb874dc6a9b5e2ecff01aab2bad057811d9c0a1b3ef.png',
    id: '9285',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ed4eb440acc4611aa53bdc3d492780d448e0b4487151d23e7e8feccb95bf17b.png',
    id: '9286',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc107e60c932b2709382d0f94e3988050cae0eedfc712cc276f6fcb195f73aa2.png',
    id: '9287',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28989f95fc46ef406b2a76efe2dd08a0e718451968f6616de048b5adea9d46d9.png',
    id: '9288',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a580795a39f7ae09547f206f93f43ca946c0f34339f7d153fd224b23956692f.png',
    id: '9289',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6df3d54f1364227eb423fc3b62a674f0704bd0119e86b77d1c2adfbc14cc3c7c.png',
    id: '9290',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0cf94563679395137e4c5a05b258c062c5d97ba01305168725e93baaddf029bb.png',
    id: '9291',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Blade of Great Heights',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cbcc297487a7357a25325fbbd1218be96861d6806f365b3a8352919f1042792.png',
    id: '9292',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/36c7eba506b023afbe59b036be104948816f8ba735be5146dc5647edb6e03e30.png',
    id: '9293',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e083552957d9f2f1e9225d83551b6ef26fa056534f192463722025fdd8d2bd8.png',
    id: '9294',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2b4be04b331425c94b18450b4018d93de5072b804b7a3dff8632fc222cb8e0b2.png',
    id: '9295',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa81438dc5501b1ac3f90f525acd65548f21cb1375ef9d77284111b9fd7d3a00.png',
    id: '9296',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84daffc52d9fac13d93cb997f406b21b81885fa65b30657f6b694f0b752c8195.png',
    id: '9297',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67363fe29eeb7eedc9c4156b128f602d35131222b93cbf1720f05cbbd6094dbf.png',
    id: '9298',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4b3642c07b05c9af171319c171cd0ee014befdf6ebb949fd09c85941b68bd37.png',
    id: '9299',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d8b0b854cd05af75ade53ae335b079b869d24b27d091640327b50fda9eef36f.png',
    id: '9300',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a07c106f81bbc14470770a7e3c2af0e886da13e2ccb40d70ba46d02ec98c2603.png',
    id: '9301',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1649e91f6b3fb89ea4b39212f14a8644f9cc79584df37d0b216ea7ffa0d71970.png',
    id: '9302',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27f608688ea1e937c44a6db8d62a3c72a0cb991ba75e0fce61fec8eb5c146be8.png',
    id: '9303',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f58740c5e461c6ea1367d40c0141ad31ecaa49b1eb00a54ef34b6b1fdc26ef81.png',
    id: '9304',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Extraterrestrial',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5cdd7a2faa92e7cb7e9f997a1e7fd46d0eed194b49647fff224c40eadfdb16d7.png',
    id: '9305',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1954faa59e5fa2e744e51166e097287c9bcc35966f386c50631ff0254a813de.png',
    id: '9306',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b71330ba9aa8d7a2ab700363464f0dff137a5f5c1ec0b7f6eea1d666e8c95cf5.png',
    id: '9307',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14abaaf3ca87b847370dfb0f8e529f650ac8fd0e126a9c2b83885026e21f6527.png',
    id: '9308',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/605ce4dab7dcbcdfa53251db25c88e78a157d9320c24de74f0050016b007ea6f.png',
    id: '9309',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65344861b174c063e785216db8505076481d6c293e7537df91d82b0d9f7c307b.png',
    id: '9310',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3399a76f3c3e29975667c2437351e69661aa0efa71ef83cdd003b010dbe03f5c.png',
    id: '9311',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/50f61da124fcc77e483f90671a95064c016ac8149747cffbc0d60626f7d9ab85.png',
    id: '9312',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ab01b3411ec4c9dbc678979acb861301d9ce73685ddc3aad6f8a8975dc01969.png',
    id: '9313',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Open Wide',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Monster Ball',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4a0faf42ec8474a6faa6bb7b7a5656977d9b2418432200ad72a3f2de981d413.png',
    id: '9314',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31d9914d929c99220beb29596d64e68a18adea582888ae5b212b249b7f8c7f81.png',
    id: '9315',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9c07792e48f8502c5a7795c3a29b6f166641a3e9a7fbbecebe273551d2b7cc7.png',
    id: '9316',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3bd88aec240fc20e32dd24ae56aea2acec062f069520c56f94b3460d9f600d8.png',
    id: '9317',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dbcf3bdf072542805163acbbae0203704ec64759adbc5a03bc6e006d8c065d8.png',
    id: '9318',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4b6f0d71df00350d22759e17400a9ac1e84d4184eeeb2895dc2d7821084f89e.png',
    id: '9319',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cf941698e5a0b1837ad13f668e278f8becc08b7314d4b8e92f9259e9c7801a66.png',
    id: '9320',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7400282ae83ed719c7c5c548ee4190da88fc1e43c7d7503b5d82fe3ad4fcd46d.png',
    id: '9321',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74ac670e35af1bc36fc02e38f46f6abc751d48ecd56fb4043cfde99a344aac1e.png',
    id: '9322',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23e8c0ac805f21f6a47d0291b31cc0f9673eb03edaf399926db8d83e75d50f1a.png',
    id: '9323',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc2210bf1a55253ed471e88fe8d6eee282ef58e227176a5c966f463cfe0f13e7.png',
    id: '9324',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8a43afcd7d65431a2eb9b11ab9e3f8e155ae5e8e019a389e8ac8f82c146bd0c.png',
    id: '9325',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f3aa1ddb2792a6fb6d16b0fb4de44660a50f7eb88c6cd15231f0aeb24d5e77b3.png',
    id: '9326',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0c7473ca454877ea9382d2cfa59088948dd5dd442138b3f56402a95517103e7e.png',
    id: '9327',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cfb2d19e6994475058058fa368b89c63a256ae3dd992baa5cef5692a27dd3052.png',
    id: '9328',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acbe3bed62c8f9646fd4f1495152d66555f4bfe5f1f4a6eae7381832915ed5eb.png',
    id: '9329',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/526f4f0dc922e18b5129898019fe36c51bfd3e4d94d98db111335768a71e912a.png',
    id: '9330',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/78e909699c8527710578e80cda2876e7e0e477fe107e967db832bfaf73735ba7.png',
    id: '9331',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gnaw Saw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98f4b33756421d6f65c6db174a2807024ea352b33650c55bbd9fdd1d1f21c103.png',
    id: '9332',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc664c9c02f5a18c389268de2719238d06319bd4e075457d9b835ea7f9f2a1d7.png',
    id: '9333',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/95bbfaa88d7943de025ce4b48cb8ab49aac51b1e39a1edd6c13c4b61ec9f7ffe.png',
    id: '9334',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55e090a4afd510d56b37e3e7c6fbb1d36d5ea0fb8d3f4649f34f9d264b730ad3.png',
    id: '9335',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8f2a7324569065dd4c64c4f39f472dede9f8b5bb644ffb67f7c9798987fd691.png',
    id: '9336',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b9f6a3438d0595393fe2ee9704b773f02441199207591e9bda0441c77bfa164e.png',
    id: '9337',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Pink Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12740be9120b65abec897c7f02dd520e72f2e9d054a5828fffd6e6099c123ac4.png',
    id: '9338',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1dcb725aebd3a16a6010c1b775787396b15f1db1e41efef8b260c25c39e3b9dd.png',
    id: '9339',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f92dcf5c712f02e984e80e42ae6aff156a0d1f2239ac072c9d34e65fc354ea3.png',
    id: '9340',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/627a041f62f89450dd2f44b831bcb9fac0e22f0a8d71bfc9cfa66e8121efb04d.png',
    id: '9341',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f44e438803f9eef9790de28a6610f6f77302e34063bb0dfc3dcc5093f929aec.png',
    id: '9342',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c9a5c2610bcad6c403b09da8a9157460c551be5fd717c19c56668b3f97f27c7.png',
    id: '9343',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e38a02288c14c1519a6854a876bb67c0869c176e5538be6e51fb1adfa234d55.png',
    id: '9344',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b354f49c12bbb5193f28aeadacf4e2c50f659d41aa0655513adab160d9f6bcca.png',
    id: '9345',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pink Fluff',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d2d4441160deefd386e4c25e702471b0ec24db3ba52623a894bc54ae1b2268d.png',
    id: '9346',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/696700c9769c506019254efd3ac795306eb2c981d9a5e88b067e241793f16cec.png',
    id: '9347',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: 'Monster Ball',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a3992d19c416821ba5adda611952784287d6a6330c4733238480978e09cfa6f.png',
    id: '9348',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fuzzy Dweller',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7e3843a5bdff301bfd513e803075eb58a0137781f304899cefdf970f13974ec.png',
    id: '9349',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/675a77914d0fc6a1004f9cc0fde70e923dc031ef00ddc4ecba892b84cf45a1c1.png',
    id: '9350',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07b8c1c1235313ebdaac7ec34756864696475a8360fb09a7ce8e770c10c28a4c.png',
    id: '9351',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8e0e4a140a3a2a53ecb6647034f8891d867f5dde43c87ccba74aea64102f0e52.png',
    id: '9352',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0200ee2be297bcfb28ee9a8151e19fd419c6509d8499ddf2d5001893faa0ab2b.png',
    id: '9353',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7494ad70466193bcdca2fb0a1baacb54520e72e01be4bc55a88d1f9b3a5f5a1d.png',
    id: '9354',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a8c5a81aed3d5945ac57e2b9c3790b8842ebe0a42fcbd8b371d0a822777530e4.png',
    id: '9355',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stretched Thin',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0501de706feca2285e0a1ef7981189dd52b091b6a490426cc143d7201581ab78.png',
    id: '9356',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af7db4ecbfdd78f591cd3bfe5fffc2005ce7fe948782d41f77950478a18f5fae.png',
    id: '9357',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2ca974e2eb14f1d3bdc56fd4c2200b2b10983bcc59974f09f98f1acf5485a1d0.png',
    id: '9358',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07debd8ddeab1a640f0541b1ed17926a13fc51d89483e680eac07401375c165e.png',
    id: '9359',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43f567a4b02c5f6fb01fa4e590b43799e62affc4badb0ec881368f1267f37864.png',
    id: '9360',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1093372b97d0ae97a18b613e39e026572b16b7017edfbb1e946e6ad264cc936d.png',
    id: '9361',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1df050c6b7beca1fa0ae7a42ff95a21bdce777f4e24b84c8852d714e8ccc0e02.png',
    id: '9362',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Occams Razor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0af32efbc8de5e090ade7049adaea409560c3727c6679a2f073650ef6585100c.png',
    id: '9363',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/787d6325d47a8f2fbbadfd284dbad026a1f8f0228a6aa0a424625b95b476c283.png',
    id: '9364',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bc447b685705adef792a883e0880018f9461ce5c39b1619d136e0ba86e76e6d.png',
    id: '9365',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/411084175ebbcb1fdf138e0e17e6efd6feaeaed2915003161fe6d932bef86963.png',
    id: '9366',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68e002def10a5c0311a3502f3e0730ec0f201c8069ea6ddb5ec3bb94f5fc944b.png',
    id: '9367',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c0d5961a5f50e6c42fc70ee57e1fdf91486e97596bdd91fff326e10de0bf32b.png',
    id: '9368',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1b309bd2bbfa8d0e4f54e21ce150d3fd4697598b6687fe1bcff872ecdf2b008.png',
    id: '9369',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6ebe1a4b47a92af1f1a52dc2ab86ae6e730f8a4fcf9ef8e259620ff2190156cc.png',
    id: '9370',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41f669059e7536196f0f6cabac10e58ff4b91dbe9fa29b3dc10f71c5bbd2395f.png',
    id: '9371',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07ce24ed9de261199f35f398030ed8b54c2c86e1d3b7a11896b699cc2f012e79.png',
    id: '9372',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cff5651b10704df87759acf138ad4fcf15272239b3fb999a0ccaab23e2cd43b1.png',
    id: '9373',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2b6de9ea8be7bcb5098d0d5d7c94008f458ac81152c9e13f20662c464b20918.png',
    id: '9374',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/744445e34028abf366c681f32591a840115b9e689ef5025f136531a009776d37.png',
    id: '9375',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Batter Up',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12e2e71ee7428639aa64f55bc46490ad6672a3657fba80c0c57b6fdf0c15b92e.png',
    id: '9376',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c6cc6b808989c52c02fd6f3a671c2a662dcac9cbaaab919dd6f0b7bc463422d.png',
    id: '9377',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/62b062a38202de584632741ee2024da3978a554bf97f7a3776c6691d26a18b64.png',
    id: '9378',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9a7221db7de9f2e5afa75b52bead39bc12bc4e5e8520b4ce2777492a7fdb75b.png',
    id: '9379',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c4a11c4395a8697322d8c925eece6ad99da36471ea6a8ced2a6680233149308.png',
    id: '9380',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa0d64ebb2f85a880cf37b723932c5110b27602a015904a8bd240ab0fb24ef40.png',
    id: '9381',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ece8c2962abcfb76f90573e01418cdcfcde427c8db44301ab229f6febfcccda3.png',
    id: '9382',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/495ce46cac237ffbfbeb2b3bc895b4db2e628c51cd04402e3958b6ed63ca2af9.png',
    id: '9383',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/11d7b652669bd820ef90e7cb87341c3497bbf0cef020e619f00ce35c2d4fccbd.png',
    id: '9384',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bc22b38ea8469bdd32cd93a9079b4f79bcb8cc2a44aedad781bf06fbfca26069.png',
    id: '9385',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/47fc3f6ac26169755865c23e43c7d183c52024ed16b450e41cdb6462ff3b4f66.png',
    id: '9386',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34290f0fc86532b1b72035d5a5775bd9d89efa2c55275add6d7477a1625ee8ee.png',
    id: '9387',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b72746f17ace06694ac468ba62a86db9989f2e94598010c832b7be8539f51b0d.png',
    id: '9388',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8cc6bd8ad1eac1c21926d2f751a63abc87a568fb1f31ff653cfcf0631c8976a.png',
    id: '9389',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f763e8c54e67e1860b0ad8b3a38c3ab9e5f48bd1ee63f5cef68f5cc0b50628fa.png',
    id: '9390',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e64f8d2bdb95a6b162b54afe8ccd213079dafdc162ab46175f8493f151263fc5.png',
    id: '9391',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48d0604e50acabddc274c1e4bd6c6cd2b5a13dedc770f2efc87b68ab42dc01c9.png',
    id: '9392',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Batter Up',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c4f1609d0913ec416471c7a58719a2abd452323115c757ea85a7796ac47608c.png',
    id: '9393',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
      {
        trait_type: 'Weapon',
        value: 'Winged Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1134c62f2d6a539e19d560cafa938cfa41039e7aa2d1c0184c217f443aef746f.png',
    id: '9394',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06b6409738378d88fe1b0089da249f2c78e8335abf3baac377243a6016376cd0.png',
    id: '9395',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb64e421d8a3a6fad0e0b6393bf06a5da7cbcaf459dd388ce03ff1f06a63aced.png',
    id: '9396',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/32335435759eb88fb5606a278da46ef9b633e8c76986cbdcfee3da5f19a17893.png',
    id: '9397',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db1eafdd36e0c6e6cc9489244e44fc44df00f1b90477839d11df34c5f6a83b6f.png',
    id: '9398',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7d597db9f6059619f81e9118e4f689f3f7d4e71a724e53bfa5de985ace3d410.png',
    id: '9399',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Comet Piercer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9fa2b80b154bbba41186d07d58dd9a6ecdfe7ea7f503e1e6a7f37ae6a56d5443.png',
    id: '9400',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d24c5e7db066d3569e82d68167d1baaa64b48e253bdd163617a96e81a0fd73a6.png',
    id: '9401',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d67014793df803168cae3feb3a1ba7c2bdd6a873f35e9222e2f68cc226cead53.png',
    id: '9402',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c05e9306909839aeaeea581df09e96e0bfe915fd2c05e6aa8286d394756190e.png',
    id: '9403',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d012adb50d07560bbfa0aaab5d942b75dffbd8de05c378e43eb20b5f92860a70.png',
    id: '9404',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7738fa3580d10b411ba384f339ec1cf1c540cda10ba74df88fdbc0995350be76.png',
    id: '9405',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/053e80c88497970b664e7476be7e13e0a6b665a98c0c2199fbcb81df414b8cbb.png',
    id: '9406',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0beb914eea8cbbae1359e052eea991aafc6075ad8ca0dd39bfada3c377d36ca.png',
    id: '9407',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/749ac616e99407efb88852ef641c2f1e0e02cac1b778281ac292993fdd88b13b.png',
    id: '9408',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e49dc45ba8315a2279fe8e94711e45de5e6e2adfb0717bf75f5e15cfbc4fe620.png',
    id: '9409',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df11faef7548ede92194147e4caa5c4721c525b348db43ad46a2a80fa0d79e88.png',
    id: '9410',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6f44ce5b3014d2ef23fcff60d990d53f88914c609e0b8334db47affaa9ffc5a.png',
    id: '9411',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/435a49af9cfea3f0ca5e6997fdfddb4f2d9c5dc4ac3a3caf429e7eb1ca634e58.png',
    id: '9412',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/664f4bd75e0a26d83b8eeca450a7bf69ad6e8f6a54dba2cb51ca8315fdde4e96.png',
    id: '9413',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a93715879b17f21eb0f56a09e4f5fe9c38e9444ce8a98c9d56b8ecb76497f3f3.png',
    id: '9414',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b6e3ff576b7aaa328e060b62a5a51a8562b8539f21a6c1737f2e090461d6b77d.png',
    id: '9415',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/87022b8c9a47c0f60be93cb461dcff0569a44444cbd56eab1d1676c0b60eb688.png',
    id: '9416',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c84e12e776e886d7075e9025d0d9edcbeec705d72eee275ac540727f2b341eda.png',
    id: '9417',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d9ff5d17d89bb1ed2cfda22b1c8cd4f534512380d559f1d83bdef4c88cc8a079.png',
    id: '9418',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f78f99f00fca3f07fba8ed994734f9f941449b28e84b826f175ae2878df8781.png',
    id: '9419',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Weapon',
        value: 'Wood Ear Chopper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ac317a8e06f6d88dd94f7145243d0c571a6e2ee6d868de310cff81b539906cc.png',
    id: '9420',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/044b80e837370b3f444b90b4e5a2aafb2d76aba8d3b0a700016213208b687798.png',
    id: '9421',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3b49fa9d5ea1164d3ecc25fe6eacf969e05af3c2730e0f19280e4147e3f31f8.png',
    id: '9422',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Beacon of Light',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19259e81ed0008f46d14103436b50cb0a4a9369cf8e8e03ff9c979d88969d2a2.png',
    id: '9423',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a2af895addcdfbc04b0562d860c9bf6ebc364338f5355cde023209af50fdef6.png',
    id: '9424',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d97f97d431e16c00a14bf99169d535d4697f44db3a037a75a11ee5142a2c93a5.png',
    id: '9425',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ceab7b4927e2f05dea14fa3d3a6a28fb7c45ccc458a33eb188f14d533d45897e.png',
    id: '9426',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3544574aba71323cc8c9f070174c66ffc54d64785086be73e5e3215700a441f4.png',
    id: '9427',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/714d63e225585bf3893939c3f0f43f04d537584f8eb8f81c5eebd89f15bc538d.png',
    id: '9428',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Calcite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c86ad9a4b3d4f11128809d990d7338e9069cd29d2ea02e0987adb06476bcfcf.png',
    id: '9429',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2aba453597c242a49c52fedf88a5cd559ffecf6c1de27e7425730a6f8f51917a.png',
    id: '9430',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/287d107fef415b81a87b08b06ed869d0fc710873605924faafb8b44adefa021b.png',
    id: '9431',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af08f1b09479bb8f912f84dc60e7615d8396377eabc394ce0479a0d5141fb360.png',
    id: '9432',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9647958f24886e4aa8676f3e56cc3e76573e3ce04c26bf40e60a9540b5fa5043.png',
    id: '9433',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/863a4f0fb226277aa06b0626bed604bdbfae50a4e381a5ace829e57e1925023d.png',
    id: '9434',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6419215eb96c5bf08a82e99929e1739b055acf1278ff51eb7fd23f3594e2a202.png',
    id: '9435',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c91a208de0e65c1cba93d6cda2cba12ea43a6d527caf1f22459498700a0693fd.png',
    id: '9436',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9a140e417192be8d793545a2c48c3bed2ef84dd5f896639a82bac0dabafb8ac5.png',
    id: '9437',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acf2eae3a54c09c8343f252363118c555f1df3c78ea28d18fe158c4edede4482.png',
    id: '9438',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5c4c289c8c48f2cdca9d6e1b60fc0707e749384c741bf823d82d2511f2ea23af.png',
    id: '9439',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a12867c3e87531d7871d6c017415ca451b74597d6bde547576cb3a2eb2155750.png',
    id: '9440',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a92bd3fba9028928476c53a12ed252329c47b4ea71efb238f6a2e573a3620056.png',
    id: '9441',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb6652353cc257a13a0804d82a56c1a62b347d5abc64e381204db8b7efcf5830.png',
    id: '9442',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Never Bokken',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3fce119457022e8c5b59629aa5759d83889241c5d2d8babe037a6470d354f27b.png',
    id: '9443',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Green Thumb',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03cf25741ecc6adf53eeae26007faa77b4a1a2f93a96b4f494d6cfcd8be2b17b.png',
    id: '9444',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d25cb13ce8737da40c0e735dc008f55acff5088d83fbf764ebc26c26b9c671c.png',
    id: '9445',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bb75c1af8960af8e4a13a1f09c20274e0f526db0d42c2e899818b6143d72455.png',
    id: '9446',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5f3d904dafc21c8007e0cd88e9c1b82142421d602fa4a6c102e045c3961d6aef.png',
    id: '9447',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea0c4b06493b068e649f124dad348ef593838cd3f59565766afb8278b892cd73.png',
    id: '9448',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99120cf9ac2cf81707e9e80aef2a3ef443735fc60ed079b7d37b84d0e7c402de.png',
    id: '9449',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/243cca6c5f7190fcd2293681b6abf362d730657f931a2f395ccd2d0975d7ddd5.png',
    id: '9450',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/885515762aaad66329379b177856a5a11e67ca8e475d2e9d27d5ced55a1da504.png',
    id: '9451',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/355f30ab4b54b3e308403f2f4b96fe4552568e7416ad86b124b351540bd21885.png',
    id: '9452',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d321332c072c3c1cdf11492e4bc30e3f2cbedef7239e1556e8417c1119b7c263.png',
    id: '9453',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/439556cc72793fc5051ad2524b9c36502ece1d2567f41807381d99136052829c.png',
    id: '9454',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Candy',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e1a6eb6340467a6484ba18428fe44f626611251206441b69e6f10f04d87879c1.png',
    id: '9455',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Weapon',
        value: 'Ox Tongue Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/593c7b707b6c1c2d13c1b9bcf0781fc62fde9dafd2f647ea4144ad02585a8cdb.png',
    id: '9456',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39da6153c8de942f6e89f135bf5bd4f049c2f05e410e66b4f1a9f0af5daecd65.png',
    id: '9457',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Weapon',
        value: 'Betrayer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c829b84c5f8da42b516aa7ef8728c6d244f02773b996f6bf3bcd818566f7481.png',
    id: '9458',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/91531516de3f662b17508e3d91ae983f8f03d7b437ebbf502a28f7bae4073aec.png',
    id: '9459',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76b939eb8513cbd010cb2e8c3487307d15f99b735b4636b27e060f25de1d627f.png',
    id: '9460',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5aed656a5ed1ff9f13874b4b8f989224ba30ce43119abe225f198d47369ca063.png',
    id: '9461',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d07cdeb80f0b0d5bd7e56d0f65f52fe4c90b64f47e70a7ec1bf236f3c652a3e.png',
    id: '9462',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f72fdf4e73e72d28b5ed6ca0a44ef779453f1096f90cb85f2094527995455c15.png',
    id: '9463',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/757f739fb8b92f15264731c3711fbee142a7a0add235a053a99e1b51252aae70.png',
    id: '9464',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5429c1c09fb44c148bdfb5e3d7181425ba53a206131b1c274ce1ed5bc8a828e0.png',
    id: '9465',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0abf1b8162137a484938d6f57f2ffd4f3ed228e563317c180aad6429153c0f16.png',
    id: '9466',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/84ce37643a089ea89a58e4db86399de9b812f5eab5db2c96e260e9feec6cbc31.png',
    id: '9467',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c638dc3e4ee697577596837350cfd77acd154ab846bf088f2c5a23b0516643e2.png',
    id: '9468',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a54f2cdb5fbbfa9bb2c9fbf8a83e346f4f1c6a37a985cf6700b18ca09b4dfbb0.png',
    id: '9469',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Omincidel',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/80f8561cbca1df3bcc10e4be3e56e422d1b27c713bf0ea54ad7ebfebd952099e.png',
    id: '9470',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/983a13f386c4b66b033e061ec49398e254d111d2e2d83173522f297e89ac084e.png',
    id: '9471',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc68e27945c6ce0deca81ef88ed01f2db6f58588b0fdca87caef87d56ee0a70d.png',
    id: '9472',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c308c02d7bde4de30736d01ed3888ba6dc755271c1fabd6c97f8d2c7ea7cd597.png',
    id: '9473',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09438e47d06a2721b831055541c6c4f46f25543092dd9a119441f7b8ff657b23.png',
    id: '9474',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c15e4f45c95fd965d9e3d25aac74252a231a359a475d2825204dcac374377fc8.png',
    id: '9475',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Silvertongue',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/df8531337f67fbb3c09755bb80a750be1990043522346d476df935795190079e.png',
    id: '9476',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/653f780e9b4ebc8a595fd30fa8fafddb873bbdaf7813c36af856eeea92ad4604.png',
    id: '9477',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b6350004fb64d3a30c1ed95004622daffa7523e1da994060ad24732cda7e45f.png',
    id: '9478',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd018733a5d110daa4e0db3604e86d6ddf7e747e88b57d8138caf06400015a5f.png',
    id: '9479',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9cbc06eb0f61ad16476945acb8bff99dcbc6ab0a2881650ac9f82f64ca54bccb.png',
    id: '9480',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3195cb22c7a9322fc5b1fc1097c19b39ecfca7af330af31c1549b6e6c0851c34.png',
    id: '9481',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f7d886010aeff58aff519b13d8a677f0cf61ccd8e898618744f751a41894b7f3.png',
    id: '9482',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0154a5f333f0b69251ff414b90188631a953caaed73d2390f2377dd914cf30a7.png',
    id: '9483',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9767be99abc833a85fd8b692c730b5aebf9a8cd2324efeefa96d5ab28195ab37.png',
    id: '9484',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec40301776c3e45585bc2ee761529828b06de12e18504329415bc9902d84b067.png',
    id: '9485',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d4ec16bed373cb986c8e549a9cc0bc161ddcece4535c4cb992a8dddeddc0873.png',
    id: '9486',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b9ea03efbaf41c51fede24c829e1653ae9b5b406b8dbce7d5764bd698fe0832.png',
    id: '9487',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/14622a565b889c88e8a3400761cdae97f7b127c928d0adc0ba848aa890a49d13.png',
    id: '9488',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Tearpaint',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0805951d04b87f7c8aa1e7400ee868516cd7f32da5b6098db63a92493146dc1.png',
    id: '9489',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45dace4e64c1b3ebefd956173c8f1c5d2380ddd0fa69730bc029ce6cb8c7ef7f.png',
    id: '9490',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3f331a4186a9e63c163cbb3976887ceb4a9058ccf52af97a31a0603b9af66f37.png',
    id: '9491',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f73f713c8269a991d2eb48a7ba8e40f3c7d825a184324b2be873029889e8a6e2.png',
    id: '9492',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c29e9df5dad0421393ebd463b3f7fe7db369b474d38665c54b0370c8798b0c3b.png',
    id: '9493',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dfac9eafd2fc4dabff590e8746c45029985b98c021586215f7cefed7fc82dab7.png',
    id: '9494',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7916eebc9b8ccd7ba7069a681f671595e6c07a378fd3b58f7e8e61cd892de147.png',
    id: '9495',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Stay Awake',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a0c64269d798a404b46a9242803cebd8281d8acc509bb255338f83483a302a97.png',
    id: '9496',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/deea80b1ac665714898c0d514a2e89056da32e82870fca13c72faf039233093a.png',
    id: '9497',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/40c395d2534c551d0db53945b03750d7264fb16a7062927ca4bf68aea943b7a6.png',
    id: '9498',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53c86233125eb99a6a1b2138bf8ad29f5abb3d33532b2ced83f2a6e8954aed18.png',
    id: '9499',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Ape Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10287df93b5b346f24510997731b68f1824532bab2f11e575047d5bec1b04d3f.png',
    id: '9500',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8aac8881d540b8ac11beb08f1917fec56b0c4e80c369b88023358efd96f6b088.png',
    id: '9501',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/619c3d57365cc3c5f49d093535079e1ace859c66d15e5b2c797bdad8c2c93c44.png',
    id: '9502',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc2003610ebf85aba88e573f62ff3a1169361dd96a18d6604aaffe4d5ebacfd7.png',
    id: '9503',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4c6cd5d5b1c1a2dd3e6fef56bdb3e34ea0eaa7f5a20345ac33bf1686f0d2b1c0.png',
    id: '9504',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d4e8a7705cbd580b7740fc999d2a30d3f03ef7013181beba834f1fe91f76adc2.png',
    id: '9505',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/916afd8d6ac1ab885b8e5223d899196306e7ad0af76c43cee73d925516c0bc4d.png',
    id: '9506',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f8c689ede88a6073bc7b568eea30a3283e06634f23a12621582c7be2df6dbaf.png',
    id: '9507',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9c617fb10ccc4cfda988c0ef98a874e353550225defc4421425cbe8564977602.png',
    id: '9508',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Bloodshot',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e83b1be2e824018588d43fa27483bbb1b9adc5f965d0d1f41f041e2512965a29.png',
    id: '9509',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Deep Sea',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Glutton Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b36c63d32ec4a511f541e90e0c67048072ecd05ccce90ea4f454c41f25a8a889.png',
    id: '9510',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d63cf7baadd17d06aad955c9202e20b403deedd359a5279038237a49e1abcb50.png',
    id: '9511',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Steely',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Gunk Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70fdc1886d1443ba42fb000bc1712b4c8b9d138940c410fe0eb2d4daa05acfd3.png',
    id: '9512',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Uni Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6076d3dca8196ea31cf08eb6acf85a0fa666bcc88eb0800e4b3accb66f4633cb.png',
    id: '9513',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/553071c55f1fc8f93da2f7347a8e7e7ef5f789a490ff17df6b3b16fe28df8cde.png',
    id: '9514',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8fb05793b054951984cced3afdea768abd834103146e3882376aeae22ec6f1ef.png',
    id: '9515',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dfaac700b6456e8526122f12cec261f89c2a1b4276b4e73b26f08f24c0d09a58.png',
    id: '9516',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cd20166dca74de10ff9e33f7ed0d0c2582fa91953e25c919c1ca2360ddb6e891.png',
    id: '9517',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/920d925a33b76e685a1bd9dae4a0dca6b8ee41f1e34751ad2c2324a3bca4f670.png',
    id: '9518',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a85d75cbeb02392a9803253e228643061188f0e0647439d59f52753fab420f2f.png',
    id: '9519',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02c7773a162b5060ac6d74ade4cbecfb790303b7b7b9d92b1ee09cc8d3a534ab.png',
    id: '9520',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81f8351e9c776cae171076a7856d233f6a3898819357d685e390c78b188d8ce7.png',
    id: '9521',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Sleepy',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f907f0daee29d2fa011f68632d246f9b4857977d3ad5bee654e6858ad8e0d21.png',
    id: '9522',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2a82056b08e7a397934127fd47ee83240b04b094ca2858b9fe2f2f3aed028c43.png',
    id: '9523',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ae88a24cac0c3f5c25fb07bc87f8dd88c54a2319d0bddea6e988680841dbb69.png',
    id: '9524',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ad351f5904a456f7a8ccae9c7ccd80cb37511168e15dd321fdd2433348748170.png',
    id: '9525',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b8ee19da9011a044474098e73905435df18806b7c6d04b63d816e757d7ee977b.png',
    id: '9526',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c31e4c24b749737c2e3ea1fd53bbba2972e06328c4f873c61de328cecf25425b.png',
    id: '9527',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/270741b522abaf162bd13e0979c358af26ac58f8f631de3078db04ec3c62d630.png',
    id: '9528',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Vortex Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7550b1ebd247e5c5e49d0a0f09ab6dac143e5bb8ac64e134be65140d45f7ca24.png',
    id: '9529',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1740073c98cf7ee80089abec6d7cb51ad0fb5f1e76c39b796bea184724cd65b1.png',
    id: '9530',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e3116a78306de9a53352350616a7b64085ddc0d525546c109b556c4b4545bcc.png',
    id: '9531',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/96cf2445e5cdf2fed47898aab575ce8cd7a14ecca010093939f1c21d750f4af3.png',
    id: '9532',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0985624f5d11c1f7fbb9f24714629dee4ebe76ea15437473295fc4454544162.png',
    id: '9533',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5611d4900f0744e0f0eb4198e4df513f5f0f427b4ca49b37a948fcf878541e5f.png',
    id: '9534',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27de1a736a6b6463bf59f5c8545d7814f1dba300b6a897b7dfbeaa3398a84d15.png',
    id: '9535',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98c4e8195a7f611084cdd699bb23294462b16671b4d44cf2c4fd24fad31f2775.png',
    id: '9536',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bc93821d4e3b9376de43ef60c347e9d63783f4b66679271fe9fcfd22b94def7.png',
    id: '9537',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6620167fa3f1836fd51cc4d7b0f4c078b0e5fa450ca9b80a91396be5b46de65c.png',
    id: '9538',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b0bf94d15e60d6feefc512d279aae6fe75873ed25f44f0945d7f6eaa79379f4.png',
    id: '9539',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7afe909e7baa473e480e1f85e5fd39c693724279d41805036efe07c323e5086.png',
    id: '9540',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5e9afa029f43300485ee9d8ca50a27765ad028885d10bc05db6351278078c73.png',
    id: '9541',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09ace207044af9de0bad2cbf712745318f259521a7a47706b92451b9837c6952.png',
    id: '9542',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/010847de229a376565deb8f8fccf8698c6b3f491900cd3e78063590affe56cfb.png',
    id: '9543',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/306b4afce7578b87e5fbb0899b1b97ee2de21f7940f11fa1bc7663bc2541145a.png',
    id: '9544',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04e8e1aae31db74d21ce139374a25764f56b667f96f8414a1e0358eb65e47289.png',
    id: '9545',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Ivy Swirls',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef66152b514dc27fd47cf3176153afeb210965371810c3109cf272d7ed5b0234.png',
    id: '9546',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/843ee38d5409c021ef71220f5c4ea4b4d121b1898440f4f3c9ea40effa520926.png',
    id: '9547',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/454eba4bd92d1e8bc1e9fd1157c77131fc2617aeb831ac21d54b187f5782b942.png',
    id: '9548',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Gallows Scape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c16b60d2f199c1e18a5fb895d86e2552fa5a34d28a98daea198d115248fd8847.png',
    id: '9549',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37a42ff8c83aa9486d7dba730a7592c1a01c76124b246cea26a568586ef67de6.png',
    id: '9550',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33fceb3e72f120c05fe2456f9d6440edaba0edc3532c469f42caf59871aa835a.png',
    id: '9551',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Worry Wart',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b2f1aa30a3125b9d2b04190bc551d1641de93b31c5c75a0605424a8e93cde9e5.png',
    id: '9552',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75b51c899a3b3522b74d98df70a50c804498a1122f206facd8826d103e068e2e.png',
    id: '9553',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9d16809b12d568b0332cd928a15d06ce97efe79e5a44f33ee2595522c35eecc2.png',
    id: '9554',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goliath Bull',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/75a1d6d91c96351f872d2818900b47d305cdbfefe29c84050d723c8c09774d3d.png',
    id: '9555',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e076a5ea2becab90d80d9887b76fc66c989025a8c47838af2400b479061f70ab.png',
    id: '9556',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2c6dcc8b56d8db065a4b450a78b522dbf2b159191ba119a1c63648c980df9177.png',
    id: '9557',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Hallows Crescent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c508dc3d4d10ee57564839f0953e4e191a37c0c42cba3089f827e39b64a999de.png',
    id: '9558',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/04c601ea6fbe4c961fbcd012188909e8df72a7345d2479656be421dca875815d.png',
    id: '9559',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aad1d6a32543e4a5d2b164b731f2f531873799235703352cea9adf249eea0803.png',
    id: '9560',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7a2e22c493f4a44401cc61a5ac0cb50d6cadb5046d4b9bdd1ca6162e8e446584.png',
    id: '9561',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6bfcd502961558d26b268501fa00bfb8aecd77b23113c4d9c32b15749b987014.png',
    id: '9562',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fad9e80105f7bc51b4f91fd7dd70ee69c464a8e2c0c42f8bd0bfb2e88f4b0872.png',
    id: '9563',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Eye Sore',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b054970bca0988229ce81b32af841785036886f7c9eec46767ff171ebdd86c2f.png',
    id: '9564',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02b7d5c3fbc70a42cf529c98d78e48d16092852f3f1df31f69891898635a0a47.png',
    id: '9565',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Snake Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f45806c44fef64456120660b05f5b83d38f0a7d95733f69246736f9ed9c4e6f.png',
    id: '9566',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b601e0ac753ecf650e029473122e2443e316a47ad822e408c56137910a7f0922.png',
    id: '9567',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/298242c6108b8835f76450df2357373e9cdcc5038e1b0a85999ff7d2365415ff.png',
    id: '9568',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8bedd6ccf30e672eb343798eca378e655c3b8f41df052eb43c6eb4618468305d.png',
    id: '9569',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2b505cd423ed07d23b658e4be790b35c1647c64adb9d9b0cabf9d1414c2a893.png',
    id: '9570',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aa34791dd9486d68480130cd8def9ae338730257fb660fa9bd89921e82377085.png',
    id: '9571',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9e27dbfc5baca9a9d45e6510c9014668b3536ec411f317691fb2dc081ffd169c.png',
    id: '9572',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2af0d024d82ea42a9f8c144a959d6687e08569173287294de8dc765867e24014.png',
    id: '9573',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/037ae50c2b573cf173751dd0f6de76a142978e22db5d9e53664972a87e45e86c.png',
    id: '9574',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Bamboospear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0875732b15d9b86b58aa8c69a5e6d53a7ef4ae2333637bcdb15094bdc4f8ab47.png',
    id: '9575',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ddc4f8c9b19415d4b19757fab3dfaff5d7f743668db29a61804a06caf34cd4f8.png',
    id: '9576',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0b27378c560e57ade318e806492ce35f1f2ddbd9ee6e559e2be6162e80b9c801.png',
    id: '9577',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6f118cdaa0839e3bf61a03bc07cdccf64bcd9800f651258ad0e2f47a33e5a9a7.png',
    id: '9578',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/77f8b20a32dae22072291cba933d77e2a7d03cf3b03eb1315030c750c60e1f3e.png',
    id: '9579',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a419648adc406a324f58949a3e72d46290b26419a2219060e6dad9caac17f63d.png',
    id: '9580',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sticks No Stones',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0334f9894ede59d83a74bbd5b6475cb0846e320983ac36dae39a4da712d3c62a.png',
    id: '9581',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c2441fb6a7784bb6a19137b306179520f028965eb1a57c3c80d060df1bb652e3.png',
    id: '9582',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cdd1c715d9c956e0a51f7ca9922967da28f9619081de13dfd4f251fa1feddf3d.png',
    id: '9583',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1b07b6bf5380a7147a176eba4f864fd243b80739ff2cdf9ac184c0043b31209c.png',
    id: '9584',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f1e892ac5fd74c8e0b7054e200aa1818c4b3dbc4c3e4b1abab8793c9b7b81769.png',
    id: '9585',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ba1e240b2744c9d5eee9d9b969e9c490b8c0bb838e5651b153c4a363a5d7316.png',
    id: '9586',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/38f359b7acd97f9447eb07f50a8dce7d4774a5dda68fdbb4d6fbd7152008987c.png',
    id: '9587',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c66b8c53e6f4a6c68e2b3447a6bbfd989d79cec730778e99985a7aec4c89602f.png',
    id: '9588',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/adfbd954e66fca9f42ef1c13f885148315492996ba679e2fcb1a85a7aa68c0e6.png',
    id: '9589',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6fe5575e048ba11cb168511492e97ede26143237e8d4b05ae2294f34d75be2b7.png',
    id: '9590',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventurer Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8cf824683795e53e51e2c9b90c66f4373d898b21119bf03d8b5d716fbdea65b4.png',
    id: '9591',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
      {
        trait_type: 'Weapon',
        value: 'Studded Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5d8a999717095e255bee19c733cc6a783159ff943f386d2727a31b1b0255b383.png',
    id: '9592',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce2a2dec98a4cf01f43b1a2e8c7d92a33eb3c9cb0323cfbf407b9f6f838cfe1c.png',
    id: '9593',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Ocular Drive',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6463e359d3bdc7889f5dd86461624d002b1f770fdbb6f2cafea819b2008956a1.png',
    id: '9594',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Woodland King',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db34e132570b09bf14695a3f0f34e6ecf89aad14e78a29add35205d17c5fd56c.png',
    id: '9595',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Celestial Crystal Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b3f91c4c2c06199bcebf65f81469f5d0c3d40bf2dcd770bd2613ec7a968c0155.png',
    id: '9596',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/28947285e524d74a1a4bf8f58e5e981690f049775bdfa8ed7690d9375311908d.png',
    id: '9597',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6aa76d323e7667df932176ef7281b8da10b289bf706293b4e1fc8b0dbbb513c4.png',
    id: '9598',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/edc34573f0380739406be9453ba056a2e7ee8903e1a17999655972f58b546f05.png',
    id: '9599',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e789789efc40f33a2427022c3529f629eb380d5dfcdd58b94ece21fba086b1b6.png',
    id: '9600',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Battered Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Executioners Delight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ae73b6bfed1a1aa257d8244a2d5d2f12d1c99c2217a3f9940687748113e0fea2.png',
    id: '9601',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4fbf7b6f4a9dd96cad77179e5ec294dd2c5171ea4813d67a1dc281f8da9e7526.png',
    id: '9602',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
      {
        trait_type: 'Weapon',
        value: 'Underworld Spine',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c619958967f166b681348238e0eb325322324e580de3016402d52713d434d4cb.png',
    id: '9603',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/86c89629a33a83d90feb07564ed57c0bab6fabc6d7a9464f8f2e0315b8906ea1.png',
    id: '9604',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db01163891ca72817363f8195b24d693e9a91b50a9724188304fa299a88487e1.png',
    id: '9605',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Mysterious',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/373c2d89b2fc02435f96394f140376aa112158c3cfadbc8e9e8ba727fc8cc964.png',
    id: '9606',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f58192c96d327269499d273088f88d5f219136fe482fe4fee4c7cd223a1d3c3a.png',
    id: '9607',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Catty',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa253e2e61705c0997eed3e55f2dada24cd0b2ec15f10ddf1cbaf59e609c6f1b.png',
    id: '9608',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'The Peeler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/afbd8a72619b57238dded75eddecb99b857a2ebdf0ceb49a899b5584c6b7b322.png',
    id: '9609',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b253006e162987d122e47cefe2f0330c3c1dcc99b6224b887051089cc9b3c40b.png',
    id: '9610',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57c4988101459ca78604a5ef1f038389d98a21c35d077ec5bad830008463d962.png',
    id: '9611',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Arc Wielder',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aba83489e451031fa2142ef67d4e2ac73d71f05316e160a31a0cfce28f09b2bf.png',
    id: '9612',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/815bf0db178d679601227ffe979fdbeabcfcbf597ef498a5ce86d335115bf954.png',
    id: '9613',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/082ab340e72cdb4cab8be820c5b0d49bb445703f406d2a134cd270553fc63ea4.png',
    id: '9614',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Carapace',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/086cf2130bd526c5c25f4516e2c2fa6ce9aeae9a84dd85445f642136c0511851.png',
    id: '9615',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4eaeb7903ae5cb7027d970050b184d45fa1c92e8c746bbddf5ddce138cbb4744.png',
    id: '9616',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/330149d6623e0163ab142be65ce37a792791bcea388fa84b14e082b58954be42.png',
    id: '9617',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Seeing Maw',
      },
      {
        trait_type: 'Eyes',
        value: 'Bugged Out',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18fb02df2f35587d89b833b5a1c02a662388b5837f30ad38c8dc42fa0bb8e7b7.png',
    id: '9618',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ccf51315d486401375f17313c58b101ca42a9a6bbadc83df3d552fa8c27c1619.png',
    id: '9619',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Coin Slots',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12a6677daee5e1efffa8b371b2d987743aa5e62e7153c4a12a7c1e618479e182.png',
    id: '9620',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystalline',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/806753173a52e029c026934a283adbf117285b668d485feca1c9d231deb5cffc.png',
    id: '9621',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Basic Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2bfba849035190aabe67a2f8e0602d6cf5113dd74d7501eeda7bb105bf1dbcd6.png',
    id: '9622',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c51cfe89ddfaa8c43c02dff32e211a271e7f32455fecd8b6970d449bcb0329c8.png',
    id: '9623',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7660b1e507d057f817813d99d1326f8d51529a0aab15a8ef816c287b496ad1f7.png',
    id: '9624',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9f63f3ccac8b153374d29ba75ededa116eb17a271a3fb29c69cbd11904a9456.png',
    id: '9625',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Jaded Gnaw Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5929228e840d183e6f4ad6d107d0969adbc5ecfeb056d85e1d9d38ac7ed12f0d.png',
    id: '9626',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Stygias Fang',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20d9f5543fbc2032ffd8a176e49a687bd6b2d827a38ed332d31ba0685380bbf2.png',
    id: '9627',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Elite Crimson Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19bb3c734f390cedd69a5ae957ee9f7efb9a95c234698a15d7fc1c3bc0acb005.png',
    id: '9628',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blue Crown',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6903ced2fbd9e6c4ba3d0498e765bc7fb85711b63c512ffd2bdb8476fc2c37e3.png',
    id: '9629',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/92498f93a77c107721f0aa9f32b955f34d8f61523b2e2d9d521e3fafe7dba874.png',
    id: '9630',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/68fcd56c9f5b0ac9e232712cdd60b6263fc5f844360f81160996aec5f3b5946a.png',
    id: '9631',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7b73dc42e209ef6a56a1bed1233a1a68759a4ab414a0a7ad8da85bfa7b7c484.png',
    id: '9632',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c186c3ae2eba62d6164be9b1f8d6f1bdd1028bad50d118e1aa0a9f4e893dfee8.png',
    id: '9633',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/49afb9c43d7c0ded1a26e7c2683efb709692544825d17505c0f34543ed45d773.png',
    id: '9634',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7183a5adbba2e549f406cc82b73394f98e14e9913b7e0ab24463784aa8411565.png',
    id: '9635',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d7da80897c815a5076f9961bb14a4d2b6277521167793fae6bf07589604eb961.png',
    id: '9636',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43840936dbb4fc1d1a97365d7776af218df174d3c213852c0b0730e2b85a8191.png',
    id: '9637',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Patches',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6c64d4d64c2ed97c99486a6543a435cceb03d8285b34e5e6530a2e9dc20190a1.png',
    id: '9638',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b994b296baabf7818d2813fc891cfdcc86eeaa8dd8c36200f1799f3995764475.png',
    id: '9639',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5ad658c802d8b9b11c690035d3ab9eb25f806e9ee2d723ab487412111099e8b2.png',
    id: '9640',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/390f4637eb51ca100634443775222d13780b72050e2cb4e6dbac37c63129362d.png',
    id: '9641',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1ae475a78324c57b17dd04cf38574b2dcb0c19ee0a41ff0776e4202ba6cd26b.png',
    id: '9642',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7b7b1250c5ed314a6ea2a2bab23d33f184a449fb38a8f0ef4aa9e3f94b896e48.png',
    id: '9643',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74e38eda6c8278de28ef64ad233571228d99745162b83c7ec01f60172a54c0c1.png',
    id: '9644',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/744e95b6672052572b7c780f942eba60f0d57f7a396a27d5980b5cf3f30ed2ca.png',
    id: '9645',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97ddf7ef63ad6e0f8207dcce05b3cced771beea0d74f38737736ff796f09c5f6.png',
    id: '9646',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/51ffdd8975c904ff8fb5274713c15dbc146f106704c03bcc5f1ce570f0b64a07.png',
    id: '9647',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/118cdf5731e7e8dc7d19934dede5dea2e0bbdaf96bcd49d794839806f05df72e.png',
    id: '9648',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d5136b7fc61c38775fe0f24ceb582140a1f0effb64b048123d1e5e11c34ee52.png',
    id: '9649',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea1defb572934b2bd88f22033aa2461104a5b133de11fd086e788549e76141a1.png',
    id: '9650',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/09432510953750d8aa6677ae0c2954b4a901de4280711b029acc913e2e3e4a24.png',
    id: '9651',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2e0b40e3ef6b81b19848fc91062917af42c18c78a20e3eb6ae6de260926d9b7.png',
    id: '9652',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a441b17eba6d6009764d503951d7c6f8476d1ba40289c30dcc008baee70d74d4.png',
    id: '9653',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Skurts Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Feathered Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d880782e19a68dfb768fcc6b8fd7b3b616152788f30b6702cafc437df9704e0.png',
    id: '9654',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9119d53e4538bd501bbb875a55e105f8b353d877da885badfa9e7db9c18fbee9.png',
    id: '9655',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Octo Tentacle',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19579167826d0a7ad19b533ffe4b0a4c9fe837082f66e543f67b9189cb979f9c.png',
    id: '9656',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26d7503b5bdae0c06cf45ce6936ba7626db59693816ce52ee1c5283d8e1c2cad.png',
    id: '9657',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2dd1e8705f4324583a023c926956d4a541e457b1878cc60865a42fffe40f2bd3.png',
    id: '9658',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/948c2fdc3e3830b96c9521589a18022a8f88f62318132eaafd45e960316a7210.png',
    id: '9659',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arachnid',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6322f848f3e10952ac6cb66176c822bceb56bbccd96c6b05bf221ed670bd226a.png',
    id: '9660',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Ears',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3cfb131c48365add446089769460cd573c15f28fc7e0606970a567c5a065bc76.png',
    id: '9661',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Widow Taker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71cfb53156f684b86c144df3ab45187d4a46746d97a2e7a89e2201582457bb99.png',
    id: '9662',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Vertical Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/be12446d7a35565e04515969943c974e339086c44136acd591d97382b8a9aef9.png',
    id: '9663',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Ethereal Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/81487039084f0104a0caa463d2af3641ac7555d1526ce9fc4bee52a716bd42c8.png',
    id: '9664',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'I See You',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Ogre Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/880d034c0a8cbffae7850cfb2e38692fec9d302dc92df4da183abf2cbbcd6797.png',
    id: '9665',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c6df13766efd97fc9e225f41734e1c00f8609e4a7107ae9c3a4ac37616f40af5.png',
    id: '9666',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/155fec9e48e03ddd90ef2215f704230bd978072d1de75d68246f0db935a881b4.png',
    id: '9667',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Weapon',
        value: 'Scisword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a1efbdd2087120e197c3d6fd0cb3db3a72b7930a3ed9512152075334b0012b50.png',
    id: '9668',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9abbd624bc13d2bbd15ee29dfe54439dfec4c9870b69a1133c2efbafec35bc95.png',
    id: '9669',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/634613da8ab84e25143f9a730ea94fc594655d3d3ae223d3419edac090733eb2.png',
    id: '9670',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Pits',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Trusty Hatchet',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/332b5394586692fb61328134ca56ead81ae073f0a39e32f6cae193f67b2d6af4.png',
    id: '9671',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0843ea323129075d0070774a7ec1af1d7aa15efbccf543f590453879c09be04a.png',
    id: '9672',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ade5ab760e16931cb1fe8d66993a4ee01344b51be7d369ecb6dfc65a56c17279.png',
    id: '9673',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/694796c8ebd6e12fe6f87ab055432a7460f240322a65b0723db8b53fb130a61e.png',
    id: '9674',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nautilus Trench',
      },
      {
        trait_type: 'Eyes',
        value: 'Afraid',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/efd2c1bd85884e67b64a9ce5e07404610e22d8feababc61a462bd2f837d673fd.png',
    id: '9675',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8b3b0436990912465654cb023da056a000140d78e32cbffcfbcd50b6b5bc91c.png',
    id: '9676',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Purple Flame',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Weapon',
        value: "Adventurer's Sword",
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0ada57609a5856d8419c971c1732a77980fbe617a14f6c397a131ee8078826d1.png',
    id: '9677',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dawn Disc',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of The Horde',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/16eb156e9886eec4c80f404a9f6e7edd974bd21691c9958bcaf9b569bee68667.png',
    id: '9678',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a35d67c6481f2b228bad60037accc1974b9ef9ba17b41e1965a47278b2846758.png',
    id: '9679',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Springbuck',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b442f9c42e420a6f2dea35a8a6ab9cbf2a0f6a9b2692650dad6689644d25b579.png',
    id: '9680',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystalline Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/489a408a6133a2d4d51bcfcd019d4841bede845a3ff4b842f22334fb88d7aadb.png',
    id: '9681',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Badlands Elite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/07b9424122f8724deb63400edceea5f4d5fa001ad61a1c2a05c3d79c224c132d.png',
    id: '9682',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4dd21a9269aca0122bea2553578490e76aa45c006034df799518d3ff90173580.png',
    id: '9683',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sun Goop',
      },
      {
        trait_type: 'Eyes',
        value: 'Sad',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec5025054ff214170ec068b3a54491a192118b888b3a2b2e07e7fa42b600d1f4.png',
    id: '9684',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48b24c57041c2e202a1c08cf04a2c9c29751c308f6b757d804c2bc627264a5c2.png',
    id: '9685',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Owly',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d89941892e441bc7d849776534820be1b45555661e5791123018a1d235816970.png',
    id: '9686',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b7f2d795b768a62a77d4bd6cc7b70da823d5983fc3192a6b037184bcfc222e89.png',
    id: '9687',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/46005504013e9383c86e8751c60b0951be0a5e2f10ea83273cc3ccdc8bc4aa53.png',
    id: '9688',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Weapon',
        value: 'Scavengers Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/63a77314ee1deaee1a05292aa21117ddb49f384066c3540e79c4a85ff80b9084.png',
    id: '9689',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Islander',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'Betrayer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1980f45da65eff70cdbfd364b386076d09a7a3ffe32a1b24566727910f36ea72.png',
    id: '9690',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'True Blue',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1dd53c478b6eba026a5678b16f899357f54cbd7834647576667005c643504d47.png',
    id: '9691',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/707142c41294e540814acd4107db6e5f7103d6c664b7ef0a08c9f268311a76d9.png',
    id: '9692',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Seeing Two',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/832b975318cf4150410ccf6e779766e827c7017db3b397707083ead85adf3508.png',
    id: '9693',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/af5f425b1842d551ef20d19d687cfec5763b0030102204c27e3992374d1ed4d7.png',
    id: '9694',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/994f5728d5bfbbcf11ec1c71d8eb9167664c08870a3559d6cac09b2f99894ea8.png',
    id: '9695',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0292b7a45ab870aa56cdec00a9d6e5dd2623ff5308c845ebc397f5e2fd2bf23.png',
    id: '9696',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fa2eb0c9f08f50bbdf83c874221b31b9eeb91314446153ae2ff865a8abbe88c9.png',
    id: '9697',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7d7fca35db6252b67a6a648f53a1c1f3d0a9588748582d250ad0185bf333caa.png',
    id: '9698',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/29e143e5313b2aef45e768f5a352f92171e3a3e65bdb5e5c89888c0ffef39730.png',
    id: '9699',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Wormy',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/028a67ac561bda50ad42cec6e05584e4265de89e11041bd2c2785b4686debe0d.png',
    id: '9700',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Reptilian',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a24e39e139e777ef50af1823b635eb62f101610a598b0bae5b93e35cb7babe66.png',
    id: '9701',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdf214a6cd8b2bb8e14c142e436d2a7ae9a8e9ef664ab19872afe3fd15c7da28.png',
    id: '9702',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/01e9c3ababa2b45f9c7d537e6242a2a93fd97aba620cec4fb0364e09b1818e16.png',
    id: '9703',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/71a0dd1ff813d0de8e02568b1fe40c7641effbee93d68ba8e67b5568cc1b7a9e.png',
    id: '9704',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Smile',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Wild Things',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7bde9d2f6a073b93297f3ce707e792a25e39745b6ca84f7f70f88a5588a33203.png',
    id: '9705',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/072505b942d83aa72e38116f6853e170b944976192865979b50c0872cd4b47e4.png',
    id: '9706',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41d7e8b729e2c5a84284a4d886f047806a0acd356d5acd1791b626d68fbffb96.png',
    id: '9707',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Wrinkles',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2acde3f20cc9e531fe0cd92b3e57eff1536106128e0a3e0cf4b23cfeeb6c5a78.png',
    id: '9708',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bd956ba34d1dadf26ba80ff5cfd13a5cf9f0930ee10efd3af5fce64406a76a3a.png',
    id: '9709',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Bulls Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5552c77f48bed0834515bb2c8a3a1f03726d7de932b8675629b6282a54d327a.png',
    id: '9710',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acd893a86b506ab0c82e0973bf4c24f1134232eb65050d916720c46c667fbb3e.png',
    id: '9711',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/74af5a70310a860c55ea2d0a30b8046cddf5eb8d926a30f21adc7bd3759cf132.png',
    id: '9712',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eab3b4f55c5c3fd3caf3216686059d61abf3a34b485b6d0a29d7d52ba09bf5c1.png',
    id: '9713',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c21c6df2425294f6a20611d8360c28978b12cc3b8a3b7fee201da9969f7bd011.png',
    id: '9714',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8b6bd45f80ae4fccb2cfef2b69318871525accdc209f4c78614e59a56ed89c4.png',
    id: '9715',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Teething Eye',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3e766c803111cb2a45d603a6f8085f4ce9bbb3800fc4778203f43e80d6622581.png',
    id: '9716',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e984f8846319bf37cf76f4d1a9c7b0da3cb6f3ec3fdc807af3d34bc4b117968.png',
    id: '9717',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Wise Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33eee2ace91ff4779af0efa2dfd75072c5078e40e85bc56b11b4af605044cdde.png',
    id: '9718',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cobalt Kudu',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c3971638db2dff08d99e426ed32ca4fc6bcc7076158ddda2b1e83c3b1f8e7d9d.png',
    id: '9719',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Vivarium',
      },
      {
        trait_type: 'Clothing',
        value: 'Barely There Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2d232033cdac241acaa490061075fb2d65d66256ecd0e36fe7f607e9069756c4.png',
    id: '9720',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b76680cf195f5ec8bb120b1f950b0cc2e01fa8249d47bb29769dcf0e4aa87eaf.png',
    id: '9721',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b221871ffe64d18aeeb328c797a22bb57011591ed175dc77eda8e60a69907338.png',
    id: '9722',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Apothecarys Secret',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a107f5f684251363f9c27ffd06a09df584d5cf0251c8ad0c27ff714fccdcec9e.png',
    id: '9723',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d65eb865c971953c99e12f3471d020fe9f3a4c98b65857aad8a41a385023c9c6.png',
    id: '9724',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Crusty',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Travel Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/834dc26052be6c2735c3d56a3a20ebc280d76061367d9cdaf4d4fc94bd0bc8ac.png',
    id: '9725',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggly',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e66ac81535e41637ddd0d1d376babc3f6b2ce7db48915daaf3582cb302ad36b5.png',
    id: '9726',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a180d402d35626cc4e3533809f4078f5bcddf1cc59c008ec7d7bcf7e6466cad2.png',
    id: '9727',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18d219de31ecc2404a8b1487872efb24e15a4f2291ee0937ec495367538d53b8.png',
    id: '9728',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Bucket',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/94db400784bdd9bbd3ae9874cf2301b793e90cb437e0d14f1b8e231a62c87bee.png',
    id: '9729',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasterider Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/347bf96a3c66045408dd52cfb51e9e867693fb89ada9ead28b0df92505deb56e.png',
    id: '9730',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'X Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/153f3107335999ccd0bb6afa00841178277eb00b70b36c7e887a7a186a711f74.png',
    id: '9731',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Look Deep',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ac5d378da471109482d85f7e59a62503f55cdb3923d68857c9860d52789c7f18.png',
    id: '9732',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Harpy',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/33841a8899064212252050b488bd74bae927f15292954636a25338e9effbc5eb.png',
    id: '9733',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/843a9ba8e29b53b1d0385ba6aa396df34f0d4b3e359e783ac577994a02d54b11.png',
    id: '9734',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Bad Mama Jama',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e528cf77074dfeb54364c49a563e85fde2a91406cbc7968fcf828236075776c5.png',
    id: '9735',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aefcd530016de1bd5e65634f9b824743fefbce4adbdb1af13e2b7e18e6671a1d.png',
    id: '9736',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/02d8aa684e4f06ca32197420c649ead9e0b6af5baeb16d459e3b3e5e65e1735f.png',
    id: '9737',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e030bd1849fd501466fe1e9c45d0f6f6a1f9b3e31241fe01f8bd933414e8a315.png',
    id: '9738',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Peek Curiosity',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a3e14e4f7a4c0879cbb26cb237a80a1a55c295119f52ade159d6fa3ba8d23660.png',
    id: '9739',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a47804e39d1ddaee84f6e605321a611b170d2c5df97bd539e6a44dc651b7e6d0.png',
    id: '9740',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Petrified',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c9911ae9e826e8d7b59ac9920ed92fcb3c341a2d36bb8886f8ab35156a728711.png',
    id: '9741',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Maw',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/712c49e65decf3a0fc66f0892d88c855447ab073ecafe844982a263504f2b867.png',
    id: '9742',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Over It',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4ffa9945bf5a9bca2bd9621ce89b39c8d16609e5ede88a9c28a59d2e15283955.png',
    id: '9743',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6044a19d40d714a23c05c12aef426a9819c2ed18c57711e9468b31412557f028.png',
    id: '9744',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Constant Sorrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/88208a3a3f229f67379d157c9d528854680d6563d22a739a2f46588857d91700.png',
    id: '9745',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd5c79c095f0f595b6ba9a26f05967636bc9a914160c6b26a726b10e982e869c.png',
    id: '9746',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/45fe1c83c0f8dd59bfca6e68dee9702b3a9a782011ce2cd96f451f1a85e9acf6.png',
    id: '9747',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2d1d675367430fe86987de58e61ae035db8c53f1f7ecb6f07b1ff926709c03c.png',
    id: '9748',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Tranquil Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0263e56c069b222b959fdc5dae6395f2225d69224183e9214b618bff58a96a4a.png',
    id: '9749',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ce061e40b8c4f8239a90faef38ab36d1d7e38b278d3b80a15760f2d1b2089aee.png',
    id: '9750',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Web Crawler',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/99a61aa298328a3ddf55652fd199c5993435ff6ab0418d99097b0f892c923f3a.png',
    id: '9751',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Caged',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed7937a49f90c08ad547f67b8ca0f32d8d24a9587217eabc5f54cc7fe63454b3.png',
    id: '9752',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/608add6ba2520895907330f50a2fa84d210b6321f26da006df37ec3f5118bd6c.png',
    id: '9753',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Classic Mail Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b09feed8fd191cb372e5900d4bf60e70655da2cc269f187deba7031d33b059c.png',
    id: '9754',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skull King',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6837783c28a99bf4314560d34aa3dc01dcfc9cdce36e23992cbb1dd5f9150912.png',
    id: '9755',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4225f14e1ed34c660b5a9bce011d7fbaa107bd163c896d6f336675d9bd7ce34.png',
    id: '9756',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/10179c502e1dc08163d3d112bdd25ea83027c595dec364cf695e51614252b188.png',
    id: '9757',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Natures Platter',
      },
      {
        trait_type: 'Eyes',
        value: 'Mesmerize',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/70fdf725ec478f8568482921cd97ce7a678b0e32c892e2f6148647361ba87a59.png',
    id: '9758',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef6ed37ffeaf0a218264e5db2cc62fa56989ab1da2553f3511da4a48a2dbe124.png',
    id: '9759',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Cutie Pies',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/30a2ef980bfad6233f056acfb364f3008c2264cc3f29f52845e139c1c25f1f56.png',
    id: '9760',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/289e6734f63cd610908e273f64adbd09136c91b7082fee9421c63f2d50d900b5.png',
    id: '9761',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/014e78af7a5d5bab67dfc541231a22a5dfa2641e7c43ee11d7d6062a1eeee5dc.png',
    id: '9762',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Kaktus',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/41fc183657199d85836f93dac4c4529ad648e45fe9b8f7268b8fd8bcd31d115d.png',
    id: '9763',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookalike',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98bca4c841f3ca9d3e0859c7e865578a40af384db7eef05206932bcd23d48465.png',
    id: '9764',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Flora Smock',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e7e32224a811189d268219c56b55f0ae15f9679592c6e4d3bebbd75169ceef79.png',
    id: '9765',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1277053a31fab957728ecbb99d1ff5dede4325d6922c961f36fbfcbb8f71e517.png',
    id: '9766',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Chameleo',
      },
      {
        trait_type: 'Core',
        value: 'Secret Ruins',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12260ed0834f903c7bd9fa5ad0c79c69649bb93b76d5daac2381aae5da9913fb.png',
    id: '9767',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Crystal Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c4ab8d21867bb7e254d07f0b4a5ea3c9df86750fb7ed8b9c9da7570389e52ce.png',
    id: '9768',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b5a17b75a3aaf1b18a3a7f07c1729ba8a87be9c080323cc560c5f24d36c7575d.png',
    id: '9769',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Droopy',
      },
      {
        trait_type: 'Eyes',
        value: 'Toxic',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dacd2344b703c972471da8fc2ef16f28e9832bf224270b1387266f8412217002.png',
    id: '9770',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Skeletron',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3bc6ad1ced83b3e38930e29d5fa951d590e324421c0fabd752c0fb8d57cface7.png',
    id: '9771',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Globetrotter',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d1e003163cfd4ec46acb3fedb2df1ec51b172b48e9d7926087dffd4bf7e6a99b.png',
    id: '9772',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c86b05057bf57af308b8fba9f2080c55c4d0b6a99ce04d3dcc05909238d51c0.png',
    id: '9773',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Veggie',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Iron Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5fd6036a18ff597f7852542ad4d65697ffc618b0f0f86807ab45332352108797.png',
    id: '9774',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f4876ae15464ae63e3a987732d3ee5c2443749979357fc0aa40c5a70f08734aa.png',
    id: '9775',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Boomerang',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/626896ced4d91b0af3baf8fbcc5d6c6784474f448386e13484ec73b523970755.png',
    id: '9776',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Pickles Pike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/15bc36591eae0950885e0e7578797ba8b0411ecedf594327c691f19fa4fa93c4.png',
    id: '9777',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ed3c04fae39302bd4d979faddcf44759f18144e70675a12555072734d90dbea5.png',
    id: '9778',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aqua Elf',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3c8b43506be6bdd08d0e78f2909e4310c0777d4dde560e2006bbaaa225f0aa93.png',
    id: '9779',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Deep Sea Spiked Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a476e916af89370540d733d0335d7b6c3797bc8ddb639535f1fce2bc866a3e3.png',
    id: '9780',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/891b9f4428485b0737020d093bddd2cedd13e34e9bd775d82cde2b8b37fbba88.png',
    id: '9781',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a2cea984636f14a6c76fe34b387a94ebb19e60e8ea1489a5c250b1367ac8973c.png',
    id: '9782',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Is Anyone Home',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e624c2f387958134758416d123d487bf1d415ccc17535f25635cae9f435e5d8.png',
    id: '9783',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Twenty Cubed',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Hunters Respite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3070b35fe764dd5084fd655abbbded1bc7e71922a562f3b80e6b1249c490df6e.png',
    id: '9784',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/06058380b73ee29eb279966818693fe820c5fbe29e02676397289594d9816289.png',
    id: '9785',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Coastal Scavenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca19a33cd305b5f4f1d76c9b22543313355afc82d23b6df7e1a9c02896c1aed2.png',
    id: '9786',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spiked Wyvern',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Inside Out',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/03bf92bee9099f711fe560100d38e2e44ca684f0e73d6b7117538c41bfa6c2ed.png',
    id: '9787',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Whirlpool',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d8fc697b46cca62c1c081243517fdcd948a5b2430f02020c53948789aef564ce.png',
    id: '9788',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1998d943cab9bcd4cd3648be29473c56ad00dab2dde7199df050d2cc90086243.png',
    id: '9789',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sunkissed',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/616890c8db190ed947941e40ff388037d923de5a9aad96504082fe2538be0976.png',
    id: '9790',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5b66e5d788edeca66ef023e2c28ed30a6c0c2aca1bf83d63862a1a5553fbf66.png',
    id: '9791',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Oozeguardian Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d3de17b77f895010a77df7adebab580d7faa557739f3f5e5fbf856797b9ab18e.png',
    id: '9792',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Exhausted',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Abyssal Trident',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/65486b00df9b866a408f5a4336173d1eb774507e277d7d14dcd060a73c0c3389.png',
    id: '9793',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/08b7a678767e23624a86577f39e91fffdb7466f8c05d83eeefa0872382646428.png',
    id: '9794',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Emotional',
      },
      {
        trait_type: 'Core',
        value: 'Dino Egg',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/818ab83bc845a49d77cc2161763c0789a2c6515f94fe2c9cab95b44be485829d.png',
    id: '9795',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Bharal',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Focus',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Wisps',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fc47e3992b433fad4cccb7a4119b3f0af38d7b7286be6c2bc51f7fc1021fdcc2.png',
    id: '9796',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Villainous',
      },
      {
        trait_type: 'Core',
        value: 'Lava Lamp',
      },
      {
        trait_type: 'Clothing',
        value: 'Nomad Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wavebreaker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c669ece6c96a1477cb9a5539cff879234e48fdbe393ab1a97aebee856fa07b50.png',
    id: '9797',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Amphiboid',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e29b2ec619f06d42b7507c1d530cf81d1f3479ae8442065dd2978d4f6a4c5d06.png',
    id: '9798',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Twins',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4803b32e70e8a33be13d2d2f9af4c585e97f8ef98ffcf136146d7d5b7105681.png',
    id: '9799',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fish Bowl',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9caa7f34439889925cfa7e8e1e207b17926a0553726c4121e15082a8c2f6093d.png',
    id: '9800',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/24ce1ebf3ab2a0cdd1a5f5340d20d4dd8023c298088069edadc551399fd28635.png',
    id: '9801',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Another Memory',
      },
      {
        trait_type: 'Eyes',
        value: 'Here And Everywhere',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Weapon',
        value: 'Crimson Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6d3415af92396d12c1938c2dfd54936e9b76d3301adace3b51602a35ce0d6b01.png',
    id: '9802',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e8288448c26b8a3caa0d92b5dd5fae909189fa1790206b51ef904e306cc10c28.png',
    id: '9803',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fdb8b87ea84eb060098141e51af7ff2f9829d68f22957fb344ed91063849f7bb.png',
    id: '9804',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Orangetan',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b66f227fec8282e555c906a84abf2132d71f59402e6bee6d88d1a013eaf8305.png',
    id: '9805',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
      {
        trait_type: 'Weapon',
        value: 'Death Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e4ef101f9c6718484492ac6b069c2156420289c12be5b3608658a07c092e2e68.png',
    id: '9806',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8ab1f7c56eeab949333b00180ff00f5a2a7f74957dd45d2a1beb517a92bfb669.png',
    id: '9807',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Amoebatopia',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e79040043ec8731ddc95064ffed6e17704afca17085163be78b774b7a3383ba5.png',
    id: '9808',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Horned Lion',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Outerplane Toga',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1422bbe767bf50e31ad48bad059c064af25b3a24461679ef4bbdb36ee46f45c4.png',
    id: '9809',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Diving',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/349158adf9bf29aa4c190cec5eb9bac2b05b56a59f711b987dbf9db3a77e821f.png',
    id: '9810',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Storm Tracks',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ca5e73bfddd8ea7b41602641ab83ffd9979e332f09662a425b0c75723b432d1.png',
    id: '9811',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Omniscient',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Hardened Leather Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a781b459feebdecef4200a401b8411172189a858097fb50a8cc104ba321e1141.png',
    id: '9812',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6a482898cecd05b717adceafeb2f7214029568736d7dbed54e0dadbc75fe4552.png',
    id: '9813',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Coral Delight',
      },
      {
        trait_type: 'Eyes',
        value: 'Insomniac',
      },
      {
        trait_type: 'Core',
        value: 'Berry Bomb',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53682d6bf3db4c190a0d74814fbbaa4ac811cea6734237eaa55ab7ebdde8adc9.png',
    id: '9814',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Lizard',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/19497c6f20a2ce7c566cbe0bf3d3db5f83f430fc12a0ef61cabde1370cc58f92.png',
    id: '9815',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Third Eye',
      },
      {
        trait_type: 'Core',
        value: 'Banana Wrapped',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ef6322719cd67c94c2b9ef1a2d3b6684fa44c94863177c23f6c64ea93e50bb02.png',
    id: '9816',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Minor Light Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e3f5b6b1562dd9ab46c49042716d749d427644e0abfa3a92fd73972e3e8209da.png',
    id: '9817',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Pumpkin Teeth',
      },
      {
        trait_type: 'Clothing',
        value: 'Cobalt Squad Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7cd998a5c7380756f130875451e62ba69866d6885e91e7e581d3a3dd0f16cf0e.png',
    id: '9818',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8897a072132efe42ee3e5372241a6d42251d657e0778207145db4efb8b22a20f.png',
    id: '9819',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Stare',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Cat A Comb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba85283d66a9fa85181ea5039ed5aca0d063c394136324e91adf9f0bc9bf8e83.png',
    id: '9820',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Speechless',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Duster',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Confessing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9ac2036e677e8635be572938b092ecb20c246bf9435b59f1b315303cb09a5927.png',
    id: '9821',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'See It All',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bfb626c041f85f41314ed2631fefde9d6e57aa866812666043e99c9737f034d7.png',
    id: '9822',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Triple Threat',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Oracles Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9daf21aebec44b3b55fecf97541efa3536be6c32f54b7b65bdec7572a48cbb4.png',
    id: '9823',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c00f2326af14cdac2477f16f84cf7c0ac6b7cce48f2cad616394f2d04be66d39.png',
    id: '9824',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Alinta',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/22720a24371ba2cd9eccf767de794f85592a2f81b701e9b3beede348b6cbdc26.png',
    id: '9825',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Tired',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Clothing',
        value: 'Petrified Bands',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/798a40277966d326d79b651de59c304174305f2221d00c86974b5c8de429e1c2.png',
    id: '9826',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'The Bloodletter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b86128f55df3ee8288cdeb67164fb5bd8e5ee206aac6528bf4e79be613e95058.png',
    id: '9827',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tested Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1379da2bd90f270920012cd444bf7d89111617d9219a4fe9b5a28af896237308.png',
    id: '9828',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Furious',
      },
      {
        trait_type: 'Core',
        value: 'Caged Geminite',
      },
      {
        trait_type: 'Clothing',
        value: 'Armor Of Carnage',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6f755cdb33130174dfe16f77c4dc3d93393cf21052ee495b565aecd399f1b4e.png',
    id: '9829',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Sewed Wide Shut',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'The Spatterer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/156a272557bb7a7a057536cd4693f063b7e1a54e97b4b2582139a7d2c18f799c.png',
    id: '9830',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Two Tone',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7ac14fe12705f0a9c6ef82b72938091ee8b1c92658c41f72a7036ea5620aadb0.png',
    id: '9831',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/040b024fc5dee03f6f990978d48f70c2f8b0aa149a192ddc350fd1f6047b95b7.png',
    id: '9832',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1669b67b9403b735cc6defa80aed75c56879f89585988d77e63245cf1f41365a.png',
    id: '9833',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'Hypnotic',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5d1012fbb459f19c62dbbe6a5c2dc89c3347bfe1a82119fe35160aacea1df23.png',
    id: '9834',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a4abedea09c9a8966d31de03e61860186827f669fb3e77df9e699b2eaa1f9fb6.png',
    id: '9835',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8f033c7bd8b656c239f5d57cef233cbc5fb740983bf06d2423e54acfc95583b3.png',
    id: '9836',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mummified',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fd24d90f741b065ddd2753dd9c02248009fe09ca6b6db7a43f9b47da3abf8d3b.png',
    id: '9837',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Tripartite',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Sovereign Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d0589b7ac20b81825debb8d0716af0acfcc04bdab0575729bfaf86b215cca56d.png',
    id: '9838',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Butchers Belt',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f5c33f4449a837bb16b1a247886ffb907d89e30f1dfe90edb9078394ab98c1c9.png',
    id: '9839',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sluggy',
      },
      {
        trait_type: 'Eyes',
        value: 'Ancient',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6b75b65e4fcaf51885dbb193a7f89adaf28396c37e1b55d6013aee5bcf1f4f64.png',
    id: '9840',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Sturdy Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Dual Time',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Wanderer',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9f3e97573cfeacf86396e499a87a599a343b7e8cd035ddb2ad8e4b95bde717b.png',
    id: '9841',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Twisty Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e2d4cbf01cc92223fe9e7250a1702867094a92d17ecb2aa1f240f04ff3b04cc9.png',
    id: '9842',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'John Doe',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Grey Matter',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e9895f6bf56d7cb2149193946fad950e3050946e8d81426bc6d867bc3ad1385b.png',
    id: '9843',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Bud',
      },
      {
        trait_type: 'Eyes',
        value: 'Tiny',
      },
      {
        trait_type: 'Core',
        value: 'Woodland Guardian',
      },
      {
        trait_type: 'Clothing',
        value: 'Arcana Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d1d7fdff161ee16fbe27e1f9d791fbc4ccf00683b2c7e92d4a4047096b6d958.png',
    id: '9844',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Gemstone Paladin',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d896893420b6683872b0d35f8eb2f7813cb5a81f44b525a0307a1495d4c75be.png',
    id: '9845',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Arachnid',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb472b2511722b1d2c5eaba1e1f752706c4260efbfc331d69506f434d2b05e55.png',
    id: '9846',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Cheese',
      },
      {
        trait_type: 'Eyes',
        value: 'Black Hole',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Starry Beetle Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Fancy Pickaxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ace8b4a83863f43533e332b9ad88cbaefe2c042a28ef553ddad1239679de85ff.png',
    id: '9847',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ba45dafee55e0b3930c86124ceb894a58e6512a9a7565e98f6e97ca3c7b34eb7.png',
    id: '9848',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Elemental',
      },
      {
        trait_type: 'Core',
        value: 'Swamp Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b59c295f649bc8638caad111239a745489a146a765e2b6be6e756197561e53b6.png',
    id: '9849',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Mortal Arrow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5dd6d4e230613fa730cd27bebb4e9e9bfcf6a812bf714d33433db7d2cba62925.png',
    id: '9850',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Fortune',
      },
      {
        trait_type: 'Core',
        value: 'Sunflare',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f0d09bce752c219b830a7bb97f4b39eed63d3d9be128f114ed9da5c992067928.png',
    id: '9851',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Somethings Fishy',
      },
      {
        trait_type: 'Eyes',
        value: 'Stubborn',
      },
      {
        trait_type: 'Core',
        value: 'X Ray',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Anguish',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec862d286f2f266b10fdba98a070546aefe71f412f087261b6465ca01e4ae8d7.png',
    id: '9852',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Warriors Journey Garb',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9dae633ec54a1c21407d78dbd8fc851341da610e29158d14639f4010c0d380df.png',
    id: '9853',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Loggin Noggin',
      },
      {
        trait_type: 'Eyes',
        value: 'Angry',
      },
      {
        trait_type: 'Core',
        value: 'Husk',
      },
      {
        trait_type: 'Clothing',
        value: 'Brawlers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Sabrechomp',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd08c233b72907605373642d3cc8d96cbe0a0d18874520e1fbdc28d788411e40.png',
    id: '9854',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Grassland Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Iron Wing',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e967875382970debe97df3d8a0314f0778e667f9a64c35da419b881117fcd6a7.png',
    id: '9855',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Ascended Guard Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1551a27e711899f6a84380ee819a9c2b72db90672f18758d2b2bba18d7cc5235.png',
    id: '9856',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Nuts For Nuts',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Review',
      },
      {
        trait_type: 'Core',
        value: 'Petrified',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4590a33a4e401fa0cac9c365f37af6fb99b0b3f188974f22285c102422e2ff90.png',
    id: '9857',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jade Carapace',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Stone Holes',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/db0a049672e368cdd18f2322b2ac8c3a7ba8e38507345200826e00e007e06b9d.png',
    id: '9858',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bleached Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/80585d561211067fd2c9c88f12a1540ffbee61e5d44380be4b45f01155075d0f.png',
    id: '9859',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Release Me',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
      {
        trait_type: 'Weapon',
        value: 'Dread All',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/132c9237377535b569bdc875f7079d31bc2b0be9cdf7abb8217f31c7df89c0f6.png',
    id: '9860',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Jelly Beans',
      },
      {
        trait_type: 'Core',
        value: 'Floating Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee4faac06476d36451e7db811c60ff8ebc444dee64393c194312d5ba4ae9f2f9.png',
    id: '9861',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Demon Spawn',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/64078440f77b2884f4ac387b7e89d2c76daa476ce48a9b2cf56d5ca81e2606fd.png',
    id: '9862',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Impalas',
      },
      {
        trait_type: 'Eyes',
        value: 'Tri Green',
      },
      {
        trait_type: 'Core',
        value: 'Pink Disco',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b39dd562b765dbc71d36f0c8c5bd0fb8d31180d3b2b48e5496ea7987e47844b6.png',
    id: '9863',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Winged Fiend',
      },
      {
        trait_type: 'Eyes',
        value: 'Deep Stares',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7d79cea666d8ec81e074733c840cc2efc3c1ef436d906a5850fccf0a297756a6.png',
    id: '9864',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dual Slick',
      },
      {
        trait_type: 'Eyes',
        value: 'Cyber Sight',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
      {
        trait_type: 'Clothing',
        value: 'Wanderer Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d897394d5447b3850e0e072f87bf0657a231a9049bcd1159ae37dae3bbbd44d.png',
    id: '9865',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tri Vent',
      },
      {
        trait_type: 'Eyes',
        value: 'Stare Down',
      },
      {
        trait_type: 'Core',
        value: 'Desert Bot',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a771f24eb0a5a472e5ce3b8873c5d7f5c4160f1ad23cc223da3701f9e063e68b.png',
    id: '9866',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Keep It Chill',
      },
      {
        trait_type: 'Eyes',
        value: 'Simple Math',
      },
      {
        trait_type: 'Core',
        value: 'Pure Sinew',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c0d336937a6beb0bab6fd22e5b9afd723385847c3e59fa142404f9b739b9adae.png',
    id: '9867',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geo Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'Droopy',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0615db20ee36cf88265ff09a8ff5635135d8c94dbaf440f771b26a32e81f0d92.png',
    id: '9868',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Bit Droid',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Mummy Wrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/338bf190c3627c09a16a0c552f5f329b896c9301a7598901959b860cd937068d.png',
    id: '9869',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Manta Ray',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Space Blob',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/42a4eb240174cf6129861e2fa5ae4f6aa9599b8fb3c7866ea6d1ac8a850c7a5d.png',
    id: '9870',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hoot',
      },
      {
        trait_type: 'Eyes',
        value: 'Analyze',
      },
      {
        trait_type: 'Core',
        value: 'Koda Bear',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Badlands Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bda886dce6d091ed6ba82541be6882ace497610cef80907be8a6abddb948705a.png',
    id: '9871',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'All Bite',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/032cc8e646706d8cc0a37bfa3ac0dff42d2f9a71d71ac6a18eb2066434435b96.png',
    id: '9872',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Chomp Chomp',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Zebro',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0cda7153cc1d55fbf6f8df163bf1a67aa68bcf3ff222961342fb3ce17255f3d3.png',
    id: '9873',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Lookout Below',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f15287b038e43b50665ae184949de8a0ea3f7305494b69f4e9bfc4b4c2d7f976.png',
    id: '9874',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Axe Grinder',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypcylco',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/57773f4d6c72c2882b87f2a43a56d4c00cf940231d3acb145dc4c5156e6fb9fe.png',
    id: '9875',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Screwhorn',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Explorers Outfit',
      },
      {
        trait_type: 'Weapon',
        value: 'Batter Up',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ff194a15277be26e10e9c703c2283a4f3b546982ca7b705115d5fe2b4b894c30.png',
    id: '9876',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Copper Coil',
      },
      {
        trait_type: 'Eyes',
        value: 'Boney',
      },
      {
        trait_type: 'Core',
        value: 'Cushioned',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e86b230f2813411fd0b6d21cdf6f98df7764c6f8470255604f786b38ff8e5fc4.png',
    id: '9877',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Intense Focus',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52162a793d5e12f64384fca933f47e8ec39599494ad5590a18f099b799d9d429.png',
    id: '9878',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Trance',
      },
      {
        trait_type: 'Core',
        value: 'All Smiles',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4b8d7f170ea74e5fed51c993d01fddee115bba6367df79879a96d5c9d931407c.png',
    id: '9879',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Creepy Crawly',
      },
      {
        trait_type: 'Eyes',
        value: 'Crystal Grinder',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26162395852598ec63fd84d96c8a0f7b3d6366e9d98dd4c0af882127cdface3e.png',
    id: '9880',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blazin Hot',
      },
      {
        trait_type: 'Eyes',
        value: 'Winged',
      },
      {
        trait_type: 'Core',
        value: 'Basalt',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c4cc160a76d4896e553aa2ccb3e92bbafd50a5d52ceafcd2b056452b828c213.png',
    id: '9881',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Devils Advocate',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Magma Runes',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/acc93f6403c44a5d024b4854cd2ea35be91c39e8e4f1a78ea2b64c7406aa8af6.png',
    id: '9882',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Reef Dragon',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Fanged Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eb1e9fe5cfb5fe1b97fc334338f6bd72b8d12833d7178627883a03582a8fc6a2.png',
    id: '9883',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cave Crystal',
      },
      {
        trait_type: 'Eyes',
        value: 'Stink Eye',
      },
      {
        trait_type: 'Core',
        value: 'Carpet Gorilla',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee1d083479b2fccd3bf4e7c2b3bcefa97b6f5b129ff3b04de031bed1147615a4.png',
    id: '9884',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Muddy Explorer',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Spacesuit',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Explorer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cad2fad4d50abda83784ee5d81bb6a52211f58412b711c9d2f5f4a9ebd501da3.png',
    id: '9885',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Drill Sergeant',
      },
      {
        trait_type: 'Eyes',
        value: 'Peace And Love',
      },
      {
        trait_type: 'Core',
        value: 'Stoney Pecs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/105862acfb9e026c2342decfa2a08881847551305f90c3fc55b2d172c91e222b.png',
    id: '9886',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Cauldron',
      },
      {
        trait_type: 'Eyes',
        value: 'Psychic Stare',
      },
      {
        trait_type: 'Core',
        value: 'Purple Disco Monster',
      },
      {
        trait_type: 'Clothing',
        value: 'Stone Plate Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9b0e2935133f8fc0f4e30a9a880d85e339232f287ea63d480bc5ef4aecedb891.png',
    id: '9887',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Stubborn Ox',
      },
      {
        trait_type: 'Eyes',
        value: 'Genis Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Bubble And Toil',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Serpents Hand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f21f8dfb56e796e2f66a0165fa5ceeccc4f6c860ec6af392313ebf6157183fd4.png',
    id: '9888',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fairy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Casper',
      },
      {
        trait_type: 'Core',
        value: '8 Knit',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Unraveler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bde4d477672a6f548c23c3d548933bad68d8730e14ba7001441f3e02ec3fae4c.png',
    id: '9889',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Green Gill',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Monster Smoke',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c21cfbdbf4bbf90f00608221a61acd90dbebadf56a789f582c47acab8102cf9d.png',
    id: '9890',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
      {
        trait_type: 'Clothing',
        value: 'Tough Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/945a9878456e8ef1154ecb30ca4f56d55343896f05cd76641f7029eac7c51f22.png',
    id: '9891',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Viridescent Hero',
      },
      {
        trait_type: 'Eyes',
        value: 'Frigid',
      },
      {
        trait_type: 'Core',
        value: 'Stonebase',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3198549902a6d0e5022a84607b1b1d8f9ab2d0f95dce825cd62e64c15358efe3.png',
    id: '9892',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bugle_Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Spotty',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/05845c0e8f3d54ff2c13fe1b07227bbc0a293f53c228a5d05109cd3c96dfd109.png',
    id: '9893',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Take Flight',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'Leaf Me Alone',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/aefccb04169e5b8f29720c188a17b753ad1ca25ad842516a5bea00f769dc65dd.png',
    id: '9894',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Infinite Vision',
      },
      {
        trait_type: 'Eyes',
        value: 'Maze',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8fdb4b037f24afa7e153b32e7615ebcbbea6c6f82b94f6871e3ecb928c4da6f5.png',
    id: '9895',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crystal Horned',
      },
      {
        trait_type: 'Eyes',
        value: 'Twin Pits',
      },
      {
        trait_type: 'Core',
        value: 'Onyx Doom',
      },
      {
        trait_type: 'Clothing',
        value: 'Knight Mare Armor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fbc1ccdef5225357e9c37dd994a71c582c1b93e223a96e0cc94218d4ee986382.png',
    id: '9896',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Foreteller',
      },
      {
        trait_type: 'Eyes',
        value: 'Shocked Blue',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9771df12b0fd929892f89e25845886a8ee305d4fb5b788bd1ffe9178d88ab89.png',
    id: '9897',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scorched Brain',
      },
      {
        trait_type: 'Eyes',
        value: 'Crag Glow',
      },
      {
        trait_type: 'Core',
        value: 'All Sight',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/58023947af1d8f0874ae40afb377e87fd69fd0f4304b054427720ff1092160ad.png',
    id: '9898',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Great Kleos',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Tide Roller',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31fffa04980ed8308a6cdc1964a7c93cf4e7a0ea8e156bf3216232f9eb343272.png',
    id: '9899',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Scraggly',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Woodland',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d6e3a314635d5322f073d2192401803dc3d609baab1576fcf24828afb5bea425.png',
    id: '9900',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Moss Boss',
      },
      {
        trait_type: 'Eyes',
        value: 'Mirestare',
      },
      {
        trait_type: 'Core',
        value: 'Bog Water',
      },
      {
        trait_type: 'Clothing',
        value: 'Bark Moss Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crackle Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6297db9db7711dc088b75ce16a58d05ff1c545a7f54ec7947de5b87c018078f3.png',
    id: '9901',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Eye Cid',
      },
      {
        trait_type: 'Eyes',
        value: 'Pink Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Third Eye',
      },
      {
        trait_type: 'Clothing',
        value: 'Gloopmire Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Gaze of Mysterius',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea24c5df48cb865f79a87e49d72bb483d767802bb5e2cc01e978c60a3ab237b8.png',
    id: '9902',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Glimmer Sprite',
      },
      {
        trait_type: 'Eyes',
        value: 'Dark Matters',
      },
      {
        trait_type: 'Core',
        value: 'Luminasaur',
      },
      {
        trait_type: 'Clothing',
        value: 'Neon Streaked Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Glow Up Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/35e89a2deb593ca9e9fa9cd094d7e04c515f0b094cb65a6bdfc2ed310db113cc.png',
    id: '9903',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Slime Time',
      },
      {
        trait_type: 'Eyes',
        value: 'Slick Seer',
      },
      {
        trait_type: 'Core',
        value: 'Flan',
      },
      {
        trait_type: 'Clothing',
        value: 'Deaths Call Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Slicer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0e2531147227f8df41d2514fa3b2495355c2c09d4cd97cd9aba32d8c12ac9030.png',
    id: '9904',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Grime Time',
      },
      {
        trait_type: 'Eyes',
        value: 'Slimey Terror',
      },
      {
        trait_type: 'Core',
        value: 'Jelly Belly',
      },
      {
        trait_type: 'Clothing',
        value: 'Skull Strapped Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gunk Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7e4601595ebe6a739a4e2db223709f69e7d22df55271d476a1ca6246260b6780.png',
    id: '9905',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bubble Boil',
      },
      {
        trait_type: 'Eyes',
        value: 'Toil And Rubble',
      },
      {
        trait_type: 'Core',
        value: 'Bloatus',
      },
      {
        trait_type: 'Clothing',
        value: 'Sludge Grime Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Malfunction Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/23325f1e95782fe9fa9d33b3f8017de966a2da71e150f03fe67af5dfe7e89149.png',
    id: '9906',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Emperor Ice',
      },
      {
        trait_type: 'Eyes',
        value: 'Glacia Glare',
      },
      {
        trait_type: 'Core',
        value: 'Snowmelt',
      },
      {
        trait_type: 'Clothing',
        value: 'Icicle Shard Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shimmer_Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/025f1d19bb144e68975531efc3346111b0aba5c468c92bcfa44f28ea5d8c3442.png',
    id: '9907',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Rough Puff',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Gooey',
      },
      {
        trait_type: 'Clothing',
        value: 'Reinforced Mallow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Slimey Sword',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9f319723a4a83e732312a2b2b5f34c190c6bf78fca4abbca5a84de7d985b8612.png',
    id: '9908',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Goo Goo',
      },
      {
        trait_type: 'Eyes',
        value: 'All Seeing Ooze',
      },
      {
        trait_type: 'Core',
        value: 'Grooze',
      },
      {
        trait_type: 'Clothing',
        value: 'Executioners Uniform',
      },
      {
        trait_type: 'Weapon',
        value: 'Smoozitar',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/52c726fa1d384dac14d78b6ade91b9a9aa33cc18f3b8f6278438d63c114a341a.png',
    id: '9909',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Aweful',
      },
      {
        trait_type: 'Eyes',
        value: 'Dimensional Sight',
      },
      {
        trait_type: 'Core',
        value: 'Dissolve',
      },
      {
        trait_type: 'Clothing',
        value: 'All Seeing Pauldron',
      },
      {
        trait_type: 'Weapon',
        value: 'Staff of Sentience',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ecd32ffdf78a39dc68310690a6987a118ae64dcdbdc351d150bc7df2ca5e9d27.png',
    id: '9910',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Commander',
      },
      {
        trait_type: 'Eyes',
        value: 'Golden Grill',
      },
      {
        trait_type: 'Core',
        value: 'Doubloon',
      },
      {
        trait_type: 'Clothing',
        value: 'Daybreak Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Lights Edge',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/d2ee8e7deb904fd84310eef256e7a86d43c609cb0a0a2dacc716102f0f109e8c.png',
    id: '9911',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hot Head',
      },
      {
        trait_type: 'Eyes',
        value: 'Interconnected Flow',
      },
      {
        trait_type: 'Core',
        value: 'Bellystone',
      },
      {
        trait_type: 'Clothing',
        value: 'Flame Licked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Striker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1c2fb82e20b493bceba816c3ba77c126f772b5c043512784518847457b843cbf.png',
    id: '9912',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Caustic Visage',
      },
      {
        trait_type: 'Eyes',
        value: 'Hydrothermal',
      },
      {
        trait_type: 'Core',
        value: 'Geyser Arms',
      },
      {
        trait_type: 'Clothing',
        value: 'Hot Rod Bod',
      },
      {
        trait_type: 'Weapon',
        value: 'Sulfur Triton',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f550f077965df5acdad78a32872ca50fc5ff57e4db29063c6ff70761d1ea3269.png',
    id: '9913',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Permafrost',
      },
      {
        trait_type: 'Eyes',
        value: 'Ice Cage',
      },
      {
        trait_type: 'Core',
        value: 'Blizzara',
      },
      {
        trait_type: 'Clothing',
        value: 'Frost Fur Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'The Axicle',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c4f7b5634e565108c0edf246d5223c5133f138493ea29370c99ffcb3f560dff8.png',
    id: '9914',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mallowrium',
      },
      {
        trait_type: 'Eyes',
        value: 'Smore Smudge',
      },
      {
        trait_type: 'Core',
        value: 'Molten Melt',
      },
      {
        trait_type: 'Clothing',
        value: 'Toasted Mantle Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Goop Fork',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/2e0219f8ec5eef5198cd5d2d81486d1402671c512fd58cae453d2ded1f16f51a.png',
    id: '9915',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Incandessence',
      },
      {
        trait_type: 'Eyes',
        value: 'Gemini Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Glowsaurus',
      },
      {
        trait_type: 'Clothing',
        value: 'Coral Core Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Wood Ear Chopper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bf28dfd3abef15c956c5f961de29cc72da27df002f8b48ad587087f3aba22bbe.png',
    id: '9916',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spike Stag',
      },
      {
        trait_type: 'Eyes',
        value: 'The Gates',
      },
      {
        trait_type: 'Core',
        value: 'Molten Core',
      },
      {
        trait_type: 'Clothing',
        value: 'Cauldron Complete',
      },
      {
        trait_type: 'Weapon',
        value: 'Axe of Descent',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/703b1b092a48640c5e11c7b650668fe598b55af3768fedb97da636d8021cd527.png',
    id: '9917',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Crimson Stag Beetle',
      },
      {
        trait_type: 'Eyes',
        value: 'Glazey Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Grime Shell',
      },
      {
        trait_type: 'Clothing',
        value: 'Grimace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/20d163c724e984bc0eb63477577d0c42210fab80cae33f578528a403d42b87eb.png',
    id: '9918',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mushyanna',
      },
      {
        trait_type: 'Eyes',
        value: 'Veiled Look',
      },
      {
        trait_type: 'Core',
        value: 'Reef',
      },
      {
        trait_type: 'Clothing',
        value: 'Gilly Cloth',
      },
      {
        trait_type: 'Weapon',
        value: 'Blossoming Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ca04e85e0705b50b51d7352a91109b7b5de4b681c210503ece8c6e7ca6be8b51.png',
    id: '9919',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Blossom Top',
      },
      {
        trait_type: 'Eyes',
        value: 'Adorable Intake',
      },
      {
        trait_type: 'Core',
        value: 'Glimwrap',
      },
      {
        trait_type: 'Clothing',
        value: 'Dance Of Petals',
      },
      {
        trait_type: 'Weapon',
        value: 'Magnolio Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9117769f03374af377cafdaa67e97718261f31ec26bb224477ee0a1d7adbfae8.png',
    id: '9920',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Petulant Belle',
      },
      {
        trait_type: 'Eyes',
        value: 'Hypnotic Groove',
      },
      {
        trait_type: 'Core',
        value: 'Sorrow Spore',
      },
      {
        trait_type: 'Clothing',
        value: 'Pileas Gown',
      },
      {
        trait_type: 'Weapon',
        value: 'Fungus Whip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/955ca5b4f54a53c396a299625fdd880c172b0338a617bf40d449ba6e64a1db6d.png',
    id: '9921',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Temple Growth',
      },
      {
        trait_type: 'Eyes',
        value: 'Avian Insight',
      },
      {
        trait_type: 'Core',
        value: 'Ancient Shell',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Merchant',
      },
      {
        trait_type: 'Weapon',
        value: 'Traditor',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/968ea41722065dca862beacfb98ab07ff0036947c748e36aa1a0ac68a7d2922f.png',
    id: '9922',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Runic Commander',
      },
      {
        trait_type: 'Eyes',
        value: 'Eternal',
      },
      {
        trait_type: 'Core',
        value: 'Spelunker',
      },
      {
        trait_type: 'Clothing',
        value: 'Desola Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/55584878fdd60162d34479314f5988f76b02f7838ae96262d60da08c650abd70.png',
    id: '9923',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'High Ruins Priest',
      },
      {
        trait_type: 'Eyes',
        value: 'Pinball Attention',
      },
      {
        trait_type: 'Core',
        value: 'Ancient Watcher',
      },
      {
        trait_type: 'Clothing',
        value: 'Consummation Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/abd0b036b1e3ea64be277a9b2b4fcd6e10e675a55ba89ca0a20128f9304924eb.png',
    id: '9924',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Glistener',
      },
      {
        trait_type: 'Eyes',
        value: 'Special Guest',
      },
      {
        trait_type: 'Core',
        value: 'Threadbear',
      },
      {
        trait_type: 'Clothing',
        value: 'Infiltrator',
      },
      {
        trait_type: 'Weapon',
        value: 'Dark Axesaw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0d8a030ac551ccd319d71d92afd58d9b0a6d95106a364ba5dbfcee16c96cad05.png',
    id: '9925',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Medulas Whisper',
      },
      {
        trait_type: 'Eyes',
        value: 'Darkened Foresight',
      },
      {
        trait_type: 'Core',
        value: 'Biolumey',
      },
      {
        trait_type: 'Clothing',
        value: 'Direplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Column Cleaver',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/fcb8da8d30133cd06b0b37f54928253ed99d958c6beaae0044d81ce14722d087.png',
    id: '9926',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Prism Visage',
      },
      {
        trait_type: 'Eyes',
        value: 'Unistone Green',
      },
      {
        trait_type: 'Core',
        value: 'Prism Prison',
      },
      {
        trait_type: 'Clothing',
        value: 'Pyrite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Glimmer Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a789bee466ad402636588cd9e08d833eaaaaf97caf9a030194bf85919f6a8b53.png',
    id: '9927',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Gemmo Dome',
      },
      {
        trait_type: 'Eyes',
        value: 'Rock Bottom',
      },
      {
        trait_type: 'Core',
        value: 'Amethyst Skin',
      },
      {
        trait_type: 'Clothing',
        value: 'Malachite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Crystal Stabber',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/eec63cc3c06ca0f4e49606bce313ccbfcdf56bb24b85cf36f33c2d8eda82945c.png',
    id: '9928',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Glimmer Top',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Fright',
      },
      {
        trait_type: 'Core',
        value: 'Cavernous Loot',
      },
      {
        trait_type: 'Clothing',
        value: 'Jeweled Adornment',
      },
      {
        trait_type: 'Weapon',
        value: 'Gemmo Hammeraxe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c5636883b5bf6b2b45840896474b9aca10eefea206dc91c0776d09be13c9e6e.png',
    id: '9929',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'King Red',
      },
      {
        trait_type: 'Eyes',
        value: 'Globular Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Red Button',
      },
      {
        trait_type: 'Clothing',
        value: 'Adventured',
      },
      {
        trait_type: 'Weapon',
        value: 'Explored Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0497c948862b2c393b0d2af08b9a1b870d09e2b38dcc03bc20f128a3cbc4a1fd.png',
    id: '9930',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'High Collared',
      },
      {
        trait_type: 'Eyes',
        value: 'Red Soaked Stare',
      },
      {
        trait_type: 'Core',
        value: 'Crackled Lava',
      },
      {
        trait_type: 'Clothing',
        value: 'Summoners Stole',
      },
      {
        trait_type: 'Weapon',
        value: 'Slicer Glaive',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/97b6c3a938450aafaf18c360108e0fca9eca47475f49a29287da8ae98cc7a06d.png',
    id: '9931',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Red Wizard',
      },
      {
        trait_type: 'Eyes',
        value: 'Shattered Cyclop',
      },
      {
        trait_type: 'Core',
        value: 'Red Soil Seep',
      },
      {
        trait_type: 'Clothing',
        value: 'Planet Suitor',
      },
      {
        trait_type: 'Weapon',
        value: 'Doomseeker',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a878846564df241a703455b1f643ee4691ae1f131120f99308a77488af1751a8.png',
    id: '9932',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Jellicles',
      },
      {
        trait_type: 'Eyes',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Core',
        value: 'Cumulus Blue',
      },
      {
        trait_type: 'Clothing',
        value: 'Sky Seeker',
      },
      {
        trait_type: 'Weapon',
        value: 'Azure Fangs',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee66ef554d3754b6039e44af4c9d968f4a585003bbc30995540170126410a7a6.png',
    id: '9933',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Archshroom',
      },
      {
        trait_type: 'Eyes',
        value: 'Green Glance',
      },
      {
        trait_type: 'Core',
        value: 'Polypore',
      },
      {
        trait_type: 'Clothing',
        value: 'Undergrowth Robe',
      },
      {
        trait_type: 'Weapon',
        value: 'Mush Clobberer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd08c4592d5fc4253d59764643105db0149bc94214ad8362dbd41e240dd0a30c.png',
    id: '9934',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fungi Forest',
      },
      {
        trait_type: 'Eyes',
        value: 'Earnest Caps',
      },
      {
        trait_type: 'Core',
        value: 'Rhodactiskin',
      },
      {
        trait_type: 'Clothing',
        value: 'Fun Guy Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Undergrowth Stick',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/79b2408fe4baf0dc81a3bd139357253169e2f95de1e3ee910481a02207012279.png',
    id: '9935',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bloom',
      },
      {
        trait_type: 'Eyes',
        value: 'Mycology Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Dermatosis',
      },
      {
        trait_type: 'Clothing',
        value: 'Enoki Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Deceiver Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b52bda38499765d1fb910b72f778cd165bd0d60551bf594083130b4c326fff3e.png',
    id: '9936',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Plant Tank',
      },
      {
        trait_type: 'Eyes',
        value: 'Beady',
      },
      {
        trait_type: 'Core',
        value: 'Anenome Organs',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocean Tunic',
      },
      {
        trait_type: 'Weapon',
        value: 'Conch Beater',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f9b976898ddb40da5f8b0e668f200045b758b3e9eb60fd7bdfea862565d5601e.png',
    id: '9937',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Turitella Dome',
      },
      {
        trait_type: 'Eyes',
        value: 'Aquatic Ogle',
      },
      {
        trait_type: 'Core',
        value: 'Gilly Guts',
      },
      {
        trait_type: 'Weapon',
        value: 'Urchin Flail',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/34322ce6b436da5503ae8caa70a69056572f927ea9130b0e1fa9bd018d448525.png',
    id: '9938',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Conch Visage',
      },
      {
        trait_type: 'Eyes',
        value: 'Four Eyes',
      },
      {
        trait_type: 'Core',
        value: 'Seabreeze',
      },
      {
        trait_type: 'Clothing',
        value: 'Shelly Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Bone Hook',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90e591344bda75d0b48cafa71d9cdc362a6716e8adab360d8f6565e4dae93c76.png',
    id: '9939',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bristled',
      },
      {
        trait_type: 'Eyes',
        value: 'Sullen Glance',
      },
      {
        trait_type: 'Core',
        value: 'Patchy Fur',
      },
      {
        trait_type: 'Clothing',
        value: 'Wind Shaman',
      },
      {
        trait_type: 'Weapon',
        value: 'Gargantic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b079141a3314556398c70237fbd64de0315836d7198b1de6ca8b6a0550179781.png',
    id: '9940',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hillside Grazer',
      },
      {
        trait_type: 'Eyes',
        value: 'Fuzzy Lookers',
      },
      {
        trait_type: 'Core',
        value: 'Free The Bristle',
      },
      {
        trait_type: 'Clothing',
        value: 'Indigo Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Grazing Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e744710dd38790feb6cc33da991ad83c432829014264128915049c9e50dcdd04.png',
    id: '9941',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Shaggy Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Forlorn Fuzz',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Fleece',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Seer',
      },
      {
        trait_type: 'Weapon',
        value: 'Sheer Face Claymore',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/dd1b2663dd6adac4448346fef7433b561efcd643aa6da15da1908d52a21c914a.png',
    id: '9942',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Dark Orbit',
      },
      {
        trait_type: 'Eyes',
        value: 'Peeker Orbs',
      },
      {
        trait_type: 'Core',
        value: 'Black Thorn',
      },
      {
        trait_type: 'Clothing',
        value: 'Entangled Darkness',
      },
      {
        trait_type: 'Weapon',
        value: 'Night Star',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/53ed8012c80d9d669d1a132a23b49b191bf56d051de72a9a035ce94398827659.png',
    id: '9943',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Caustic Blush',
      },
      {
        trait_type: 'Eyes',
        value: 'Every Which Way',
      },
      {
        trait_type: 'Core',
        value: 'Transcendence',
      },
      {
        trait_type: 'Clothing',
        value: 'Ocular Hydra Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3000af4228322d10100a891e8185a8972b61631086b1af064ad32c930627621b.png',
    id: '9944',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Quiet Beast',
      },
      {
        trait_type: 'Eyes',
        value: 'Open Up',
      },
      {
        trait_type: 'Core',
        value: 'Ancient Bark',
      },
      {
        trait_type: 'Clothing',
        value: 'Cloak Of Knowing',
      },
      {
        trait_type: 'Weapon',
        value: 'Apprentices Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/48bb74096a11efce8c8542f07ce25f4d28eb48292bf9d43f1ebc9fd12280ac5d.png',
    id: '9945',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Rhino Beetle',
      },
      {
        trait_type: 'Eyes',
        value: 'Spider Trance',
      },
      {
        trait_type: 'Core',
        value: 'Shadow Scales',
      },
      {
        trait_type: 'Clothing',
        value: 'Beetle Shell',
      },
      {
        trait_type: 'Weapon',
        value: 'Carapace Claws',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1d82674a73885cfaec3d0ebc423ed3909d4deda16977e2733bc10fda99e60b4a.png',
    id: '9946',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Lackian Helm',
      },
      {
        trait_type: 'Eyes',
        value: 'Into Your Zone',
      },
      {
        trait_type: 'Core',
        value: 'Petrichor Explorer',
      },
      {
        trait_type: 'Clothing',
        value: 'Wingsect',
      },
      {
        trait_type: 'Weapon',
        value: 'Petri Spikes',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/6337e89eaf9a40ceb28dc45bb8109db70ac203ac84e45d9588eee2874fd05179.png',
    id: '9947',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Spindle Dome',
      },
      {
        trait_type: 'Eyes',
        value: 'Squiggles',
      },
      {
        trait_type: 'Core',
        value: 'Tavo River',
      },
      {
        trait_type: 'Clothing',
        value: 'Gunk Thread',
      },
      {
        trait_type: 'Weapon',
        value: 'Undulate Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/90f1b69b0518b007698c684efc4a629c3638862d025eb0adc127ead25e33930e.png',
    id: '9948',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Grey Horn',
      },
      {
        trait_type: 'Eyes',
        value: 'Circular',
      },
      {
        trait_type: 'Core',
        value: 'Grey Pelt',
      },
      {
        trait_type: 'Clothing',
        value: 'Spike Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Vivecsection',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/31dcf74474e122d65ba74df4f48c586ec53cd42f0738f947b2a40b0199ba802a.png',
    id: '9949',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pomped Out',
      },
      {
        trait_type: 'Eyes',
        value: 'Peep Holes',
      },
      {
        trait_type: 'Core',
        value: 'Grey Belly',
      },
      {
        trait_type: 'Clothing',
        value: 'Mace Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Skull Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/00a458fe621c25b8ccce1675e8ed16f50b3eed3a3474e2f89d4c5afaaaa4a2fd.png',
    id: '9950',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Grey Seeker',
      },
      {
        trait_type: 'Eyes',
        value: 'Infrared',
      },
      {
        trait_type: 'Core',
        value: 'Grey Fuzzy',
      },
      {
        trait_type: 'Clothing',
        value: 'Leader Plate',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Scythe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ceef95e13d3637686cb523045aae388bb4757755adc642d42cba0b00d7f963ff.png',
    id: '9951',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Clifftop Nest',
      },
      {
        trait_type: 'Eyes',
        value: 'Beaky',
      },
      {
        trait_type: 'Core',
        value: 'Midnight',
      },
      {
        trait_type: 'Clothing',
        value: 'Pluma Pauldrons',
      },
      {
        trait_type: 'Weapon',
        value: 'Mighty Quill',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bdf0b1593465374128d7c79a496f04ae93b176963a10068beadfa0a1cf99e3d1.png',
    id: '9952',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Ravens Approach',
      },
      {
        trait_type: 'Eyes',
        value: 'Birdlike',
      },
      {
        trait_type: 'Core',
        value: 'Midnight',
      },
      {
        trait_type: 'Clothing',
        value: 'Feather Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'The Claw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/39f826bcd5d04cb247c9d278e0c0d4e26286d201d1496616f96da8a80ce85e99.png',
    id: '9953',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Leonin Scout',
      },
      {
        trait_type: 'Eyes',
        value: 'Fierce Adventurer',
      },
      {
        trait_type: 'Core',
        value: 'Leonid',
      },
      {
        trait_type: 'Clothing',
        value: 'Scouts Honor',
      },
      {
        trait_type: 'Weapon',
        value: 'Electro Grip',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/18ebb7b461e725c8154504ec7b34d22065f8fa327b7c1fd0a9ec47291ac99add.png',
    id: '9954',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Fluid',
      },
      {
        trait_type: 'Eyes',
        value: 'Blob Eye',
      },
      {
        trait_type: 'Core',
        value: 'Tazer Droplets',
      },
      {
        trait_type: 'Clothing',
        value: 'Bubble Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Bubble Blower',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/182fb7f00b38045eeaa70facb3cbd54443cfabd7839e0e59973a3dbef8ab62f2.png',
    id: '9955',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cinderflow',
      },
      {
        trait_type: 'Eyes',
        value: 'Burn On Sight',
      },
      {
        trait_type: 'Core',
        value: 'Glowing Embers',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Brigids Fury',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1110d02c29e7bd77df9ed43f5d3fdd6f345a84a22210c9742c809d755a94f781.png',
    id: '9956',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Lazuli Nebula',
      },
      {
        trait_type: 'Eyes',
        value: 'Solar Focus',
      },
      {
        trait_type: 'Core',
        value: 'Sky Myth',
      },
      {
        trait_type: 'Clothing',
        value: 'Orchestrated',
      },
      {
        trait_type: 'Weapon',
        value: 'Golden Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c8ce2bee66f45abe6ca3aa188ee28109f9bec6dd8723fcd2f5c2aec60c4a82da.png',
    id: '9957',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'The Rising Sun',
      },
      {
        trait_type: 'Eyes',
        value: 'Glimmer Of Hope',
      },
      {
        trait_type: 'Core',
        value: 'Deep Space',
      },
      {
        trait_type: 'Clothing',
        value: 'Geometric',
      },
      {
        trait_type: 'Weapon',
        value: 'Lightning Rod',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/67c3dc4136c6313e1fe6c79ce376d175565be2dac26c9ea64a64575651f23e80.png',
    id: '9958',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Omni Lapis',
      },
      {
        trait_type: 'Eyes',
        value: 'Moon Pilot',
      },
      {
        trait_type: 'Core',
        value: 'Night Sky',
      },
      {
        trait_type: 'Clothing',
        value: 'Angelic',
      },
      {
        trait_type: 'Weapon',
        value: 'Wise Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1477e0b0688098ed5cbd65ce50552a6a619b3e4f987d1c94c2196e79adb02e5c.png',
    id: '9959',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cracked Pilot',
      },
      {
        trait_type: 'Eyes',
        value: 'Oval Ancients',
      },
      {
        trait_type: 'Core',
        value: 'Pressure Crack',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Chief',
      },
      {
        trait_type: 'Weapon',
        value: 'Orbius Glave',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/9472bcea41c89a065c935f4314a406feda4be9f2ad4f133bff33264de3357c2b.png',
    id: '9960',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Epiphyte',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Ah',
      },
      {
        trait_type: 'Core',
        value: 'Prolific Growth',
      },
      {
        trait_type: 'Clothing',
        value: 'Thorny Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Green Machete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/73f0dd3091fd82f3307ac4b2c2244fb02c5ed999cd5cd2949c37cf5be5370354.png',
    id: '9961',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Flora Display',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Gem',
      },
      {
        trait_type: 'Core',
        value: 'Jungle Brewing',
      },
      {
        trait_type: 'Clothing',
        value: 'Leafy Top',
      },
      {
        trait_type: 'Weapon',
        value: 'Sticky Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/0dbff70214b12930cfe0e1767f9bb7e64fbaab1bd05290f8f7807eaedf540ed5.png',
    id: '9962',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Whimsy Wisps',
      },
      {
        trait_type: 'Eyes',
        value: 'Through Your Soul',
      },
      {
        trait_type: 'Core',
        value: 'Climbing Dusk',
      },
      {
        trait_type: 'Clothing',
        value: 'Tattered Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Shoo Flame',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ea4e18c611106d0bde48de313da5506fcbf441c5e76f3236412cc16b35bea25f.png',
    id: '9963',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Flame Demon',
      },
      {
        trait_type: 'Eyes',
        value: 'Grim Bellows',
      },
      {
        trait_type: 'Core',
        value: 'Redtilian',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Sword of Alinta',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ec45e97b2e82fea5de5503de2a7180458aa0546833a10ed1eebeec66b8dac914.png',
    id: '9964',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Shrine Seer',
      },
      {
        trait_type: 'Eyes',
        value: 'Skylight',
      },
      {
        trait_type: 'Core',
        value: 'Enshrined',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Cloak',
      },
      {
        trait_type: 'Weapon',
        value: 'Highwind Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/505fa3d23d09b929485c6ffe58c8470b8f9ac185126f25a363e120f54d3508f4.png',
    id: '9965',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arid Elder',
      },
      {
        trait_type: 'Eyes',
        value: 'Terra Glare',
      },
      {
        trait_type: 'Core',
        value: 'Terra Welder',
      },
      {
        trait_type: 'Clothing',
        value: 'Skeletarmour',
      },
      {
        trait_type: 'Weapon',
        value: 'The Clobbler',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/921cd53feff334b93066d9c2eee913cf05e006736e63725463b2db54e3c2d33c.png',
    id: '9966',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'High Wonder',
      },
      {
        trait_type: 'Eyes',
        value: 'Ruby Red',
      },
      {
        trait_type: 'Core',
        value: 'Mid Knight',
      },
      {
        trait_type: 'Clothing',
        value: 'Golden Caesars Dressing',
      },
      {
        trait_type: 'Weapon',
        value: 'Dual Eclipse',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3d442bb78e539c7c2984b57943654f6e0d818642682bbfc47cab838230451dcb.png',
    id: '9967',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Billy Brayer',
      },
      {
        trait_type: 'Eyes',
        value: 'Steppe See',
      },
      {
        trait_type: 'Core',
        value: 'Golden Hide',
      },
      {
        trait_type: 'Clothing',
        value: 'Terra Light Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Winged Axe',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e08b68fff01ecc8579a8dd023d5ed3c1df206b208ba79401c92c8633c7dda3bc.png',
    id: '9968',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Regal',
      },
      {
        trait_type: 'Eyes',
        value: 'Melted Gold',
      },
      {
        trait_type: 'Core',
        value: '24k Thoracic',
      },
      {
        trait_type: 'Clothing',
        value: 'Chrono Plate Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Everlights Wand',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5a8b3d01315871ef4fc027dcd5cbc3e139e8c7fae1f76c1e03ccfb158e81d18c.png',
    id: '9969',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Leader of The Pack',
      },
      {
        trait_type: 'Eyes',
        value: 'Clifftop Sigh',
      },
      {
        trait_type: 'Core',
        value: 'Groundswell',
      },
      {
        trait_type: 'Clothing',
        value: 'Champion Sash',
      },
      {
        trait_type: 'Weapon',
        value: 'The Tenderizer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/12d1a8886ecc179be2de048ce879f0b3eaad8cedf39d80565ad7f7e3f8700a10.png',
    id: '9970',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'All Knowing Naru',
      },
      {
        trait_type: 'Eyes',
        value: 'Readers',
      },
      {
        trait_type: 'Core',
        value: 'Forest Secrets',
      },
      {
        trait_type: 'Clothing',
        value: 'Sol Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Adventurer Bow',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/c7778fb5235b62e65756f85b05e6afb3e4ed11cb74d2bc510726b3ff681e40e4.png',
    id: '9971',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Ivory Tusk',
      },
      {
        trait_type: 'Eyes',
        value: 'Stoney',
      },
      {
        trait_type: 'Core',
        value: 'Crackle',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Spiked Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spike In The Head',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f87abf73beab80238b69eca657899e2d5f117be618949f304f2e5307d2e2cefc.png',
    id: '9972',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hollowed Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Empty Stare',
      },
      {
        trait_type: 'Core',
        value: 'Hollow Belly',
      },
      {
        trait_type: 'Clothing',
        value: 'Temple Rot Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Absentia Club',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/76610e8747cc66282c8b89aee547c096545a3df7cba56d207644a604f99e30e1.png',
    id: '9973',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Shama Horns',
      },
      {
        trait_type: 'Eyes',
        value: 'Bone Sneak',
      },
      {
        trait_type: 'Core',
        value: 'Mountain Tested',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Beast Instincts',
      },
      {
        trait_type: 'Weapon',
        value: 'Truth Teller',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f8e75def77341bc0d245b1581c01b69669f591956fef048caf97f0922d019aa9.png',
    id: '9974',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Shrine Mentor',
      },
      {
        trait_type: 'Eyes',
        value: 'On The Edge',
      },
      {
        trait_type: 'Core',
        value: 'Gorillatron',
      },
      {
        trait_type: 'Clothing',
        value: 'Shrine Bandolier',
      },
      {
        trait_type: 'Weapon',
        value: 'Gusty Spear',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee89ed6392add101233b9e46a4fec6256a299cac41a6a7188d03bd6f51b21b2b.png',
    id: '9975',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Hella Flagella',
      },
      {
        trait_type: 'Eyes',
        value: 'Are You There',
      },
      {
        trait_type: 'Core',
        value: 'Glowbulb',
      },
      {
        trait_type: 'Clothing',
        value: 'Boiled Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Spiny Staff',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/cc37708009b1703eb228598e2c5d2c6cc14f3266a4c50623dbbb4ade6cf90d38.png',
    id: '9976',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Shrine Guardian',
      },
      {
        trait_type: 'Eyes',
        value: 'Eyevestigation',
      },
      {
        trait_type: 'Core',
        value: 'Spirit Slab',
      },
      {
        trait_type: 'Clothing',
        value: 'Ancient Orb Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Blade of Great Heights',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/e915c8add4be2fced13a20f9082463966b0ba8bcb688ffa2b2a1b469dba3d138.png',
    id: '9977',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Arid Trotter',
      },
      {
        trait_type: 'Eyes',
        value: 'Dry Stare',
      },
      {
        trait_type: 'Core',
        value: 'Carcussed',
      },
      {
        trait_type: 'Clothing',
        value: 'Wasteland Elite Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Betrayer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7c843b05b734f60d1ee88567545385e857ef422ddd5ee4faf0d5cc83789360b8.png',
    id: '9978',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Gentle Grazer',
      },
      {
        trait_type: 'Eyes',
        value: 'Judger',
      },
      {
        trait_type: 'Core',
        value: 'Root Believer',
      },
      {
        trait_type: 'Clothing',
        value: 'Light Foliage Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Terrachete',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8b77fe72f563c7fda629d9b715e0a6d9591d3044e3700d81ba28ceb5d26a2dab.png',
    id: '9979',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Badland Rover',
      },
      {
        trait_type: 'Eyes',
        value: 'Arid Stare',
      },
      {
        trait_type: 'Core',
        value: 'Stone Shard',
      },
      {
        trait_type: 'Clothing',
        value: 'Polished Marrow Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Rib Spreader',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/5b5d190f9e5e9b2639f215e5207dce65c80ead2ef21b5d8e3536a28c9f3d7253.png',
    id: '9980',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Skeleton Chief',
      },
      {
        trait_type: 'Eyes',
        value: 'Dryclops',
      },
      {
        trait_type: 'Core',
        value: 'Terra Former',
      },
      {
        trait_type: 'Clothing',
        value: 'Unknown Beast Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Demon Catcher',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/27d08c08290ea3c950fe228a8d032ecd8cf089b228254a7b87685eee42a98527.png',
    id: '9981',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Pearl Spires of Dread',
      },
      {
        trait_type: 'Eyes',
        value: 'Tricemore',
      },
      {
        trait_type: 'Core',
        value: 'Sharp Of Night',
      },
      {
        trait_type: 'Clothing',
        value: 'Ice Crystal Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Shaved Ice',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/1db9aed6c0e74dfed1f0ffdd3b2a46a4979cc6d6e6d0ed0144e8cc55988bf90a.png',
    id: '9982',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Absentia Mind',
      },
      {
        trait_type: 'Eyes',
        value: 'All Knowing Ahh',
      },
      {
        trait_type: 'Core',
        value: 'Ruined Skin',
      },
      {
        trait_type: 'Clothing',
        value: 'Bladed Carapace Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Tongue Tearer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/b505142948fa73317afe38efd3bee113e1c2753b0bea52ecbcfdb87b73da66d8.png',
    id: '9983',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Plant Life',
      },
      {
        trait_type: 'Eyes',
        value: 'Jungle Eh',
      },
      {
        trait_type: 'Core',
        value: 'Undergrowth',
      },
      {
        trait_type: 'Clothing',
        value: 'Organic Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Cactivorpal',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/59f67d819f37e7b8011de8f0f66aea95b2877be63eb314f2aa2de7fece2a2e70.png',
    id: '9984',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bone Dry Heretic',
      },
      {
        trait_type: 'Eyes',
        value: 'Staredown',
      },
      {
        trait_type: 'Core',
        value: 'Terra Builder',
      },
      {
        trait_type: 'Clothing',
        value: 'Ravenmore Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Skulds Renown',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/98e04463961ddfdac8c5c0e60962738c5f671adcd17d24edde018808ae2bf5ce.png',
    id: '9985',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elysion',
      },
      {
        trait_type: 'Eyes',
        value: 'Gloomy',
      },
      {
        trait_type: 'Core',
        value: 'Ordyceptus',
      },
      {
        trait_type: 'Clothing',
        value: 'Degenerates Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Confraction',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/37d359941717e3ac06a3ba016cb1298dd29d6e65a4de5a5fa97f94d8c2f0c6ff.png',
    id: '9986',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Echo Locator',
      },
      {
        trait_type: 'Eyes',
        value: 'Late Night',
      },
      {
        trait_type: 'Core',
        value: 'Deviled Belly',
      },
      {
        trait_type: 'Clothing',
        value: 'Monster Mesh',
      },
      {
        trait_type: 'Weapon',
        value: 'Underworld Spine',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/43cdfad9cb631f8234e14c8cc2d25275d156c6780b96878a61810a38f54dcda4.png',
    id: '9987',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Night Lies',
      },
      {
        trait_type: 'Eyes',
        value: 'Awakened Entertainer',
      },
      {
        trait_type: 'Core',
        value: 'Grasp Of Shadows',
      },
      {
        trait_type: 'Clothing',
        value: 'Gutterplate',
      },
      {
        trait_type: 'Weapon',
        value: 'Spade Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/4de40724ba68630d7ecd4ecfe3f622b9df00907be000d3d78d0d60bf03213fc2.png',
    id: '9988',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'The Ram',
      },
      {
        trait_type: 'Eyes',
        value: 'Furnace Burn',
      },
      {
        trait_type: 'Core',
        value: 'Magma Plate',
      },
      {
        trait_type: 'Clothing',
        value: 'Furnace Commander',
      },
      {
        trait_type: 'Weapon',
        value: 'Brimstone Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/bb322bf51f11edb5c8bfd2b3f75bc7370f86514cd117ed2ff82a8ba83517ceb3.png',
    id: '9989',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Ruined Senses',
      },
      {
        trait_type: 'Eyes',
        value: "Jack 'O Waste",
      },
      {
        trait_type: 'Core',
        value: 'Dilapidated Presence',
      },
      {
        trait_type: 'Clothing',
        value: 'Eternal Drain Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Gnaw Saw',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/7f9b78de5fccc29d02a8556c732885967c3b8be7f8ef4d0bf098f9706f518982.png',
    id: '9990',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Temple Sky',
      },
      {
        trait_type: 'Eyes',
        value: 'Peer Through Sky',
      },
      {
        trait_type: 'Core',
        value: 'Aqua Toshi',
      },
      {
        trait_type: 'Clothing',
        value: 'Plateau Explorer',
      },
      {
        trait_type: 'Weapon',
        value: 'Twin Shadows',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f115cc2b54e45ccc23abfbc0bbcb2694f8b87458a5d2f2d7b6bd2eb842b3dc4a.png',
    id: '9991',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Bog Head',
      },
      {
        trait_type: 'Eyes',
        value: 'Swamp Secrets',
      },
      {
        trait_type: 'Core',
        value: 'Bog Wood',
      },
      {
        trait_type: 'Clothing',
        value: 'Wildlands Armor',
      },
      {
        trait_type: 'Weapon',
        value: 'Twistree Blade',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/8999f31669ceab27379053e57e1c2540ce5303ecc495e815c1ed5077f50d17b9.png',
    id: '9992',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mallowfied',
      },
      {
        trait_type: 'Eyes',
        value: 'Fluff Vision',
      },
      {
        trait_type: 'Core',
        value: 'Smore',
      },
      {
        trait_type: 'Clothing',
        value: 'Charred Mallow Cape',
      },
      {
        trait_type: 'Weapon',
        value: 'Batter Up',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/3ddec6f78228f69341b111b1f9e6bc40bdfea68fcb66216a5b979bab862078fc.png',
    id: '9993',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Tazor',
      },
      {
        trait_type: 'Eyes',
        value: 'Light Visor',
      },
      {
        trait_type: 'Core',
        value: 'Kilowatt',
      },
      {
        trait_type: 'Clothing',
        value: 'Chaos Avenger',
      },
      {
        trait_type: 'Weapon',
        value: 'Coils of Vengeance',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/a5caa4c5eb374b403127929398fbfc56fdae04c801417dc091fef2a17c435bc7.png',
    id: '9994',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Mech Mouse',
      },
      {
        trait_type: 'Eyes',
        value: 'Galactic Gaze',
      },
      {
        trait_type: 'Core',
        value: 'Static Enigma',
      },
      {
        trait_type: 'Clothing',
        value: 'Chaos Soldier',
      },
      {
        trait_type: 'Weapon',
        value: 'Truestrike',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/511a0bc2bdfa296f24dc6a942c3205e84bbf9dc9963a10ae78dbc12283630de8.png',
    id: '9995',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Cyber Mind',
      },
      {
        trait_type: 'Eyes',
        value: 'Coiled Up',
      },
      {
        trait_type: 'Core',
        value: 'Dyna Volt',
      },
      {
        trait_type: 'Clothing',
        value: 'Chaos Gunner',
      },
      {
        trait_type: 'Weapon',
        value: 'Cosmic Hammer',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/059b92470fdd0dfc65eae4843c4a6d3f6a74b14e72e679ae19dfe702e4582c1b.png',
    id: '9996',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Geiser Dome',
      },
      {
        trait_type: 'Eyes',
        value: 'Calcified Pair',
      },
      {
        trait_type: 'Core',
        value: 'Sink Hole',
      },
      {
        trait_type: 'Clothing',
        value: 'Cape Of Eternal Burning',
      },
      {
        trait_type: 'Weapon',
        value: 'Blasphemy',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/26a5e23cc27d938c85bae7760d4dbe8b946abb353ee945414e3a3e685769682d.png',
    id: '9997',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Elder Tree',
      },
      {
        trait_type: 'Eyes',
        value: 'Eye Of Glob',
      },
      {
        trait_type: 'Core',
        value: 'Mossy Bod',
      },
      {
        trait_type: 'Clothing',
        value: 'Belt Of All Seeing',
      },
      {
        trait_type: 'Weapon',
        value: 'Bramble Thumper',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/f99b3b6ecc1311747742118d01284237fa92799bb69774c9301f283dac8d490c.png',
    id: '9998',
  },
  {
    attributes: [
      {
        trait_type: 'Head',
        value: 'Glo Jo',
      },
      {
        trait_type: 'Eyes',
        value: 'Cave Glow',
      },
      {
        trait_type: 'Core',
        value: 'Radiadon',
      },
      {
        trait_type: 'Clothing',
        value: 'Heavy Mantle',
      },
      {
        trait_type: 'Weapon',
        value: 'Cavern Haunter',
      },
    ],
    image: 'https://assets.otherside.xyz/kodas/ee9cd51a36448d7836459bb9e873d837b953fd19f03aff522a95fb4493dcfb6b.png',
    id: '9999',
  },
]

module.exports = { kodas }
