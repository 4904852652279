import React from 'react'
import Tag from '../Tag/Tag'
import './DashboardPanel.css'

const DashboardPanel = ({ traitType, traitValues, traitTier, disclaimer, isBig }) => {
  return (
    <div className="dashboardPanel">
      {traitType && <p className="dashboardPanel__name text-ellipsis">{traitType}</p>}
      {traitValues.length > 0 &&
        traitValues.map((value, i) => {
          return (
            <span key={i} className={'dashboardPanel__value text-ellipsis'}>
              <Tag label={value} hasEllipsis={true} className={'tag-purple'} />
            </span>
          )
        })}
      {disclaimer && <h6 className="dashboardPanel__disclaimer text-ellipsis">{disclaimer}</h6>}
      {traitTier && <div className="dashboardPanel__level-indicator">Tier {traitTier}</div>}
    </div>
  )
}

export default DashboardPanel
