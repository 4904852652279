import axios from 'axios'
import { isAddress } from '../helpers/isAddress'

export const getNFTsFromOS = async (searchQuery) => {
  let nextPage = null
  let openseaResults = []
  while (true) {
    let OSApiResponse = []

    try {
      isAddress(searchQuery)
        ? (OSApiResponse = await axios.get('/lambda/getNFTsFromOS', {
            params: { account: searchQuery, nextPage },
          }))
        : (OSApiResponse = await axios.get('/lambda/getNFTByTokenId', {
            params: { tokenId: searchQuery, nextPage },
          }))
    } catch (error) {
      console.log(`--- ${error?.response?.data?.customErrorMessage || error} ---`)
      nextPage = null
    }

    if (!OSApiResponse?.data?.assets.length) break
    openseaResults = [...openseaResults, ...OSApiResponse.data.assets]

    nextPage = OSApiResponse?.data?.nextPage || null
    if (!nextPage) break
  }
  return openseaResults
}
